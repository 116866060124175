import { IResource } from '../Utils/Resource';
import { CertificationDocument } from './CertificationDocument';
import { CertificationDossier } from './CertificationDossier';
import { CertificationVersion } from './CertificationVersion';
import { Course } from './Course';
import { EducationLevel } from './EducationLevel';
import { ExamContent } from './ExamContent';
import { Portal } from './Portal';

export type CertificationStatus = 'CONCEPT' | 'PUBLISHED' | 'DELETED';

export interface Certification extends IResource<'certifications'> {
  title: string;
  status: CertificationStatus;
  description: string;
  label: string;
  isValidated: boolean;
  certificationVersions?: () => CertificationVersion[];
  /** @deprecated  use certificationDossier instead */
  certificationDossiers?: () => CertificationDossier[];
  certificationDossier?: () => CertificationDossier;
  certificationDocuments?: () => CertificationDocument[];
  courses?: () => Course[];
  educationLevels?: () => EducationLevel[];
  examContent?: () => ExamContent[];
  createdAt: string;
  updatedAt: string;
  owner?: () => Portal;
}

export interface CertificationCreateInput {
  title?: string;
  label?: string;
  status?: CertificationStatus;
  description?: string;
  is_validated: boolean;
  dossier_import_code?: string;
  relationships?: {
    education_levels?: string[];
    portals?: string;
    courses?: string[];
  };
}

export type CertificationUpdateInput = Partial<CertificationCreateInput>;

export enum CertificationIncludes {
  dossiers,
  dossier,
  education_levels,
  exam_content,
  courses,
  documents,
  portals,
  versions,
  'versions.certification_documents',
  owner,
}

export interface CertificationFilters {
  portal?: string;
  status?: string;
  search?: string;
  title?: string;
  course?: string;
}

export enum CertificationSorts {
  title,
  status,
}
