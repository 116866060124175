import { Shareable } from '@brainstud/academy-api';
import classNames from 'classnames/bind';
import { Avatar } from 'Components';
import { useTranslator } from 'Providers/Translator';
import { ActivityTitle } from '../ActivityTitle';
import styles from './Shareable.module.css';

const cx = classNames.bind(styles);

type TProps = {
  shareable?: Shareable;
};

export const ActivityFeedShareable = ({ shareable }: TProps) => {
  const [t] = useTranslator();
  const target = shareable?.target?.();
  const type = target?.resourceType;
  const name =
    type === 'accounts' ? `${target?.fullName || ''}` : `${target?.name || ''}`;

  return shareable && target ? (
    <>
      <ActivityTitle id={shareable.id}>
        <span>{t('components.activity_feed.assignment_shared')}</span>
      </ActivityTitle>
      <div className={cx(styles.list)}>
        <Avatar size="extra-small" name={name} />
        <span className={cx(styles.name)}>{name}</span>
      </div>
    </>
  ) : null;
};
