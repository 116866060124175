import React from 'react';
import classNames from 'classnames/bind';
import { BlockRenderer } from '../../../Components/BlockRenderer/BlockRenderer';
import { IGroupContainerBlock } from './Types';
import styles from './GroupContainer.module.css';

const cx = classNames.bind(styles);

export const GroupContainer = ({
  blocks,
  className,
}: Omit<IGroupContainerBlock, 'id' | 'type'>) => (
  <div className={cx(styles.base, 'block-group__container', className)}>
    {blocks.map((block) => (
      <BlockRenderer key={block?.id} id={block?.id} block={block} />
    ))}
  </div>
);
