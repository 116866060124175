import { UseQueryOptions } from 'react-query';
import { useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import { AssistantCoach, AssistantCoachCreateInput, UUID } from '../Types';

interface IAssistantCoachProps {
  assistantCoach?: UUID;
}

interface IAssistantCoachProps {
  assistantCoach?: UUID;
}

export function useAssistantCoach(
  { assistantCoach, ...queryParameters }: IAssistantCoachProps = {},
  settings?: UseQueryOptions<DataDocument<AssistantCoach>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { assistantCoach: defaultAssistantCoach },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(assistantCoach || defaultAssistantCoach),
      baseName: 'v1.assistant_coaches',
      baseUri: '/v1/assistant_coaches',
      uri: `/${assistantCoach || defaultAssistantCoach}`,
      invalidate: ['v1.coaches', 'assistant_coach', 'assistant_coaches'],
      queryParameters,
    },
    context
  );

  return useSingleCRUD<AssistantCoach, AssistantCoachCreateInput>(
    request,
    settings
  );
}
