import React, { ReactNode, useEffect, useMemo, useReducer } from 'react';
import BreadcrumbsActions from './BreadcrumbsActions';
import { BreadcrumbsContext } from './BreadcrumbsContext';
import {
  BreadcrumbsInitialState,
  BreadcrumbsReducer,
} from './BreadcrumbsReducer';
import { toCrumbArray } from './toCrumbData';

type Props = {
  children: ReactNode;
};

/**
 * BreadcrumbsProvider.
 *
 * Keeps track of the current location and the data to show the
 * breadcrumbs properly.
 */
export const BreadcrumbsProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(
    BreadcrumbsReducer,
    BreadcrumbsInitialState
  );

  useEffect(() => {
    const crumbList = toCrumbArray({
      coach: null,
      admin: null,
      enrollment: null,
    });

    dispatch({
      type: BreadcrumbsActions.ADD_CRUMBS,
      payload: crumbList,
    });

    return () => {
      dispatch({
        type: BreadcrumbsActions.REMOVE_CRUMBS,
        payload: crumbList,
      });
    };
  }, []);

  const context = useMemo(
    () => ({
      ...state,
      dispatch,
    }),
    [state]
  );

  return (
    <BreadcrumbsContext.Provider value={context}>
      {children}
    </BreadcrumbsContext.Provider>
  );
};
