type ObjectInput = {
  [key: string]: boolean;
};

/**
 * getKeyWithTrue
 *
 * Returns the first key of the given object that is true and `undefined` otherwise.
 *
 * **CAUTION**: browsers can change the order of an object. If the order matters, try another solution.
 */
export function getKeyWithTrue<T extends ObjectInput>(
  object: T
): undefined | keyof T {
  return Object.keys(object).reduce<undefined | string>(
    (string, key) => (object[key] ? key : string),
    undefined
  );
}
