import { IBlock } from '@brainstud/course-blocks';
import { DateTimeString } from '../Utils/DateTimeString';
import { IResource } from '../Utils/Resource';

export interface ContentTemplate extends IResource<'content_templates'> {
  title: string;
  description: string;
  icon: string;
  status: 'concept' | 'published' | 'archived';
  template: IBlock[];
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
}

export enum ContentTemplateIncludes {}
export enum ContentTemplateFilters {}
export enum ContentTemplatesSorts {}
