import { createReactReducer } from 'Utils/createReactReducer';
import ACTIONS from './ModalActions';
import { TModal } from './ModalContext';

export interface IModalState {
  currentModal?: TModal;
  queue: TModal[];
}

type TOpenModalAction = {
  type: typeof ACTIONS.OPEN_MODAL;
  payload: TModal;
};

type TCloseModalAction = {
  type: typeof ACTIONS.CLOSE_MODAL | typeof ACTIONS.CLOSE_ALL_MODALS;
  payload: {
    closeAll: boolean;
  };
};

type TCloseAllModalsAction = {
  type: typeof ACTIONS.CLOSE_ALL_MODALS | typeof ACTIONS.CLOSE_MODAL;
};

export type TModalActions =
  | TOpenModalAction
  | TCloseModalAction
  | TCloseAllModalsAction;

export const ModalReducer = createReactReducer<IModalState, TModalActions>({
  [ACTIONS.OPEN_MODAL]: ({ payload: nextModal }, { currentModal, queue }) => {
    if (currentModal) {
      if (currentModal.priority < nextModal.priority) {
        return {
          currentModal: nextModal,
          queue: [...queue, currentModal].sort((a, b) =>
            a.priority > b.priority ? -1 : 1
          ),
        };
      }
      return {
        queue: [...queue, nextModal].sort((a, b) =>
          a.priority > b.priority ? -1 : 1
        ),
      };
    }
    return {
      currentModal: nextModal,
    };
  },
  [ACTIONS.CLOSE_MODAL]: (_, { queue }) => {
    if (queue.length > 0) {
      const nextModal = queue[0];
      return {
        currentModal: nextModal,
        queue: queue.slice(1),
      };
    }
    return {
      currentModal: undefined,
    };
  },
  [ACTIONS.CLOSE_ALL_MODALS]: () => ({
    currentModal: undefined,
    queue: [],
  }),
});
