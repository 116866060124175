import { createContext } from 'react';

export interface IPaginationContext {
  /** The current page number */
  page?: number;
  /** The current page limit */
  limit?: number;
  /** Sets the page number */
  setPage: React.Dispatch<React.SetStateAction<IPaginationContext['page']>>;
  /** Sets the page limit */
  setLimit: React.Dispatch<React.SetStateAction<IPaginationContext['limit']>>;
  /** @deprecated Use `useViewSettings` instead */
  toggleSortBy: (sortBy: string) => void;
  /** @deprecated Use `useViewSettings` instead */
  filter?: {
    [key: string]: undefined | string | number | boolean;
  };
  /** @deprecated Use `useViewSettings` instead */
  setFilter: React.Dispatch<React.SetStateAction<IPaginationContext['filter']>>;
  /** @deprecated Use `useViewSettings` instead */
  setStringFilter: (filterName: string, value: null | string) => void;
  /** @deprecated Use `useViewSettings` instead */
  sort?: string[];
  /** @deprecated Use `useViewSettings` instead */
  setSort: React.Dispatch<React.SetStateAction<IPaginationContext['sort']>>;
}

export const PaginationContext = createContext<null | IPaginationContext>(null);
