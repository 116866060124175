import React, { useCallback } from 'react';
import { useMe } from '@brainstud/academy-api';
import { Button, ContentModal } from '@brainstud/ui';
import { Delete } from '@mui/icons-material';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';

type TProps = {
  content: string;
  closeModal: () => void;
  closeMessage: () => void;
};

const MessageModal = ({ content, closeMessage, closeModal }: TProps) => {
  const [me] = useMe();
  const [t] = useTranslator();

  const handleClose = useCallback(() => {
    closeMessage();
    closeModal();
  }, [closeModal, closeMessage]);

  return (
    <ContentModal onClickOutside onClose={closeModal}>
      <div
        dangerouslySetInnerHTML={{
          __html: sanitizer(
            content.replace('{{name}}', me?.account?.()?.firstName || 'student')
          ),
        }}
      />
      <Button type="button" outline onClick={handleClose}>
        <Delete fontSize="large" />
        <span>{t('modals.message.hide_message')}</span>
      </Button>
    </ContentModal>
  );
};

export default MessageModal;
