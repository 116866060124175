/**
 * pick
 *
 * Will return key value pairs based on the given input.
 * pass key value pair object, and the keys you want to return of that object
 */
export function pick<O extends object, T extends keyof O = keyof O>(
  input: O,
  ...keys: T[]
): Pick<O, T> {
  return Object.keys(input).reduce(
    (output, key) =>
      keys.includes(key as T)
        ? {
            ...output,
            [key]: input[key],
          }
        : output,
    {} as Pick<O, T>
  );
}
