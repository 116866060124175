import React from 'react';
import { useSubjectEditor } from 'Modules/course-editor';
import { QuizEditView } from './QuizEditView';
import { SubjectEditView } from './SubjectEditView';

export const LearningSubjectEditView = () => {
  const { learningSubject } = useSubjectEditor();

  const SubjectLayout = learningSubject.layout;

  return (
    <>
      {(() => {
        switch (SubjectLayout) {
          case 'quiz':
            return <QuizEditView />;
          case 'list':
          default:
            return <SubjectEditView />;
        }
      })()}
    </>
  );
};
