/* eslint-disable class-methods-use-this,prefer-regex-literals */
import addDays from 'date-fns/addDays';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInHours from 'date-fns/differenceInHours';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import differenceInMonths from 'date-fns/differenceInMonths';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import differenceInWeeks from 'date-fns/differenceInWeeks';
import differenceInYears from 'date-fns/differenceInYears';
import formatter from 'date-fns/format';
import formatDistance from 'date-fns/formatDistance';
import formatDuration from 'date-fns/formatDuration';
import intervalToDuration from 'date-fns/intervalToDuration';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import en from 'date-fns/locale/en-US';
import nl from 'date-fns/locale/nl';
import subDays from 'date-fns/subDays';

type TDate = undefined | Date | number | string;
type TFormatterOptions = Exclude<Parameters<typeof formatter>, undefined>[2];
type TDifferenceFormats =
  | 'seconds'
  | 'minutes'
  | 'hours'
  | 'days'
  | 'weeks'
  | 'months'
  | 'years';

const locales = {
  nl,
  en,
};

const dateTranslations = {
  '[on]': 'datetime.on',
  '[at]': 'datetime.at',
  '[until]': 'datetime.until',
  '[between]': 'datetime.between',
  '[through]': 'datetime.through',
  '[before]': 'datetime.before',
  '[after]': 'datetime.after',
  '[from]': 'datetime.from',
  '[oclock]': 'datetime.oclock',
};

const dateTranslationKeys = Object.keys(dateTranslations)
  .map((key) => key.slice(1, -1))
  .join('|');

const dateFormatRegex = new RegExp(`\\[(${dateTranslationKeys})\\]`, 'g');

/**
 * The DateFormatHelper helps with formatting dates based on the locale. It works with the date-fns package to format
 * the dates.
 */
class DateFormatHelper {
  public locale: keyof typeof locales = 'en';

  private translator?: (input: string) => string;

  constructor(language: keyof typeof locales = 'en') {
    this.locale = language;
  }

  /**
   * Set the locale if it exists
   */
  setLocale(language: string, translator?: (input: string) => string) {
    if (language in locales) {
      this.locale = language as keyof typeof locales;
    }
    if (translator) {
      this.translator = translator;
    }
  }

  private translate(input: string) {
    return this.translator ? this.translator(`${input}`) : input;
  }

  /**
   * Makes sure the input is a Javascript Date object.
   */
  private static stringToDate(input: TDate) {
    if (typeof input === 'string') {
      return new Date(input);
    }
    return input || 0;
  }

  /**
   * A general purpose formatting, which receives the set locale as option already.
   */
  public toFormat(
    input: TDate,
    format: string,
    options: TFormatterOptions = {}
  ) {
    if (!input) return input;

    const translatedFormat = format.replace(
      dateFormatRegex,
      (_match, key) => `'${this.translate(dateTranslations[`[${key}]`])}'`
    );

    return formatter(DateFormatHelper.stringToDate(input), translatedFormat, {
      locale: locales[this.locale],
      ...options,
    });
  }

  /**
   * Transforms the given date to '[name of day] [day of month] [name of month].
   * Default example: 'Monday 3 January'
   */
  public toDayDate(
    input: TDate,
    format = 'cccc d MMM',
    options?: TFormatterOptions
  ) {
    return this.toFormat(input, format, options);
  }

  /**
   * Transforms the given date to '[name of day] [day of month] [name of month].
   * Default example: 'Monday 3 January'
   */
  public toDayDateTime(
    input: TDate,
    format = "cccc d MMM ''yy [at] HH:mm",
    options?: TFormatterOptions
  ) {
    return this.toFormat(input, format, options);
  }

  /**
   * Transforms the given date to '[day of month] [abbreviated month] [year] [hours]:[minutes]'
   * Default example: '3 Jan. 1992 16:55'
   */
  public toDateTime(
    input: TDate,
    format = 'd MMM y [at] HH:mm',
    options?: TFormatterOptions
  ) {
    return this.toFormat(input, format, options);
  }

  /**
   * Transforms the given date to '[day of month] [name of month] [year]'
   * Default example: '3 January 1992'
   */
  public toDate(
    input: TDate,
    format = 'd MMMM y',
    options?: TFormatterOptions
  ) {
    return this.toFormat(input, format, options);
  }

  /**
   * Returns the specific time of day '[hour]:[minutes]'
   * Default example: '16:55'
   */
  public toTime(input: TDate, format = 'HH:mm', options?: TFormatterOptions) {
    return this.toFormat(input, format, options);
  }

  /**
   * Shows a period of time, taking into account whether it is the same day
   */
  public toPeriod(
    startDate: TDate,
    endDate: TDate,
    format = "cccc d MMM ''yy [from] HH:mm",
    options?: TFormatterOptions
  ): string | number | undefined {
    const start = DateFormatHelper.stringToDate(startDate);
    const end = DateFormatHelper.stringToDate(endDate);
    if (!end) {
      return this.toDateTime(start, format.replace('[from]', '[at]'), options);
    }
    return isSameDay(start, end)
      ? `${this.toDayDate(start, format, options)} ${this.translate('datetime.until')} ${this.toTime(end, 'HH:mm', options)}`
      : `${this.toDayDate(start, format, options)} ${this.translate('datetime.until')} ${this.toDayDate(end, format, options)}`;
  }

  /**
   * Transforms the given date to '[day of month] [abbreviated month] [2 year digits]'
   * Default example: "3 Jan. '92"
   */
  public toAbbreviatedDate(
    input: TDate,
    format = "d MMM ''yy",
    options?: TFormatterOptions
  ) {
    return this.toFormat(input, format, options);
  }

  /**
   * isBefore.
   *
   * Checks if the first date is before the second date. In other words, the first date is older than the
   * second date.
   */
  public isBefore(input: TDate, compareTo: TDate) {
    return isBefore(
      DateFormatHelper.stringToDate(input),
      DateFormatHelper.stringToDate(compareTo)
    );
  }

  /**
   * isAfter.
   *
   * Checks if the first date is after the second date. In other words, the first date is newer than the
   * second date.
   */
  public isAfter(input: TDate, compareTo: TDate) {
    return isAfter(
      DateFormatHelper.stringToDate(input),
      DateFormatHelper.stringToDate(compareTo)
    );
  }

  /**
   * Return TRUE when the input date is within a number of days and after a from date (default now).
   *
   * @param input The date that is tested to be in the timeframe of a set of days
   * @param days The number of days that is added to the 'from date' (default now)
   * @param fromDate The date to calculate from, defaults to now.
   */
  public isWithinDays(
    input: TDate,
    days: number,
    fromDate: TDate = new Date()
  ) {
    if (!input) return false;
    const inputDate = DateFormatHelper.stringToDate(input);
    const relativeTo = DateFormatHelper.stringToDate(fromDate);

    return (
      isBefore(inputDate, addDays(relativeTo, days)) &&
      isAfter(inputDate, subDays(relativeTo, days))
    );
  }

  /**
   * Shows a relative date (e.g. 2 days ago) when the date is within a certain number of days.
   * @param input The input date
   * @param days The number of days to show a relative date
   * @param format The format that is shown when it is not shown as relative
   * @param suffix Whether or not the relative date should have a suffix
   */
  public toRelativeWithinDays(
    input: TDate,
    days: number = 3,
    format = '[on] d MMM y [at] HH:mm',
    suffix: boolean = true
  ) {
    if (!input) {
      return input;
    }
    const inputDate = DateFormatHelper.stringToDate(input);
    return this.isWithinDays(inputDate, days)
      ? this.toRelativeDateUntil(input, suffix)
      : this.toDateTime(input, format);
  }

  /**
   * Transforms the input date to a relative text string.
   * Default example: '10 minutes ago'
   * @param input The input date that is transformed
   * @param suffix Whether or not to add the suffix 'ago' or 'until'.
   * @param relativeTo
   */
  public toRelativeDateUntil(
    input: TDate,
    suffix: boolean = true,
    relativeTo: TDate = new Date()
  ) {
    if (!input) {
      return input;
    }
    return formatDistance(
      DateFormatHelper.stringToDate(input),
      DateFormatHelper.stringToDate(relativeTo),
      {
        locale: locales[this.locale],
        addSuffix: suffix,
      }
    );
  }

  /**
   * Turns two dates into a duration string.
   */
  public toDuration(
    start: TDate,
    end: TDate = new Date(),
    format = ['years', 'months', 'weeks', 'days', 'hours', 'minutes']
  ): string {
    if (!start) return '';

    const formatRegex = new RegExp(`\\b(${format.join('|')})((, )|$)`, 'g');

    return formatDuration(
      intervalToDuration({
        start: DateFormatHelper.stringToDate(start),
        end: DateFormatHelper.stringToDate(end),
      }),
      { format, delimiter: ', ' }
    ).replace(
      formatRegex,
      /**
       * Replacement function
       * @param _match The whole matched string.
       * @param unit Captures the time unit
       * @param separator Captures the comma and space or end of string
       */
      (_match, unit, separator) =>
        `${this.translate(`datetime.timeframe.${unit}`)}${separator}`
    );
  }

  /**
   * differenceIn.
   *
   * Get the difference between two dates in a specified format.
   */
  public differenceIn(
    format: TDifferenceFormats,
    dateOne: TDate,
    dateTwo: TDate
  ): number {
    const dateLeft = DateFormatHelper.stringToDate(dateOne);
    const dateRight = DateFormatHelper.stringToDate(dateTwo);

    switch (format) {
      case 'seconds':
        return differenceInSeconds(dateLeft, dateRight);
      case 'minutes':
        return differenceInMinutes(dateLeft, dateRight);
      case 'hours':
        return differenceInHours(dateLeft, dateRight);
      case 'days':
        return differenceInDays(dateLeft, dateRight);
      case 'weeks':
        return differenceInWeeks(dateLeft, dateRight);
      case 'months':
        return differenceInMonths(dateLeft, dateRight);
      case 'years':
        return differenceInYears(dateLeft, dateRight);
      default:
        return differenceInMilliseconds(
          DateFormatHelper.stringToDate(dateOne),
          DateFormatHelper.stringToDate(dateTwo)
        );
    }
  }
}

const DateFormatter = new DateFormatHelper();
export { DateFormatter };
