import { useMemo } from 'react';
import { useAnswer } from '@brainstud/academy-api';
import { Callout, Panel } from '@brainstud/ui';
import { Container, Loading } from 'Components';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import { useAnswerReview } from '../../../Providers';
import { withContentErrorBoundary } from '../../Errors/withContentErrorBoundary';
import styles from './ReviewTeacherRemarksView.module.css';

export const ReviewTeacherRemarksView = withContentErrorBoundary(() => {
  const [t] = useTranslator();
  const { answerId } = useAnswerReview() || {};
  const [{ data: answer }, { isLoading }] = useAnswer({
    answer: answerId,
  });

  const remarks = useMemo(
    () =>
      answer?.learningObjectVariety?.().teacherRemarks ||
      answer?.learningObject?.().teacherRemarks,
    [answer]
  );

  return isLoading ? (
    <Loading />
  ) : (
    <Container className={styles.base}>
      {remarks?.length ? (
        <Panel className={styles.panel}>
          <span className={styles['content-header']}>
            {t('review.remarksForTeacher')}
          </span>
          <div
            className={styles['html-content']}
            dangerouslySetInnerHTML={{
              __html: sanitizer(remarks, 'inlineOnly', {
                ADD_ATTR: ['target'],
              }),
            }}
          />
        </Panel>
      ) : (
        <Callout info>{t('review.noTeacherRemarks')}</Callout>
      )}
    </Container>
  );
});
