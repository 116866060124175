import { createContext, Dispatch } from 'react';
import { IEnvironmentState, TEnvironmentActions } from './EnvironmentReducer';

export interface IEnvironmentContext extends IEnvironmentState {
  baseEnvironment: IEnvironmentState['environment'];
  isLoggedIn: boolean;
  dispatch: Dispatch<TEnvironmentActions>;
}

export const EnvironmentContext = createContext<null | IEnvironmentContext>(
  null
);
