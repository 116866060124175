import { UseQueryOptions } from 'react-query';
import useIndexCR from '../Factories/useIndexCR';
import useSingleCRUD from '../Factories/useSingleCRUD';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  LearningObjectCollection,
  LearningObjectCollectionCreateInput,
  LearningObjectCollectionFilters,
  LearningObjectCollectionIncludes,
  LearningObjectCollectionSorts,
  LearningObjectCollectionUpdateInput,
  UUID,
} from '../Types';

interface Props
  extends HookParams<
    typeof LearningObjectCollectionIncludes,
    typeof LearningObjectCollectionFilters,
    typeof LearningObjectCollectionSorts
  > {
  learningObjectCollection?: UUID;
}

export function useLearningObjectCollection(
  { learningObjectCollection, ...queryParameters }: Props = {},
  settings?: UseQueryOptions<
    DataDocument<LearningObjectCollection>,
    ErrorDocument
  >
) {
  const context = useApi();
  const {
    parameters: { learningObjectCollection: defaultLearningObjectCollection },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(learningObjectCollection || defaultLearningObjectCollection),
      baseName: 'v1.learningObjectCollections',
      invalidate: [
        'learning_object_collections',
        'learning_object_collection',
        'v1.learningObjectCollections.statistics',
        'v1.learningRoutes.nodes',
      ],
      baseUri: '/v1/learning_object_collections',
      uri: `/${learningObjectCollection || defaultLearningObjectCollection}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    LearningObjectCollection,
    LearningObjectCollectionCreateInput,
    LearningObjectCollectionUpdateInput
  >(request, settings);
}

export function useLearningObjectCollections(
  { ...queryParameters }: Omit<Props, 'learningObjectCollection'> = {},
  settings?: UseQueryOptions<
    DataDocument<LearningObjectCollection[]>,
    ErrorDocument
  >
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.learningObjectCollections',
      invalidate: [
        'learning_object_collections',
        'learning_object_collection',
        'v1.learningObjectCollections.statistics',
      ],
      baseUri: '/v1/learning_object_collections',
      queryParameters,
    },
    context
  );
  return useIndexCR<
    LearningObjectCollection,
    LearningObjectCollectionCreateInput
  >(request, settings);
}
