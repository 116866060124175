import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames/bind';
import { useOnMount } from 'Hooks';
import { Checkbox } from '../../Checkbox/Checkbox';
import { useDropdownContext } from '../DropdownContext';
import { DROPDOWN_GROUP_TOGGLE_EVENT } from '../DropdownEvents';
import { DropdownGroupProps } from '../DropdownTypes';
import styles from './DropdownGroup.module.css';

const classes = classNames.bind(styles);

/**
 * DropdownGroup.
 *
 * Adds a group of options to a dropdown, resulting in a complex menu
 */
export const DropdownGroup = ({
  label,
  small,
  hidden,
  className,
  style,
  children,
}: DropdownGroupProps) => {
  const [open, setOpen] = useState(false);
  const { multiple, state, search, emit, handleToggleGroup } =
    useDropdownContext();
  const values = useMemo(
    () => state.filter((item) => item.selected).map((item) => item.value),
    [state]
  );
  const [groupValues, setGroupValues] = useState<string[]>([]);
  const isAllSelected = groupValues?.every((value) =>
    values.includes(String(value))
  );

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      const optionElements = ref.current.querySelectorAll(
        '[data-value]'
      ) as NodeListOf<HTMLElement>;
      setGroupValues(
        Array.from(optionElements).map((item) => item.dataset.value || '')
      );
    }
  }, []);

  const isIndeterminate =
    !isAllSelected &&
    groupValues?.some((value) => values.includes(String(value)));
  const handleToggleAll = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >(() => {
    const selectedGroupValues = groupValues?.filter(
      (item) => item !== undefined && values.includes(String(item))
    );
    const unselectedGroupValues = groupValues?.filter(
      (item) => item !== undefined && !values.includes(String(item))
    );

    handleToggleGroup(
      selectedGroupValues?.length === groupValues?.length
        ? groupValues
        : unselectedGroupValues
    );
  }, [groupValues, handleToggleGroup, values]);

  // set the state of the parent regarding open groups
  useOnMount(() => {
    emit(DROPDOWN_GROUP_TOGGLE_EVENT, open);
  }, [emit, open]);

  const handleToggleOpen = useCallback((event) => {
    if (event.target.tagName !== 'INPUT') {
      setOpen((prevOpen) => !prevOpen);
    }
  }, []);

  return (
    <div
      ref={ref}
      className={classes(
        styles.base,
        'dropdown-group',
        {
          small,
          open,
          searching: !!search,
          'dropdown-group__open': open,
          hidden,
        },
        className
      )}
      role="group"
    >
      <button
        type="button"
        tabIndex={0}
        className={classes(styles.button)}
        style={style}
        onClick={!search ? handleToggleOpen : undefined}
      >
        {multiple && (
          <Checkbox
            className={styles.checkbox}
            onChange={handleToggleAll}
            onClick={(event) => event.stopPropagation()}
            indeterminate={isIndeterminate}
            checked={isAllSelected}
            quiet
          />
        )}
        <div className={styles.label}>{label}</div>
        <svg
          className={classes('chevron-right')}
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
          role="presentation"
        >
          <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
        </svg>
      </button>
      <div
        className={classes(styles.panel, { open }, 'ui-dropdown-group_panel')}
      >
        {children}
      </div>
    </div>
  );
};
