import React from 'react';
import { Button, ContentModal } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { useModals } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { ReactComponent as RatingSuccessful } from '../Assets/rating_successful_sent.svg';
import styles from './ExternalAssessmentSuccessModal.module.css';

const cx = classNames.bind(styles);

export const ExternalAssessmentSuccessModal = () => {
  const [t] = useTranslator();
  const { closeModal } = useModals();

  return (
    <ContentModal onClose={closeModal} onClickOutside>
      <div>
        <div className={cx(styles.base)}>
          <h2>{t('modals.externalAssessmentSuccessModal.title')}</h2>
          <div className={cx(styles.content)}>
            <RatingSuccessful className={cx(styles['success-svg'])} />
            <div className={cx(styles.text)}>
              <p>{t('modals.externalAssessmentSuccessModal.content_1')}</p>
              <p>{t('modals.externalAssessmentSuccessModal.content_2')}</p>
            </div>
          </div>
        </div>
        <Button className={cx(styles.close)} link onClick={closeModal}>
          {t('modals.externalAssessmentSuccessModal.close')}
        </Button>
      </div>
    </ContentModal>
  );
};
