import React, { useCallback, useMemo, useState } from 'react';
import { useLearningObjectCollection } from '@brainstud/academy-api';
import { Callout, Search } from '@brainstud/ui';
import { Container, Loading } from 'Components';
import { useRouter } from 'next/router';
import { useModals } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { ToolkitModal } from '../../../Modals';
import { withContentErrorBoundary } from '../../Errors/withContentErrorBoundary';
import ToolkitCategories from './ToolkitCategories';

const CoursesToolkitsView = withContentErrorBoundary(() => {
  const [t] = useTranslator();
  const { openModal } = useModals();
  const [search, setSearch] = useState<string>('');
  const router = useRouter();
  const { collectionId } = router.query as { collectionId: string };
  const [{ data: collection }, { isLoading: isCollectionLoading }] =
    useLearningObjectCollection({
      learningObjectCollection: collectionId,
    });
  const availableToolkits = useMemo(
    () => collection?.toolkits?.() || [],
    [collection]
  );

  const handleRowAction = useCallback(
    (e, row) => {
      e.stopPropagation();
      openModal(ToolkitModal, { mediaItem: row });
    },
    [openModal]
  );

  if (isCollectionLoading) return <Loading />;

  return (
    <Container>
      {availableToolkits.length ? (
        <>
          <div style={{ margin: '2.5rem 0' }}>
            <Search
              onChange={(e) => setSearch(e.target.value)}
              placeholder={t('search')}
            />
          </div>
          {availableToolkits.map((toolkit) => (
            <div key={toolkit.id}>
              <ToolkitCategories
                toolkit={toolkit}
                requiredRole={toolkit.requiredRole}
                handleRowAction={handleRowAction}
                searchTerm={search}
              />
            </div>
          ))}
        </>
      ) : (
        <Callout info style={{ marginTop: '2rem' }}>
          {t('toolkit.noToolkits')}
        </Callout>
      )}
    </Container>
  );
});

export default CoursesToolkitsView;
