import { createContext, Dispatch, SetStateAction } from 'react';
import { AnswerGroup, DataDocument } from '@brainstud/academy-api';

export interface IAnswerGroupContext {
  isLoading: boolean;
  answerGroups: AnswerGroup[];
  currentAnswerGroup?: AnswerGroup;
  setAnswerGroupId: Dispatch<SetStateAction<undefined | string>>;
  createNewAnswerGroup: () => Promise<DataDocument<AnswerGroup>>;
}

export const AnswerGroupContext = createContext<null | IAnswerGroupContext>(
  null
);
