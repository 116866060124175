import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { Container } from 'Components';
import { useTranslator } from 'Providers/Translator';
import { VideoLink } from '../../../Utils';
import styles from './Video.module.css';

const cx = classNames.bind(styles);

interface VideoProps {
  caption?: string;
  source: string;
  title: string;
  url: string;
  thumbnail?: string;
}

const VideoBlock = ({ videos }: { videos: VideoProps[] }) => {
  const [active, setActive] = useState(0);
  const [t] = useTranslator();

  const activeVideo = videos[active];
  const activeVideoLink = new VideoLink(activeVideo.url);

  return (
    <div className={styles.video}>
      <Container>
        <div>
          <h2>{activeVideo.title}</h2>
          <div className={cx(styles.videoContent)}>
            <iframe
              title={activeVideo.title}
              src={activeVideoLink.getEmbedLink()}
              frameBorder="0"
              allow="autoplay; fullscreen"
            />
          </div>
          <p>{activeVideo.caption}</p>
        </div>

        {videos.length > 1 && (
          <>
            <h3>{t('components.videos.more')}</h3>

            <div className={cx(styles.scroller)}>
              {videos.map((video, videoIdx) => {
                const videoLink = new VideoLink(video.url);
                return (
                  <button
                    key={video.url}
                    type="button"
                    onClick={() => setActive(videoIdx)}
                    className={cx(
                      styles.videoItem,
                      { active: videoIdx === active },
                      styles.button
                    )}
                  >
                    <div className={cx(styles.title)}>
                      <h4>{video.title}</h4>
                    </div>
                    <img
                      className={cx(styles.thumbnail)}
                      src={
                        video?.thumbnail
                          ? video.thumbnail
                          : videoLink.getThumbnail()
                      }
                      alt=""
                    />
                  </button>
                );
              })}
            </div>
          </>
        )}
      </Container>
    </div>
  );
};

export default VideoBlock;
