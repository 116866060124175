import React, { CSSProperties, useCallback, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useMe, usePortals } from '@brainstud/academy-api';
import { Dropdown } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { UNLIMITED_ROLES } from 'Config/roles';
import { useHasAnyRole } from 'Hooks';
import { useTranslator } from 'Providers/Translator';
import { setBrowserCookie } from 'Utils';
import styles from './PortalSwitch.module.css';

const classes = classNames.bind(styles);

type Props = {
  style?: CSSProperties;
  className?: string;
};

/**
 * PortalSwitch.
 *
 * Shows a dropdown of all portals and sets the portal domain on select. It also invalidates the API cache.
 */
export const PortalSwitch = ({ className, style }: Props) => {
  const [t] = useTranslator();
  const [me] = useMe();
  const currentPortal = useMemo(() => me?.portal?.(), [me]);

  const isUnlimitedAdmin = useHasAnyRole(UNLIMITED_ROLES);
  const [portals] = usePortals(
    { include: ['portal_domains'] },
    { enabled: isUnlimitedAdmin }
  );
  const queryClient = useQueryClient();

  const handlePortalChange = useCallback(
    (portalId?: string) => {
      const selectedPortal = portals.find((item) => item.id === portalId);
      const portalUrl = selectedPortal?.portalDomains?.()[0]?.domain;
      const portalDomain = portalUrl?.replace('https://', '');
      setBrowserCookie(
        'portal_domain',
        portalDomain || '',
        portalDomain ? 1 : -1
      );
      queryClient.invalidateQueries();
    },
    [portals, queryClient]
  );

  return !currentPortal || !isUnlimitedAdmin ? null : (
    <div className={classes(styles.base, className)} style={style}>
      <Dropdown
        label={t('layouts.navigation.portal_change')}
        value={currentPortal.id}
        onChange={handlePortalChange}
        block
      >
        {portals.map((portal) => (
          <Dropdown.Option key={portal.id} value={portal.id}>
            {portal.name}
          </Dropdown.Option>
        ))}
      </Dropdown>
    </div>
  );
};
