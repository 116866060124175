import React, { useCallback, useState } from 'react';
import { IBlock } from '@brainstud/course-blocks';
import { Accordion, Button, ContentModal, Input } from '@brainstud/ui';
import { Form } from '@brainstud/universal-components';
import { Grid } from '@mui/material';
import classNames from 'classnames/bind';
import { useHasAnyRole } from 'Hooks';
import { useTranslator } from 'Providers/Translator';
import { ColorPicker } from '../../../Components/ColorPicker';
import { ExtraAnswersForm } from './ExtraAnswersForm/ExtraAnswersForm';
import styles from './BlockConfigModal.module.css';

const cx = classNames.bind(styles);

type TFormData = {
  paddingTop: string;
  paddingBottom: string;
  backgroundColor: string;
};

type TProps = {
  block: IBlock;
  handleBlockChange: (changes: Partial<IBlock>) => void;
  closeModal: () => void;
};

export const BlockConfigModal = ({
  block,
  handleBlockChange,
  closeModal,
}: TProps) => {
  const [t] = useTranslator();
  const [backgroundColor, setBackgroundColor] = useState(
    block?.style?.backgroundColor
  );
  const [extraIdentifiers, setExtraIdentifiers] = useState<string[]>(
    block.extraAnswerIds || []
  );

  const handleSaveBlockConfig = useCallback(
    async ({ paddingTop, paddingBottom }: TFormData) => {
      handleBlockChange?.({
        style: {
          ...(paddingTop ? { paddingTop: `${paddingTop}%` } : {}),
          ...(paddingBottom ? { paddingBottom: `${paddingBottom}%` } : {}),
          backgroundColor,
        },
        ...(extraIdentifiers.length > 0
          ? { extraAnswerIds: extraIdentifiers }
          : {}),
      });

      closeModal();
    },
    [handleBlockChange, backgroundColor, extraIdentifiers, closeModal]
  );

  const isAuthorRole = useHasAnyRole('author');

  return (
    <ContentModal onClose={closeModal} className={cx(styles.base)}>
      <h2>{t('course-editor.content_editor.block_config_modal.heading')}</h2>
      <Form onSubmit={handleSaveBlockConfig}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Input
              name="paddingTop"
              type="number"
              rules={['max:20']}
              label={t(
                'course-editor.content_editor.block_config_modal.padding_top'
              )}
              defaultValue={`${block?.style?.paddingTop}`
                .match(/\d+/)
                ?.toString()}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              name="paddingBottom"
              type="number"
              rules={['max:20']}
              label={t(
                'course-editor.content_editor.block_config_modal.padding_bottom'
              )}
              defaultValue={`${block?.style?.paddingBottom}`
                .match(/\d+/)
                ?.toString()}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ColorPicker
              name="backgroundColor"
              label={t(
                'course-editor.content_editor.block_config_modal.background_color'
              )}
              defaultValue={block?.style?.backgroundColor}
              presets={[
                'rgba(var(--brand-color), .1)',
                'rgba(var(--primary-color), .1)',
              ]}
              onChange={setBackgroundColor}
            />
          </Grid>
        </Grid>

        {isAuthorRole && (
          <Accordion
            title={t(
              'course-editor.content_editor.block_config_modal.advanced'
            )}
            className={styles.advanced}
          >
            <ExtraAnswersForm
              identifiers={extraIdentifiers}
              onChange={setExtraIdentifiers}
            />
          </Accordion>
        )}

        <div className={cx(styles.actions)}>
          <Button type="submit">
            {t('course-editor.content_editor.block_config_modal.save')}
          </Button>
          <Button type="button" quiet onClick={closeModal}>
            {t('course-editor.content_editor.block_config_modal.cancel')}
          </Button>
        </div>
      </Form>
    </ContentModal>
  );
};
