import React from 'react';
import { useUnsavedContentConfirmation } from '@brainstud/course-editor';
import { BackButton } from 'Modules/action-based-learning-panel/Components';
import { useTranslator } from 'Providers/Translator';

type Props = {
  className?: string;
};

/**
 * This component needs to placement within a ContentEditorProvider. It checks the unsaved status of the editor
 * before allowing the user to go back to the previous page.
 */
export const EditorBackButtonWithUnsavedCheck = ({ className }: Props) => {
  const [t] = useTranslator();
  const { handleUnsavedChangesCheck } = useUnsavedContentConfirmation({
    prompt: t('navigation.back.prompt'),
    confirm: t('navigation.back.confirm'),
    cancel: t('navigation.back.cancel'),
  });

  return (
    <BackButton
      onBeforeBack={handleUnsavedChangesCheck}
      className={className}
    />
  );
};
