import { DateTimeString } from '../Utils/DateTimeString';
import { IResource } from '../Utils/Resource';
import { Account } from './Account';
import { StoredFile } from './StoredFile';

export type AnswerFeedbackStatus = 'ACCEPTED' | 'REJECTED';

type FeedbackAccount = Pick<
  Account,
  'id' | 'resourceType' | 'firstName' | 'fullName' | 'lastName'
>;

export interface AnswerFeedback extends IResource<'answer_feedback'> {
  givenFeedback: string;
  givenStatus: AnswerFeedbackStatus;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;

  account?: () => FeedbackAccount;
  files?: () => StoredFile[];
}

export interface AnswerFeedbackCreateInput {
  feedback: string;
  status: AnswerFeedbackStatus;
  relationships?: AnswerFeedbackFiles;
}

export interface AnswerFeedbackFiles {
  files: string[];
}

export interface AnswerFeedbackUpdateInput
  extends Partial<AnswerFeedbackCreateInput> {}

export enum AnswerFeedbackIncludes {
  'files',
}

export enum AnswerFeedbackFilters {}

export enum AnswerFeedbackSorts {}
