import { DateTimeString } from '../Utils/DateTimeString';
import { IResource } from '../Utils/Resource';
import { UUID } from '../Utils/UUID';
import { Account } from './Account';
import { AnswerFeedback } from './AnswerFeedback';
import { AnswerGroup } from './AnswerGroups';
import { AnswerUpload } from './AnswerUpload';
import { Course } from './Course';
import { Enrollment } from './Enrollment';
import { ExternalAssessment } from './ExternalAssessment';
import { LearningObject } from './LearningObject';
import { LearningObjectVariety } from './LearningObjectVariety';

export type AnswerStatus =
  | 'OPEN'
  | 'CONCEPT'
  | 'TURNED_IN'
  | 'ACCEPTED'
  | 'REJECTED';

export interface Answer extends IResource<'answers'> {
  givenAnswer: { [t: string]: any };
  coachHasViewed: boolean;
  status: AnswerStatus;
  score: number;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  feedback?: () => AnswerFeedback[];
  uploads?: () => AnswerUpload[];
  account?: () => Account;
  course?: () => Course;
  learningObject?: () => LearningObject;
  learningObjectVariety?: () => LearningObjectVariety;
  enrollment?: () => Enrollment;
  externalAssessments?: () => ExternalAssessment[];
  links: {
    portfolioExport: {
      href: string;
    };
  };
  answerGroup?: () => AnswerGroup;
}

export interface AnswerCreateInput {
  learning_object: UUID;
  given_answer: string;
  status?: AnswerStatus;
  score?: number;
  relationships?: {
    answer_group?: UUID;
  };
}

export interface AnswerUpdateInput
  extends Omit<Partial<AnswerCreateInput>, 'learning_object'> {
  seen?: boolean;
}

export enum AnswerIncludes {
  'enrollment',
  'feedback',
  'feedback.files',
  'uploads',
  'account',
  'course',
  'coach_account',
  'learning_object',
  'learning_object_variety',
  'learning_object_variety.dossier_workprocesses',
  'learning_object_variety.dossier_workprocess_behaviours',
  'learning_object_variety.dossier_knowledge_skills',
  'external_assessments',
  'external_assessments.stored_files',
}

export interface AnswerFilters {
  seen: boolean;
  account: UUID;
  course: UUID;
  learning_object_collection: UUID;
  status: string;
  'learning_object.title': string;
  answer_group: string[];
  without_answer_group: true | false;
  'learning_object.metadata.labels': string[];
}

export enum AnswerSorts {
  'created_at',
}
