import React, { useCallback } from 'react';
import { Button, ConfirmationModal, Toggle } from '@brainstud/ui';
import { Settings } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';
import {
  useEnvironmentProvider,
  useLearningRouteProvider,
  useModals,
} from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { LearningRouteViewSettingsModal } from 'Views/CollectionViews/CollectionListView/LearningRouteViewSettingsModal';
import { useLearningRouteEditorProvider } from './Provider';
import styles from './LearningRouteEditorBar.module.css';

const cx = classNames.bind(styles);

type Props = {
  backTo?: string;
  /* Will wrap the preview toggle and lable */
  wrapPreview?: boolean;
  className?: string;
};

/**
 * LearningRouteEditorBar.
 *
 * Displays a bar for saving the edited state of a learning route.
 */
export const LearningRouteEditorBar = ({
  backTo = '/',
  wrapPreview,
  className,
}: Props) => {
  const {
    isLoading,
    save,
    updated,
    isUpdating,
    setPreview,
    preview,
    learningRoute,
  } = useLearningRouteEditorProvider();
  const { layout } = useLearningRouteProvider();
  const [t] = useTranslator();
  const router = useRouter();
  const { openModal, closeModal } = useModals();
  const { isCoachEnvironment } = useEnvironmentProvider();

  const handleConfirmation = useCallback(
    (e) => {
      if (updated) {
        e.preventDefault();
        openModal(ConfirmationModal, {
          question: t(
            'course-editor.learning_route_editor.confirmation.confirm_leave'
          ),
          yes: t('course-editor.learning_route_editor.confirmation.confirm'),
          no: t('course-editor.learning_route_editor.confirmation.cancel'),
          handleConfirm: () => {
            closeModal();
            router.push(backTo);
          },
        });
        return false;
      }
      return true;
    },
    [updated, openModal, t, closeModal, router, backTo]
  );

  const handleOpenLearningRouteSettingsModal = useCallback(() => {
    openModal(LearningRouteViewSettingsModal, {
      layout,
      learningRouteId: learningRoute?.id,
    });
  }, [openModal, layout, learningRoute]);

  return (
    <div className={cx(styles.base, className)}>
      <div className={cx(styles.inner)}>
        <div className={cx('option')}>
          <Toggle
            name="learningroute_editor__preview"
            className={cx(styles.preview, { wrap: wrapPreview })}
            checked={preview}
            onChange={() => setPreview(!preview)}
          >
            {t('course-editor.learning_route_editor.preview')}
          </Toggle>
        </div>
        <div className={cx(styles.buttonGroup, { wrapPreview })}>
          <Button
            type="button"
            success
            onClick={save}
            loading={isLoading || isUpdating}
            className={cx(styles.button)}
          >
            {t('course-editor.learning_route_editor.save')}
          </Button>
          <Button
            type="button"
            href={backTo}
            onClick={handleConfirmation}
            loading={isLoading || isUpdating}
            className={cx(styles.button)}
          >
            {t('course-editor.learning_route_editor.close')}
          </Button>
          {isCoachEnvironment && (
            <Button quiet small onClick={handleOpenLearningRouteSettingsModal}>
              <Settings fontSize="large" />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
