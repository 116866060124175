import { UseQueryOptions } from 'react-query';
import useIndexCR from '../Factories/useIndexCR';
import useSingleCRUD from '../Factories/useSingleCRUD';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  ToolkitCategory,
  ToolkitCategoryCreateInput,
  ToolkitCategoryFilters,
  ToolkitCategoryIncludes,
  ToolkitCategorySorts,
  ToolkitCategoryUpdateInput,
  UUID,
} from '../Types';

interface IToolkitCategoriesProps
  extends HookParams<
    typeof ToolkitCategoryIncludes,
    typeof ToolkitCategoryFilters,
    typeof ToolkitCategorySorts
  > {
  toolkit?: UUID;
  category?: UUID;
}

export function useToolkitCategory(
  { toolkit, category, ...queryParameters }: IToolkitCategoriesProps = {},
  settings?: UseQueryOptions<DataDocument<ToolkitCategory>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { toolkit: defaultToolkit, toolkitCategory: defaultCategory },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(toolkit || defaultToolkit) && !!(category || defaultCategory),
      baseName: 'v1.toolkits.categories',
      invalidate: ['v1.toolkits', 'toolkit_categories'],
      baseUri: `/v1/toolkits/${toolkit || defaultToolkit}/categories`,
      uri: `/${category || defaultCategory}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    ToolkitCategory,
    ToolkitCategoryCreateInput,
    ToolkitCategoryUpdateInput
  >(request, settings);
}

export function useToolkitCategories(
  { toolkit, ...queryParameters }: Omit<IToolkitCategoriesProps, 'category'>,
  settings?: UseQueryOptions<DataDocument<ToolkitCategory[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { toolkit: defaultToolkit },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(toolkit || defaultToolkit),
      baseName: 'v1.toolkits.categories',
      invalidate: ['v1.toolkits', 'toolkit_categories'],
      baseUri: `/v1/toolkits/${toolkit || defaultToolkit}/categories`,
      queryParameters,
    },
    context
  );
  return useIndexCR<ToolkitCategory, ToolkitCategoryCreateInput>(
    request,
    settings
  );
}
