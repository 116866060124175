import React, { CSSProperties, PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames/bind';
import { Tooltip } from '../Tooltip/Tooltip';
import styles from './ProgressMarker.module.css';

const cx = classNames.bind(styles);

interface Props {
  className?: string;
  style?: CSSProperties;
  tooltip: string;
  children: ReactNode;
  isPassed?: boolean;
}

/**
 * Shows a marker inside de progress bar can be used to show on what point you unlock something
 */
export const ProgressMarker = ({
  style,
  className,
  children,
  tooltip,
  isPassed,
}: PropsWithChildren<Props>) => (
  <div
    className={cx(styles.base, className, { isPassed, fallback: !children })}
    style={style}
  >
    <Tooltip button={children}>{tooltip}</Tooltip>
  </div>
);
