import { UseQueryOptions } from 'react-query';
import useIndexCR from '../Factories/useIndexCR';
import useSingleCRUD from '../Factories/useSingleCRUD';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  Account,
  AccountCreateInput,
  AccountFilters,
  AccountIncludes,
  AccountMeta,
  AccountSort,
  AccountUpdateInput,
  HookParams,
  UUID,
} from '../Types';
import { TJsonSpecResponse } from '../Types/Utils/JsonApiResource';
import { constructUrl } from '../Utils/constructUrl';
import { transformFetchResponse } from '../Utils/transformFetchResponse';

interface IAccountProps
  extends HookParams<
    typeof AccountIncludes,
    AccountFilters,
    typeof AccountSort,
    AccountMeta
  > {
  account?: UUID;
}

/**
 * getAccounts.
 *
 * Fetch accounts with Next fetch method.
 */
export async function getAccounts<T extends boolean = false>(
  queryParameters: IAccountProps = {},
  { asJson }: { asJson?: T } = {}
): Promise<T extends true ? TJsonSpecResponse : DataDocument<Account[]>> {
  return fetch(constructUrl('/api/v1/accounts', queryParameters)).then(
    (response) => transformFetchResponse(response, asJson)
  );
}

/**
 * Every user (except guests) have an account. This provides basic information about the such as name and email address.
 * @param account
 * @param queryParameters
 * @param settings
 * @returns Account
 */
export function useAccount(
  { account, ...queryParameters }: IAccountProps = {},
  settings?: UseQueryOptions<DataDocument<Account>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { account: defaultAccount },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(account || defaultAccount),
      baseName: 'v1.accounts',
      baseUri: '/v1/accounts',
      uri: `/${account || defaultAccount}`,
      invalidate: ['account', 'accounts'],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    Account,
    Partial<AccountCreateInput>,
    AccountUpdateInput
  >(request, settings);
}

/**
 * Every user (except guests) have an account. This provides basic information about the such as name and email address.
 * @param queryParameters
 * @param settings
 * @returns Account[]
 */
export function useAccounts(
  queryParameters: IAccountProps = {},
  settings?: UseQueryOptions<DataDocument<Account[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.accounts',
      baseUri: '/v1/accounts',
      queryParameters,
    },
    context
  );
  return useIndexCR<Account, AccountCreateInput>(request, settings);
}
