export const DEFAULT_COOKIE_OPTIONS = {
  httpOnly: true,
  /**
   * maxAge.
   * From seconds to days to 30 days default
   */
  maxAge: 60 * 60 * 24 * 30,
  path: '/',
  sameSite: 'lax' as const,
  secure: process.env.NODE_ENV === 'production',
};
