import React, { useMemo } from 'react';
import { useLearningRouteAutomations, UUID } from '@brainstud/academy-api';
import { ContentModal, Status } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { TNodeListItem, useModals } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import styles from './LearningRouteViewSettingsModal.module.css';

const cx = classNames.bind(styles);

type Props = {
  layout: 'action-cards' | 'chapter-cards';
  learningRouteId: UUID;
  list?: TNodeListItem[];
};
export const LearningRouteViewSettingsModal = ({
  layout,
  learningRouteId,
  list,
}: Props) => {
  const [t, { lc }] = useTranslator();
  const { closeModal } = useModals();

  const [{ data: automations }] = useLearningRouteAutomations({
    learningRoute: learningRouteId,
  });

  const automation = automations[0];
  const requiredAssignments = useMemo(
    () =>
      list?.filter((item) =>
        automation
          ?.triggerModels?.()
          .some((triggerModel) => triggerModel.id === item.id)
      ),
    [list, automation]
  );

  return (
    <ContentModal closeable onClose={closeModal}>
      <ContentModal.Header>
        <h2>{t('modals.learning_route_settings_modal.title')}</h2>
      </ContentModal.Header>
      <section className={cx(styles.base)}>
        <div>
          <strong>{t('modals.learning_route_settings_modal.layout')}</strong>
          <div>
            <span>
              {t('modals.learning_route_settings_modal.layout_description')}
            </span>
            <Status scheme="info" className={cx(styles.layout)}>
              {t(`views.courses.collection_edit.layouts.route.${layout}.title`)}
            </Status>
          </div>
        </div>
        {automation && (
          <div className={cx(styles.automations)}>
            <strong>
              {t('modals.learning_route_settings_modal.automations.title')}
            </strong>
            <div className={cx(styles.actions)}>
              <span>
                {t(
                  'modals.learning_route_settings_modal.automations.action_description'
                )}
              </span>
              <span>
                {lc(
                  t(
                    `modals.learning_route_settings_modal.automations.triggers.${automation.trigger}`
                  )
                )}
                .
              </span>
            </div>
            {requiredAssignments && requiredAssignments?.length > 0 && (
              <div className={cx(styles.selectedAssignments)}>
                <strong>
                  {t(
                    'modals.learning_route_settings_modal.automations.selected_assignments'
                  )}
                </strong>
                <ul>
                  {requiredAssignments?.map((assignment) => (
                    <li key={assignment?.id}>
                      <i>{assignment?.title}</i>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </section>
    </ContentModal>
  );
};
