import { UseQueryOptions } from 'react-query';
import { useIndexCRUD, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  Student,
  StudentBulkUpdateInput,
  StudentCreateInput,
  StudentFilters,
  StudentIncludes,
  StudentSorts,
  StudentUpdateInput,
  UUID,
} from '../Types';

interface IStudentProps
  extends HookParams<
    typeof StudentIncludes,
    StudentFilters,
    typeof StudentSorts
  > {
  student?: UUID;
}

export function useStudent(
  { student, ...queryParameters }: IStudentProps = {},
  settings?: UseQueryOptions<DataDocument<Student>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { student: defaultStudent },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(student || defaultStudent),
      baseName: 'v1.students',
      baseUri: '/v1/students',
      invalidate: ['v1.accounts', 'account', 'accounts'],
      uri: `/${student || defaultStudent}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<Student, StudentCreateInput, StudentUpdateInput>(
    request,
    settings
  );
}

export function useStudents(
  { ...queryParameters }: Omit<IStudentProps, 'student'> = {},
  settings?: UseQueryOptions<DataDocument<Student[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.students',
      baseUri: '/v1/students',
      invalidate: ['v1.accounts', 'account', 'accounts'],
      queryParameters,
    },
    context
  );

  return useIndexCRUD<Student, StudentCreateInput, StudentBulkUpdateInput>(
    request,
    settings
  );
}
