import { useContext } from 'react';
import TwoFactorContext from './TwoFactorContext';

export function useTwoFactor() {
  const context = useContext(TwoFactorContext);
  if (!context) {
    throw new Error('Please initialize TwoFactorProvider first!');
  }
  return context;
}
