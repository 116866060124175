import { IResource } from '../Utils/Resource';
import { LtiToolDeployment } from './LtiToolDeployment';

export interface LtiToolProvider extends IResource<'lti_tool_providers'> {
  title: string;
  ltiVersion: string;
  clientId: string;
  launchUrl: string;
  loginUrl: string;
  secret: string;
  privateKey: string;

  ltiDeployments?: () => LtiToolDeployment[];
  ltiResourceLinks?: string[];
}

export interface LtiToolProviderCreateInput {
  title: string;
  ltiVersion: '1.0.1' | '1.3.0';
  clientId?: string;
  launchUrl?: string;
  loginUrl?: string;
  secret?: string;
}

export interface LtiToolProviderUpdateInput
  extends LtiToolProviderCreateInput {}

export enum LtiToolProviderIncludes {
  lti_deployments,
  lti_resource_links,
}

export enum LtiToolProviderFilters {}

export enum LtiToolProviderSorts {}
