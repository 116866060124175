import React, { useEffect, useState } from 'react';
import { Loading } from 'Components';
import mammoth from 'mammoth';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import {
  isImage,
  isPDFDocument,
  isVideo,
  isWordDocument,
} from '../../Utils/getFileType';
import styles from './PreviewFileContainer.module.css';

type Props = {
  title: string;
  url: string;
  previewUrl?: string;
  mimeType?: string;
};

/**
 * FilePreviewContainer.
 *
 * Tries to show a preview of the provided file details. It will render docx, pdf, images, and videos.
 * Often it will render in an <object> tag (similar to an iframe), except for the docx files. That will
 * render as HTML.
 *
 * Note: use dynamic loading to show this Container, as it uses large libraries.
 */
export const FilePreviewContainer = ({
  url,
  mimeType,
  title,
  previewUrl,
}: Props) => {
  const [t] = useTranslator();

  const [isLoading, setIsLoading] = useState(true);

  const [__html, setHTML] = useState<string>('');
  const [iframeUrl, setIframeUrl] = useState<string>();
  const [isSupported, setIsSupported] = useState(true);

  const isAbsoluteUrl = url.startsWith('http');
  const fullUrl = !isAbsoluteUrl
    ? `${window?.location?.origin || ''}${url}`
    : url;

  useEffect(() => {
    if (
      isWordDocument(mimeType) ||
      isPDFDocument(mimeType) ||
      isImage(mimeType)
    ) {
      fetch(fullUrl, { credentials: 'include' }).then(async (resp) => {
        if (isWordDocument(mimeType)) {
          const arrayBuffer = await resp.arrayBuffer();
          mammoth
            .convertToHtml({ arrayBuffer })
            .then((result) => {
              setIsLoading(false);
              setHTML(result.value);
            })
            .catch(() => {
              setIsLoading(false);
              setIsSupported(false);
            });
        } else if (isPDFDocument(mimeType) || isImage(mimeType)) {
          const fileBlob = await resp.blob();
          setIframeUrl(URL.createObjectURL(fileBlob));
          setIsLoading(false);
        }
      });
    } else if (isVideo(mimeType) || previewUrl) {
      setIframeUrl(previewUrl || fullUrl);
      setIsLoading(false);
    } else {
      setIsSupported(false);
      setIsLoading(false);
    }
  }, [fullUrl, previewUrl, mimeType]);

  return (
    <div className={styles.base}>
      {!isSupported && <p>{t('modals.file_preview.unsupported')}</p>}
      {isLoading && isSupported ? (
        <Loading />
      ) : (
        <>
          {iframeUrl && (
            <object
              data={iframeUrl}
              type={mimeType}
              title={title}
              className={styles.object}
              style={{ maxWidth: '100%', maxHeight: '60vh' }}
            >
              <p>{t('modals.file_preview.unsupported')}</p>
            </object>
          )}
          {__html && (
            <>
              <div className={styles.note}>
                {t('modals.file_preview.is_preview')}
              </div>
              <div dangerouslySetInnerHTML={{ __html: sanitizer(__html) }} />
            </>
          )}
        </>
      )}
    </div>
  );
};
