import { UseQueryOptions } from 'react-query';
import { useIndex } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  IResource,
  ISearch,
  ISearchFilters,
  ISearchIncludes,
  ISearchSorts,
  UUID,
} from '../Types';

type Requires<I extends object, T extends keyof I> = Omit<I, T> &
  Pick<Required<I>, T>;

interface ISearchProps
  extends Requires<
    HookParams<
      typeof ISearchIncludes,
      Requires<ISearchFilters, 'query'>,
      typeof ISearchSorts
    >,
    'filter'
  > {
  query?: UUID;
}

export function useSearch<IResult extends IResource = ISearch>(
  queryParameters: ISearchProps,
  settings?: UseQueryOptions<DataDocument<(IResult & ISearch)[]>, ErrorDocument>
) {
  const context = useApi();
  const query = queryParameters?.filter?.query;
  const request = new ApiRequest(
    {
      enabled: !!query,
      baseName: 'v1.search',
      baseUri: '/v1/search',
      queryParameters,
    },
    context
  );
  return useIndex<IResult & ISearch>(request, settings);
}
