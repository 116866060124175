import { UseQueryOptions } from 'react-query';
import { useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  LearningObjectCollection,
  LearningObjectCollectionCreateInput,
  LearningObjectCollectionFilters,
  LearningObjectCollectionIncludes,
  LearningObjectCollectionSorts,
  LearningObjectCollectionUpdateInput,
  UUID,
} from '../Types';

interface ILearningObjectCollectionProps
  extends HookParams<
    typeof LearningObjectCollectionIncludes,
    typeof LearningObjectCollectionFilters,
    typeof LearningObjectCollectionSorts
  > {
  learningObjectCollection?: UUID;
  enrollment?: UUID;
}

export function useEnrollmentLearningObjectCollection(
  {
    enrollment,
    learningObjectCollection,
    ...queryParameters
  }: ILearningObjectCollectionProps = {},
  settings?: UseQueryOptions<
    DataDocument<LearningObjectCollection>,
    ErrorDocument
  >
) {
  const context = useApi();
  const {
    parameters: {
      learningObjectCollection: defaultLearningObjectCollection,
      enrollment: defaultEnrollment,
    },
  } = context;
  const request = new ApiRequest(
    {
      enabled:
        !!(learningObjectCollection || defaultLearningObjectCollection) &&
        !!(enrollment || defaultEnrollment),
      baseName: 'v1.enrollments.learningObjectCollections',
      baseUri: `/v1/enrollments/${enrollment || defaultEnrollment}/learning_object_collections`,
      uri: `/${learningObjectCollection || defaultLearningObjectCollection}`,
      invalidate: [
        'learning_object_collections',
        'learning_object_collection',
        'v1.learningObjectCollections.statistics',
      ],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    LearningObjectCollection,
    LearningObjectCollectionCreateInput,
    LearningObjectCollectionUpdateInput
  >(request, settings);
}
