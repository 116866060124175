import React from 'react';
import classNames from 'classnames/bind';
import { CalloutProps } from './CalloutProps';
import styles from './Callout.module.css';

const cx = classNames.bind(styles);

export const Callout = ({
  success,
  correct,
  incorrect,
  neutral,
  info,
  warning,
  attention,
  error,
  icon = true,
  dangerouslySetInnerHTML,
  hidden,
  margin,
  style,
  className,
  children,
}: CalloutProps) => {
  const hasCustomIcon = React.isValidElement(icon);
  // eslint-disable-next-line max-len
  const hasIcon =
    icon &&
    (correct ||
      success ||
      incorrect ||
      error ||
      attention ||
      warning ||
      neutral ||
      info ||
      hasCustomIcon);
  return (
    <div
      style={margin && !hidden ? { margin } : undefined}
      className={cx('margin-box', { 'is-hidden': hidden })}
    >
      <div
        style={style}
        className={cx(
          'base',
          'callout-component',
          {
            'is-positive': correct || success,
            'is-negative': incorrect || error,
            'is-warning': attention || warning,
            'is-neutral': neutral || info,
            'has-icon': hasIcon,
          },
          className
        )}
      >
        {hasIcon && (
          <div className={cx('icon', 'feedback-icon')}>
            {hasCustomIcon ? (
              icon
            ) : (
              <>
                {(correct || success) && (
                  <svg
                    className={cx('icon-svg')}
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
                  </svg>
                )}
                {(attention || warning) && (
                  <svg
                    className={cx('icon-svg')}
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
                  </svg>
                )}
                {incorrect && (
                  <svg
                    className={cx('icon-svg')}
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
                  </svg>
                )}
                {neutral && (
                  <svg
                    className={cx('icon-svg')}
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                  >
                    <path
                      d="M14.05,17.58l-0.01,0.01l-2.4-2.4l1.06-1.06l1.35,1.35L16.54,13l1.06,1.06 l-3.54,3.54L14.05,17.58z M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M7.34,6.28l1.41,1.41l1.41-1.41 l1.06,1.06L9.81,8.75l1.41,1.41l-1.06,1.06L8.75,9.81l-1.41,1.41l-1.06-1.06l1.41-1.41L6.28,7.34L7.34,6.28z M12,20 c-2.2,0-4.2-0.9-5.7-2.3L17.7,6.3C19.1,7.8,20,9.8,20,12C20,16.4,16.4,20,12,20z"
                      fillRule="evenodd"
                    />
                  </svg>
                )}
                {info && (
                  <svg
                    className={cx('icon-svg')}
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
                  </svg>
                )}
                {error && (
                  <svg
                    className={cx('icon-svg')}
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 0 24 24"
                    width="24"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
                  </svg>
                )}
              </>
            )}
          </div>
        )}
        {/* eslint-disable */}
        {!children && dangerouslySetInnerHTML ? (
          <div
            className={cx(styles.content, 'feedback-content')}
            dangerouslySetInnerHTML={{ __html: dangerouslySetInnerHTML.__html }} // eslint-disable-line
          />
        ) : (
          <div className={cx(styles.content, 'feedback-content')}>
            {children}
          </div>
        )}
        {/* eslint-enable */}
      </div>
    </div>
  );
};
