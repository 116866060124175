export default {
  'v1.answers': [
    'learning_object',
    'account',
    'enrollment',
    'learning_object_variety',
    'course',
  ],
  'v1.answers.show': [
    'learning_object',
    'account',
    'enrollment',
    'learning_object_variety',
    'course',
    'learning_object_variety.dossier_workprocesses',
    'learning_object_variety.dossier_workprocess_behaviours',
    'learning_object_variety.dossier_knowledge_skills',
  ],
  'v1.answers.feedback': ['files'],
  'v1.learningObjectCollections.show': [
    'education_levels',
    'learning_routes',
    'learning_objects',
    'learning_objects.varieties',
    'learning_objects.conditions',
    'learning_routes.learning_route_nodes.conditions',
    'learning_subjects',
    'toolkits',
  ],
  'v1.enrollments.index': ['course'],
  'v1.enrollments.show': [
    'learning_object_collection',
    'course',
    'coach_mandates',
    'exam',
    'exam.assessment',
    'schedule',
  ],
  'v1.productAddOns': ['event_calendar', 'event_calendar.items'],
  'v1.portals.faqItems': ['faq_audience', 'faq_category'],
  'v1.portals.courses': [
    'learning_object_collections',
    'certifications',
    'certifications.documents',
  ],
  'v1.certifications': ['versions.certificationDocuments'],
  'v1.accounts': ['subscription', 'tags', 'enrollments'],
  'v1.certifications.dossiers.core_tasks': [
    'workprocesses',
    'workprocesses.behaviours',
    'knowledge_skills',
  ],
  'v1.exams': [
    'assessment',
    'exam_content',
    'exam_content_variety',
    'sample_answers',
    'assessors',
    'assessors.account',
    'assessors.assigned_by_account',
    'uploads',
    'step_approvals',
  ],
  'v1.students': ['student_groups'],
  'v1.toolkits.categories': ['toolkit_items'],
};
