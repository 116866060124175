import { useCallback, useEffect, useState } from 'react';
import {
  DataDocument,
  getAccounts,
  IShareableObject,
  Shareable,
  ShareableCreateInput,
  useMe,
} from '@brainstud/academy-api';
import { useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';

type TAccountInputs = Record<string, IShareableObject>;

type TProps = {
  onSubmit: (
    values: ShareableCreateInput
  ) => Promise<DataDocument<Shareable<'accounts' | 'portals'>>>;
  shareables?: Shareable<'accounts'>[];
};

/**
 * useShareable.
 *
 * Hook to keep track of who a shareable is shared with.
 * Also tracks temporary state for adding who will be invited to view/comment on a shareable.
 */
export function useShareable({ onSubmit, shareables }: TProps) {
  const [me] = useMe();
  const [t] = useTranslator();
  const entity = me?.entity?.()?.id;
  const [setToast] = useToaster();
  const [sharedWith, setSharedWith] = useState<TAccountInputs>({});
  const [autocompleteInputs, setAutocompleteInputs] = useState<string[]>([]);

  const handleSubmit = useCallback(() => {
    const targets = autocompleteInputs.map((accountId) => ({
      targetableId: accountId,
      targetableType: 'account' as const,
    }));

    if (!targets.length) {
      return setToast(t('views.portfolio.share.incomplete'), 'error', 10000);
    }

    return onSubmit({
      access: 'read', // Always assume read access initially. The user can select an other access scope after creation.
      targets: targets.map((target) => ({
        id: target.targetableId,
        type: target.targetableType,
      })),
    });
  }, [autocompleteInputs, onSubmit, setToast, t]);

  const handleChangeShare = useCallback(
    (accountIds: string[]) => {
      const keys = Object.keys(sharedWith);
      setAutocompleteInputs(
        accountIds.filter((accountId) => !keys.includes(accountId))
      );
    },
    [sharedWith]
  );

  const handleSearch = useCallback(
    async (value: string) => {
      const { data: accounts } = await getAccounts({
        filter: { full_name: value, entity },
      });

      return accounts.reduce(
        (list, item) =>
          sharedWith[item.id] ? list : { ...list, [item.id]: item.fullName },
        {}
      );
    },
    [entity, sharedWith]
  );

  useEffect(() => {
    if (!shareables) {
      return setSharedWith({});
    }

    setSharedWith(
      shareables.reduce(
        (ids, shareable) => ({
          ...ids,
          [shareable.target().id]: {
            access: shareable.access,
            targetableId: shareable.target().id,
            targetableType: shareable.targetableType,
          },
        }),
        {} as TAccountInputs
      )
    );
  }, [shareables]);

  return {
    handleSubmit,
    handleSearch,
    handleChangeShare,
  };
}
