import { Container } from 'Components';
import { useRouter } from 'next/router';
import { CertificationsDocumentsTables } from '../../../Components';
import { withContentErrorBoundary } from '../../Errors/withContentErrorBoundary';

const CoursesExamsView = withContentErrorBoundary(() => {
  const router = useRouter();
  const { courseId } = router.query as {
    courseId: string;
    collectionId: string;
  };

  return (
    <Container style={{ marginTop: '5rem' }}>
      <CertificationsDocumentsTables course={courseId} />
    </Container>
  );
});

export default CoursesExamsView;
