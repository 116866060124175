type TOutput = {
  [key: string]: string | number | boolean | null | undefined;
};

type TInput = {
  [key: string]: unknown;
};

function isObject(input: unknown): input is TInput {
  return typeof input === 'object' && input !== null;
}

/**
 * toFlatObjectStructure.
 *
 * Restructures an object of multiple depths to a single depth with keys in a dot-notation.
 */
export function toFlatObjectStructure(
  object?: TInput,
  parent?: string,
  result = {}
): TOutput {
  return !object
    ? {}
    : Object.entries(object).reduce((flatObject, [key, value]) => {
        const propName = parent ? `${parent}.${key}` : key;
        return isObject(value)
          ? toFlatObjectStructure(value, propName, flatObject)
          : {
              ...flatObject,
              [propName]: value,
            };
      }, result);
}
