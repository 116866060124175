import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  CatalogProduct,
  CatalogProductCreateInput,
  CatalogProductFilters,
  CatalogProductIncludes,
  CatalogProductSorts,
  CatalogProductUpdateInput,
  HookParams,
  UUID,
} from '../Types';

interface CatalogProductProps
  extends HookParams<
    typeof CatalogProductIncludes,
    CatalogProductFilters,
    typeof CatalogProductSorts
  > {
  catalog?: UUID;
  product?: UUID;
}

export function useCatalogProduct(
  { catalog, product, ...queryParameters }: CatalogProductProps = {},
  settings?: UseQueryOptions<DataDocument<CatalogProduct>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { catalog: defaultCatalog, product: defaultProduct },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!((catalog ?? defaultCatalog) && (product ?? defaultProduct)),
      baseName: 'v1.catalogs.products',
      baseUri: `/v1/catalogs/${
        catalog ?? defaultCatalog
      }/products/${product ?? defaultProduct}`,
      invalidate: [
        'product',
        'products',
        'catalog_products',
        'catalog_product',
      ],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    CatalogProduct,
    CatalogProductCreateInput,
    CatalogProductUpdateInput
  >(request, settings);
}

export function useCatalogProducts(
  { catalog, ...queryParameters }: Omit<CatalogProductProps, 'product'> = {},
  settings?: UseQueryOptions<DataDocument<CatalogProduct[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { catalog: defaultCatalog },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(catalog ?? defaultCatalog),
      baseName: 'v1.catalogs.products',
      baseUri: `/v1/catalogs/${catalog ?? defaultCatalog}/products`,
      invalidate: [
        'product',
        'products',
        'catalog_products',
        'catalog_product',
      ],
      queryParameters,
    },
    context
  );
  return useIndexCR<CatalogProduct, CatalogProductCreateInput>(
    request,
    settings
  );
}
