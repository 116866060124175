import { useState } from 'react';
import {
  Coach,
  useCoaches,
  useMe,
  usePortalCourses,
  UUID,
} from '@brainstud/academy-api';
import { Button, Dropdown, Panel } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { Container, Loading } from 'Components';
import { Form } from 'Modules/universal-components';
import { useRouter } from 'next/router';
import { useTranslator } from 'Providers/Translator';
import { CoachAssistant, CoachAssistantAdd } from '../../../Components';
import { NO_COACH, useUnenroll } from '../../../Hooks';
import { withContentErrorBoundary } from '../../Errors/withContentErrorBoundary';
import styles from './CoursesCoachesView.module.css';

const cx = classNames.bind(styles);

const CoursesCoachesView = withContentErrorBoundary(() => {
  const [t] = useTranslator();
  const router = useRouter();
  const { courseId, collectionId } = router.query as {
    courseId: string;
    collectionId: string;
  };
  const [{ data: courses }, { isLoading: coursesLoading }] = usePortalCourses();
  const course = courses.find(
    (item) => item.id === courseId && !!item?.enrollment
  );
  const [newCoachId, setNewCoachId] = useState<UUID | undefined>();
  const { handleUnenroll, coaches, loading, coachesLoading } = useUnenroll({
    newCoachId,
    course,
    redirectOnSuccess: true,
  });
  const [me] = useMe();

  const [{ data: assistants }] = useCoaches({
    filter: {
      course: courseId,
      primary_coach: me?.account?.().id,
    },
    include: ['assistant_coaches'],
  });

  if (coursesLoading || !course) return <Loading />;

  return (
    <Container className={styles.base}>
      <h4>{t('coaches.colleagues')}</h4>
      <Panel className={cx(styles.panel, styles.coachesPanel)}>
        <div className={styles.coaches}>
          {assistants.map((assistant) => (
            <CoachAssistant key={assistant.id} assistant={assistant} />
          ))}
          <CoachAssistantAdd
            courseId={courseId}
            learningObjectCollectionId={collectionId}
          />
        </div>
      </Panel>
      <h4 className={styles.enenrollTitle}>
        {t('modals.unEnrollModal.confirm')}
      </h4>
      <Panel className={styles.unenrollPanel}>
        <p>{t('views.courseCoaches.description')}</p>
        <Form onSubmit={handleUnenroll}>
          <Dropdown
            label={t('modals.unEnrollModal.select_coach')}
            className={styles.dropdown}
            value={newCoachId}
            disabled={coachesLoading}
            onChange={setNewCoachId}
            placeholder={t('modals.unEnrollModal.coaches_default')}
            rules="required"
          >
            <Dropdown.Option value={NO_COACH}>
              {t('modals.unEnrollModal.no_transfer')}
            </Dropdown.Option>
            {coaches.map((coach: Coach) => (
              <Dropdown.Option key={coach.id} value={coach.id}>
                {[coach.firstName, coach.lastNameInsertion, coach.lastName]
                  .filter(Boolean)
                  .join(' ')}
              </Dropdown.Option>
            ))}
          </Dropdown>
          <div className={styles.buttons}>
            <Button type="submit" loading={loading} error>
              {t('modals.unEnrollModal.confirm')}
            </Button>
          </div>
        </Form>
      </Panel>
    </Container>
  );
});

export default CoursesCoachesView;
