/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { IAccordionContainer } from '@brainstud/course-blocks';
import { Input } from '@brainstud/ui';
import { useTranslator } from 'Providers/Translator';
import { ContentEditor } from '../../../ContentEditor/ContentEditor';
import styles from './AccordionForm.module.css';

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export interface AccordionFormProps {
  data: Optional<IAccordionContainer, 'blocks'>;
  onChange: (e: Omit<IAccordionContainer, 'id' | 'type'>) => void;
}

export const AccordionForm = ({
  data: initialData,
  onChange,
}: AccordionFormProps) => {
  const [t] = useTranslator();

  const [data, setData] = useState(initialData);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setData((prevData) => ({
        ...prevData,
        title: event.target.value,
      })),
    []
  );

  useEffect(() => {
    onChange?.({
      blocks: [],
      ...data,
    });
  }, [data]);

  return (
    <div className={styles.base}>
      <Input
        label={t(
          'course-editor.content_editor.blocks.accordion_container.title'
        )}
        value={data.title}
        onChange={handleChange}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label>
        {t(
          'course-editor.content_editor.blocks.accordion_container.description'
        )}
      </label>
      <div className={styles.content}>
        <ContentEditor
          containerId={initialData.id}
          className="accordion-editor"
          blocks={initialData.blocks || []}
          containerType={initialData.type}
        />
      </div>
    </div>
  );
};
