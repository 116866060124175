import { DateTimeString } from '../Utils/DateTimeString';
import { IResource } from '../Utils/Resource';
import { DossierCoreTask } from './DossierCoreTask';

export interface CertificationDossier
  extends IResource<'certification_dossiers'> {
  title: string;
  identifierLabel: string;
  coreTasks?: () => DossierCoreTask[];
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  hasExternalSource: boolean;
  meta: {
    canEdit?: boolean;
  };
}

export interface CertificationDossierCreateInput {
  title: string;
  identifier_label: string;
}

export interface CertificationDossierUpdateInput {
  title: string;
}

export enum CertificationDossierIncludes {
  core_tasks,
  'core_tasks.workprocesses',
  'core_tasks.knowledge_skills',
}

export enum CertificationDossierFilters {
  title,
  identifier_label,
}

export enum CertificationDossierSorts {
  title,
  identifier_label,
}
