export interface ITableState {
  initialized: boolean;
  header: boolean;
  sortOn?: string;
}

type TSET_SORT = {
  type: 'SET_SORT' | 'TOGGLE_SORT';
  payload?: string;
};

type TSET_HEADER = {
  type: 'SET_HEADER';
  payload: boolean;
};

export type TTableActions = TSET_SORT | TSET_HEADER;

export function TableReducer(data: ITableState, action: TTableActions) {
  switch (action.type) {
    case 'SET_SORT':
      return {
        ...data,
        initialized: true,
        sortOn: action.payload,
      };
    case 'TOGGLE_SORT':
      return {
        ...data,
        initialized: true,
        sortOn:
          data.sortOn && data.sortOn === action.payload
            ? `-${data.sortOn}`
            : action.payload,
      };
    case 'SET_HEADER':
      return {
        ...data,
        header: action.payload,
      };
    default:
      return data;
  }
}
