import React, { FC, useCallback } from 'react';
import {
  Toolkit,
  ToolkitCategory,
  useToolkitCategories,
} from '@brainstud/academy-api';
import { Accordion, Callout, Panel, Table, Td, Th, Tr } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { Loading } from 'Components';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import styles from './ToolkitCategories.module.css';

const cx = classNames.bind(styles);

export interface ToolkitCategoriesProps {
  toolkit: Toolkit;
  requiredRole: string | undefined;
  searchTerm: string;
  handleRowAction: (e: any, row: any) => void;
}
/**
 *
 * This component will show the different toolkit files by category, including a requiredRole badge ('student'|'coach') if available.
 *
 */
const ToolkitCategories: FC<ToolkitCategoriesProps> = ({
  toolkit,
  requiredRole,
  searchTerm,
  handleRowAction,
}) => {
  const [t] = useTranslator();
  const [{ data: categories }, { isLoading: isCategoriesLoading }] =
    useToolkitCategories({
      toolkit: toolkit.id,
      include: ['toolkit_items'],
    });

  const foundCategories = useCallback(
    (category: ToolkitCategory) =>
      category
        .toolkitItems?.()
        .filter((toolkitItem) =>
          new RegExp(searchTerm, 'ig').test(toolkitItem.title)
        ),
    [searchTerm]
  );

  return isCategoriesLoading || !categories ? (
    <Loading />
  ) : (
    <>
      {categories.length === 0 ? (
        <div style={{ marginTop: '2rem' }}>
          <strong>{toolkit.title}</strong>
          <Callout info style={{ marginTop: '1rem' }}>
            {t('toolkit.noToolkits')}
          </Callout>
        </div>
      ) : (
        <>
          {categories.map((category) => (
            <div key={category.id}>
              <Accordion
                heading
                quiet
                title={
                  <h3 className={cx(styles.title)}>
                    {category.title || toolkit.title
                      ? category.title || toolkit.title
                      : requiredRole
                        ? t(`toolkit.defaultTitle.${requiredRole}`)
                        : t('toolkit.defaultTitle.student')}
                  </h3>
                }
              >
                <Panel className={cx(styles.toolkit)}>
                  <Table data={category.toolkitItems?.() || []}>
                    <Table.THead>
                      <Tr>
                        <Th>{t('toolkit.documents')}</Th>
                        <Th>{t('toolkit.description')}</Th>
                      </Tr>
                    </Table.THead>
                    <Table.TBody>
                      {foundCategories(category)?.map((row) => (
                        <Tr key={row.id}>
                          <Td onClick={(e) => handleRowAction(e, row)}>
                            {row.title}
                          </Td>
                          <Td
                            className={cx(styles.nowrap)}
                            dangerouslySetInnerHTML={{
                              __html: sanitizer(row.description || ''),
                            }}
                          />
                        </Tr>
                      ))}
                    </Table.TBody>
                  </Table>
                </Panel>
              </Accordion>
            </div>
          ))}
        </>
      )}
    </>
  );
};
export default ToolkitCategories;
