import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  CertificationDossier,
  CertificationDossierCreateInput,
  CertificationDossierFilters,
  CertificationDossierIncludes,
  CertificationDossierSorts,
  CertificationDossierUpdateInput,
  HookParams,
  UUID,
} from '../Types';

interface ICertificationDossierProps
  extends HookParams<
    typeof CertificationDossierIncludes,
    typeof CertificationDossierFilters,
    typeof CertificationDossierSorts
  > {
  certification?: UUID;
  dossier?: UUID;
}

export function useCertificationDossier(
  {
    certification,
    dossier,
    ...queryParameters
  }: ICertificationDossierProps = {},
  settings?: UseQueryOptions<DataDocument<CertificationDossier>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: {
      certification: defaultCertification,
      dossier: defaultDossier,
    },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(certification || defaultCertification),
      baseName: 'v1.certifications.dossiers',
      baseUri: `/v1/certifications/${certification || defaultCertification}/dossiers`,
      uri: `/${dossier || defaultDossier}`,
      queryParameters,
      invalidate: [
        'dossier',
        'dossiers',
        'certification',
        'certifications',
        'v1.certifications',
      ],
    },
    context
  );
  return useSingleCRUD<
    CertificationDossier,
    CertificationDossierCreateInput,
    CertificationDossierUpdateInput
  >(request, settings);
}

export function useCertificationDossiers(
  {
    certification,
    ...queryParameters
  }: Omit<ICertificationDossierProps, 'dossier'> = {},
  settings?: UseQueryOptions<
    DataDocument<CertificationDossier[]>,
    ErrorDocument
  >
) {
  const context = useApi();
  const {
    parameters: { certification: defaultCertification },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(certification || defaultCertification),
      baseName: 'v1.certifications.dossiers',
      baseUri: `/v1/certifications/${certification || defaultCertification}/dossiers`,
      queryParameters,
      invalidate: [
        'dossier',
        'dossiers',
        'certification',
        'certifications',
        'v1.certifications',
      ],
    },
    context
  );
  return useIndexCR<CertificationDossier, CertificationDossierCreateInput>(
    request,
    settings
  );
}
