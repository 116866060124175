export const ADMIN_ROUTES = ['/admin'];

export const COACH_ROUTES = ['/coach'];

export const STUDENT_ROUTES = [
  '/courses',
  '/catalog',
  '/portfolio',
  '/profile',
];

export const GUEST_ROUTES = ['/auth', '/rate', '/profiles'];
