import { UseQueryOptions } from 'react-query';
import {
  Scorm,
  ScormCreateInput,
  ScormFilters,
  ScormIncludes,
  ScormSorts,
  ScormUpdateInput,
} from '../Types/Resources/Scorm';
import {
  ApiRequest,
  DataDocument,
  ErrorDocument,
  HookParams,
  useApi,
  useIndex,
  useSingleCRUD,
  UUID,
} from '..';

interface IScormProps
  extends HookParams<
    typeof ScormIncludes,
    typeof ScormFilters,
    typeof ScormSorts
  > {
  course: UUID;
  scorm?: UUID;
}

export function useScorm(
  { course, scorm, ...queryParameters }: IScormProps,
  settings?: UseQueryOptions<DataDocument<Scorm>, ErrorDocument>
) {
  const context = useApi();

  const request = new ApiRequest(
    {
      enabled: !!scorm,
      baseName: 'v1.courses.scorms',
      baseUri: `/v1/courses/${course}/scorms`,
      uri: `/${scorm}`,
      invalidate: ['v1.courses', 'scorm', 'scorms'],
      queryParameters,
    },
    context
  );

  return useSingleCRUD<Scorm, ScormCreateInput, ScormUpdateInput>(
    request,
    settings
  );
}

export function useScorms(
  { course, scorm, ...queryParameters }: IScormProps,
  settings?: UseQueryOptions<DataDocument<Scorm[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.courses.scorms',
      baseUri: `/v1/courses/${course}/scorms`,
      invalidate: ['v1.courses'],
      queryParameters,
    },
    context
  );

  return useIndex<Scorm>(request, settings);
}
