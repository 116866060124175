import React, { useCallback } from 'react';
import { Status, Td, Tr } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { ReviewItem } from 'Modules/coach-panel/Providers/AnswerReviewProvider';
import { useRouter } from 'next/router';
import { useAccessProvider } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { DateFormatter } from 'Utils/DateFormatHelper';
import { useReviewState } from '../../../useReviewState';
import { QuizReviewablesType } from '../../useQuizReviewables';
import styles from '../row.module.css';

const cx = classNames.bind(styles);

type Props = {
  data: QuizReviewablesType;
};

export const QuizRow = ({ data }: Props) => {
  const [t] = useTranslator();
  const router = useRouter();
  const { hasAccess } = useAccessProvider();
  const [, setReviewSet] = useReviewState();
  const handleStartReview = useCallback(
    (reviewSet: ReviewItem[]) => {
      setReviewSet({ origin: router.pathname, index: 0, reviewSet });
      const startWith = reviewSet[0];
      router.push(
        `/coach/review/${startWith.enrollmentId}/${startWith.learningObjectId}/answer`
      );
    },
    [router, setReviewSet]
  );

  return (
    <Tr
      key={data.id}
      onClick={() =>
        hasAccess ? handleStartReview(data.reviewSet) : undefined
      }
      className={cx(styles.base, { disabled: !hasAccess })}
    >
      <Td>{data.participant}</Td>
      <Td>{data.courseName}</Td>
      <Td>{data.title}</Td>
      <Td>
        <Status scheme="warning">
          {t(`states.${data.status?.toLowerCase()}`)}
        </Status>
      </Td>
      <Td>{DateFormatter.toDateTime(data.date)}</Td>
    </Tr>
  );
};
