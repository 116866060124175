import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useCourses } from '@brainstud/academy-api';
import {
  Accordion,
  Button,
  Checkbox,
  Pagination,
  Panel,
  Search,
} from '@brainstud/ui';
import { usePagination } from '@brainstud/universal-components';
import { Add, HighlightOff } from '@mui/icons-material';
import { Chip } from '@mui/material';
import classNames from 'classnames/bind';
import { FilterSidebarButton, Loading } from 'Components';
import { StatusColors } from 'Modules/admin-panel/Utils';
import { useTranslator } from 'Providers/Translator';
import { TableLayout } from './Layouts/TableLayout';
import styles from './CoursesIndex.module.css';

const cx = classNames.bind(styles);

const CoursesIndex = () => {
  const [t] = useTranslator();

  const { page, limit, sort, setSort, filter, setFilter } = usePagination();

  const statusOptions = useMemo(
    () => Object.keys(StatusColors).filter((status) => status !== 'LIVE'),
    []
  );

  const selectedStatusOptions = useMemo(
    () =>
      filter?.status && typeof filter.status === 'string'
        ? filter?.status?.split(',')
        : [],
    [filter]
  );

  const [search, setSearch] = useState<string | undefined>();

  const handleToggleStatusFilter = useCallback(
    (statusValue: null | string) => {
      setFilter((prevFilters) => ({
        ...prevFilters,
        ...(!statusValue
          ? { status: undefined }
          : {
              status: selectedStatusOptions.includes(statusValue)
                ? selectedStatusOptions
                    .filter((item) => item !== statusValue)
                    .join(',')
                : [...selectedStatusOptions, statusValue].join(','),
            }),
      }));
    },
    [selectedStatusOptions, setFilter]
  );

  useEffect(() => {
    setFilter((prevFilters) => ({
      ...prevFilters,
      ...(search && search.trim() !== ''
        ? { title: search }
        : { title: undefined }),
    }));
  }, [search, setFilter]);

  const [{ data: courses }, { data: request, isLoading, isFetching }] =
    useCourses({
      page,
      limit,
      sort: (sort as
        | ('status' | 'title' | '-status' | '-title')[]
        | undefined) || ['-updated_at'],
      filter: filter as any,
      include: ['products', 'products.catalogs'],
      meta: ['policy'],
    });

  const handleResetFilter = useCallback(() => {
    setFilter({});
    setSearch(undefined);
  }, [setFilter]);

  const handleResetSearch = useCallback(() => {
    setSearch(undefined);
  }, []);

  return (
    <div className={cx(styles.base)}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className={cx(styles.filters)}>
            <FilterSidebarButton
              className={cx(styles.filter)}
              label={t('views.courses.coursesIndex.filter')}
              title={t('views.courses.coursesIndex.filters')}
              onReset={handleResetFilter}
            >
              <Accordion
                title={`${t('views.courses.coursesIndex.status')} (${selectedStatusOptions.length})`}
                quiet
                open
              >
                {statusOptions.map((status) => (
                  <Checkbox
                    key={status}
                    quiet
                    defaultValue={status}
                    checked={selectedStatusOptions.includes(status)}
                    onChange={() => handleToggleStatusFilter(status)}
                  >
                    {t(`views.courses.coursesIndex.statusOptions.${status}`)}
                  </Checkbox>
                ))}
              </Accordion>
            </FilterSidebarButton>
            <Search
              placeholder={t('views.courses.search')}
              defaultValue={search || ''}
              onChange={(v) =>
                v.target.value ? setSearch(v.target.value) : handleResetSearch()
              }
              onClose={handleResetSearch}
            />
            <Button to="/admin/courses/create">
              <Add />
              <span>{t('views.courses.add')}</span>
            </Button>
          </div>

          {(selectedStatusOptions.length > 0 || search) && (
            <div className={cx(styles.appliedFilters)}>
              <span>{`${t('components.catalog.filters')}:`}</span>
              {selectedStatusOptions.map((status) => (
                <Chip
                  key={status}
                  label={t(
                    `views.courses.coursesIndex.statusOptions.${status}`
                  )}
                  className={cx(styles.chip)}
                  onDelete={() => handleToggleStatusFilter(status)}
                  deleteIcon={<HighlightOff />}
                />
              ))}
              {search && (
                <Chip
                  label={search}
                  className={cx(styles.chip)}
                  onDelete={handleResetSearch}
                  deleteIcon={<HighlightOff />}
                />
              )}
            </div>
          )}

          <Panel>
            <TableLayout
              onSort={setSort}
              isFetching={isFetching}
              courses={courses}
            />
          </Panel>
          <Pagination
            totalPages={request?.meta?.lastPage}
            totalResults={`${t('components.pagination.totalResults')} ${request?.meta?.total}`}
            onPageChange={() =>
              document
                .querySelector('#scroller')
                ?.scroll({ top: 0, left: 0, behavior: 'smooth' })
            }
            labels={{
              itemsPerPage: t('components.pagination.resultsPerPage'),
            }}
          />
        </>
      )}
    </div>
  );
};

export default CoursesIndex;
