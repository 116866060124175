import React from 'react';
import { ITabContainerBlock } from '@brainstud/course-blocks';
import classNames from 'classnames/bind';
import { ContentEditor } from '../../../ContentEditor/ContentEditor';
import styles from './TabContainerForm.module.css';

const classes = classNames.bind(styles);

interface TabContainerFormProps extends Omit<ITabContainerBlock, 'type'> {
  /** Indicate the tabcontent is in a vertical tab container. This adds the vertical class to the element. */
  vertical?: boolean;
}

/**
 * Shows the content of a tab. It does _not_ show the tab itself.
 */
export const TabContainerForm = ({
  id,
  className,
  style,
  blocks,
  hidden,
  vertical,
}: TabContainerFormProps & { hidden: boolean }) => (
  <div
    aria-hidden={hidden}
    role="tabpanel"
    className={classes(styles.base, { hidden, vertical }, className)}
    style={style}
  >
    <ContentEditor
      containerId={id}
      blocks={blocks || []}
      containerType="tab_content"
    />
  </div>
);
