import { Comment } from '@brainstud/academy-api';
import { Thread } from '@brainstud/ui/Comments/Thread';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import { DateFormatter } from 'Utils/DateFormatHelper';
import { ActivityTitle } from '../ActivityTitle';
import styles from '../ActivityFeed.module.css';

const cx = classNames.bind(styles);

type TProps = {
  comment?: Omit<Comment, 'resourceType'> & { resourceType: 'comments' };
  isOpen: boolean;
  onOpen: (threadId: string) => void;
};

export const ActivityFeedComment = ({ comment, isOpen, onOpen }: TProps) => {
  const [t] = useTranslator();

  return comment ? (
    <>
      <ActivityTitle id={comment.id}>
        <span>{t('components.activity_feed.comment_posted')}</span>
        <span className={cx(styles.timestamp)}>
          {DateFormatter.toTime(comment.createdAt)}
        </span>
      </ActivityTitle>
      <Thread
        thread={{ ...comment, resourceType: 'comment', type: 'comment' }}
        author={comment.account?.().fullName}
        isExpanded={isOpen}
        onToggleActions={onOpen}
      />
    </>
  ) : null;
};
