import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import { Badge } from '@brainstud/ui';
import { ChevronLeft, ChevronRight, MoreHoriz } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Container, Link } from 'Components';
import { useRouter } from 'next/router';
import { useBreadcrumbsProvider, useEnvironmentProvider } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { isUUID } from 'Utils/isUuid';
import { ADMIN_ENV_ROLES, COACH_ROLES } from '../../Config/roles';
import { useHasAnyRole } from '../../Hooks';
import styles from './Breadcrumbs.module.css';

const classes = classNames.bind(styles);

type Props = {
  style?: CSSProperties;
  className?: string;
};

/**
 * Breadcrumbs.
 *
 * Component for rendering the breadcrumbs of the platform based on the pathname and translations.
 * It is possible to specify UUID identifiers in a dictionary to replace those in the breadcrumbs
 * as a human-readable value (title / name). This is done via the useBreadcrumbs hook.
 */
export const Breadcrumbs = ({ className, style }: Props) => {
  const router = useRouter();
  const [t] = useTranslator();

  // Small fix for next.config rewrites. This way, `/coach` route is shown as `coach > dashboard` in the breadcrumbs.
  const route = router.asPath === '/coach' ? router.pathname : router.asPath;
  const segments = route
    .replace(/(.*)[?#].*/, '$1')
    .split('/')
    .slice(1);

  const { crumbs } = useBreadcrumbsProvider();
  const { environment, prevEnvironment } = useEnvironmentProvider();
  const hasAccessToMultipleEnvironments = useHasAnyRole([
    ...ADMIN_ENV_ROLES,
    ...COACH_ROLES,
  ]);

  const { returnTo: returnToQs } = router.query as { returnTo?: string };
  const [returnTo, setReturnTo] = useState<
    undefined | { returnToEnv: string; returnToUri: string }
  >();
  const { returnToEnv, returnToUri } = returnTo || {};

  const validSegments = useMemo(
    () =>
      segments
        .map((segment) => {
          const crumb = crumbs[segment];
          return { segment, crumb };
        })
        .filter(
          ({ segment, crumb }) => crumb?.text !== null && segment !== ''
        ) || [],
    [segments, crumbs]
  );

  const segmentMaxWidth: React.CSSProperties = useMemo(
    () => ({
      ['--segment-max-width' as any]: `${
        100 /
        (hasAccessToMultipleEnvironments || crumbs.environment
          ? validSegments.length + 1
          : validSegments.length)
      }%`,
    }),
    [crumbs.environment, hasAccessToMultipleEnvironments, validSegments.length]
  );

  useEffect(() => {
    if (returnToQs) {
      setReturnTo({
        returnToEnv: prevEnvironment || 'guest',
        returnToUri: returnToQs,
      });
    } else if (returnToEnv !== prevEnvironment) {
      setReturnTo(undefined);
    }
  }, [environment, returnToQs, prevEnvironment, returnToEnv]);

  const rootUrl =
    returnToUri ||
    crumbs?.environment?.href ||
    (environment === 'admin' && '/admin') ||
    (environment === 'coach' && '/coach') ||
    (environment === 'student' && '/catalog') ||
    '/';

  if (crumbs.environment && !crumbs.environment.text) {
    return null;
  }

  return (
    <div
      className={classes(styles.base, className)}
      style={{ ...style, ...segmentMaxWidth }}
    >
      <Container>
        {router.isReady && (
          <ul>
            {(hasAccessToMultipleEnvironments || crumbs.environment) && (
              <>
                <li key="root">
                  <Link href={rootUrl}>
                    <Badge className={classes(styles.badge)} color="blue">
                      {crumbs.environment ? (
                        crumbs.environment.text
                      ) : (
                        <>
                          {!returnToUri ? (
                            t(
                              `components.breadcrumbs.environments.${environment}`
                            )
                          ) : (
                            <>
                              <ChevronLeft />
                              <span>
                                {t('components.breadcrumbs.environments.back')}
                              </span>
                            </>
                          )}
                        </>
                      )}
                    </Badge>
                  </Link>
                </li>
                <li className={styles.separator}>
                  <ChevronRight />
                </li>
              </>
            )}

            {segments.map((segment, idx, arr) => {
              const crumb = crumbs[segment];
              const isLoading = isUUID(segment) && crumb === undefined;
              const text =
                crumb?.text || t(`components.breadcrumbs.${segment}`, {}, '');
              const segmentPrefix = segments.slice(0, 1 + idx).join('/');
              const isLastCrumb = idx + 1 === arr.length;
              const href = crumb?.href?.startsWith('/')
                ? crumb.href.slice(1)
                : `${segmentPrefix}${(crumb?.href && `/${crumb.href}`) || ''}`;
              return crumb?.text === null || segment === '' ? null : (
                <React.Fragment key={segment}>
                  {(isLoading && (
                    <li className={classes(styles.isLoading)}>
                      <MoreHoriz />
                    </li>
                  )) || (
                    <li title={text || undefined}>
                      {crumb?.href === null || isLastCrumb ? (
                        text || undefined
                      ) : (
                        <Link href={!href.startsWith('/') ? `/${href}` : href}>
                          {text || undefined}
                        </Link>
                      )}
                    </li>
                  )}
                  {!isLastCrumb && (
                    <li className={styles.separator}>
                      <ChevronRight />
                    </li>
                  )}
                </React.Fragment>
              );
            })}
          </ul>
        )}
      </Container>
    </div>
  );
};
