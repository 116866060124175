import { UseQueryOptions } from 'react-query';
import axios from 'axios';
import { useShow } from '../Factories';
import { useApi } from '../Providers';
import { THeaders } from '../Providers/ApiProvider/IApiContext';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import { Me } from '../Types';

export async function getMe(hostDomainName?: string, headers: THeaders = {}) {
  const domainName = process.env.PORTAL_DOMAIN || hostDomainName;
  const result = new DataDocument<Me>(
    await axios.get(`${process.env.API_ENDPOINT}/v1/me?cache=${domainName}`, {
      headers: {
        ...(domainName ? { 'X-Portal-Domain': domainName } : {}),
        'X-Feature-Next': '1',
        ...headers,
      },
    })
  );

  return [result.data, result] as const;
}

export function useMe(
  settings?: UseQueryOptions<DataDocument<Me>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.me',
      baseUri: '/v1/me',
      queryParameters: {
        include: [
          'account',
          'entity',
          'portal',
          'subscription',
          'contract',
          'primary_coach',
          'profile',
        ],
      },
    },
    context
  );
  return useShow<Me>(request, settings);
}
