import { UseQueryOptions } from 'react-query';
import { useIndex } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  FaqItem,
  FaqItemFilters,
  FaqItemIncludes,
  FaqItemSort,
  HookParams,
  UUID,
} from '../Types';

interface Props
  extends HookParams<
    typeof FaqItemIncludes,
    typeof FaqItemFilters,
    typeof FaqItemSort
  > {
  portal?: UUID;
}

export function useFaqItems(
  { portal, ...queryParameters }: Props = {},
  settings?: UseQueryOptions<DataDocument<FaqItem[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { portal: defaultPortal },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(portal || defaultPortal),
      baseName: 'v1.portals.faqItems',
      baseUri: `/v1/portals/${portal || defaultPortal}/faq_items`,
      invalidate: ['faq_items', 'faq_item'],
      queryParameters,
    },
    context
  );
  return useIndex<FaqItem>(request, settings);
}
