import { useEffect } from 'react';
import axios, { AxiosRequestHeaders } from 'axios';
import clone from '../../Utils/clone';
import { toSnakeCase } from '../../Utils/transformCase';
import type { THeaders } from './IApiContext';

export default function useAxiosDefaults(baseUrl: string, headers: THeaders) {
  axios.defaults.baseURL = baseUrl;
  axios.defaults.headers.common = Object.keys(
    headers
  ).reduce<AxiosRequestHeaders>(
    (result, key) =>
      headers[key] !== null
        ? { ...result, [key]: headers[key] as string }
        : result,
    {}
  );
  axios.defaults.withCredentials = true;

  useEffect(() => {
    const renameKeysToSnakeCase = (data: object) => clone(data, toSnakeCase);

    if (Array.isArray(axios.defaults.transformRequest)) {
      axios.defaults.transformRequest?.unshift(renameKeysToSnakeCase);
    } else if (axios.defaults.transformRequest) {
      axios.defaults.transformRequest = [
        renameKeysToSnakeCase,
        axios.defaults.transformRequest,
      ];
    } else {
      axios.defaults.transformRequest = [renameKeysToSnakeCase];
    }
  }, []);
}
