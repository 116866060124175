import { IResource } from '../Utils/Resource';
import { UUID } from '../Utils/UUID';
import { ObjectCondition } from './ObjectConditions';

type NodeTypes = 'group';

export interface LearningRouteNode extends IResource<'learning_route_nodes'> {
  nodeType: NodeTypes;
  title: string;
  position: number;
  introduction?: {
    title?: string;
    content: string;
  };
  summary?: string;
  image?: string;
  scheme: Array<{
    id: UUID;
    type: string;
  }>;
  /** Conditions that are linked to this learningObject */
  conditions?: () => ObjectCondition[];
  meta?: {
    isLocked: boolean;
  };
}

export interface LearningRouteNodeCreateInput {
  node_title: string;
  node_type: NodeTypes;
  position: number | null;
  introduction?: {
    title?: string;
    content: string;
  };
  summary?: string;
  image?: string;
  scheme?: Array<{
    id: UUID;
    type: string;
  }>;
}

export interface LearningRouteNodeUpdateInput
  extends Partial<LearningRouteNodeCreateInput> {}

export enum LearningRouteNodeIncludes {}

export enum LearningRouteNodeFilters {}

export enum LearningRouteNodeSorts {}
