/**
 * durationConversion.
 *
 * Converts a duration string to an array with a translation string and interval parameter, which can be spread
 * in the translator function.
 *
 * @example durationConversion('3 months'), becomes ['datetime.timeframe_interval.months', {interval: 3}]
 * @example durationConversion('1 year'), becomes ['datetime.timeframe_interval.year', {interval: 1}]
 * @example Incorrect usage: durationConversion('1 week ago'), becomes ['1 week ago', {interval: 0}]
 */
export function durationConversion(
  duration: string
): [string, { interval: number | undefined }] {
  const durationParts = duration.split(' ');

  // Do nothing if it doesn't match the expected format
  if (durationParts.length > 2) return [duration, { interval: 0 }];

  const interval =
    durationParts.find((part) => Number.isFinite(parseInt(part, 10))) || '';
  const timeframe = durationParts.find((part) =>
    Number.isNaN(parseInt(part, 10))
  );

  return [
    `datetime.timeframe_interval.${timeframe}`,
    { interval: parseInt(interval, 10) },
  ];
}
