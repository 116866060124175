import React, { useCallback, useMemo, useState } from 'react';
import {
  useAnswerGroups,
  useAnswers,
  useCoaches,
  useExams,
  useMe,
  usePortalCourses,
  useSampleSets,
} from '@brainstud/academy-api';
import { toSnakeCase } from '@brainstud/academy-api/Utils/transformCase';
import {
  Accordion,
  Checkbox,
  Chip,
  Dropdown,
  Pagination,
  Panel,
  QuickFilter,
  Search,
} from '@brainstud/ui';
import { Assignment } from '@mui/icons-material';
import classNames from 'classnames/bind';
import {
  Container,
  FilterSidebarButton,
  InlineList,
  Tabs,
  TabsItem,
} from 'Components';
import { IconOverlay } from 'Components/Cards/LearningCard/IconOverlay';
import { useLocalStorage } from 'Hooks';
import { BADGES, OBJECT_META_TAG_ICONS } from 'Hooks/useObjectTags';
import { usePagination } from 'Modules/universal-components';
import { useTranslator } from 'Providers/Translator';
import { SubscriptionCallout } from '../../../Components';
import { withContentErrorBoundary } from '../../Errors/withContentErrorBoundary';
import { AnswersTable } from './Tables/AnswersTable';
import { ExamsTable } from './Tables/ExamsTable';
import { QuizzesTable } from './Tables/QuizzesTable';
import { SampleSetsTable } from './Tables/SampleSetsTable';
import styles from './ReviewIndexView.module.css';

const cx = classNames.bind(styles);

type TTabValues = 'exams' | 'samplesets' | 'assignments' | 'quizzes';

export const ReviewIndexView = withContentErrorBoundary(() => {
  const [t] = useTranslator();
  const [courseFilter, setCourseFilter, removeCourseFilter] = useLocalStorage<
    undefined | string
  >('courseFilter');
  const [coachFilter, setCoachFilter, removeCoachFilter] = useLocalStorage<
    undefined | string
  >('coachFilter');
  const [answerLabelFilters, setAnswerLabelFilters, removeAnswerLabelFilters] =
    useLocalStorage<undefined | string[]>('answerLabelFilters');
  const [activeTab, setActiveTab] = useLocalStorage<TTabValues>(
    'selectedTab',
    'exams'
  );

  // Label filters and quickFilter
  const handleLabelFilter = useCallback(
    (label: string) => {
      const newLabelFilters = answerLabelFilters?.includes(label)
        ? answerLabelFilters.filter((item) => item !== label)
        : [...(answerLabelFilters || []), label];
      setAnswerLabelFilters(
        newLabelFilters.length === 0 ? undefined : newLabelFilters
      );
      if (newLabelFilters.length === 0) {
        removeAnswerLabelFilters();
      }
    },
    [answerLabelFilters, removeAnswerLabelFilters, setAnswerLabelFilters]
  );

  const quickFilterValues = useMemo(() => ['interim_final_assignment'], []);

  const quickFilterIsActive = useMemo(
    () => answerLabelFilters?.includes('interim_final_assignment'),
    [answerLabelFilters]
  );

  const handleQuickFilter = useCallback(() => {
    const remainingFilters = answerLabelFilters?.filter(
      (label) => !quickFilterValues.includes(label)
    );
    const remainingFiltersExists =
      remainingFilters && remainingFilters.length > 0;
    if (quickFilterIsActive) {
      setAnswerLabelFilters(
        remainingFiltersExists ? remainingFilters : undefined
      );
      if (!remainingFiltersExists) {
        removeAnswerLabelFilters();
      }
    } else if (remainingFiltersExists) {
      const combinedFilters: string[] = [
        ...new Set([...remainingFilters, ...quickFilterValues]),
      ];
      setAnswerLabelFilters(combinedFilters);
    } else {
      setAnswerLabelFilters(quickFilterValues);
    }
  }, [
    quickFilterIsActive,
    answerLabelFilters,
    setAnswerLabelFilters,
    quickFilterValues,
    removeAnswerLabelFilters,
  ]);

  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);

  const [me] = useMe();
  const myAccount = useMemo(() => me?.account?.(), [me]);
  const [{ data: coaches }] = useCoaches({
    filter: {
      assistant_coach: myAccount?.id,
    },
  });

  const [{ data: courses }] = usePortalCourses();
  const enrolledCourses = useMemo(
    () => courses.filter((course) => course.enrollment?.()),
    [courses]
  );

  const { page, setPage, limit, sort, setSort } = usePagination();

  const [
    { data: answers },
    { data: answersDocument, isFetching: isFetchingAnswers },
  ] = useAnswers({
    limit,
    page,
    filter: {
      status: 'TURNED_IN',
      without_answer_group: true,
      ...(activeTab === 'assignments'
        ? {
            ...(courseFilter ? { course: courseFilter } : {}),
            ...(coachFilter ? { coach_account: coachFilter } : {}),
            ...(searchQuery ? { 'learning_object.title': searchQuery } : {}),
            ...(answerLabelFilters && answerLabelFilters.length > 0
              ? { 'learning_object.metadata.labels': answerLabelFilters }
              : {}),
          }
        : {}),
    },
    sort: sort as any,
  });

  const [{ data: sampleSets }, { data: sampleSetsDocument }] = useSampleSets({
    limit,
    page,
    filter: {
      status: 'incomplete',
      ...(activeTab === 'samplesets'
        ? {
            ...(courseFilter ? { course: courseFilter } : {}),
            ...(searchQuery ? { query: searchQuery } : {}),
          }
        : {}),
    },
    include: ['enrollment', 'enrollment.course', 'enrollment.account'],
    sort: sort as any,
  });

  const [{ data: exams }, { data: examsDocument }] = useExams({
    limit,
    page,
    include: ['enrollment', 'enrollment.course', 'enrollment.account'],
    filter: {
      current_step: 'HANDED_IN',
      coach_account: me?.account?.().id as string,
      ...(activeTab === 'exams'
        ? {
            ...(courseFilter && activeTab === 'exams'
              ? { course: courseFilter }
              : {}),
            ...(searchQuery ? { query: searchQuery } : {}),
          }
        : {}),
    },
    sort: sort as any,
  });

  const [{ data: answerGroups }, { data: answerGroupsDocument }] =
    useAnswerGroups({
      include: [
        'answers',
        'answers.learning_object',
        'enrollment',
        'enrollment.course',
        'account',
        'learning_subject',
      ],
      filter: {
        status: 'TURNED_IN',
      },
      sort: sort as any,
      page,
      limit,
    });

  const documents = useMemo(
    () => ({
      exams: examsDocument,
      samplesets: sampleSetsDocument,
      assignments: answersDocument,
      quizzes: answerGroupsDocument,
    }),
    [examsDocument, sampleSetsDocument, answersDocument, answerGroupsDocument]
  );

  const handleResetAnswersFilter = useCallback(() => {
    setCourseFilter(undefined);
    setCoachFilter(undefined);
    setAnswerLabelFilters(undefined);
  }, [setAnswerLabelFilters, setCoachFilter, setCourseFilter]);
  const handleChangeTab = useCallback(
    (target: 'exams' | 'samplesets' | 'assignments' | 'quizzes') => {
      setPage(1);
      setActiveTab(target);
    },
    [setActiveTab, setPage]
  );

  const FilterCount = useMemo(() => {
    let count = 0;
    if (courseFilter) {
      count += 1;
    }
    if (coachFilter) {
      count += 1;
    }
    if (searchQuery) {
      count += 1;
    }
    if (activeTab === 'assignments' && answerLabelFilters) {
      count += answerLabelFilters.length;
    }
    return count;
  }, [activeTab, answerLabelFilters, coachFilter, courseFilter, searchQuery]);

  return (
    <Container className={cx(styles.container)}>
      <h1>{t('layouts.navigation.items.review')}</h1>
      <SubscriptionCallout />
      <div className={cx(styles.searchAndFilterWrapper)}>
        <div className={cx(styles['search-filter'])}>
          <FilterSidebarButton
            className={cx(styles.filter)}
            label={t('review.exams.filter.buttontext')}
            title={t('review.exams.filter.title')}
            onReset={handleResetAnswersFilter}
            count={FilterCount}
          >
            <div className={cx(styles['filter-content'])}>
              <Dropdown
                label={t('review.exams.filter.courses')}
                placeholder={t('review.exams.filter.placeholder')}
                onChange={(value) => setCourseFilter(value)}
                block
                searchable
                value={(courseFilter || '') as string}
              >
                {enrolledCourses.map((course) => (
                  <Dropdown.Option key={course.id} value={course.id}>
                    {course.title}
                  </Dropdown.Option>
                ))}
              </Dropdown>
              {myAccount && coaches.length > 0 && activeTab !== 'exams' && (
                <Dropdown
                  value={coachFilter}
                  className={cx(styles.dropdown, styles.courseDropdown)}
                  block
                  label={t('review.filter.select_coach')}
                  placeholder={t('review.filter.all_coaches')}
                  searchable={coaches.length > 5}
                  onChange={(value) => {
                    setCoachFilter(value);
                  }}
                >
                  {[myAccount, ...coaches].map((coach) => (
                    <Dropdown.Option key={coach.id} value={coach.id}>
                      {coach.firstName}
                    </Dropdown.Option>
                  ))}
                </Dropdown>
              )}
              {activeTab === 'assignments' && (
                <Accordion title={t('badges')} quiet open>
                  {BADGES.map((badge) => {
                    const Icon = OBJECT_META_TAG_ICONS[badge];
                    const snakeBadge = toSnakeCase(badge);
                    return (
                      <Checkbox
                        key={snakeBadge}
                        quiet
                        defaultValue={snakeBadge}
                        checked={(answerLabelFilters || []).includes(
                          snakeBadge
                        )}
                        onChange={() => handleLabelFilter(snakeBadge)}
                        className={cx(styles.checkbox)}
                      >
                        <IconOverlay
                          label={t(
                            `views.collection.object.metadata.${snakeBadge}.title`
                          )}
                          icon={Icon}
                          className={cx(badge)}
                        />
                        <span>
                          {t(
                            `views.collection.object.metadata.${snakeBadge}.title`
                          )}
                        </span>
                      </Checkbox>
                    );
                  })}
                </Accordion>
              )}
            </div>
          </FilterSidebarButton>
          <Search
            onChange={(event) => setSearchQuery(event.target.value)}
            placeholder={t('search')}
            defaultValue={searchQuery}
            onClose={() => setSearchQuery(undefined)}
            debounce={200}
          />
        </div>
      </div>

      <div className={cx(styles['chip-wrapper'])}>
        {activeTab === 'assignments' && (
          <>
            <InlineList>
              <QuickFilter
                onClick={handleQuickFilter}
                isActive={quickFilterIsActive}
              >
                <span className={cx(styles.quickFilter)}>
                  <Assignment />
                  {t('views.coach.review.final_assignment')}
                </span>
              </QuickFilter>
            </InlineList>
            {(courseFilter ||
              coachFilter ||
              searchQuery ||
              (answerLabelFilters && answerLabelFilters.length > 0)) && (
              <InlineList>
                <>
                  {courseFilter && (
                    <Chip
                      label={
                        enrolledCourses.find((item) => item.id === courseFilter)
                          ?.title || t('course')
                      }
                      className={cx(styles.chip)}
                      OnClose={() => {
                        setCourseFilter(undefined);
                        removeCourseFilter();
                      }}
                    />
                  )}
                  {coachFilter && (
                    <Chip
                      label={
                        coaches.find((item) => item.id === coachFilter)
                          ?.fullName || t('coach')
                      }
                      className={cx(styles.chip)}
                      OnClose={() => {
                        setCoachFilter(undefined);
                        removeCoachFilter();
                      }}
                    />
                  )}
                  {searchQuery && (
                    <Chip
                      label={`${t('review.searchterm')}: ${searchQuery}`}
                      className={cx(styles.chip)}
                      OnClose={() => setSearchQuery(undefined)}
                    />
                  )}
                  {answerLabelFilters &&
                    answerLabelFilters.length > 0 &&
                    activeTab === 'assignments' && (
                      <>
                        {answerLabelFilters.map((answerLabelFilter) => (
                          <Chip
                            key={answerLabelFilter}
                            label={t(
                              `views.collection.object.metadata.${answerLabelFilter}.title`
                            )}
                            className={cx(styles.chip)}
                            OnClose={() => {
                              handleLabelFilter(answerLabelFilter);
                            }}
                          />
                        ))}
                      </>
                    )}
                </>
              </InlineList>
            )}
          </>
        )}
      </div>

      <div className={cx(styles.tabs)}>
        <Tabs>
          <TabsItem
            active={activeTab === 'exams'}
            onClick={() => handleChangeTab('exams')}
          >
            {`${t('navigation.reviewTabs.exams')} (${examsDocument?.meta?.total || 0})`}
          </TabsItem>
          <TabsItem
            active={activeTab === 'samplesets'}
            onClick={() => handleChangeTab('samplesets')}
          >
            {`${t('navigation.reviewTabs.samplesets')} (${sampleSetsDocument?.meta?.total || 0})`}
          </TabsItem>
          <TabsItem
            active={activeTab === 'assignments'}
            onClick={() => handleChangeTab('assignments')}
          >
            {`${t('navigation.reviewTabs.assignments')} (${answersDocument?.meta?.total || 0})`}
          </TabsItem>
          <TabsItem
            active={activeTab === 'quizzes'}
            onClick={() => handleChangeTab('quizzes')}
          >
            {`${t('navigation.reviewTabs.quizzes')} (${answerGroupsDocument?.meta?.total || 0})`}
          </TabsItem>
        </Tabs>
      </div>
      <div style={{ margin: '2rem 0' }}>
        <Panel>
          {activeTab === 'exams' && (
            <ExamsTable exams={exams} setSort={setSort} />
          )}
          {activeTab === 'samplesets' && (
            <SampleSetsTable sampleSets={sampleSets} setSort={setSort} />
          )}
          {activeTab === 'assignments' && (
            <AnswersTable
              answers={answers}
              loading={isFetchingAnswers}
              setSort={setSort}
            />
          )}
          {activeTab === 'quizzes' && (
            <QuizzesTable answerGroups={answerGroups} />
          )}
        </Panel>
        <Container style={{ marginTop: '4rem' }}>
          <Pagination
            totalPages={documents[activeTab || 'exams']?.meta?.lastPage}
            totalResults={`${t('components.pagination.totalResults')} ${documents[activeTab || 'exams']?.meta?.total}`}
            labels={{
              itemsPerPage: t('components.pagination.resultsPerPage'),
              results: t('students.totalResults'),
            }}
          />
        </Container>
      </div>
    </Container>
  );
});
