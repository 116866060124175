export type TRGBValueArray = [number, number, number];

/**
 * Parses a rgb string to RGB values in an array
 */
export function rgbToValues(rgb: string): TRGBValueArray {
  const rgbaRegex =
    /^(?:rgba?\s?\()?(\s?[0-9]{1,3}),\s?([0-9]{1,3}),\s?([0-9]{1,3})(?:,\s?[\d.]{1,3})?\)?$/i;
  const result = rgbaRegex.exec(rgb);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : [0, 0, 0];
}

/**
 * Calculates the luminance of an RGB color code
 */
export function getColorLuminance(rgbColorArray: TRGBValueArray) {
  const a = rgbColorArray.map((value) => {
    let v = parseInt(value.toString(), 10);
    v /= 255;
    return v <= 0.03928
      ? v / 12.92
      : // eslint-disable-next-line no-restricted-properties
        ((v + 0.055) / 1.055) ** 2.4;
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

/**
 * Calculates the contrast of 2 colors based on luminance
 */
export function getColorContrast(
  colorOne: TRGBValueArray,
  colorTwo: TRGBValueArray
) {
  const result =
    (getColorLuminance(colorOne) + 0.05) / (getColorLuminance(colorTwo) + 0.05);
  return result < 1 ? 1 / result : result;
}

/**
 * Parses a hex string to RGB values in an array
 */
export function hexToRgbValues(hex: string): TRGBValueArray {
  if (hex.length <= 4) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    // eslint-disable-next-line no-param-reassign
    hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
  }
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : [0, 0, 0];
}

/**
 * Strips the typical css rgb(a) value to value only.
 * For example: rgba(255, 255, 255, 0.3) -> 255,255,255
 */
export function convertCSStoRGBValues(rgb: string | TRGBValueArray): string {
  if (Array.isArray(rgb)) {
    return rgb.join(',');
  }
  const rgbaRegex =
    /^rgba?\s?\((\s?[0-9]{1,3}),\s?([0-9]{1,3}),\s?([0-9]{1,3})(?:,\s?[\d.]{1,3})?\)$/i;
  const result = rgbaRegex.exec(rgb);
  return result ? [result[1], result[2], result[3]].join(', ') : rgb;
}
