import { createContext } from 'react';

type THandler<EventParams extends any[]> = (...data: EventParams) => void;
type TUnsubscribeFn = () => void;

export type TEventsObject = Record<string, any[]>;

export interface ISystemEventContext<TEvents extends TEventsObject = any> {
  subscribe: <EventName extends keyof TEvents>(
    name: EventName,
    handler: THandler<TEvents[EventName]>
  ) => TUnsubscribeFn;
  emit: <EventName extends keyof TEvents>(
    name: EventName,
    ...data: TEvents[EventName]
  ) => void;
}

export const SystemEventContext = createContext<null | ISystemEventContext>(
  null
);
