import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  InvoiceProfile,
  InvoiceProfileCreate,
  InvoiceProfileFilters,
  InvoiceProfileIncludes,
  InvoiceProfileSort,
  InvoiceProfileUpdate,
  UUID,
} from '../Types';

interface IInvoiceProfileProps
  extends HookParams<
    typeof InvoiceProfileIncludes,
    InvoiceProfileFilters,
    typeof InvoiceProfileSort
  > {
  invoiceProfile?: UUID;
}

export function useInvoiceProfile(
  { invoiceProfile, ...queryParameters }: IInvoiceProfileProps = {},
  settings?: UseQueryOptions<DataDocument<InvoiceProfile>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { invoiceProfile: defaultInvoiceProfile },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(invoiceProfile || defaultInvoiceProfile),
      baseName: 'v1.invoice_profile',
      baseUri: '/v1/invoice_profiles',
      invalidate: ['invoice_profile', 'invoice_profiles'],
      uri: `/${invoiceProfile}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    InvoiceProfile,
    InvoiceProfileCreate,
    InvoiceProfileUpdate
  >(request, settings);
}

export function useInvoiceProfiles(
  { ...queryParameters }: Omit<IInvoiceProfileProps, 'invoiceProfile'> = {},
  settings?: UseQueryOptions<DataDocument<InvoiceProfile[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.invoice_profiles',
      baseUri: '/v1/invoice_profiles',
      invalidate: ['invoice_profile', 'invoice_profiles'],
      queryParameters,
    },
    context
  );
  return useIndexCR<InvoiceProfile, InvoiceProfileCreate>(request, settings);
}
