import {
  ADMIN_ROUTES,
  COACH_ROUTES,
  GUEST_ROUTES,
  STUDENT_ROUTES,
} from 'Config/environment';
import { createReactReducer } from 'Utils/createReactReducer';
import ACTIONS from './EnvironmentActions';

export type TEnvironments = 'guest' | 'student' | 'coach' | 'admin';

export interface IEnvironmentState {
  environment?: TEnvironments;
  isGuestEnvironment: boolean;
  isStudentEnvironment: boolean;
  isCoachEnvironment: boolean;
  isAdminEnvironment: boolean;
  prevEnvironment?: string;
}

type TSetEnvironmentAction = {
  type: typeof ACTIONS.SET_ENVIRONMENT;
  payload: {
    environment: TEnvironments;
  };
};

type TAutoSetEnvironmentAction = {
  type: typeof ACTIONS.AUTO_SET_ENVIRONMENT;
  payload: {
    pathname: string;
    role?: TEnvironments;
  };
};

export type TEnvironmentActions =
  | TSetEnvironmentAction
  | TAutoSetEnvironmentAction;

export const InitialEnvironmentState: IEnvironmentState = {
  isGuestEnvironment: false,
  isStudentEnvironment: false,
  isCoachEnvironment: false,
  isAdminEnvironment: false,
};

export const EnvironmentReducer = createReactReducer<
  IEnvironmentState,
  TEnvironmentActions
>({
  [ACTIONS.SET_ENVIRONMENT]: ({ payload }) => ({
    environment: payload.environment,
    isStudentEnvironment: payload.environment === 'student',
    isCoachEnvironment: payload.environment === 'coach',
    isAdminEnvironment: payload.environment === 'admin',
  }),
  [ACTIONS.AUTO_SET_ENVIRONMENT]: (
    { payload },
    { environment, prevEnvironment }
  ) => {
    const { pathname, role } = payload;

    const env =
      (ADMIN_ROUTES.some((route) => pathname.startsWith(route)) && 'admin') ||
      (COACH_ROUTES.some((route) => pathname.startsWith(route)) && 'coach') ||
      (STUDENT_ROUTES.some((route) => pathname.startsWith(route)) &&
        'student') ||
      (GUEST_ROUTES.some((route) => pathname.startsWith(route)) && 'guest') ||
      environment ||
      role;

    const isNewEnvironment = env !== environment;

    return {
      environment: env,
      isGuestEnvironment: env === 'guest',
      isStudentEnvironment: env === 'student',
      isCoachEnvironment: env === 'coach',
      isAdminEnvironment: env === 'admin',
      prevEnvironment: isNewEnvironment ? environment : prevEnvironment,
    };
  },
});
