import { DateTimeString } from '../Utils/DateTimeString';
import { IResource } from '../Utils/Resource';
import { Catalog } from './Catalog';

export interface SubEntity extends IResource<'sub_entities'> {
  name: string;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  catalog?: () => Catalog;
  reference: string;
  type: string;
}

export interface SubEntityCreateInput {
  type: string;
  reference: string;
  order: number;
}

export enum SubEntityIncludes {
  catalog,
  catalogCount,
  'catalog.products',
  'catalog.catalogProducts',
}

export interface SubEntityFilters {}

export enum SubEntitySorts {}
