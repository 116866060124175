import { DateTimeString } from '../Utils/DateTimeString';
import { IResource } from '../Utils/Resource';
import { Account } from './Account';
import { Answer } from './Answer';
import { Exam } from './Exam';
import { LearningObject } from './LearningObject';

export interface ExamSampleAnswer extends IResource {
  learningObjectTitle: string;
  assessmentPassed: boolean;
  assessmentFeedback: string;
  iteration: number;
  answer?: () => Answer;
  exam?: () => Exam;
  learningObject: () => LearningObject;
  assessorAccount?: () => Pick<Account, 'firstName' | 'lastName' | 'fullName'>;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
}

export interface ExamSampleAnswerCreateInput {
  action: string;
}

export interface ExamSampleAnswerUpdateInput {
  status: 'ACCEPTED' | 'REJECTED';
  feedback: string;
}

export enum ExamSampleAnswerIncludes {
  answer,
  exam,
  learning_object,
  assessor_account,
}

export enum ExamSampleAnswerFilters {}

export enum ExamSampleAnswerSorts {}
