import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  SubscriptionOption,
  SubscriptionOptionCreateInput,
  SubscriptionOptionFilters,
  SubscriptionOptionIncludes,
  SubscriptionOptionSorts,
  SubscriptionOptionUpdateInput,
  UUID,
} from '../Types';

interface ISubscriptionOptionProps
  extends HookParams<
    typeof SubscriptionOptionIncludes,
    SubscriptionOptionFilters,
    typeof SubscriptionOptionSorts
  > {
  subscriptionOption?: UUID;
}

export function useSubscriptionOption(
  { subscriptionOption, ...queryParameters }: ISubscriptionOptionProps = {},
  settings?: UseQueryOptions<DataDocument<SubscriptionOption>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { subscriptionOption: defaultSubscriptionOption },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(subscriptionOption || defaultSubscriptionOption),
      baseName: 'v1.subscription_options',
      baseUri: '/v1/subscription_options',
      invalidate: ['v1.subscription_options'],
      uri: `/${subscriptionOption || defaultSubscriptionOption}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    SubscriptionOption,
    SubscriptionOptionCreateInput,
    SubscriptionOptionUpdateInput
  >(request, settings);
}

export function useSubscriptionOptions(
  {
    ...queryParameters
  }: Omit<ISubscriptionOptionProps, 'subscriptionOption'> = {},
  settings?: UseQueryOptions<DataDocument<SubscriptionOption[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.subscription_options',
      baseUri: '/v1/subscription_options',
      invalidate: ['v1.subscription_options'],
      queryParameters,
    },
    context
  );

  return useIndexCR<SubscriptionOption, SubscriptionOptionCreateInput>(
    request,
    settings
  );
}
