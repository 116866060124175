import { UseQueryOptions } from 'react-query';
import { useIndex } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  Answer,
  AnswerFeedback,
  Comment,
  IResource,
  Shareable,
  UUID,
} from '../Types';

interface Props {
  enrollment: UUID;
  learningObject: UUID;
}

export interface IActivity extends IResource<'activities'> {
  activityAt: string;
  answer?: () => Answer & { resourceType: 'answers' };
  answerFeedback?: () => AnswerFeedback & { resourceType: 'answer_feedback' };
  comment?: () => Comment & { resourceType: 'comments' };
  shareable?: () => Shareable & { resourceType: 'shareables' };
  type: TActivityType;
}

export type TActivityType =
  | 'answer_feedback'
  | 'answer'
  | 'comment'
  | 'shareable';

/**
 * useEnrollmentLearningObjectActivity.
 *
 * Gets an activity 'timeline' for a learning object on which a participant is enrolled in.
 * Things like turned in date, comments and grading are fetched here.
 */
export function useEnrollmentLearningObjectActivity(
  { enrollment, learningObject, ...queryParameters }: Props,
  settings?: UseQueryOptions<DataDocument<IActivity[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.enrollment.learningObject.activities',
      baseUri: `/v1/enrollments/${enrollment}/learning_objects/${learningObject}/activities`,
      queryParameters,
    },
    context
  );
  return useIndex<IActivity>(request, settings);
}
