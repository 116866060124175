import { createContext, Dispatch } from 'react';
import { TViewSettings, TViewSettingsActions } from './ViewSettingsActions';
import { IViewSettingsState } from './ViewSettingsReducer';

export interface IViewSettingsContext<T extends TViewSettings>
  extends Omit<IViewSettingsState<T>, 'isLoaded' | 'isDirty'> {
  dispatch: Dispatch<TViewSettingsActions<T>>;
}

export const ViewSettingsContext =
  createContext<null | IViewSettingsContext<TViewSettings>>(null);
