import React from 'react';
import { Button } from '@brainstud/ui';
import { ChevronLeft } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import styles from './BackButton.module.css';

const cx = classNames.bind(styles);

type TProps = {
  returnTo?: string;
};

export const BackButton = ({ returnTo }: TProps) => {
  const [t] = useTranslator();

  return (
    <Button
      quiet
      link
      type="button"
      title={t('go_back')}
      href={returnTo || window.location.origin}
      className={cx('base', 'back-button')}
    >
      <ChevronLeft style={{ fontSize: '3rem' }} />
      <span>{t('go_back')}</span>
    </Button>
  );
};
