import React, { useCallback, useEffect, useState } from 'react';
import { Button, Textarea } from '@brainstud/ui';
import { useForm } from '@brainstud/universal-components';
import { AddCircle, DeleteForever } from '@mui/icons-material';
import { useBlock } from 'Hooks/useBlock';
import { useTranslator } from 'Providers/Translator';
import { v4 as uuidv4 } from 'uuid';
import { toName } from '../../../Utils/toName';
import { ICardsWidgetQuestion } from './Types';
import styles from './CardsWidgetQuestion.module.css';

type Props = Omit<ICardsWidgetQuestion, 'type'>;

export const CardsWidgetQuestion = ({
  id,
  question,
  label,
  cardStyles,
  style,
}: Props) => {
  const [t] = useTranslator();
  const name = toName(id);
  const {
    values,
    disabled,
    fields: formFields,
    subscribe,
  } = useForm<{ [key: string]: string[] }>();

  const [cardIds, setCardIds] = useState<string[]>(
    formFields
      .filter((field) => field.name === name && field.value !== '')
      .map((field) => field.id)
  );

  const handleAddCard = useCallback(() => {
    setCardIds((existingIds) => [...existingIds, toName(uuidv4())]);
  }, []);

  const handleDeleteCard = useCallback((cardId) => {
    setCardIds((existingIds) =>
      existingIds.filter((existingId) => existingId !== cardId)
    );
  }, []);

  const [restored, setRestored] = useState(false);
  useEffect(() => {
    if (!restored) {
      const unsubscribe = subscribe('restore', (formState) => {
        if (formState.fields) {
          setCardIds(
            formState.fields
              .filter((field) => field.name === name && field.value !== '')
              .map((field) => field.id)
          );
          setRestored(true);
        }
      });
      return () => unsubscribe();
    }
  }, [name, subscribe, restored]);

  useEffect(() => {
    const unsubscribe = subscribe('reset', () => {
      setCardIds([]);
    });
    return () => unsubscribe();
  }, [subscribe]);

  const inputFields = values[name];
  useBlock({
    id,
    touched: inputFields && inputFields.filter((val) => val).length > 0,
    open: true,
    fields: cardIds,
  });

  return (
    <div className={styles.base} style={style}>
      <p>{question}</p>
      <div className={styles.cardsContainer}>
        {cardIds.map((fieldName) => (
          <div key={fieldName} className={styles.card} style={cardStyles}>
            <Textarea
              id={fieldName}
              name={`${name}[]`}
              placeholder={t('course-blocks.blocks.cards_widget.start_typing')}
            />
            {!disabled && (
              <Button
                type="button"
                className={styles.deleteCard}
                link
                onClick={() => handleDeleteCard(fieldName)}
              >
                <DeleteForever />
              </Button>
            )}
          </div>
        ))}
        {!disabled && (
          <Button
            type="button"
            className={styles.addCard}
            style={cardStyles}
            onClick={handleAddCard}
          >
            <AddCircle />
            <span>{t('course-blocks.blocks.cards_widget.add', { label })}</span>
          </Button>
        )}
      </div>
    </div>
  );
};
