import React, { Ref } from 'react';
import classNames from 'classnames/bind';
import { Link } from 'Components';
import { ButtonProps } from './ButtonProps';
import styles from './Button.module.css';

const cx = classNames.bind(styles);
/**
 * Button.
 *
 * Shows a button or anchor tag suitable for a standalone clickable element on the page
 *
 * When a `href` or `to` attribute is provided, it will render an anchor tag, otherwise it will
 * render a button. The `link` attribute does not affect the type of HTMLElement, but only affects
 * the styling of the button.
 */
export const Button = React.forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  ButtonProps
>(
  (
    {
      href,
      alternative,
      block,
      className,
      disabled,
      download,
      error,
      file,
      info,
      link,
      loading,
      onClick,
      outline,
      quiet,
      round,
      rel,
      small,
      success,
      style,
      form,
      target,
      to,
      title,
      transparent,
      warning,
      white,
      type = 'submit',
      onMouseOver,
      onFocus,
      children,
    },
    ref
  ) => {
    const isLink = href || file || download || to;
    const classList = cx(
      styles.base,
      {
        'is-link': link,
        'is-quiet': quiet,
        'is-button': !link,
        'is-round': round,
        'is-error': error,
        'is-info': info,
        'is-warning': warning,
        'is-alternative': alternative,
        'is-success': success,
        'is-white': white,
        'is-outline': outline,
        'is-loading': loading,
        'is-transparent': transparent,
        'is-disabled': disabled,
        'is-block': block,
        'is-small': small,
      },
      className,
      'ui__button'
    );

    return (
      <>
        {isLink && (
          <Link
            href={href || to || '#'}
            download={file || download}
            target={target}
            style={style}
            rel={rel}
            ref={ref as Ref<HTMLAnchorElement>}
            title={title}
            aria-label={title}
            onClick={
              !disabled
                ? onClick
                : (e) => {
                    e.preventDefault();
                    return false;
                  }
            }
            onMouseOver={onMouseOver}
            onFocus={onFocus}
            className={classList}
          >
            {children}
          </Link>
        )}
        {!isLink && (
          <button
            // eslint-disable-next-line react/button-has-type
            type={type}
            title={title}
            form={form}
            ref={ref as Ref<HTMLButtonElement>}
            aria-label={title}
            disabled={disabled}
            style={style}
            onClick={onClick}
            onMouseOver={onMouseOver}
            onFocus={onFocus}
            className={classList}
          >
            {children}
          </button>
        )}
      </>
    );
  }
);
