import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Course,
  EducationProfile,
  Enrollment,
  usePortalCourses,
  useProductCategories,
  UUID,
} from '@brainstud/academy-api';
import { useSessionStorage } from 'Hooks';
import queryString from 'query-string';
import CourseListContext, {
  TSortAttribute,
  TSortOrder,
  TViewType,
} from './CourseListContext';

type CourseListProviderProps = {
  uniqueStorageId: string;
  onCategoryChange?: (category: UUID | null) => void;
  courses?: Course[];
  enrollments?: Enrollment[];
  category?: UUID;
  quickStart?: boolean;
  children: React.ReactNode;
};

type StoredState = {
  sortAttribute?: TSortAttribute;
  sortOrder?: TSortOrder;
  categoryFilter?: UUID | null;
  profileFilter?: UUID[];
  sbuFilters?: string[];
  viewType?: TViewType;
};

const courseHasProfile = (course: Course, profiles: UUID[]) =>
  profiles.some((profile) =>
    course
      ?.educationProfiles?.()
      .some((courseProfile) => courseProfile.id === profile)
  );

const CourseListProvider = ({
  uniqueStorageId,
  courses: courseList,
  enrollments,
  quickStart = true,
  category: defaultCategory,
  children,
  onCategoryChange,
}: CourseListProviderProps) => {
  // Search
  const [searchQuery, setSearchQuery] = useState<string>('');

  // View Type
  const [viewType, setViewType] = useState<TViewType>('grid');

  // Sorting
  const [sortAttribute, setSortAttribute] = useState<TSortAttribute>('');
  const [sortOrder, setSortOrder] = useState<TSortOrder>('ascending');

  // Filters
  const [categoryFilter, setCategoryFilter] = useState<UUID | null>(
    defaultCategory || null
  );
  const [sbuFilters, setSbuFilters] = useState<string[]>([]);
  const [profileFilter, setProfileFilter] = useState<UUID[]>([]);
  const toggleProfile = useCallback((profile: UUID) => {
    setProfileFilter((prevProfiles) =>
      prevProfiles?.includes(profile)
        ? prevProfiles.filter((i) => i !== profile) // remove item
        : [...prevProfiles, profile]
    ); // add item
  }, []);

  // Profiles
  const [, { data: document }] = usePortalCourses();
  const educationProfiles = useMemo(
    () => document?.findByType<EducationProfile>('education_profiles') || [],
    [document]
  );

  useEffect(() => {
    if (onCategoryChange) onCategoryChange(categoryFilter);
  }, [categoryFilter, onCategoryChange]);

  // Loading data to and from localStorage
  const [localStorage, setLocalStorage] = useSessionStorage<StoredState>(
    uniqueStorageId,
    {}
  );
  const [restored, setRestored] = useState(false);
  useEffect(() => {
    if (restored) {
      setLocalStorage({
        sortAttribute,
        sortOrder,
        categoryFilter,
        profileFilter,
        sbuFilters,
        viewType,
      });
    }
  }, [
    restored,
    setLocalStorage,
    sortOrder,
    sortAttribute,
    categoryFilter,
    profileFilter,
    sbuFilters,
    viewType,
  ]);

  const [{ data: allCategories }] = useProductCategories({
    include: ['product_sub_categories'],
  });

  const categories = useMemo(
    () =>
      allCategories.filter((category) =>
        courseList?.some(
          (course) => course.productCategory?.()?.id === category.id
        )
      ),
    [allCategories, courseList]
  );

  const courseCount = useMemo(
    () =>
      courseList?.reduce<{ [key: string]: number }>((categoryList, course) => {
        const categoryId = course.productCategory?.()?.id;
        const subCategoryId = course.productSubCategory?.()?.id;
        const totalCategories = categoryId
          ? {
              ...categoryList,
              [categoryId]: (categoryList[categoryId] || 0) + 1,
            }
          : categoryList;
        return subCategoryId
          ? {
              ...totalCategories,
              [subCategoryId]: (totalCategories[subCategoryId] || 0) + 1,
            }
          : totalCategories;
      }, {}) || {},
    [courseList]
  );

  const { category: categoryLabel } = queryString.parse(
    window.location.search
  ) as { category?: string };
  const [queryStringConfigLoaded, setQueryStringConfigLoaded] = useState(false);
  useEffect(() => {
    if (
      !queryStringConfigLoaded &&
      restored &&
      Object.values(categories).length > 0
    ) {
      setQueryStringConfigLoaded(true);
      const lowerCaseCategoryLabel = categoryLabel?.toLowerCase();
      if (lowerCaseCategoryLabel) {
        const existingCategory = categories.filter(
          (item) => item.label.toLowerCase() === lowerCaseCategoryLabel
        );
        setCategoryFilter(existingCategory[0]?.id);
      }
    }
  }, [categories, categoryLabel, restored, queryStringConfigLoaded]);

  useEffect(() => {
    if (restored || !localStorage) return;

    if (localStorage.sortAttribute)
      setSortAttribute(localStorage.sortAttribute);
    if (localStorage.sortOrder) setSortOrder(localStorage.sortOrder);
    if (localStorage.categoryFilter)
      setCategoryFilter(localStorage.categoryFilter);
    if (localStorage.profileFilter)
      setProfileFilter(localStorage.profileFilter);
    if (localStorage.sbuFilters) setSbuFilters(localStorage.sbuFilters);
    if (localStorage.viewType) setViewType(localStorage.viewType);
    setRestored(true);
  }, [categoryFilter, restored, localStorage, categoryLabel]);

  // Resetting all filters
  const reset = useCallback(() => {
    setSortAttribute('createdAt');
    setSortOrder('ascending');
    setSbuFilters([]);
    setProfileFilter([]);
  }, []);

  // Creating the course list
  const courses = useMemo(
    () =>
      courseList
        ?.filter((course) => {
          if (searchQuery.length > 1) {
            return course.title
              .toLowerCase()
              .includes(searchQuery.toLowerCase());
          }

          return (
            (!categoryFilter ||
              categoryFilter === course.productCategory?.()?.id ||
              // @ts-ignore Can be removed when refactoring to API based filtering and searching
              categoryFilter === course.productSubCategory?.()?.id) &&
            (!profileFilter.length ||
              courseHasProfile(course, profileFilter)) &&
            (!sbuFilters.length ||
              sbuFilters.includes(
                course?.metadata?.informationLines?.sbu || 'undefined'
              ))
          );
        })
        .sort((a, b) => {
          if (sortAttribute === '') return 1;
          const courseAttributeA =
            sortAttribute === 'sbu'
              ? a?.metadata?.informationLines?.sbu
              : a[sortAttribute];
          const courseAttributeB =
            sortAttribute === 'sbu'
              ? b?.metadata?.informationLines?.sbu
              : b[sortAttribute];
          if (sortOrder === 'ascending') {
            return courseAttributeA! < courseAttributeB! ? -1 : 1;
          }
          return courseAttributeA! > courseAttributeB! ? -1 : 1;
        }),
    [
      courseList,
      searchQuery,
      categoryFilter,
      profileFilter,
      sbuFilters,
      sortAttribute,
      sortOrder,
    ]
  );

  const toggleSbuFilter = useCallback((sbuValue: null | string) => {
    if (!sbuValue) {
      setSbuFilters([]);
    } else {
      setSbuFilters((prevState) => {
        if (prevState.includes(sbuValue)) {
          return prevState.filter((item) => item !== sbuValue);
        }
        return [sbuValue, ...prevState];
      });
    }
  }, []);

  const state = useMemo(
    () => ({
      catalog: courseList,
      categories: Object.values(categories),
      courses,
      enrollments,
      courseCount,
      educationProfiles,
      quickStart,
      setCategoryFilter,
      toggleSbuFilter,
      toggleProfile,
      setSortAttribute,
      setSortOrder,
      searchQuery,
      setSearchQuery,
      sortOrder,
      sortAttribute,
      setViewType,
      viewType,
      filters: {
        category: categoryFilter,
        profiles: profileFilter,
        sbu: sbuFilters,
      },
      reset,
    }),
    [
      courseList,
      courses,
      enrollments,
      courseCount,
      quickStart,
      toggleProfile,
      categoryFilter,
      profileFilter,
      sbuFilters,
      toggleSbuFilter,
      sortOrder,
      sortAttribute,
      viewType,
      reset,
      searchQuery,
      categories,
      educationProfiles,
    ]
  );
  return (
    <CourseListContext.Provider value={state}>
      {children}
    </CourseListContext.Provider>
  );
};

export default CourseListProvider;
