import { useMemo } from 'react';
import { useAnswer } from '@brainstud/academy-api';
import { Callout, Panel } from '@brainstud/ui';
import { Container, Loading } from 'Components';
import { useTranslator } from 'Providers/Translator';
import { useAnswerReview } from '../../../Providers';
import { withContentErrorBoundary } from '../../Errors/withContentErrorBoundary';
import styles from './ReviewCoreTasksView.module.css';

/**
 * View that displays the core tasks of the learning object for which the answer is reviewed
 */
export const ReviewCoreTasksView = withContentErrorBoundary(() => {
  const [t] = useTranslator();
  const { answerId } = useAnswerReview() || {};
  const [{ data: answer }, { isLoading }] = useAnswer({
    answer: answerId,
    include: [
      'account',
      'learning_object',
      'learning_object_variety',
      'learning_object_variety.dossier_workprocesses',
      'learning_object_variety.dossier_workprocess_behaviours',
      'learning_object_variety.dossier_knowledge_skills',
    ],
  });

  const processes = useMemo(
    () => answer?.learningObjectVariety?.().dossierWorkprocesses?.(),
    [answer]
  );

  if (isLoading) return <Loading />;

  return (
    <Container className={styles.base} style={{ paddingTop: '4rem' }}>
      {processes?.length ? (
        <Panel className={styles.panel}>
          {processes.map((process) => (
            <div key={process.id}>
              <h4>{`${process.code}: ${process.title}`}</h4>
              <p>{process.description}</p>
            </div>
          ))}
        </Panel>
      ) : (
        <Callout info>{t('review.noWorkprocesses')}</Callout>
      )}
    </Container>
  );
});
