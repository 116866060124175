import { useContext } from 'react';
import { IProfileContext, ProfileContext } from './ProfileContext';

/**
 * useProfileProvider.
 *
 * Gives direct access to the profile that is fetched based on the URL and or the current logged in user.
 *
 * @param nullable When true, this hook returns null when no context is found.
 */
export function useProfileProvider(nullable?: false): IProfileContext;
export function useProfileProvider(nullable?: true): null | IProfileContext;
export function useProfileProvider(nullable = false) {
  const context = useContext(ProfileContext);

  if (!context && !nullable) {
    throw new Error('Please, initiate the ProfileProvider first!');
  }

  return context;
}
