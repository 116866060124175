import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Button } from '@brainstud/ui';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import styles from './Carousel.module.css';

const cx = classNames.bind(styles);

type Props = {
  children?: ReactNode;
  slidesPerView?: {
    desktop?: number;
    tablet?: number;
    mobile?: number;
  };
};
/**
 * Carousel shows content in the form of a carousel/slider
 * The component is responsive, and will adjust navigation on basis of breakpoints
 * @param children the actual slides which you pass as children
 * @param SlidesPerView give you the possibility to adjust how many slides are shown for desktop, tablet and mobile
 */
export const Carousel = ({
  children,
  slidesPerView = { desktop: 4, tablet: 3, mobile: 2 },
}: Props) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [dynamicSlidesPerView, setDynamicSlidesPerView] = useState(
    slidesPerView.desktop || 4
  );

  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>(
    {
      initial: 0,
      loop: false,
      mode: 'free-snap',
      renderMode: 'performance',
      slides: {
        perView: slidesPerView?.desktop,
        spacing: 10,
      },
      breakpoints: {
        '(max-width: 1000px)': {
          slides: { perView: slidesPerView?.tablet, spacing: 5 },
        },
        '(max-width: 640px)': {
          slides: { perView: slidesPerView?.mobile, spacing: 5 },
        },
      },
      slideChanged(s) {
        setCurrentSlide(s.track.details.rel);
      },
      optionsChanged(s) {
        const slides = s.options.slides as { perView: number; spacing: number };
        if (slides.perView) {
          setDynamicSlidesPerView(slides.perView);
        }
      },
      created() {
        setLoaded(true);
      },
    },
    []
  );

  const numberOfSlides = useMemo(
    () => React.Children.count(children),
    [children]
  );

  useEffect(() => {
    slider?.current?.update();
  }, [children]);

  const carouselBullets = useMemo(() => {
    if (loaded && numberOfSlides > 0) {
      return [...Array(numberOfSlides).keys()].filter((idx) => {
        const index = idx;
        const maxId = numberOfSlides - 1;
        if (
          maxId - index + 1 === dynamicSlidesPerView ||
          (index % dynamicSlidesPerView === 0 &&
            index + dynamicSlidesPerView <= maxId)
        ) {
          return true;
        }
        return index === 0;
      });
    }
  }, [loaded, numberOfSlides, dynamicSlidesPerView]);

  const calculateSlideGroups = useCallback(
    (bullets: number[]): readonly [number, number][] =>
      // eslint-disable-next-line implicit-arrow-linebreak
      bullets.reduce(
        (groups: [number, number][], currentNumber: number, index: number) => {
          if (index < bullets.length - 1) {
            const group: [number, number] = [currentNumber, bullets[index + 1]];
            groups.push(group);
          }
          return groups;
        },
        []
      ),
    []
  );

  const slideGroups = useMemo(() => {
    if (carouselBullets) {
      const maxId = numberOfSlides - 1;
      return calculateSlideGroups([...carouselBullets, maxId]);
    }
  }, [carouselBullets, calculateSlideGroups, numberOfSlides]);

  const isSlideGroupInView = useCallback(
    (index: number) =>
      !!slideGroups?.some(
        ([beginBoundary, endBoundary]) =>
          index >= beginBoundary &&
          index < endBoundary &&
          currentSlide >= beginBoundary &&
          currentSlide < endBoundary
      ),
    [currentSlide, slideGroups]
  );

  return (
    <>
      <div className={cx(styles.base)}>
        <Button
          onClick={() => slider.current?.prev()}
          className={cx(styles.navigationButton)}
          disabled={currentSlide === 0}
          type="button"
          quiet
          link
        >
          <ChevronLeft viewBox="8 6 7.41 12" />
        </Button>
        {children && (
          <section ref={sliderRef} className={cx(styles.slider, 'keen-slider')}>
            {React.Children.map(children, (child: ReactNode) => (
              <div className={cx(styles.slide, 'keen-slider__slide')}>
                {child}
              </div>
            ))}
          </section>
        )}
        <Button
          onClick={() => slider.current?.next()}
          className={cx(styles.navigationButton)}
          type="button"
          disabled={currentSlide + dynamicSlidesPerView === numberOfSlides}
          quiet
          link
        >
          <ChevronRight viewBox="8 6 7.41 12" />
        </Button>
      </div>
      {slider.current && carouselBullets && (
        <div className="dots">
          {carouselBullets.map((idx) => (
            <button
              key={idx}
              className={cx(
                styles.dot,
                isSlideGroupInView(idx) ? 'active' : ''
              )}
              type="button"
              aria-label="bullets"
              onClick={() => {
                slider.current?.moveToIdx(idx);
              }}
            />
          ))}
        </div>
      )}
    </>
  );
};
