import React from 'react';
import classNames from 'classnames';
import { Link } from 'Components';
import { useAccessProvider } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import styles from './PanelHeader.module.css';

const cx = classNames.bind(styles);

interface IProps {
  title: string;
  to: string;
  classname?: string;
}

export const PanelHeader = ({ title, to, classname }: IProps) => {
  const [t] = useTranslator();

  const { hasAccess } = useAccessProvider();
  return (
    <div className={cx(styles.base, classname)}>
      <span className={cx(styles.title)}>{title}</span>
      <Link if={hasAccess} href={to}>
        <strong data-cy="view_all">{t('view_all')}</strong>
      </Link>
    </div>
  );
};
