import type { TMetaItem } from '@brainstud/academy-api/Types/Utils/HookParams';

/**
 * constructMetaObject.
 *
 * Converts a meta-object to a single string.
 */
function constructMetaObject(meta: TMetaItem): string {
  return typeof meta === 'string'
    ? meta
    : Object.entries(meta)
        .map(
          ([key, value]) =>
            `meta[${key}]=${Array.isArray(value) ? value.join(',') : value}`
        )
        .join('&');
}

function constructMeta(meta: TMetaItem | Array<TMetaItem>) {
  if (Array.isArray(meta)) {
    const metaObjects = meta
      .filter((item) => typeof item !== 'string')
      .map(constructMetaObject);
    const metaStrings = meta
      .filter((item) => typeof item === 'string')
      .map(constructMetaObject);
    const metaString =
      metaStrings.length > 0 ? `meta=${metaStrings.join(',')}` : null;

    return `${[metaString, metaObjects.join('&')].filter(Boolean).join('&')}`;
  }
  return constructMetaObject(meta);
}

type TModifiers = {
  includes?: string[];
  filter?: {
    [key: string]: string | boolean | number | undefined | null | string[];
  };
  fields?: {
    [key: string]: string[];
  };
  sort?: string | string[];
  meta?: TMetaItem | Array<TMetaItem>;
  token?: string;
  shared?: boolean;
  page?: number;
  limit?: number;
  locale?: string;
  requestAllTranslations?: boolean;
  showStoredFilesCount?: boolean;
};

/**
 * constructUrl.
 *
 * A simple method to combine the base URL with the modifiers of the url.
 */
export function constructUrl(baseUri: string, mods: TModifiers) {
  const modString = [
    mods.includes && mods.includes.length > 0
      ? `include=${mods.includes.sort().join(',')}`
      : '',
    mods.filter &&
      Object.entries(mods.filter).reduce<string[]>(
        (output, [key, value]) => [
          ...output,
          ...(value !== null && value !== undefined
            ? [
                `filter[${key}]=${Array.isArray(value) ? value.join(',') : value}`,
              ]
            : []),
        ],
        []
      ),
    mods.fields &&
      Object.entries(mods.fields).reduce<string[]>(
        (output, [key, value]) => [
          ...output,
          ...(value.length > 0 ? [`fields[${key}]=${value.join(',')}`] : []),
        ],
        []
      ),
    mods.meta ? constructMeta(mods.meta) : '',
    mods.sort && mods.sort.length > 0
      ? `sort=${Array.isArray(mods.sort) ? mods.sort.join(',') : mods.sort}`
      : '',
    mods.token ? `token=${mods.token}` : '',
    mods.locale ? `locale=${mods.locale}` : '',
    mods.page || mods.page === 0 ? `page[number]=${mods.page}` : '',
    mods.limit ? `page[size]=${mods.limit}` : '',
    mods.requestAllTranslations ? 'request_translations' : '',
    mods.showStoredFilesCount ? 'show_stored_files_count=1' : '',
  ]
    .flat()
    .filter((item) => !!item && item !== '');

  return modString.length > 0 ? `${baseUri}?${modString.join('&')}` : baseUri;
}
