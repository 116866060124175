import Color from 'color';

const colorRegex = /^(rgb|#|hsl|cymk)/i;
const generatedRegex = /-[0-9]{1,3}$/;
function toColor(colorString: string): Color {
  const normalizedColor = colorRegex.test(colorString)
    ? colorString
    : colorString.split(',').map((val) => Number(val.trim()));
  return Color(normalizedColor);
}

function toRGBString(colorObject: Color) {
  return colorObject
    .rgb()
    .array()
    .map((val) => Math.round(val))
    .join(',');
}

export function toColorPalette(name: string, rgbValues: string) {
  const nameWithoutSuffix = name.replace('Auto', '').replace('-auto', '');
  if (rgbValues.includes('var') || generatedRegex.test(name)) {
    return {
      [nameWithoutSuffix]: rgbValues,
    };
  }

  const color = toColor(rgbValues);

  const hasSuffix = name.endsWith('Auto') || name.endsWith('-auto');
  return {
    [nameWithoutSuffix]: rgbValues,
    [`${nameWithoutSuffix}-text`]: `var(--${color.isLight() ? 'dark' : 'light'}-text-color)`,
    [`${nameWithoutSuffix}-20`]: toRGBString(color.whiten(0.8)),
    ...(hasSuffix
      ? {
          [`${nameWithoutSuffix}-115`]: toRGBString(color.darken(0.15)),
          [`${nameWithoutSuffix}-110`]: toRGBString(color.darken(0.1)),
          [`${nameWithoutSuffix}-100`]: toRGBString(color),
          [`${nameWithoutSuffix}-75`]: toRGBString(color.darken(0.15)),
          [`${nameWithoutSuffix}-70`]: toRGBString(color.darken(0.1)),
          [`${nameWithoutSuffix}-60`]: toRGBString(color),
          [`${nameWithoutSuffix}-50`]: toRGBString(color.lighten(0.4)),
          [`${nameWithoutSuffix}-40`]: toRGBString(color.lighten(0.5)),
          [`${nameWithoutSuffix}-30`]: toRGBString(color.lighten(0.6)),
          [`${nameWithoutSuffix}-10`]: toRGBString(color.lighten(0.8)),
        }
      : {}),
  };
}
