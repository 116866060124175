import React, { useEffect, useState } from 'react';
import { IRichMediaBlock } from '@brainstud/course-blocks';
import { useRouter } from 'next/router';
import { RichTextEditor } from '../../../../../Components';
import { useGlossaryTerms } from '../../../../academy-api';

export type RichMediaFormProps = {
  data?: IRichMediaBlock;
  onChange: (e: Partial<IRichMediaBlock>) => void;
};

export const RichMediaForm = ({ data, onChange }: RichMediaFormProps) => {
  const [value, setValue] = useState(data?.content);
  const { id } = data || {};

  const router = useRouter();
  const { courseId } = router.query as { courseId: string };
  const [{ data: glossaryTerms }] = useGlossaryTerms({
    course: courseId,
    sort: ['term'],
  });

  useEffect(() => {
    onChange({
      content: value,
    });
  }, [value]);

  return (
    <RichTextEditor
      id={id}
      preset="course_content"
      defaultValue={data?.content}
      onChange={setValue}
      terms={glossaryTerms}
    />
  );
};
