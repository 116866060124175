import { DefaultIncludes } from '@brainstud/academy-api/Providers/ApiProvider/IApiContext';
import merge from 'deepmerge';
import { admin, coach, courses } from './Includes';

export const API_BASE_PATH = '/api';

export const DEFAULT_INCLUDES = merge.all<DefaultIncludes>(
  [coach, courses, admin],
  {
    arrayMerge: (target, source, options) => {
      const destination = target.slice();
      source.forEach((item, index) => {
        if (typeof destination[index] === 'undefined') {
          // @ts-ignore
          destination[index] = options!.cloneUnlessOtherwiseSpecified!(
            item,
            options
          );
        } else if (options!.isMergeableObject!(item)) {
          destination[index] = merge(target[index], item, options);
        } else if (target.indexOf(item) === -1) {
          destination.push(item);
        }
      });
      return destination;
    },
  }
);
