import React from 'react';
import classNames from 'classnames/bind';
import styles from './Heading.module.css';

const cx = classNames.bind(styles);

type TProps = {
  style?: React.CSSProperties;
  className?: string;
  children: React.ReactNode;
};

/**
 * A header for the Admin panel that make sure there is a vertical alignment for the items within it.
 */
export const Heading = ({ style, className, children }: TProps) => (
  <div className={cx(styles.base, className)} style={style}>
    {children}
  </div>
);
