import React, { useEffect, useMemo, useState } from 'react';
import { useFormField, useSyncRefs } from '@brainstud/universal-components';
import classNames from 'classnames/bind';
import { Checkbox } from '../../Checkbox/Checkbox';
import { useDropdownContext } from '../DropdownContext';
import { DropdownOptionProps } from '../DropdownTypes';
import styles from './DropdownOption.module.css';

const classes = classNames.bind(styles);

/**
 * A helper component for rendering options which the Dropdown understands
 */
export const DropdownOption = React.forwardRef<
  HTMLLIElement,
  DropdownOptionProps
>(
  (
    {
      children,
      persist,
      disabled,
      hidden,
      text,
      small,
      value,
      className,
      style,
    },
    ref
  ) => {
    const {
      name,
      search,
      dispatch,
      multiple,
      handleToggleSelect,
      state,
      defaultValue,
    } = useDropdownContext();

    const stringifiedValue = value?.toString();
    const liElement = useSyncRefs(ref, null);
    const [textContent, setTextContent] = useState<string | null | undefined>(
      text
    );
    useEffect(() => {
      if (textContent === undefined && liElement.current) {
        setTextContent(
          text ||
            (multiple
              ? liElement.current.textContent
              : liElement.current.innerHTML)
        );
      }
    }, [liElement, textContent, multiple, text]);

    const isSelected = state.some(
      (item) => item.value === stringifiedValue && item.selected
    );
    const isDefaultChecked = useMemo(
      () =>
        stringifiedValue && Array.isArray(defaultValue)
          ? defaultValue.includes(stringifiedValue)
          : defaultValue === value,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    const { locked } = useFormField({
      id: name ? `${name}_${stringifiedValue}` : undefined,
      name: name
        ? multiple && !name?.endsWith('[]')
          ? `${name}[]`
          : name
        : undefined,
      defaultValue: stringifiedValue,
      checked: isSelected,
    });

    const isDisabled = locked || disabled;
    const isFound =
      !search || textContent?.toLowerCase().includes(search) || persist;
    useEffect(() => {
      if (stringifiedValue && textContent) {
        dispatch({
          type: 'connect',
          payload: {
            value: stringifiedValue,
            label: textContent,
            selected: isDefaultChecked,
          },
        });
        return () => {
          dispatch({
            type: 'disconnect',
            payload: {
              value: stringifiedValue,
            },
          });
        };
      }
    }, [dispatch, textContent, stringifiedValue, isDefaultChecked]);

    return isFound ? (
      <li
        tabIndex={!isDisabled ? 0 : -1}
        ref={liElement}
        role="option"
        aria-selected={isSelected}
        className={classes(
          styles.base,
          {
            disabled: isDisabled,
            hidden,
            smallItem: small,
            selected: isSelected,
          },
          className
        )}
        style={style}
        data-value={stringifiedValue}
        onKeyPress={!isDisabled ? handleToggleSelect : undefined}
        onClick={!isDisabled ? handleToggleSelect : undefined}
      >
        {multiple && !!stringifiedValue && (
          <Checkbox
            className={styles.checkbox}
            quiet
            small
            disabled={isDisabled}
            checked={isSelected}
          />
        )}
        <div
          className={classes(styles.inner, 'ui-dropdown-option__inner', {
            'ui-dropdown-option__selected': isSelected,
          })}
        >
          {children}
        </div>
      </li>
    ) : null;
  }
);
