import { UseQueryOptions } from 'react-query';
import { useIndexCRUD, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  Notification,
  NotificationBulkUpdate,
  NotificationCreate,
  NotificationFilters,
  NotificationIncludes,
  NotificationSort,
  NotificationUpdate,
  UUID,
} from '../Types';

interface Props
  extends HookParams<
    typeof NotificationIncludes,
    typeof NotificationFilters,
    typeof NotificationSort
  > {
  account?: UUID;
  notification?: UUID;
}

export function useNotification(
  { account, notification, ...queryParameters }: Props = {},
  settings?: UseQueryOptions<DataDocument<Notification>, ErrorDocument>
) {
  const {
    parameters: { account: defaultAccount, notification: defaultNotification },
  } = useApi();
  const request = new ApiRequest({
    enabled:
      !!(account || defaultAccount) && !!(notification || defaultNotification),
    baseName: 'v1.notifications',
    baseUri: `/v1/accounts/${account || defaultAccount}/notifications`,
    uri: `/${notification || defaultNotification}`,
    invalidate: ['notification', 'notifications'],
    queryParameters,
  });
  return useSingleCRUD<
    Notification,
    NotificationCreate,
    Partial<NotificationUpdate>
  >(request, settings);
}

export function useNotifications(
  { account, ...queryParameters }: Props = {},
  settings?: UseQueryOptions<DataDocument<Notification[]>, ErrorDocument>
) {
  const {
    parameters: { account: defaultAccount },
  } = useApi();
  const request = new ApiRequest({
    enabled: !!(account || defaultAccount),
    baseName: 'v1.notifications',
    baseUri: `/v1/accounts/${account || defaultAccount}/notifications`,
    invalidate: ['notification', 'notifications'],
    queryParameters,
  });
  return useIndexCRUD<Notification, NotificationCreate, NotificationBulkUpdate>(
    request,
    settings
  );
}
