import React from 'react';
import { toSnakeCase } from '@brainstud/academy-api/Utils/transformCase';
import classNames from 'classnames';
import { OBJECT_META_TAG_ICONS } from 'Hooks';
import { useTranslator } from 'Providers/Translator';
import styles from './ObjectMetaLabel.module.css';

const cx = classNames.bind(styles);

type TagProps = {
  type: string;
};

export const ObjectMetaLabel = ({ type }: TagProps) => {
  const [t] = useTranslator();
  const Icon = OBJECT_META_TAG_ICONS[type];

  return (
    <>
      {Icon ? (
        <div className={cx(styles.base)}>
          <Icon />
          <span>
            {t(
              `views.collection.object.metadata.${toSnakeCase(type)}.description`
            )}
          </span>
        </div>
      ) : null}
    </>
  );
};
