import React, { CSSProperties, ReactNode } from 'react';
import { Button } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { useModals } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { ReactComponent as Lamp } from '../../Assets/svgs/lamp.svg';
import { TipsModal } from './TipsModal';
import styles from './Tips.module.css';

const cx = classNames.bind(styles);

type Props = {
  style?: CSSProperties;
  className?: string;
  children?: string | ReactNode;
};

export const Tips = ({ children, style, className }: Props) => {
  const { openModal } = useModals();
  const [t] = useTranslator();
  return (
    <Button
      type="button"
      onClick={() => openModal(TipsModal, { content: children })}
      className={cx(styles.base, { hidden: !children }, className)}
      style={style}
    >
      <Lamp />
      {t('menu.tips')}
    </Button>
  );
};
