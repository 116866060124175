import React, { FC, useCallback, useState } from 'react';
import {
  FeedbackTypes,
  useCreateContentFeedback,
} from '@brainstud/academy-api';
import classNames from 'classnames/bind';
import { Container } from 'Components';
import { useTranslator } from 'Providers/Translator';
import { Slider } from '../Slider/Slider';
import {
  FivePointScale,
  SmileyFeedback,
  TFeedbackFormProps,
  ThankYouFeedback,
} from './FeedbackTypes';
import styles from './AuthorFeedback.module.css';

const cx = classNames.bind(styles);

const slides: Array<{ type: string; component: FC<TFeedbackFormProps> }> = [
  { type: 'experience_satisfaction', component: SmileyFeedback },
  { type: 'xp_versus_study_hours', component: FivePointScale },
  { type: 'thank_you', component: ThankYouFeedback },
];

type Props = {
  objectId: string;
  xp?: number;
  onFinished: () => void;
};

/**
 * This component asks the participant to evaluate the learning object after filling in the answer for the first time.
 */
export const AuthorFeedback = ({ objectId, xp, onFinished }: Props) => {
  const sendFeedback = useCreateContentFeedback();
  const [active, setActive] = useState(0);
  const [t] = useTranslator();
  const onAnswer = useCallback(
    (feedbackType: FeedbackTypes, feedbackValue?: number | string) => {
      if (feedbackValue) {
        sendFeedback.mutate({
          content_type: 'learning_object',
          content_id: objectId,
          feedback_type: feedbackType,
          feedback_value: feedbackValue,
        });
      }
      if (active === slides.length - 1) {
        onFinished();
      } else {
        setActive((prevActive) => prevActive + 1);
      }
    },
    [sendFeedback, onFinished, active, objectId]
  );

  return (
    <div className={cx(styles.base)}>
      <Container className={cx('container')}>
        <Slider
          speed={200}
          active={active}
          onChange={(idx) => setActive(idx)}
          footer={() => null}
        >
          {slides.map((slide, i) => (
            <slide.component
              key={slide.type}
              title={t(`components.feedback.${slide.type}`, { xp })}
              active={active === i}
              onAnswer={(val) => onAnswer(slide.type as FeedbackTypes, val)}
            />
          ))}
        </Slider>
      </Container>
    </div>
  );
};
