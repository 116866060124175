import React from 'react';
import { SidebarModal } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { LtiResourceLinkForm } from 'Forms/Integrations/LTI';
import { useTranslator } from 'Providers/Translator';
import styles from './LtiModal.module.css';

const cx = classNames.bind(styles);

type TProps = {
  ltiResourceLinkId?: string;
  courseId?: string;
  closeModal?: () => void;
};

/**
 *  Modal to create or update a LtiResourseLink.
 */
export const LtiResourceLinkModal = ({
  ltiResourceLinkId,
  courseId,
  closeModal,
}: TProps) => {
  const [t] = useTranslator();
  const action = ltiResourceLinkId ? 'update' : 'create';

  return (
    <SidebarModal size="medium" closeable onClose={closeModal}>
      <SidebarModal.Header className={cx(styles.header)}>
        <h2>
          {t(`modals.integrations.lti.lti_resource_link.${action}.title`)}
        </h2>
      </SidebarModal.Header>
      <LtiResourceLinkForm
        ltiResourceLinkId={ltiResourceLinkId}
        courseId={courseId}
      />
    </SidebarModal>
  );
};
