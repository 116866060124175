import React, { ChangeEvent, useCallback, useState } from 'react';
import {
  ErrorDocument,
  NfcTag,
  useApi,
  useNfcTag,
} from '@brainstud/academy-api';
import {
  Button,
  Callout,
  Input,
  Textarea,
  Toggle,
  UploadBox,
} from '@brainstud/ui';
import classNames from 'classnames/bind';
import { useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import { PreviewNfcTag } from '../Previews';
import styles from './NfcForm.module.css';

const cx = classNames.bind(styles);

type Props = {
  nfcTagEnabled: boolean;
  setNfcTagEnabled: (enabled: boolean) => void;
  nfcTag: NfcTag;
  setCreatedNfcTag: (nfcTag: NfcTag) => void;
  learningObjectTitle?: string;
};

export const NfcForm = ({
  nfcTagEnabled,
  setNfcTagEnabled,
  nfcTag,
  setCreatedNfcTag,
  learningObjectTitle,
}: Props) => {
  const [t] = useTranslator();
  const apiConfig = useApi();

  const [location, setLocation] = useState(nfcTag?.location);
  const [address, setAddress] = useState(nfcTag?.address);
  const [city, setCity] = useState(nfcTag?.city);
  const [notes, setNotes] = useState(nfcTag?.notes);
  const [locationImage, setLocationImage] = useState(nfcTag?.storedFile?.());
  const [setToast] = useToaster();

  const [{ createOrUpdate: createOrUpdateNfcTag }] = useNfcTag({
    nfcTag: nfcTag?.id,
  });

  // Create or update a NfcTag
  const handleNfcTag = useCallback(
    async () =>
      createOrUpdateNfcTag.mutateAsync(
        {
          notes,
          location,
          address,
          city,
          ...(locationImage
            ? {
                relationships: {
                  stored_file: locationImage.id,
                },
              }
            : {}),
        },
        {
          onSuccess: (data: { statusCode: number; data: NfcTag }) => {
            if (data.statusCode < 300) {
              setCreatedNfcTag(data.data);
              setToast(
                'views.courses.collection_edit.assignment_modal.nfc.update.success',
                'success'
              );
            }
          },
          onError: (data: ErrorDocument) => {
            setToast(
              data.errors.map((error) => error.detail).join('<br />'),
              'error'
            );
          },
        }
      ),
    [
      address,
      city,
      createOrUpdateNfcTag,
      location,
      locationImage,
      notes,
      setCreatedNfcTag,
      setToast,
    ]
  );

  return (
    <div className={cx(styles.wrapper, { expanded: nfcTagEnabled })}>
      <div className={cx(styles.toggleWrapper)}>
        <strong>
          {t('views.courses.collection_edit.assignment_modal.nfc.toggle')}
        </strong>
        <Toggle
          name="nfc_enabled"
          checked={nfcTagEnabled}
          onChange={(e) =>
            setNfcTagEnabled((e.target as HTMLInputElement).checked)
          }
        />
      </div>
      {nfcTag?.tagContents && (
        <Callout className={cx(styles.nfcResult)}>
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizer(
                  `${t('views.courses.collection_edit.nfc_tag.output')}`
                ),
              }}
            />
            <textarea
              readOnly
              onClick={(event) => event.currentTarget.select?.()}
              defaultValue={nfcTag.tagContents}
            />
          </div>
          <div style={{ width: 150 }}>
            <img
              src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${nfcTag.tagContents}`}
              alt="QR code"
            />
          </div>
        </Callout>
      )}
      <div className={cx(styles.nfc)}>
        <Input
          label={t('views.courses.collection_edit.nfc_tag.form.location')}
          placeholder={t(
            'views.courses.collection_edit.nfc_tag.form.location_placeholder'
          )}
          name="nfc_location"
          value={location}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setLocation(e.target.value)
          }
        />
        <Input
          label={t('views.courses.collection_edit.nfc_tag.form.address')}
          placeholder={t(
            'views.courses.collection_edit.nfc_tag.form.address_placeholder'
          )}
          name="nfc_address"
          value={address}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setAddress(e.target.value)
          }
        />
        <Input
          label={t('views.courses.collection_edit.nfc_tag.form.city')}
          placeholder={t(
            'views.courses.collection_edit.nfc_tag.form.city_placeholder'
          )}
          name="nfc_city"
          value={city}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setCity(e.target.value)
          }
        />
        <Textarea
          label={t('views.courses.collection_edit.nfc_tag.form.notes')}
          placeholder={t(
            'views.courses.collection_edit.nfc_tag.form.notes_placeholder'
          )}
          name="nfc_notes"
          value={notes}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setNotes(e.target.value)
          }
        />
        <strong>
          {t('views.courses.collection_edit.nfc_tag.form.location_image')}
        </strong>
        <div className={cx(styles.uploadBox)}>
          <UploadBox
            url={`${apiConfig.baseUrl}/v1/services/temporary_file_upload`}
            headers={apiConfig.headers}
            paramName="file"
            maxFiles={1}
            label={t(
              'views.courses.collection_edit.nfc_tag.form.upload_image_label'
            )}
            onAfterFileUpload={(file: Dropzone.DropzoneFile, response: any) => {
              setLocationImage(response?.data);
            }}
          />
        </div>
        <span
          dangerouslySetInnerHTML={{
            __html: sanitizer(
              t('views.courses.collection_edit.form.image_instructions')
            ),
          }}
        />
        <PreviewNfcTag
          title={learningObjectTitle}
          location={location}
          address={address}
          city={city}
          notes={notes}
          locationImage={locationImage?.downloadUrl}
        />

        <Button
          type="button"
          block
          onClick={handleNfcTag}
          loading={createOrUpdateNfcTag.isLoading}
        >
          {t(
            `views.courses.collection_edit.nfc_tag.form.submit.${!nfcTag ? 'create' : 'update'}`
          )}
        </Button>
      </div>
    </div>
  );
};
