import React from 'react';
import classNames from 'classnames/bind';
import styles from './ToolbarItem.module.css';

const cx = classNames.bind(styles);

type ToolbarItemProps = {
  onClick: () => void;
  active?: boolean;
  children: React.ReactNode | string;
};

export const ToolbarItem = ({
  onClick,
  active = false,
  children,
}: ToolbarItemProps) => (
  <button
    type="button"
    onClick={onClick}
    className={cx(styles.base, { 'is-active': active })}
  >
    {children}
  </button>
);
