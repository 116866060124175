import { createReactReducer } from 'Utils/createReactReducer';

export type TSortAttribute = 'createdAt' | 'title' | 'sbu';
export type TSortOrder = 'ascending' | 'descending';
export type TViewType = 'grid' | 'list';

export interface ICourseListState {
  filters: {
    category?: string;
    profiles?: string[];
    sbu?: string[];
  };
  searchQuery?: string;
  viewType: TViewType;
  sort: {
    attribute: TSortAttribute | string;
    direction: TSortOrder;
  };
}

type FilterSbuAction = {
  type: 'FILTER_SBU';
  payload?: string[];
};
type FilterCategoryAction = {
  type: 'FILTER_CATEGORY';
  payload?: string;
};

type ToggleProfilesAction = {
  type: 'TOGGLE_PROFILES';
  payload: string[];
};
type FilterProfileAction = {
  type: 'FILTER_PROFILES';
  payload?: string[];
};

type ToggleViewTypeAction = {
  type: 'TOGGLE_VIEWTYPE';
};

type SearchAction = {
  type: 'SEARCH';
  payload?: string;
};

type ResetAction = {
  type: 'RESET';
};

type ToggleSortDirectionAction = {
  type: 'TOGGLE_SORT_DIRECTION';
};

type SetSortAction = {
  type: 'SET_SORT';
  payload: Partial<ICourseListState['sort']>;
};

export type CourseListActions =
  | ToggleProfilesAction
  | SearchAction
  | ResetAction
  | ToggleViewTypeAction
  | FilterSbuAction
  | ToggleSortDirectionAction
  | SetSortAction
  | FilterCategoryAction
  | FilterProfileAction;

export const CourseListInitialState: ICourseListState = {
  filters: {
    profiles: [],
    sbu: [],
  },
  viewType: 'grid',
  sort: {
    attribute: 'createdAt',
    direction: 'ascending',
  },
};

export const CourseListReducer = createReactReducer<
  ICourseListState,
  CourseListActions
>({
  FILTER_CATEGORY: ({ payload }, { filters: prevFilters }) => ({
    filters: {
      ...prevFilters,
      category: payload,
    },
  }),
  SET_SORT: ({ payload }, state) => ({
    sort: {
      ...state.sort,
      ...payload,
    },
  }),
  TOGGLE_SORT_DIRECTION: (_, state) => ({
    sort: {
      ...state.sort,
      direction:
        state.sort.direction === 'ascending' ? 'descending' : 'ascending',
    },
  }),
  FILTER_SBU: ({ payload }, { filters: prevFilters }) => ({
    filters: {
      ...prevFilters,
      sbu: payload,
    },
  }),
  FILTER_PROFILES: ({ payload }, state) => ({
    filters: {
      ...state.filters,
      profiles: payload,
    },
  }),
  TOGGLE_PROFILES: ({ payload }, state) => ({
    filters: {
      ...state.filters,
      profiles: [
        // Remove existing profiles when they are in the payload
        ...(state?.filters?.profiles ?? []).reduce<string[]>(
          (remaining, existing) =>
            payload.includes(existing) ? remaining : [...remaining, existing],
          []
        ),
        // Add profiles when they are not in the state
        ...payload.reduce<string[]>(
          (additions, item) =>
            (state.filters.profiles ?? []).includes(item)
              ? additions
              : [...additions, item],
          []
        ),
      ],
    },
  }),
  TOGGLE_VIEWTYPE: (_, state) => ({
    viewType: state.viewType === 'grid' ? 'list' : 'grid',
  }),
  SEARCH: ({ payload }) => ({
    searchQuery: payload,
  }),
  RESET: () => CourseListInitialState,
});
