import React from 'react';
import { withDropability } from '@brainstud/universal-components';
import classNames from 'classnames/bind';
import { DraggableArea } from '../DraggableArea/DraggableArea';
import { DropAreaProps } from './DropAreaProps';
import styles from './DropArea.module.css';

const cx = classNames.bind(styles);

/**
 * Area on which you can drop draggable areas as part of a Drag and Drop exercise
 */
export const DropArea = withDropability<DropAreaProps>(
  ({
    list,
    hovering,
    droppable,
    element,
    placeholder,
    children,
    className,
    style,
  }) => (
    <div
      ref={element}
      className={cx(
        styles.base,
        'droparea',
        {
          'is-hovering': hovering,
          'is-droppable': droppable,
          'droparea-hovering': hovering,
          'droparea-droppable': droppable,
        },
        className
      )}
      style={style}
    >
      {children}
      <div className={cx('listing', 'droparea-listing')}>
        {list.length === 0
          ? placeholder
          : list.map((item) => (
              <DraggableArea
                key={item.identifier}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...item}
              />
            ))}
      </div>
    </div>
  )
);
