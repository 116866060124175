import { createContext, Dispatch } from 'react';
import {
  LearningObject,
  LearningObjectVariety,
  UnenrolledLearningObject,
} from '@brainstud/academy-api';
import { TLearningObjectActions } from './LearningObjectReducer';

export type TLearningObjectIndeterminate = UnenrolledLearningObject &
  Pick<Partial<LearningObject>, 'userState'>;

export interface ILearningObjectContext {
  learningObject?: TLearningObjectIndeterminate;
  isLoading: boolean;
  hasEnrollment: boolean;
  variety?: LearningObjectVariety;
  varieties?: LearningObjectVariety[];
  dispatch: Dispatch<TLearningObjectActions>;
  shared?: boolean;
}

export const LearningObjectContext =
  createContext<null | ILearningObjectContext>(null);
