import React, { ChangeEventHandler, useCallback, useState } from 'react';
import { useMentees } from '@brainstud/academy-api/Hooks/useMentees';
import { Pagination } from '@brainstud/ui';
import {
  DataProvider,
  useDebounce,
  usePagination,
} from '@brainstud/universal-components';
import classNames from 'classnames/bind';
import { Container, Loading } from 'Components';
import { useBreadcrumbs } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { MenteesTable } from '../../Components/MenteesTable/MenteesTable';
import { withContentErrorBoundary } from '../Errors/withContentErrorBoundary';
import { MenteesSearchAndFilter } from './SearchAndFilter';
import styles from './MenteesIndexView.module.css';

const cx = classNames.bind(styles);

/**
 * MenteesIndexView.
 *
 * Shows an overview of mentees and their enrollments.
 */
const MenteesIndexView = () => {
  const [t] = useTranslator();
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const debouncedSearchQuery = useDebounce(searchQuery, 300);
  const [showArchived, setShowArchived] = useState(false);

  useBreadcrumbs({
    mentees: { text: t('views.coach.mentees.breadcrumb') },
  });

  const { page, limit, sort, setSort } = usePagination();

  const filters = { full_name: debouncedSearchQuery, archived: showArchived };
  const [{ data: mentees }, { data: document, isLoading, isFetching }] =
    useMentees({
      limit,
      page,
      filter: filters,
      sort: sort as any,
      include: [
        'enrollments',
        'enrollments.course',
        'subscription',
        'enrollments.coach',
      ],
    });

  const handleSearch = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setSearchQuery(e.target.value);
    },
    []
  );

  const handleToggleArchived = useCallback(
    (archive) => setShowArchived(archive),
    []
  );

  const handleClearFilter = useCallback(() => {
    setSearchQuery('');
    setShowArchived(false);
  }, []);

  const handleOnClose = useCallback(() => setSearchQuery(''), []);

  return (
    <DataProvider>
      {isLoading ? (
        <Loading />
      ) : (
        <Container className={cx(styles.base)}>
          <h1>{t('views.coach.mentees.breadcrumb')}</h1>

          <MenteesSearchAndFilter
            showArchived={showArchived}
            onToggleArchived={handleToggleArchived}
            onSearchClear={handleOnClose}
            onSearch={handleSearch}
            searchQuery={searchQuery}
            onFilterClear={handleClearFilter}
            filter={filters}
          />

          <MenteesTable
            mentees={mentees}
            onSort={setSort}
            total={document?.meta?.total}
            isFetching={isFetching}
            showingArchived={showArchived}
            searchQuery={debouncedSearchQuery}
          />

          {mentees.length > 0 && (
            <div className={cx(styles.pagination)}>
              <Pagination
                totalPages={document?.meta?.lastPage}
                totalResults={`${t('components.pagination.totalResults')} ${document?.meta?.total || 0}`}
                labels={{
                  itemsPerPage: t('views.coach.mentees.mentees_per_page'),
                  results: t('components.pagination.totalResults'),
                }}
              />
            </div>
          )}
        </Container>
      )}
    </DataProvider>
  );
};

export default withContentErrorBoundary(MenteesIndexView);
