import React, { useCallback } from 'react';
import {
  TShareableAccessType,
  useDeleteShareable,
  useUpdateShareable,
  UUID,
} from '@brainstud/academy-api';
import { Button, ConfirmationModal, Dropdown, Td, Tr } from '@brainstud/ui';
import { Delete } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Avatar } from 'Components';
import { useModals, useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import styles from './ShareableTableRow.module.css';

const cx = classNames.bind(styles);

type ShareableTableRowProps = {
  id: UUID;
  name: string;
  access: TShareableAccessType;
};

export const ShareableTableRow = ({
  id,
  name,
  access,
}: ShareableTableRowProps) => {
  const [t] = useTranslator();
  const { openModal, closeModal } = useModals();
  const [setToast] = useToaster();
  const destroy = useDeleteShareable({ shareable: id });
  const update = useUpdateShareable({ shareable: id });

  const handleDelete = useCallback(() => {
    openModal(
      ConfirmationModal,
      {
        title: t('modals.share_learning_object.confirm.title'),
        description: t('modals.share_learning_object.confirm.description'),
        yes: t('confirm'),
        no: t('cancel'),
        handleConfirm: () =>
          destroy.mutateAsync(
            {},
            {
              onSuccess: () => {
                setToast(
                  t('modals.share_learning_object.confirm.success'),
                  'success'
                );
                closeModal();
              },
            }
          ),
      },
      1
    );
  }, [closeModal, destroy, openModal, setToast, t]);

  const handleOnAccessChange = useCallback(
    async (scope?: string) => {
      await update.mutateAsync(
        {
          _method: 'patch',
          access: scope as TShareableAccessType,
        },
        {
          onSuccess: () => {
            setToast(
              t('modals.share_learning_object.access.success', {
                name,
                access: t(`views.portfolio.${scope}`),
              }),
              'success'
            );
          },
          onError: () => {
            setToast(
              t('modals.share_learning_object.access.error', { name }),
              'error'
            );
          },
        }
      );
    },
    [update, setToast, t, name]
  );

  return (
    <Tr key={name} className={cx(styles.base)}>
      <Td collapse>
        <Avatar name={name} />
      </Td>
      <Td>
        <span>{name}</span>
      </Td>
      <Td collapse>
        <Dropdown value={access} onChange={handleOnAccessChange}>
          <Dropdown.Option value="read" disabled={access === 'read'}>
            {t(`views.portfolio.read`)}
          </Dropdown.Option>
          <Dropdown.Option value="comment" disabled={access === 'comment'}>
            {t(`views.portfolio.comment`)}
          </Dropdown.Option>
        </Dropdown>
      </Td>
      <Td collapse>
        <Button
          type="button"
          quiet
          small
          disabled={destroy.isLoading}
          onClick={handleDelete}
          className={cx(styles.button)}
        >
          <Delete fontSize="large" />
        </Button>
      </Td>
    </Tr>
  );
};
