import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from '@brainstud/universal-components';
import { useContentBlocksProvider } from 'Providers/ContentBlocksProvider';

type Props = {
  id?: string;
  touched?: boolean;
  read?: boolean;
  open?: boolean;
  closed?: boolean;
  fields?: string[];
};

/**
 * Easily set the state of a block an retrieve its data and state
 * @param id Identifier of the block
 * @param touched Boolean to toggle whether block has been touched
 * @param settings The Block settings
 * @param fieldNames The names of the form fields in the block
 */
export function useBlock({
  id,
  touched,
  read = true,
  open = false,
  closed = false,
  fields: fieldNames,
}: Props) {
  const { blocks, setBlockById } = useContentBlocksProvider();
  const { fields: formFields } = useForm(true);

  const block = useMemo(
    () => blocks.find((item) => item.id === id),
    [blocks, id]
  );
  const blockId = block?.id;

  const fields = useMemo(
    () =>
      formFields?.filter(
        (field) =>
          field.name === id ||
          (fieldNames || []).indexOf(field.id) > -1 ||
          (fieldNames || []).indexOf(field.name) > -1
      ),
    [fieldNames, formFields, id]
  );

  const isValid = fields?.every((field) => field?.valid === true);
  const isValidated = fields?.some((field) => field?.valid !== undefined);

  useEffect(() => {
    if (blockId) {
      setBlockById(blockId, {
        loaded: true,
        open,
        closed,
        touched,
        static: !open && !closed,
        submittable: open || closed,
      });
    }
  }, [blockId, open, touched, closed, setBlockById]);

  const markAsRead = useCallback(() => {
    if (blockId) {
      setBlockById(blockId, { read: true });
    }
  }, [blockId, setBlockById]);

  useEffect(() => {
    if (read) {
      markAsRead();
    }
  }, [read, markAsRead]);

  return {
    block,
    isValid,
    isValidated,
    fields,
    markAsRead,
  };
}
