import React, { PropsWithChildren } from 'react';
import { BackButton } from '@brainstud/account-panel/Components';
import { Panel } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { Container } from 'Components';
import { useTranslator } from 'Providers/Translator';
import styles from './ChangePasswordLayout.module.css';

const cx = classNames.bind(styles);

type Props = {
  className?: string;
};
/**
 * ChangePasswordLayout
 *
 *
 */
export const ChangePasswordLayout = ({
  className,
  children,
}: PropsWithChildren<Props>) => {
  const [t] = useTranslator();

  return (
    <Container className={cx(styles.base, className)}>
      <BackButton returnTo="/account/security" />
      <h2>{t('layouts.account.change_password.title')}</h2>
      <Panel className={cx(styles.panel)}>{children}</Panel>
    </Container>
  );
};
