export type TSortFn = (a: object, b: object) => number;

/**
 * Creates a sort function for a key of the object
 *
 * Use this function if you want to sort an array of objects based on one of the object properties.
 *
 * **Example usage**: `data.sort(sortOnPropFactory('keyOfData'))` or `data.sort(sortOnPropFactory('-keyOfData'))`
 */
export function sortOnPropFactory(prop: string): TSortFn;
export function sortOnPropFactory(prop?: string): undefined | TSortFn;
export function sortOnPropFactory(prop?: string): undefined | TSortFn {
  if (!prop) return;
  const sortDirection = prop.startsWith('-') ? 1 : -1;
  const sortProperty =
    sortDirection === 1 ? prop.substring(1, prop.length) : prop;

  return (a: { [key: string]: any }, b: { [key: string]: any }) =>
    a[sortProperty] > b[sortProperty] ? sortDirection : sortDirection * -1; // Flip the sortDirection, 1 becomes -1, -1 becomes 1
}
