import { UseQueryOptions } from 'react-query';
import { useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  TwoFactorAuthentication,
  TwoFactorAuthenticationCreateInput,
  TwoFactorAuthenticationDeleteInput,
  TwoFactorAuthenticationUpdateInput,
  UUID,
} from '../Types';

type TTwoFactorAuthenticationProps = {
  account?: UUID;
};

/**
 * Two factor authentication can be used to check if this is enabled or enable it.
 * @param account The account uuid
 * @param settings The Query settings
 * @param queryParameters
 * @returns TwoFactorAuthentication
 */
export function useTwoFactorAuthentication(
  { account, ...queryParameters }: TTwoFactorAuthenticationProps = {},
  settings?: UseQueryOptions<
    DataDocument<TwoFactorAuthentication>,
    ErrorDocument
  >
) {
  const context = useApi();
  const {
    parameters: { account: defaultAccount },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(account || defaultAccount),
      baseName: 'v1.accounts.two_factor_authentication',
      baseUri: `/v1/accounts/${account || defaultAccount}/two_factor_authentication`,
      invalidate: ['accounts.two_factor_authentication'],
      queryParameters,
    },
    context
  );

  return useSingleCRUD<
    TwoFactorAuthentication,
    TwoFactorAuthenticationCreateInput,
    TwoFactorAuthenticationUpdateInput,
    TwoFactorAuthenticationDeleteInput
  >(request, settings);
}
