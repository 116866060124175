import { FC, useCallback, useState } from 'react';
import {
  useLearningObjectCollectionDuplicate,
  UUID,
} from '@brainstud/academy-api';
import { Button, Checkbox, ContentModal, Input } from '@brainstud/ui';
import { Form } from '@brainstud/universal-components';
import { useRouter } from 'next/router';
import { useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import styles from './DuplicateModal.module.css';

interface IDuplicateModal {
  collectionId: UUID;
  courseId: UUID;
  closeModal: () => void;
}

type TFormData = {
  title: string;
};

/**
 * The modal shows a form with which you can duplicate an existing learning object collection.
 */
export const DuplicateModal: FC<IDuplicateModal> = ({
  collectionId,
  closeModal,
  courseId,
}) => {
  const [t] = useTranslator();
  const [setToast] = useToaster();
  const duplicate = useLearningObjectCollectionDuplicate({
    learningObjectCollection: collectionId,
  });
  const [aware, setAware] = useState(false);

  const router = useRouter();

  const handleDuplication = useCallback(
    ({ title }: Partial<TFormData>) => {
      if (title) {
        duplicate.mutate(
          { title },
          {
            onSuccess: (response) => {
              setToast(t('modals.duplicateModal.confirmation'), 'success');
              router.push(
                `/coach/courses/${courseId}/collections/${response.data.id}/content`
              );
              closeModal();
            },
            onError: () => {
              closeModal();
              setToast(t('modals.duplicateModal.notPossible'), 'error');
            },
          }
        );
      }
    },
    [closeModal, duplicate, setToast, t, courseId, router]
  );

  return (
    <ContentModal onClickOutside onClose={closeModal} className={styles.base}>
      <Form<TFormData>
        onSubmit={handleDuplication}
        language="nl"
        disabled={duplicate.isLoading}
      >
        <ContentModal.Header>
          <h3>{t('modals.duplicateModal.title')}</h3>
        </ContentModal.Header>
        <p>{t('modals.duplicateModal.description')}</p>
        <Input
          name="title"
          rules={['required']}
          label={t('modals.duplicateModal.labelInput')}
        />
        <Checkbox name="guarantee" quiet onChange={() => setAware((v) => !v)}>
          {t('modals.duplicateModal.guaranteeCheck')}
        </Checkbox>
        <ContentModal.Footer>
          <Button
            type="submit"
            disabled={!aware}
            loading={duplicate.isLoading}
            style={{ marginRight: '1rem' }}
          >
            {t('modals.duplicateModal.confirmButton')}
          </Button>
          <Button type="reset" onClick={closeModal} quiet>
            {t('cancel')}
          </Button>
        </ContentModal.Footer>
      </Form>
    </ContentModal>
  );
};
