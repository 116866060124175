/**
 * Compares either string values or array values are identical
 * @param {string|array<string>} givenInput
 * @param {string|array<string>} expectedInput
 * @returns {boolean}
 */
function equals(
  givenInput: string | string[],
  expectedInput: string | string[]
) {
  if (Array.isArray(expectedInput)) {
    if (Array.isArray(givenInput)) {
      return (
        expectedInput.length === givenInput.length &&
        expectedInput.every((val) => givenInput.includes(val))
      );
    }
    return expectedInput.includes(givenInput);
  }
  if (Array.isArray(givenInput)) {
    return givenInput.includes(expectedInput);
  }
  return givenInput === expectedInput;
}

/**
 * Compares whether one array is a superset of the other
 * @param {string|array<string>} givenInput
 * @param {string|array<string>} expectedInput
 * @returns {boolean}
 */
function contains(
  givenInput: string | string[],
  expectedInput: string | string[]
): boolean {
  if (Array.isArray(expectedInput)) {
    if (Array.isArray(givenInput)) {
      return expectedInput.every((expected) => contains(givenInput, expected));
    }
    return expectedInput.every((expectation) =>
      new RegExp(expectation, 'i').test(givenInput)
    );
  }
  if (Array.isArray(givenInput)) {
    return givenInput.some((input) => contains(input, expectedInput));
  }
  return new RegExp(expectedInput, 'i').test(givenInput);
}

type Value = string | string[];

export default {
  equals: (value: Value, parameters: string = '') => {
    const expected = parameters.split(';');
    const outcome = equals(value, expected);
    return [outcome, outcome ? 1 : 0];
  },
  contains: (value: Value, parameters: string = '') => {
    const expected = parameters.split(';');
    const outcome = contains(value, expected);
    return [outcome, outcome ? 1 : 0];
  },
  checked: (value: Value, _: string, values: Value | Value[]) => {
    const outcome = Array.isArray(values)
      ? values.includes(value as string)
      : values === value;
    return [outcome, outcome ? 1 : 0];
  },
  unchecked: (value: Value, _: string, values: Value | Value[]) => {
    const outcome = Array.isArray(values)
      ? !values.includes(value as string)
      : values !== value;
    return [outcome, outcome ? 1 : 0];
  },
};
