import React, { useCallback, useMemo } from 'react';
import { Mentee, MenteeEnrollment } from '@brainstud/academy-api';
import { ActionMenu, Progress, Status, Table, Td, Th, Tr } from '@brainstud/ui';
import { Timestamp } from '@brainstud/ui/Static/Timestamp';
import { SupervisorAccount } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { CompletedBadge } from 'Components';
import { MentorChangeCoachModal } from 'Modules/coach-panel/Modals/MentorChangeCoachModal';
import { StatusColors } from 'Modules/coach-panel/Utils';
import { useModals } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { toDecimals } from 'Utils';
import styles from './MenteesTable.module.css';

const cx = classNames.bind(styles);

type TProps = {
  data: Mentee;
};

const VALID_EXAM_STATUS = [
  'IN_PRE_ASSESSMENT',
  'PRE_ASSESSMENT_REJECTED',
  'PRE_ASSESSMENT_APPROVED',
  'IN_PROGRESS',
  'HANDED_IN',
  'COMPLETED_APPROVED',
  'COMPLETED_REJECTED',
];

export const ExpandedMenteeRow = ({ data: mentee }: TProps) => {
  const [t] = useTranslator();
  const enrollments = useMemo(() => mentee.enrollments?.() || [], [mentee]);
  const { openModal } = useModals();

  const handleOpenChangeCoachModal = useCallback(
    (enrollment: MenteeEnrollment) => {
      openModal(MentorChangeCoachModal, {
        enrollmentId: enrollment.id,
        courseName: enrollment.course?.()?.title,
        courseId: enrollment.course?.()?.id,
        currentCoachId: enrollment.coach?.().id,
      });
    },
    [openModal]
  );

  return (
    <Tr className="no-hover">
      <Td colSpan={6}>
        <Table>
          <Table.THead>
            <Tr>
              <Th>{t('students.course')}</Th>
              <Th />
              <Th>{t('coach')}</Th>
              <Th>{t('students.lastActive')}</Th>
              <Th />
            </Tr>
          </Table.THead>
          <Table.TBody>
            {enrollments.length > 0 ? (
              enrollments.map((enrollment) => {
                const course = enrollment.course?.();
                const examStep = enrollment.meta?.exam?.currentStep;
                const coach = enrollment.coach?.();

                /**
                 * In theory this should never happen, but on staging it is happening.
                 * Therefore, we check this.
                 */
                if (!course) return null;

                return (
                  <Tr key={enrollment.id}>
                    <Td>
                      <strong>{course.title}</strong>
                      <div className={cx(styles.progress)}>
                        <Progress
                          value={enrollment.currentXp}
                          max={enrollment.totalXp}
                          label={
                            <span>
                              <strong>
                                {toDecimals(enrollment.currentXp, 1)}
                              </strong>
                              {` / ${toDecimals(enrollment.totalXp, 1)}XP`}
                            </span>
                          }
                        />
                      </div>
                    </Td>
                    <Td>
                      <>
                        {enrollment?.isCompleted ? (
                          <CompletedBadge label={t('states.completed')} />
                        ) : (
                          examStep &&
                          VALID_EXAM_STATUS.includes(examStep) && (
                            <Status scheme={StatusColors[examStep]}>
                              {t(`states.exam.${examStep}`)}
                            </Status>
                          )
                        )}
                      </>
                    </Td>
                    <Td className={cx(styles.coach)}>
                      {coach ? (
                        <div>
                          <SupervisorAccount />
                          <span>{coach.fullName}</span>
                        </div>
                      ) : (
                        '-'
                      )}
                    </Td>
                    <Td collapse>
                      <Timestamp timestamp={enrollment.activeAt} />
                    </Td>
                    <Td collapse>
                      <ActionMenu
                        id={enrollment.id}
                        className={cx(styles.actions)}
                      >
                        <button
                          role="menuitem"
                          type="button"
                          onClick={() => handleOpenChangeCoachModal(enrollment)}
                        >
                          <span>
                            {t('views.coach.mentees.action_menu.change_coach')}
                          </span>
                        </button>
                      </ActionMenu>
                    </Td>
                  </Tr>
                );
              })
            ) : (
              <Tr>
                <Td colSpan={4}>
                  {t('views.coach.mentees.enrollments.no_results')}
                </Td>
              </Tr>
            )}
          </Table.TBody>
        </Table>
      </Td>
    </Tr>
  );
};
