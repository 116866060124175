import { ErrorDocument } from '@brainstud/academy-api';
import PaymentRequiredView from 'Views/Errors/PaymentRequiredView';
import { Exception, TExceptionOptions } from './Exception';

/**
 * ApiException.
 *
 * Can be thrown when the API returns an unexpected response, e.g. a 4xx or 5xx response.
 */
export class ApiException extends Exception {
  public name = 'API request failed';

  public silent = true;

  public level: Exception['level'] = 'info';

  public template = 'block' as const;

  public constructor(error: ErrorDocument, options?: TExceptionOptions) {
    super(
      `Status code ${error.statusCode} was returned from the API.`,
      {
        document: error,
      },
      options
    );

    const errorType =
      {
        401: 'unauthorized',
        402: 'payment_required',
        403: 'forbidden',
        404: 'not_found',
        408: 'unavailable',
        502: 'unavailable',
        503: 'unavailable',
        504: 'unavailable',
      }[error.statusCode] || 'other';

    if (error.statusCode === 402 && !this.view) {
      this.view = PaymentRequiredView;
    }

    if (errorType === 'other') {
      this.level = 'critical';
      this.silent = false;
    }

    this.props = {
      title: `exceptions.api_error.${errorType}.title`,
      description: `exceptions.api_error.${errorType}.description`,
    };
  }
}
