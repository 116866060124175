import React, { CSSProperties, useCallback, useMemo, useState } from 'react';
import { useApi } from '@brainstud/academy-api';
import { Button, Callout, FileCard, Input, UploadBox } from '@brainstud/ui';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Container, Loading } from 'Components';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import { RichTextEditor } from '../../../Components';
import { useAnswerReview } from '../../../Providers';
import { TExternalRatingFormValues } from '../ExternalRatingView';
import styles from './ExternalFeedbackDrawer.module.css';

const classes = classNames.bind(styles);

type Props = {
  onSubmit: (values: TExternalRatingFormValues) => boolean | Promise<boolean>;
  style?: CSSProperties;
  className?: string;
};

/**
 * ExternalFeedbackDrawer.
 *
 * Shows a drawer at the bottom, similar to the AnswerReviewDrawer that is meant for reviews
 * by external guests.
 */
export const ExternalFeedbackDrawer = ({
  onSubmit,
  className,
  style,
}: Props) => {
  const [collapsed, setCollapsed] = useState(false);

  const [files, setFiles] = useState<string[]>([]);
  const [t] = useTranslator();
  const { headers, baseUrl } = useApi();

  const { isLoading, externalRating } = useAnswerReview() || {};

  const savedFiles = useMemo(
    () => externalRating?.storedFiles?.() || [],
    [externalRating]
  );
  const [name, setName] = useState(externalRating?.name);
  const [feedback, setFeedback] = useState(externalRating?.feedback);
  const [isFeedbackSaved, setIsFeedbackSaved] = useState(!!feedback);
  const [error, setError] = useState<undefined | string>();

  const handleSubmit = useCallback(async () => {
    if (!name || !feedback) {
      setError(t('external_rating.error'));
    } else {
      setError(undefined);
      setIsFeedbackSaved(
        !!(await onSubmit?.({
          name,
          feedback,
          files,
        }))
      );
    }
  }, [name, feedback, files, onSubmit, t]);

  return isLoading ? (
    <div
      className={classes(styles.base, { collapsed }, className)}
      style={style}
    >
      <Loading />
    </div>
  ) : (
    <div
      className={classes(styles.base, { collapsed }, className)}
      style={style}
    >
      <Container className={styles.inner}>
        <header>
          <h3>{t('external_rating.feedback.title')}</h3>
          <Button
            type="button"
            quiet
            small
            onClick={() => setCollapsed((prevCollapsed) => !prevCollapsed)}
          >
            {collapsed ? (
              <ExpandLess fontSize="large" />
            ) : (
              <ExpandMore fontSize="large" />
            )}
          </Button>
        </header>
        {!collapsed && (
          <>
            <div className={styles.form}>
              <div>
                {!isFeedbackSaved ? (
                  <>
                    <Input
                      label={t('external_rating.feedback.name')}
                      placeholder={t('external_rating.feedback.name')}
                      value={externalRating?.name}
                      onChange={(event) => setName(event.target.value)}
                    />
                    <RichTextEditor
                      label={t('review.optionalFeedback')}
                      preset="minimal"
                      placeholder={t('review.feedbackPlaceholder')}
                      defaultValue={feedback}
                      onChange={setFeedback}
                    />
                  </>
                ) : (
                  <>
                    <strong>{`${t('external_rating.feedback.name')}: `}</strong>
                    <span>{name}</span>
                    <div
                      dangerouslySetInnerHTML={{ __html: sanitizer(feedback) }}
                    />
                  </>
                )}
              </div>
              <div>
                {!isFeedbackSaved && (
                  <UploadBox
                    label={t('review.upload_file')}
                    url={
                      externalRating?.links?.temporaryFileUpload?.href ||
                      `${baseUrl}/v1/services/temporary_file_upload`
                    }
                    headers={{ ...headers, 'Content-Type': undefined }}
                    paramName="file"
                    className={styles.upload}
                    onAfterFileUpload={(file, response) => {
                      if (response?.data?.id) {
                        setFiles((prevFiles) => [
                          ...prevFiles,
                          response?.data?.id as string,
                        ]);
                      }
                    }}
                  />
                )}
                {savedFiles.length > 0 && (
                  <div className={styles['uploaded-files']}>
                    {savedFiles.map((file) => (
                      <a key={file.id} href={file.downloadUrl} download>
                        <FileCard thumbnail={file.downloadUrl}>
                          {file.originalFileName}
                        </FileCard>
                      </a>
                    ))}
                  </div>
                )}
                {error && (
                  <Callout error>
                    {t('external_rating.feedback.invalid_input')}
                  </Callout>
                )}
              </div>
            </div>
            {!isFeedbackSaved && (
              <footer>
                <Button type="button" onClick={handleSubmit}>
                  {t('external_rating.feedback.submit')}
                </Button>
              </footer>
            )}
          </>
        )}
      </Container>
    </div>
  );
};
