import React, { CSSProperties } from 'react';
import { StoredFile } from '@brainstud/academy-api';
import { FileCard, Status } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import { DateFormatter } from 'Utils/DateFormatHelper';
import { sanitizer } from 'Utils/Sanitizer';
import styles from './AnswerFeedback.module.css';

const classes = classNames.bind(styles);

type Props = {
  name?: string;
  date: string;
  assessment?: 'TURNED_IN' | 'REJECTED' | 'ACCEPTED' | 'OPEN' | 'CONCEPT';
  feedback?: string;
  files?: StoredFile[];
  style?: CSSProperties;
  className?: string;
};

/**
 * This component renders a nicely formatted feedback item of a guest or coach.
 */
export const AnswerFeedback = ({
  name,
  date,
  assessment,
  feedback,
  files = [],
  className,
  style,
}: Props) => {
  const [t, { ucf }] = useTranslator();
  const statusOptions = {
    TURNED_IN: ucf(t('states.turned_in')),
    REJECTED: ucf(t('states.rejected')),
    ACCEPTED: ucf(t('states.accepted')),
    OPEN: ucf(t('states.accepted')),
    CONCEPT: ucf(t('states.accepted')),
  };
  return (
    <div className={classes(styles.base, className)} style={style}>
      <div className={classes(styles.name)}>
        <strong>{name}</strong>
      </div>

      <div className={classes(styles.subtext)}>
        {t('components.status.posted_on', {
          date: DateFormatter.toRelativeWithinDays(date),
        })}
      </div>

      {assessment && (
        <div className={classes(styles.subtext, styles.assessment)}>
          <span>{`${t('components.status.assessment')}:`}</span>
          <Status
            inline
            scheme={assessment === 'ACCEPTED' ? 'green' : 'red'}
            className={styles.status}
          >
            {statusOptions[assessment]}
          </Status>
        </div>
      )}

      <div
        className={classes(styles.content)}
        dangerouslySetInnerHTML={{ __html: sanitizer(feedback) }}
      />

      {files.map((file) => (
        <FileCard
          key={file.id}
          thumbnail={file.downloadUrl}
          className={classes(styles.file)}
          href={file.downloadUrl}
        >
          <span className={classes(styles.name)}>{file.originalFileName}</span>
        </FileCard>
      ))}
    </div>
  );
};
