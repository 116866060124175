import { useCallback, useMemo } from 'react';
import { Course, usePortalCourses } from '@brainstud/academy-api';
import { Button } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { Loading } from 'Components';
import { useModals } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { DateFormatter } from 'Utils/DateFormatHelper';
import { UnEnrollModal } from '../../Modals';
import { Badge } from '../Badge';
import styles from './CourseListItem.module.css';

type CourseListItemProps = {
  courseId?: string;
};

const cx = classNames.bind(styles);

const CourseListItem = ({ courseId }: CourseListItemProps) => {
  const [t] = useTranslator();
  const [{ data: courses }, { isLoading }] = usePortalCourses();
  const course = courses.find((c) => c.id === courseId);
  const { openModal } = useModals();

  const hasThumbnail =
    (course?.metadata?.media?.thumbnailUrl || course?.thumbnailUrl) &&
    (course?.metadata?.media?.thumbnailUrl?.search(
      /(.jpg|.png|.jpeg|.webp)/
    ) !== -1 ||
      course?.thumbnailUrl?.search(/(.jpg|.png|.jpeg|.webp)/) !== -1);
  const defaultThumbnail = '/media/abstract-thumbnail.jpg';
  const thumbnail = hasThumbnail
    ? course?.metadata?.media?.thumbnailUrl || course?.thumbnailUrl
    : defaultThumbnail;

  const participants = useMemo(() => course?.meta?.studentsCount, [course]);

  const unEnroll = useCallback(
    (e: React.MouseEvent, courseToUnEnroll: Course) => {
      e.preventDefault();
      openModal(UnEnrollModal, { course: courseToUnEnroll });
    },
    [openModal]
  );

  if (isLoading || !course) return <Loading />;

  return (
    <div className={cx('base')}>
      <img alt="" className={styles.thumbnail} src={`${thumbnail}?width=640`} />
      <div className={styles.info}>
        <p className={styles.title}>
          <strong>{course.title}</strong>
        </p>
        <div className={styles.badges}>
          <div className={styles.between}>
            {DateFormatter.isWithinDays(course.createdAt, 180) && (
              <Badge color="yellow">{t('components.courseCard.new')}</Badge>
            )}
            {course.status === 'COMING_SOON' && (
              <Badge color="yellow">
                {t('components.courseCard.comingSoon')}
              </Badge>
            )}
            {(course.educationProfiles?.() || []).map((profile) => (
              <Badge
                key={profile.id}
                className={styles.eduBadge}
                color="transparent"
              >
                {profile.title}
              </Badge>
            ))}
            {course.metadata?.informationLines?.sbu && (
              <Badge color="black" className={styles.sbuBadge}>
                {`${course.metadata.informationLines.sbu} ${t('SBU')}`}
              </Badge>
            )}
          </div>
        </div>
      </div>
      <div className={styles.meta}>
        {participants !== undefined && (
          <Badge color="grey">
            {t('components.courseCard.participants', { participants })}
          </Badge>
        )}
        {course.metadata?.informationLines?.sbu && (
          <Badge color="black">{`${course.metadata.informationLines.sbu} SBU`}</Badge>
        )}
        {course.enrollment?.() && (
          <Button
            type="button"
            className={styles.unEnrollBadge}
            link
            onClick={(e) => unEnroll(e, course)}
          >
            <Badge color="red">{t('components.courseCard.unenroll')}</Badge>
          </Button>
        )}
      </div>
    </div>
  );
};

export default CourseListItem;
