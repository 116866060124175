import { createContext } from 'react';
import { LearningSubject, Quiz } from '@brainstud/academy-api';
import {
  ILearningSubjectWithResources,
  TNodeListItem,
} from '../LearningRouteProvider';

export interface ILearningSubjectContext {
  isLoading: boolean;
  learningSubject?: ILearningSubjectWithResources | LearningSubject;
  usesAnswerGroups: boolean;
  quiz?: Quiz;
  subnodes: Array<TNodeListItem>;
}

type PartialLearningSubjectContext = Partial<ILearningSubjectContext> &
  Pick<ILearningSubjectContext, 'subnodes'>;

export const LearningSubjectContext =
  createContext<PartialLearningSubjectContext>({
    isLoading: true,
    subnodes: [],
  });
