import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  LearningObjectVariety,
  LearningObjectVarietyCreateInput,
  LearningObjectVarietyUpdateInput,
  UUID,
} from '../Types';

interface ILearningObjectVarietyProps {
  learningObject?: UUID;
  variety?: UUID;
}

export function useLearningObjectVariety(
  {
    learningObject,
    variety,
    ...queryParameters
  }: ILearningObjectVarietyProps = {},
  settings?: UseQueryOptions<DataDocument<LearningObjectVariety>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: {
      variety: defaultLearningObjectVariety,
      learningObject: defaultLearningObject,
    },
  } = context;
  const request = new ApiRequest(
    {
      enabled:
        !!(variety || defaultLearningObjectVariety) &&
        !!(learningObject || defaultLearningObject),
      baseName: 'v1.learningObjects.learningObjectVarieties',
      baseUri: `/v1/learning_objects/${learningObject || defaultLearningObject}/varieties`,
      uri: `/${variety || defaultLearningObjectVariety}`,
      invalidate: [
        'learning_object_variety',
        'learning_object_varieties',
        'learning_objects',
        'learning_object',
        'v1.enrollments.learningObjects',
        'v1.learningObjects',
      ],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    LearningObjectVariety,
    LearningObjectVarietyCreateInput,
    LearningObjectVarietyUpdateInput
  >(request, settings);
}

export function useLearningObjectVarieties(
  {
    learningObject,
    ...queryParameters
  }: Omit<ILearningObjectVarietyProps, 'variety'> = {},
  settings?: UseQueryOptions<
    DataDocument<LearningObjectVariety[]>,
    ErrorDocument
  >
) {
  const context = useApi();
  const {
    parameters: { learningObject: defaultLearningObject },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(learningObject || defaultLearningObject),
      baseName: 'v1.learningObjects.learningObjectVarieties',
      baseUri: `/v1/learning_objects/${learningObject || defaultLearningObject}/varieties`,
      invalidate: [
        'variety',
        'varieties',
        'learning_object_variety',
        'learning_object_varieties',
        'learning_objects',
        'learning_object',
        'v1.enrollments.learningObjects',
      ],
      queryParameters,
    },
    context
  );
  return useIndexCR<LearningObjectVariety, LearningObjectVarietyCreateInput>(
    request,
    settings
  );
}
