import React, { CSSProperties, PropsWithChildren } from 'react';

type Props = {
  style?: CSSProperties;
  className?: string;
};

/**
 * SimpleContainer.
 *
 * A simple div that accepts basic input
 */
export const SimpleContainer = ({
  className,
  style,
  children,
}: PropsWithChildren<Props>) => (
  <div className={className} style={style}>
    {children}
  </div>
);
