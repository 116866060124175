import { UseMutationOptions } from 'react-query';
import { useCreate } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import { LearningRoute, LearningRouteDuplicate, UUID } from '../Types';

interface ILearningRouteProps {
  learningRoute?: UUID;
}

export function useLearningRouteDuplicate(
  { learningRoute, ...queryParameters }: ILearningRouteProps = {},
  settings?: UseMutationOptions<
    DataDocument<LearningRoute>,
    ErrorDocument,
    LearningRouteDuplicate
  >
) {
  const context = useApi();
  const {
    parameters: { learningRoute: defaultLearningRoute },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(learningRoute || defaultLearningRoute),
      baseName: 'v1.learningRoutes.duplicate',
      baseUri: `/v1/learning_routes/${learningRoute || defaultLearningRoute}/duplicate`,
      invalidate: ['learning_route', 'learning_routes', 'v1.learningRoutes'],
      queryParameters,
    },
    context
  );
  return useCreate<LearningRoute, LearningRouteDuplicate>(request, settings);
}
