import { IResource } from '../Utils/Resource';

export interface EducationLevel extends IResource<'education_levels'> {
  /** An official title of the level */
  title: string;
  /** A user friendly default label for the level */
  label: string;
  /** A number to indicate the level */
  eqfNumber: number;
  /**
   * An overwrite for the default label.
   * Note: Only available when loaded as a relationship
   */
  customLabel?: string;
}

export interface EducationLevelCreateInput {
  title: string;
  label: string;
  eqf_number: number;
}

export type EducationLevelUpdateInput = Partial<EducationLevelCreateInput>;

export enum EducationLevelIncludes {
  education_group,
}

export enum EducationLevelFilters {
  title,
  label,
  eqf_number,
  eqf_label,
}

export enum EducationLevelSorts {
  title,
  label,
  eqf_number,
  eqf_label,
}
