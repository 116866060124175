import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { LearningObject } from '@brainstud/academy-api';
import { Callout } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import { LearningObjectEditRow } from '../Components/NodeOrderEditor/DraggableNodes';
import styles from './UnlinkedLearningObjects.module.css';

const cx = classNames.bind(styles);
export type UnlinkedLearningObjectsGroup = {
  id: string;
  learningObjects: LearningObject[];
};

type Props = {
  unlinkedGroup: UnlinkedLearningObjectsGroup;
};
/**
 * UnlinkedLearningObjects
 *
 * This component will show the not included learningObject in a custom learningRoute
 */
export const UnlinkedLearningObjects = ({ unlinkedGroup }: Props) => {
  const [t] = useTranslator();

  const plural = unlinkedGroup?.learningObjects?.length > 0;

  return (
    <div className={cx(styles.base)}>
      <Callout info>
        <div className={cx(styles.callout)}>
          <strong>
            {t('course-editor.unlinked_learning_objects.callout_title')}
          </strong>
          <span>
            {t('course-editor.unlinked_learning_objects.callout_content')}
          </span>
        </div>
      </Callout>
      <strong className={cx(styles['table-label'])}>
        {t(
          `course-editor.unlinked_learning_objects.new_object${plural ? 's' : ''}`
        )}
      </strong>
      <Droppable
        droppableId={unlinkedGroup.id}
        type="droppableGroup"
        isDropDisabled
      >
        {(providedProps) => (
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          <div ref={providedProps.innerRef} {...providedProps.droppableProps}>
            {unlinkedGroup?.learningObjects?.map((learningObject, idx) => (
              <LearningObjectEditRow
                item={learningObject}
                index={idx}
                key={learningObject.id}
                disabled
              />
            ))}
            {providedProps.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};
