import React, { useMemo } from 'react';
import {
  DraggableGroup,
  NodeOrderEditor,
} from '@brainstud/course-editor/Components';
import { Button, Callout } from '@brainstud/ui';
import { Add } from '@mui/icons-material';
import { Loading } from 'Components';
import {
  UnlinkedLearningObjects,
  UnlinkedLearningObjectsGroup,
  useLearningRouteEditorProvider,
} from 'Modules/course-editor/LearningRouteEditor';
import {
  useEnvironmentProvider,
  useLearningRouteProvider,
  useModals,
} from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { LearningRouteNodeModal } from './LearningRouteNodeModal';
import styles from './LearningRouteEditorView.module.css';

/**
 * Render an editor for editing the full learning route
 */
export const LearningRouteEditorView = () => {
  const { nodes, setNodes, isLoading } = useLearningRouteEditorProvider();
  const { learningObjectCollection, list, learningRoute, isCustomCollection } =
    useLearningRouteProvider();
  const { openModal } = useModals();
  const [t] = useTranslator();
  const { isAdminEnvironment } = useEnvironmentProvider();

  const learningObjectsInCollection =
    learningObjectCollection?.learningObjects?.();

  const notIncludedLearningObjects =
    useMemo<UnlinkedLearningObjectsGroup>(() => {
      const result = learningObjectsInCollection?.filter(
        (learningObject) =>
          !list?.some((listObject) => listObject.id === learningObject.id)
      );
      return {
        id: 'unlinkedLearningObjects',
        learningObjects: result ? [...result] : [],
      };
    }, [learningObjectsInCollection, list]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      {nodes.length <= 0 ? (
        <Callout info>
          {t('course-editor.learning_route_editor.no_nodes_yet')}
        </Callout>
      ) : (
        <NodeOrderEditor
          nodes={nodes}
          onChange={setNodes}
          unlinkedLearningObjects={notIncludedLearningObjects}
        >
          {/* New component here */}
          {notIncludedLearningObjects.learningObjects.length > 0 && (
            <UnlinkedLearningObjects
              unlinkedGroup={notIncludedLearningObjects}
            />
          )}
          {nodes.map((group, idx) => (
            <DraggableGroup key={group.id} item={group} index={idx} />
          ))}
        </NodeOrderEditor>
      )}
      <hr />
      {(isCustomCollection || isAdminEnvironment) && (
        <div className={styles.addNewContent}>
          <Button
            type="button"
            onClick={() => openModal(LearningRouteNodeModal, { learningRoute })}
            quiet
          >
            <Add className={styles.addNewContentIcon} />
            <span>{t('course-editor.learning_route_editor.add_chapter')}</span>
          </Button>
        </div>
      )}
    </>
  );
};
