import React, { useCallback } from 'react';
import { useLearningObjectVariety } from '@brainstud/academy-api';
import { Form } from '@brainstud/universal-components';
import classNames from 'classnames/bind';
import { RichTextEditor } from 'Components';
import { useLearningObjectProvider, useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import styles from './AssignmentCoachRemark.module.css';

const cx = classNames.bind(styles);

type FormData = {
  tips: string | null;
  teacher_remarks: string;
};

type TProps = {
  setIsLoading: (isLoading: boolean) => void;
};

export const AssignmentCoachRemark = ({ setIsLoading }: TProps) => {
  const [t] = useTranslator();
  const [setToast] = useToaster();
  const { learningObject, variety } = useLearningObjectProvider();
  const [{ update }] = useLearningObjectVariety(
    {
      learningObject: learningObject?.id,
      variety: variety?.id,
    },
    { enabled: false }
  );

  const handleSaveTips = useCallback(
    async ({ teacher_remarks, tips }: FormData) => {
      setIsLoading(true);
      await update.mutateAsync(
        {
          _method: 'patch',
          teacher_remarks,
          tips: sanitizer(tips as string, 'plainText') ? tips : null,
        },
        {
          onSuccess: () => {
            setToast(
              'views.courses.collection_edit.assignment_modal.coach_remarks.update.success',
              'success'
            );
            setIsLoading(false);
          },
          onError: (errorDocument) => {
            setToast(
              errorDocument.errors.map((error) => error.detail).join('<br />'),
              'error'
            );
            setIsLoading(false);
          },
        }
      );
    },
    [update, setToast, setIsLoading]
  );

  return (
    <Form
      onSubmit={handleSaveTips}
      id="assignment-coach-remark"
      className={cx(styles.base)}
    >
      <RichTextEditor
        name="tips"
        label={t(
          'views.courses.collection_edit.assignment_modal.coach_remarks.tips_participant'
        )}
        defaultValue={variety?.tips}
      />
      <RichTextEditor
        name="teacher_remarks"
        label={t(
          'views.courses.collection_edit.assignment_modal.coach_remarks.tips_coach'
        )}
        defaultValue={variety?.teacherRemarks || learningObject?.teacherRemarks}
      />
    </Form>
  );
};
