import React from 'react';
import { BlockRenderer, IBlock, Spacing } from '@brainstud/course-blocks';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import { EditorButton } from '../../Components';
import TuneButton from '../Tunes/TuneButton';
import styles from './Renderers.module.css';

const cx = classNames.bind(styles);

type TProps = {
  index: number;
  /** Whether to add toggles to edit the block */
  editable?: boolean;
  block: IBlock & { wideness?: string };
  blocks: IBlock[];
  onEdit: () => void;
};

export const PreviewBlockRenderer = ({
  index,
  block,
  blocks,
  onEdit,
  editable = true,
}: TProps) => {
  const [t] = useTranslator();

  return (
    <Spacing
      type={block.type}
      wideness={block.wideness}
      style={block.style}
      className={styles.preview}
    >
      {editable && (
        <div className={cx(styles.tuneBar)}>
          <EditorButton onClick={onEdit} className={cx(styles.button)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="currentColor"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
            </svg>
            <span>{t('course-editor.content_editor.edit')}</span>
          </EditorButton>
          <TuneButton index={index} block={block} blocks={blocks} />
        </div>
      )}
      <BlockRenderer id={block.id} block={block} spacing={false} />
    </Spacing>
  );
};
