import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import { IResource } from '../Types';

export default function useDestroy<
  Resource extends IResource,
  DestroyInput = void,
>(
  request: ApiRequest,
  queryConfig?: UseMutationOptions<
    DataDocument<Resource>,
    ErrorDocument,
    DestroyInput,
    any
  >
) {
  const queryClient = useQueryClient();
  return useMutation<DataDocument<Resource>, ErrorDocument, DestroyInput, any>(
    request.destroy.bind(request),
    {
      mutationKey: request.getKey('destroy') || '',
      onSuccess: () =>
        Promise.all<void>([
          queryClient.invalidateQueries([
            {
              [request.baseName]: true,
            },
          ]),
          ...request.invalidate.map((invalidKey) =>
            queryClient.invalidateQueries([{ [invalidKey]: true }])
          ),
        ]).then(),
      ...queryConfig,
    }
  );
}
