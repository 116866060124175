import React, { useCallback, useEffect, useState } from 'react';
import { GlossaryTerm } from '@brainstud/academy-api';
import GlossaryFormModal from '@brainstud/admin-panel/Views/Glossaries/GlossaryFormModal';
import { Dropdown } from '@brainstud/ui';
import { Add, Delete, Edit } from '@mui/icons-material';
import { AUTHOR_ROLES } from 'Config/roles';
import { useHasAnyRole } from 'Hooks';
import { useRouter } from 'next/router';
import { useModals } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { EditorButton } from '../../../EditorButton';
import { EditorTooltip, EditorTooltipButton } from '../../../EditorTooltip';
import { useRichTextEditorProvider } from '../../../useRichTextEditorProvider';

/**
 * DefinitionButton.
 *
 * These buttons can be shown when a rich text editor can toggle a link.
 */
export const DefinitionButton = () => {
  const { editor, terms = [] } = useRichTextEditorProvider();
  const [t] = useTranslator();
  const { openModal } = useModals();
  const router = useRouter();
  const { courseId } = router.query as { courseId?: string };

  const [glossaryId, setGlossaryId] = useState<string>();
  const [isOpen, setIsOpen] = useState(false);

  const isAuthor = useHasAnyRole(AUTHOR_ROLES);

  const previousTerm = editor.getAttributes('definition')['data-key'];
  const handleClose = useCallback(() => {
    const prevTerm = editor.getAttributes('definition')['data-key'];
    setIsOpen(false);
    if ((!glossaryId || glossaryId === '') && !prevTerm) {
      editor.commands.unsetDefinition();
    }
    setGlossaryId(undefined);
  }, [editor, glossaryId]);

  const handleAddDefinition = useCallback(
    (termId?: string) => {
      editor
        .chain()
        .focus()
        .setDefinition({ 'data-key': termId || glossaryId || '' })
        .run();
      setIsOpen((prevIsOpen) => !prevIsOpen);
    },
    [editor, glossaryId]
  );

  const handleNewTerm = useCallback(() => {
    openModal(GlossaryFormModal, {
      courseId,
      onSuccess: (term: GlossaryTerm) => {
        handleAddDefinition(term.id);
        setGlossaryId(term.id);
      },
    });
  }, [courseId, handleAddDefinition, openModal]);

  const handleRemoveDefinition = useCallback(() => {
    editor.commands.unsetDefinition();
  }, [editor]);

  useEffect(() => {
    if (isOpen && editor.isActive('definition')) {
      editor.commands.extendMarkRange('definition');
    }
  }, [editor, isOpen]);

  return (
    <>
      <div>
        {editor.can().toggleDefinition?.() && (
          <EditorButton
            active={editor.isActive('definition')}
            onClick={handleAddDefinition}
          >
            <svg height="1em" width="1em" viewBox="0 0 24 24">
              <path d="M4,6H2v14c0,1.1,0.9,2,2,2h14v-2H4V6z" />
              <path d="M20,2H8C6.9,2,6,2.9,6,4v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V4C22,2.9,21.1,2,20,2z M14.01,15 c-0.59,0-1.05-0.47-1.05-1.05c0-0.59,0.47-1.04,1.05-1.04c0.59,0,1.04,0.45,1.04,1.04C15.04,14.53,14.6,15,14.01,15z M16.51,8.83 c-0.63,0.93-1.23,1.21-1.56,1.81c-0.13,0.24-0.18,0.4-0.18,1.18h-1.52c0-0.41-0.06-1.08,0.26-1.65c0.41-0.73,1.18-1.16,1.63-1.8 c0.48-0.68,0.21-1.94-1.14-1.94c-0.88,0-1.32,0.67-1.5,1.23l-1.37-0.57C11.51,5.96,12.52,5,13.99,5c1.23,0,2.08,0.56,2.51,1.26 C16.87,6.87,17.08,7.99,16.51,8.83z" />
            </svg>
          </EditorButton>
        )}
      </div>

      <EditorTooltip
        shouldShow={({ editor: instance }) => instance.isActive('definition')}
        onClickOutside={!isOpen ? undefined : handleClose}
      >
        {!isOpen ? (
          <>
            <EditorTooltipButton onClick={() => setIsOpen(true)}>
              <Edit />
              <span>{t('edit')}</span>
            </EditorTooltipButton>
            <EditorTooltipButton onClick={handleRemoveDefinition}>
              <Delete />
            </EditorTooltipButton>
          </>
        ) : (
          <div style={{ display: 'flex', gap: '.8rem' }}>
            {!!courseId && isAuthor && (
              <EditorTooltipButton onClick={handleNewTerm}>
                <Add />
                <span>{t('new')}</span>
              </EditorTooltipButton>
            )}
            {terms.length > 0 && (
              <Dropdown
                placeholder={t('select')}
                value={glossaryId || previousTerm}
                onChange={(termId) => setGlossaryId(termId)}
              >
                {terms.map((term) => (
                  <Dropdown.Option key={term.id} value={term.id}>
                    {term.term}
                  </Dropdown.Option>
                ))}
              </Dropdown>
            )}
            <EditorTooltipButton onClick={() => handleAddDefinition()}>
              {t('save')}
            </EditorTooltipButton>
          </div>
        )}
      </EditorTooltip>
    </>
  );
};
