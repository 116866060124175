import React, { CSSProperties } from 'react';
import { CheckCircle } from '@mui/icons-material';
import classNames from 'classnames/bind';
import styles from './CompletedBadge.module.css';

const cx = classNames.bind(styles);

type Props = {
  style?: CSSProperties;
  className?: string;
  label?: string;
};

/**
 * CompletedBadge.
 *
 * A badge that can be used to indicate a completed state
 */
export const CompletedBadge = ({ className, style, label }: Props) => (
  <div className={cx(styles.base, className)} style={style}>
    <span className={cx(styles.content)}>
      <CheckCircle />
      <strong>{label}</strong>
    </span>
  </div>
);
