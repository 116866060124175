import React from 'react';
import classNames from 'classnames/bind';
import { BlockRenderer, Slider } from '../../../Components';
import { ISliderContainerBlock } from './Types';
import styles from './SliderContainer.module.css';

const cx = classNames.bind(styles);

export const SliderContainer = ({
  blocks,
}: Omit<ISliderContainerBlock, 'id' | 'type'>) => {
  const isOneSlide = blocks.length < 2;
  return (
    <div className={cx(styles.base)}>
      {isOneSlide ? (
        <>
          {blocks.map((block) => (
            <BlockRenderer key={block.id} id={block.id} block={block} />
          ))}
        </>
      ) : (
        <Slider>
          {blocks.map((block) => (
            <div key={block?.id} className={styles.blocksWrapper}>
              <BlockRenderer id={block.id} block={block} />
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};
