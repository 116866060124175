import { Enrollment } from '@brainstud/academy-api/Types/Resources/Enrollment';
import { LearningSubject } from '@brainstud/academy-api/Types/Resources/LearningSubject';
import { UUID } from '@brainstud/academy-api/Types/Utils/UUID';
import { DateTimeString } from '../Utils/DateTimeString';
import { IResource } from '../Utils/Resource';
import { Account } from './Account';
import { Answer, AnswerStatus } from './Answer';

export interface AnswerGroup extends IResource<'answer_group'> {
  status: AnswerStatus;
  score: number;
  coachViewedAt: DateTimeString;
  coachViewed?: boolean;
  createdAt: DateTimeString;
  completedAt: DateTimeString;
  enrollment?: () => Enrollment;
  answers?: () => Answer[];
  account?: () => Account;
  learningSubject: () => LearningSubject;
}

export interface AnswerGroupCreateInput {}

export interface AnswerGroupUpdateInput {
  status?: AnswerStatus;
  coach_viewed?: boolean;
}

export enum AnswerGroupIncludes {
  enrollment,
  account,
  answers,
  answers_count,
  'learning_subject',
  'enrollment.course',
  'answers.learning_object',
}
export interface AnswerGroupFilter {
  status: string | string[];
  account: UUID;
  enrollment: UUID;
  learning_subject: UUID;
  faker: string;
}
export enum AnswerGroupSorts {}
