/**
 * withTrailingSlash.
 *
 * Ensures a `/` at the end of the string.
 */
export function withTrailingSlash(input?: string): string {
  return input?.replace(/([^/])$/g, '$1/') || '';
}

/**
 * withoutTrailingSlash.
 *
 * Removes all `/` from the end of the string.
 */
export function withoutTrailingSlash(input?: string): string {
  return input?.replace(/~\/$/, '') || '';
}
