import React, { PropsWithChildren, useCallback } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import type { TRouteLearningNode } from '../../LearningRouteEditor/Provider/LearningRouteEditorContext';
import type { UnlinkedLearningObjectsGroup } from '../../LearningRouteEditor/UnlinkedLearningObjects';
import { isGroup } from '../../Support/is';

type TProps<T extends TRouteLearningNode> = PropsWithChildren<{
  nodes: T[];
  onChange: (nodes: T[]) => void;
  unlinkedLearningObjects?: UnlinkedLearningObjectsGroup;
}>;

/**
 * Render an editor for editing the full learning route
 */
export const NodeOrderEditor = <T extends TRouteLearningNode>({
  nodes,
  unlinkedLearningObjects,
  onChange,
  children,
}: TProps<T>) => {
  /**
   * Find the `LearningSubject` or `LearningObject` based on the `id`.
   */
  const findElementForId = useCallback(
    (list: T[], id: string) => list.find((item) => item.id === id),
    []
  );

  const handleDragEnd = useCallback(
    ({ source, destination, type }: DropResult) => {
      // Element got dropped outside of list
      if (!destination) return;

      const updatedList = Array.from<(typeof nodes)[0]>(nodes);
      const excludedList = unlinkedLearningObjects;
      const sourceIsUnlinked = source.droppableId === 'unlinkedLearningObjects';

      switch (type) {
        case 'droppableRoute': {
          // Remove element from source
          const [removed] = updatedList.splice(source.index, 1);

          // Add element to destination
          updatedList.splice(destination.index, 0, removed);
          break;
        }
        case 'droppableGroup':
          // eslint-disable-next-line no-case-declarations
          let sourceElement;
          if (!sourceIsUnlinked) {
            sourceElement = findElementForId(updatedList, source.droppableId);
          }

          // eslint-disable-next-line no-case-declarations
          const destinationElement = findElementForId(
            updatedList,
            destination.droppableId
          );

          if (!sourceIsUnlinked) {
            if (isGroup(sourceElement) && isGroup(destinationElement)) {
              const [nodeReference] = sourceElement.scheme.splice(
                source.index,
                1
              );
              destinationElement.scheme.splice(
                destination.index,
                0,
                nodeReference
              );
            }
          } else if (sourceIsUnlinked) {
            if (
              excludedList?.id === 'unlinkedLearningObjects' &&
              isGroup(destinationElement)
            ) {
              if (excludedList.learningObjects) {
                const [learningObject] = excludedList.learningObjects.splice(
                  source.index,
                  1
                );
                destinationElement.scheme.splice(
                  destination.index,
                  0,
                  learningObject
                );
              }
            }
          }
          break;
        default:
          throw new Error(`Droppable type ${type} not supported`);
      }

      onChange(updatedList);
    },
    [nodes, unlinkedLearningObjects, onChange, findElementForId]
  );

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="route" type="droppableRoute">
        {(provided) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
