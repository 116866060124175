import { PropsWithChildren, useCallback, useEffect } from 'react';
import { useForm } from 'Modules/universal-components';
import { useContentBlocksProvider } from '../../ContentBlocksProvider';

type TProps = {
  hasAnswer: boolean;
};

/**
 * AnswerFormInner.
 *
 * Small wrapper component to be able to hook into the events of the AnswerForm
 */
export const AnswerFormInner = ({
  children,
  hasAnswer,
}: PropsWithChildren<TProps>) => {
  const form = useForm();
  const { save, subscribe, disabled } = form;
  const { isStatic, isRead, readonly, resetTouched } =
    useContentBlocksProvider();

  const handleOnSubmit = useCallback(() => {
    save({ status: 'TURNED_IN', isStatic });
  }, [save, isStatic]);

  // Save form on submit
  useEffect(
    () => subscribe('submit', handleOnSubmit),
    [isStatic, handleOnSubmit, subscribe]
  );

  /**
   * Send in answer if assignment is static and all blocks are read.
   */
  useEffect(() => {
    if (isStatic && isRead && !disabled && !readonly && !hasAnswer) {
      handleOnSubmit();
    }
  }, [isStatic, save, disabled, readonly, isRead, hasAnswer, handleOnSubmit]);

  /**
   * Reset touched states on reset
   */
  useEffect(() => subscribe('reset', resetTouched), [subscribe, resetTouched]);

  return <>{children}</>;
};
