export function AssignmentTranslator(
  outcomes: Array<{ message?: string; parameter?: string | string[] }>,
  translations: {
    [key: string]:
      | string
      | ((data: { parameter?: string | string[] }) => string);
  }
) {
  return outcomes.reduce<string[]>((list, outcome) => {
    if (outcome.message) {
      const translation = translations[outcome.message]
        ? translations[outcome.message]
        : outcome.message;
      if (typeof translation === 'string') {
        list.push(translation);
      } else {
        list.push(translation({ parameter: outcome.parameter }));
      }
    }
    return list;
  }, []);
}
