import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useApi, useLearningObjectVariety } from '@brainstud/academy-api';
import { IBlock } from '@brainstud/course-blocks';
import { useLearningObjectProvider, useModals, useToaster } from 'Providers';
import { v4 } from 'uuid';
import { useActualObjectLayoutName } from 'Views/CollectionViews/ObjectViews/useActualObjectLayoutName';
import { ToolObject } from '../../../ToolArray';
import { createEmptyContainerBlock } from '../../Support/createEmptyContainerBlock';
import * as Migrations from '../Migrations';
import { migrateToVersion4 } from '../Migrations/version-4';
import { ToolModal } from '../Modals/ToolModal/ToolModal';
import {
  findAndAppendBlock,
  findAndRemoveBlock,
  findAndUpdateBlock,
  flattenBlockList,
  PartialSome,
  swapBlocksInTree,
} from './BlockActions';
import { ContentEditorContext, IEditorContext } from './ContentEditorContext';

type Props = {
  blocks?: IBlock[];
  children: React.ReactNode | ((state: IEditorContext) => React.ReactNode);
};

const createBlock = (block: PartialSome<IBlock, 'id' | 'type'>) => ({
  id: v4(),
  type: block.type || '',
  ...block,
});

function deepclone<T>(input: T): T {
  return JSON.parse(JSON.stringify(input)) as T;
}

/**
 * ContentEditorProvider.
 *
 * The ContentEditorProvider makes the current state of the learning object content available. It provides
 * the current content, as well as methods for saving and modifying the content.
 */
export const ContentEditorProvider = ({
  blocks: initialBlocks,
  children,
}: Props) => {
  const [previewMode, setPreviewMode] = useState(false);
  const [isUnsaved, setIsUnsaved] = useState(false);
  const [activeBlock, setActiveBlock] =
    useState<IEditorContext['activeBlock']>(null);
  const [activeEditor, setActiveEditor] =
    useState<IEditorContext['activeEditor']>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const {
    variety: primaryVariety,
    learningObject,
    isLoading,
  } = useLearningObjectProvider();

  const [{ update }] = useLearningObjectVariety(
    {
      variety: primaryVariety?.id,
      learningObject: learningObject?.id,
    },
    { enabled: false }
  );

  const { invalidateQueries } = useApi();
  const [blocks, setBlocks] = useState(deepclone(initialBlocks || []));

  const blockList = useMemo(() => flattenBlockList(blocks), [blocks]);

  const toolList = useMemo(
    () => blockList.map((block) => ToolObject[block.type]),
    [blockList]
  );
  const isOpen = useMemo(
    () => toolList.some((tool) => tool?.contentType === 'open'),
    [toolList]
  );
  const isClosed = useMemo(
    () => toolList.some((tool) => tool?.contentType === 'closed') && !isOpen,
    [toolList, isOpen]
  );
  const contentType = isOpen ? 'OPEN' : isClosed ? 'CLOSED' : 'STATIC';

  const container = useRef<HTMLDivElement>(null);
  const addBlock = useCallback<IEditorContext['addBlock']>(
    (block, parentContainerId) => {
      setIsUnsaved(true);
      const newBlock = createBlock(block);
      setBlocks((prevBlocks) =>
        findAndAppendBlock(prevBlocks, newBlock, parentContainerId)
      );
      return newBlock.id;
    },
    []
  );

  const swapBlocks = useCallback<IEditorContext['swapBlocks']>(
    (firstId, secondId) => {
      setIsUnsaved(true);
      setBlocks((prevBlocks) =>
        swapBlocksInTree(prevBlocks, firstId, secondId)
      );
    },
    []
  );

  const updateBlock = useCallback<IEditorContext['updateBlock']>(
    (block) => {
      if (isLoaded) {
        setIsUnsaved(true);
      }
      setBlocks((prevBlocks) =>
        findAndUpdateBlock(prevBlocks, block.id, block)
      );
    },
    [isLoaded]
  );

  const removeBlock = useCallback<IEditorContext['removeBlock']>((block) => {
    setIsUnsaved(true);
    setBlocks((prevBlocks) =>
      findAndRemoveBlock(
        prevBlocks,
        typeof block === 'string' ? block : block.id
      )
    );
  }, []);

  const [layout, setLayout] = useState<IEditorContext['layout']>(
    primaryVariety?.layout || 'rows'
  );
  const [points, updatePoints] = useState<IEditorContext['points']>(
    primaryVariety?.points || 0
  );

  const setPoints = useCallback((xp: string) => {
    const normalized = parseFloat(xp.trim().replace(' ', '').replace(',', '.'));
    updatePoints(Number.isNaN(normalized) ? 0 : normalized);
  }, []);

  const saveBlocks = useCallback(async () => {
    const migrated = migrateToVersion4(blocks, learningObject, primaryVariety);
    await update.mutateAsync(
      {
        _method: 'patch',
        content: migrated,
        content_type: contentType,
        ...(layout && { layout }),
        version: 4,
        points,
      },
      {
        onSuccess: () => {
          setBlocks(migrated);
          setIsUnsaved(false);
          invalidateQueries(['variety', 'varieties']);
        },
      }
    );
  }, [
    blocks,
    learningObject,
    primaryVariety,
    update,
    contentType,
    layout,
    points,
    invalidateQueries,
  ]);

  useEffect(() => {
    setIsLoaded(false);
  }, [primaryVariety]);

  useEffect(() => {
    if (primaryVariety && !isLoaded && learningObject) {
      const content = deepclone(primaryVariety?.content);
      const contentArray =
        (Array.isArray(content) ? content : content.components) || [];
      const migratedBlocks = Object.values(Migrations).reduce(
        (latestBlocks, Migration) => {
          const migrator = new Migration(
            latestBlocks,
            learningObject,
            primaryVariety
          );
          if (migrator.shouldRun()) {
            const migrated = migrator.run();
            // eslint-disable-next-line no-console
            console.log(`[${migrator.name}] executed:`, contentArray, migrated);
            return migrated;
          }
          return latestBlocks;
        },
        contentArray
      );
      setBlocks(migratedBlocks);
      setLayout(primaryVariety.layout);
      updatePoints(primaryVariety.points);
      setIsLoaded(true);
    }
  }, [primaryVariety, setPoints, isLoaded, learningObject]);

  useEffect(() => {
    if (isLoaded) {
      if (points !== primaryVariety?.points) {
        setIsUnsaved(true);
      }
      if (layout !== primaryVariety?.layout) {
        setIsUnsaved(true);
      }
    }
  }, [isLoaded, points, layout, primaryVariety]);

  // to support legacy panel layout learningObjects
  const layoutName = useActualObjectLayoutName();
  const isPanelTemplate = layoutName === 'panel';

  const handleAddBlock = useCallback(
    (toolType, containerId, data: Partial<IBlock> = {}) => {
      const tool = ToolObject[toolType];
      const blockId = v4();
      const block =
        !containerId && isPanelTemplate
          ? createEmptyContainerBlock([
              {
                id: blockId,
                type: toolType,
                ...(tool?.props
                  ? typeof tool.props === 'function'
                    ? tool.props()
                    : tool.props
                  : {}),
                ...data,
              },
            ])
          : {
              id: blockId,
              type: toolType,
              ...(tool?.props
                ? typeof tool.props === 'function'
                  ? tool.props()
                  : tool.props
                : {}),
              ...data,
            };
      addBlock(block, containerId);
      setActiveBlock(blockId);
    },
    [addBlock, isPanelTemplate]
  );

  const [setToast] = useToaster();
  const { openModal } = useModals();
  const handleOpenBlockModal = useCallback(
    (containerId) => {
      openModal(ToolModal, {
        layoutName,
        handleAddBlock,
        containerId,
      });
      return false;
    },
    [openModal, layoutName, handleAddBlock]
  );

  useHotkeys(
    'ctrl+c+t',
    () => {
      try {
        navigator.clipboard.writeText(JSON.stringify(blocks)).then(() => {
          setToast('📋 Copied content to clipboard.', 'success');
        });
      } catch (error) {
        setToast('Failed to copy content.', 'error');
      }
    },
    [blocks, setToast]
  );

  const isLoadingAny = isLoading || update.isLoading;
  const state: IEditorContext = useMemo(
    () => ({
      isContentEditor: true,
      learningObject,
      setLayout,
      layout,
      points,
      setPoints,
      setBlocks,
      blocks,
      addBlock,
      updateBlock,
      swapBlocks,
      removeBlock,
      save: saveBlocks,
      isUnsaved,
      isLoading: !isLoaded || isLoadingAny,
      previewMode,
      setPreviewMode,
      activeEditor,
      setActiveEditor,
      activeBlock,
      setActiveBlock,
      handleOpenBlockModal,
    }),
    [
      learningObject,
      layout,
      points,
      setPoints,
      blocks,
      addBlock,
      updateBlock,
      swapBlocks,
      removeBlock,
      saveBlocks,
      isUnsaved,
      isLoaded,
      isLoadingAny,
      previewMode,
      activeEditor,
      activeBlock,
      handleOpenBlockModal,
    ]
  );

  return (
    <ContentEditorContext.Provider value={state}>
      {!isLoaded ? null : (
        <div ref={container}>
          {typeof children === 'function' ? children(state) : children}
        </div>
      )}
    </ContentEditorContext.Provider>
  );
};
