import { AccountProfilePermissionOption } from '@brainstud/academy-api';

export type AccountProfilePermissions = {
  generalPermission: AccountProfilePermissionOption;
  avatarPermission: AccountProfilePermissionOption;
  contactPermission: AccountProfilePermissionOption;
  skillsAndInterestsPermission: AccountProfilePermissionOption;
};

export type AccountProfilePermissionActionType =
  | {
      type: 'updatePermission';
      payload: {
        permissionType: keyof AccountProfilePermissions;
        value: AccountProfilePermissionOption;
      };
    }
  | { type: 'updatePermissions'; payload: Partial<AccountProfilePermissions> };

export const AccountProfilePermissionReducer = (
  state: AccountProfilePermissions,
  action: AccountProfilePermissionActionType
): AccountProfilePermissions => {
  switch (action.type) {
    case 'updatePermission': {
      if (action.payload.permissionType === 'generalPermission') {
        const position = Object.keys(AccountProfilePermissionOption).indexOf(
          action.payload.value
        );
        const allowedOptions = Object.keys(
          AccountProfilePermissionOption
        ).slice(position);

        const newState: AccountProfilePermissions = {
          ...state,
          generalPermission:
            AccountProfilePermissionOption[action.payload.value],
        };

        return Object.entries(newState)
          .filter(([key]) => key !== 'generalPermission')
          .reduce(
            (previousValue, [key, value]) => ({
              ...previousValue,
              [key]: allowedOptions.includes(value)
                ? value
                : action.payload.value,
            }),
            newState
          );
      }

      return {
        ...state,
        [action.payload.permissionType]: action.payload.value,
      };
    }
    case 'updatePermissions': {
      return {
        generalPermission:
          action.payload.generalPermission ?? state.generalPermission,
        avatarPermission:
          action.payload.avatarPermission ?? state.avatarPermission,
        contactPermission:
          action.payload.contactPermission ?? state.contactPermission,
        skillsAndInterestsPermission:
          action.payload.skillsAndInterestsPermission ??
          state.skillsAndInterestsPermission,
      };
    }
    default:
      return state;
  }
};

export const profilePermissionDefault: AccountProfilePermissions = {
  generalPermission: AccountProfilePermissionOption.private,
  avatarPermission: AccountProfilePermissionOption.private,
  contactPermission: AccountProfilePermissionOption.private,
  skillsAndInterestsPermission: AccountProfilePermissionOption.private,
};
