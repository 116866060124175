import { useCallback, useEffect, useState } from 'react';

// Type for the value that will be stored in localStorage/sessionStorage
type TReturnValue<T> = T | undefined;
export type TReturn<T> = [
  TReturnValue<T>,
  (value: T | ((prevValue: TReturnValue<T>) => T)) => void,
  () => void,
];

export function useStorageEngine<T>(
  storageEngine: 'localStorage' | 'sessionStorage',
  key?: string,
  defaultValue?: T
): TReturn<T> {
  const [value, setValue] = useState<TReturnValue<T>>(() => {
    let initialValue: TReturnValue<T> = defaultValue ?? undefined;

    if (typeof window !== 'undefined' && key) {
      const storageValue = window[storageEngine]?.getItem(key);
      if (storageValue) {
        try {
          initialValue = JSON.parse(storageValue);
        } catch (error) {
          console.error(
            `Error parsing ${storageEngine} value for key "${key}":`,
            error
          );
        }
      }
    }

    return initialValue;
  });

  useEffect(() => {
    if (typeof window === 'undefined' || !key) return; // Skip when running server-side

    if (value !== null && value !== undefined) {
      try {
        window[storageEngine].setItem(key, JSON.stringify(value));
      } catch (error) {
        console.error(
          `Error storing ${storageEngine} value for key "${key}":`,
          error
        );
      }
    }
  }, [key, value, storageEngine]);

  const removeValue = useCallback(() => {
    if (typeof window !== 'undefined' && window[storageEngine] && !!key) {
      window[storageEngine].removeItem(key);
    }
    setValue(undefined);
  }, [storageEngine, key]);

  return [value, setValue, removeValue];
}
