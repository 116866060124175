import React, { forwardRef } from 'react';
import classNames from 'classnames/bind';
import { PanelProps } from './PanelProps';
import styles from './Panel.module.css';

const cx = classNames.bind(styles);

/**
 * Shows a basic white panel that stands out as a panel
 * @param children
 * @param pad
 * @param style
 * @param className
 * @constructor
 */
export const Panel = forwardRef<HTMLDivElement, PanelProps>(
  ({ children, pad, style, className }: PanelProps, ref) => (
    <div
      className={cx(
        styles.base,
        { pad, unpad: !pad },
        'panel-component',
        className
      )}
      style={style}
      ref={ref}
    >
      {children}
    </div>
  )
);
