import React, { createContext, ReactNode } from 'react';

export type TDragItem = {
  identifier: string;
  name: string;
  type: string;
  dropArea?: string;
  preview: React.ReactElement<any, any> | null;
  children?: ReactNode;
  className?: string | undefined;
  style?: React.CSSProperties | undefined;
};

export interface IDropLists {
  [key: string]: TDragItem[];
}

export interface IDragAndDropContext {
  list: IDropLists;
  createList: (name: string) => void;
  deleteList: (name: string) => void;
  addToList: (name: undefined | string, item: TDragItem) => void;
  moveToList: (listName: undefined | string, item: TDragItem) => void;
  findInLists: (item: TDragItem, lists: IDropLists) => string[];
  removeFromList: (name: string, item: TDragItem) => void;
  removeFromAllLists: (item: TDragItem) => void;
}

export const DragAndDropContext = createContext<null | IDragAndDropContext>(
  null
);
