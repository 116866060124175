import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { Button, Input } from '@brainstud/ui';
import { Form } from '@brainstud/universal-components';
import classNames from 'classnames/bind';
import { useTwoFactor } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import styles from './TwoFactorVerificationForm.module.css';

const cx = classNames.bind(styles);

export type TFormData = {
  verification_code: string;
};

type TProps = {
  onSubmit: (verification_code: string) => void;
};

export const TwoFactorVerificationForm = ({
  onSubmit,
  children,
}: PropsWithChildren<TProps>) => {
  const [t] = useTranslator();
  const ref = useRef<HTMLInputElement>(null);

  const { tfaError, loading } = useTwoFactor();

  useEffect(() => {
    if (tfaError && ref.current) {
      ref.current?.focus();
    }
  }, [tfaError, ref]);

  const handleSubmit = useCallback(
    (values: TFormData) => {
      onSubmit(values.verification_code);
    },
    [onSubmit]
  );

  return (
    <Form<TFormData>
      onSubmit={handleSubmit}
      autoReset={false}
      disableAfterSubmit={false}
      className={cx(styles.form)}
    >
      <Input
        ref={ref}
        inline
        type="text"
        valid={tfaError ? false : undefined}
        size={50}
        min={6}
        autoComplete="off"
        name="verification_code"
        label={t('security.two_factor.verification_code')}
        placeholder={t('security.two_factor.verification_code_placeholder')}
      />
      {children}
      <div>
        <Button type="submit" loading={loading}>
          <span>{t('next')}</span>
        </Button>
      </div>
    </Form>
  );
};
