import React, { ChangeEventHandler, CSSProperties, ReactNode } from 'react';
import classNames from 'classnames/bind';
import styles from 'Components/TileSelector/TileSelector.module.css';

const cx = classNames.bind(styles);

type Props = {
  id?: string;
  name: string;
  value?: string;
  style?: CSSProperties;
  className?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onClick?: () => void;
  children?: ReactNode;
  selected?: boolean;
  outline?: boolean;
  quiet?: boolean;
};

/**
 * TileSelector.
 *
 * Visually select something using Tiles
 */
export const TileSelector = ({
  id,
  name,
  value,
  style,
  className,
  onChange,
  onClick,
  children,
  selected,
  outline = false,
  quiet = false,
}: Props) => (
  <label
    htmlFor={id}
    className={cx(styles.base, className, {
      'is-quiet': quiet,
      'is-outline': outline,
      'is-selected': selected,
    })}
    style={style}
  >
    <input
      id={id}
      type="radio"
      name={name}
      onClick={onClick}
      onChange={onChange}
      value={value}
      className={styles.hidden}
    />
    {children}
  </label>
);
