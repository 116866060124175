import { UseQueryOptions } from 'react-query';
import { useIndex, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  Feature,
  FeatureCreateInput,
  FeatureFilters,
  FeatureIncludes,
  FeatureSort,
  FeatureUpdateInput,
  HookParams,
  UUID,
} from '../Types';

interface IFeatureProps
  extends HookParams<
    typeof FeatureIncludes,
    typeof FeatureFilters,
    typeof FeatureSort
  > {
  feature?: UUID;
}

/**
 * Retrieve a feature that is portal scoped.
 */
export function useFeature(
  { feature, ...queryParameters }: IFeatureProps = {},
  settings?: UseQueryOptions<DataDocument<Feature>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { feature: defaultFeature },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(feature || defaultFeature),
      baseName: 'v1.features',
      baseUri: '/v1/features',
      invalidate: ['portal', 'portals', 'v1.accounts.portals', 'v1.features'],
      uri: `/${feature}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<Feature, FeatureCreateInput, FeatureUpdateInput>(
    request,
    settings
  );
}

export function useFeatures(
  { ...queryParameters }: Omit<IFeatureProps, 'feature'> = {},
  settings?: UseQueryOptions<DataDocument<Feature[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.features',
      invalidate: ['features', 'feature'],
      baseUri: '/v1/features',
      queryParameters,
    },
    context
  );
  return useIndex<Feature>(request, settings);
}
