import { TNodeListItem } from './LearningRouteContext';

/**
 * expandNodeToList.
 *
 * A simple function to create an array of all the subnodes of a node.
 *
 * @param {TNodeListItem} node
 * @returns {TNodeListItem[]}
 */
export function expandNodeToList(node?: TNodeListItem): TNodeListItem[] {
  if (!node) {
    return [];
  }
  if (node.resourceType === 'learning_route_nodes') {
    return [
      node,
      ...(node.scheme ? node.scheme.map(expandNodeToList).flat() : []),
    ];
  }
  if (node.resourceType === 'learning_subjects') {
    return [
      node,
      ...(node.scheme ? node.scheme.map(expandNodeToList).flat() : []),
    ];
  }
  if (node.resourceType === 'learning_objects') {
    return [node];
  }
  return [];
}
