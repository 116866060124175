import React from 'react';
import { LearningObject } from '@brainstud/academy-api';
import { Accordion, Table } from '@brainstud/ui';
import { LearningObjectRow } from 'Components/CollectionsTable/LearningObjectRow';
import { useTranslator } from 'Providers/Translator';

export const LinkedLearningObjectsTable = ({
  learningObjects,
}: {
  learningObjects: LearningObject[];
}) => {
  const [t] = useTranslator();

  return (
    <Accordion
      key={learningObjects[0].id}
      title={t('views.courses.qualifications.linked_assignments')}
      quiet
      heading
    >
      <Table>
        <Table.TBody>
          {learningObjects.map((learningObject) => (
            <LearningObjectRow
              key={learningObject.id}
              object={learningObject}
              locked={false}
            />
          ))}
        </Table.TBody>
      </Table>
    </Accordion>
  );
};
