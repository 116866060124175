import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames/bind';
import Head from 'next/head';
import styles from './JsonEditor.module.css';

const classes = classNames.bind(styles);

type Props = {
  defaultValue?: string;
  modes?: Array<'tree' | 'text' | 'preview'>;
  onChange?: (value: string) => void;
  style?: CSSProperties;
  className?: string;
};

/**
 * JsonEditor.
 *
 * Loads the JSONEditor script from the public folder and initializes a div with the editor
 * within.
 */
export const JsonEditor = ({
  defaultValue,
  modes,
  onChange,
  className,
  style,
}: Props) => {
  const [instance, setInstance] = useState();
  const container = useRef<null | HTMLDivElement>(null);
  // @ts-ignore Externally loaded library
  const JSONEditor = window?.JSONEditor;

  const handleChange = useCallback(
    (textInput) => {
      onChange?.(JSON.parse(textInput));
    },
    [onChange]
  );

  useEffect(() => {
    if (JSONEditor && container.current && !instance) {
      setInstance(
        new JSONEditor(
          container.current,
          {
            modes,
            onChangeText: handleChange,
          },
          defaultValue
        )
      );
    }
  }, [instance, JSONEditor, handleChange, modes, defaultValue]);

  return (
    <>
      <Head>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/jsoneditor/9.9.0/jsoneditor.css"
        />
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jsoneditor/9.9.0/jsoneditor-minimalist.js" />
      </Head>
      <div
        ref={container}
        className={classes(styles.base, className)}
        style={style}
      />
    </>
  );
};
