import { Layouts, TLayoutConfig } from 'Views/CollectionViews/Layouts';

/**
 * getLayout.
 *
 * Retrieves a layout or falls back to the configured fallback layout view.
 */
export function getLayout(
  routeLayout: string,
  itemType?: string,
  itemLayout?: string
): TLayoutConfig;
export function getLayout(
  routeLayout?: string,
  itemType?: string,
  itemLayout?: string
): null | TLayoutConfig;
export function getLayout(
  routeLayout?: string,
  itemType?: string,
  itemLayout?: string
): null | TLayoutConfig {
  if (!routeLayout) return null;
  if (!itemType) {
    return Layouts[routeLayout];
  }

  const { sublayouts, fallback } = Layouts[routeLayout];

  return itemLayout && itemLayout in sublayouts[itemType]
    ? sublayouts[itemType][itemLayout]
    : fallback[itemType];
}
