import React, { PropsWithChildren } from 'react';
import classNames from 'classnames/bind';
import { DateFormatter } from 'Utils/DateFormatHelper';
import styles from './ActivityGroup.module.css';

const cx = classNames.bind(styles);

type TGroupProps = {
  date: string;
};

export const ActivityGroup = ({
  date,
  children,
}: PropsWithChildren<TGroupProps>) => (
  <section className={cx(styles.base)}>
    <div className={cx(styles.header)}>
      <span>{DateFormatter.toAbbreviatedDate(date, 'd MMM yyyy')}</span>
    </div>
    <div className={cx(styles.items)}>
      {React.Children.map(children, (child) => (
        <div className={styles.item}>{child}</div>
      ))}
    </div>
  </section>
);
