import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  AccountTag,
  AccountTagCreateInput,
  AccountTagFilters,
  AccountTagIncludes,
  AccountTagSorts,
  AccountTagUpdateInput,
  HookParams,
  UUID,
} from '../Types';

interface IAccountTagProps
  extends HookParams<
    typeof AccountTagIncludes,
    typeof AccountTagFilters,
    typeof AccountTagSorts
  > {
  account?: UUID;
  tag?: UUID;
}

/**
 * Account tags can be used to 'tag' an account with specific information, like a note or a class code.
 * @param tag
 * @param account
 * @param queryParameters
 * @param settings
 */
export function useAccountTag(
  { tag, account, ...queryParameters }: IAccountTagProps = {},
  settings?: UseQueryOptions<DataDocument<AccountTag>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { tag: defaultTag, account: defaultAccount },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(tag || defaultTag) && !!(account || defaultAccount),
      baseName: 'v1.accounts.tags',
      baseUri: `/v1/accounts/${account || defaultAccount}/tags`,
      uri: `/${tag}`,
      invalidate: ['tags', 'tag', 'v1.students'],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    AccountTag,
    AccountTagCreateInput,
    AccountTagUpdateInput
  >(request, settings);
}

/**
 * Account tags can be used to 'tag' an account with specific information, like a note or a class code.
 * @param tag
 * @param account
 * @param queryParameters
 * @param settings
 */
export function useAccountTags(
  { account, ...queryParameters }: Omit<IAccountTagProps, 'tag'> = {},
  settings?: UseQueryOptions<DataDocument<AccountTag[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { account: defaultAccount },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(account || defaultAccount),
      baseName: 'v1.accounts.tags',
      baseUri: `/v1/accounts/${account || defaultAccount}/tags`,
      invalidate: ['tags', 'tag', 'v1.students'],
      queryParameters,
    },
    context
  );
  return useIndexCR<AccountTag, AccountTagCreateInput>(request, settings);
}
