import { IResource } from '../Utils/Resource';
import { UUID } from '../Utils/UUID';

export interface NotificationPreference
  extends IResource<'notification_preferences'> {
  notify: {
    email: boolean;
    platform: boolean;
  };
}

export interface NotificationPreferenceUpdate {
  notify: {
    email: boolean;
    platform: boolean;
  };
}

export interface NotificationPreferenceCreate
  extends NotificationPreferenceUpdate {
  relationships?: {
    notification_type: UUID;
  };
}

export enum NotificationPreferenceIncludes {}

export enum NotificationPreferenceFilters {}

export enum NotificationPreferenceSorts {
  type,
}
