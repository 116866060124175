import React from 'react';
import { ImageBlock, VideoBlock } from '@brainstud/course-blocks';
import classNames from 'classnames/bind';
import { useLearningObjectProvider } from 'Providers';
import { useContentBlocksProvider } from 'Providers/ContentBlocksProvider';
import styles from './PrefixPoster.module.css';

const classes = classNames.bind(styles);
const hasPosterFirst = [
  'rich_media',
  'rich_title',
  'block_container',
  'slider_container',
  'tabbar_container',
];

/**
 * This adds an image or video block based on the learning object poster when the first (actual) block is a text block,
 * this is for backwards compatibility reasons.
 */
export const PrefixPoster = () => {
  const { blocks } = useContentBlocksProvider();
  const { learningObject } = useLearningObjectProvider();
  const posterUrl =
    learningObject?.metadata?.media?.videoUrl ||
    learningObject?.metadata?.media?.posterUrl ||
    learningObject?.poster?.src;

  const posterIsVideo =
    ((posterUrl?.includes('embed') || posterUrl?.includes('v=')) &&
      posterUrl?.includes('youtube')) ||
    posterUrl?.includes('vimeo');
  const isFirstRichMedia =
    hasPosterFirst.includes(blocks[0]?.type) && posterUrl;
  if (!isFirstRichMedia || !posterUrl) {
    return null;
  }
  return (
    <div className={classes(styles.base)}>
      {posterIsVideo ? (
        <VideoBlock id="" title="Intro video" src={posterUrl} />
      ) : (
        <ImageBlock
          id=""
          images={[{ id: 'prefix-poster-image', src: posterUrl, alt: '' }]}
        />
      )}
    </div>
  );
};
