import {
  Dropdown as SingleComponent,
  MultiDropdown as MultiComponent,
} from './Dropdown';
import { DropdownGroup } from './Group';
import { DropdownOption } from './Option';

type TSingleDropdownComponent = typeof SingleComponent;
type TMultiDropdownComponent = typeof MultiComponent;

export interface CompoundedSingleDropdown extends TSingleDropdownComponent {
  Option: typeof DropdownOption;
  Group: typeof DropdownGroup;
}
export interface CompoundedMultiDropdown extends TMultiDropdownComponent {
  Option: typeof DropdownOption;
  Group: typeof DropdownGroup;
}

const Dropdown = SingleComponent as CompoundedSingleDropdown;
Dropdown.Option = DropdownOption;
Dropdown.Group = DropdownGroup;

const MultiDropdown = MultiComponent as CompoundedMultiDropdown;
MultiDropdown.Option = DropdownOption;
MultiDropdown.Group = DropdownGroup;

export { Dropdown, MultiDropdown };
