import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslator } from 'Providers/Translator';
import styles from './PreviewQuiz.module.css';

const cx = classNames.bind(styles);

type TProps = {
  title?: string;
  poster?: string;
};

/**
 * Shows a simple preview of the learning subject
 */
export const PreviewQuiz: FC<TProps> = ({
  title,
  poster = '/media/quiz_placeholder.png',
}) => {
  const [t] = useTranslator();
  return (
    <div className={cx(styles.base)}>
      <img src={poster} alt="" />
      <div className={cx(styles.content)}>
        <h3>
          {title || t('views.courses.collection_edit.quiz_modal.form.title')}
        </h3>
      </div>
    </div>
  );
};
