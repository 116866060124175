import { UUID } from '../Utils/UUID';
import { Account } from './Account';
import { AssistantCoach } from './AssistantCoach';

type CoachAccountExcerpt =
  | 'id'
  | 'resourceType'
  | 'fullName'
  | 'firstName'
  | 'lastNameInsertion'
  | 'lastName';

export interface Coach extends Pick<Account<'coaches'>, CoachAccountExcerpt> {
  isActiveCoach: boolean;
  assistantCoaches?: () => AssistantCoach[];
}

export interface CoachCreateInput {
  coach?: UUID;
}

export enum CoachIncludes {
  'entity',
  'sub_entities',
  'assistant_coaches',
  'assistant_coaches.course',
  'assistant_coaches.primary_coach_account',
  'assistant_coaches.assistant_coach_account',
  'main_coaches',
  'main_coaches.course',
}

export interface CoachFilters {
  /** Filter coaches that are enrolled in specific course */
  course: UUID;
  /** Filter coaches on their name */
  full_name: string;
  /** Find the assistant coaches to a specific coach */
  primary_coach: UUID;
  /** Find the coaches that a specific coach assisting to */
  assistant_coach: UUID;
}

export enum CoachSorts {}
