import { UseQueryOptions } from 'react-query';
import { useShow } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  Profile,
  ProfileFilters,
  ProfileIncludes,
  ProfileSort,
  UUID,
} from '../Types';

interface IProfileProps
  extends HookParams<
    typeof ProfileIncludes,
    ProfileFilters,
    typeof ProfileSort
  > {
  profile?: UUID;
  slug?: string;
}

/**
 * Every account can have a public profile.
 * @param profile
 * @param slug
 * @param queryParameters
 * @param settings
 * @returns Profile
 */
export function useProfile(
  { profile, slug, ...queryParameters }: IProfileProps = {},
  settings?: UseQueryOptions<DataDocument<Profile>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { profile: defaultProfile },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(slug || profile || defaultProfile),
      baseName: 'v1.profile',
      baseUri: !slug ? '/v1/profiles' : '/v1/profiles/slug',
      uri: `/${slug || profile || defaultProfile}`,
      queryParameters,
    },
    context
  );
  return useShow<Profile>(request, settings);
}
