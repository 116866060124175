import { useCallback, useMemo } from 'react';
import { Coach, useAssistantCoach, useMe } from '@brainstud/academy-api';
import { Button } from '@brainstud/ui';
import { LinkOff } from '@mui/icons-material';
import { useTranslator } from 'Providers/Translator';
import { DateFormatter } from 'Utils/DateFormatHelper';
import { ReactComponent as CoachIcon } from '../../Assets/coach-icon.svg';
import styles from './CoachAssistant.module.css';

type Props = {
  assistant: Coach;
};

const CoachAssistant = ({ assistant }: Props) => {
  const [t] = useTranslator();
  const [me] = useMe();

  const assistantCoach = useMemo(
    () =>
      assistant
        .assistantCoaches?.()
        .find((a) => a.primaryCoach?.().id === me?.account?.().id),
    [assistant, me]
  );

  const [{ destroy }] = useAssistantCoach(
    {
      assistantCoach: assistantCoach?.id,
    },
    { enabled: false }
  );

  const decouple = useCallback(async () => {
    await destroy.mutateAsync();
  }, [destroy]);

  return (
    <div className={styles.root}>
      <figure className={styles.avatar}>
        <CoachIcon />
      </figure>
      <h4>
        {[assistant.firstName, assistant.lastName].filter(Boolean).join(' ')}
      </h4>
      <small>
        {t('coaches.connectedAt', {
          date: DateFormatter.toDate(assistantCoach?.createdAt),
        })}
      </small>
      <Button
        type="button"
        quiet
        onClick={decouple}
        loading={destroy.isLoading}
      >
        {t('coaches.decouple')}
        <LinkOff />
      </Button>
    </div>
  );
};

export default CoachAssistant;
