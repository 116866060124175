import { IResource } from '../Utils/Resource';

// The order matters for determining the available options, from most public to most protected.
export enum AccountProfilePermissionOption {
  public = 'public',
  portal = 'portal',
  private = 'private',
}

export interface AccountProfile extends IResource<'account_profiles'> {
  biography: string;
  avatarUrl: string;
  bannerUrl: string;
  slug: string;
  generalPermission: AccountProfilePermissionOption;
  contactPermission: AccountProfilePermissionOption;
  avatarPermission: AccountProfilePermissionOption;
  skillsAndInterestsPermission: AccountProfilePermissionOption;
}

export interface AccountProfileCreateInput {
  biography?: string;
  avatar_uuid?: string;
  banner_uuid?: string;
  slug?: string;
  general_permission?: AccountProfilePermissionOption;
  contact_permission?: AccountProfilePermissionOption;
  avatar_permission?: AccountProfilePermissionOption;
  skills_and_interests_permission?: AccountProfilePermissionOption;
}

export interface AccountProfileUpdateInput extends AccountProfileCreateInput {
  _method?: 'patch';
}

export enum AccountProfileIncludes {
  account,
}

export enum AccountProfileFilters {}

export enum AccountProfileSorts {}
