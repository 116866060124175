import React, { useCallback, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useContentTemplates } from '@brainstud/academy-api';
import { IBlock } from '@brainstud/course-blocks';
import { useContentEditor } from '@brainstud/course-editor';
import { reIdentifyBlockTree } from '@brainstud/course-editor/ContentEditor/Provider/BlockActions';
import { Button } from '@brainstud/ui';
import { ReactComponent as Check } from 'Assets/Icons/check.svg';
import classNames from 'classnames/bind';
import { Container } from 'Components/Container';
import { useSticky } from 'Hooks';
import { useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { Carousel } from '../Carousel';
import { TileSelector } from '../TileSelector';
import styles from './TemplateSelector.module.css';

const cx = classNames.bind(styles);

type Props = {
  className?: string;
};

/**
 * TemplateSelector.
 *
 * With this component you can select a different Template for a LearningObject
 */
export const TemplateSelector = ({ className }: Props) => {
  const [contentTemplates] = useContentTemplates();
  const [isOpen, setIsOpen] = useState(false);
  const { isSticking, stickyRef } = useSticky();
  const [setToast] = useToaster();

  const [t] = useTranslator();

  const { blocks, setBlocks, save, handleOpenBlockModal, setPreviewMode } =
    useContentEditor();
  const [selectedTemplate, setSelectedTemplate] = useState('build_yourself');

  const blockLength = blocks?.length || 0;
  useEffect(() => {
    if (blockLength === 0) {
      setIsOpen(true);
    }
  }, [blockLength]);

  const applyTemplate = useCallback(() => {
    const timer = setTimeout(() => {
      setIsOpen(false);
    }, 400);
    setPreviewMode(false);
    if (selectedTemplate !== 'build_yourself') {
      save();
      setToast('components.template_selector.success', 'success');
    } else {
      if (blockLength === 0) {
        handleOpenBlockModal();
      }
      save();
    }
    return () => clearTimeout(timer);
  }, [
    setPreviewMode,
    selectedTemplate,
    save,
    setToast,
    blockLength,
    handleOpenBlockModal,
  ]);

  const migrateTemplateBlocks = (templateBlocks: IBlock[]): IBlock[] =>
    templateBlocks.map((block) => ({ ...reIdentifyBlockTree(block) }));
  const handleTemplateSelection = useCallback(
    (template, templateId) => {
      if (templateId === 'build_yourself') {
        setPreviewMode(false);
        setSelectedTemplate('build_yourself');
        setBlocks([]);
      } else {
        setPreviewMode(true);
        setBlocks(migrateTemplateBlocks(template));
        setSelectedTemplate(templateId);
      }
    },
    [setBlocks, setPreviewMode]
  );

  // Adds the default build yourself template to the templates
  const extendContentTemplates = [
    {
      title: 'create',
      id: 'build_yourself',
      icon: 'https://cdn-a.unlimited.farm/course-content/files/mbmFgmrbhpC1XweLrkRLEltr7Er83gnRLa8ADg9A.svg',
      template: [],
    },
    ...contentTemplates,
  ];

  return (
    <>
      <div className={cx(styles.filler, { sticking: isSticking })} />
      <div
        className={cx(styles.base, className, { sticking: isSticking })}
        ref={stickyRef}
      >
        <AnimateHeight height={isOpen ? 'auto' : 0}>
          <div className={cx(styles.templateSelector)}>
            <Container>
              <div className={cx(styles.header)}>
                <div>
                  <strong>{t('components.template_selector.title')}</strong>
                </div>
                <Button
                  quiet
                  outline
                  onClick={() => applyTemplate()}
                  type="button"
                >
                  <span>{t('components.template_selector.save')}</span>
                  <Check />
                </Button>
              </div>
              <div className={cx(styles.templates)}>
                <div className={cx(styles.carouselInner)}>
                  <Carousel>
                    {extendContentTemplates?.map((contentTemplate) => (
                      <div
                        className={cx(styles.tileWrapper)}
                        key={contentTemplate.id}
                      >
                        <TileSelector
                          className={cx(styles.tile)}
                          key={contentTemplate.title}
                          name={contentTemplate.title}
                          value={contentTemplate.id}
                          selected={selectedTemplate === contentTemplate.id}
                          onClick={() =>
                            handleTemplateSelection(
                              contentTemplate.template,
                              contentTemplate.id
                            )
                          }
                          outline
                        >
                          <div className={cx(styles.tileContent)}>
                            <div className={cx(styles.tileHeader)}>
                              <strong
                                className={cx(styles.templateTitle, {
                                  selected:
                                    selectedTemplate === contentTemplate.id,
                                })}
                              >
                                {t(
                                  `components.template_selector.templates.${contentTemplate.title}`,
                                  undefined,
                                  contentTemplate.title
                                )}
                              </strong>
                            </div>
                            <div className={cx(styles.imageWrapper)}>
                              <img
                                className={cx(styles.image)}
                                src={contentTemplate.icon}
                                alt="template icon"
                              />
                            </div>
                          </div>
                        </TileSelector>
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
            </Container>
          </div>
        </AnimateHeight>
      </div>
    </>
  );
};
