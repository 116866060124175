import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  LearningSubject,
  LearningSubjectCreateInput,
  LearningSubjectFilters,
  LearningSubjectIncludes,
  LearningSubjectSorts,
  LearningSubjectUpdateInput,
  UUID,
} from '../Types';

interface ILearningSubjectProps
  extends HookParams<
    typeof LearningSubjectIncludes,
    typeof LearningSubjectFilters,
    typeof LearningSubjectSorts
  > {
  learningSubject?: UUID;
}

export function useLearningSubject(
  { learningSubject, ...queryParameters }: ILearningSubjectProps = {},
  settings?: UseQueryOptions<DataDocument<LearningSubject>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { learningSubject: defaultLearningSubject },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(learningSubject || defaultLearningSubject),
      baseName: 'v1.learningSubjects',
      baseUri: '/v1/learning_subjects',
      uri: `/${learningSubject}`,
      invalidate: ['learning_subjects', 'learning_subject'],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    LearningSubject,
    LearningSubjectCreateInput,
    LearningSubjectUpdateInput
  >(request, settings);
}

export function useLearningSubjects(
  { ...queryParameters }: Omit<ILearningSubjectProps, 'learningSubject'> = {},
  settings?: UseQueryOptions<DataDocument<LearningSubject[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.learningSubjects',
      baseUri: '/v1/learning_subjects',
      invalidate: ['learning_subjects', 'learning_subject'],
      queryParameters,
    },
    context
  );
  return useIndexCR<LearningSubject, LearningSubjectCreateInput>(
    request,
    settings
  );
}
