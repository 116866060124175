import { QueryClient } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 20 * 60000,
      cacheTime: 30 * 60000,
      retry: 1,
      retryDelay: (attemptIndex) => Math.min(2000 * 2 ** attemptIndex, 30000),
    },
  },
});

export default queryClient;
