import React, { useMemo } from 'react';
import {
  ExamSampleAnswer,
  useEnrollment,
  useExam,
} from '@brainstud/academy-api';
import { Button } from '@brainstud/ui';
import { ArrowForward } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Container, Loading } from 'Components';
import { useRouter } from 'next/router';
import { useModals } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import { AccordionTitle, BackButton } from '../../../Components';
import { Content } from '../../../Layouts';
import VideoLink from '../../../Utils/VideoLink';
import { ExamModal } from '../Modal/ExamModal';
import styles from './ExamFeedbackView.module.css';

const cx = classNames.bind(styles);

export const ExamFeedbackView = () => {
  const router = useRouter();
  const { courseId, examId } = router.query as {
    courseId: string;
    examId: string;
  };
  const [{ data: enrollment }] = useEnrollment();
  const [t] = useTranslator();
  const { openModal } = useModals();

  const [{ data: exam }, { isLoading }] = useExam({
    exam: examId,
  });
  const sampleAnswers = useMemo(() => exam?.sampleAnswers?.() || [], [exam]);
  const isReadyForExam = exam?.currentStep === 'PRE_ASSESSMENT_APPROVED';

  const iterations = useMemo(
    () =>
      sampleAnswers
        .reduce(
          (list, item) => {
            // eslint-disable-next-line no-param-reassign
            list[item.iteration - 1] = [
              ...(list[item.iteration - 1] || []),
              item,
            ];
            return list;
          },
          [] as Array<ExamSampleAnswer[]>
        )
        .reverse(),
    [sampleAnswers]
  );

  return (
    <Container>
      <Content className={cx('base')}>
        <BackButton />
        <h1>{t('views.exam.feedback.title')}</h1>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {iterations.map((iteration, idx) => (
              <AccordionTitle
                key={iteration[0].id}
                title={`${t('views.exam.feedback.iteration')} ${iterations.length - idx}`}
                open={idx === 0}
              >
                {iteration.map((item) => {
                  const learningObject = item.learningObject?.();
                  const videoLink = new VideoLink(learningObject?.poster?.src);
                  const verdict = item.assessmentPassed ? 'passed' : 'failed';
                  return (
                    <div key={item.id} className={cx(styles.card)}>
                      <div className={cx(styles.thumbnail)}>
                        <img
                          height={150}
                          src={videoLink.getThumbnail()}
                          alt=""
                        />
                      </div>
                      <div className={cx(styles.main)}>
                        <div className={cx(styles.title)}>
                          <h3>{learningObject?.title}</h3>
                          <span className={cx(styles.badge, verdict)}>
                            {t(`views.exam.feedback.${verdict}`)}
                          </span>
                        </div>
                        <div className={cx(styles.content)}>
                          <div className={cx(styles.feedback)}>
                            <strong>
                              {t('views.exam.feedback.coach_feedback')}
                            </strong>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: sanitizer(item.assessmentFeedback),
                              }}
                            />
                          </div>
                          <div className={cx(styles.cta_box)}>
                            <Button
                              outline
                              to={`/courses/${courseId}/detail/${learningObject?.id}`}
                            >
                              {t('views.exam.feedback.go_assignment')}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </AccordionTitle>
            ))}
          </>
        )}

        {isReadyForExam && (
          <div className={cx(styles.start_exam)}>
            <Button onClick={() => openModal(ExamModal, { exam, enrollment })}>
              <span>{t('views.exam.feedback.start_exam')}</span>
              <ArrowForward fontSize="large" />
            </Button>
          </div>
        )}
      </Content>
    </Container>
  );
};
