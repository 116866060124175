import React, { useCallback, useState } from 'react';
import { Button, Input } from '@brainstud/ui';
import { Close } from '@mui/icons-material';
import { useTranslator } from 'Providers/Translator';
import styles from './ExtraAnswersForm.module.css';

type Props = {
  identifiers: string[];
  onChange: (uuids: string[] | ((prevIds: string[]) => string[])) => void;
};

/**
 * ExtraAnswersForm.
 *
 * Make a list of identifiers nicely formatted.
 */
export const ExtraAnswersForm = ({ onChange, identifiers }: Props) => {
  const [t] = useTranslator();
  const [newUUID, setNewUUID] = useState<string>();

  const handleAddToIdList = useCallback(() => {
    if (newUUID && !identifiers.includes(newUUID)) {
      onChange((prevIds) => [...prevIds, newUUID]);
    }
    setNewUUID('');
  }, [identifiers, newUUID, onChange]);

  const handleRemoveFromIdList = useCallback(
    (id: string) => {
      onChange((prevIds) => prevIds.filter((item) => item !== id));
    },
    [onChange]
  );

  return (
    <div className={styles.base}>
      <div className={styles.fieldset}>
        <Input
          type="text"
          label={t(
            'course-editor.content_editor.block_config_modal.extra_answer_identifiers.identifier'
          )}
          onChange={(event) => setNewUUID(event.target.value)}
          value={newUUID}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              handleAddToIdList();
            }
          }}
        />
        <Button type="button" onClick={handleAddToIdList}>
          {t(
            'course-editor.content_editor.block_config_modal.extra_answer_identifiers.add_item'
          )}
        </Button>
      </div>
      {identifiers.map((id) => (
        <div className={styles.entry} key={id}>
          <span className={styles.uuid}>{id}</span>
          <button type="button" onClick={() => handleRemoveFromIdList(id)}>
            <Close />
          </button>
        </div>
      ))}
    </div>
  );
};
