import { getColorContrast, hexToRgbValues, rgbToValues } from '../Utils/Color';

/**
 * Returns either first or second color based on the contrast threshold
 */
export function useContrastColor(
  backgroundColor?: string,
  primaryColor: string = '#000000',
  alternativeColor: string = '#FFFFFF',
  threshold: number = 4.5
) {
  if (!backgroundColor) {
    return primaryColor;
  }
  const colorBg =
    backgroundColor.charAt(0) === '#'
      ? hexToRgbValues(backgroundColor)
      : rgbToValues(backgroundColor);
  const colorPrimary =
    primaryColor.charAt(0) === '#'
      ? hexToRgbValues(primaryColor)
      : rgbToValues(primaryColor);

  const contrastPrimary = getColorContrast(colorPrimary, colorBg);

  if (contrastPrimary > threshold) {
    return primaryColor;
  }
  const colorAlternative =
    alternativeColor.charAt(0) === '#'
      ? hexToRgbValues(alternativeColor)
      : rgbToValues(alternativeColor);
  const contrastAlternative = getColorContrast(colorAlternative, colorBg);

  if (contrastAlternative > threshold) {
    return alternativeColor;
  }
  return contrastPrimary >= contrastAlternative
    ? primaryColor
    : alternativeColor;
}
