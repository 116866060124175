import { useLearningRouteEditorProvider } from '@brainstud/course-editor';
import { Container } from 'Components';
import { CollectionView } from 'Views/CollectionViews';
import { useReducedUserExperienceWarning } from '../../../Hooks';
import { LearningRouteEditorView } from './LearningRouteEditorView';

export const CollectionEditView = () => {
  const { preview, nodes } = useLearningRouteEditorProvider();

  useReducedUserExperienceWarning();

  return (
    <>
      {preview ? (
        <CollectionView nodes={nodes} />
      ) : (
        <Container style={{ marginTop: '2rem' }}>
          <LearningRouteEditorView />
        </Container>
      )}
    </>
  );
};
