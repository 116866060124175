import React from 'react';
import classNames from 'classnames/bind';
import { IHTMLBaseProperties } from '../../Types/ElementTypes';
import styles from './LoadingDots.module.css';

const cx = classNames.bind(styles);

/**
 * Shows a small CSS vertical line of dots indicating a loading action
 */
export const LoadingDots = ({ className, style }: IHTMLBaseProperties) => (
  <div className={cx(styles.base, className)} style={style}>
    <div />
    <div />
    <div />
    <div />
  </div>
);
