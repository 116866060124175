import { DateTimeString } from '../Utils/DateTimeString';
import { IResource } from '../Utils/Resource';

export interface AccountTag extends IResource<'account_tags'> {
  tag: string;
  payload: string;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
}

export interface AccountTagCreateInput {
  tag: string;
  contents: string;
}

export interface AccountTagUpdateInput extends AccountTagCreateInput {}

export enum AccountTagIncludes {}

export enum AccountTagFilters {}

export enum AccountTagSorts {}
