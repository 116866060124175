import React, { FC, useEffect, useState } from 'react';
import { useApi } from '@brainstud/academy-api';
import { IRichListBlock } from '@brainstud/course-blocks';
import { UploadButton } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { v4 } from 'uuid';
import { RichTextEditor } from '../../../../../Components';
import { EditorButton } from '../../../Components';
import styles from './ListItemForm.module.css';

const cx = classNames.bind(styles);

type TItem = IRichListBlock['items'][0];
type TProps = {
  index: number;
  item: TItem;
  listStyle: IRichListBlock['listStyle'];
  onChange: (idx: number, item?: Partial<TItem>) => void;
};

/**
 * Shows a form whit which you can add list items and upload icons for those list items.
 */
export const ListItemForm: FC<TProps> = ({
  index,
  item,
  listStyle,
  onChange,
}) => {
  const { baseUrl, headers: apiHeaders } = useApi();
  const [id] = useState(item.id || v4());
  const [iconUrl, setIconUrl] = useState<TItem['icon']>(item.icon);
  const [content, setContent] = useState(item.content);

  useEffect(() => {
    onChange(index, {
      id,
      icon: iconUrl,
      content,
    });
  }, [index, id, iconUrl, content]);

  const prefix = {
    icons: (
      <img
        alt=""
        width="45"
        height="45"
        className={styles.image}
        src={item.icon}
      />
    ),
    ol: <span className={styles.numbered}>{index + 1}</span>,
    ul: null,
  }[listStyle || 'ol'];

  return (
    <div className={cx(styles.base, listStyle)}>
      <div className={cx(styles.iconUpload)}>
        <div className={cx(styles.icon)}>{prefix}</div>
        {listStyle === 'icons' && (
          <UploadButton
            url={`${baseUrl}/v1/services/media_upload`}
            headers={apiHeaders}
            paramName="files[0]"
            maxFiles={1}
            className={styles.uploadButton}
            small
            outline={!!iconUrl}
            onAfterFileUpload={(file: any, response: any) => {
              setIconUrl(response?.data?.[0]?.attributes?.file_url);
            }}
          />
        )}
      </div>
      <div className={cx(styles.content)}>
        <RichTextEditor
          id={id}
          preset="inline"
          defaultValue={item.content}
          onChange={setContent}
        />
      </div>
      <div className={cx(styles.remove)}>
        <EditorButton onClick={() => onChange(index)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="currentColor"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" />
          </svg>
        </EditorButton>
      </div>
    </div>
  );
};
