import { useCallback, useState } from 'react';
import { useCoaches, useCoachMandate } from '@brainstud/academy-api';
import { Button, SidebarModal } from '@brainstud/ui';
import { Dropdown } from '@brainstud/ui/Form Input/Dropdown/Dropdown';
import { DropdownOption } from '@brainstud/ui/Form Input/Dropdown/Option';
import classNames from 'classnames/bind';
import { Form } from 'Modules/universal-components';
import { useModals, useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import styles from './MentorChangeCoachModal.module.css';

const cx = classNames.bind(styles);

type TProps = {
  enrollmentId: string;
  courseName?: string;
  currentCoachId?: string;
  courseId?: string;
};

export const MentorChangeCoachModal = ({
  enrollmentId,
  courseName,
  courseId,
  currentCoachId,
}: TProps) => {
  const [t] = useTranslator();
  const { closeModal } = useModals();
  const [{ data: coaches }] = useCoaches({ filter: { course: courseId } });
  const [{ createOrUpdate }] = useCoachMandate({
    enrollment: enrollmentId,
    invalidate: ['enrollments.coach'],
  });
  const [coachId, setCoachId] = useState(currentCoachId || '');
  const [setToast] = useToaster();

  const handleOnSubmit = useCallback(async () => {
    await createOrUpdate.mutateAsync(
      { coach: coachId },
      {
        onSuccess: () => {
          setToast(
            t('modals.mentor.change_coach.success', { courseName }),
            'success'
          );
          closeModal();
        },
        onError: () => {
          setToast(t('error'), 'error');
        },
      }
    );
  }, [createOrUpdate, coachId, setToast, closeModal, t, courseName]);

  const handleChangeCoach = (newCoachId?: string) =>
    setCoachId(newCoachId || '');

  return (
    <SidebarModal
      className={styles.base}
      closeable
      onClickOutside
      onClose={closeModal}
      size="medium"
    >
      <header className={cx(styles.header)}>
        <h2>{t('modals.mentor.change_coach.title')}</h2>
        <Button type="submit" form="change-coach">
          <span>{t('save')}</span>
        </Button>
      </header>

      <p className={cx(styles.intro)}>
        {t('modals.mentor.change_coach.intro')}
      </p>
      <p className={cx(styles.course)}>{courseName}</p>

      <Form onSubmit={handleOnSubmit} id="change-coach">
        <Dropdown
          name="coachId"
          value={coachId}
          label={t('coach')}
          className={cx(styles.dropdown)}
          searchable={coaches.length > 5}
          rules="required"
          onChange={handleChangeCoach}
        >
          {coaches.map((coach) => (
            <DropdownOption key={coach.id} value={coach.id}>
              {coach.fullName}
            </DropdownOption>
          ))}
        </Dropdown>
      </Form>
    </SidebarModal>
  );
};
