import { UseQueryOptions } from 'react-query';
import { useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  EventCalendarItemEnrollment,
  EventCalendarItemEnrollmentCreateInput,
  EventCalendarItemEnrollmentFilters,
  EventCalendarItemEnrollmentIncludes,
  EventCalendarItemEnrollmentSorts,
  EventCalendarItemEnrollmentUpdateInput,
  HookParams,
  UUID,
} from '../Types';

interface IEventCalendarItemEnrollmentProps
  extends HookParams<
    typeof EventCalendarItemEnrollmentIncludes,
    typeof EventCalendarItemEnrollmentFilters,
    typeof EventCalendarItemEnrollmentSorts
  > {
  eventCalendarItem?: UUID;
  eventCalendarItemEnrollment?: UUID;
}

export function useEventCalendarItemEnrollment(
  {
    eventCalendarItem,
    eventCalendarItemEnrollment,
    ...queryParameters
  }: IEventCalendarItemEnrollmentProps,
  settings?: UseQueryOptions<
    DataDocument<EventCalendarItemEnrollment>,
    ErrorDocument
  >
) {
  const context = useApi();
  const {
    parameters: {
      eventCalendarItem: defaultEventCalendarItem,
      eventCalendarItemEnrollment: defaultEventCalendarItemEnrollment,
    },
  } = context;
  const request = new ApiRequest(
    {
      enabled:
        !!(eventCalendarItemEnrollment || defaultEventCalendarItemEnrollment) &&
        !!(eventCalendarItem || defaultEventCalendarItem),
      baseName: 'v1.eventCalendars.items.enrollments',
      baseUri: `/v1/event_calendar_items/${eventCalendarItem || defaultEventCalendarItem}/enrollments`,
      uri: `/${eventCalendarItemEnrollment || defaultEventCalendarItemEnrollment}`,
      invalidate: ['current_user_enrollment', 'items.current_user_enrollment'],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    EventCalendarItemEnrollment,
    EventCalendarItemEnrollmentCreateInput,
    EventCalendarItemEnrollmentUpdateInput
  >(request, settings);
}
