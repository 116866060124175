import { useCallback, useMemo } from 'react';
import { useAccount, useEnrollment, useStudent } from '@brainstud/academy-api';
import { Button, ContentModal, Status } from '@brainstud/ui';
import {
  BusinessCenter,
  DesktopMac,
  Email,
  HomeWork,
  Phone,
  School,
} from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Avatar } from 'Components';
import { useTranslator } from 'Providers/Translator';
import { DateFormatter } from 'Utils/DateFormatHelper';
import styles from './StudentDetailModal.module.css';

const cx = classNames.bind(styles);

interface StudentDetailModalProps {
  /** The studentId for which to show the informaion */
  studentId: string;
  /** An optional enrollmentId to overwrite the first found enrollment for a student  */
  enrollmentId?: string;
  /** An optional action to perform on closing the modal */
  closeModal: () => void;
}

/**
 * StudentDetailModal.
 *
 * A modal that shows participant information based on the given studentId, along with any enrollment information
 * for the first enrollment for the student, or any specific enrollment given.
 */
export const StudentDetailModal = ({
  studentId,
  enrollmentId,
  closeModal,
}: StudentDetailModalProps) => {
  const [t] = useTranslator();
  const [{ data: student }] = useStudent({
    student: studentId,
    include: ['enrollments'],
  });

  const [{ data: account }] = useAccount({
    account: studentId,
    include: [
      'skills',
      'interests',
      'tags',
      'subscriptions',
      'orders',
      'product_activations',
      'product_activations.product',
      'subscriptions.plan',
    ],
  });

  const entity = useMemo(() => account?.entity?.().name, [account]);

  const findTagByName = useCallback(
    (name: string, fallback?: string) =>
      account?.tags?.().find((item) => item.tag === name)?.payload || fallback,
    [account]
  );

  const classCode = useMemo(
    () => findTagByName('class_code') || account?.classCode || '',
    [account, findTagByName]
  );

  const note = useMemo(() => findTagByName('note', ''), [findTagByName]);

  const defaultEnrollmentId = useMemo(
    () => enrollmentId || student?.enrollments?.()?.[0]?.id,
    [enrollmentId, student]
  );

  const [{ data: enrollment }] = useEnrollment(
    { enrollment: defaultEnrollmentId },
    { enabled: !!defaultEnrollmentId }
  );
  const educationLevel = useMemo(
    () => enrollment?.educationLevel?.(),
    [enrollment]
  );

  const subscriptions = useMemo(
    () =>
      (
        account?.subscriptions?.() || [
          ...(account?.subscription ? [account.subscription()] : []),
        ]
      ).filter((subscription) => subscription.isActive),
    [account]
  );

  return (
    <ContentModal onClickOutside onClose={closeModal} className={styles.base}>
      <div className={cx(styles.header)}>
        <Avatar outline name={student?.fullName} />
        <h1>{student?.fullName}</h1>
      </div>

      <h3>{t('modals.student_details.course_information')}</h3>
      <div className={cx(styles.blocks, styles.section)}>
        <dl>
          <dt>{t('modals.student_details.contentset')}</dt>
          <dd>{enrollment?.learningObjectCollection?.().title}</dd>
          <dt>{t('modals.student_details.level')}</dt>
          <dd>{educationLevel?.label}</dd>
          <dt>{t('modals.student_details.class')}</dt>
          <dd>{classCode || '-'}</dd>
        </dl>
        <div className={cx(styles.block)}>
          <div className={cx(styles.notes)}>
            <span>{t('modals.student_details.notes')}:</span>
            <div>{note || t('modals.student_details.no_notes')}</div>
          </div>
        </div>
      </div>

      <h3>{t('modals.student_details.participant_information')}</h3>
      <div className={cx(styles.blocks, styles.section)}>
        <dl>
          <dt>
            <Email />
            {t('modals.student_details.email_address')}
          </dt>
          <dd>{account?.emailAddress || '-'}</dd>
          <dt>
            <Phone />
            {t('modals.student_details.phone_number')}
          </dt>
          <dd>{account?.phoneNumber || '-'}</dd>
          <dt>
            <DesktopMac />
            {t('modals.student_details.last_online')}
          </dt>
          <dd>{DateFormatter.toDateTime(account?.lastOnline) || '-'}</dd>
        </dl>

        <dl>
          <dt>
            <School />
            {t('modals.student_details.education')}
          </dt>
          <dd>{account?.education || '-'}</dd>
          <dt>
            <BusinessCenter />
            {t('modals.student_details.sector')}
          </dt>
          <dd>{account?.sector || '-'}</dd>
          <dt>
            <HomeWork />
            {t('modals.student_details.organization')}
          </dt>
          <dd>{entity || '-'}</dd>
        </dl>
      </div>
      {(account?.interests?.() || account?.skills?.()) && (
        <>
          <h3>{t('modals.student_details.skills_and_interests')}</h3>
          <div className={cx(styles.section)}>
            {account?.interests?.() && (
              <>
                <h3>{t('modals.student_details.interests')}</h3>
                <div className={cx(styles.chips)}>
                  {account?.interests?.().map((interest) => (
                    <Status
                      key={interest.id}
                      scheme="blue"
                      className={cx(styles.chip)}
                    >
                      {interest.title}
                    </Status>
                  ))}
                </div>
              </>
            )}
            {account?.skills?.() && (
              <>
                <h3>{t('modals.student_details.skills')}</h3>
                <div className={cx(styles.chips)}>
                  {account?.skills?.().map((skill) => (
                    <Status
                      key={skill.id}
                      scheme="blue"
                      className={cx(styles.chip)}
                    >
                      {skill.title}
                    </Status>
                  ))}
                </div>
              </>
            )}
          </div>
        </>
      )}

      <h3>{t('modals.student_details.activated_subscriptions')}</h3>
      <div className={cx(styles.blocks, styles.section)}>
        {subscriptions.map((sub) => (
          <dl key={sub.id}>
            {sub.plan && (
              <>
                <dt>{t('modals.student_details.product')}</dt>
                <dd>{sub.plan().name}</dd>
              </>
            )}
            <dt>{t('modals.student_details.end_date')}</dt>
            <dd>{DateFormatter.toDateTime(sub.endDate) || '-'}</dd>
          </dl>
        ))}
      </div>

      {(account?.productActivations?.() || []).length > 0 && (
        <>
          <h3>{t('modals.student_details.activated_products')}</h3>
          <div className={cx(styles.blocks, styles.section)}>
            {account?.productActivations?.().map((productActivation) => (
              <dl key={productActivation.id}>
                {productActivation.product && (
                  <>
                    <dt>{t('modals.student_details.product')}</dt>
                    <dd>{productActivation.product().name}</dd>
                  </>
                )}
                {productActivation.validFrom && (
                  <>
                    <dt>{t('modals.student_details.start_date')}</dt>
                    <dd>
                      {DateFormatter.toDateTime(productActivation.validFrom) ||
                        '-'}
                    </dd>
                  </>
                )}
                {productActivation.validUntil && (
                  <>
                    <dt>{t('modals.student_details.end_date')}</dt>
                    <dd>
                      {DateFormatter.toDateTime(productActivation.validUntil) ||
                        '-'}
                    </dd>
                  </>
                )}
              </dl>
            ))}
          </div>
        </>
      )}

      <ContentModal.Footer>
        <Button type="button" quiet onClick={closeModal}>
          {t('modals.close')}
        </Button>
      </ContentModal.Footer>
    </ContentModal>
  );
};
