import React, { FC, useMemo, useState } from 'react';
import { LearningObject, useLearningObject } from '@brainstud/academy-api';
import { Callout, ContentModal, Dropdown } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { Loading, Tabs, TabsItem } from 'Components';
import { useEnvironmentProvider } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import styles from './AssignmentDetailsModal.module.css';

const cx = classNames.bind(styles);

interface IAssignmentDetailsModal {
  assignment: LearningObject;
  closeModal: () => void;
}

/**
 * Modal containing all data concerning a learning object
 */
const AssignmentDetailsModal: FC<IAssignmentDetailsModal> = ({
  assignment,
  closeModal,
}) => {
  const [t] = useTranslator();
  const { isAdminEnvironment } = useEnvironmentProvider();
  const [active, setActive] = useState('workprocesses');
  const [level, setLevel] = useState(0);

  const [{ data: assignmentDetails }, { isLoading }] = useLearningObject({
    learningObject: assignment?.id,
    include: [
      'varieties',
      'varieties.dossier_workprocesses',
      'varieties.dossier_workprocess_behaviours',
      'varieties.dossier_knowledge_skills',
    ],
  });

  const processes = useMemo(
    () =>
      assignmentDetails?.varieties?.()[level].dossierWorkprocesses?.() || [],
    [assignmentDetails, level]
  );

  const behaviours = useMemo(
    () =>
      assignmentDetails
        ?.varieties?.()
        [level].dossierWorkprocessBehaviours?.() || [],
    [assignmentDetails, level]
  );

  const skills = useMemo(
    () =>
      assignmentDetails?.varieties?.()[level].dossierKnowledgeSkills?.() || [],
    [assignmentDetails, level]
  );

  const remarks = useMemo(
    () =>
      assignmentDetails?.varieties?.()[level].teacherRemarks ||
      assignmentDetails?.teacherRemarks,
    [assignmentDetails, level]
  );
  const remarksWithoutTags = remarks?.replace(/(<([^>]+)>)/gi, '');
  const remarksNotEmpty = remarks && !!remarksWithoutTags?.length;

  return (
    <ContentModal
      onClickOutside
      onClose={closeModal}
      className={cx(styles.base)}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <div className={cx(styles.wrapper)}>
          <ContentModal.Header withTabs>
            <h3>
              {`${t('modals.assignmentDetails.assignment')}: ${assignment?.title}`}
              {assignmentDetails?.varieties?.() &&
                assignmentDetails?.varieties?.().length > 1 && (
                  <Dropdown
                    onChange={(value) => {
                      setLevel(+parseInt(value as string, 2));
                    }}
                    defaultValue={level.toString()}
                    style={{ marginLeft: '1rem' }}
                  >
                    {assignmentDetails?.varieties?.().map((variety, key) => (
                      <Dropdown.Option key={variety.id} value={key.toString()}>
                        {`${t('modals.assignmentDetails.level')} ${key + 1}`}
                      </Dropdown.Option>
                    ))}
                  </Dropdown>
                )}
            </h3>
            <Tabs>
              <TabsItem
                active={active === 'workprocesses'}
                onClick={() => setActive('workprocesses')}
              >
                {t('modals.assignmentDetails.workprocesses')}
              </TabsItem>
              <TabsItem
                active={active === 'skills'}
                onClick={() => setActive('skills')}
              >
                {t('modals.assignmentDetails.knowledgeAndSkills')}
              </TabsItem>
              {!isAdminEnvironment && (
                <TabsItem
                  active={active === 'remarks'}
                  onClick={() => setActive('remarks')}
                >
                  {t('modals.assignmentDetails.remarks')}
                </TabsItem>
              )}
            </Tabs>
          </ContentModal.Header>
          {active === 'workprocesses' && (
            <>
              {processes.length === 0 && (
                <Callout info>
                  {t('modals.assignmentDetails.no_workprocesses')}
                </Callout>
              )}
              {processes.length > 0 && (
                <>
                  {processes.map((process) => (
                    <div key={process.id}>
                      <h4>{`${process.code}: ${process.title}`}</h4>
                      <p>{process.description}</p>
                    </div>
                  ))}
                </>
              )}
            </>
          )}

          {active === 'skills' && (
            <>
              {skills?.length === 0 && behaviours?.length === 0 && (
                <Callout info>
                  {t('modals.assignmentDetails.no_knowlegde_no_skills')}
                </Callout>
              )}
              {skills.length > 0 && (
                <>
                  <h3>{t('modals.assignmentDetails.knowledge')}</h3>
                  <ul>
                    {skills.map((skill) => (
                      <div key={skill.id}>
                        <li>{skill.description}</li>
                      </div>
                    ))}
                  </ul>
                </>
              )}
              {behaviours.length > 0 && (
                <>
                  <h3>{t('modals.assignmentDetails.skills')}</h3>
                  <ul>
                    {behaviours.map((behaviour) => (
                      <div key={behaviour.id}>
                        <li>{behaviour.description}</li>
                      </div>
                    ))}
                  </ul>
                </>
              )}
            </>
          )}

          {active === 'remarks' && (
            <>
              {remarks && remarksNotEmpty ? (
                <div dangerouslySetInnerHTML={{ __html: sanitizer(remarks) }} />
              ) : (
                <Callout info>
                  {t('modals.assignmentDetails.no_remarks')}
                </Callout>
              )}
            </>
          )}
        </div>
      )}
    </ContentModal>
  );
};

export default AssignmentDetailsModal;
