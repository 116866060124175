import { ReactNode } from 'react';
import * as Sentry from '@sentry/nextjs';
import { ExceptionTemplates } from 'Providers/ErrorDataProvider/Templates';
import { TErrorViewProps } from 'Providers/ErrorDataProvider/Templates/Types';

type TSentryErrorLevel =
  | 'debug'
  | 'info'
  | 'log'
  | 'warning'
  | 'error'
  | 'critical'
  | 'fatal';

export type TExceptionScope = Record<string, unknown>;

export type TExceptionOptions = {
  template?: keyof typeof ExceptionTemplates;
  silent?: boolean;
  level?: TSentryErrorLevel;
};

export abstract class Exception extends Error {
  /**
   * level.
   *
   * The severity of the Exception.
   */
  public level: TSentryErrorLevel = 'warning';

  /**
   * silent.
   *
   * Whether to inform the bugtracker about this exception or not.
   */
  public silent: boolean = true;

  /**
   * template.
   *
   * Use one of the pre-configured templates to render the fallback in.
   */
  public template: keyof typeof ExceptionTemplates = 'block';

  /**
   * props.
   *
   * Contains information passed to the error view component.
   */
  public props: Omit<TErrorViewProps, 'className' | 'handleReset'> = {
    title: this.name,
    description: this.message,
  };

  /**
   * view.
   *
   * An optional view to present when this error occurs.
   */
  public view?: ({
    error,
    children,
  }: {
    error: Error;
    children?: ReactNode;
  }) => JSX.Element = undefined;

  /**
   * state.
   *
   * Contains extra information about the scope in which the error happens.
   */
  public scope: TExceptionScope;

  constructor(
    message?: string,
    scope: TExceptionScope = {},
    options?: TExceptionOptions
  ) {
    super(message);

    this.scope = scope;

    if (options?.level) {
      this.level = options.level;
    }
    if (options?.template) {
      this.template = options.template;
    }
    if (options?.silent) {
      this.silent = options.silent;
    }
  }

  /**
   * toSentry.
   *
   * If the exception is not silent, it can be send to Sentry with this method.
   */
  public toSentry(info?: { [key: string]: any }) {
    if (!this.silent) {
      Sentry.captureException(this, (scope) => {
        Object.keys(this.scope).forEach((key) => {
          scope.setExtra(key, this.scope[key]);
        });
        if (info) {
          Object.keys(info).forEach((key) => {
            scope.setExtra(key, info[key]);
          });
        }
        return scope;
      });
    }
  }
}
