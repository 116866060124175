import React from 'react';
import { AnnouncementType } from '@brainstud/academy-api';
import { Callout } from '@brainstud/ui';
import { Close } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { sanitizer } from 'Utils/Sanitizer';
import styles from './AnnouncementDisplay.module.css';

const cx = classNames.bind(styles);

type Props = {
  title: string;
  description: string;
  type: AnnouncementType;
  className?: string;
  onClose?: () => void;
};

/**
 * AnnouncementDisplay.
 *
 * Renders a single Announcement callout to display the announcements information.
 */
export const AnnouncementDisplay = ({
  title,
  description,
  type,
  className,
  onClose,
}: Props) => (
  <Callout
    info={type === 'info'}
    error={type === 'danger'}
    warning={type === 'warning'}
    className={cx(styles.base, className)}
  >
    <div className={cx(styles.titleRow)}>
      <h4 className={cx(styles.title)}>{title}</h4>
      {onClose && (
        <button onClick={onClose} type="button">
          <Close />
        </button>
      )}
    </div>
    <div dangerouslySetInnerHTML={{ __html: sanitizer(description) }} />
  </Callout>
);
