import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { Callout, Status } from '@brainstud/ui';
import { CommentBankRounded, Lock } from '@mui/icons-material';
import classNames from 'classnames';
import { useObjectTags } from 'Hooks';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  ILearningObjectWithResources,
  useEnvironmentProvider,
  useLearningRouteProvider,
} from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import { ObjectMetaBadge } from './ObjectMetaBadge';
import { ObjectMetaLabel } from './ObjectMetaLabel';
import styles from './ObjectRow.module.css';

const cx = classNames.bind(styles);

type TProps = {
  object: ILearningObjectWithResources;
  indent?: number;
};

export const ObjectRow = ({ object, indent = 0 }: TProps) => {
  const [t, { uc }] = useTranslator();
  const router = useRouter();
  const { learningObjectCollection } = useLearningRouteProvider();
  const { courseId } = router.query as { courseId: string };
  const [showCoachRemarks, setShowCoachRemarks] = useState(false);
  const [showObjectConditions, setShowObjectConditions] = useState(false);
  const { isCoachEnvironment } = useEnvironmentProvider();
  const tags = useObjectTags(object);

  const isTheory = tags.filter((tag) => tag.type === 'theory').length > 0;

  const quantitativeTags = tags.filter((tag) =>
    ['xp', 'groupSize'].includes(tag.name)
  );
  const leftTags = tags.filter(
    (tag) => !quantitativeTags.includes(tag) && tag.type !== 'theory'
  );

  const handleClick = useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      callback: Dispatch<SetStateAction<boolean>>
    ) => {
      event.stopPropagation();
      event.preventDefault();
      callback((prev) => !prev);
    },
    []
  );

  return (
    <Link
      href={`/${isCoachEnvironment ? 'coach' : 'admin'}/courses/${courseId}/collections/${learningObjectCollection?.id}/content/detail/${object.id}`}
      className={cx(styles.link)}
    >
      <div
        className={cx(styles.base, styles[`indent-${indent < 5 ? indent : 5}`])}
      >
        <div className={cx(styles.row)}>
          <Status inline>
            {uc(
              t(
                `views.courses.collection_list.${isTheory ? 'theory' : 'assignment'}`
              )
            )}
          </Status>
          <span className={styles.header}>{object.title}</span>
          <div className={cx(styles.meta)}>
            {object.conditions && (
              <button
                type="button"
                onClick={(event) => handleClick(event, setShowObjectConditions)}
                title={t('components.collection_list.conditions.conditions')}
              >
                <Lock
                  fontSize="large"
                  className={cx(styles.showDetailsIcon, {
                    [styles.active]: showObjectConditions,
                  })}
                />
              </button>
            )}
            {object.varieties?.()[0].teacherRemarks && (
              <button
                type="button"
                onClick={(event) => handleClick(event, setShowCoachRemarks)}
                title={t('components.collection_list.coach_remarks')}
              >
                <CommentBankRounded
                  fontSize="large"
                  className={cx(styles.showDetailsIcon, {
                    [styles.active]: showCoachRemarks,
                  })}
                />
              </button>
            )}
            {quantitativeTags.map((tag) => (
              <ObjectMetaBadge
                key={tag.name}
                type={tag.name}
                value={tag?.value}
              />
            ))}
          </div>
        </div>

        {leftTags.length > 0 && (
          <div className={cx(styles.labelRow)}>
            {leftTags.map((tag) => (
              <ObjectMetaLabel key={tag.name} type={tag.type} />
            ))}
          </div>
        )}

        {object.varieties?.()[0].teacherRemarks && (
          <Callout
            className={cx(styles.callout, {
              [styles.visible]: showCoachRemarks,
            })}
            dangerouslySetInnerHTML={{
              __html: sanitizer(object.varieties?.()[0].teacherRemarks),
            }}
            icon={<CommentBankRounded fontSize="large" />}
            info
          />
        )}

        {object.conditions && (
          <Callout
            className={cx(styles.callout, styles.conditions, {
              [styles.visible]: showObjectConditions,
            })}
            icon={<Lock fontSize="large" />}
          >
            <strong>
              {t('components.collection_list.conditions.requirements')}
            </strong>
            <ul>
              {object.conditions().map((condition) => (
                <li key={condition.id}>{condition.target?.().title}</li>
              ))}
            </ul>
          </Callout>
        )}
      </div>
    </Link>
  );
};
