import React, { FC, useEffect, useState } from 'react';
import { ITextareaBlockProps } from '@brainstud/course-blocks';
import { Input } from '@brainstud/ui';
import { useTranslator } from 'Providers/Translator';

type Props = {
  data: ITextareaBlockProps;
  onChange: (block: Partial<ITextareaBlockProps>) => void;
};

/**
 * For creating open answered questions
 */
export const TextareaForm: FC<Props> = ({ data, onChange }) => {
  const [t] = useTranslator();
  const [question, setQuestion] = useState(data.question);

  useEffect(() => {
    onChange?.({
      question,
      inputFieldsCount: 1,
    });
  }, [question]);

  return (
    <Input
      label={t('course-editor.content_editor.blocks.textarea.question')}
      value={question}
      onChange={(e) => setQuestion(e.target.value)}
    />
  );
};
