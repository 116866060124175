/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useCallback, useMemo, useState } from 'react';
import { FlowContext, TFlowStepChild } from './FlowContext';
import { FlowTranslations } from './translations';

type TFlowProps = {
  /**
   * Set a language for the default labels
   *
   * @default 'en'
   */
  language?: 'en' | 'nl';
  /** @deprecated Use `language` instead */
  lang?: TFlowProps['language'];
  /** Set the step to start on */
  step?: number;
  children: TFlowStepChild | TFlowStepChild[];
};

/**
 * Easily creates a flow in which you can go to next and previous states
 */
const Flow = ({ children, language, lang, step }: TFlowProps) => {
  const childArray = useMemo(
    () => (!Array.isArray(children) ? [children] : children),
    [children]
  );
  // FIXME: Refactor this to use a reducer to move between states
  const next = useCallback(() => {
    setFlow((prevState) => {
      const nextStep = prevState.step + 1;
      const newTrace = prevState.trace;
      newTrace.push(nextStep);
      return { ...prevState, ...{ step: nextStep, trace: newTrace } };
    });
  }, []);

  const previous = useCallback(() => {
    setFlow((prevState) => {
      const newTrace = prevState.trace;
      newTrace.pop();
      const prevStep = newTrace[newTrace.length - 1] || 1;
      return { ...prevState, ...{ step: prevStep, trace: newTrace } };
    });
  }, []);

  const goto = useCallback((thisStep) => {
    setFlow((prevState) => {
      const newTrace = prevState.trace;
      newTrace.push(thisStep);
      return { ...prevState, ...{ step: thisStep, trace: newTrace } };
    });
  }, []);

  const set = useCallback((values) => {
    setFlow((prevState) => ({ ...prevState, ...values }));
  }, []);

  const steps = useMemo(
    () =>
      childArray.filter(
        (child) => child.type && child.type.displayName === 'FlowStep'
      ),
    [childArray]
  );

  const languageCode = language || lang || 'en';
  const defaultLabels = FlowTranslations[languageCode];

  const [flow, setFlow] = useState({
    exists: true,
    trace: [step || 1],
    labels: defaultLabels,
    step: step || 1,
    total: steps.length,
    steps,
    set,
    goto,
    next,
    previous,
    defaults: {
      next,
      previous,
      labels: defaultLabels,
    },
  });

  return (
    <FlowContext.Provider value={flow}>
      {childArray.filter(
        (child) =>
          (child.key && parseInt(child.key.toString(), 10) === flow.step) ||
          (child.type && child.type.displayName !== 'FlowStep')
      )}
    </FlowContext.Provider>
  );
};
Flow.displayName = 'FlowForm';

export { Flow };
