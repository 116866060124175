import { useCallback } from 'react';
import { TNodeListItem, useLearningRouteProvider } from 'Providers';
import { expandNodeToList } from '../../Providers/LearningRouteProvider/expandNodeToList';
import { getTotalProgress } from './getTotalProgress';

/**
 * useProgressCalculator.
 *
 * Calculate the total progress by recursively retrieving the data.
 * Accepts: LearningRouteNode, LearningSubject or LearningObject
 */
export function useProgressCalculator(item?: TNodeListItem) {
  const { list } = useLearningRouteProvider();

  const getSubjectItems = useCallback(
    (subject) =>
      expandNodeToList(subject)
        .flat()
        .filter((node) => node.resourceType === 'learning_objects'),
    []
  );

  if (!item) {
    return getTotalProgress([]);
  }
  switch (item?.resourceType) {
    case 'learning_route_nodes': {
      return getTotalProgress(item.scheme, list);
    }
    case 'learning_subjects': {
      const learningObjects = getSubjectItems(item);
      return getTotalProgress(learningObjects || [], list);
    }
    case 'learning_objects':
    default: {
      return getTotalProgress([item], list);
    }
  }
}
