import React, { isValidElement, ReactNode } from 'react';
import { Panel } from '@brainstud/ui';

interface FallbackProps {
  condition: boolean;
  fallback?: string | ReactNode;
  children: ReactNode | undefined;
}

/**
 * With this component you can render a subset of the children based on a condition, with a
 * fallback string or component that is shown when the condition fails.
 */
export const Display: React.FC<FallbackProps> = ({
  condition,
  fallback,
  children,
}) => {
  if (!condition) {
    if (!fallback) {
      return null;
    }

    return isValidElement(fallback) ? (
      fallback
    ) : (
      <Panel>
        <section>{fallback}</section>
      </Panel>
    );
  }

  return <>{children}</>;
};
