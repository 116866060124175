import React, { useCallback } from 'react';
import { AttachFile } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import styles from './UploadedFile.module.css';

const cx = classNames.bind(styles);

type Props = {
  src: string;
  name?: string;
};

export const UploadedFile = ({ src, name }: Props) => {
  const [t] = useTranslator();

  const handleNoFile = useCallback(() => {
    if (!src) {
      alert(t('components.upload_file.file_not_found'));
      return false;
    }
    return true;
  }, [src, t]);

  return (
    <a
      href={src}
      target="_blank"
      rel="noopener noreferrer"
      className={cx('file')}
      onClick={handleNoFile}
    >
      <span className={cx('icon')}>
        <AttachFile fontSize="large" />
      </span>
      <span className={cx('filename')}>
        {name || t('course-blocks.uploaded_file.unknown')}
      </span>
    </a>
  );
};
