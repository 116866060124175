import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  UUID,
  WorkprocessBehavior,
  WorkprocessBehaviorUpdateInput,
  WorkprocessBehaviourCreateInput,
  WorkprocessBehaviourFilters,
  WorkprocessBehaviourIncludes,
  WorkprocessBehaviourSorts,
} from '../Types';

interface IWorkprocessBehaviorProps
  extends HookParams<
    typeof WorkprocessBehaviourIncludes,
    typeof WorkprocessBehaviourFilters,
    typeof WorkprocessBehaviourSorts
  > {
  dossier?: UUID;
  coreTask?: UUID;
  workProcess?: UUID;
  behavior?: UUID;
}

export function useWorkprocessBehavior(
  {
    dossier,
    coreTask,
    workProcess,
    behavior,
    ...queryParameters
  }: IWorkprocessBehaviorProps = {},
  settings?: UseQueryOptions<DataDocument<WorkprocessBehavior>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: {
      dossier: defaultDossier,
      coreTask: defaultCoreTask,
      workProcess: defaultWorkProcess,
    },
  } = context;
  const request = new ApiRequest(
    {
      enabled:
        !!(dossier || defaultDossier) &&
        !!(coreTask || defaultCoreTask) &&
        !!(workProcess || defaultWorkProcess) &&
        !!behavior,
      baseName: 'v1.workprocesses.behaviors',
      baseUri: `/v1/dossiers/${dossier || defaultDossier}/core_tasks/${coreTask || defaultCoreTask}/workprocesses/${workProcess || defaultWorkProcess}/behaviors`,
      uri: `/${behavior}`,
      invalidate: ['behavior', 'behaviors'],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    WorkprocessBehavior,
    WorkprocessBehaviourCreateInput,
    WorkprocessBehaviorUpdateInput
  >(request, settings);
}

export function useWorkprocessBehaviors(
  {
    dossier,
    coreTask,
    workProcess,
    ...queryParameters
  }: Omit<IWorkprocessBehaviorProps, 'workprocess_behaviors'> = {},
  settings?: UseQueryOptions<DataDocument<WorkprocessBehavior[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: {
      dossier: defaultDossier,
      coreTask: defaultCoreTask,
      workProcess: defaultWorkProcess,
    },
  } = context;
  const request = new ApiRequest(
    {
      enabled:
        !!(dossier || defaultDossier) &&
        !!(coreTask || defaultCoreTask) &&
        !!(workProcess || defaultWorkProcess),
      baseName: 'v1.workprocesses.behaviors',
      baseUri: `/v1/dossiers/${dossier || defaultDossier}/core_tasks/${coreTask || defaultCoreTask}/workprocesses/${workProcess || defaultWorkProcess}/behaviors`,
      invalidate: ['behavior', 'behaviors'],
      queryParameters,
    },
    context
  );
  return useIndexCR<WorkprocessBehavior, WorkprocessBehaviourCreateInput>(
    request,
    settings
  );
}
