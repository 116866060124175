import React from 'react';
import { Group } from '@mui/icons-material';
import classNames from 'classnames';
import { useAccessProvider } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import styles from './CourseRow.module.css';

const cx = classNames.bind(styles);

type TProps = {
  title: string;
  thumbnail?: string;
  /** Number of participants within the course relevant for the viewer */
  participants?: number;
  /** Hours of content for a student to complete */
  sbu?: string;
};

export const CourseRow = ({ title, thumbnail, participants, sbu }: TProps) => {
  const [t] = useTranslator();
  const { hasAccess } = useAccessProvider();

  return (
    <div className={cx(styles.base, { [styles.disabled]: !hasAccess })}>
      <img src={thumbnail} alt="" className={cx(styles.courseImage)} />
      <div className={cx(styles.content)}>
        <div className={styles.header}>
          {title || t('views.coach.dashboard.empty.course_title')}
        </div>
        <div className={cx(styles.metadata)}>
          {participants !== undefined && (
            <div className={cx(styles.centered)}>
              <span className={cx(styles.metaCount)}>{participants}</span>
              <Group fontSize="medium" />
            </div>
          )}
          {sbu && (
            <div>
              <span className={cx(styles.metaCount)}>{sbu}</span>
              {t('scu')}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
