import React, { ReactNode } from 'react';
import styles from './SubHeader.module.css';

type TProps = {
  children?: ReactNode;
};

const SubHeader = ({ children }: TProps) => (
  <div className={styles.base}>{children}</div>
);

export default SubHeader;
