import { useContext } from 'react';
import {
  ITranslatorContext,
  TLanguages,
  TranslatorContext,
} from './TranslatorContext';

type TReturn = readonly [
  ITranslatorContext['translate'],
  {
    lc: (value: string) => string;
    ucf: (value: string) => string;
    currentDayPeriod: string;
    uc: (value: string) => string;
    setLanguage: ITranslatorContext['setLanguage'];
    language: TLanguages;
  },
  ITranslatorContext['translations'],
];

/**
 * Retrieves translations from context and makes accessible through methods
 */
export function useTranslator(): TReturn {
  const context = useContext(TranslatorContext);

  const { translate, setLanguage, translations, language } = context;

  return [
    translate,
    {
      setLanguage,
      ucf: (value: string) => value.charAt(0).toUpperCase() + value.slice(1),
      lc: (value: string) => value.toLowerCase(),
      uc: (value: string) => value.toUpperCase(),
      language,
      currentDayPeriod: (() => {
        const currentHour = new Date().getHours();
        if (currentHour < 5) {
          return 'night';
        }
        if (currentHour < 12) {
          return 'morning';
        }
        if (currentHour < 18) {
          return 'afternoon';
        }
        return 'evening';
      })(),
    },
    translations,
  ] as const;
}
