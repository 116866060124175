import React from 'react';
import dynamic from 'next/dynamic';
import { IReactWidgetBlock } from './Types';

const ReactWidgetLazyLoaded = dynamic(
  () =>
    import(
      'Modules/course-blocks/Blocks/Static/ReactWidget/ReactWidgetLazyLoaded'
    )
);

export const ReactWidget = ({
  id,
  url,
  read,
  props,
}: Omit<IReactWidgetBlock, 'type'>) => (
  <ReactWidgetLazyLoaded id={id} url={url} read={read} props={props} />
);
