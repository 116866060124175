import React from 'react';
import { Base } from './Base';

/**
 * Higher Order Component that returns your component, surrounded with Base component
 */
export function withBase<T extends {} = any>(
  Component: React.ComponentType<T>
) {
  return (props: T) => (
    <Base>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Component {...props} />
    </Base>
  );
}
