import { ReactNode, useCallback, useMemo } from 'react';
import {
  LearningRouteNode,
  useApi,
  useEnrollmentLearningObjectCollection,
  useLearningObjectCollection,
  UUID,
} from '@brainstud/academy-api';
import { ApiException } from 'Exceptions';
import { useRouter } from 'next/router';
import { useBreadcrumbs } from 'Providers';
import { useEnvironmentProvider } from '../EnvironmentProvider';
import {
  ILearningRouteContext,
  ILearningRouteNodeWithResources,
  LearningRouteContext,
  TNodeListItem,
} from './LearningRouteContext';
import { useNodes } from './useNodes';
import { useRouteNavigation } from './useRouteNavigation';

type Props = {
  children: ReactNode;
  nodes?: LearningRouteNode[];
  enrollmentId?: UUID;
  learningObjectCollectionId?: UUID;
};

/**
 * LearningRouteProvider.
 *
 * Assembles all nodes, subjects and objects of the collection into a tree of
 * objects according to the defined learning route. This way, you can display
 * the full contents of a collection to the user and navigate between items
 * if necessary.
 *
 * The LearningRouteProvider works on either a given collection ID or based on
 * an enrollment. These are different API endpoints with somewhat different data
 * structures. It is therefore very flexible in nature, but do verify that you
 * use the intended API calls when using this provider.
 */
export const LearningRouteProvider = ({
  children,
  nodes: givenNodes,
  enrollmentId: givenEnrollmentId,
  learningObjectCollectionId: givenLearningObjectCollectionId,
}: Props) => {
  const { query } = useRouter();
  const { parameters } = useApi();
  const { isStudentEnvironment } = useEnvironmentProvider();
  const enrollmentId = (givenEnrollmentId ||
    query.enrollmentId ||
    parameters.enrollment) as string;

  const learningObjectCollectionId = (givenLearningObjectCollectionId ||
    query.collectionId ||
    parameters.learningObjectCollection) as string;

  // Get 'default' ObjectCollection
  const [
    { data: collection },
    { error: collectionError, isLoading: collectionLoading },
  ] = useLearningObjectCollection(
    {
      learningObjectCollection: learningObjectCollectionId,
      include: [
        'learning_objects.conditions',
        'learning_routes.learning_route_nodes.conditions',
        'learning_subjects.quiz',
        'learning_subjects.learning_objects',
      ],
    },
    { enabled: !isStudentEnvironment }
  );

  if (collectionError) {
    throw new ApiException(collectionError);
  }

  // Get ObjectCollection using enrollment endpoint
  const [
    { data: enrolledCollection },
    { error: enrolledError, isLoading: enrolledLoading },
  ] = useEnrollmentLearningObjectCollection(
    {
      enrollment: enrollmentId,
      learningObjectCollection: learningObjectCollectionId,
      include: [
        'learning_objects.conditions',
        'learning_routes.learning_route_nodes.conditions',
        'learning_subjects.quiz',
        'learning_subjects.learning_objects',
      ],
    },
    {
      enabled: isStudentEnvironment,
      refetchOnWindowFocus: false,
    }
  );

  const isLoading = collectionLoading || enrolledLoading;
  const learningObjectCollection = enrolledCollection || collection;
  const { learningRoute, learningRouteNodes, list } = useNodes(
    learningObjectCollection
  );

  const nodes = useMemo(
    () =>
      (
        (givenNodes || learningRouteNodes) as ILearningRouteNodeWithResources[]
      )?.sort((a, b) => (a.position > b.position ? 1 : -1)),
    [givenNodes, learningRouteNodes]
  );

  const pageTree = useRouteNavigation(list);

  const getHierarchy = useCallback(
    (
      currentItem,
      hierarchy: Array<TNodeListItem> = []
    ): Array<TNodeListItem> => {
      if (
        currentItem?.resourceType === 'learning_subjects' ||
        currentItem?.resourceType === 'learning_objects'
      ) {
        if (currentItem.parent) {
          const spreadItem = list.find(
            (item) => item.id === currentItem?.parent.id
          );
          return [...getHierarchy(spreadItem, hierarchy), currentItem];
        }
        return [...hierarchy, currentItem];
      }
      return hierarchy;
    },
    [list]
  );

  const hierarchy = useMemo<Array<TNodeListItem>>(
    () => getHierarchy(pageTree?.currentItem),
    [getHierarchy, pageTree?.currentItem]
  );

  useBreadcrumbs(
    {
      [learningObjectCollectionId]: learningObjectCollection?.title,
    },
    [learningObjectCollection]
  );

  const isCustomRoute = learningRoute?.type === 'custom';
  const isCustomCollection = learningObjectCollection?.type === 'custom';

  const layout = learningRoute?.layout || 'action-cards';
  const isLive =
    learningObjectCollection?.status === 'ARCHIVED' ||
    learningObjectCollection?.status === 'PUBLISHED';

  const state = useMemo<ILearningRouteContext>(
    () => ({
      learningRoute,
      nodes,
      list,
      learningObjectCollection,
      isLoading,
      isCustomRoute,
      isCustomCollection,
      layout,
      pageTree,
      hierarchy,
      enrollmentId,
      isLive,
    }),
    [
      learningRoute,
      nodes,
      list,
      learningObjectCollection,
      isLoading,
      isCustomRoute,
      isCustomCollection,
      layout,
      pageTree,
      hierarchy,
      enrollmentId,
      isLive,
    ]
  );

  if (enrolledError) {
    throw new ApiException(enrolledError);
  }

  return (
    <LearningRouteContext.Provider value={state}>
      {children}
    </LearningRouteContext.Provider>
  );
};
