import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useModals } from '../../Providers/ModalProvider';
import { DebugModal } from './DebugModal';

/**
 * DebugPanel.
 *
 * Shows a set of options that can be used during review & development.
 */
export const DebugPanel = () => {
  const { openModal } = useModals();

  useHotkeys('ctrl + D', () => {
    openModal(DebugModal);
  });

  return <ReactQueryDevtools initialIsOpen={false} />;
};
