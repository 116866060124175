import React, { useCallback, useMemo } from 'react';
import { usePortalCourses } from '@brainstud/academy-api';
import { Button, Progress } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { Loading } from 'Components';
import { CourseFeedbackModal } from 'Modals';
import { useModals } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { ReactComponent as PartyPopper } from 'public/media/icons/party_popper.svg';
import { useCourseStartup, usePortalParameter } from '../../Hooks';
import { useCourseList } from '../../Providers';
import { Badge } from '..';
import styles from './CourseCard.module.css';

export interface CatalogCourseCardProps {
  courseId: string;
  isOpened: boolean;
  isNew: boolean;
  isCompleted: boolean;
  className?: string;
  openInfoPanel: () => void;
}

const cx = classNames.bind(styles);

const CourseCard = React.forwardRef(
  (
    {
      courseId,
      isOpened,
      isNew,
      isCompleted,
      className,
      openInfoPanel,
    }: CatalogCourseCardProps,
    ref: any
  ) => {
    const [{ data: courses }, { isLoading }] = usePortalCourses({
      include: ['lti_resource_links'],
    });
    const course = useMemo(
      () => courses.find((item) => item.id === courseId),
      [courses, courseId]
    );
    const catalogProduct = useMemo(
      () =>
        course
          ?.catalogProducts?.()
          .find((item) => item.priceModel === 'flat_fee'),
      [course]
    );
    const { quickStart } = useCourseList();
    const { openModal } = useModals();

    const enrollment = useMemo(() => course?.enrollment?.(), [course]);

    const hasThumbnail =
      (course?.metadata?.media?.thumbnailUrl || course?.thumbnailUrl) &&
      (course?.metadata?.media?.thumbnailUrl?.search(
        /(.jpg|.png|.jpeg|.webp)/
      ) !== -1 ||
        course?.thumbnailUrl?.search(/(.jpg|.png|.jpeg|.webp)/) !== -1);
    const defaultThumbnail = '/media/abstract-thumbnail.jpg';
    const thumbnail = hasThumbnail
      ? course?.metadata?.media?.thumbnailUrl || course?.thumbnailUrl
      : defaultThumbnail;
    const {
      label,
      requires,
      handleOpenCourse,
      isLoading: isOpening,
      hasAccess,
    } = useCourseStartup({ course });
    const profiles = useMemo(
      () => course?.educationProfiles?.() || [],
      [course]
    );
    const isComingSoon = course?.status === 'COMING_SOON';

    const handleClick = useCallback(() => {
      if (!quickStart) {
        openInfoPanel();
      }
      if (enrollment && quickStart) {
        if (isCompleted) {
          openModal(CourseFeedbackModal, {
            enrollmentId: enrollment.id,
            course,
            hasAccess,
            ...(hasAccess ? { handleConfirm: () => handleOpenCourse() } : {}),
          });
        } else if (hasAccess) {
          handleOpenCourse();
        }
      } else if (!enrollment && quickStart) {
        openInfoPanel();
      }
    }, [
      quickStart,
      enrollment,
      openInfoPanel,
      isCompleted,
      hasAccess,
      openModal,
      course,
      handleOpenCourse,
    ]);

    const [t] = useTranslator();

    const labels =
      // @ts-ignore FIXME: Or can be removed when all courses have adopted the informationLines approach.
      course?.metadata?.informationLines || course?.metadata?.labels;

    const beforeVatPrice = !!usePortalParameter('show_prices_excluding_vat');
    const priceTag =
      catalogProduct?.[beforeVatPrice ? 'priceBeforeVat' : 'priceAfterVat'];

    return isLoading || !course ? (
      <Loading />
    ) : (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
      <div
        className={cx('card', className, {
          isActive: isOpened,
          isOpening,
          isLocked: !hasAccess && quickStart && !isCompleted,
        })}
        onClick={handleClick}
        ref={ref}
        style={{ backgroundImage: `url(${thumbnail})` }}
      >
        <div className={styles.batchTopLeft}>
          {isNew && !enrollment && (
            <Badge color="yellow">{t('components.course_card.new')}</Badge>
          )}
        </div>

        {isComingSoon && !enrollment && (
          <Badge color="yellow" className={styles.badgeTopLeft}>
            {course.metadata?.informationLines?.availableFrom
              ? t('components.course_card.from', {
                  expectedOn: course.metadata?.informationLines?.availableFrom,
                })
              : t('components.course_card.coming_soon')}
          </Badge>
        )}

        {isCompleted ? (
          <div className={cx(styles.completedBadge)}>
            <PartyPopper />
            <strong>{t('components.course_card.completed')}</strong>
          </div>
        ) : (
          <>
            {enrollment?.progress && (
              <Progress
                className={styles.progressBar}
                value={enrollment.progress.percentage.completed}
                max={100}
              />
            )}
            {labels?.sbu && (
              <Badge color="black" className={styles.badgeTopRight}>
                {`${labels.sbu} SBU`}
              </Badge>
            )}
          </>
        )}

        {!isComingSoon && !!catalogProduct && requires.payment && (
          <div className={cx(styles.priceSection)}>
            <Badge color="blue" size="large">
              <span className={styles.pricePrefix}>
                {catalogProduct.addOnCount! > 1 &&
                  t('components.course_card.from_price')}
              </span>
              {priceTag?.toLocaleString('nl-nl', {
                style: 'currency',
                currency: catalogProduct.currency,
              })}
            </Badge>
          </div>
        )}

        <h4>{course.title}</h4>

        {profiles.length > 0 && (
          <div className={cx('profiles-bar')}>
            <Badge limit color="transparent">
              {profiles[0].title}
            </Badge>
            {profiles.length > 1 && (
              <Badge color="white" circle>
                +{profiles.length - 1}
              </Badge>
            )}
          </div>
        )}

        {!requires?.enrollment && quickStart && (
          <div className={cx('start-course')}>
            <Button quiet>
              {typeof label === 'object' ? label.subscription : label}
            </Button>
          </div>
        )}
      </div>
    );
  }
);

export default CourseCard;
