import React, { useCallback, useMemo, useState } from 'react';
import {
  AutomationTrigger,
  automationTriggerMap,
  useLearningRouteAutomation,
  useLearningRouteAutomations,
  UUID,
} from '@brainstud/academy-api';
import { Button, Dropdown, MultiDropdown, SidebarModal } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { useLoadingState } from 'Modules/admin-panel/Hooks/useLoadingState';
import { ILearningRouteGroup } from 'Modules/course-editor';
import { TNodeListItem, useModals, useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import styles from './LearningRouteSettingsModal.module.css';

const cx = classNames.bind(styles);

type Props = {
  learningRouteId: UUID;
  nodes?: ILearningRouteGroup[];
  list?: TNodeListItem[];
};

export const LearningRouteSettingsModal = ({
  learningRouteId,
  nodes,
  list,
}: Props) => {
  const [t] = useTranslator();
  const [setToast] = useToaster();
  const { closeModal } = useModals();

  const [{ data: automations }, { isLoading: isLoadingAutomations }] =
    useLearningRouteAutomations({
      learningRoute: learningRouteId,
    });

  const automation = automations[0];
  const requiredAssignments = useMemo(
    () =>
      list?.filter((item) =>
        automation
          ?.triggerModels?.()
          .some((triggerModel) => triggerModel.id === item.id)
      ),
    [list, automation]
  );

  const [{ createOrUpdate, destroy }] = useLearningRouteAutomation(
    {
      learningRoute: learningRouteId,
      automation: automation?.id,
    },
    { enabled: false }
  );
  const isLoading = createOrUpdate.isLoading || destroy.isLoading;

  const [selectedAutomationTrigger, setAutomationTrigger] = useState<string>();
  const automationTrigger = selectedAutomationTrigger || automation?.trigger;

  const [selectedLearningObjects, setSelectedLearningObjects] = useLoadingState<
    string[]
  >(
    !automation,
    () => automation?.triggerModels?.().map((item) => item.id) || []
  );

  const handleAutomations = useCallback(() => {
    if (automationTrigger === 'no_trigger') {
      destroy.mutateAsync().then(() => {
        setToast(
          'modals.learning_route_settings_modal.automations.success',
          'success'
        );
      });
    } else {
      const triggerModels = selectedLearningObjects?.map(
        (selectedLearningObject) => {
          const learningObject = list?.find(
            (listItem) => listItem.id === selectedLearningObject
          );
          return {
            type: learningObject?.resourceType,
            id: selectedLearningObject,
          };
        }
      );

      createOrUpdate
        .mutateAsync({
          trigger: automationTrigger as AutomationTrigger,
          action: 'enrollment_completion',
          ...(triggerModels && {
            relationships: {
              trigger_models: triggerModels,
            },
          }),
        })
        .then(() => {
          setToast(
            'modals.learning_route_settings_modal.automations.success',
            'success'
          );
        });
    }
  }, [
    automationTrigger,
    createOrUpdate,
    destroy,
    list,
    selectedLearningObjects,
    setToast,
  ]);

  return (
    <SidebarModal size="medium" closeable onClose={closeModal}>
      <SidebarModal.Header className={cx(styles.header)}>
        <h2>{t('modals.learning_route_settings_modal.title')}</h2>
        <Button loading={isLoading} onClick={handleAutomations}>
          {t('save')}
        </Button>
      </SidebarModal.Header>
      <section className={cx(styles.subtitle)}>
        <strong>
          {t('modals.learning_route_settings_modal.automations.title')}
        </strong>
        <span>
          {t('modals.learning_route_settings_modal.automations.description')}
        </span>
      </section>
      <section className={cx(styles.automations)}>
        <div className={cx(styles.row)}>
          <span>{t('action')}</span>
          <strong>
            {t(
              'modals.learning_route_settings_modal.automations.actions.enrollment_completion'
            )}
          </strong>
        </div>
        <div className={cx(styles.row)}>
          <span>{t('when')}</span>
          <Dropdown
            name="automation-trigger"
            value={automationTrigger}
            block
            onChange={(value) =>
              setAutomationTrigger(value as AutomationTrigger)
            }
            loading={isLoadingAutomations}
            placeholder={t(
              'modals.learning_route_settings_modal.automations.triggers.placeholder'
            )}
          >
            {Object.entries(automationTriggerMap).map(([key]) => (
              <Dropdown.Option key={key} value={key}>
                {t(
                  `modals.learning_route_settings_modal.automations.triggers.${key}`
                )}
              </Dropdown.Option>
            ))}
            {automation && (
              <Dropdown.Option key="no_trigger" value="no_trigger">
                {t(
                  'modals.learning_route_settings_modal.automations.triggers.no_trigger'
                )}
              </Dropdown.Option>
            )}
          </Dropdown>
        </div>
        {automationTrigger === 'assignment_approved' && (
          <div>
            <MultiDropdown
              onChange={(value) => setSelectedLearningObjects(value)}
              placeholder={t(
                'course-editor.learning_route_editor.assignment_conditions.condition_placeholder'
              )}
              label="Selecteer opdrachten"
              block
              value={selectedLearningObjects}
            >
              {nodes?.map((node) => (
                <Dropdown.Group key={node.id} label={node.title}>
                  {node.scheme.map((object) =>
                    /* LearningObjects of a LearningSubject */
                    object.resourceType === 'learning_subjects' ? (
                      object?.learningObjects?.().map((subObject) => (
                        <Dropdown.Option
                          key={`${subObject.id}-subject`}
                          value={subObject.id}
                        >
                          {subObject?.title}
                        </Dropdown.Option>
                      ))
                    ) : (
                      <Dropdown.Option
                        key={`${object.id}-object`}
                        value={object.id}
                      >
                        {object.title}
                      </Dropdown.Option>
                    )
                  )}
                </Dropdown.Group>
              ))}
            </MultiDropdown>
            {requiredAssignments && requiredAssignments?.length > 0 && (
              <div className={cx(styles.selectedAssignments)}>
                <strong>
                  {t(
                    'modals.learning_route_settings_modal.automations.selected_assignments'
                  )}
                </strong>
                <ul>
                  {requiredAssignments?.map((assignment) => (
                    <li key={assignment?.id}>
                      <i>{assignment?.title}</i>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
        <div />
      </section>
    </SidebarModal>
  );
};
