import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMe } from '@brainstud/academy-api';
import { Badge } from '@brainstud/ui';
import { Close, Forum } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { COACH_ROLES } from 'Config/roles';
import { useHasAnyRole } from 'Hooks';
import Script from 'next/script';
import styles from './ZendeskButton.module.css';

const cx = classNames.bind(styles);

type Props = {
  className?: string;
};

declare const zE: (
  target: string,
  action: string,
  listener?: ((count: number) => void) | (() => void) | string | string[]
) => void;

/**
 * ZendeskButton.
 *
 * This hides the original Zendesk chat widget button and replaces it with
 * a new button that has more control over open and closed state of the widget.
 */
export const ZendeskButton = ({ className }: Props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const isMounted = useRef(false);
  const [me] = useMe();
  const isCoach = useHasAnyRole(COACH_ROLES);
  const [isOpen, setIsOpen] = useState(false);
  const locale = useMemo(() => me?.account?.()?.locale, [me]);

  const { id, fullName } = useMemo(
    () => ({ id: me?.account?.()?.id, fullName: me?.account?.()?.fullName }),
    [me]
  );

  const zendeskChatId = useMemo(
    () => me?.portal?.().information?.zendeskChatId,
    [me]
  );

  const hasZendesk = !!zendeskChatId && isCoach;

  const handleClick = useCallback(() => {
    if ('zE' in window && isMounted.current) {
      zE('messenger', isOpen ? 'close' : 'open');
      setIsOpen(!isOpen);
    }
  }, [isOpen]);

  const handleOnLoad = useCallback(() => {
    if (isMounted.current) {
      setIsLoaded(true);

      if (window?.sessionStorage.getItem('ZD-widgetOpen') === 'true') {
        setIsOpen(true);
      }
    }
  }, []);

  const checkClose = useCallback(() => {
    if (isOpen && isMounted.current) {
      setIsOpen(false);
      zE('messenger', 'close');
    }
  }, [isOpen]);

  useEffect(() => {
    if ('zE' in window) {
      zE('messenger:on', 'unreadMessages', setUnreadMessageCount);
      zE('messenger:on', 'close', checkClose);
      zE('messenger:set', 'locale', locale);
      zE('messenger:set', 'conversationTags', [
        fullName || 'Naam onbekend',
        id || 'ID onbekend',
      ]);
    }
  }, [locale, checkClose, fullName, id]);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const snippet = document.getElementById('ze-snippet');
    if (hasZendesk && !isLoaded && snippet) handleOnLoad();
  }, [hasZendesk, isLoaded, handleOnLoad]);

  return hasZendesk ? (
    <div className={cx(styles.base, className)}>
      <Script
        id="ze-snippet"
        src={`https://static.zdassets.com/ekr/snippet.js?key=${zendeskChatId}`}
        onLoad={handleOnLoad}
      />
      <button
        type="button"
        className={cx(
          styles.button,
          styles.chat,
          { open: isOpen, closed: !isOpen, hidden: !isLoaded },
          'zendesk__button'
        )}
        onClick={handleClick}
      >
        <div className={cx(styles['open-icon'])}>
          <Forum />
        </div>

        <div className={cx(styles['closed-icon'])}>
          <Close />
        </div>
      </button>
      {unreadMessageCount > 0 && (
        <Badge color="red" circle className={cx('zendesk__messages')}>
          {unreadMessageCount}
        </Badge>
      )}
    </div>
  ) : null;
};
