import React, { useCallback } from 'react';
import { useTwoFactor } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { TwoFactorVerificationForm } from './Form/TwoFactorVerificationForm';

export const TwoFactorAuthRegenerateVerificationView = () => {
  const [t] = useTranslator();
  const { handleTwoFactor } = useTwoFactor();

  const handleSubmit = useCallback(
    (verification_code: string) => {
      handleTwoFactor(undefined, verification_code);
    },
    [handleTwoFactor]
  );

  return (
    <>
      <h3>
        {t('security.two_factor.generate_recovery.fill_verification_code')}
      </h3>
      <TwoFactorVerificationForm onSubmit={handleSubmit} />
    </>
  );
};
