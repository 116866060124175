import { DateTimeString } from '../Utils/DateTimeString';
import { IResource } from '../Utils/Resource';
import { Account } from './Account';
import { Answer } from './Answer';
import { Enrollment } from './Enrollment';
import { StoredFile } from './StoredFile';

export interface ExternalAssessment extends IResource<'external_assessments'> {
  /** Name of the assessor */
  name?: string;
  /** Email of the assessor */
  emailAddress: string;
  /** Feedback that the assessor has given */
  feedback?: string;
  /** The date that the assessor has seen the assessment */
  seenAt?: DateTimeString;
  /** The date that the external rating invitation is sent */
  createdAt: DateTimeString;
  /** the enrollment of which this externalAssessment is part of */
  enrollment?: () => Enrollment;
  /** The answer given by the participant */
  answer?: () => Answer;
  /** Files uploaded by the participant to complete the assessment */
  storedFiles?: () => StoredFile[];
  /** Account of the student that has made this assessment */
  account: () => Account;
  links?: {
    /** The provided upload url when you're unauthenticated */
    temporaryFileUpload?: {
      href: string;
    };
  };
}

export interface ExternalAssessmentCreate {
  /** Email of the assessor */
  email_address: string;
  relationships?: {
    /** The answer where this externalAssessment is part of */
    answer?: string;
  };
}

export interface ExternalAssessmentUpdate {
  /** Name of the assessor */
  name?: string;
  /** Feedback that the assessor has given */
  feedback?: string;
  relationships: {
    stored_files: string[];
  };
}

export enum ExternalAssessmentIncludes {
  'enrollment',
  'answer',
  'answer.learning_object',
  'answer.learning_object_variety',
  'answer.uploads',
  'stored_files',
  'account',
}

export interface ExternalAssessmentsFilters {
  answer: string;
  account: string;
}

export enum ExternalAssessmentsSorts {}
