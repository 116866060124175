import React, { useCallback } from 'react';
import { getLanguageDownloadLink, useLanguage } from '@brainstud/academy-api';
import { Button, Panel } from '@brainstud/ui';
import { Add } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Loading } from 'Components';
import { useRouter } from 'next/router';
import { useBreadcrumbs, useModals } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { useTranslationLoader } from 'Providers/Translator/useTranslationLoader';
import { LanguageFormModal } from './LanguageFormModal';
import styles from './Languages.module.css';

const cx = classNames.bind(styles);

export const LanguagesResourceView = () => {
  const [t] = useTranslator();
  const router = useRouter();
  const { languageId } = router.query as { languageId: string };
  const { openModal } = useModals();

  const [{ data: language }, { isLoading }] = useLanguage({
    language: languageId,
  });

  const translations = useTranslationLoader(language?.locale);

  useBreadcrumbs(
    {
      ...(language ? { [language.id]: language.label } : {}),
    },
    [language]
  );

  const handleDownloadBackend = useCallback(async () => {
    if (language) {
      const [storedFile] = await getLanguageDownloadLink(language.id);
      router.push(storedFile.downloadUrl);
    }
  }, [language, router]);

  const fileUnified = new Blob([JSON.stringify(translations)], {
    type: 'text/plain',
  });

  return (
    <div className={cx(styles.base)}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <header className={cx(styles.heading)}>
            <h1>{language?.label}</h1>
            <Button
              onClick={() => openModal(LanguageFormModal, { languageId })}
            >
              <Add />
              {t('edit')}
            </Button>
          </header>
          <Panel pad>
            <dl>
              <dt>{t('label')}</dt>
              <dd>{language?.label}</dd>
              <dt>{t('locale')}</dt>
              <dd>{language?.locale}</dd>
            </dl>
          </Panel>
          <Panel pad>
            <h2>{t('download')}</h2>
            <p>{t('views.languages.resource.download_languages')}</p>
            <div className={cx(styles.buttons)}>
              <Button
                type="button"
                outline
                download
                onClick={handleDownloadBackend}
              >
                {t('views.languages.resource.download_backend')}
              </Button>
              {language && (
                <Button type="button" outline>
                  <a
                    download={`${language?.label}.txt`}
                    target="_blank"
                    rel="noreferrer"
                    href={URL.createObjectURL(fileUnified)}
                    style={{
                      textDecoration: 'inherit',
                      color: 'inherit',
                    }}
                  >
                    {t('views.languages.resource.download_frontend')}
                  </a>
                </Button>
              )}
            </div>
          </Panel>
        </>
      )}
    </div>
  );
};
