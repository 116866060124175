import { UseQueryOptions } from 'react-query';
import { useIndexCR, useShow } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  SubEntity,
  SubEntityCreateInput,
  SubEntityFilters,
  SubEntityIncludes,
  SubEntitySorts,
  UUID,
} from '../Types';

interface IEntityProps
  extends HookParams<
    typeof SubEntityIncludes,
    SubEntityFilters,
    typeof SubEntitySorts
  > {
  entity?: UUID;
  subEntity?: UUID;
}

export function useSubEntity(
  { entity, subEntity, ...queryParameters }: IEntityProps = {},
  settings?: UseQueryOptions<DataDocument<SubEntity>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { entity: defaultEntity },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!subEntity,
      baseName: 'v1.entities.sub-entities',
      baseUri: `/v1/entities/${entity || defaultEntity}/sub_entities`,
      uri: `/${subEntity}`,
      queryParameters,
    },
    context
  );
  return useShow<SubEntity>(request, settings);
}

export function useSubEntities(
  { entity, ...queryParameters }: Omit<IEntityProps, 'subEntity'>,
  settings?: UseQueryOptions<DataDocument<SubEntity[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { entity: defaultEntity },
  } = context;
  const request = new ApiRequest(
    {
      baseName: 'v1.entities.sub-entities',
      baseUri: `/v1/entities/${entity || defaultEntity}/sub_entites`,
      queryParameters,
    },
    context
  );
  return useIndexCR<SubEntity, SubEntityCreateInput>(request, settings);
}
