import React, { MouseEventHandler } from 'react';
import { Button } from '@brainstud/ui';
import classNames from 'classnames/bind';
import styles from './EditorTooltipButton.module.css';

const cx = classNames.bind(styles);

type ToolbarItemProps = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  active?: boolean;
  children: React.ReactNode | string;
  className?: string;
};

export const EditorTooltipButton = ({
  onClick,
  active = false,
  children,
  className,
}: ToolbarItemProps) => (
  <Button
    type="button"
    outline
    onClick={onClick}
    className={cx(styles.base, { 'is-active': active }, className)}
  >
    {children}
  </Button>
);
