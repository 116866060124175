import { useReducer } from 'react';
import { createReactReducer } from 'Utils/createReactReducer';

export interface IAnswerReviewState {
  isLoading: boolean;
  origin: string;
  reviewSet: Array<ReviewItem>;
  index: number;
}

type ReviewItem = {
  learningObjectId: string;
  enrollmentId: string;
};

type TLoadAction = {
  type: 'LOAD_SET';
  payload: {
    origin: string;
    set: ReviewItem[];
    index?: number;
  };
};
type TNavigationAction = {
  type: 'NEXT_ITEM' | 'PREVIOUS_ITEM';
};
type TFindIndexAction = {
  type: 'GO_TO_ITEM';
  payload: {
    enrollmentId: string;
    learningObjectId: string;
  };
};
export type TAnswerReviewActions =
  | TLoadAction
  | TNavigationAction
  | TFindIndexAction;

const AnswerReviewReducer = createReactReducer<
  IAnswerReviewState,
  TAnswerReviewActions
>({
  LOAD_SET: ({ payload }) => ({
    isLoading: false,
    origin: payload.origin,
    reviewSet: payload.set,
    ...(payload.index ? { index: payload.index } : {}),
  }),
  NEXT_ITEM: (_, state) => ({
    index:
      state.index + 1 < state.reviewSet.length ? state.index + 1 : state.index,
  }),
  PREVIOUS_ITEM: (_, state) => ({
    index: state.index - 1 >= 0 ? state.index - 1 : state.index,
  }),
  GO_TO_ITEM: ({ payload }, state) => ({
    index: state.reviewSet.findIndex(
      (item) =>
        item.enrollmentId === payload.enrollmentId &&
        item.learningObjectId === payload.learningObjectId
    ),
  }),
});

export function useAnswerReviewReducer(
  initialState: Partial<IAnswerReviewState>
) {
  return useReducer(AnswerReviewReducer, {
    isLoading: true,
    reviewSet: [],
    origin: '/coach/review',
    index: 0,
    ...initialState,
  });
}
