import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import { IResource } from '../Types';

type Output<Resource extends IResource> = [
  Resource[],
  QueryObserverResult<DataDocument<Resource[]>, ErrorDocument>,
];

export default function useIndex<Resource extends IResource>(
  request: ApiRequest | null,
  queryConfig?: UseQueryOptions<DataDocument<Resource[]>, ErrorDocument>
): Output<Resource> {
  const response = useQuery<DataDocument<Resource[]>, ErrorDocument>({
    queryKey: request?.getKey('index'),
    queryFn: request?.index.bind(request),
    ...queryConfig,
    keepPreviousData: !!request && request.queryParameters?.page !== undefined,
    enabled:
      !!request &&
      !!request?.enabled &&
      queryConfig?.enabled !== false &&
      request.isRouteValid(),
  });

  return [response?.data?.data || [], response];
}
