import { PropsWithChildren } from 'react';
import classNames from 'classnames/bind';
import { useActiveSubscription } from '../../Hooks';
import styles from './SubscriptionOverlay.module.css';

const cx = classNames.bind(styles);

/**
 * This component renders a div which turns everything opaque when the subscription of the user is
 * invalid, rendering all children inaccessible.
 */
export const SubscriptionOverlay = ({ children }: PropsWithChildren<{}>) => {
  const { isSubscriptionRequired, isSubscriptionActive } =
    useActiveSubscription();
  return (
    <div
      className={cx({
        isSubscriptionActive: !isSubscriptionActive && isSubscriptionRequired,
      })}
    >
      {children}
    </div>
  );
};
