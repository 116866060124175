import React, { useCallback, useState } from 'react';
import { Button, Input, InputProps } from '@brainstud/ui';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import classNames from 'classnames/bind';
import styles from './PasswordInput.module.css';

/**
 * PasswordInput.
 */
export const PasswordInput = ({ ...props }: InputProps) => {
  const [toggleType, setToggleType] = useState('password');
  const cx = classNames.bind(styles);
  const { size } = props;

  const handleRevealPassword = useCallback(() => {
    setToggleType((prevType) =>
      prevType === 'password' ? 'text' : 'password'
    );
  }, []);

  return (
    <div
      className={cx(styles.base)}
      style={{ width: size ? `${size}%` : undefined }}
    >
      <Input
        className={cx(styles.input)}
        type={toggleType}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...props}
        size={100}
      />
      <Button
        quiet
        type="button"
        onClick={handleRevealPassword}
        className={cx(styles.button)}
      >
        {toggleType === 'text' ? <VisibilityOff /> : <Visibility />}
      </Button>
    </div>
  );
};
