import { TLanguages } from 'Providers/Translator/TranslatorContext';
import { DateTimeString } from '../Utils/DateTimeString';
import { IResource } from '../Utils/Resource';
import { UUID } from '../Utils/UUID';
import { AccountNote } from './AccountNote';
import { AccountProfile } from './AccountProfile';
import { AccountTag } from './AccountTag';
import { Coach } from './Coach';
import { CoachMandate } from './CoachMandate';
import { Enrollment } from './Enrollment';
import { Entity } from './Entity';
import { Interest } from './Interest';
import { Order } from './Order';
import { ProductActivation } from './ProductActivation';
import { Shareable } from './Shareable';
import { Skill } from './Skill';
import { StudentGroup } from './StudentGroup';
import { Subscription } from './Subscription';

export type AccountType = 'SAML' | 'WEB' | 'DEMO' | 'LTI' | 'FAKER';

export interface Account<TResourceType extends string = 'accounts'>
  extends IResource<TResourceType> {
  /** Description via which way the account was created */
  accountType: AccountType;
  /** When the user was last online */
  lastOnline: DateTimeString;
  /** An ID given by an external credential provider */
  externalId: string;
  /** The email address associated with the account */
  emailAddress: string;
  /** @deprecated. Use class unit instead. Easy access to the class_code tag associated with the account */
  classCode?: string;
  /** Easy access to the subscription end date for this account */
  subscriptionEndDate?: DateTimeString;
  /** First name of the user */
  firstName: string;
  /** Last name of the user, including the last name insertion */
  fullLastName?: string;
  /** Last name of the user, excluding the last name insertion */
  lastName?: string;
  /** The last name insertion or in Dutch 'tussenvoegsel' */
  lastNameInsertion?: string;
  /** Combines the first name and last name of the user for ease of use */
  fullName: string;
  /** The phone number of the user */
  phoneNumber?: string;
  /** The educational sector of the user (open input by user) */
  sector?: string;
  /** The name of the study the user is following (open input by user) */
  education?: string;
  /** Roles passed by an external credential provider */
  externalRole: string;
  /** @depreciated Use 'roles' instead. Primary role of the account */
  role: string;
  /** All roles assigned to this account, used the identify which items the account has access to */
  roles: string[];
  /** Easy access to the class_code tag associated with the account */
  classUnit: null | string;
  /** Datetime when the account was created */
  createdAt: DateTimeString;
  /** Datetime when the account was last updated */
  updatedAt: DateTimeString;
  /** Access token for this account */
  accessToken: null | string;
  /** The url of the avatar connected to this account's profile, if any. */
  avatarUrl?: string;
  /** The locale preference for this user, indicated by the locale code */
  locale: TLanguages;
  tags?: () => AccountTag[];
  entity?: () => Entity;
  entities?: () => Entity[];
  coaches?: () => Coach[];
  coachMandates?: () => CoachMandate[];
  studentGroup?: () => StudentGroup;
  enrollments?: () => Enrollment[];
  subscription?: () => Subscription;
  subscriptions?: () => Subscription[];
  productActivations?: () => ProductActivation[];
  orders?: () => Order[];
  accountNotes?: () => AccountNote[];
  skills?: () => Skill[];
  interests?: () => Interest[];
  primaryCoach?: () => Account;
  profile?: () => AccountProfile;
  shareables?: () => Shareable[];
}

export interface AccountCreateInput {
  first_name: string;
  last_name_insertion: string;
  last_name: string;
  email_address: string;
  /** @deprecated Use `relationships.entity` instead */
  entity: UUID;
  password: string;
  relationships?: {
    /** Entity the user belongs to */
    entity?: UUID;
    /** Skills the user has indicated to possess */
    skills?: UUID[];
    /** Interests of the user */
    interests?: UUID[];
    /** A coach that is the primary contact for a participant */
    primary_coach?: UUID | null;
  };
}

export interface AccountUpdateInput extends Partial<AccountCreateInput> {
  /** The phone number of the user */
  phone_number?: string;
  /** The educational sector of the user (open input by user) */
  sector?: string;
  /** The name of the study the user is following (open input by user) */
  education?: string;
  /** The API only allows for updating the role to student or coach */
  role?: 'student' | 'coach';
}

export enum AccountIncludes {
  enrollments,
  entity,
  tags,
  subscription,
  subscriptions,
  'subscriptions.portal',
  'subscriptions.plan',
  orders,
  'orders.order_lines',
  notes,
  'notes.author',
  skills,
  interests,
  primary_coach,
  product_activations,
  'product_activations.product',
  profile,
}

export interface AccountFilters {
  portal: string;
  entity: string;
  full_name: string;
  email_address: string;
  identifier: string;
}

export enum AccountSort {}

/* meta for checking tfa requirements */
export type AccountMeta = keyof {
  has_enabled_tfa?: boolean;
};

export type MinimalAccount = Pick<
  Account,
  | 'avatarUrl'
  | 'firstName'
  | 'fullName'
  | 'id'
  | 'lastName'
  | 'lastNameInsertion'
>;
