import { useContext } from 'react';
import {
  ILearningRouteContext,
  LearningRouteContext,
} from './LearningRouteContext';

/**
 * useLearningRouteProvider.
 *
 * @param nullable When true, this hook returns null when no context is found.
 */
export function useLearningRouteProvider(
  nullable?: false
): ILearningRouteContext;
export function useLearningRouteProvider(
  nullable?: true
): null | ILearningRouteContext;
export function useLearningRouteProvider(nullable = false) {
  const context = useContext(LearningRouteContext);

  if (!context && !nullable) {
    throw new Error('Please, initiate the LearningRouteProvider first!');
  }

  return context;
}
