import { UseMutationOptions } from 'react-query';
import { useCreate } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';

interface IChangePasswordBase {
  password: string;
}

interface IChangePasswordTokenBased extends IChangePasswordBase {
  email_address: string;
  token: string;
}

interface IChangePasswordPasswordBased extends IChangePasswordBase {
  current_password: string;
}

export type TChangePasswordAction =
  | IChangePasswordTokenBased
  | IChangePasswordPasswordBased;

type TChangePasswordSettings = UseMutationOptions<
  DataDocument<undefined>,
  ErrorDocument,
  TChangePasswordAction
>;

/**
 * useChangePassword.
 *
 * Changes the password of the user.
 */
export function useChangePassword(settings?: TChangePasswordSettings) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.account_actions/change_password',
      baseUri: '/v1/account_actions/change_password',
    },
    context
  );
  return useCreate<undefined, TChangePasswordAction>(request, settings);
}
