/* eslint-disable class-methods-use-this */
import { v4 as uuid } from 'uuid';
import { UnknownBlock } from '../Types';
import { Migration } from './BaseMigration';

/**
 * This migration changes the Accordion structure to
 * a container structure.
 */
export class AccordionContainerV1Migration extends Migration {
  name = 'AccordionContainerV1Migration';

  shouldRun() {
    return super.shouldRun() && this.list.some(this.isLegacy);
  }

  isLegacy(block: UnknownBlock): boolean {
    return block.type === 'accordion';
  }

  run() {
    return this.forEachLegacyBlock((input) => ({
      ...((input.content || []).length > 1
        ? {
            id: input.id,
            type: 'group_container' as const,
            style: {
              padding: 0,
            },
            blocks: (input.content || []).map((item) => ({
              id: uuid(),
              type: 'accordion_container' as const,
              title: item.title,
              blocks: [
                {
                  id: uuid(),
                  type: 'rich_media',
                  content: item.description,
                },
              ],
            })),
          }
        : {
            id: input.id,
            type: 'accordion_container',
            title: input?.content?.[0]?.title,
            blocks: [
              {
                id: uuid(),
                type: 'rich_media',
                content: input.content?.[0]?.description,
              },
            ],
          }),
    }));
  }
}
