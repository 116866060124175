import { ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { Comment as CommentType, StoredFile } from '@brainstud/academy-api';
import { useComments } from '@brainstud/academy-api/Hooks/useComments';
import { Button } from '@brainstud/ui/Buttons';
import { FileCard } from '@brainstud/ui/FileHandling';
import { LoadingDots } from '@brainstud/ui/Loaders';
import { Comment as CommentIcon, KeyboardArrowDown } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Avatar } from 'Components';
import { CommentForm } from 'Components/ActivityFeed/CommentForm';
import { useTranslator } from 'Providers/Translator';
import { DateFormatter } from 'Utils/DateFormatHelper';
import { sanitizer } from 'Utils/Sanitizer';
import { Comment } from '../Comment';
import styles from './Thread.module.css';

const cx = classNames.bind(styles);

export type TProps = {
  thread: CommentType;
  isExpanded?: boolean;
  actions?: ReactNode;
  author?: string;
  subheader?: ReactNode;
  onToggleActions?: (threadId: string) => void;
  attachments?: StoredFile[];
};

/**
 * Thread.
 *
 * Displays a thread with comments.
 */
export const Thread = ({
  thread,
  isExpanded = false,
  actions,
  author,
  subheader,
  onToggleActions,
  attachments = [],
}: TProps) => {
  const [t] = useTranslator();
  const actionsRef = useRef<HTMLDivElement>(null);
  // It is currently not possible to fetch the comments with accounts attached, so we query this seperately when expanded
  const threadComments = useMemo(() => thread.comments?.() || [], [thread]);
  const [{ data: comments }, { isLoading }] = useComments(
    { filter: { [thread.type]: thread.id } },
    { enabled: !!thread.type && !!thread.id && isExpanded }
  );

  const handleToggleActions = useCallback(() => {
    onToggleActions?.(thread.id);
  }, [onToggleActions, thread]);

  useEffect(() => {
    if (isExpanded && actionsRef.current) {
      actionsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isExpanded]);

  return (
    <section
      className={cx(styles.base, { isExpanded })}
      data-thread-id={thread.id}
    >
      {author && (
        <div className={cx(styles.author)}>
          <Avatar
            name={author}
            size="extra-small"
            className={cx(styles.avatar)}
          />
          <span className={cx(styles.name)}>{author}</span>
          <span className={cx(styles.timestamp)}>
            {DateFormatter.toTime(thread.createdAt)}
          </span>
        </div>
      )}

      {subheader && <div className={cx(styles.subheader)}>{subheader}</div>}

      {thread.content && (
        <div className={cx(styles.message)}>
          <p dangerouslySetInnerHTML={{ __html: sanitizer(thread.content) }} />
        </div>
      )}

      {attachments.length > 0 && (
        <div className={cx(styles.attachments)}>
          {attachments.map((attachment) => (
            <FileCard
              key={attachment.id}
              thumbnail={attachment.downloadUrl}
              href={attachment.downloadUrl}
            >
              <span>{attachment.originalFileName}</span>
            </FileCard>
          ))}
        </div>
      )}

      <div className={cx(styles.actions)} ref={actionsRef}>
        <Button
          type="button"
          quiet
          small
          className={cx(styles.button)}
          onClick={handleToggleActions}
        >
          <CommentIcon />
          {threadComments.length > 0 && (
            <span>
              {threadComments.length === 1
                ? t('components.activity_feed.num_comment')
                : t('components.activity_feed.num_comments', {
                    comments: threadComments.length,
                  })}
            </span>
          )}
          {(threadComments.length > 0 || isExpanded) && (
            <div className={cx(styles.commentButton)}>
              <KeyboardArrowDown />
            </div>
          )}
        </Button>
        {actions}
      </div>

      {isExpanded && (
        <div className={cx(styles.comments, { isExpanded })}>
          {isLoading && <LoadingDots />}
          {comments.map((comment) => (
            <Comment key={comment.id} comment={comment} />
          ))}

          <div className={cx(styles.form)}>
            <CommentForm id={thread.id} type={thread.type} />
          </div>
        </div>
      )}
    </section>
  );
};
