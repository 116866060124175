import React, { CSSProperties, useCallback, useState } from 'react';
import {
  ConditionType,
  LearningObject,
  useObjectCondition,
  UUID,
} from '@brainstud/academy-api';
import { Button, Callout, ContentModal, Dropdown } from '@brainstud/ui';
import { DeleteForever } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import type { ILearningRouteGroup } from '../../LearningRouteEditor';
import styles from './AssignmentConditionsModal.module.css';

const cx = classNames.bind(styles);
const ASSIGNMENT_TYPES = [
  'NODE_COMPLETED',
  'LEARNING_OBJECT_APPROVED',
  'LEARNING_OBJECT_SUBMITTED',
];

type Props = {
  closeModal: () => void;
  style?: CSSProperties;
  className?: string;
  baseObject: LearningObject | ILearningRouteGroup;
  learningRouteId: UUID;
  nodes?: ILearningRouteGroup[];
};

/**
 * AssignmentConditionsModal.
 *
 * With this modal you can add, update or delete Assignment Conditions
 *
 */
export const AssignmentConditionsModal = ({
  className,
  style,
  closeModal,
  baseObject,
  nodes,
  learningRouteId,
}: Props) => {
  const [t] = useTranslator();
  const [setFlash] = useToaster();

  const [target, setTarget] = useState<{ type: string; identifier: string }>();
  const objectId = baseObject.id;
  const objectTitle = baseObject.title;
  const objectType = baseObject.resourceType;
  const objectConditions = baseObject.conditions?.();
  const objectConditionId =
    (baseObject.resourceType === 'learning_objects' ||
      baseObject.resourceType === 'learning_route_nodes') &&
    objectConditions?.find(
      (condition) => condition.resourceType !== 'object_condition'
    )?.id;
  const [{ createOrUpdate, destroy }] = useObjectCondition(
    {
      objectType,
      objectIdentifier: objectId,
      ...(objectConditionId && { condition: objectConditionId }),
    },
    { enabled: false }
  );

  const conditionType = {
    learning_route_nodes: 'NODE_COMPLETED',
    learning_objects: 'LEARNING_OBJECT_APPROVED',
  }[target?.type || 'learning_objects'] as ConditionType;

  const handleCondition = useCallback(() => {
    createOrUpdate
      .mutateAsync({
        target: target?.identifier,
        learning_route: learningRouteId,
        type: conditionType,
      })
      .then(() => {
        closeModal();
      })
      .catch(() => {
        setFlash(
          t('course-editor.learning_route_editor.assignment_conditions.errors'),
          'error'
        );
      });
  }, [
    closeModal,
    conditionType,
    createOrUpdate,
    learningRouteId,
    setFlash,
    t,
    target?.identifier,
  ]);

  const handleDelete = useCallback(() => {
    destroy
      .mutateAsync()
      .then(() => {
        closeModal();
        setFlash(
          t(
            'course-editor.learning_route_editor.assignment_conditions.success.delete'
          ),
          'success'
        );
      })
      .catch(() => {
        setFlash(
          t('course-editor.learning_route_editor.assignment_conditions.errors'),
          'error'
        );
      });
  }, [destroy, setFlash, t]);
  const itemIsHidden = useCallback((itemId) => itemId === objectId, [objectId]);

  const setObjectConditionTarget = (value?: string) => {
    if (value) {
      const split = value?.split(':');
      setTarget({ type: split[0], identifier: split[1] });
    }
  };

  const firstAssignmentObjectTarget = objectConditions
    ? objectConditions
        .filter((item) => ASSIGNMENT_TYPES.includes(item.type))[0]
        .target?.()
    : undefined;

  return (
    <ContentModal
      className={cx(styles.base, className)}
      onClickOutside
      onClose={closeModal}
      style={{ ...style, overflow: 'visible' }}
    >
      <h2>
        {t(
          `course-editor.learning_route_editor.assignment_conditions.titles.${objectConditionId ? 'edit' : 'add'}`
        )}
      </h2>
      <span className={cx(styles['sub-title'])}>
        {t(
          'course-editor.learning_route_editor.assignment_conditions.subtitle'
        )}
      </span>
      {objectConditionId && (
        <Callout warning className={cx(styles.callout)}>
          <div className={cx(styles['callout-content'])}>
            <strong>
              {t(
                'course-editor.learning_route_editor.assignment_conditions.warning'
              )}
            </strong>
            <span>
              {t(
                'course-editor.learning_route_editor.assignment_conditions.edit_callout'
              )}
            </span>
          </div>
        </Callout>
      )}
      <div className={cx(styles.wrapper)}>
        <div className={cx(styles.row)}>
          <strong>
            {t(
              'course-editor.learning_route_editor.assignment_conditions.target_action'
            )}
          </strong>
          {objectTitle && (
            <span
              dangerouslySetInnerHTML={{
                __html: sanitizer(
                  `${t('course-editor.learning_route_editor.assignment_conditions.action', { base_object_title: objectTitle })}`
                ),
              }}
            />
          )}
        </div>
        <div className={cx(styles.row)}>
          <strong>
            {t(
              'course-editor.learning_route_editor.assignment_conditions.condition'
            )}
          </strong>
          <div className={cx(styles.conditions)}>
            <Dropdown
              onChange={(value) => setObjectConditionTarget(value)}
              placeholder={t(
                'course-editor.learning_route_editor.assignment_conditions.condition_placeholder'
              )}
              defaultValue={`${firstAssignmentObjectTarget?.resourceType}:${firstAssignmentObjectTarget?.id}`}
            >
              {nodes
                ?.filter((item) => !itemIsHidden(item.id))
                .map((node) => (
                  <Dropdown.Group
                    key={`${node.id}-node`}
                    label={`${node.title}`}
                  >
                    <>
                      <Dropdown.Option
                        value={`${node.resourceType}:${node.id}`}
                      >
                        {t(
                          'course-editor.learning_route_editor.assignment_conditions.whole_chapter',
                          {
                            chapter: node.title,
                          }
                        )}
                      </Dropdown.Option>
                      {node.scheme
                        .filter((object) => !itemIsHidden(object.id))
                        .map((object) =>
                          /* LearningObjects of a LearningSubject */
                          object.resourceType === 'learning_subjects' ? (
                            object
                              ?.learningObjects?.()
                              .filter(
                                (subObject) => !itemIsHidden(subObject.id)
                              )
                              .map((subObject) => (
                                <Dropdown.Option
                                  key={`${subObject.id}-subject`}
                                  value={`${subObject.resourceType}:${subObject.id}`}
                                >
                                  {subObject?.title}
                                </Dropdown.Option>
                              ))
                          ) : (
                            <Dropdown.Option
                              key={`${object.id}-object`}
                              value={`${object.resourceType}:${object.id}`}
                            >
                              {object.title}
                            </Dropdown.Option>
                          )
                        )}
                    </>
                  </Dropdown.Group>
                ))}
            </Dropdown>
            {objectConditionId && (
              <div className={cx(styles.delete)}>
                <Button quiet onClick={handleDelete} error>
                  <DeleteForever />
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <ContentModal.Footer>
        <Button
          disabled={!target || createOrUpdate.isLoading}
          loading={createOrUpdate.isLoading}
          onClick={handleCondition}
        >
          {t(
            `course-editor.learning_route_editor.assignment_conditions.button.${objectConditionId ? 'edit' : 'add'}`
          )}
        </Button>
        <Button quiet onClick={closeModal}>
          {t('course-editor.learning_route_editor.assignment_conditions.close')}
        </Button>
      </ContentModal.Footer>
    </ContentModal>
  );
};
