import { UseQueryOptions } from 'react-query';
import { useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  OrderAutomationProduct,
  OrderAutomationProductCreate,
  OrderAutomationProductFilters,
  OrderAutomationProductIncludes,
  OrderAutomationProductSort,
  OrderAutomationProductUpdate,
  UUID,
} from '../Types';

interface IOrderAutomationProductsProps
  extends HookParams<
    typeof OrderAutomationProductIncludes,
    OrderAutomationProductFilters,
    typeof OrderAutomationProductSort
  > {
  orderAutomation?: UUID;
  orderAutomationProduct?: UUID;
}

export function useOrderAutomationProduct(
  {
    orderAutomationProduct,
    orderAutomation,
    ...queryParameters
  }: IOrderAutomationProductsProps = {},
  settings?: UseQueryOptions<
    DataDocument<OrderAutomationProduct>,
    ErrorDocument
  >
) {
  const context = useApi();
  const {
    parameters: { order: defaultOrder },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(orderAutomationProduct || defaultOrder),
      baseName: 'v1.order_automation.order_automation_product',
      baseUri: `/v1/order_automations/${orderAutomation}/order_automation_products`,
      invalidate: ['order_automation_products'],
      uri: `/${orderAutomationProduct}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    OrderAutomationProduct,
    OrderAutomationProductCreate,
    OrderAutomationProductUpdate
  >(request, settings);
}
