import { useContext } from 'react';
import { AccessContext, IAccessContext } from './AccessContext';

/**
 * useAccessProvider.
 *
 * With the useAccessProvider you can check if the current user has Access to the resource,
 * for now on a global scope but this provider is build to be more specific.
 *
 * @param nullable When true, this hook returns null when no context is found.
 */
export function useAccessProvider(nullable?: false): IAccessContext;
export function useAccessProvider(
  nullable?: true
): Partial<IAccessContext> | IAccessContext;
export function useAccessProvider(nullable = false) {
  const context = useContext(AccessContext);

  if (!context && !nullable) {
    throw new Error('Please, initiate the AccessProvider first!');
  }

  return context || {};
}
