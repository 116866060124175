import React, {
  ChangeEventHandler,
  CSSProperties,
  useCallback,
  useMemo,
} from 'react';
import {
  Course,
  StudentFilters,
  StudentSorts,
  useMe,
  useStudentGroups,
} from '@brainstud/academy-api';
import { Chip, QuickFilter, Search } from '@brainstud/ui';
import { GetApp, SupervisorAccount } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Container, FilterSidebarButton, InlineList } from 'Components';
import { useRouter } from 'next/router';
import { TFilterInput, useViewSettings } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { TViewNames } from 'Providers/ViewSettingsProvider/ViewSettingsActions';
import { ActionMenu, SubscriptionCallout } from '../../../Components';
import StudentsFilterContent from './StudentsFiltercontent/StudentsFilterContent';
import styles from './StudentsSearchAndFilter.module.css';

const cx = classNames.bind(styles);

type Props = {
  courses?: Course[];
  style?: CSSProperties;
  className?: string;
  viewName: TViewNames;
};

type TFilters = {
  filters: Partial<StudentFilters>;
  sort: StudentSorts[];
};

type TFilter =
  | TFilterInput<TFilters['filters']>
  | TFilterInput<TFilters['filters']>[];

/**
 * Handles the search and filtering for the student overview
 */
export const StudentsSearchAndFilter = ({
  courses,
  style,
  className,
  viewName,
}: Props) => {
  const [t] = useTranslator();
  const router = useRouter();
  const { collectionId } = router.query as { collectionId: string };
  const [me] = useMe();
  const currentLoggedInCoachId = me?.account?.().id;
  const [{ data: studentGroups }] = useStudentGroups();
  const [{ filters, apiFilters = {} }, dispatch] =
    useViewSettings<TFilters>(viewName);

  const displayableFilters = Object.keys(apiFilters).filter(
    (key) =>
      (key !== 'coach_account' ||
        apiFilters.coach_account !== currentLoggedInCoachId) &&
      (key !== 'archived' || apiFilters.archived === true)
  );

  const handleFilterChange = useCallback(
    (filterInput: TFilter) => {
      if (Array.isArray(filterInput)) {
        dispatch({ type: 'BATCH_FILTERS', payload: { filters: filterInput } });
      } else {
        dispatch({ type: 'TOGGLE_FILTER', payload: filterInput });
      }
    },
    [dispatch]
  );

  const handleSearch = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      const { value } = event.target;
      if (value) {
        dispatch({
          type: 'SET_FILTER',
          payload: { key: 'full_name', value, label: value },
        });
      } else {
        dispatch({ type: 'REMOVE_FILTER', payload: { key: 'full_name' } });
      }
    },
    [dispatch]
  );

  const downloadLink = useMemo(() => {
    const accountId = me?.account?.().id;
    const filterCoach = apiFilters.coach_account;
    return `${window.location.origin}/exports/students_overview${collectionId ? `/${filterCoach || accountId}/${collectionId}` : ''}`;
  }, [collectionId, apiFilters.coach_account, me]);

  const handleMyParticipantsQuickFilter = useCallback(() => {
    const { coach_account } = apiFilters;
    const coach =
      coach_account && coach_account === currentLoggedInCoachId
        ? undefined
        : currentLoggedInCoachId;

    if (coach)
      dispatch({
        type: 'SET_FILTER',
        payload: { key: 'coach_account', value: coach, label: coach },
      });
    else dispatch({ type: 'REMOVE_FILTER', payload: { key: 'coach_account' } });
  }, [apiFilters, currentLoggedInCoachId, dispatch]);

  const removeChipFilter = useCallback(
    (activeFilter: keyof StudentFilters) => {
      dispatch({ type: 'REMOVE_FILTER', payload: { key: activeFilter } });
    },
    [dispatch]
  );

  const handleResetFilters = useCallback(
    () => dispatch({ type: 'RESET_FILTERS', payload: {} }),
    [dispatch]
  );

  const handleResetSearch = useCallback(
    () => dispatch({ type: 'REMOVE_FILTER', payload: { key: 'full_name' } }),
    [dispatch]
  );

  return (
    <div className={cx(styles.root, className)} style={style}>
      <Container>
        <div className={cx(styles.filterBar)}>
          <FilterSidebarButton
            className={cx(styles.filter)}
            label={t('students.filter')}
            title={t('students.filter_panel.title')}
            onReset={handleResetFilters}
          >
            <StudentsFilterContent
              studentGroups={studentGroups}
              courses={courses}
              onFilterChange={handleFilterChange}
              viewName={viewName}
            />
          </FilterSidebarButton>
          <Search
            onChange={handleSearch}
            placeholder={t('search')}
            defaultValue={apiFilters.full_name || ''}
            onClose={handleResetSearch}
          />
          <div className={cx(styles.spacer)} />
          <ActionMenu id="student-actions">
            <a role="menuitem" href={downloadLink}>
              <GetApp />
              <span>{t('students.export_list')}</span>
            </a>
          </ActionMenu>
        </div>
        <div className={cx(styles.quickFilter)}>
          <InlineList>
            <QuickFilter
              isActive={apiFilters.coach_account === currentLoggedInCoachId}
              onClick={handleMyParticipantsQuickFilter}
            >
              <SupervisorAccount />
              <span>{t('students.myStudents')}</span>
            </QuickFilter>
          </InlineList>
          {displayableFilters.length > 0 && (
            <InlineList>
              {displayableFilters.map((activeFilter) => (
                <Chip
                  key={activeFilter}
                  label={
                    filters?.[activeFilter]?.label ||
                    t(`students.filters.${activeFilter}`)
                  }
                  OnClose={() =>
                    removeChipFilter(activeFilter as keyof StudentFilters)
                  }
                />
              ))}
            </InlineList>
          )}
        </div>
      </Container>
      <Container>
        <SubscriptionCallout />
      </Container>
    </div>
  );
};
