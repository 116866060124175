import { useCallback } from 'react';
import { Language, useApi, useLanguage } from '@brainstud/academy-api';
import { ActionMenu, ConfirmationModal, Td, Tr } from '@brainstud/ui';
import { DeleteForever } from '@mui/icons-material';
import { useModals, useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';

/**
 * Component that take a single language and converts it to a table with a delete action.
 * @param language The language to display
 * @returns Rendered language row for a table
 */
export const LanguageRow = ({ language }: { language: Language }) => {
  const [t] = useTranslator();
  const [setToast] = useToaster();
  const { openModal, closeModal } = useModals();
  const [{ destroy }] = useLanguage(
    { language: language.id },
    { enabled: false }
  );
  const { invalidateQueries } = useApi();

  const handleDeleteAction = useCallback(() => {
    openModal(ConfirmationModal, {
      title: t('views.languages.actions.delete.title'),
      description: t('views.languages.actions.delete.description'),
      yes: t('views.languages.actions.delete.confirm'),
      no: t('cancel'),
      isDanger: true,
      handleConfirm: async () =>
        destroy.mutateAsync(undefined, {
          onSuccess: () => {
            setToast(t('views.languages.actions.delete.success'), 'success');
            closeModal();
            invalidateQueries(['v1.languages']);
          },
          onError: () => {
            setToast(t('views.languages.actions.delete.error'), 'error');
          },
        }),
    });
  }, [closeModal, destroy, invalidateQueries, openModal, setToast, t]);

  return (
    <Tr>
      <Td href={`/admin/languages/${language.id}`}>{language.label}</Td>
      <Td right collapse>
        <ActionMenu id="manage-languages">
          <button role="menuitem" type="button" onClick={handleDeleteAction}>
            <DeleteForever />
            <span>{t('delete')}</span>
          </button>
        </ActionMenu>
      </Td>
    </Tr>
  );
};
