import React, { ReactPortal, useEffect, useState } from 'react';
import { useDragLayer } from 'react-dnd';
import ReactDOM from 'react-dom';
import styles from './DragLayer.module.css';

/**
 * The DragLayer is used to show the element whilst dragging. It is added through a portal to the document body.
 */
export function DragLayer(): null | ReactPortal;
export function DragLayer() {
  const { item, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    isDragging: monitor.isDragging(),
    currentOffset: monitor.getSourceClientOffset(),
  }));
  const Preview = item && item.preview;

  const [container, setContainer] = useState<HTMLDivElement>();
  useEffect(() => {
    if (!container) {
      const element: HTMLDivElement =
        container ||
        (document.getElementById(
          'universalcomponents__draglayer'
        ) as HTMLDivElement) ||
        document.createElement('div');
      if (!element.id) {
        element.id = 'universalcomponents__draglayer';
        document.body.appendChild(element);
      }
      setContainer(element);
    }
  }, [container]);

  return container
    ? ReactDOM.createPortal(
        <div className={`${styles.base} drag-layer`}>
          <div
            style={
              currentOffset
                ? {
                    transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)`,
                  }
                : {}
            }
          >
            {Preview}
          </div>
        </div>,
        container
      )
    : null;
}
