import { LearningObject, LearningSubject } from '@brainstud/academy-api';
import { isPresent } from 'ts-is-present';
import {
  ILearningObjectWithResources,
  ILearningRouteNodeWithResources,
  ILearningSubjectWithResources,
  ParentType,
  TNodeListItem,
} from './LearningRouteContext';

type NodeList = {
  scheme?: { id: string; type: string }[];
  [key: string]: any;
}[];

type ItemWithScheme =
  | ILearningRouteNodeWithResources
  | ILearningSubjectWithResources;
type LearningObjectOrSubject =
  | ILearningSubjectWithResources
  | ILearningObjectWithResources;
type TCollection = Array<LearningObject | LearningSubject>;

function isWithScheme(node: object): node is ItemWithScheme {
  return 'scheme' in node;
}

/**
 * convertToRouteTree.
 *
 * Converts a learning route to a full tree by recursively checking the scheme property and replacing that
 * with the full resource from the provided collection.
 *
 * @param {NodeList} nodes
 * @param {Array<LearningObject | LearningSubject>} collection
 * @param parent {ILearningSubjectWithResources | ILearningRouteNodeWithResources}
 * @returns {TNodeListItem[]}
 */
export function convertToRouteTree(
  nodes: NodeList,
  collection: TCollection,
  parent?: ParentType
): TNodeListItem[] {
  return nodes.map((node) => {
    if (isWithScheme(node)) {
      const nodeTree = node.scheme
        .map((schemeItem) =>
          collection.find((item) => item.id === schemeItem.id)
        )
        .filter(isPresent);
      return {
        ...node,
        ...(parent
          ? {
              parent,
              scheme: convertToRouteTree(
                nodeTree,
                collection,
                parent
              ) as unknown as Array<LearningObjectOrSubject>,
            }
          : {}),
        scheme: convertToRouteTree(
          nodeTree,
          collection,
          node
        ) as unknown as Array<LearningObjectOrSubject>,
      };
    }
    return { ...node, ...(parent ? { parent } : {}) } as TNodeListItem;
  });
}
