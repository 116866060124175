import React from 'react';
import classNames from 'classnames/bind';
import { ProgressMarker } from './ProgressMarker';
import { ProgressProps } from './ProgressProps';
import styles from './Progress.module.css';

const cx = classNames.bind(styles);

/**
 * A uniform way of showing progress, with the possibility to add markers and an indicator along the bar
 */
export const Progress = ({
  indicator,
  label,
  loading,
  value,
  max,
  markers = [],
  className,
}: ProgressProps) => {
  const parsedValue = Number(value) || 0;
  const parsedMax = Number(max) || 100;
  const isEnded = parsedValue >= parsedMax;
  const oneStepWidth = (1 / parsedMax) * 100;
  const percentage = Math.min(100, Math.max(0, oneStepWidth * parsedValue));

  return (
    <div className={cx(styles.base, className)}>
      <div
        className={cx(styles.trackContainer, 'progress__track', { loading })}
      >
        <div
          className={cx(styles.track, { isFull: isEnded })}
          style={{ width: `${percentage}%` }}
        />
        {!loading && (
          <>
            {markers.map((marker) => (
              <ProgressMarker
                key={marker.step}
                style={{
                  ...marker.style,
                  left: `${oneStepWidth * marker.step}%`,
                }}
                tooltip={marker.tooltip}
                isPassed={oneStepWidth * marker.step < percentage}
              >
                {marker.child}
              </ProgressMarker>
            ))}
            {indicator && !isEnded && (
              <div
                className={cx(styles.indicator)}
                style={{ ...indicator.style, left: `${percentage}%` }}
              >
                {indicator.component}
              </div>
            )}
          </>
        )}
      </div>
      {label && (
        <div className={cx(styles.label, 'progress__label')}>{label}</div>
      )}
    </div>
  );
};
