import React, { useMemo } from 'react';
import {
  CertificationDossier,
  useCertificationDossierCoreTasks,
} from '@brainstud/academy-api';
import { Loading } from 'Components';
import { useTranslator } from 'Providers/Translator';

export interface DossierInfoProps {
  dossier: CertificationDossier;
}

const DossierInfo = ({ dossier }: DossierInfoProps) => {
  const [t] = useTranslator();
  const [{ data: coreTasks }, { isLoading }] = useCertificationDossierCoreTasks(
    {
      dossier: dossier.id,
    }
  );

  const workprocesses = useMemo(
    () => coreTasks.flatMap((coreTask) => coreTask?.workprocesses?.() || []),
    [coreTasks]
  );

  if (isLoading) return <Loading />;

  return (
    <>
      {coreTasks.map((coreTask) => {
        const skills = coreTask?.knowledgeSkills?.() || [];

        return (
          <>
            {workprocesses.map((workprocess) => {
              const behaviours = workprocess.behaviours?.() || [];
              return (
                <React.Fragment key={workprocess.id}>
                  <h4>{`${workprocess.code}: ${workprocess.title}`}</h4>

                  {behaviours && behaviours.length > 0 && (
                    <>
                      <p>
                        <strong>
                          {t('components.dossierInfo.behaviours')}
                        </strong>
                      </p>
                      <ul>
                        {behaviours.map((behavior) => (
                          <li key={behavior.id}>{behavior.description}</li>
                        ))}
                      </ul>
                    </>
                  )}
                </React.Fragment>
              );
            })}
            {skills && skills.length > 0 && (
              <>
                <h4>{`${coreTask.code}: ${coreTask.title}`}</h4>
                <p>
                  <strong>{t('components.dossierInfo.skills')}</strong>
                </p>
                <ul>
                  {skills.map(({ id, description }) => (
                    <li key={id}>{description}</li>
                  ))}
                </ul>
              </>
            )}
          </>
        );
      })}
    </>
  );
};

export default DossierInfo;
