import React, { useMemo } from 'react';
import { TRouteLayouts } from '@brainstud/academy-api';
import { Accordion } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { TileSelector, Wrap } from 'Components';
import { useTranslator } from 'Providers/Translator';
import { Layouts, TLayoutConfig } from 'Views/CollectionViews';
import { ReactComponent as ObjectLinearIcon } from './assets/object-layout-linear.svg';
import { ReactComponent as ObjectOpenIcon } from './assets/object-layout-open.svg';
import styles from './LayoutSelector.module.css';

const cx = classNames.bind(styles);

type Props<T = 'route' | 'subject' | 'object'> = {
  layoutType: T;
  setSelectedLayout: (value: string) => void;
  selectedLayout?: string;
  routeLayout?: string;
  isQuiz?: boolean;
};

/**
 * LayoutSelector.
 *
 * Determines the possible layouts based on current configuration and provides a TileSelector for each to select
 * the desired layout. Deprecated layouts will be hidden.
 */
export const LayoutSelector = <T extends string = string>({
  layoutType,
  setSelectedLayout,
  selectedLayout,
  routeLayout,
  isQuiz,
}: Props<T>) => {
  const [t] = useTranslator();

  const Icons = {
    'action-cards': ObjectOpenIcon,
    'chapter-cards': ObjectLinearIcon,
    open: ObjectOpenIcon,
    linear: ObjectLinearIcon,
    panel: ObjectOpenIcon,
  };

  const layouts = useMemo(() => {
    const filteredLayouts = Object.entries<TLayoutConfig>(
      layoutType === 'route'
        ? Layouts
        : Layouts[routeLayout || 'action-cards'].sublayouts[layoutType]
    )
      .map(([name, config]) => ({ name, view: config.view }))
      .filter(
        (option) =>
          !option.view.deprecated &&
          !(
            layoutType === 'object' &&
            !isQuiz &&
            option.name.includes('question')
          )
      );

    return filteredLayouts;
  }, [layoutType, routeLayout, isQuiz]);

  return (
    <>
      {layouts && layouts?.length > 1 && (
        <Wrap
          if={layoutType === 'object'}
          // eslint-disable-next-line react/no-unstable-nested-components
          with={({ children }) => (
            <Accordion
              quiet
              heading
              title={t('views.courses.collection_edit.layouts.selector.title')}
            >
              {children}
            </Accordion>
          )}
        >
          <div className={cx(styles.tiles)}>
            {layouts?.map((layout) => {
              const Icon = Icons[layout?.name];
              return (
                <TileSelector
                  key={layout.name}
                  name="layout"
                  value={layout.name}
                  onChange={(event) =>
                    setSelectedLayout(event.target.value as TRouteLayouts)
                  }
                  selected={selectedLayout === layout.name}
                  quiet
                >
                  <div className={styles.tileContent}>
                    {Icon && (
                      <div className={cx(styles.iconWrapper)}>
                        <Icon />
                      </div>
                    )}
                    <div className={cx(styles.info)}>
                      <strong>
                        {t(
                          `views.courses.collection_edit.layouts.${layoutType}.${layout.name}.title`
                        )}
                      </strong>
                      <span>
                        {t(
                          `views.courses.collection_edit.layouts.${layoutType}.${layout.name}.description`
                        )}
                      </span>
                    </div>
                  </div>
                </TileSelector>
              );
            })}
          </div>
        </Wrap>
      )}
    </>
  );
};
