import { useCallback, useMemo } from 'react';
import { Student } from '@brainstud/academy-api';
import { Td, TdCheckbox } from '@brainstud/ui';
import { Timestamp } from '@brainstud/ui/Static/Timestamp';
import { StudentExamStepSummary } from '../Students/StudentExamStepSummary';
import { StudentInfo } from '../Students/StudentInfo';

type TProps = {
  data: Student;
  isSelected: boolean;
  onToggleSelect: (studentIds: string[]) => void;
};

export const StudentRow = ({
  data: student,
  isSelected,
  onToggleSelect,
}: TProps) => {
  const enrollments = useMemo(() => student.enrollments?.(), [student]);

  const handleToggleSelect = useCallback(() => {
    onToggleSelect([student.id]);
  }, [student.id, onToggleSelect]);

  return (
    <>
      <TdCheckbox
        id={student.id}
        checked={isSelected}
        onChange={handleToggleSelect}
      />
      <Td>
        <StudentInfo
          classUnit={student.classUnit || student.classCode}
          fullName={student.fullName}
          lastOnline={student.lastOnline}
          unseenAnswerCount={student.meta?.unseenAnswerCount}
        />
      </Td>
      <Td collapse>
        <StudentExamStepSummary enrollments={enrollments} />
      </Td>
      <Td collapse>
        <Timestamp timestamp={student.lastOnline} />
      </Td>
    </>
  );
};
