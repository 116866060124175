import React from 'react';
import { Container } from 'Components';
import { sanitizer } from 'Utils/Sanitizer';
import styles from './About.module.css';

interface AboutProps {
  title: string;
  content: string;
}

const AboutBlock = ({ title, content }: AboutProps) => (
  <div className={styles.about}>
    <Container>
      <h2>{title}</h2>
      <div
        className={styles.content}
        dangerouslySetInnerHTML={{ __html: sanitizer(content) }}
      />
    </Container>
  </div>
);

export default AboutBlock;
