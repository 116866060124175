import { AnswerStatus, LearningObject } from '@brainstud/academy-api';
import {
  TNodeListItem,
  TProgress,
  TProgressFn,
} from 'Providers/LearningRouteProvider/LearningRouteContext';

function hasStatus(
  userState?: { status?: AnswerStatus },
  ...status: AnswerStatus[]
) {
  if (status.length === 0) {
    return !!userState;
  }
  return !!userState?.status && status.includes(userState?.status);
}

/**
 * Calculate the total progress of NodeItem list by recursively retrieving the data.
 */
export function getTotalProgress(
  items: TNodeListItem[],
  list?: Array<TNodeListItem>,
  progress: TProgress = {
    madeObjects: 0,
    totalObjects: 0,
    madeObjectsPercentage: 0,
    acceptedObjects: 0,
    rejectedObjects: 0,
    turnedInObjects: 0,
    xpReceived: 0,
    xpAvailable: 0,
    xpPercentage: 0,
    percentage: 0,
  }
): ReturnType<TProgressFn> {
  return items?.reduce<TProgress>((totalProgress, item) => {
    switch (item.resourceType) {
      case 'learning_route_nodes':
        return getTotalProgress(item?.scheme, list, totalProgress);
      case 'learning_subjects':
        return getTotalProgress(
          item?.scheme || item?.learningObjects?.() || [],
          list,
          totalProgress
        );
      case 'learning_objects': {
        const userState = {
          ...(item.userState ||
            (
              list?.find(
                (listItem) =>
                  listItem?.resourceType === 'learning_objects' &&
                  listItem?.id === item.id
              ) as undefined | LearningObject
            )?.userState),
          ...(item.progress ||
            (
              list?.find(
                (listItem) =>
                  listItem?.resourceType === 'learning_objects' &&
                  listItem?.id === item.id
              ) as undefined | LearningObject
            )?.progress),
        };

        const absoluteProgress = {
          madeObjects:
            hasStatus(userState) && !hasStatus(userState, 'OPEN')
              ? totalProgress.madeObjects + 1
              : totalProgress.madeObjects,
          totalObjects: totalProgress.totalObjects + 1,
          turnedInObjects: hasStatus(userState, 'TURNED_IN')
            ? totalProgress.turnedInObjects + 1
            : totalProgress.turnedInObjects,
          acceptedObjects: hasStatus(userState, 'ACCEPTED')
            ? totalProgress.acceptedObjects + 1
            : totalProgress.acceptedObjects,
          rejectedObjects: hasStatus(userState, 'REJECTED')
            ? totalProgress.rejectedObjects + 1
            : totalProgress.rejectedObjects,
          xpReceived: totalProgress.xpReceived + (userState?.xpReceived || 0),
          xpAvailable:
            totalProgress.xpAvailable +
            (userState?.xpAvailable || item.varieties?.()[0].points || 0),
        };

        return {
          ...absoluteProgress,
          xpPercentage:
            absoluteProgress.xpAvailable !== 0
              ? (absoluteProgress.xpReceived / absoluteProgress.xpAvailable) *
                100
              : 0,
          madeObjectsPercentage:
            absoluteProgress.totalObjects !== 0
              ? (absoluteProgress.madeObjects / absoluteProgress.totalObjects) *
                100
              : 0,
          percentage:
            absoluteProgress.xpAvailable !== 0
              ? (absoluteProgress.xpReceived / absoluteProgress.xpAvailable) *
                100
              : (absoluteProgress.acceptedObjects /
                  absoluteProgress.totalObjects) *
                100,
        } as TProgress;
      }
      default:
        return totalProgress;
    }
  }, progress);
}
