import { IResource } from '../Utils/Resource';
import { UUID } from '../Utils/UUID';

export interface EventCalendarEnrollmentGroup extends IResource {
  name: string;
  description: string;
  maxEnrollments: number;
}

export interface EventCalendarEnrollmentGroupCreateInput {
  name: string;
  description?: string;
  maxEnrollments: number;
  portal: UUID;
}

export interface EventCalendarEnrollmentGroupUpdateInput
  extends EventCalendarEnrollmentGroupCreateInput {}

export enum EventCalendarEnrollmentGroupIncludes {
  event_calendar_items,
  enrollments,
}

export enum EventCalendarEnrollmentGroupFilters {
  max_enrollments,
  portal,
  name,
}

export enum EventCalendarEnrollmentGroupSorts {
  name,
  max_enrollments,
}
