import React, { ComponentProps, PropsWithChildren } from 'react';
import classNames from 'classnames';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

const classes = classNames;

type LinkProps = ComponentProps<typeof NextLink>;
interface Props extends LinkProps {
  /** Whether to add the `active` class only on exact match */
  exact?: boolean;
  /** Set the activeness explicitly (defaults to current pathname */
  active?: boolean;
  /** Only set the link if a certain condition is truthy */
  if?: boolean;
}

/**
 * Link.
 *
 * Automatically adds 'active' class to link when route matches
 */
export const Link = ({
  href,
  exact,
  active,
  className,
  children,
  if: conditional = true,
  ...props
}: PropsWithChildren<Props>) => {
  const { asPath, pathname } = useRouter();
  const hrefString = typeof href === 'string' ? href : (href || {}).pathname;
  const isActive =
    !!hrefString &&
    (exact
      ? asPath === hrefString || pathname === hrefString
      : pathname.startsWith(hrefString) || asPath.startsWith(hrefString));
  return conditional ? (
    <NextLink
      href={href}
      className={classes(
        { active: (active === undefined && isActive) || active },
        className
      )}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </NextLink>
  ) : (
    <span
      className={classes(
        { active: (active === undefined && isActive) || active },
        className
      )}
    >
      {children}
    </span>
  );
};
