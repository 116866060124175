import { useCallback } from 'react';
import { Mentee } from '@brainstud/academy-api';
import { Status, Td, TdCheckbox } from '@brainstud/ui';
import { Timestamp } from '@brainstud/ui/Static/Timestamp';
import { useTranslator } from 'Providers/Translator';
import { DateFormatter } from 'Utils/DateFormatHelper';
import { StudentInfo } from '../Students/StudentInfo';

type TProps = {
  data: Mentee;
  isSelected: boolean;
  onToggleSelect: (menteeIds: string[]) => void;
};

/**
 * MenteesTableRow.
 *
 * Renders a row with student information and when expanded, displays the associated enrollments.
 */
export const MenteesTableRow = ({
  data: mentee,
  isSelected,
  onToggleSelect,
}: TProps) => {
  const [t] = useTranslator();
  const subscription = mentee.subscription?.();

  const handleToggleSelect = useCallback(() => {
    onToggleSelect([mentee.id]);
  }, [mentee.id, onToggleSelect]);

  return (
    <>
      <TdCheckbox
        id={mentee.id}
        checked={isSelected}
        onChange={handleToggleSelect}
      />
      <Td>
        <StudentInfo
          classUnit={mentee.classUnit}
          fullName={mentee.fullName}
          lastOnline={mentee.lastOnline}
        />
      </Td>
      <Td collapse>
        {subscription?.endDate ? (
          <Status scheme={subscription.isActive ? 'green' : 'grey'}>
            {t(
              `views.coach.mentees.subscription.${subscription.isActive ? 'active_till' : 'expired_on'}`,
              {
                date: DateFormatter.toAbbreviatedDate(
                  subscription.endDate,
                  "dd MMM ''yy"
                ),
              }
            )}
          </Status>
        ) : null}
      </Td>
      <Td collapse>
        <Timestamp timestamp={mentee.lastOnline} />
      </Td>
    </>
  );
};
