import React, { CSSProperties, FC, useEffect } from 'react';
import { useFormField } from '@brainstud/universal-components';
import classNames from 'classnames/bind';
import dynamic from 'next/dynamic';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import styles from './RichEditor.module.css';

const ReactQuill = dynamic(() => import('react-quill'), { ssr: false });

const cx = classNames.bind(styles);

type Props = {
  id: string;
  name: string;
  rules?: string | string[];
  defaultValue?: string;
  extensive?: boolean;
  label?: string;
  placeholder?: string;
  className?: string;
  style?: CSSProperties;
  onChange?: (value?: string) => void;
};

const RichEditor: FC<Props> = ({
  onChange,
  id,
  name,
  defaultValue,
  extensive,
  label,
  rules,
  className,
  style,
  placeholder,
}) => {
  const { value, setValue } = useFormField({
    id,
    name,
    defaultValue,
    rules,
  });

  useEffect(() => {
    onChange?.(value as string);
    // Suppressing ESLint here, as it would require useCallback on every onChange handler
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div
      className={cx(styles.base, className, {
        'is-required': rules?.includes('required'),
      })}
      style={style}
    >
      {label && <label htmlFor={id}>{label}</label>}
      <ReactQuill
        id={id}
        className={cx(styles.editor, 'text-editor')}
        theme={extensive ? 'snow' : 'bubble'}
        defaultValue={defaultValue}
        onChange={(newValue) => setValue(newValue)}
        placeholder={placeholder}
      />
    </div>
  );
};

export default RichEditor;
