import { UseMutationOptions } from 'react-query';
import { useCreate } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  LearningObjectCollection,
  TLearningObjectCollectionsImport,
} from '../Types';

interface ILearningObjectCollectionsImportProps {}

export function useLearningObjectCollectionsImport(
  { ...queryParameters }: ILearningObjectCollectionsImportProps = {},
  settings?: UseMutationOptions<
    DataDocument<LearningObjectCollection>,
    ErrorDocument,
    TLearningObjectCollectionsImport
  >
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.learningObjectCollections.import',
      baseUri: '/v1/learning_object_collections/import',
      invalidate: [
        'v1.learningObjectCollections',
        'learning_object_collection',
        'learning_object_collections',
      ],
      queryParameters,
    },
    context
  );
  return useCreate<LearningObjectCollection, TLearningObjectCollectionsImport>(
    request,
    settings
  );
}
