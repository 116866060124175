import { useEffect, useMemo, useState } from 'react';
import { sanitizer } from 'Utils/Sanitizer';

function truncateOnWord(str: string, limit: number) {
  const trimmable =
    '\u0009\u000A\u000B\u000C\u000D\u0020\u00A0\u1680\u180E\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u2028\u2029\u3000\uFEFF';
  const reg = new RegExp(`(?=[${trimmable}])`);
  const words = str.split(reg);
  let count = 0;
  return words
    .filter((word) => {
      count += word.length;
      return count <= limit;
    })
    .join('');
}

/**
 * Truncates a text on word based on the given limit and gives ability to toggle
 * the expanded state.
 */
export function useTextExpander(inputText: string = '', limit: number = 500) {
  const [isExpanded, setIsExpanded] = useState(true);
  const isExpandable = useMemo(
    () => sanitizer(inputText, 'plainText').trim().length > limit,
    [limit, inputText]
  );

  useEffect(() => {
    setIsExpanded(!isExpandable);
  }, [isExpandable]);

  const transformedText = useMemo(
    () =>
      isExpanded || !inputText
        ? inputText.trim()
        : truncateOnWord(inputText, limit),
    [isExpanded, inputText]
  );

  return useMemo(
    () => [transformedText, isExpanded, isExpandable, setIsExpanded] as const,
    [transformedText, isExpanded]
  );
}
