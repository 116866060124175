import React, { useCallback } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import {
  LearningObject as ILearningObject,
  useLearningObject,
} from '@brainstud/academy-api';
import { DuplicateModal } from '@brainstud/coach-panel/Modals';
import { ActionMenu, Button, ConfirmationModal, Status } from '@brainstud/ui';
import {
  DeleteForever,
  DragIndicator,
  Edit,
  Lock,
  Settings,
} from '@mui/icons-material';
import classNames from 'classnames/bind';
import { useLearningRouteEditorProvider } from 'Modules/course-editor/LearningRouteEditor';
import { useRouter } from 'next/router';
import {
  useEnvironmentProvider,
  useLearningRouteProvider,
  useModals,
} from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { AssignmentConditionsModal } from '../../../AssignmentConditionsModal';
import styles from './LearningObjectEditRow.module.css';

const cx = classNames.bind(styles);

interface LearningObjectProps {
  item: ILearningObject;
  index: number;
  disabled?: boolean;
}

/**
 * Renders a draggable or undraggable `LearningObject`
 */
export const LearningObjectEditRow = ({
  item,
  index,
  disabled,
}: LearningObjectProps) => {
  const [t] = useTranslator();
  const router = useRouter();
  const { courseId, collectionId } = router.query as {
    courseId: string;
    collectionId: string;
  };
  const { openModal, closeModal } = useModals();
  const [{ destroy }] = useLearningObject(
    {
      learningObject: item.id,
    },
    { enabled: false }
  );
  const handleDelete = useCallback(() => {
    openModal(ConfirmationModal, {
      question: t(
        'course-editor.learning_route_editor.confirmation.question_delete'
      ),
      yes: t('course-editor.learning_route_editor.confirmation.confirm_delete'),
      no: t('course-editor.learning_route_editor.confirmation.cancel'),
      handleConfirm: () => {
        destroy.mutateAsync();
        closeModal();
      },
    });
  }, [openModal, closeModal, t, destroy]);

  const { nodes } = useLearningRouteEditorProvider();
  const { learningRoute, isCustomCollection } = useLearningRouteProvider();
  const hasCondition = item?.conditions?.();

  const { isAdminEnvironment, environment } = useEnvironmentProvider();

  const handleClickEdit = useCallback(
    () =>
      isCustomCollection || isAdminEnvironment
        ? router.push(
            `/${environment}/courses/${courseId}/collections/${collectionId}/content/detail/${item.id}/edit`
          )
        : openModal(DuplicateModal, { collectionId, courseId }),
    [
      isCustomCollection,
      isAdminEnvironment,
      router,
      environment,
      courseId,
      collectionId,
      item.id,
      openModal,
    ]
  );

  const assignmentStatusColor = (baseType?: string) => {
    const statusColor = {
      assignment: 'purple',
      theory: 'blue',
    };
    if (baseType) {
      return statusColor[baseType];
    }
    return 'grey';
  };

  return destroy.isLoading ? null : (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {({ innerRef, draggableProps, dragHandleProps }, { isDragging }) => (
        <div
          className={cx(styles.base, { dragging: isDragging })}
          ref={innerRef}
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...draggableProps}
        >
          <div className={cx(styles.badge)}>
            <Status
              scheme={assignmentStatusColor(item.baseType)}
              className={cx(styles.status)}
            >
              {t(
                `course-editor.learning_route_editor.learning_object.${item.baseType}`
              )}
            </Status>
          </div>
          <Button
            className={cx(styles['course-title'])}
            quiet
            onClick={() => !disabled && handleClickEdit()}
          >
            {item.title}
          </Button>
          <Lock className={cx(!hasCondition && styles['hide-lock'])} />
          {!disabled && (
            <ActionMenu
              id={`action-menu-${index}`}
              icon={<Settings />}
              className={cx(styles['action-menu'])}
            >
              <button
                role="menuitem"
                type="button"
                onClick={() =>
                  openModal(AssignmentConditionsModal, {
                    baseObject: item,
                    nodes,
                    learningRouteId: learningRoute?.id,
                  })
                }
              >
                <Lock />
                <span>
                  {t(
                    `course-editor.learning_route_editor.action_menu.assignment_conditions.${hasCondition ? 'edit' : 'add'}`
                  )}
                </span>
              </button>
              <button role="menuitem" type="button" onClick={handleClickEdit}>
                <Edit />
                <span>
                  {t(
                    'course-editor.learning_route_editor.learning_object.edit'
                  )}
                </span>
              </button>
              {(isCustomCollection || isAdminEnvironment) && (
                <button role="menuitem" type="button" onClick={handleDelete}>
                  <DeleteForever />
                  <span>
                    {t(
                      'course-editor.learning_route_editor.learning_object.delete'
                    )}
                  </span>
                </button>
              )}
            </ActionMenu>
          )}
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <span className={cx(styles.dragHandle)} {...dragHandleProps}>
            <DragIndicator />
          </span>
        </div>
      )}
    </Draggable>
  );
};
