import React, { useCallback, useState } from 'react';
import { useSharePortfolio } from '@brainstud/academy-api';
import { Button, ContentModal, Input } from '@brainstud/ui';
import { Form } from '@brainstud/universal-components';
import { AddCircleRounded, ArrowForward } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import styles from './PortfolioShareModal.module.css';

const cx = classNames.bind(styles);

type Props = {
  id?: string;
  closeModal: () => void;
};

type FormInput = {
  recipients: string[];
};

export const PortfolioShareModal = ({ id, closeModal }: Props) => {
  const share = useSharePortfolio({ answer: id });
  const [inputCount, setInputCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [t] = useTranslator();

  const handleSubmit = useCallback(
    (values: Partial<FormInput>) => {
      setLoading(true);
      return share.mutateAsync(
        { email_addresses: values?.recipients || [] },
        {
          onSettled: () => {
            setLoading(false);
          },
          onSuccess: () => {
            closeModal();
          },
        }
      );
    },
    [closeModal, share]
  );

  const addRecipient = () => {
    setInputCount((count) => count + 1);
  };

  return (
    <ContentModal
      className={cx(styles.base)}
      onClickOutside
      onClose={closeModal}
    >
      <h3>{t('menu.share_result')}</h3>
      <p>
        {id
          ? t('views.portfolio.share_result_item')
          : t('views.portfolio.share_all')}
      </p>

      <Form<FormInput>
        onSubmit={handleSubmit}
        className={cx(styles.base)}
        language="nl"
      >
        {[...Array(inputCount)].map((_, index) => (
          <Input
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            type="email"
            className={cx('modal-email')}
            id={`recipients_${index}`}
            name="recipients[]"
            placeholder={t('views.portfolio.email_recipient')}
          />
        ))}

        <div className={cx('add-input')}>
          <Button type="button" link onClick={addRecipient}>
            {t('views.portfolio.add_email_recipient')}
            <AddCircleRounded fontSize="large" />
          </Button>
        </div>

        <div className={cx('buttons')}>
          <Button type="button" link onClick={closeModal}>
            {t('close')}
          </Button>
          <Button loading={loading}>
            {t('views.portfolio.share_assignment')}
            <ArrowForward />
          </Button>
        </div>
      </Form>
    </ContentModal>
  );
};
