import React, { useEffect, useState } from 'react';
import { ICalloutBlock } from '@brainstud/course-blocks';
import { Dropdown, Input } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { RichTextEditor } from 'Components';
import { useTranslator } from 'Providers/Translator';
import { TToolChangeHandler } from '../../../ContentEditor/Types';
import { toName } from '../../../Support/toName';
import styles from './CalloutTool.module.css';

const cx = classNames.bind(styles);

export type CalloutProps = {
  data?: ICalloutBlock;
  onChange: TToolChangeHandler<ICalloutBlock>;
};

export const CalloutForm = ({ data, onChange }: CalloutProps) => {
  const [t] = useTranslator();
  const [title, setTitle] = useState(data?.title);
  const [variety, setVariety] = useState(data?.variety);
  const [content, setContent] = useState(data?.content);
  useEffect(() => {
    onChange({
      variety,
      title,
      content: content || '',
    });
  }, [variety, content, title]);
  return (
    <>
      <div className={styles.heading}>
        <Dropdown
          label={t('course-editor.content_editor.blocks.callout.variety.label')}
          placeholder="---"
          className={cx(styles.dropdown)}
          onChange={(value) => value && setVariety(value)}
          value={data?.variety}
        >
          <Dropdown.Option value="info">
            <svg
              className={cx(styles.icon, styles.isInfo)}
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
            </svg>
          </Dropdown.Option>
          <Dropdown.Option value="attention">
            <svg
              className={cx(styles.icon, styles.isWarn)}
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
            </svg>
          </Dropdown.Option>
          <Dropdown.Option value="error">
            <svg
              className={cx(styles.icon, styles.isError)}
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
            </svg>
          </Dropdown.Option>
        </Dropdown>
        <Input
          className={styles.title}
          label={t('course-editor.content_editor.blocks.callout.title')}
          onChange={(e) => setTitle(e.target.value)}
          value={title}
        />
      </div>
      <RichTextEditor
        id={toName(data?.id)}
        label={t('course-editor.content_editor.blocks.callout.content')}
        onChange={(value) => setContent(value)}
        defaultValue={data?.content}
      />
    </>
  );
};
