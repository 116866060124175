import { TReturn, useStorageEngine } from './useStorageEngine';

/**
 * useSessionStorage.
 *
 * Stores value in the session storage of the browser. Value is deleted when the browser session stops.
 * __Note__: During SSR the value is not available!
 */
export function useSessionStorage<T>(key: string, defaultValue: T): TReturn<T>;
export function useSessionStorage<T>(key?: string): TReturn<undefined | T>;
export function useSessionStorage<T = undefined>(
  key?: string,
  defaultValue?: T
): TReturn<undefined | T> {
  return useStorageEngine<undefined | T>('sessionStorage', key, defaultValue);
}
