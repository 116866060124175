import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMe } from '@brainstud/academy-api';
import {
  useOnClickOutside,
  useWindowSize,
} from '@brainstud/universal-components';
import {
  Call,
  Close,
  Dashboard,
  Edit,
  EmojiPeople,
  HeadsetMic,
  ImportContacts,
  InsertDriveFile,
  Mail,
  Menu,
  PermMedia,
  PlaylistAddCheck,
  Settings,
  ShoppingCart,
  Storefront,
  SupervisorAccount,
} from '@mui/icons-material';
import classNames from 'classnames/bind';
import {
  ADMIN_ENV_ROLES,
  ADMIN_ROLES,
  AUTHOR_ROLES,
  COACH_ROLES,
  MANAGER_ROLES,
  STUDENT_ROLES,
  SUPPORT_ROLES,
  UNLIMITED_ROLES,
} from 'Config/roles';
import { usePortalFeatureCheck } from 'Hooks';
import { useHasAnyRole } from 'Hooks/Roles';
import { useRouter } from 'next/router';
import { useEnvironmentProvider } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { Link, UserBox } from '../../Components';
import { PortalSwitch } from './PortalSwitch/PortalSwitch';
import { EnvironmentSwitch } from './EnvironmentSwitch';
import { NavigationGroup } from './NavigationGroup';
import { NavigationLink } from './NavigationLink';
import styles from './Navigation.module.css';

const classes = classNames.bind(styles);

type Props = {
  style?: CSSProperties;
  className?: string;
};

/**
 * Navigation.
 *
 * Component that renders the main navigation and a toggle button to hide/show it.
 */
export const Navigation = ({ className, style }: Props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [t] = useTranslator();
  const { asPath } = useRouter();
  const [me] = useMe();

  const navigationRef = useRef(null);
  useOnClickOutside(navigationRef, () => setIsDrawerOpen(false));

  const { width: windowWidth } = useWindowSize();
  const isSmallWindow = windowWidth && windowWidth <= 640;

  const {
    isAdminEnvironment,
    isCoachEnvironment,
    isStudentEnvironment,
    isGuestEnvironment,
  } = useEnvironmentProvider();

  // This role is used until the academy backend is totally migrated.
  const isUnlimitedRole = useHasAnyRole(UNLIMITED_ROLES);

  const isAuthor = useHasAnyRole(AUTHOR_ROLES);
  const isStudent = useHasAnyRole(STUDENT_ROLES);
  const isCoach = useHasAnyRole(COACH_ROLES);

  const isSupport = useHasAnyRole(SUPPORT_ROLES);
  const isAdmin = useHasAnyRole(ADMIN_ROLES);
  const isManager = useHasAnyRole(MANAGER_ROLES);

  const hasAccessToMultipleEnvironments = useHasAnyRole([
    ...ADMIN_ENV_ROLES,
    ...COACH_ROLES,
  ]);
  const handleEscButton = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsDrawerOpen(false);
    }
  }, []);

  useEffect(() => {
    if (isSmallWindow && isDrawerOpen) {
      document.body.classList.add('disable-scroll');
    }
    return () => {
      document.body.classList.remove('disable-scroll');
    };
  }, [isSmallWindow, isDrawerOpen]);

  useEffect(() => {
    if (isDrawerOpen) {
      window.addEventListener('keyup', handleEscButton);
      return () => window.removeEventListener('keyup', handleEscButton);
    }
  }, [isDrawerOpen, handleEscButton]);

  useEffect(() => {
    if (isSmallWindow) {
      setIsDrawerOpen(!asPath);
    }
  }, [asPath, isSmallWindow]);

  const primaryCoach = useMemo(() => me?.primaryCoach?.(), [me]);

  const isPortfolioEnabled = usePortalFeatureCheck('portfolio_feature');

  return (
    <div
      className={classes(styles.base, { 'is-open': isDrawerOpen }, className)}
      style={style}
      ref={navigationRef}
    >
      <button
        type="button"
        data-cy="menu-button"
        title={t(
          `layouts.navigation.${!isDrawerOpen ? 'open_menu' : 'close_menu'}`
        )}
        className={classes(styles.toggle, 'no-outline')}
        onClick={() => setIsDrawerOpen((prevOpen) => !prevOpen)}
      >
        {!isDrawerOpen ? (
          <Menu fontSize="large" className="onboarding-menu-1" />
        ) : (
          <Close fontSize="large" />
        )}
      </button>

      <div className={classes(styles.navigation)}>
        <div className={styles.top}>
          {isUnlimitedRole && <PortalSwitch />}
          {hasAccessToMultipleEnvironments && <EnvironmentSwitch />}

          <div className={styles.links}>
            {isAdminEnvironment && (
              <>
                <NavigationLink href="/admin/dashboard">
                  <Dashboard fontSize="large" className={styles['menu-icon']} />
                  <span>{t('layouts.navigation.items.dashboard')}</span>
                </NavigationLink>

                {(isAuthor || isManager) && (
                  <NavigationGroup
                    pathname="/admin/courses"
                    title={t('layouts.navigation.items.courses')}
                    icon={
                      <ImportContacts
                        fontSize="large"
                        className={styles['menu-icon']}
                      />
                    }
                  >
                    {isAuthor && (
                      <>
                        <NavigationLink href="/admin/courses" indent={1}>
                          <span>
                            {t('layouts.navigation.items.all_courses')}
                          </span>
                        </NavigationLink>

                        <NavigationLink href="/admin/courses/create" indent={1}>
                          <span>
                            {t('layouts.navigation.items.add_course')}
                          </span>
                        </NavigationLink>
                      </>
                    )}
                    {(isAdmin || isManager) && (
                      <NavigationLink href="/admin/catalogs" indent={1}>
                        <span>
                          {t('layouts.navigation.items.view_academy')}
                        </span>
                      </NavigationLink>
                    )}
                  </NavigationGroup>
                )}

                {isUnlimitedRole && (
                  <NavigationLink href="/admin/certifications">
                    <InsertDriveFile
                      fontSize="large"
                      className={styles['menu-icon']}
                    />
                    <span>{t('layouts.navigation.items.certifications')}</span>
                  </NavigationLink>
                )}
                {(isAdmin || isSupport || isManager) && (
                  <NavigationGroup
                    pathname="/admin/sales"
                    title={t('layouts.navigation.items.sales')}
                    icon={
                      <ShoppingCart
                        fontSize="large"
                        className={styles['menu-icon']}
                      />
                    }
                  >
                    {isUnlimitedRole && isAdmin && (
                      <NavigationLink href="/admin/sales/orders" indent={1}>
                        <span>{t('layouts.navigation.items.all_orders')}</span>
                      </NavigationLink>
                    )}
                    {isUnlimitedRole && (isSupport || isAdmin) && (
                      <NavigationLink
                        href="/admin/sales/orders/create"
                        indent={1}
                      >
                        <span>{t('layouts.navigation.items.make_order')}</span>
                      </NavigationLink>
                    )}
                    <NavigationLink
                      exact={false}
                      href="/admin/sales/catalogs"
                      indent={1}
                    >
                      <span>{t('layouts.navigation.items.product')}</span>
                    </NavigationLink>
                  </NavigationGroup>
                )}
                {isUnlimitedRole && isAdmin && (
                  <NavigationLink href="/academy/marketing">
                    <Storefront
                      fontSize="large"
                      className={styles['menu-icon']}
                    />
                    <span>{t('layouts.navigation.items.marketing')}</span>
                  </NavigationLink>
                )}
                <NavigationGroup
                  pathname="/admin/support"
                  title={t('layouts.navigation.items.support')}
                  icon={
                    <HeadsetMic
                      fontSize="large"
                      className={styles['menu-icon']}
                    />
                  }
                >
                  {isUnlimitedRole && isSupport && (
                    <NavigationLink href="/academy/accounts_support" indent={1}>
                      <span>{t('layouts.navigation.items.users')}</span>
                    </NavigationLink>
                  )}

                  {(isAdmin || isSupport || isManager) && (
                    <NavigationLink
                      href="/admin/support/announcements"
                      indent={1}
                    >
                      <span>{t('layouts.navigation.items.announcements')}</span>
                    </NavigationLink>
                  )}
                </NavigationGroup>

                {isAdmin && (
                  <NavigationGroup
                    pathname="/admin/settings"
                    title={t('layouts.navigation.items.academy_management')}
                    icon={
                      <Settings
                        fontSize="large"
                        className={styles['menu-icon']}
                      />
                    }
                  >
                    {isUnlimitedRole && (
                      <>
                        <NavigationLink
                          href="/admin/settings/agreements"
                          indent={1}
                        >
                          <span>
                            {t('layouts.navigation.items.agreements')}
                          </span>
                        </NavigationLink>
                        <NavigationLink
                          href="/admin/settings/languages"
                          indent={1}
                        >
                          <span>{t('layouts.navigation.items.languages')}</span>
                        </NavigationLink>
                        <NavigationLink
                          href="/admin/settings/order-automations"
                          indent={1}
                        >
                          <span>
                            {t('layouts.navigation.items.order_automations')}
                          </span>
                        </NavigationLink>
                        <NavigationLink
                          href="/admin/settings/integrations"
                          indent={1}
                        >
                          <span>
                            {t('layouts.navigation.items.integrations')}
                          </span>
                        </NavigationLink>
                      </>
                    )}
                  </NavigationGroup>
                )}
              </>
            )}

            {(isCoachEnvironment || (isGuestEnvironment && isCoach)) && (
              <>
                <NavigationLink href="/coach/dashboard">
                  <Dashboard fontSize="large" className={styles['menu-icon']} />
                  <span>{t('layouts.navigation.items.dashboard')}</span>
                </NavigationLink>

                <NavigationLink href="/coach/courses">
                  <ImportContacts
                    fontSize="large"
                    className={styles['menu-icon']}
                  />
                  <span>{t('layouts.navigation.items.courses')}</span>
                </NavigationLink>

                <NavigationLink href="/coach/students">
                  <EmojiPeople
                    fontSize="large"
                    className={styles['menu-icon']}
                  />
                  <span>{t('layouts.navigation.items.participants')}</span>
                </NavigationLink>

                <NavigationLink href="/coach/review">
                  <PlaylistAddCheck
                    fontSize="large"
                    className={styles['menu-icon']}
                  />
                  <span>{t('layouts.navigation.items.review')}</span>
                </NavigationLink>

                <NavigationLink href="/coach/mentees">
                  <SupervisorAccount
                    fontSize="large"
                    className={styles['menu-icon']}
                  />
                  <span>{t('layouts.navigation.items.mentees')}</span>
                </NavigationLink>
              </>
            )}

            {(isStudentEnvironment ||
              (isGuestEnvironment && isStudent && !isCoach)) && (
              <>
                <NavigationLink href="/catalog">
                  <ImportContacts
                    fontSize="large"
                    className={styles['menu-icon']}
                  />
                  <span>{t('layouts.navigation.items.catalog')}</span>
                </NavigationLink>

                {isPortfolioEnabled && (
                  <NavigationLink href="/portfolio">
                    <PermMedia
                      fontSize="large"
                      className={styles['menu-icon']}
                    />
                    <span>{t('layouts.navigation.items.portfolio')}</span>
                  </NavigationLink>
                )}
              </>
            )}
          </div>
        </div>

        {primaryCoach && isStudent && (
          <div className={styles['primary-coach']}>
            <p>
              <strong>{t('layouts.navigation.primary_coach.title')}</strong>
              <br />
              {t('layouts.navigation.primary_coach.body')}
            </p>
            <Link
              className={classes(styles['coach-name'])}
              href="/account/primary-coach"
            >
              <strong>{primaryCoach.fullName}</strong>
              <Edit />
            </Link>

            {primaryCoach.emailAddress && (
              <Link
                className={classes(styles.contact)}
                href={`mailto:${primaryCoach.emailAddress}`}
              >
                <Mail />
                <span>{primaryCoach.emailAddress}</span>
              </Link>
            )}
            {primaryCoach.phoneNumber && (
              <Link
                className={classes(styles.contact)}
                href={`tel:${primaryCoach.phoneNumber}`}
              >
                <Call />
                <span>{primaryCoach.phoneNumber}</span>
              </Link>
            )}
          </div>
        )}

        {/* Footer */}
        <UserBox className={styles['user-box']} />
      </div>
    </div>
  );
};
