/**
 * isUUID.
 *
 * Check if a value is a universal unique identifier.
 */
export function isUUID(uuid?: unknown): uuid is string {
  return (
    typeof uuid === 'string' &&
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi.test(
      uuid
    )
  );
}
