import { v4 } from 'uuid';

/**
 * shortId.
 *
 * This method takes everything before the first dash (-), making it
 * perfect to shorten an UUID. When no input is given, it will generate a short ID for you.
 */
export function shortId(id?: string, fallback = v4()): string {
  return id
    ? id.substr(0, id.indexOf('-') > 0 ? id.indexOf('-') : id.length)
    : shortId(fallback);
}
