import React, { ReactNode, useMemo, useState } from 'react';
import { useMe } from '@brainstud/academy-api';
import Head from 'next/head';
import { useTranslator } from 'Providers/Translator';
import { useThemeProvider } from '../ThemeProvider';
import { HeadContext } from './HeadContext';

type Props = {
  children: ReactNode;
};

/**
 * HeadProvider.
 *
 * Provides easy access to base <head> tags. Especially title and meta tags.
 */
export const HeadProvider = ({ children }: Props) => {
  const [title, setTitle] = useState<string>();

  const [t] = useTranslator();
  const context = useMemo(
    () => ({
      title,
      setTitle,
    }),
    [title]
  );

  const [me] = useMe();
  const portal = useMemo(() => me?.portal?.(), [me]);
  const { colors } = useThemeProvider();
  const themeColor =
    'primaryColor' in (colors || {})
      ? `rgb(${colors?.primaryColor})`
      : undefined;

  return (
    <HeadContext.Provider value={context}>
      <Head>
        <title>
          {[t(title || '', {}, ''), portal?.name].filter(Boolean).join(' | ')}
        </title>

        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="author" content="Brainstud" />
        <meta name="robots" content="noindex,nofollow" />

        <link sizes="57x57" href="/Icons/apple-touch-icon-57x57.png" />
        <link sizes="114x114" href="/Icons/apple-touch-icon-114x114.png" />
        <link sizes="72x72" href="/Icons/apple-touch-icon-72x72.png" />
        <link sizes="144x144" href="/Icons/apple-touch-icon-144x144.png" />
        <link sizes="60x60" href="/Icons/apple-touch-icon-60x60.png" />
        <link sizes="120x120" href="/Icons/apple-touch-icon-120x120.png" />
        <link sizes="76x76" href="/Icons/apple-touch-icon-76x76.png" />
        <link sizes="152x152" href="/Icons/apple-touch-icon-152x152.png" />
        <link
          rel="icon"
          type="image/png"
          href="/Icons/favicon-196x196.png"
          sizes="196x196"
        />
        <link
          rel="icon"
          type="image/png"
          href="/Icons/favicon-96x96.png"
          sizes="96x96"
        />
        <link
          rel="icon"
          type="image/png"
          href="/Icons/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/Icons/favicon-16x16.png"
          sizes="16x16"
        />
        <link
          rel="icon"
          type="image/png"
          href="/Icons/favicon-128.png"
          sizes="128x128"
        />
        {portal && (
          <>
            <meta name="application-name" content={portal.name} />
            <meta
              name="msapplication-TileColor"
              content={themeColor || 'rgb(113,72,229)'}
            />
            <meta
              name="theme-color"
              content={themeColor || 'rgb(113,72,229)'}
            />
          </>
        )}
        <meta
          name="msapplication-TileImage"
          content="/Icons/mstile-144x144.png"
        />
        <meta
          name="msapplication-square70x70logo"
          content="/Icons/mstile-70x70.png"
        />
        <meta
          name="msapplication-square150x150logo"
          content="/Icons/mstile-150x150.png"
        />
        <meta
          name="msapplication-wide310x150logo"
          content="/Icons/mstile-310x150.png"
        />
        <meta
          name="msapplication-square310x310logo"
          content="/Icons/mstile-310x310.png"
        />
      </Head>
      {children}
    </HeadContext.Provider>
  );
};
