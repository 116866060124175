import { useCallback, useEffect, useState } from 'react';

type TDimensions = {
  width: number;
  height: number;
  scrollWidth: number;
  scrollHeight: number;
  top: number;
  left: number;
  x: number;
  y: number;
  right: number;
  bottom: number;
};

function getDimensionObject(node: Element): TDimensions {
  const rect = node.getBoundingClientRect();

  return {
    width: rect.width,
    height: rect.height,
    scrollWidth: node.scrollWidth,
    scrollHeight: node.scrollHeight,
    top: 'x' in rect ? rect.x : (rect as { top: number }).top,
    left: 'y' in rect ? rect.y : (rect as { left: number }).left,
    x: 'x' in rect ? rect.x : (rect as { left: number }).left,
    y: 'y' in rect ? rect.y : (rect as { top: number }).top,
    right: rect.right,
    bottom: rect.bottom,
  };
}

type TOptions = {
  /**
   * When true, it will add event listeners to window on resize or scroll to update
   * the size accordingly.
   * @default true
   */
  live: boolean;
};

/**
 * Returns the measurements of the referenced node
 */
export function useDimensions<RefElement extends HTMLElement = any>(
  { live }: TOptions = { live: true }
) {
  const [dimensions, setDimensions] = useState<Partial<TDimensions>>({});
  const [node, setNode] = useState<RefElement>();

  /**
   * Method to add as ref attribute to a HTMLElement
   */
  const ref = useCallback((refElement: RefElement) => {
    setNode(refElement);
  }, []);

  // Create a ResizeObserver on mount when 'window' is available
  const [resizeObserver, setResizeObserver] = useState<ResizeObserver>();
  useEffect(() => {
    if ('ResizeObserver' in window) {
      setResizeObserver(
        new ResizeObserver((entries) => {
          setDimensions(getDimensionObject(entries[0].target));
        })
      );
    }
  }, []);

  useEffect(() => {
    if (node) {
      const measure = () =>
        window.requestAnimationFrame(() =>
          setDimensions(getDimensionObject(node))
        );
      measure();

      if (live) {
        if (resizeObserver) {
          resizeObserver.observe(node);
          return () => {
            resizeObserver.unobserve(node);
          };
        }
        if (typeof window.ResizeObserver === 'undefined') {
          window.addEventListener('resize', measure);
          window.addEventListener('scroll', measure);

          return () => {
            window.removeEventListener('resize', measure);
            window.removeEventListener('scroll', measure);
          };
        }
      }
    }
  }, [node, live, resizeObserver]);

  return [ref, dimensions, node] as const;
}
