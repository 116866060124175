import React, { useEffect, useMemo, useState } from 'react';
import {
  Account,
  AnswerStatus,
  ExternalAssessment,
  useAnswer,
} from '@brainstud/academy-api';
import { useRouter } from 'next/router';
import { useLearningObjectProvider } from 'Providers';
import { useAnswerProvider } from 'Providers/AnswerProvider';
import { useCurrentLearningObjectAnswers } from '../../../../Hooks';
import { useIsObjectViewDataLoading } from '../../../../Views/CollectionViews/ObjectViews/useIsObjectViewDataLoading';
import { useReviewState } from '../../Views/Review/useReviewState';
import AnswerReviewContext, {
  IAnswerReviewContext,
} from './AnswerReviewContext';
import { useAnswerReviewReducer } from './useAnswerReviewReducer';
import { useReviewSetNavigation } from './useReviewSetNavigation';

type Props = {
  enrollmentId?: string;
  account?: Partial<Account>;
  externalRating?: ExternalAssessment;
  children: React.ReactNode;
};

const REVIEWABLE_ANSWER_STATES: AnswerStatus[] = [
  'TURNED_IN',
  'ACCEPTED',
  'REJECTED',
];

/**
 * AnswerReviewProvider.
 *
 * Reviewing answers can happen from multiple sides. Primarily, from the review tab on the coach side,
 * but also from the student portfolio, and a sample set. This Provider ensures that the state is read from
 * the session storage and provides all information about the set to review.
 */
export const AnswerReviewProvider = ({
  enrollmentId: providedEnrollmentId,
  account: providedAccount,
  externalRating,
  children,
}: Props) => {
  const [storedState] = useReviewState();
  const {
    learningObject,
    isLoading: isLoadingLearningObject,
    shared,
  } = useLearningObjectProvider();

  const { query, pathname } = useRouter();
  const {
    enrollmentId: routeEnrollmentId,
    learningObjectId: routeLearningObjectId,
    studentId,
  } = query as {
    enrollmentId?: string;
    learningObjectId?: string;
    studentId?: string;
  };
  const { currentAnswer, setSelectedAnswerId } = useAnswerProvider();
  const enrollmentId = providedEnrollmentId || routeEnrollmentId;
  const learningObjectId = learningObject?.id || routeLearningObjectId;

  const isStudentReview = pathname.startsWith(
    '/coach/students/[studentId]/review'
  );
  const baseUri = isStudentReview
    ? `/coach/students/${studentId}/review`
    : '/coach/review';

  const [state, dispatch] = useAnswerReviewReducer(
    storedState || {
      ...(enrollmentId && learningObjectId
        ? { reviewSet: [{ enrollmentId, learningObjectId }] }
        : {}),
    }
  );
  const { reviewSet, origin, index } = state;

  // Make sure to save the current state of things, so they are properly loaded on page reload
  useEffect(() => {
    if (storedState) {
      dispatch({
        type: 'LOAD_SET',
        payload: {
          origin: storedState.origin,
          set: storedState.reviewSet,
        },
      });
    }
  }, [dispatch, storedState]);

  const [collapsed, setCollapsed] = useState(false);

  const variety = useMemo(
    () =>
      currentAnswer?.learningObjectVariety?.() || learningObject?.variety?.(),
    [learningObject, currentAnswer]
  );

  // Get currently selected answer
  const { answers } = useCurrentLearningObjectAnswers(
    learningObject,
    REVIEWABLE_ANSWER_STATES
  );

  const { isFirst, isLast, currentItem, nextItem, previousItem } =
    useReviewSetNavigation(
      dispatch,
      index,
      reviewSet,
      enrollmentId,
      learningObjectId
    );

  // When answer is loaded, make sure to set the 'seen' status to true
  const [{ update: updateAnswer }] = useAnswer(
    { answer: currentAnswer?.id },
    { enabled: false }
  );

  /**
   * As we're also using this provider to facilitate peer review,
   * we should check if this Answer is shared or not
   */
  useEffect(() => {
    if (
      !shared &&
      !!currentAnswer &&
      !currentAnswer.coachHasViewed &&
      !updateAnswer.isLoading
    ) {
      updateAnswer.mutate({
        seen: true,
      });
    }
  }, [updateAnswer, currentAnswer, shared]);

  const isLoading = useIsObjectViewDataLoading() || isLoadingLearningObject;

  const reviewAnswer = useMemo(
    () =>
      currentAnswer?.status &&
      REVIEWABLE_ANSWER_STATES.includes(currentAnswer?.status)
        ? currentAnswer
        : answers[0],
    [answers, currentAnswer]
  );

  const context: IAnswerReviewContext = useMemo(
    () => ({
      isLoading,
      index,
      reviewSet,
      nextItem,
      previousItem,
      currentItem,
      isFirst,
      isLast,
      origin,
      answer: reviewAnswer,
      answers,
      variety,
      learningObject,
      setAnswerId: setSelectedAnswerId,
      answerId: reviewAnswer?.id,
      setCollapsed,
      collapsed,
      enrollmentId,
      account: providedAccount,
      externalRating,
      baseUri,
    }),
    [
      isLoading,
      index,
      reviewSet,
      nextItem,
      previousItem,
      currentItem,
      isFirst,
      isLast,
      origin,
      reviewAnswer,
      setSelectedAnswerId,
      answers,
      variety,
      learningObject,
      enrollmentId,
      providedAccount,
      externalRating,
      collapsed,
      baseUri,
    ]
  );

  return (
    <AnswerReviewContext.Provider value={context}>
      {children}
    </AnswerReviewContext.Provider>
  );
};
