import { UseQueryOptions } from 'react-query';
import useIndexCR from '../Factories/useIndexCR';
import useSingleCRUD from '../Factories/useSingleCRUD';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  Course,
  CourseCreateInput,
  CourseFilters,
  CourseIncludes,
  CourseMeta,
  CourseSorts,
  CourseUpdateInput,
  HookParams,
  UUID,
} from '../Types';
import { TJsonSpecResponse } from '../Types/Utils/JsonApiResource';
import { constructUrl } from '../Utils/constructUrl';
import { transformFetchResponse } from '../Utils/transformFetchResponse';

interface ICourseProps
  extends HookParams<
    typeof CourseIncludes,
    CourseFilters,
    typeof CourseSorts,
    CourseMeta
  > {
  course?: UUID;
}

/**
 * getCourses.
 *
 * Fetch courses with Next fetch method.
 */
export async function getCourses<T extends boolean = false>(
  queryParameters: ICourseProps = {},
  { asJson }: { asJson?: T } = {}
): Promise<T extends true ? TJsonSpecResponse : DataDocument<Course[]>> {
  return fetch(constructUrl('/api/v1/courses', queryParameters)).then(
    (response) => transformFetchResponse(response, asJson)
  );
}

export function useCourse(
  { course, ...queryParameters }: ICourseProps = {},
  settings?: UseQueryOptions<DataDocument<Course>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { course: defaultCourse },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(course || defaultCourse),
      baseName: 'v1.courses',
      baseUri: '/v1/courses',
      invalidate: [
        'v1.portals.courses',
        'course',
        'courses',
        'v1.courses.statistics',
      ],
      uri: `/${course || defaultCourse}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<Course, CourseCreateInput, CourseUpdateInput>(
    request,
    settings
  );
}

export function useCourses(
  { ...queryParameters }: Omit<ICourseProps, 'course'> = {},
  settings?: UseQueryOptions<DataDocument<Course[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.courses',
      baseUri: '/v1/courses',
      invalidate: ['v1.portals.courses', 'course', 'courses'],
      queryParameters,
    },
    context
  );
  return useIndexCR<Course, CourseCreateInput>(request, settings);
}
