import React, { PropsWithChildren } from 'react';
import { Panel } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { ReactComponent as LogoSVG } from '../../Modules/account-panel/Assets/images/logo.svg';
import { useHeadProvider, useThemeProvider } from '../../Providers';
import styles from './AuthLayout.module.css';

const classes = classNames.bind(styles);

type Props = {
  title?: string;
};

/**
 * AuthLayout.
 *
 * Shows the basic layout of an account-panel page.
 */
export const AuthLayout = ({ title, children }: PropsWithChildren<Props>) => {
  useHeadProvider(title || 'titles.auth.main');
  const { assets } = useThemeProvider();
  const logo = assets?.logo;

  return (
    <div className={classes(styles.base)}>
      <Panel className={classes(styles.panel)}>
        <header>
          {logo ? (
            <img className={styles.logo} src={logo} alt="" />
          ) : (
            <LogoSVG className={styles.logo} />
          )}
        </header>
        <main className={classes(styles.main)}>{children}</main>
      </Panel>
    </div>
  );
};
