import { StatusColors, TColorScheme } from './StatusColors';

type TStatusKeys = keyof typeof StatusColors;
type TStatusColors = (typeof StatusColors)[TStatusKeys];

/**
 * getColorForStatus.
 *
 * Returns an appropriate color name e.g. 'info', 'yellow', 'grey', 'success' based on a status enum.
 * Defaults to grey when unknown.
 */
export function getColorNameForStatus(
  status: string,
  fallback?: TStatusKeys,
  scheme: TColorScheme = StatusColors
): TStatusColors {
  if (status in scheme) {
    return scheme[status];
  }
  return fallback ? scheme[fallback] : 'grey';
}
