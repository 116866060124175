import React from 'react';
import classNames from 'classnames/bind';
import styles from './EditorButton.module.css';

const cx = classNames.bind(styles);

type ToolbarItemProps = {
  onClick?: () => void;
  active?: boolean;

  disabled?: boolean;
  children: React.ReactNode | string;
  className?: string;
};

export const EditorButton = ({
  onClick,
  active = false,
  disabled,
  children,
  className,
}: ToolbarItemProps) => (
  <button
    type="button"
    tabIndex={-1}
    onClick={onClick}
    className={cx(
      styles.base,
      'richtexteditor-button',
      { 'is-active': active, disabled },
      className
    )}
  >
    {children}
  </button>
);
