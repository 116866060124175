import React, { useState } from 'react';
import {
  useLearningObjectCollection,
  usePortalCourses,
} from '@brainstud/academy-api';
import { Button, Search } from '@brainstud/ui';
import { Edit, Launch } from '@mui/icons-material';
import { ReactComponent as LearningRouteIcon } from 'Assets/Icons/learning_route.svg';
import classNames from 'classnames/bind';
import { CollectionsTable, Container, Link, Loading } from 'Components';
import { useRouter } from 'next/router';
import { useLearningRouteProvider, useModals } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { ActionMenu } from '../../../Components';
import { DuplicateLearningRouteModal } from '../../../Modals';
import { withContentErrorBoundary } from '../../Errors/withContentErrorBoundary';
import styles from './CoursesAssignmentsView.module.css';

const cx = classNames.bind(styles);

const CourseAssignmentsView = withContentErrorBoundary(() => {
  const [t] = useTranslator();
  const { openModal } = useModals();
  const router = useRouter();
  const { nodes, isLoading, learningRoute, isCustomCollection, isCustomRoute } =
    useLearningRouteProvider();

  const { courseId, collectionId } = router.query as {
    courseId: string;
    collectionId: string;
  };
  const [search, setSearch] = useState('');

  const [{ data: courses }] = usePortalCourses();
  const isEnrolled = courses.some(
    (item) => item.id === courseId && !!item.enrollment
  );
  const [{ data: learningObjectCollection }] = useLearningObjectCollection({
    learningObjectCollection: collectionId,
    include: [
      'learning_routes',
      'learning_routes.learning_route_nodes',
      'learning_objects',
      'learning_subjects',
    ],
  });
  const viewUrl = learningObjectCollection?.links?.view?.href;
  const editUrl = `/coach/courses/${courseId}/collections/${collectionId}/routes/${learningRoute?.id}/edit`;

  if (isLoading) return <Loading />;

  return (
    <Container className={cx(styles.base)}>
      <div className={cx(styles['action-bar'])}>
        <Search
          onChange={(e) => setSearch(e.target.value)}
          placeholder={t('search')}
        />
        {isEnrolled && (
          <div className={cx(styles.right)}>
            {viewUrl && (
              <Button
                quiet
                href={`/courses/${courseId}?returnTo=${router.asPath}`}
              >
                <span>{t('views.courses.assignments.view_content')}</span>
                <Launch />
              </Button>
            )}
            <ActionMenu id="assignments-actions">
              {editUrl && isCustomCollection && (
                <Link role="menuitem" href={editUrl}>
                  <Edit />
                  <span>{t('views.courses.assignments.edit_content')}</span>
                </Link>
              )}
              {learningRoute && !isCustomCollection && (
                <button
                  role="menuitem"
                  type="button"
                  onClick={() =>
                    !isCustomRoute
                      ? openModal(DuplicateLearningRouteModal, {
                          learningRouteId: learningRoute?.id,
                          learningRouteTitle: learningRoute?.title,
                        })
                      : router.push(editUrl)
                  }
                >
                  <LearningRouteIcon />
                  <span>{t('views.courses.assignments.edit_route')}</span>
                </button>
              )}
            </ActionMenu>
          </div>
        )}
      </div>
      <CollectionsTable nodes={nodes} searchTerm={search} />
    </Container>
  );
});

export default CourseAssignmentsView;
