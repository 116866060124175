import React, { useEffect, useMemo, useState } from 'react';
import { Button } from '@brainstud/ui';
import { useDimensions } from '@brainstud/universal-components';
import { UnfoldLess, UnfoldMore } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import styles from './CardIntroduction.module.css';

const cx = classNames.bind(styles);

type Props = {
  introduction?:
    | string
    | {
        title?: string;
        content:
          | string
          | {
              title?: string;
              content: string;
            };
      };
  className?: string;
};

function isString(str: any): str is string {
  return typeof str === 'string';
}

/**
 * Showing an excerpt of the introduction in a box
 */
export const CardIntroduction = ({ introduction, className }: Props) => {
  const [t] = useTranslator();
  const [excerptLength, setExcerptLength] = useState(472);
  const [expanded, setExpanded] = useState(false);
  const introductionTitle =
    // eslint-disable-next-line no-nested-ternary
    isString(introduction)
      ? null
      : isString(introduction?.title) || isString(introduction?.content)
        ? introduction?.title
        : introduction?.content?.title;

  const introductionContent =
    // eslint-disable-next-line no-nested-ternary
    isString(introduction)
      ? introduction
      : isString(introduction?.content)
        ? introduction?.content
        : introduction?.content?.content; // Strip all HTML tags

  const hasValidIntroductionContent =
    (introductionContent || '')?.replaceAll(/<\/?[^>]+(>|$)/gi, '').trim() !==
    '';

  const isExpandable = (introductionContent?.length || 0) > excerptLength;
  const [ref, introCardDimensions] = useDimensions<HTMLDivElement>();

  const excerpt = useMemo(() => {
    if (!introductionContent) return '';
    return introductionContent.substring(0, excerptLength);
  }, [introductionContent, excerptLength]);

  useEffect(() => {
    const newExcerptLength = ((introCardDimensions?.width || 0) / 8) * 3;
    setExcerptLength(newExcerptLength);
  }, [introCardDimensions]);

  const text = isExpandable ? `${excerpt}...` : excerpt;

  return hasValidIntroductionContent ? (
    <div ref={ref} className={cx(styles.base, { expanded }, className)}>
      <h4>
        {introductionTitle || t('components.card_introduction.default_title')}
      </h4>
      <div>
        <div
          className={cx('intro-text')}
          dangerouslySetInnerHTML={{
            __html: sanitizer(expanded ? introductionContent : text),
          }}
        />
        {isExpandable && (
          <Button
            link
            className={cx('show-more')}
            onClick={() => setExpanded((prevExpanded) => !prevExpanded)}
          >
            {!expanded ? (
              <>
                {t('more')}
                <UnfoldMore fontSize="large" />
              </>
            ) : (
              <>
                {t('less')}
                <UnfoldLess fontSize="large" />
              </>
            )}
          </Button>
        )}
      </div>
    </div>
  ) : null;
};
