/**
 * getUserFriendlyBrowserNameAndVersion.
 *
 * Based on the userAgent string provided by the browser a simplified name and version is given.
 * __Note:__ This should NOT be used for feature detection!
 */
export function getUserFriendlyBrowserNameAndVersion() {
  if (window) {
    const ua = window.navigator.userAgent;
    let tem;
    let M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE', version: tem[1] || '' };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR|Edge\/(\d+)/);
      if (tem != null) {
        return { name: 'Opera', version: tem[1] };
      }
    }
    M = M[2]
      ? [M[1], M[2]]
      : [window.navigator.appName, window.navigator.appVersion, '-?'];
    tem = ua.match(/version\/(\d+)/i);
    if (tem != null) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: M[1],
    };
  }
  return {
    browser: 'node',
    version: 'x.x',
  };
}
