import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  LearningRoute,
  OnErrorHandler,
  OnSettledHandler,
  useLearningRoute,
} from '@brainstud/academy-api';
import { useLearningRouteProvider, useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import LearningRouteContext, {
  ILearningRouteEditorContext,
  LearningRouteEditorLayouts,
} from './LearningRouteEditorContext';

interface LearningRouteProviderProps {
  onSettled?: OnSettledHandler<LearningRoute>;
  onError?: OnErrorHandler;
  children: React.ReactNode;
}

/**
 * The learning route editor provider
 */
export const LearningRouteEditorProvider = ({
  children,
  onError,
  onSettled,
}: LearningRouteProviderProps) => {
  const {
    learningObjectCollection: collection,
    learningRoute,
    nodes: initialNodes,
    isLoading,
  } = useLearningRouteProvider();

  const items = useMemo(
    () => [
      ...(collection?.learningObjects?.() || []),
      ...(collection?.learningSubjects?.() || []),
    ],
    [collection]
  );

  const [{ update }] = useLearningRoute(
    { learningRoute: learningRoute?.id },
    { enabled: false }
  );

  const [setToast] = useToaster();
  const [t] = useTranslator();

  const [nodes, setNodes] = useState<ILearningRouteEditorContext['nodes']>([]);
  const [layout, setLayout] = useState<LearningRouteEditorLayouts>('grid');
  const [preview, setPreview] = useState<boolean>(false);
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (!isLoading && initialNodes) {
      setNodes([...initialNodes]);
    }
  }, [initialNodes, isLoading, items]);

  const save = useCallback(async () => {
    await update.mutateAsync(
      {
        _method: 'PATCH',
        nodes: nodes.map((item) => ({
          id: item.id,
          node_type: 'group',
          scheme: item.scheme.map((object) => ({
            id: object.id,
            type: object.resourceType,
          })),
        })),
      },
      {
        onSuccess: () => {
          setToast(t('course-editor.learning_route_editor.saved'), 'success');
        },
        onError,
        onSettled,
      }
    );
    setUpdated(false);
  }, [update, nodes, onError, onSettled, setToast, t]);

  useEffect(() => {
    if (nodes.length) {
      const nodeList = nodes.flatMap((item) => [
        item.id,
        ...item.scheme.map((schemeItem) => schemeItem.id),
      ]);
      const initialNodeList =
        initialNodes?.flatMap((item) => [
          item.id,
          ...item.scheme.map((schemeItem) => schemeItem.id),
        ]) || [];
      setUpdated(nodeList.some((item, idx) => initialNodeList[idx] !== item));
    }
  }, [nodes, initialNodes]);

  const isUpdating = update.isLoading;
  const context: ILearningRouteEditorContext = useMemo(
    () => ({
      collection,
      learningRoute,
      nodes,
      setNodes,
      layout,
      setLayout,
      save,
      updated,
      isLoading,
      isUpdating,
      preview,
      setPreview,
    }),
    [
      collection,
      learningRoute,
      nodes,
      layout,
      save,
      updated,
      isLoading,
      isUpdating,
      preview,
    ]
  );

  return (
    <LearningRouteContext.Provider value={context}>
      {children}
    </LearningRouteContext.Provider>
  );
};
