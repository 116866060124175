import React, { useState } from 'react';
import { Button } from '@brainstud/ui';
import { Star } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { TFeedbackFormProps } from './Types';
import styles from './FeedbackTypes.module.css';

const cx = classNames.bind(styles);

export const StarsFeedback = ({ title, onAnswer }: TFeedbackFormProps) => {
  const [rating, setRating] = useState(0);

  return (
    <div className={cx('stars')}>
      <p className={cx('title')}>{title}</p>
      <div className={cx('buttons-list')}>
        {[...Array(5)].map((_, i) => (
          <span
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            onMouseEnter={() => setRating(i + 1)}
            onMouseLeave={() => setRating(0)}
          >
            <Button
              onClick={() => onAnswer(i + 1)}
              className={cx({ active: i < rating })}
              link
            >
              <Star />
            </Button>
          </span>
        ))}
        <span>
          {rating}
          /5
        </span>
      </div>
    </div>
  );
};
