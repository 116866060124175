import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  SampleSet,
  SampleSetCreateInput,
  SampleSetFilters,
  SampleSetIncludes,
  SampleSetSorts,
  SampleSetUpdateInput,
  UUID,
} from '../Types';

interface IProps
  extends HookParams<
    typeof SampleSetIncludes,
    typeof SampleSetFilters,
    typeof SampleSetSorts
  > {
  sampleSet?: UUID;
}

export function useSampleSet(
  { sampleSet, ...queryParameters }: IProps,
  settings?: UseQueryOptions<DataDocument<SampleSet>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { sampleSet: defaultSampleSet },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(sampleSet || defaultSampleSet),
      baseName: 'v1.exams.sampleSets',
      baseUri: `/v1/sample_sets/${sampleSet || defaultSampleSet}`,
      invalidate: [],
      uri: `/${sampleSet || defaultSampleSet}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<SampleSet, SampleSetCreateInput, SampleSetUpdateInput>(
    request,
    settings
  );
}

export function useSampleSets(
  { ...queryParameters }: Omit<IProps, 'sampleAnswer'>,
  settings?: UseQueryOptions<DataDocument<SampleSet[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.exams.sampleSets',
      baseUri: '/v1/sample_sets',
      invalidate: ['sampleSet', 'sampleSets'],
      queryParameters,
    },
    context
  );
  return useIndexCR<SampleSet, SampleSetCreateInput>(request, settings);
}
