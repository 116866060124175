import { createContext } from 'react';
import { TDataContext, TDataDispatcher, TSelectionContext } from './Types';

export const DataContext = createContext<undefined | TDataContext>(undefined);

export const DataDispatcherContext = createContext<null | TDataDispatcher>(
  null
);

export const DataSelectionContext = createContext<null | TSelectionContext>(
  null
);
