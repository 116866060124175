import React from 'react';
import type { Editor } from '@tiptap/core';
import Highlight from '@tiptap/extension-highlight';
import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';
import { EditorContent, EditorOptions, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import classNames from 'classnames/bind';
import { MinimalToolbar } from './Toolbars/MinimalToolbar';
import styles from './RichTextEditor.module.css';

const cx = classNames.bind(styles);

type TPresetList = {
  [key: string]: Partial<EditorOptions>;
};

const Presets: TPresetList = {
  default: {
    extensions: [StarterKit],
  },
  minimal: {
    extensions: [
      StarterKit.configure({
        strike: false,
      }),
      Underline,
      Highlight,
    ],
  },
};

type RichTextEditorProps = {
  /** Sets an ID on the editor and the label HTMLFor attribute */
  id?: string | undefined;
  /** Shows a label above the textarea  */
  label?: string;
  /** Shows a text when the textarea is empty  */
  placeholder?: string;
  /** Set a default value on load */
  defaultValue?: string;
  /** Set a config preset. Defaults to 'minimal' */
  preset?: keyof typeof Presets;
  /** Configurable options for this RichTextEditor */
  config?: Partial<EditorOptions>;
  /** Sets the onChange event handler */
  onChange?: (value: string, editor: Editor) => void;
  /** Overwrites on style properties of the base div */
  style?: object;
  /** Adds classes to base div */
  className?: string;
};

/**
 * RichTextEditor
 *
 * Use the RichTextEditor to create a Textarea that can also influence the styling of the text. It
 * works with [TipTap editor](https://tiptap.dev), which is based on ProseMirror.
 */
export const RichTextEditor = ({
  id,
  label,
  placeholder,
  defaultValue,
  config,
  onChange,
  preset = 'default',
  className,
  style,
}: RichTextEditorProps) => {
  const editor = useEditor({
    ...config,
    extensions: [
      ...(preset && preset in Presets ? Presets[preset]?.extensions || [] : []),
      ...(config?.extensions || []),
      Placeholder.configure({
        placeholder,
      }),
    ],
    content: defaultValue || config?.content,
    onUpdate: ({ editor: updatedEditor }) => {
      onChange?.(updatedEditor.getHTML(), updatedEditor);
    },
  });

  return (
    <div
      className={cx(styles.base, 'richtexteditor-component', className)}
      style={style}
    >
      {label && (
        <label htmlFor={id} className={cx(styles.label)}>
          {label}
        </label>
      )}
      <div className={cx(styles.textarea)}>
        <MinimalToolbar editor={editor} />
        <EditorContent
          editor={editor}
          id={id}
          className={cx(styles.editor, 'editor-content')}
        />
      </div>
    </div>
  );
};
