import React from 'react';

export interface IBlock<T extends string = string> {
  /** A UUID defining this block */
  id: string;
  /** The block type indicating the rest of the structure of this object */
  type: T;
  /** Determines the amount of spacing around the content block, used by the Spacing component */
  wideness?: 'medium' | 'large' | 'max' | string;
  /** Optional css style properties for a block */
  style?: React.CSSProperties;
  /** Way to add extra identifiers in case of changed uuid */
  extraAnswerIds?: string[];
}
export interface IFeedback {
  /** Object containing the strings to show after validation */
  feedback: {
    /** The message shown when the block is valid */
    correct: string;
    /** The message shown when the block invalidated */
    incorrect: string;
    /** Currently unused in the project */
    hint?: string;
  };
}
