import { FC } from 'react';
import { DataProvider } from 'Modules/universal-components/Providers/DataProvider';

/**
 * A higher order component that wraps the component with the data provider
 */
export const withDataProvider =
  <T extends {} = {}>(Component: FC<T>): FC<T> =>
  (props: T) => (
    <DataProvider data={[]}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Component {...props} />
    </DataProvider>
  );
