import React, { ReactNode } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

type Props = {
  children?: ReactNode;
};

/**
 * This provider prevents that multiple DND Backends occur at the same time.
 * From: https://github.com/react-dnd/react-dnd/issues/186#issuecomment-561631584
 */
export const DndProviderWithBackend = ({ children }: Props) => (
  <DndProvider backend={HTML5Backend}>{children}</DndProvider>
);
