import { createContext } from 'react';

type ToastSchemes = 'success' | 'warning' | 'error' | 'info' | 'primary';
export type TCreateToast = (
  message: string,
  scheme?: ToastSchemes,
  duration?: number
) => void;
export type TClearToast = () => void;

type TToasterProvider = Readonly<[TCreateToast, TClearToast]>;

export const ToasterContext = createContext<null | TToasterProvider>(null);
