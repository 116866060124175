import { IResource } from '../Utils/Resource';
import { UUID } from '../Utils/UUID';

export interface ISearch extends IResource {}

export enum ISearchIncludes {
  'enrollments',
  'entity',
  'subscription',
}

export interface ISearchFilters {
  query: string;
  entity: UUID;
}

export enum ISearchSorts {}
