import React, { CSSProperties } from 'react';
import classNames from 'classnames/bind';
import styles from './SourceTag.module.css';

const classes = classNames.bind(styles);

type Props = {
  source: string;
  style?: CSSProperties;
  className?: string;
};

function url_domain(value: string) {
  const regex = /^https?:\/\/([^/:?#]+)(?:[/:?#]|$)/i;
  const matches = value.match(regex);
  return matches ? matches[1].replace('www.', '') : value;
}

/**
 * SourceTag.
 *
 * Shows a small tag at the bottom right.
 */
export const SourceTag = ({ source, className, style }: Props) => {
  const baseUrl = url_domain(source);
  const isUrl = baseUrl !== source;
  return isUrl ? (
    <a
      className={classes(styles.base, className)}
      href={source}
      target="_blank"
      rel="noopener noreferrer"
      title={source}
      style={style}
    >
      {baseUrl}
    </a>
  ) : (
    <span
      title={source}
      className={classes(styles.base, className)}
      style={style}
    >
      {source}
    </span>
  );
};
