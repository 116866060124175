import { ExceptionTemplates } from '../Providers/ErrorDataProvider/Templates';
import { Exception } from './Exception';

/**
 * Not enrolled exception.
 *
 * Exception that is thrown when user has no valid enrollment for a course.
 */
export class NotEnrolledException extends Exception {
  public name = 'Missing enrollment';

  public silent = true;

  public level = 'info' as const;

  public template = 'toast' as keyof typeof ExceptionTemplates;

  public props = {
    title: 'exceptions.not_enrolled.title',
    description: 'exceptions.not_enrolled.description',
  };

  constructor({
    template,
  }: { template?: keyof typeof ExceptionTemplates } = {}) {
    super();

    if (template) {
      this.template = template;
    }
  }
}
