import React, { useCallback, useState } from 'react';
import { LearningRoute, useLearningRouteNode } from '@brainstud/academy-api';
import { ILearningRouteEditorContext } from '@brainstud/course-editor';
import { Button, Input, SidebarModal } from '@brainstud/ui';
import { FlexContainer, RichTextEditor } from 'Components';
import { Form } from 'Modules/universal-components';
import { useModals, useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';

interface LearningRouteNodeModalProps {
  learningRoute?: LearningRoute;
  node?: ILearningRouteEditorContext['nodes'][0];
}

type ChangeEvent = React.ChangeEvent<HTMLInputElement>;

type FormData = {
  title: string;
  introduction_title: string;
  image_url: string;
};

export const LearningRouteNodeModal = ({
  learningRoute,
  node,
}: LearningRouteNodeModalProps) => {
  const { closeModal } = useModals();
  const [t] = useTranslator();
  const [setToast] = useToaster();

  const [title, setTitle] = useState<string>(node?.title || '');
  const [introText, setIntroText] = useState('');

  const [{ createOrUpdate }] = useLearningRouteNode(
    {
      learningRoute: learningRoute?.id,
      node: node?.id,
    },
    { enabled: false }
  );

  const handleSubmit = useCallback(
    (data: FormData) => {
      createOrUpdate
        .mutateAsync({
          node_type: 'group',
          position: node?.position !== undefined ? node?.position : null,
          introduction: {
            title: data?.introduction_title,
            content: introText,
          },
          // @ts-ignore
          introduction_text: introText,
          ...data,
        })
        .then(() => {
          setToast(t('modals.learning_route_node.success'), 'success');
          closeModal();
        })
        .catch(() => {
          setToast(t('modals.learning_route_node.error'), 'error');
        });
    },
    [createOrUpdate, node?.position, introText, setToast, t, closeModal]
  );

  return (
    <SidebarModal onClose={closeModal} onClickOutside size="medium">
      <Form<FormData> onSubmit={handleSubmit}>
        <SidebarModal.Header>
          <FlexContainer row justifyContent="space-between" alignItems="center">
            <h3>
              {t(
                `modals.learning_route_node.title.${!node ? 'create' : 'edit'}`
              )}
            </h3>
            <Button type="submit" loading={createOrUpdate.isLoading}>
              {t(`modals.submit.${!node ? 'create' : 'edit'}`)}
            </Button>
          </FlexContainer>
        </SidebarModal.Header>
        <Input
          name="title"
          label={t('modals.learning_route_node.input.title')}
          placeholder={t('modals.learning_route_node.input.title_placeholder')}
          onChange={(e: ChangeEvent) => setTitle(e.target.value)}
          value={title}
        />
        <Input
          name="introduction_title"
          label={t('modals.learning_route_node.input.introduction_title')}
          placeholder={t('modals.learning_route_node.input.introduction_title')}
          defaultValue={node?.introduction?.title}
        />
        <RichTextEditor
          id="introduction_text"
          label={t('modals.learning_route_node.input.introduction_content')}
          placeholder={t(
            'modals.learning_route_node.input.introduction_content'
          )}
          onChange={(val) => setIntroText(val)}
          defaultValue={node?.introduction?.content || ''}
        />
      </Form>
    </SidebarModal>
  );
};
