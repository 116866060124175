import React, { FC, useMemo } from 'react';
import { useForm } from '@brainstud/universal-components';
import { useBlock } from 'Hooks/useBlock';
import { useRouter } from 'next/router';
import { RichTextEditor } from '../../../../../Components';
import { toName } from '../../../Utils/toName';
import { ITextareaBlockProps } from './Types';
import styles from './TextareaQuestion.module.css';

type Props = Omit<ITextareaBlockProps, 'type'>;

export const TextareaQuestion: FC<Props> = ({
  id,
  question,
  inputFieldsCount = 1,
}) => {
  const name = toName(id);
  const { values } = useForm();
  const fields = useMemo(
    () =>
      Array.from(
        new Array(parseInt(inputFieldsCount as unknown as string, 10)),
        (_val, index) => `${name}_${index}`
      ),
    [inputFieldsCount]
  );

  const inputValues = values[name];
  useBlock({
    id,
    fields,
    touched:
      Array.isArray(inputValues) && inputValues.filter((val) => val).length > 0,
    open: true,
  });

  const router = useRouter();
  const isEditing = router.asPath.includes('/edit');

  return (
    <div className={styles.base}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label>{question}</label>
      {fields.map((fieldName, idx) => (
        <RichTextEditor
          key={fieldName}
          id={fieldName}
          name={`${name}[]`}
          defaultValue={inputValues?.[idx]}
          disabled={isEditing}
        />
      ))}
    </div>
  );
};
