import React from 'react';
import { Button } from '@brainstud/ui';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { LearningObjectCircle } from 'Components';
import { isLearningObject } from 'Modules/action-based-learning-panel/Utils/isLearningGroupSubjectObject';
import { useRouter } from 'next/router';
import {
  ILearningObjectWithResources,
  useLearningSubjectProvider,
} from 'Providers';
import { useQuestionNavigation } from '../useQuestionNavigation';
import styles from './QuestionHeaderNavigation.module.css';

const cx = classNames.bind(styles);

export const QuestionHeaderNavigation = () => {
  const router = useRouter();
  const { courseId } = router.query as { courseId: string };

  const { subnodes } = useLearningSubjectProvider();
  const objects = subnodes.filter(
    isLearningObject
  ) as ILearningObjectWithResources[];

  const { nextItem, previousItem } = useQuestionNavigation();

  return (
    <nav className={cx(styles.base)}>
      <Button
        type="button"
        quiet
        link
        disabled={!previousItem}
        to={
          previousItem
            ? `/courses/${courseId}/detail/${previousItem.id}`
            : undefined
        }
      >
        <ChevronLeft fontSize="large" />
      </Button>
      <aside className={cx(styles.bullets)}>
        {objects.map((object, index) => (
          <LearningObjectCircle
            key={object.id}
            to={`/courses/${courseId}/detail/${object.id}`}
            learningObject={object}
            index={index}
          />
        ))}
      </aside>
      <Button
        type="button"
        quiet
        link
        disabled={!nextItem}
        to={nextItem ? `/courses/${courseId}/detail/${nextItem.id}` : undefined}
      >
        <ChevronRight fontSize="large" />
      </Button>
    </nav>
  );
};
