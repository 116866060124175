import { DateTimeString } from '../Utils/DateTimeString';
import { IPagination } from '../Utils/IPagination';
import { IPolicy } from '../Utils/IPolicy';
import { IResource } from '../Utils/Resource';
import { Certification } from './Certification';
import { EducationProfile } from './EducationProfile';
import { Enrollment } from './Enrollment';
import { LearningObjectCollection } from './LearningObjectCollection';
import { LtiResourceLink } from './LtiResourceLink';
import { Product } from './Product';
import { ProductCategory } from './ProductCategory';
import { ProductSubCategory } from './ProductSubCategory';
import { Scorm } from './Scorm';
import { StoredFile } from './StoredFile';

export type CourseStatus = 'CONCEPT' | 'PUBLISHED' | 'COMING_SOON';

export interface Course extends IResource<'courses'> {
  title: string;
  status: CourseStatus;
  state: string;
  description: string;
  posterUrl: string;
  thumbnailUrl: string;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  opener: string;
  examsEnabled: boolean;
  courseLink: string;
  /** @deprecated Use the include `factsheet` instead. */
  factsheetUrl: string;
  locked: boolean;
  hasAccess: boolean;
  metadata?: {
    media?: {
      thumbnailUrl?: string;
      posterUrl?: string;
      teaserUrl?: string;
      [key: string]: undefined | boolean | string;
    };
    informationLines?: {
      sbu?: string;
      originals?: string;
      developers?: string;
      dossiers?: string;
      [key: string]: boolean | string | undefined;
    };
    files: {
      /** @deprecated Use the include `coach_conditions` instead. */
      coachConditions: string;
      [key: string]: boolean | string | undefined;
    };
    features?: {
      mrChadd?: boolean;
      [key: string]: boolean | string | undefined;
    };
  };
  requireSubscription: boolean;
  type: string;
  archived: boolean;
  typeSingular: string;
  useGlossary: boolean;
  useSchedule: boolean;
  ltiResourceLinks?: () => LtiResourceLink[];
  learningObjectCollections?: () => LearningObjectCollection[];
  /**
   * @deprecated Use .catalogProducts (plural) instead.
   */
  catalogProduct?: () => Product;
  catalogProducts?: () => Product[];
  enrollment?: () => Enrollment;
  educationProfiles?: () => EducationProfile[];
  productCategory?: () => ProductCategory;
  productSubCategory?: () => ProductSubCategory;
  certifications?: () => Certification[];
  products?: () => Product[];
  /** A file containing the conditions a coach has to accept before teaching the course */
  coachConditions?: () => StoredFile;
  /** A file containing all details about this course */
  factsheet?: () => StoredFile;
  scorms?: () => Scorm[];
  meta?: {
    /** Only for coahces. Indicates the number of participants that have selected this coach for this course */
    studentsCount: number;
  } & IPagination &
    IPolicy<{
      /** Whether the user may create learning object collections within this resource */
      createLearningObjectCollection: boolean;
    }>;
}

export interface CourseCreateInput {
  title: string;
  status: CourseStatus;
  course_link?: string;
  publish_to_portal: string[];
  description: string;
  education_profiles?: string[];
  relationships?: {
    /** A temporary stored file identifier to link to this course factsheet */
    factsheet?: string | null;
    /** A temporary stored file identifier to link to this course as coach conditions */
    coach_conditions?: string | null;
  };
  metadata?: {
    media?: {
      thumbnail_url?: string;
      poster_url?: string;
      teaser_url?: string;
      [key: string]: undefined | boolean | string | number;
    };
    information_lines?: {
      sbu?: string;
      originals?: string;
      developers?: string;
      dossiers?: string;
      available_from?: string;
      [key: string]: undefined | boolean | string | number;
    };
    files?: {
      /** @deprecated Use the relationship `coach_conditions` instead. */
      coach_conditions?: string;
      [key: string]: undefined | boolean | string | number;
    };
    features?: {
      mr_chadd?: boolean;
      [key: string]: undefined | boolean | string | number;
    };
  };
  content_package?: {
    title?: string;
    description?: string;
    path?: string;
    location?: string;
    is_course_opener?: boolean;
  };
}

export interface CourseUpdateInput extends Partial<CourseCreateInput> {
  archived?: boolean;
  opener?: string;
}

export enum CourseIncludes {
  portals,
  learning_object_collections,
  catalog_product,
  catalog_products,
  education_profiles,
  'products',
  'products.catalogs',
  certifications,
  'certifications.dossiers',
  'product_category',
  'scorms',
  'scorms.scos',
  lti_resource_links,
  lti_resource_linksCount,
  lti_resource_linksExists,
}

export interface CourseFilters {
  status: string;
  title: string;
  portal: string;
  coach_account: string;
}

export enum CourseSorts {
  status,
  title,
  updated_at,
  'enrollment.active_at',
}

export type CourseMeta = Array<'policy'>;
