import { FC, useCallback } from 'react';
import { CourseStatus, useCourse } from '@brainstud/academy-api';
import { Button, ContentModal } from '@brainstud/ui';
import { Form } from '@brainstud/universal-components';
import { useToaster } from 'Providers/ToasterProvider';
import { useTranslator } from 'Providers/Translator';

interface ICourseChangeStatusModal {
  /* the course id of the course to update */
  courseId: string;
  /* the new course status */
  status: string;
  closeModal: () => void;
}

/**
 * The modal shows a form with which you can confirm your status change action for a course.
 */
export const CourseChangeStatusModal: FC<ICourseChangeStatusModal> = ({
  courseId,
  status,
  closeModal,
}) => {
  const [t] = useTranslator();
  const [setToast] = useToaster();
  const [{ update, destroy }] = useCourse({
    course: courseId,
  });

  const handleStatusChange = useCallback(() => {
    if (status === 'DELETED') {
      destroy.mutateAsync(undefined, {
        onSuccess: () => {
          setToast(
            t(`modals.course_change_status.${status}.success`),
            'success'
          );
          closeModal();
        },
        onError: () => {
          closeModal();
          setToast(t(`modals.course_change_status.${status}.error`), 'error');
        },
      });
    } else {
      update.mutate(
        {
          _method: 'patch',
          status: status as CourseStatus,
        },
        {
          onSuccess: () => {
            setToast(
              t(`modals.course_change_status.${status}.success`),
              'success'
            );
            closeModal();
          },
          onError: () => {
            closeModal();
            setToast(t(`modals.course_change_status.${status}.error`), 'error');
          },
        }
      );
    }
  }, [closeModal, destroy, setToast, status, t, update]);

  return (
    <ContentModal onClickOutside onClose={closeModal}>
      <Form
        onSubmit={handleStatusChange}
        language="nl"
        disabled={update.isLoading || destroy.isLoading}
      >
        <ContentModal.Header>
          <h3>{t(`modals.course_change_status.${status}.title`)}</h3>
        </ContentModal.Header>

        <p>{t(`modals.course_change_status.${status}.description`)}</p>

        <ContentModal.Footer>
          <Button type="submit" loading={update.isLoading || destroy.isLoading}>
            {t(`modals.course_change_status.${status}.confirm`)}
          </Button>
          <Button type="reset" onClick={closeModal} outline>
            {t('cancel')}
          </Button>
        </ContentModal.Footer>
      </Form>
    </ContentModal>
  );
};
