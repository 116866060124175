import React from 'react';
import { IBlock } from '../../Types';

/**
 * @deprecated Use ITabContainerBlock instead.
 */
export type TTab = {
  title?: string;
  block: IBlock;
};

/**
 * The TabContainer is a child of the TabbarContainer, and contains all
 * information for 1 tab: the contents and the title.
 */
export interface ITabContainerBlock extends IBlock<'tab_container'> {
  /** The title of the tab. Used by the Tabbar to display the tab */
  title: string;
  /** The contents of the tab */
  blocks: IBlock[];
  /** Add a global className to the specific tab */
  className?: string;
  /** Styling properties added to the container div of the block */
  style?: React.CSSProperties;
}

/**
 * The TabbarContainer is the container to display TabContainers. It renders
 * the list of tabs and the tab contents of the active tab.
 */
export interface ITabbarContainerBlock extends IBlock<'tabbar_container'> {
  /** @deprecated Use .blocks instead */
  tabs?: TTab[];
  /** The tabbar shows a set of TabContainer blocks. */
  blocks?: ITabContainerBlock[];
  /** Whether the Tabbar should default to a vertical Tabbar */
  variant?: 'horizontal' | 'vertical';
  /** Styling properties added to the container div of the block */
  style?: React.CSSProperties;
  /** Add a global className to the specific tab */
  className?: string;
}
