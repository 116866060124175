export enum ListReducerActions {
  CREATE_LIST,
  REMOVE_LIST,
  ADD_TO_LIST,
  REMOVE_FROM_LIST,
  REMOVE_FROM_ALL_LIST,
  MOVE_ITEM,
  RESET,
}

type TCreateListAction = {
  type: ListReducerActions.CREATE_LIST;
  payload: {
    listName: string;
  };
};

type TRemoveListAction = {
  type: ListReducerActions.REMOVE_LIST;
  payload: {
    listName: string;
  };
};

type TAddToListAction<T> = {
  type: ListReducerActions.ADD_TO_LIST;
  payload: {
    listName: string;
    item: T;
  };
};

type TMoveItemAction<T> = {
  type: ListReducerActions.MOVE_ITEM;
  payload: {
    item: T;
    toList: string;
  };
};

type TRemoveFromAllListAction<T> = {
  type: ListReducerActions.REMOVE_FROM_ALL_LIST;
  payload: {
    item: T;
  };
};

type TRemoveFromListAction<T> = {
  type: ListReducerActions.REMOVE_FROM_LIST;
  payload: {
    listName: string;
    item: T;
  };
};

type TResetListsAction = {
  type: ListReducerActions.RESET;
};

type ObjectType = { id: string } | { identifier: string };
export type TListReducerActions<T extends ObjectType = { id: string }> =
  | TCreateListAction
  | TRemoveListAction
  | TAddToListAction<T>
  | TMoveItemAction<T>
  | TRemoveFromAllListAction<T>
  | TRemoveFromListAction<T>
  | TResetListsAction;
