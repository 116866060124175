import { TValidator } from 'Hooks';

const invalidNameRegex = /[^0-9><()@#$%^]/;
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneRegex =
  /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/;
const inappropriate = [
  'hoer',
  'kanker',
  'fuck',
  'kut',
  'godverdomme',
  'neger',
  'nigger',
  'homo',
  'gay',
  'slet',
];
const appropriateRegex = new RegExp(`(${inappropriate.join('|')})`, 'i');

function is_string(input: null | string): input is string {
  return input !== null && input.trim().length !== 0;
}

export function is_required(input: null | string): ReturnType<TValidator> {
  return !is_string(input) ? 'is_required' : null;
}

export function is_appropriate(input: null | string): ReturnType<TValidator> {
  return is_string(input) && !appropriateRegex.test(input)
    ? 'inappropriate_content'
    : null;
}

export function valid_email(input: null | string): ReturnType<TValidator> {
  return is_string(input) && !emailRegex.test(input) ? 'invalid_email' : null;
}
export function valid_phone(input: null | string): ReturnType<TValidator> {
  return is_string(input) && !phoneRegex.test(input) ? 'invalid_phone' : null;
}

export function valid_name(input: null | string): ReturnType<TValidator> {
  return is_string(input) && !invalidNameRegex.test(input)
    ? 'invalid_name'
    : null;
}

export function valid_slug(input: null | string): ReturnType<TValidator> {
  const validSlug = /^[a-z0-9]{2}(?:-[a-z0-9]+)*$/;
  return is_string(input) &&
    !appropriateRegex.test(input) &&
    validSlug.test(input)
    ? 'invalid_slug'
    : null;
}

export type TLengthOptions = {
  min?: number;
  max?: number;
};

export function has_length(
  input: string,
  options?: TLengthOptions
): ReturnType<TValidator> {
  // value is less than max
  if (options?.max && !options?.min) {
    return !(input.length < options?.max) ? 'more_than_max' : null;
  }
  // value is more then min
  if (options?.min && !options?.max) {
    return input.length > options?.min ? 'less_than_min' : null;
  }
  // value is between min and max
  if (options?.min && options?.max) {
    return !(input.length >= options?.min && input.length <= options?.max)
      ? 'not_between_min_max'
      : null;
  }
  return false;
}
