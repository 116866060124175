import React, { useCallback, useMemo } from 'react';
import { useEnrollment } from '@brainstud/academy-api';
import { Dropdown, Status } from '@brainstud/ui';
import { DisabledByDefault } from '@mui/icons-material';
import classNames from 'classnames';
import { useTranslator } from 'Providers/Translator';
import styles from '../EnrollmentSettingsForm.module.css';

type TProps = {
  /** Overwrite the default enrollmentId */
  enrollmentId?: string;
  className?: string;
};

const cx = classNames.bind(styles);

export const SettingAssignmentSet = ({ enrollmentId, className }: TProps) => {
  const [t] = useTranslator();
  const [{ data: enrollment, update: updateEnrollment }, { isFetching }] =
    useEnrollment({ enrollment: enrollmentId });
  const collections = useMemo(
    () => enrollment?.learningObjectCollections?.(),
    [enrollment]
  );
  const currentCollection = useMemo(
    () => enrollment?.learningObjectCollection?.(),
    [enrollment]
  );

  const handleContentSetChange = useCallback(
    async (value?: null | string) => {
      await updateEnrollment.mutateAsync({
        learning_object_collection: value || undefined,
      });
    },
    [updateEnrollment]
  );

  return (
    <div className={cx(styles.setting, className)}>
      <h4>{t('menu.assignmentsset')}</h4>
      <Dropdown
        loading={updateEnrollment.isLoading}
        placeholder={
          !currentCollection?.id
            ? t('components.settings_collection.select_collection')
            : undefined
        }
        value={currentCollection?.id}
        onChange={handleContentSetChange}
        block
      >
        {isFetching ? (
          <Dropdown.Option value={currentCollection?.id}>
            <span>{currentCollection?.title}</span>
            {!currentCollection?.allowNewEnrollments && <DisabledByDefault />}
          </Dropdown.Option>
        ) : (
          <>
            {currentCollection?.status === 'ARCHIVED' && (
              <Dropdown.Option value={currentCollection.id} disabled>
                <span>{currentCollection.title}</span>
                <Status scheme="warning">{t('states.archived')}</Status>
                {!currentCollection.allowNewEnrollments && (
                  <DisabledByDefault />
                )}
              </Dropdown.Option>
            )}
            {collections?.map((collection) => (
              <Dropdown.Option key={collection.id} value={collection.id}>
                <span>{collection.title}</span>
                {!collection.allowNewEnrollments && <DisabledByDefault />}
              </Dropdown.Option>
            ))}
          </>
        )}
      </Dropdown>
    </div>
  );
};
