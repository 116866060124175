import { IResource } from '../Utils/Resource';
import type { Catalog } from './Catalog';
import type { Course } from './Course';
import type { OrderAutomation } from './OrderAutomation';
import { ProductAddOn } from './ProductAddOn';
import { SlaProduct } from './SlaProduct';
import type { SubscriptionOption } from './SubscriptionOption';
import { VatRate } from './VatRate';

export type PriceModel =
  | 'flat_fee'
  | 'subscription'
  | 'free'
  | 'external'
  | 'in_plan';

export interface Product extends IResource<'catalog_products'> {
  name: string;
  accessDuration: number;
  description: string;
  productType: TProductType;
  productModelType: string;
  priceModel: PriceModel;
  price: number;
  priceBeforeVat?: number;
  priceAfterVat?: number;
  vatRate?: () => VatRate;
  currency?: string;
  /** Hides product from catalog. Used for SLA kind of products */
  listed: boolean;
  /** @deprecated Makes it invisible in catalog and disables purchase */
  enabled: boolean;
  /** Makes it visible in the catalog, but disables enrollment or purchase */
  locked: boolean;
  /** Makes it only available to people that already purchased it. */
  deprecated?: boolean;
  discount?: string;
  metadata?: {
    media?: {
      icon?: string;
    };
    labels?: {
      popular?: boolean;
      warning?: string;
    };
    informationLines?: {
      features?: string[];
    };
  };
  addOnCount?: number;
  requiresPreRegistration: boolean;
  requiresEventCalendarItemEnrollment: boolean;
  productAddOns?: () => ProductAddOn[];
  productModels?: () => (Course | SubscriptionOption)[];
  catalogs?: () => Catalog[];
  externalPaymentUrl?: string;
  orderAutomations?: () => OrderAutomation[];
  slaProducts?: () => SlaProduct[];
  subscriptionOptions?: () => SubscriptionOption[];
  courses?: () => Course[];
}

export interface ProductCreateInput {
  name?: string;
  description?: string;
  product_type?: string;
  price_model: PriceModel;
  price_before_vat?: number;
  price_after_vat?: number;
  price?: number;
  vat_rate?: string;
  currency?: string;
  listed?: boolean;
  metadata?: {
    media?: {
      icon?: string;
    };
    labels?: {
      popular?: boolean;
    };
  };
  enabled: boolean;
  locked?: boolean;
  discount?: string;
  add_on_count?: number;
  requires_pre_registration?: boolean;
  requires_event_calendar_item_enrollment?: boolean;
  relationships?: {
    product_models: Array<{
      id: string;
      type: 'sla_products' | 'courses' | 'subscription_options';
    }>;
    plans?: string[];
    catalogs?: string[];
    vat_rate?: string;
  };
}

export type TProductType = 'subscription_option' | 'plan_option' | 'course';

export type ProductUpdateInput = Partial<ProductCreateInput>;

export enum ProductIncludes {
  order_automations,
  product_add_ons,
  sla_products,
  plans,
  subscription_options,
  category,
  sub_category,
  product_models,
  vat_rate,
  courses,
  catalog_products,
  catalogs,
}
export interface ProductFilters {
  product_type: string;
  product_models: string[];
  price_model: PriceModel;
  name: string;
}
export enum ProductSort {}
