import { IResource } from '../Utils/Resource';
import { Account } from './Account';

export type PermissionType =
  | 'public'
  | 'portal'
  | 'entity'
  | 'sub_entity'
  | 'private';

interface AccountProfile extends Partial<Account> {}

export interface Profile<TResourceType extends string = 'account_profiles'>
  extends IResource<TResourceType> {
  /** Description via which way the account was created */
  biography?: string;
  avatarUrl?: string;
  bannerUrl?: string;
  slug?: string;
  account?: () => AccountProfile;
}

export enum ProfileIncludes {
  account,
  'account.expositions',
}

export interface ProfileFilters {}

export enum ProfileSort {}
