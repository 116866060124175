import React from 'react';
import { OrderLine as OrderLineType } from '@brainstud/academy-api';
import classNames from 'classnames/bind';
import { Link } from 'Components';
import { useTranslator } from 'Providers/Translator';
import { toCurrency } from 'Utils';
import { DateFormatter } from 'Utils/DateFormatHelper';
import styles from 'Views/Admin/Sales/Partial/OrderDetailPartialView.module.css';

const cx = classNames.bind(styles);

type Props = {
  /** the orderline to display */
  line: OrderLineType;
};

export const Orderline = ({ line }: Props) => {
  const [t] = useTranslator();

  return (
    <div className={cx(styles.line)} key={line.id}>
      <div className={cx(styles.data)}>
        <div className={cx(styles.label)}>
          {line.catalogProduct?.()
            ? t('views.orders.partials.order_details.catalog_product')
            : line.links?.exportPrepaidCodes
              ? t('views.orders.partials.order_details.generated_prepaid_codes')
              : t('views.orders.partials.order_details.product_addon')}
        </div>
        <div>
          <span>{`${t('views.orders.partials.order_details.product')}: `}</span>
          <span>{line.productName}</span>
        </div>
        {line.catalogProduct?.().productModels?.() &&
          line
            .catalogProduct?.()
            .productModels?.()
            .map((model) => (
              <div key={model.id}>
                <span>{`${t('views.orders.partials.order_details.duration')}: `}</span>
                <span>
                  {model.resourceType === 'subscription_options'
                    ? model.duration
                    : t('views.orders.partials.order_details.without_limit')}
                </span>
              </div>
            ))}
        {line.links?.exportPrepaidCodes && (
          <>
            <div>
              <span>{`${t('views.orders.partials.order_details.expiry_date')}: `}</span>
              <span>
                {DateFormatter.toDateTime(
                  line.prepaidCodes?.()[0].expiryDate,
                  'dd MMMM yyyy'
                )}
              </span>
            </div>
            <div>
              <Link href={line.links?.exportPrepaidCodes.href} target="_blank">
                {t('views.orders.partials.order_details.see_generated_codes')}
              </Link>
            </div>
          </>
        )}
        {line.discountType && (
          <div>
            <span>{`${t('views.orders.partials.order_details.discount_type')}: `}</span>
            <span>{line.discountType}</span>
          </div>
        )}
        {line.discountAmount && (
          <div>
            <span>{`${t('views.orders.partials.order_details.discount_amount')}: `}</span>
            <span>{toCurrency(line.discountAmount)}</span>
          </div>
        )}
      </div>
      <dl className={cx(styles.pricing)}>
        <dt>{t('views.orders.partials.order_details.quantity')}</dt>
        <dd>{line.quantity}</dd>
        <dt>{t('views.orders.partials.order_details.vat_rate')}</dt>
        <dd>{`${line.vatRate}%`}</dd>
        <dt>{t('views.orders.partials.order_details.price')}</dt>
        <dd>{toCurrency(line.totalPriceIncVat)}</dd>
      </dl>
    </div>
  );
};
