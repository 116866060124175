/* eslint-disable no-underscore-dangle */
import { QueryKey } from 'react-query';
import { withTrailingSlash } from '@brainstud/academy-api/Utils/trailingSlash';
import axios, { AxiosError, Method } from 'axios';
import {
  DefaultIncludes,
  IApiContext,
} from '../../Providers/ApiProvider/IApiContext';
import { IResourceOrCollection } from '../../Types';
import clone from '../../Utils/clone';
import { constructUrl } from '../../Utils/constructUrl';
import { toCamelCase } from '../../Utils/transformCase';
import DataDocument from '../Documents/DataDocument/DataDocument';
import ErrorDocument from '../Documents/ErrorDocument/ErrorDocument';

type TQueryParameters = {
  filter?: {
    [key: string]: undefined | string | string[] | number | boolean;
  };
  fields?: {
    [key: string]: string[];
  };
  sort?: string | string[];
  include?: string[];
  page?: number;
  limit?: number;
  meta?:
    | string
    | string[]
    | {
        [key: string]: string | string[];
      };
  token?: string;
  /** Indicates the url should use the shared insert, e.g.: api/v1/shared */
  shared?: boolean;
  invalidate?: string[] | null;
  locale?: string;
  requestAllTranslations?: boolean;
  showStoredFilesCount?: boolean;
};

type THeaders = {
  [key: string]: string;
};

type TInput = {
  baseName: string;
  baseUri: string;
  baseUrl?: string;
  enabled?: boolean;
  invalidate?: string[];
  /**
   * On api Calls all keys in the resourse are converted from camelCase to snakeCase, giving a list
   * of properties names will cause this process to be skipped for those propertie.
   */
  skipPropertyConversion?: string[];
  uri?: string | null;
  data?: object;
  queryParameters?: TQueryParameters;
  /** Indicate this url can use a shared insert */
  shareable?: boolean;
  headers?: THeaders;
  includes?: DefaultIncludes;
};

type Specifier = 'index' | 'show' | 'create' | 'update' | 'destroy';

const _context = Symbol('context');

export default class ApiRequest<TExpected extends IResourceOrCollection = any> {
  public baseName: string;

  public baseUri: string;

  public baseUrl?: string;

  public data?: object;

  public enabled: boolean = true;

  public uri?: string;

  public headers?: THeaders;

  public invalidate: string[];

  public locale?: string;

  public requestAllTranslations?: boolean;

  public skipPropertyConversion?: string[];

  public queryParameters?: TQueryParameters;

  public shareable?: boolean;

  protected readonly [_context]: Partial<IApiContext> = {};

  constructor(input: TInput, context?: Partial<IApiContext>) {
    this.baseName = input.baseName;
    this.baseUrl = withTrailingSlash(input.baseUrl);
    this.baseUri = input.baseUri;
    this.data = input.data;
    this.headers = input.headers;
    this.invalidate =
      input.queryParameters?.invalidate !== undefined
        ? input.queryParameters?.invalidate || []
        : input.invalidate || [];
    this.skipPropertyConversion = input.skipPropertyConversion || [];
    this.queryParameters = {
      include: [
        ...(input.queryParameters?.include || []),
        ...(input.includes?.[this.baseName] || []),
      ],
      ...input.queryParameters,
    };
    this.shareable = input.shareable;
    this.enabled = input.enabled === undefined ? true : input.enabled;
    if (input.uri) {
      this.uri = input.uri;
    }
    if (context) {
      this[_context] = context;
    }

    // eslint-disable-next-line no-constructor-return
    return this;
  }

  /**
   * Returns true when the route does not contain unknown parameters
   */
  isRouteValid(): boolean {
    return !this.getBaseRoute().includes('undefined');
  }

  /**
   * Returns the full route excluding the query parameters
   */
  getBaseRoute(uriSuffix?: string) {
    return (this.baseUrl || '') + this.baseUri + (uriSuffix || '');
  }

  /**
   * The query parameters will be added to the url in a later stage. This function will create the string
   * that is appended to the url for filtering, sorting and including the data.
   */
  queryParametersToString(specifier: Specifier): string {
    const includes = this.getIncludes(specifier);
    const {
      filter,
      sort,
      page,
      limit,
      token,
      fields,
      meta,
      shared,
      locale,
      requestAllTranslations,
      showStoredFilesCount,
    } = this.queryParameters || {};

    return constructUrl('', {
      token,
      shared,
      sort,
      filter,
      meta,
      fields,
      page,
      limit,
      includes,
      locale: this.locale ?? locale,
      requestAllTranslations,
      showStoredFilesCount,
    });
  }

  private getIncludes(specifier?: Specifier) {
    return [
      ...Array.from(
        new Set([
          ...(this.queryParameters?.include || []),
          ...(this[_context]?.includes?.[this.baseName] || []),
          ...(this[_context]?.includes?.[`${this.baseName}.${specifier}`] ||
            []),
        ])
      ),
    ];
  }

  private getMeta() {
    return this.queryParameters?.meta;
  }

  /**
   * Returns the full route including query parameters
   */
  getFullRoute(specifier: Specifier, uriSuffix?: string): string {
    let fullRoute =
      this.getBaseRoute(uriSuffix) + this.queryParametersToString(specifier);

    if (this.shareable && this.queryParameters?.shared)
      fullRoute = fullRoute.replace('/v1/', '/v1/shared/');

    return fullRoute;
  }

  /**
   * Returns a key with which this request can properly be cached.
   * @param specifier Specific specifier for a specific request.
   */
  public getKey(specifier?: Specifier): QueryKey | undefined {
    return [
      {
        [this.baseName]: true,
        ...(specifier ? { [`${this.baseName}.${specifier}`]: true } : {}),
        ...this.getIncludes(specifier).reduce(
          (ac, a) => ({ ...ac, [a]: true }),
          {}
        ),
        ...this.getBaseRoute(this.uri)
          .split('/')
          .reduce(
            (routeSegments, segment) => ({
              ...routeSegments,
              ...(segment !== '' ? { [`/${segment}`]: true } : {}),
            }),
            {}
          ),
      },
      {
        uri: this.getBaseRoute(this.uri),
        queryString: this.queryParametersToString(specifier || 'index'),
      },
    ];
  }

  public fetch(
    specifier: Specifier,
    method: Method,
    uri?: string,
    data?: object | void
  ): Promise<DataDocument<TExpected>> {
    const source = axios.CancelToken.source();
    const promise: Promise<DataDocument> & { cancel?: () => void } = axios
      .request({
        method,
        url: this.getFullRoute(specifier, uri),
        data: {
          ...this.data,
          ...data,
        },
        cancelToken: source.token,
      })
      .then((response) => {
        if (response.status <= 299) {
          try {
            return new DataDocument(
              clone(response, toCamelCase, this.skipPropertyConversion),
              this.skipPropertyConversion
            );
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            // eslint-disable-next-line @typescript-eslint/no-throw-literal
            throw response;
          }
        } else {
          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          throw response;
        }
      })
      .catch((error: AxiosError) => {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw new ErrorDocument(error);
      });
    promise.cancel = () => {
      source.cancel();
    };
    return promise;
  }

  public index() {
    return this.fetch('index', 'get');
  }

  public show() {
    return this.fetch('show', 'get', this.uri);
  }

  public create(data?: any) {
    if (data?._invalidate) {
      this.invalidate = data?._invalidate;
    }
    if (data?._locale) {
      this.locale = data._locale;
    }
    return this.fetch('create', 'post', undefined, data);
  }

  public update(data?: any) {
    const { _method, _invalidate, ...rest } = data;
    if (_invalidate) {
      this.invalidate = _invalidate;
    }

    if (data?._locale) {
      this.locale = data._locale;
    }

    return this.fetch('update', _method || 'put', this.uri, rest);
  }

  public destroy(data?: any) {
    return this.fetch('destroy', 'delete', this.uri, data);
  }
}
