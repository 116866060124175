import { useCallback, useEffect } from 'react';
import { useApi } from '@brainstud/academy-api';
import { useRouter } from 'next/router';
import { GlossaryModal } from '../../Modules/action-based-learning-panel/Modals/GlossaryModal';
import { useModals } from '../../Providers/ModalProvider';

/**
 * useDfnTags.
 *
 * Enables the DFN tags to be opened in the glossary modal.
 */
export function useDfnTags(courseIdProp?: string) {
  const router = useRouter();
  const { courseId: routeCourseId } = router.query as { courseId?: string };
  const { parameters } = useApi();
  const { openModal } = useModals();
  const isEditing = router.asPath.includes('/edit');

  const courseId = courseIdProp || routeCourseId || parameters.course;

  const handleDfnTags = useCallback(
    (e: MouseEvent) => {
      if (e.target instanceof Element) {
        if (e.target.nodeName !== 'DFN') return;
        const glossaryKey = e.target.getAttribute('data-key');
        if (glossaryKey) {
          openModal(GlossaryModal, { glossaryKey, courseId }, 2);
        }
      }
    },
    [openModal, courseId]
  );

  useEffect(() => {
    if (window && !isEditing) {
      window.addEventListener('click', handleDfnTags);
      return () => {
        window.removeEventListener('click', handleDfnTags);
      };
    }
  }, [handleDfnTags, isEditing]);
}
