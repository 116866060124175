import { IResource } from '../Utils/Resource';
import { UUID } from '../Utils/UUID';
import { LearningRouteNode } from './LearningRouteNode';

export type LearningRouteStatus = 'CONCEPT' | 'PUBLISHED';

export type TRouteLayouts = 'action-cards' | 'chapter-cards';

export interface DepreciatedNodeReference {
  id: string;
  type: string;
  attributes: {
    position: number;
  };
}

export interface DepreciatedObjectReference extends DepreciatedNodeReference {
  type: 'learning_objects';
}

export interface DepreciatedSubjectReference extends DepreciatedNodeReference {
  type: 'learning_subjects';
  attributes: {
    position: number;
    madeAssignmentsCount: number;
    totalAssignmentsCount: number;
  };
}

export interface DepreciatedGroupAttributes {
  title: string;
  image?: string;
  introduction: {
    title: string;
    content: string;
  };
  summary: string;
  type: string;
  locked: boolean;
  position: number;
  totalAssignments?: number;
  totalSubjects?: number;
  totalXpAvailable?: number;
  totalXpReceived?: number;
}

export interface DepreciatedGroupNode extends DepreciatedNodeReference {
  id: string;
  type: 'group';
  attributes: DepreciatedGroupAttributes;
  objects: Array<DepreciatedObjectReference | DepreciatedSubjectReference>;
}

export interface LearningRouteNodeSchemeItem {
  id: UUID;
  type: 'learning_objects' | 'learning_subjects';
}

export interface LearningRoute extends IResource<'learning_routes'> {
  /** @depreciated this title isn't used */
  title?: string;
  status?: LearningRouteStatus;
  configuration?: {
    menuTemplate: string;
    version: number;
  };
  /** @depreciated If without progress, use .learningRouteNodes instead */
  nodes?: DepreciatedGroupNode[];
  learningRouteNodes?: () => LearningRouteNode[];
  type: string;
  layout: TRouteLayouts;
}

export interface LearningRouteCreateInput {
  title: string;
  status: LearningRouteStatus;
  type: string;
  learning_object_collection: UUID;
  layout: TRouteLayouts | string;
}

export interface LearningRouteUpdateInput
  extends Partial<
    Omit<LearningRouteCreateInput, 'learning_object_collection'>
  > {
  nodes?: Array<{
    id: UUID;
    scheme: LearningRouteNodeSchemeItem[];
  }>;
}

export interface LearningRouteDuplicate {
  title?: string;
}

export enum LearningRouteIncludes {
  learning_route_nodes,
}

export enum LearningRouteFilters {}

export enum LearningRouteSorts {}
