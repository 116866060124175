import { createContext } from 'react';
import { TwoFactorAuthentication } from '@brainstud/academy-api';

export interface ITwoFactorContext {
  twoFactorAuthentication?: TwoFactorAuthentication;
  setVerificationCode: (val?: string) => void;
  verificationCode?: string;
  recoveryCodes?: string[];
  setRecoveryCodes: (val?: string[]) => void;
  loading: boolean;
  handleTwoFactor: (reRouteTo?: string, otpCode?: string) => void;
  disableTwoFactor: (otpCode: string, reRouteTo?: string) => void;
  setToken: () => void;
  tfaError?: boolean;
  /**  determines if the layout is accessed though `/account` since the the provider is also used on the `/login` route */
  returnTo?: string;
  isAccountPage?: boolean;
  isRegenerating?: boolean;
}

export default createContext<null | ITwoFactorContext>(null);
