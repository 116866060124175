import { UseQueryOptions } from 'react-query';
import { useCreate, useDestroy, useIndex } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  StudentGroupAccount,
  StudentGroupAccountCreateInput,
  StudentGroupAccountDestroyInput,
  UUID,
} from '../Types';

interface IStudentGroupAccountsProps {
  studentGroup?: UUID;
}

export function useStudentGroupAccounts(
  { studentGroup }: IStudentGroupAccountsProps,
  settings?: UseQueryOptions<DataDocument<StudentGroupAccount[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { studentGroup: defaultStudentGroup },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(studentGroup || defaultStudentGroup),
      baseName: 'v1.studentGroups.accounts',
      baseUri: `/v1/student_groups/${studentGroup || defaultStudentGroup}/accounts`,
      invalidate: [
        'student_groups',
        'student_group',
        'student_group.accounts',
        'student_groups.accounts',
        'students',
        'accounts',
        'v1.studentGroups',
        'v1.students',
      ],
    },
    context
  );

  const [data, responseIndex] = useIndex<StudentGroupAccount>(
    request,
    settings
  );

  const create = useCreate<StudentGroupAccount, StudentGroupAccountCreateInput>(
    request
  );

  const destroy = useDestroy<
    StudentGroupAccount,
    StudentGroupAccountDestroyInput
  >(request);

  return [
    {
      data,
      create,
      destroy,
    },
    responseIndex,
  ] as const;
}
