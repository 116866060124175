import { IResource } from '../Utils/Resource';

export type ExamMessage = {
  type: string;
  step: string;
  content: string;
};

export interface ExamContent extends IResource<'exam_content'> {
  configuration: {
    type: string;
    messages: ExamMessage[];
  };
  contentVersion: number;
  status: string;
}

export interface ExamContentCreateInput {
  configuration?: {
    type?: string;
    messages?: ExamMessage[];
  };
  contentVersion?: number;
  status?: string;
}

export interface ExamContentUpdateInput extends ExamContentCreateInput {}

export enum ExamContentIncludes {}

export enum ExamContentFilters {}

export enum ExamContentSorts {}
