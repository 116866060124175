import React, { ChangeEvent, useEffect, useState } from 'react';
import { IVideoBlock } from '@brainstud/course-blocks';
import { Dropdown, Input } from '@brainstud/ui';
import { Grid } from '@mui/material';
import { useTranslator } from 'Providers/Translator';
import VideoBlock from './VideoBlock';

export interface VideoFormProps {
  data?: IVideoBlock;
  onChange: (e: Partial<IVideoBlock>) => void;
}

export const VideoForm = ({ data, onChange }: VideoFormProps) => {
  const [t] = useTranslator();
  const [title, setTitle] = useState(data?.title || '');
  const [src, setSrc] = useState(data?.src || '');
  const [ratio, setRatio] = useState(data?.ratio || [16, 9]);
  const [source, setSource] = useState(data?.source);

  useEffect(() => {
    onChange?.({
      title,
      src,
      ratio: [ratio[0] || 16, ratio[1] || 9],
      source,
    });
  }, [src, ratio, title, source]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Input
          label={t('course-editor.content_editor.blocks.rich_video.src')}
          value={src}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setSrc(e.target.value)
          }
        />
        <VideoBlock title={title || ''} src={src} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <div
          style={{ display: 'flex', height: '100%', flexFlow: 'column nowrap' }}
        >
          <Input
            label={t('course-editor.content_editor.blocks.rich_video.source')}
            value={source}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setSource(e.target.value)
            }
          />
          <Input
            label={t('course-editor.content_editor.blocks.rich_video.title')}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setTitle(e.target.value)
            }
            value={title}
          />
          <Dropdown
            label={t(
              'course-editor.content_editor.blocks.rich_video.ratio.label'
            )}
            onChange={(value) =>
              value && setRatio(value.split(':').map((i) => parseInt(i, 10)))
            }
            value={ratio.join(':')}
          >
            <Dropdown.Option value="16:9">
              {t('course-editor.content_editor.blocks.rich_video.ratio.16:9')}
            </Dropdown.Option>
            <Dropdown.Option value="9:16">
              {t('course-editor.content_editor.blocks.rich_video.ratio.9:16')}
            </Dropdown.Option>
          </Dropdown>
        </div>
      </Grid>
    </Grid>
  );
};
