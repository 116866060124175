import { UseQueryOptions } from 'react-query';
import { useIndexCR, useShow } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  Entity,
  EntityCreateInput,
  EntityFilters,
  EntityIncludes,
  EntitySorts,
  HookParams,
  UUID,
} from '../Types';

interface IEntityProps
  extends HookParams<typeof EntityIncludes, EntityFilters, typeof EntitySorts> {
  entity?: UUID;
}

export function useEntity(
  { entity, ...queryParameters }: IEntityProps = {},
  settings?: UseQueryOptions<DataDocument<Entity>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { entity: defaultEntity },
  } = context;
  const request = new ApiRequest(
    {
      baseName: 'v1.entities',
      baseUri: '/v1/entities',
      uri: `/${entity || defaultEntity}`,
      queryParameters,
    },
    context
  );
  return useShow<Entity>(request, settings);
}

export function useEntities(
  { ...queryParameters }: Omit<IEntityProps, 'entity'> = {},
  settings?: UseQueryOptions<DataDocument<Entity[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.entities',
      baseUri: '/v1/entities',
      queryParameters,
    },
    context
  );
  return useIndexCR<Entity, EntityCreateInput>(request, settings);
}
