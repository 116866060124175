import { IResource } from '../Utils/Resource';
import { UUID } from '../Utils/UUID';
import { Account } from './Account';

export interface ExamAssessor extends IResource<'exam_assessors'> {
  isPrimaryAssessor: boolean;
  account?: () => Account;
  assignedByAccount?: () => Account;
}

export interface ExamAssessorCreateInput {
  account: UUID;
  is_primary_assessor: boolean;
  overwrite?: boolean;
}

export enum ExamAssessorIncludes {
  account,
  assigned_by_account,
}
