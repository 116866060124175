import {
  QueryObserverResult,
  UseMutationResult,
  UseQueryOptions,
} from 'react-query';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import { IResource } from '../Types';
import { UpdateMethods } from '../Types/Utils/UpdateMethods';
import useCreate from './useCreate';
import useDestroy from './useDestroy';
import useShow from './useShow';
import useUpdate from './useUpdate';

type TOutputFormat<
  Resource extends IResource,
  CreateInput,
  UpdateInput,
  DeleteInput,
> = [
  {
    data: Resource | undefined;
    create: UseMutationResult<
      DataDocument<Resource>,
      ErrorDocument,
      CreateInput
    >;
    update: UseMutationResult<
      DataDocument<Resource>,
      ErrorDocument,
      UpdateInput & UpdateMethods
    >;
    createOrUpdate: UseMutationResult<
      DataDocument<Resource>,
      ErrorDocument,
      CreateInput | (UpdateInput & UpdateMethods)
    >;
    destroy: UseMutationResult<
      DataDocument<Resource>,
      ErrorDocument,
      DeleteInput
    >;
  },
  QueryObserverResult<DataDocument<Resource | Resource[]>, ErrorDocument>,
];

export default function useSingleCRUD<
  Resource extends IResource,
  CreateInput,
  UpdateInput = Partial<CreateInput>,
  DeleteInput = void,
>(
  request: ApiRequest,
  settings?: UseQueryOptions<DataDocument<Resource>, ErrorDocument>
): TOutputFormat<Resource, CreateInput, UpdateInput, DeleteInput> {
  const [data, responseShow] = useShow<Resource>(request, settings);

  const create = useCreate<Resource, CreateInput>(request);

  const update = useUpdate<Resource, UpdateInput>(request);

  const destroy = useDestroy<Resource, DeleteInput>(request);

  return [
    {
      data,
      create,
      update,
      createOrUpdate: !request.enabled
        ? (create as unknown as UseMutationResult<
            DataDocument<Resource>,
            ErrorDocument,
            CreateInput | (UpdateInput & UpdateMethods)
          >)
        : (update as unknown as UseMutationResult<
            DataDocument<Resource>,
            ErrorDocument,
            CreateInput | (UpdateInput & UpdateMethods)
          >),
      destroy,
    },
    responseShow,
  ];
}
