/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { PropsWithChildren } from 'react';
import classNames from 'classnames/bind';
import styles from './Card.module.css';

const cx = classNames.bind(styles);

type MediaCardProps = {
  image: string;
  color?: string;
  className?: string;
  style?: React.CSSProperties;
  video?: string;
  ratio: number;
  onClick?: () => void;
};

export const CardMedia = ({
  children,
  image,
  color,
  className,
  style,
  video,
  ratio,
  onClick,
}: PropsWithChildren<MediaCardProps>) => {
  const backgroundStyle = {
    backgroundImage: image ? `url(${image})` : '',
    backgroundColor: color,
  };

  return (
    <div
      className={cx('media', {}, className)}
      style={{ paddingBottom: `${ratio}%`, ...style }}
      onClick={onClick}
    >
      {video && (
        <div className={styles.mediaVideo}>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video autoPlay loop src={video} />
        </div>
      )}
      <div className={styles.mediaBackground} style={backgroundStyle}>
        {children}
      </div>
    </div>
  );
};
