import React, { useCallback, useState } from 'react';
import {
  useExternalAssessment,
  useExternalAssessments,
} from '@brainstud/academy-api';
import { Button, Callout, ContentModal, Input } from '@brainstud/ui';
import { Form } from '@brainstud/universal-components';
import classNames from 'classnames/bind';
import { useModals } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { DateFormatter } from 'Utils/DateFormatHelper';
import styles from './ExternalRatingModal.module.css';

const cx = classNames.bind(styles);

type Props = {
  answerId: string;
};

export const ExternalRatingModal = ({ answerId }: Props) => {
  const [t] = useTranslator();
  const { closeModal } = useModals();

  const [{ create }, { isLoading }] = useExternalAssessment({});
  const [{ data: externalAssessments }] = useExternalAssessments({
    include: ['answer'],
    filter: {
      answer: answerId,
    },
  });

  const [success, setSuccess] = useState(false);
  const handleSubmit = useCallback(
    (formData) =>
      create.mutateAsync(
        {
          email_address: formData.email_address,
          relationships: {
            answer: answerId,
          },
        },
        {
          onSuccess: () => {
            setSuccess(true);
          },
        }
      ),
    [create, answerId]
  );

  return (
    <ContentModal
      onClickOutside
      onClose={closeModal}
      className={cx(styles.base)}
    >
      <h3>{t('components.external_rating_modal.title')}</h3>
      <p>{t('components.external_rating_modal.description')}</p>
      {/* @ts-ignore FIXME: When language property is either redundant or added as a type */}
      <Form
        onSubmit={handleSubmit}
        className={cx(styles.form)}
        language={t('language')}
      >
        <Input
          name="email_address"
          type="text"
          rules={['is_email']}
          label={t('components.external_rating_modal.label')}
          placeholder={t('components.external_rating_modal.placeholder')}
        />
        <Button type="submit" loading={isLoading} className={cx(styles.button)}>
          {t('components.external_rating_modal.send')}
        </Button>
      </Form>
      {success && (
        <Callout success margin="1rem 0">
          {t('components.external_rating_modal.success')}
        </Callout>
      )}
      {externalAssessments.length !== 0 && (
        <div className={cx(styles.invitations)}>
          <strong>{t('components.external_rating_modal.invited')}</strong>
          <div className={cx(styles.assessors)}>
            {externalAssessments?.map((assessor) => (
              <span>
                {`
                  ${assessor.emailAddress}
                  ${
                    assessor.createdAt
                      ? `${t('components.external_rating_modal.on')} ${DateFormatter.toDate(assessor.createdAt, 'dd-MM-yy')}`
                      : ''
                  }
                `}
              </span>
            ))}
          </div>
        </div>
      )}
      <Button className={cx(styles.cancel)} link onClick={() => closeModal()}>
        {t('cancel')}
      </Button>
    </ContentModal>
  );
};
