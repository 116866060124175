import React, { PropsWithChildren } from 'react';
import classNames from 'classnames/bind';
import styles from './Card.module.css';

const cx = classNames.bind(styles);

type CardContentProps = {
  style?: React.CSSProperties;
  className?: string;
};

export const CardContent = ({
  children,
  style,
  className,
}: PropsWithChildren<CardContentProps>) => (
  <div className={cx('content', {}, className)} style={style}>
    {children}
  </div>
);
