import React, { useCallback, useMemo } from 'react';
import { useApi, useCoachMandate, useEnrollment } from '@brainstud/academy-api';
import { Dropdown } from '@brainstud/ui';
import classNames from 'classnames';
import { useTranslator } from 'Providers/Translator';
import styles from '../EnrollmentSettingsForm.module.css';

type TProps = {
  /** Overwrite the default enrollmentId */
  enrollmentId?: string;
  className?: string;
};

const cx = classNames.bind(styles);

export const SettingCoach = ({ enrollmentId, className }: TProps) => {
  const [{ data: enrollment }] = useEnrollment({ enrollment: enrollmentId });
  const coaches = useMemo(() => enrollment?.coaches?.() || [], [enrollment]);
  const currentCoach = coaches.find((coach) => coach.isActiveCoach);

  const { invalidateQueries } = useApi();
  const [{ create: createMandate, destroy }] = useCoachMandate(
    {
      enrollment: enrollmentId,
      coachMandate: enrollment?.coachMandates?.().at(-1)?.id,
    },
    { enabled: false }
  );
  const [t] = useTranslator();

  const handleCoachChange = useCallback(
    (value?: null | string) => {
      if (value) {
        createMandate.mutate(
          { coach: value },
          {
            onSettled: () =>
              Promise.all(
                invalidateQueries(['v1.enrollments', 'enrollment'])
              ).then(),
          }
        );
      } else if (currentCoach) destroy.mutate();
    },
    [createMandate, currentCoach, destroy, invalidateQueries]
  );

  const isLoading = createMandate.isLoading || destroy.isLoading;

  return (
    <div className={cx(styles.setting, className)}>
      <h4>{t('menu.coach')}</h4>
      <Dropdown
        placeholder={
          !currentCoach?.id ? t('components.setting_coach.select_coach') : ''
        }
        loading={isLoading}
        searchable={coaches.length > 3}
        onChange={handleCoachChange}
        defaultValue={currentCoach?.id}
        block
      >
        {coaches?.map((coach) => (
          <Dropdown.Option key={coach.id} value={coach.id}>
            {`${coach.firstName || ''} ${coach.lastNameInsertion || ''} ${coach.lastName || ''}`}
          </Dropdown.Option>
        ))}
      </Dropdown>
    </div>
  );
};
