import React, { PropsWithChildren } from 'react';
import classNames from 'classnames/bind';
import { SimpleContainer } from '../SimpleContainer/SimpleContainer';
import styles from './Spacing.module.css';

const cx = classNames.bind(styles);

type Props = {
  type?: string;
  className?: string;
  wideness?: 'medium' | 'large' | 'max' | string;
  implicit?: boolean;
  explicit?: boolean;
  style?: React.CSSProperties;
};

const maxWidthBlocks = [
  'rich_image',
  'rich_video',
  'panel_container',
  'tabbar_container',
  'slider_container',
];

/**
 * Spacing.
 *
 * Handles the spacing between blocks. Next to that, it applies the styling provided by the author.
 */
export const Spacing = ({
  type,
  wideness,
  className,
  implicit,
  style,
  children,
  explicit,
}: PropsWithChildren<Props>) => {
  const defaultWideness =
    type && maxWidthBlocks.includes(type) ? 'max' : 'medium';
  const actualWideness =
    wideness && wideness !== 'auto' ? wideness : defaultWideness;
  const isPredefinedWidth = ['medium', 'large', 'max'].includes(actualWideness);
  const widthClassName = isPredefinedWidth ? actualWideness : '';
  const width = !isPredefinedWidth ? { width: actualWideness } : {};

  const { backgroundColor, ...spacingStyle } = style || {};
  const { paddingTop, paddingBottom, ...restStyle } = style || {};

  return (
    <div
      className={cx(
        styles.base,
        'spacing-component',
        widthClassName,
        `spacing-type__${actualWideness}`,
        {
          'implicit-wideness': !explicit && (!wideness || implicit),
        },
        className
      )}
      style={{ ...width, ...spacingStyle }}
    >
      <SimpleContainer style={restStyle}>{children}</SimpleContainer>
    </div>
  );
};
