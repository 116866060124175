import { useContext } from 'react';
import LearningRouteEditorContext, {
  ILearningRouteEditorContext,
} from './LearningRouteEditorContext';

/**
 * useLearningRouteEditorProvider.
 *
 * @param nullable When true, this hook returns null when no context is found.
 */
export function useLearningRouteEditorProvider(
  nullable?: false
): ILearningRouteEditorContext;
export function useLearningRouteEditorProvider(
  nullable?: true
): null | ILearningRouteEditorContext;
export function useLearningRouteEditorProvider(nullable = false) {
  const context = useContext(LearningRouteEditorContext);
  if (!context && !nullable) {
    throw new Error('Please, initiate the LearningRouteEditorProvider first!');
  }

  return context;
}
