import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, ConfirmationModal } from '@brainstud/ui';
import { useForm } from '@brainstud/universal-components';
import { RateReviewOutlined as RateReview, Save } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Tips } from 'Modules/action-based-learning-panel/Components';
import { ExternalRatingModal } from 'Modules/action-based-learning-panel/Modals/ExternalRatingModal';
import { useContentEditor } from 'Modules/course-editor';
import {
  useLearningObjectProvider,
  useLearningRouteProvider,
  useLearningSubjectProvider,
  useModals,
} from 'Providers';
import { useContentBlocksProvider } from 'Providers/ContentBlocksProvider';
import { useTranslator } from 'Providers/Translator';
import { AssignmentModal, TAssignmentModalData } from 'Views/Courses';
import { useActualObjectLayoutName } from './useActualObjectLayoutName';
import styles from './ObjectHeader.module.css';

const cx = classNames.bind(styles);

type Props = {
  editable?: boolean;
};

export const ObjectHeader = ({ editable }: Props) => {
  const [t] = useTranslator();
  const { subscribe, save, disabled } = useForm();
  const { isStatic, states: blockStates } = useContentBlocksProvider();
  const { learningObject, variety, hasEnrollment } =
    useLearningObjectProvider();
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const { openModal, closeModal } = useModals();

  const { layout: routeLayout, learningRoute } = useLearningRouteProvider();
  const {
    layout: panelLayout,
    setLayout: setPanelLayout,
    isUnsaved,
    save: saveContent,
  } = useContentEditor();

  const { quiz } = useLearningSubjectProvider();
  const isQuiz = !!quiz;

  const isSavable = useMemo(
    () => !disabled && blockStates.some((block) => block.touched),
    [disabled, blockStates]
  );

  const handleConceptSave = useCallback(() => {
    setSaving(true);
    save(
      { status: 'CONCEPT' },
      {
        onSettled: () => setSaving(false),
        onSuccess: () => setSaved(true),
      }
    );
  }, [save]);

  useEffect(() => {
    if (!saved) return;
    const unsubscribe = subscribe('change', () => {
      setSaved(false);
    });
    return () => {
      unsubscribe();
    };
  }, [saved, subscribe]);

  // External rating
  const externalRating =
    learningObject?.metadata?.configuration?.allowExternalRating;
  const lastAnswer = learningObject?.lastAnswer?.();

  const handleAddExternalRating = useCallback(() => {
    openModal(ExternalRatingModal, { answerId: lastAnswer?.id });
  }, [openModal, lastAnswer]);

  const tips = !Array.isArray(variety?.content)
    ? variety?.content?.tips
    : variety?.tips;

  const handleOpenLearningObjectModal = useCallback(() => {
    const modalProps: TAssignmentModalData = {
      isQuiz,
      routeLayout,
      isTheory: learningObject?.baseType === 'theory',
      learningRouteId: learningRoute?.id,
    };
    const openAssigmentModal = () => openModal(AssignmentModal, modalProps);

    if (isUnsaved) {
      openModal(ConfirmationModal, {
        title: t('views.collection.object.header.unsaved.title'),
        description: t('views.collection.object.header.unsaved.description'),
        yes: t('views.collection.object.header.unsaved.confirm'),
        no: t('cancel'),
        handleConfirm: () => {
          saveContent();
          closeModal();
          openAssigmentModal();
        },
      });
    } else {
      openAssigmentModal();
    }
  }, [
    isUnsaved,
    openModal,
    learningObject,
    routeLayout,
    learningRoute?.id,
    isQuiz,
    t,
    saveContent,
    closeModal,
  ]);

  const isLinear = useActualObjectLayoutName() === 'linear';
  const isPanel = useActualObjectLayoutName() === 'panel';

  return learningObject ? (
    <header className={cx(styles.base, { linear: isLinear })}>
      <h2 className={cx(styles.title)}>{learningObject.title}</h2>
      <div className={cx(styles['button-bar'])}>
        {tips && <Tips>{tips}</Tips>}
        {!editable ? (
          <>
            {externalRating && !isStatic && (
              <Button
                type="button"
                quiet
                disabled={!lastAnswer}
                onClick={handleAddExternalRating}
                className={cx({ 'is-disabled': !lastAnswer })}
              >
                <RateReview />
                <span>
                  {t('views.collection.object.header.request_external_rating')}
                </span>
              </Button>
            )}
            {!isStatic && hasEnrollment && false && (
              <Button
                type="button"
                quiet={!saving && !saved}
                loading={saving}
                disabled={!isSavable && !saving}
                success={!saving && saved}
                error={!saving && !saved}
                onClick={handleConceptSave}
                className={cx('button-concept-save', {
                  'is-disabled': !isSavable,
                })}
              >
                <Save />
                <span>{t('views.collection.object.header.save')}</span>
              </Button>
            )}
          </>
        ) : (
          <div className={cx(styles.editable)}>
            {isPanel && (
              <div className={cx(styles.layoutToggle)}>
                <strong>
                  {t('course-editor.content_editor.toggle_layout')}
                </strong>
                <button
                  type="button"
                  className={cx({ isSelected: panelLayout === 'columns' })}
                  disabled={panelLayout === 'columns'}
                  onClick={() => setPanelLayout('columns')}
                >
                  <svg
                    width="21"
                    height="19"
                    viewBox="0 0 21 19"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0.414062 1.287L0.414062 17.8895C0.414062 18.5028 0.94234 19 1.594 19L7.72183 19C8.37349 19 8.90177 18.5028 8.90177 17.8895L8.90177 1.287C8.90177 0.673671 8.37349 0.176469 7.72183 0.176469L1.594 0.17647C0.942339 0.17647 0.414062 0.673672 0.414062 1.287Z" />
                    <path d="M11.9258 1.287L11.9258 17.8895C11.9258 18.5028 12.4541 19 13.1057 19L19.2336 19C19.8852 19 20.4135 18.5028 20.4135 17.8895L20.4135 1.287C20.4135 0.673671 19.8852 0.176469 19.2335 0.176469L13.1057 0.17647C12.4541 0.17647 11.9258 0.673672 11.9258 1.287Z" />
                  </svg>
                </button>
                <button
                  type="button"
                  className={cx({ isSelected: panelLayout === 'rows' })}
                  disabled={panelLayout === 'rows'}
                  onClick={() => setPanelLayout('rows')}
                >
                  <svg
                    width="19"
                    height="20"
                    viewBox="0 0 19 20"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M17.713 0H1.11053C0.497202 0 0 0.528278 0 1.17994V7.30777C0 7.95943 0.497202 8.48771 1.11053 8.48771H17.713C18.3263 8.48771 18.8235 7.95943 18.8235 7.30777V1.17994C18.8235 0.528278 18.3263 0 17.713 0Z" />
                    <path d="M17.713 12H1.11053C0.497202 12 0 12.5283 0 13.1799V19.3078C0 19.9594 0.497202 20.4877 1.11053 20.4877H17.713C18.3263 20.4877 18.8235 19.9594 18.8235 19.3078V13.1799C18.8235 12.5283 18.3263 12 17.713 12Z" />
                  </svg>
                </button>
              </div>
            )}
            <Button
              link
              quiet
              onClick={handleOpenLearningObjectModal}
              type="button"
            >
              {t('views.collection.object.header.edit')}
            </Button>
          </div>
        )}
      </div>
    </header>
  ) : null;
};
