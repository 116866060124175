/* eslint-disable no-param-reassign */
import React, { useRef } from 'react';
import {
  useDrag,
  useDrop,
  useFormField,
} from '@brainstud/universal-components';
import { Check, Clear, DragIndicator } from '@mui/icons-material';
import classNames from 'classnames/bind';
import type { Identifier } from 'dnd-core';
import styles from './DragToSortQuestion.module.css';

const cx = classNames.bind(styles);

interface DragOptionProps {
  correctPosition: number;
  id: string;
  index: number;
  label: string;
  onMove: (dragId: string, hoverIndex: number) => void;
  onDrop?: () => void;
}

interface DragItem {
  index: number;
  id: string;
}

const ItemType = 'option';

const DragOption = ({
  id,
  label,
  index,
  correctPosition,
  onMove,
  onDrop,
}: DragOptionProps) => {
  const name = id;

  const ref = useRef<HTMLDivElement>(null);

  const { valid } = useFormField<string>({
    id,
    name,
    defaultValue: `${index}`,
    rules: [`equals:${correctPosition}`],
  });

  const [{ isDragging }, drag] = useDrag<
    DragItem,
    void,
    { isDragging: boolean }
  >({
    type: ItemType,
    item: () => ({ id, index }),
    /**
     * It could happen the drop is not correctly registered. This could be because a
     * user is dropping outside the container or anything which might mess up the drop registration.
     * And, as we're already moving the items on hover, the visual state
     * and the actual state can be mismatched. So, as a solution, we perform the drop action when the item is
     * finished dragging. But only if no registration has taken place.
     */
    end: (_, monitor) => {
      const didDrop = monitor.didDrop();
      if (!didDrop) onDrop?.();
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    undefined,
    { handlerId: Identifier | null }
  >({
    accept: ItemType,
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover(item, monitor) {
      if (!ref.current) return;
      const dragId = item.id;
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex !== hoverIndex) {
        const hoverBoundingRect = ref?.current?.getBoundingClientRect();
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const clientOffset = monitor.getClientOffset()!;
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

        onMove(dragId, hoverIndex);
        item.index = hoverIndex;
      }
    },
    drop: () => {
      onDrop?.();
      return undefined;
    },
  });

  drag(drop(ref));

  return (
    <div
      ref={ref}
      className={cx('drag-option', {
        isDragging,
        isValid: valid,
        isInvalid: valid === false,
      })}
      data-handler-id={handlerId}
    >
      {valid === undefined && <DragIndicator />}
      {valid && <Check />}
      {valid === false && <Clear />}
      {label}
    </div>
  );
};

export default DragOption;
