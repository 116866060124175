import { IResource } from '../Utils/Resource';
import { UUID } from '../Utils/UUID';
import { MinimalAccount } from './Account';

export interface Comment<T extends TCommentableType = TCommentableType>
  extends IResource<'comment'> {
  content: string;
  type: T;
  commentableType: TCommentableType;
  createdAt: string;
  updatedAt: string;
  account?: () => MinimalAccount;
  comments?: () => Comment<TCommentableType>[];
}

export interface CommentCreateInput {
  content: string;
  relationships: {
    commentable: { id: UUID; type: TCommentableType };
  };
}

export interface CommentUpdateInput {
  _method: 'patch';
  content: string;
}

export enum CommentIncludes {
  account,
  comments,
}

export interface CommentFilters {
  commentable_type: TCommentableType;
  answer_feedback: string;
  comment: string;
  rating: string;
  answer: string;
}

export type TCommentableType =
  | 'answer'
  | 'rating'
  | 'comment'
  | 'answer_feedback';

export enum CommentSorts {}
