import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest } from '../Support';
import {
  EducationLevel,
  EducationLevelCreateInput,
  EducationLevelFilters,
  EducationLevelIncludes,
  EducationLevelSorts,
  EducationLevelUpdateInput,
  HookParams,
  UUID,
} from '../Types';

interface IEducationLevelProps
  extends HookParams<
    typeof EducationLevelIncludes,
    typeof EducationLevelFilters,
    typeof EducationLevelSorts
  > {
  educationLevel?: UUID;
}

export function useEducationLevel({
  educationLevel,
  ...queryParameters
}: IEducationLevelProps = {}) {
  const context = useApi();
  const {
    parameters: { educationLevel: defaultEducationLevel },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(educationLevel || defaultEducationLevel),
      baseName: 'v1.education_levels',
      baseUri: '/v1/education_levels',
      invalidate: ['education_level', 'education_levels'],
      uri: `/${educationLevel || defaultEducationLevel}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    EducationLevel,
    EducationLevelCreateInput,
    EducationLevelUpdateInput
  >(request);
}

export function useEducationLevels({
  ...queryParameters
}: Omit<IEducationLevelProps, 'educationLevel'> = {}) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.education_levels',
      baseUri: '/v1/education_levels',
      invalidate: ['education_level', 'education_levels'],
      queryParameters,
    },
    context
  );
  return useIndexCR<EducationLevel, EducationLevelCreateInput>(request);
}
