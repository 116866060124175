import type { ScopeContext } from '@sentry/types';
import { createReactReducer } from 'Utils/createReactReducer';
import { ACTIONS } from './ErrorDataActions';

export interface IErrorDataState extends Partial<ScopeContext> {}

type TAddContextAction = {
  type: typeof ACTIONS.ADD_EXTRA;
  payload: Record<string, any>;
};
type TRemoveContextAction = {
  type: typeof ACTIONS.REMOVE_EXTRA;
  payload: Record<string, any>;
};

export type TErrorDataActions = TAddContextAction | TRemoveContextAction;

export const ErrorDataReducer = createReactReducer<
  IErrorDataState,
  TErrorDataActions
>({
  [ACTIONS.ADD_EXTRA]: (action, state) => ({
    ...state,
    extra: {
      ...(state.extra || {}),
      ...action.payload,
    },
  }),
  [ACTIONS.REMOVE_EXTRA]: (action, state) => ({
    ...state,
    extra: state.extra
      ? Object.keys(state.extra).reduce((result, property) => {
          if (!(property in action.payload)) {
            // eslint-disable-next-line no-param-reassign
            result[property] = state.extra?.[property];
          }
          return result;
        }, {})
      : {},
  }),
});
