/**
 * removePropertyFromObject.
 *
 * It removes a property. From an object.
 */
export function removePropertyFromObject<O extends object, A extends keyof O>(
  key: A,
  object: O
): Omit<O, A> {
  const { [key]: removedKey, ...objWithoutKey } = object || {};
  return objWithoutKey;
}
