import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  Order,
  OrderCreate,
  OrderFilters,
  OrderIncludes,
  OrderSort,
  OrderUpdate,
  UUID,
} from '../Types';

interface IOrderProps
  extends HookParams<typeof OrderIncludes, OrderFilters, typeof OrderSort> {
  order?: UUID;
}

export function useOrder(
  { order, ...queryParameters }: IOrderProps = {},
  settings?: UseQueryOptions<DataDocument<Order>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { order: defaultOrder },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(order || defaultOrder),
      baseName: 'v1.orders',
      baseUri: '/v1/orders',
      invalidate: ['order', 'orders', 'v1.accounts.orders'],
      uri: `/${order}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<Order, OrderCreate, OrderUpdate>(request, settings);
}

export function useOrders(
  { ...queryParameters }: Omit<IOrderProps, 'order'> = {},
  settings?: UseQueryOptions<DataDocument<Order[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.orders',
      baseUri: '/v1/orders',
      invalidate: ['order', 'orders', 'v1.accounts.orders'],
      queryParameters,
    },
    context
  );
  return useIndexCR<Order, OrderCreate>(request, settings);
}
