import React, { FC } from 'react';
import { sanitizer } from 'Utils/Sanitizer';
import styles from './PreviewLearningSubject.module.css';

type TProps = {
  title?: string;
  poster?: string;
  introduction?: string;
};

/**
 * Shows a simple preview of the learning subject
 */
export const PreviewLearningSubject: FC<TProps> = ({
  title,
  poster,
  introduction,
}) => (
  <div className={styles.base}>
    <figure>
      <img src={poster} alt="" />
    </figure>
    <div className={styles.content}>
      <h3>{title}</h3>
      <div
        className={styles.excerpt}
        dangerouslySetInnerHTML={{ __html: sanitizer(introduction, 'unstyle') }}
      />
    </div>
  </div>
);
