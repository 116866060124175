import React, { useCallback } from 'react';
import { Button } from '@brainstud/ui';
import { CopyAll, RemoveRedEye } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { useModals, useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { LongTextModal } from './LongTextModal';
import styles from './LongTextWrapper.module.css';

const cx = classNames.bind(styles);

type TProps = {
  text: string;
  length?: number;
  /** Adds button making it easy to copy the long text */
  copyable?: boolean;
};

/**
 * Wraps long texts by slicing it to a specific length and makes the original long text visible in a modal.
 */
export const LongTextWrapper = ({ text, length = 25, copyable }: TProps) => {
  const { openModal, closeModal } = useModals();
  const [setToast] = useToaster();
  const [t] = useTranslator();

  const shortText = text.slice(0, length);

  const handleShow = useCallback(() => {
    openModal(LongTextModal, { text, closeModal, preformatted: true });
  }, [text, closeModal, openModal]);

  const handleCopy = useCallback(async () => {
    await navigator.clipboard.writeText(text);
    setToast(t('components.long_text_wrapper.copy'), 'success');
  }, [setToast, t, text]);

  return (
    <div className={cx(styles.base)}>
      <span>{shortText}</span>
      <Button type="button" link quiet onClick={handleShow}>
        <RemoveRedEye fontSize="large" />
      </Button>
      {copyable && (
        <Button type="button" link quiet onClick={handleCopy}>
          <CopyAll fontSize="large" />
        </Button>
      )}
    </div>
  );
};
