import React, {
  MouseEventHandler,
  PropsWithChildren,
  useCallback,
} from 'react';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';
import styles from './TrThTd.module.css';

const classes = classNames.bind(styles);

interface Props extends React.ComponentProps<'tr'> {
  /** An anchor tag to an internal route */
  to?: string;
  /** An anchor tag to an external route */
  href?: string;
  /** Sets the target of the URL, e.g. _blank */
  target?: string;
  /** Indents the first cell to look like a 'child' row */
  indent?: number;
}

/**
 * The `Tr` component makes it easier to make an entire row clickable with nice styling
 */
export const Tr = ({
  href,
  to,
  onClick,
  target,
  className,
  style,
  children,
  indent,
  ...props
}: PropsWithChildren<Props>) => {
  const router = useRouter();
  const handleClick = useCallback<MouseEventHandler<HTMLTableRowElement>>(
    (event) => {
      if (!event.isPropagationStopped()) {
        if (href) {
          if (target) {
            window.open(href, target);
          } else {
            window.location.href = href;
          }
        } else if (to) {
          router.push(to);
        }
        onClick?.(event);
      }
    },
    [href, to, onClick, target, router]
  );
  return (
    <tr
      onClick={handleClick}
      className={classes(
        styles.base,
        'ui-tr__base',
        {
          clickable: to || href || onClick,
          'indent-1': indent === 1,
          'indent-2': indent === 2,
          'indent-3': indent === 3,
          'indent-4': indent === 4,
          'indent-5': indent === 5,
          'indent-6': indent === 6,
        },
        className
      )}
      style={{
        ...style,
        ...(indent !== undefined
          ? { paddingLeft: `${indent * 3 + 1}rem` }
          : {}),
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </tr>
  );
};
