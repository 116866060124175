import {
  DateTimeString,
  IResource,
  Portal,
  UUID,
} from '@brainstud/academy-api';

export enum AnnouncementType {
  DANGER = 'danger',
  WARNING = 'warning',
  INFO = 'info',
}

export interface Announcement extends IResource<'announcements'> {
  title: string;
  description: string;
  from?: DateTimeString;
  until?: DateTimeString;
  roles: string[];
  type: AnnouncementType;
  /* A record of available translations, only for admins. */
  titleTranslations?: Record<string, string>;
  /* A record of available translations, only for admins. */
  descriptionTranslations?: Record<string, string>;
  portal?: () => Portal;
  updatedAt: DateTimeString;
}

export interface AnnouncementCreateInput {
  titles: Record<string, string>;
  descriptions: Record<string, string>;
  type: AnnouncementType;
  until?: DateTimeString;
  from?: DateTimeString;
  roles?: string[];
  relationships?: {
    portal?: UUID;
  };
}

export interface AnnouncementUpdateInput extends AnnouncementCreateInput {}

export enum AnnouncementIncludes {
  'portal',
}

export enum AnnouncementSorts {
  'created_at',
  'type',
}

export interface AnnouncementFilters {
  type: string;
  portal: UUID;
  active: boolean;
}
