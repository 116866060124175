export default class VideoLink {
  readonly link: string;

  readonly isYoutube: boolean;

  readonly isVimeo: boolean;

  constructor(link: string) {
    this.link = link;
    this.isYoutube = !link
      ? false
      : link.includes('youtube') || link.includes('youtu.be');
    this.isVimeo = !link ? false : link.includes('vimeo');
  }

  /**
   * Regex explained: https://regex101.com/r/xHd2m8/1
   * Tested on:
   * https://www.youtube.com/watch?v=this_is-theid234
   * https://youtu.be/this_is-theid234
   * https://www.youtube.com/embed/this_is-theid234
   * https://player.vimeo.com/video/133713371
   * https://vimeo.com/133713371/0c8c0d4ce1
   * @returns {string}
   */
  getVideoId() {
    const [id] =
      /(\.be\/|[?&](v=)|embed\/|vimeo.com\/(video\/)?)([a-zA-Z0-9\-_]*)/
        .exec(this.link)
        ?.slice(-1) || [];
    return id;
  }

  getEmbedLink() {
    if (this.isYoutube) {
      const id = this.getVideoId();
      return `https://www.youtube.com/embed/${id}`;
    }
    if (this.isVimeo) {
      const id = this.getVideoId();
      return `https://player.vimeo.com/video/${id}?color=ff7300&title=0&byline=0&portrait=0`;
    }
    return this.link;
  }

  getThumbnail() {
    if (this.isYoutube) {
      const id = this.getVideoId();
      return `https://img.youtube.com/vi/${id}/0.jpg`;
    }
    if (this.isVimeo) {
      return '/media/brain_no_image.svg';
    }
    return this.link;
  }
}
