import React, { useMemo } from 'react';
import { useCourseEventCalendars } from '@brainstud/academy-api';
import {
  BusinessCenter,
  Dehaze,
  EmojiEvents,
  Event,
  MenuBook,
  Photo,
  Settings,
} from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Container, Link, Tabs, TabsItem } from 'Components';
import { useEnrollmentByCourseId, useUserData } from 'Hooks';
import { useRouter } from 'next/router';
import { useLearningRouteProvider } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { ReactComponent as ContentPaste } from '../../Assets/svgs/content_paste.svg';
import styles from './CourseNavigation.module.css';

const cx = classNames.bind(styles);

/**
 * CourseNavigation.
 *
 * Shows the menu structure at the top of the course view.
 */
export const CourseNavigation = () => {
  const [t] = useTranslator();
  const [{ roles }] = useUserData();
  const { learningObjectCollection: collection } = useLearningRouteProvider();
  const { enrollment, course } = useEnrollmentByCourseId();
  const [{ data: calendars }] = useCourseEventCalendars({ course: course?.id });
  const toolkits = useMemo(() => collection?.toolkits?.() || [], [collection]);
  const glossary = course?.useGlossary;
  const exam = useMemo(() => enrollment?.exam?.(), [enrollment]);
  const hasExam = exam?.currentStep === 'IN_PROGRESS';
  const router = useRouter();
  const basePath = `/courses/${router.query.courseId}`;
  const isDetailPath = [
    '/courses/[courseId]',
    '/courses/[courseId]/subject',
    '/courses/[courseId]/detail',
    '/courses/[courseId]/collections/[collectionId]',
    '/courses/[courseId]/collections/[collectionId]/subject',
    '/courses/[courseId]/collections/[collectionId]/detail',
  ].some(
    (item, idx) =>
      router.pathname === item || (idx > 0 && router.pathname.includes(item))
  );

  const scopedToolkits = toolkits.filter(
    (toolkit) => !toolkit.requiredRole || roles?.includes(toolkit.requiredRole)
  );

  return (
    <nav className={cx(styles.base)}>
      <Container>
        <Tabs className={cx(styles.tabs)}>
          <div className={cx(styles.main)}>
            {hasExam && exam && (
              <TabsItem component={Link} href={`/exam/${exam.id}`}>
                <EmojiEvents />
                <span>{t('menu.exam')}</span>
              </TabsItem>
            )}
            <TabsItem
              component={Link}
              href={`${basePath}`}
              active={isDetailPath}
              exact
            >
              <ContentPaste />
              <span>{t('menu.assignments')}</span>
            </TabsItem>
            <TabsItem component={Link} href={`${basePath}/portfolio`}>
              <div style={{ display: 'flex' }} className={cx(styles.icon)}>
                <Photo />
                <Dehaze />
              </div>
              <span>{t('menu.portfolio')}</span>
            </TabsItem>
            {scopedToolkits.length > 0 && !hasExam && (
              <TabsItem component={Link} href={`${basePath}/toolkit`}>
                <BusinessCenter />
                <span>{t('menu.toolkit')}</span>
              </TabsItem>
            )}
            {glossary && !hasExam && (
              <TabsItem component={Link} href={`${basePath}/glossary`}>
                <MenuBook />
                <span>{t('menu.glossary')}</span>
              </TabsItem>
            )}
            {calendars.length > 0 && (
              <TabsItem component={Link} href={`${basePath}/events`}>
                <Event fontSize="large" />
                <span>{t('menu.events')}</span>
              </TabsItem>
            )}
          </div>

          {enrollment && (
            <div className={styles.extra}>
              <TabsItem
                component={Link}
                href={`${basePath}/settings`}
                className={cx(styles.settings)}
              >
                <span>{t('menu.settings')}</span>
                <Settings fontSize="large" />
              </TabsItem>
            </div>
          )}
        </Tabs>
      </Container>
    </nav>
  );
};
