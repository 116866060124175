import React, { CSSProperties, ReactNode } from 'react';
import classNames from 'classnames/bind';
import styles from './Container.module.css';

const classes = classNames.bind(styles);

type TProps = {
  id?: string;
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
  wide?: boolean;
  /* Will default be medium */
  size?: 'small' | 'large';
  /** Adds default margin-top and margin-bottom */
  gap?: boolean;
  /** Adds default padding-top and padding-bottom */
  pad?: boolean;
  as?: React.ElementType;
};

export const Container = ({
  id,
  style,
  className,
  children,
  as: Element = 'div',
  wide = false,
  size,
  gap,
  pad,
}: TProps) => (
  <Element
    id={id}
    style={style}
    className={classes(styles.base, 'layout-container__base', className, {
      wide,
      size,
      gap,
      pad,
    })}
  >
    {children}
  </Element>
);
