import { useContext } from 'react';
import { AnswerGroupContext, IAnswerGroupContext } from './AnswerGroupContext';

/**
 * useAnswerGroupProvider.
 *
 * [DESCRIPTION]
 *
 * @param nullable When true, this hook returns null when no context is found.
 */
export function useAnswerGroupProvider(nullable?: false): IAnswerGroupContext;
export function useAnswerGroupProvider(
  nullable?: true
): Partial<IAnswerGroupContext> | IAnswerGroupContext;
export function useAnswerGroupProvider(nullable = false) {
  const context = useContext(AnswerGroupContext);

  if (!context && !nullable) {
    throw new Error('Please, initiate the AnswerGroupProvider first!');
  }

  return context || {};
}
