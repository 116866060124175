export const FlowTranslations = {
  en: {
    next: 'Go to next step',
    previous: 'Go back',
  },
  nl: {
    next: 'Ga naar volgende stap',
    previous: 'Ga terug',
  },
};
