import { createContext, Dispatch, SetStateAction } from 'react';
import {
  Account,
  Answer,
  ExternalAssessment,
  LearningObject,
  LearningObjectVariety,
  UUID,
} from '@brainstud/academy-api';

export type ReviewItem = {
  learningObjectId: UUID;
  enrollmentId: UUID;
};

export interface IAnswerReviewContext {
  isLoading: boolean;
  index: number;
  reviewSet: ReviewItem[];
  nextItem?: ReviewItem;
  previousItem?: ReviewItem;
  currentItem?: ReviewItem;
  isFirst: boolean;
  isLast: boolean;
  origin?: string;
  answer?: Answer;
  answers: Answer[];
  variety?: LearningObjectVariety;
  learningObject?: LearningObject;
  account?: Partial<Account>;
  externalRating?: ExternalAssessment;
  answerId?: string;
  enrollmentId?: string;
  setAnswerId: Dispatch<SetStateAction<IAnswerReviewContext['answerId']>>;
  collapsed: boolean;
  setCollapsed: Dispatch<SetStateAction<IAnswerReviewContext['collapsed']>>;
  baseUri: string;
}

export default createContext<null | IAnswerReviewContext>(null);
