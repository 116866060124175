import React, { PropsWithChildren } from 'react';
import { AnswerReviewNavigation } from '@brainstud/coach-panel/Views/Review';
import { LearningObjectProvider, useHeadProvider } from 'Providers';
import { ContentBlocksProvider } from 'Providers/ContentBlocksProvider';
import { AnswerReviewLayout } from './AnswerReviewLayout';

type Props = {
  title?: string;
};

/**
 * ReviewLayout.
 *
 * Contains the main layout of the review pages.
 */
export const ReviewLayout = ({ title, children }: PropsWithChildren<Props>) => {
  useHeadProvider(title || 'titles.coach.review.main');

  return (
    <LearningObjectProvider>
      <ContentBlocksProvider>
        <AnswerReviewLayout>
          <AnswerReviewNavigation />
          {children}
        </AnswerReviewLayout>
      </ContentBlocksProvider>
    </LearningObjectProvider>
  );
};
