import React, { CSSProperties, ReactNode } from 'react';
import classNames from 'classnames/bind';
import styles from './VerticalTabList.module.css';

const cx = classNames.bind(styles);

type Props = {
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
};

export const VerticalTabList = ({ className, style, children }: Props) => (
  <div className={cx('navigation', className)} style={style}>
    {children}
  </div>
);
