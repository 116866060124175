export default {
  'v1.learningObjectCollections.show': [
    'learning_routes',
    'learning_routes.learning_route_nodes',
    'learning_objects',
    'learning_subjects',
    'learning_objects.varieties',
  ],
  'v1.learningRoutes': ['learning_route_nodes'],
  'v1.enrollments.learningObjectCollections': [
    'learning_route',
    'learning_subjects',
    'learning_objects',
    'education_levels',
    'toolkits',
    'learning_subjects.learning_objects',
    'learning_objects.nfc_tags',
  ],
  'v1.enrollments.learningObjects': [
    'answers',
    'answers.uploads',
    'answers.external_assessments',
    'answers.external_assessments.stored_files',
    'nfc_tags',
    'answers.feedback.account',
    'answers.feedback.files',
  ],
  'v1.learningObjects': ['varieties'],
  'v1.toolkits': ['toolkitCategories', 'toolkitCategories.toolkitItems'],
  'v1.exams': [
    'exam_content_variety',
    'uploads',
    'sample_answers',
    'step_approvals',
    'assessment',
  ],
  'v1.eventCalendars': ['items', 'items.current_user_enrollment'],
};
