import React, { useState } from 'react';
import { useLearningObjectCollectionDossierCoreTasks } from '@brainstud/academy-api';
import { Callout, Panel } from '@brainstud/ui';
import classNames from 'classnames';
import { Container, FlexContainer, Loading, Tabs } from 'Components';
import { TabItem } from 'Components/Tabs/Tabs';
import { useRouter } from 'next/router';
import { useTranslator } from 'Providers/Translator';
import { LinkedLearningObjectsTable } from './LinkedLearningObjectsTable';
import styles from './CourseQualificationsView.module.css';

const cx = classNames.bind(styles);

export const CourseQualificationsView = () => {
  const router = useRouter();
  const [showWorkProcesses, setShowWorkProcesses] = useState(true);
  const { collectionId } = router.query as { collectionId: string };
  const [t, { ucf }] = useTranslator();

  const [coreTasks, { isLoading }] =
    useLearningObjectCollectionDossierCoreTasks({
      learningObjectCollection: collectionId,
      include: [
        'workprocesses',
        'knowledge_skills',
        'workprocesses.learning_objects',
        'knowledge_skills.learning_objects',
      ],
    });

  return isLoading ? (
    <Loading />
  ) : (
    <Container className={cx(styles.base)}>
      {coreTasks.length > 0 ? (
        <Panel className={cx(styles.content, styles.panel)}>
          <FlexContainer row>
            <Tabs className={cx(styles.tabs)} vertical>
              <TabItem
                active={showWorkProcesses}
                onClick={() => setShowWorkProcesses(true)}
                label={t('views.courses.qualifications.workprocesses')}
              />
              <TabItem
                active={!showWorkProcesses}
                onClick={() => setShowWorkProcesses(false)}
                label={t('views.courses.qualifications.knowledge_and_skills')}
              />
            </Tabs>
            <div>
              {coreTasks.map((coreTask) => (
                <div key={coreTask.id}>
                  <div>
                    <h2>{`${coreTask.code}: ${coreTask.title}`}</h2>
                  </div>
                  {showWorkProcesses ? (
                    coreTask.workprocesses?.().map((workProcess) => (
                      <div key={workProcess.id}>
                        <h3>{`${workProcess.code}: ${workProcess.title}`}</h3>
                        <p>{workProcess.description}</p>
                        {!!workProcess.learningObjects && (
                          <LinkedLearningObjectsTable
                            learningObjects={workProcess.learningObjects?.()}
                          />
                        )}
                      </div>
                    ))
                  ) : (
                    <>
                      {coreTask.knowledgeSkills?.().map((knowledgeSkill) => (
                        <div key={knowledgeSkill.id}>
                          <h3>{ucf(knowledgeSkill.description)}</h3>
                          {!!knowledgeSkill.learningObjects && (
                            <LinkedLearningObjectsTable
                              learningObjects={knowledgeSkill.learningObjects?.()}
                            />
                          )}
                        </div>
                      ))}
                    </>
                  )}
                </div>
              ))}
            </div>
          </FlexContainer>
        </Panel>
      ) : (
        <div className={cx(styles.content)}>
          <Callout info>
            {t('views.courses.qualifications.no_qualifications')}
          </Callout>
        </div>
      )}
    </Container>
  );
};
