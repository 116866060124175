import { useMemo } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useEnrollment } from '@brainstud/academy-api';
import { Button } from '@brainstud/ui';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Grid } from '@mui/material';
import classNames from 'classnames/bind';
import { Container, Link, Tabs, TabsItem } from 'Components';
import { useRouter } from 'next/router';
import { useBreadcrumbs } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { useAnswerReview } from '../../../Providers';
import styles from './AnswerReviewNavigation.module.css';

const cx = classNames.bind(styles);

export const AnswerReviewNavigation = () => {
  const [t] = useTranslator();
  const router = useRouter();
  const { studentId } = router.query as { studentId: string };

  const {
    nextItem,
    previousItem,
    isLast,
    isFirst,
    learningObject,
    enrollmentId,
    baseUri,
  } = useAnswerReview() || {};

  const [{ data: enrollment }] = useEnrollment({
    enrollment: enrollmentId,
    include: ['account', 'course', 'education_level'],
  });
  const isStudentReview = router.pathname.startsWith(
    '/coach/students/[studentId]/review'
  );
  useBreadcrumbs(
    {
      [studentId]: {
        text: enrollment?.account?.().firstName,
        href: null,
      },
      [enrollmentId || 0]: isStudentReview
        ? {
            text: enrollment?.course?.().title,
            href: `/coach/students/${enrollment?.account?.().id}/enrollment/${enrollmentId}/portfolio`,
          }
        : null,
      ...(learningObject && {
        [learningObject.id]: {
          text: learningObject.title,
          href: null,
        },
      }),
      ...(isStudentReview
        ? { review: null }
        : { review: t('components.breadcrumbs.review') }),
    },
    [enrollment, learningObject]
  );

  useHotkeys(
    'left',
    () => {
      if (previousItem) {
        router.push(
          `${baseUri}/${previousItem.enrollmentId}/${previousItem.learningObjectId}/answer`
        );
      }
    },
    undefined,
    [router, previousItem]
  );
  useHotkeys(
    'right',
    () => {
      if (nextItem) {
        router.push(
          `${baseUri}/${nextItem.enrollmentId}/${nextItem.learningObjectId}/answer`
        );
      }
    },
    undefined,
    [router, nextItem]
  );

  const participantName = useMemo(
    () => enrollment?.account?.().fullName,
    [enrollment]
  );
  const courseTitle = useMemo(() => enrollment?.course?.().title, [enrollment]);
  const educationLevel = useMemo(
    () => enrollment?.educationLevel?.(),
    [enrollment]
  );
  const hasTeacherRemarks = !!(
    learningObject?.teacherRemarks || learningObject?.variety?.().teacherRemarks
  );

  return (
    <header className={styles.base}>
      <Container>
        <Grid container spacing={2} style={{ margin: '0 -0.8rem' }}>
          <Grid item sm={7}>
            <div className={styles.flexHeader}>
              <h2 className={styles.title}>{learningObject?.title}</h2>
            </div>
            {(previousItem || nextItem) && (
              <div className={styles.answerReviewNav}>
                <Button
                  quiet
                  disabled={isFirst || !previousItem}
                  className={cx({ isDisabled: isFirst || !previousItem })}
                  to={`${baseUri}/${previousItem?.enrollmentId}/${previousItem?.learningObjectId}/answer`}
                >
                  <ArrowBack />
                  <span>{t('review.previous')}</span>
                </Button>
                <Button
                  quiet
                  disabled={isLast || !nextItem}
                  className={cx({ isDisabled: isLast || !nextItem })}
                  to={`${baseUri}/${nextItem?.enrollmentId}/${nextItem?.learningObjectId}/answer`}
                >
                  <span>{t('review.next')}</span>
                  <ArrowForward />
                </Button>
              </div>
            )}
          </Grid>
          <Grid item container sm={5} className={styles.meta}>
            <Grid item>
              <p className={styles.metaTitle}>{t('review.participant')}</p>
              <p>{participantName}</p>
            </Grid>
            {educationLevel && (
              <Grid item style={{ marginRight: '1rem' }}>
                <p className={styles.metaTitle}>{t('review.level')}</p>
                <p>{educationLevel.label || '-'}</p>
              </Grid>
            )}
            <Grid item>
              <p className={styles.metaTitle}>{t('review.course')}</p>
              <p>{courseTitle || '-'}</p>
            </Grid>
          </Grid>
        </Grid>
        <Tabs>
          <TabsItem
            component={Link}
            href={`${baseUri}/${enrollmentId}/${learningObject?.id}/answer`}
            label={t('review.turnedinAnswer')}
          />
          <TabsItem
            component={Link}
            href={`${baseUri}/${enrollmentId}/${learningObject?.id}/core-tasks`}
            label={t('review.coreTasks')}
          />
          <TabsItem
            component={Link}
            href={`${baseUri}/${enrollmentId}/${learningObject?.id}/knowledge-and-skills`}
            label={t('review.knowledgeAndSkills')}
          />
          {hasTeacherRemarks && (
            <TabsItem
              component={Link}
              href={`${baseUri}/${enrollmentId}/${learningObject?.id}/teacher-remarks`}
              label={t('review.teacherRemarks')}
            />
          )}
        </Tabs>
      </Container>
    </header>
  );
};
