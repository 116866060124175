import { UseQueryOptions } from 'react-query';
import { useIndex, useShow } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  ContentTemplate,
  ContentTemplateFilters,
  ContentTemplateIncludes,
  ContentTemplatesSorts,
  HookParams,
  UUID,
} from '../Types';

interface IContentTemplateProps
  extends HookParams<
    typeof ContentTemplateIncludes,
    typeof ContentTemplatesSorts,
    typeof ContentTemplateFilters
  > {
  contentTemplate?: UUID;
}

export function useContentTemplate(
  { contentTemplate, ...queryParameters }: IContentTemplateProps = {},
  settings?: UseQueryOptions<DataDocument<ContentTemplate>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { contentTemplate: defaultContentTemplate },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(contentTemplate || defaultContentTemplate),
      baseName: 'v1.content_templates',
      baseUri: '/v1/content_templates',
      invalidate: ['content_template', 'content_templates'],
      uri: `/${contentTemplate || defaultContentTemplate}`,
      queryParameters,
    },
    context
  );
  return useShow<ContentTemplate>(request, settings);
}

export function useContentTemplates(
  { ...queryParameters }: Omit<IContentTemplateProps, 'contentTemplate'> = {},
  settings?: UseQueryOptions<DataDocument<ContentTemplate[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.content_templates',
      baseUri: 'v1/content_templates',
      invalidate: ['content_template', 'content_templates'],
      queryParameters,
    },
    context
  );
  return useIndex<ContentTemplate>(request, settings);
}
