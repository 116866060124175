import { useMemo } from 'react';
import { Shareable, TTargetTypes } from '@brainstud/academy-api';

type TInputObject = {
  shareables?: () => Shareable[];
};

/**
 * useShareablesWithTarget.
 *
 * Filters the shareables by target type and returns it typesafe.
 */
export function useShareablesWithTarget<T extends TTargetTypes>(
  targetType: T,
  resource?: TInputObject
) {
  return useMemo(
    () =>
      (resource
        ?.shareables?.()
        .filter((item) => item.target?.().resourceType === targetType) ||
        []) as Shareable<T>[],
    [resource, targetType]
  );
}
