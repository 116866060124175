import { PropsWithChildren } from 'react';
import classNames from 'classnames/bind';
import styles from './InlineList.module.css';

const cx = classNames.bind(styles);

type TProps = PropsWithChildren<{ className?: string }>;

/**
 * InlineList.
 *
 * Inline list component to display children in a more structured way
 */
export const InlineList = ({ children, className }: TProps) => (
  <div className={cx(styles.base, className)}>{children}</div>
);
