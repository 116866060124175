import React, { useCallback, useMemo } from 'react';
import {
  Student,
  StudentFilters,
  StudentSorts,
  TSortType,
  useMe,
  useStudents,
} from '@brainstud/academy-api';
import {
  Callout,
  Indicator,
  Pagination,
  Panel,
  Progress,
  Status,
  Table,
  Td,
  TdCheckbox,
  Th,
  Tr,
} from '@brainstud/ui';
import { Tooltip } from '@brainstud/ui/Static/Tooltip/Tooltip';
import {
  useDataSelection,
  usePagination,
} from '@brainstud/universal-components';
import { Archive, LinkOff, People } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Container, Link, Loading } from 'Components';
import { useRouter } from 'next/router';
import { useModals, useViewSettings, ViewSettings } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { DateFormatter } from 'Utils/DateFormatHelper';
import { Badge, SelectionMenu } from '../../../Components';
import { ArchiveModal, DecoupleModal, GroupModal } from '../../../Modals';
import { StatusColors } from '../../../Utils';
import { StudentsSearchAndFilter } from '../../Students/StudentsSearchAndFilter/StudentsSearchAndFilter';
import styles from './CoursesStudentsView.module.css';

const cx = classNames.bind(styles);

const StatusSteps = Object.keys(StatusColors);

type TFilters = {
  filters: StudentFilters;
  sort: TSortType<typeof StudentSorts>[];
};

const CoursesStudentsView = () => {
  const router = useRouter();
  const { courseId, collectionId } = router.query as {
    courseId: string;
    collectionId: string;
  };
  const [t] = useTranslator();
  const { openModal } = useModals();
  const [me] = useMe();

  const { page, limit } = usePagination();
  const [{ apiFilters = {}, sort }, dispatch] = useViewSettings<TFilters>(
    ViewSettings.COURSES_STUDENTS_VIEW
  );
  const isShowingArchived = !!apiFilters?.archived;

  const [
    { data: students, update },
    { data: document, isLoading, isFetching },
  ] = useStudents(
    {
      limit,
      page,
      sort: sort || ['-unseen_answer_count'],
      filter: {
        archived: false,
        ...apiFilters,
        learning_object_collection: collectionId,
        course: courseId,
      },
    },
    { refetchInterval: 60000, staleTime: 10000 }
  );

  const { selection, handleToggleSelect, handleToggleSelectAll } =
    useDataSelection();

  const selectedStudents = useMemo(
    () =>
      selection.map((selectedId: string) =>
        students.find((student) => student.id === selectedId)
      ),
    [students, selection]
  );

  const archiveableSelectedStudents = useMemo(
    () =>
      selectedStudents.filter(
        (item: Student | undefined) =>
          !!item?.coaches?.().some((coach) => coach.id === me?.account?.().id)
      ),
    [selectedStudents, me]
  );

  const isNotArchivable = archiveableSelectedStudents.length === 0;
  const isSelectionPartiallyArchivable =
    archiveableSelectedStudents.length > 0 &&
    archiveableSelectedStudents.length !== selectedStudents.length;

  const handleSort = useCallback(
    (sortOn?: string) => {
      dispatch({ type: 'TOGGLE_SORT', payload: { value: sortOn || '' } });
    },
    [dispatch]
  );

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <StudentsSearchAndFilter
        className={cx(styles.search)}
        viewName={ViewSettings.COURSES_STUDENTS_VIEW}
      />
      <Container>
        {students.length ? (
          <Panel>
            <Table onSort={handleSort}>
              <Table.THead>
                <Tr>
                  {isFetching ? (
                    <Th style={{ width: '2rem' }}>
                      {isFetching && <Indicator loading />}
                    </Th>
                  ) : (
                    <TdCheckbox id={students.map((item) => item.id)} />
                  )}
                  <Th center sortOn="unseen_action_count">
                    {t('students.new')}
                  </Th>
                  <Th sortOn="full_name">{t('students.name')}</Th>
                  <Th sortOn="class_unit">{t('students.class')}</Th>
                  <Th>{t('students.status')}</Th>
                  <Th>{t('students.progress')}</Th>
                  <Th sortOn="last_online">{t('students.lastActive')}</Th>
                </Tr>
              </Table.THead>
              <Table.TBody>
                {students.map((student) => {
                  const enrollment = (student.enrollments?.() || []).find(
                    (item) => item?.course?.().id === courseId
                  );
                  const currentXPPercentage =
                    enrollment?.currentXp &&
                    (enrollment?.totalXp || enrollment?.targetXp)
                      ? // eslint-disable-next-line max-len
                        (Number(enrollment?.currentXp) /
                          Number(enrollment?.totalXp || enrollment?.targetXp)) *
                        100
                      : 0;
                  return !enrollment ? null : (
                    <Tr key={student.id}>
                      <TdCheckbox
                        id={student.id}
                        checked={selection.includes(student.id)}
                        onChange={() => handleToggleSelect([student.id])}
                      />
                      <Td center>
                        <Badge
                          color={
                            (student?.meta?.unseenAnswerCount || 0) <= 0
                              ? 'grey'
                              : 'red'
                          }
                          circle
                        >
                          {student?.meta?.unseenAnswerCount || 0}
                        </Badge>
                      </Td>
                      <Td
                        to={`/coach/students/${student.id}/enrollment/${enrollment.id}/portfolio`}
                      >
                        {student.fullName || student.firstName}
                      </Td>
                      <Td>{student.classCode}</Td>

                      <Td>
                        {enrollment.examStep &&
                          StatusSteps.includes(enrollment.examStep) && (
                            <Link
                              href={`/coach/students/${student.id}/enrollment/${enrollment.id}/exam`}
                            >
                              <Status
                                className={cx(styles.status, {
                                  'unseen-exam-status':
                                    !enrollment?.meta?.exam?.coachHasSeenAt,
                                })}
                                scheme={
                                  StatusColors[
                                    enrollment?.meta?.exam?.currentStep ||
                                      'OPEN'
                                  ]
                                }
                              >
                                {t(
                                  `exam.status.${enrollment?.meta?.exam?.currentStep || 'UNKNOWN'}`
                                )}
                              </Status>
                            </Link>
                          )}
                      </Td>
                      <Td style={{ width: '100px' }}>
                        <Tooltip
                          button={<Progress value={currentXPPercentage} />}
                        >
                          <span className={cx(styles.tooltip)}>
                            {`${Number(enrollment.currentXp)} / ${Number(enrollment.totalXp)} ${t('components.progressBar.xp')}`}
                          </span>
                        </Tooltip>
                      </Td>
                      <Td>
                        {DateFormatter.toAbbreviatedDate(
                          student.lastOnline,
                          "dd MMM ''yy [at] HH:mm"
                        )}
                      </Td>
                    </Tr>
                  );
                })}
              </Table.TBody>
            </Table>
          </Panel>
        ) : (
          <>
            {Object.keys(apiFilters).length > 0 ? (
              <>
                {isShowingArchived ? (
                  <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
                    {t('students.no_archived_students')}
                  </p>
                ) : (
                  <p className={cx(styles.no_match_message)}>
                    {t('students.no_students_match')}
                  </p>
                )}
              </>
            ) : (
              <Callout info>{t('students.noStudents')}</Callout>
            )}
          </>
        )}

        {document?.meta?.total > 0 && (
          <Pagination
            totalPages={document?.meta?.lastPage}
            totalResults={`${t('components.pagination.totalResults')} ${document?.meta?.total}`}
            labels={{
              itemsPerPage: t('students.participantsPerPage'),
              results: t('students.totalResults'),
            }}
          />
        )}
      </Container>

      <SelectionMenu open={selection.length > 0}>
        <button
          type="button"
          onClick={() =>
            openModal(GroupModal, {
              students: students.filter((s) => selection.includes(s.id)),
              onComplete: () => handleToggleSelectAll([]),
            })
          }
        >
          <span>{t('students.group')}</span>
          <People />
        </button>
        <button
          type="button"
          onClick={() =>
            openModal(ArchiveModal, {
              accountIds: archiveableSelectedStudents.map(
                (student) => student?.id
              ),
              unarchive: isShowingArchived,
              handleToggleSelect,
              disabled: isNotArchivable && !isSelectionPartiallyArchivable,
              onArchive: update.mutateAsync,
              onComplete: () => handleToggleSelectAll([]),
              ...(isNotArchivable || isSelectionPartiallyArchivable
                ? {
                    warning: t(
                      `views.students.${isShowingArchived ? 'unarchive' : 'archive'}.warning.${isSelectionPartiallyArchivable ? 'partial' : 'full'}`
                    ),
                  }
                : {}),
            })
          }
        >
          <span>
            {isShowingArchived
              ? t('students.dearchive')
              : t('students.archive')}
          </span>
          <Archive />
        </button>
        {courseId !== undefined && (
          <button
            type="button"
            onClick={() =>
              openModal(DecoupleModal, {
                students: students.filter((s) => selection.includes(s.id)),
                course: courseId,
                onComplete: () => handleToggleSelectAll([]),
              })
            }
          >
            <span>{t('students.decouple')}</span>
            <LinkOff />
          </button>
        )}
      </SelectionMenu>
    </>
  );
};

export default CoursesStudentsView;
