import React, { ReactNode } from 'react';
import { Order } from '@brainstud/academy-api';
import { Button, Panel, Status, Table, Td, Th, Tr } from '@brainstud/ui';
import { GetApp, OpenInNew } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Link, Wrap } from 'Components';
import { useTranslator } from 'Providers/Translator';
import { toCurrency } from 'Utils';
import { DateFormatter } from 'Utils/DateFormatHelper';
import { OrderStatusColors, TransactionColors } from 'Utils/StatusColors';
import { Orderline } from 'Views/Admin/Sales/Partial/Orderline';
import styles from 'Views/Admin/Sales/Partial/OrderDetailPartialView.module.css';

const cx = classNames.bind(styles);

interface Props {
  /** The order for which to show the information */
  order: Order;
  /** Indicator for loading the orderdetails as a partial */
  isPartial?: boolean;
}

const PanelWrapper = ({ children }: { children: ReactNode }) => (
  <Panel pad className={cx(styles.base)}>
    {children}
  </Panel>
);

export const OrderDetailPartialView = ({ order, isPartial }: Props) => {
  const [t] = useTranslator();

  return (
    <div className={cx(styles.base)}>
      <h2>
        {t('views.orders.partials.order_details.order')}
        {': '}
        {isPartial ? (
          <Link
            className={cx(styles.pagelink)}
            href={`/admin/sales/orders/${order?.id}`}
          >
            <span>{order?.code}</span>
            <OpenInNew fontSize="large" />
          </Link>
        ) : (
          <span>{order?.code}</span>
        )}
      </h2>

      <Wrap if={!isPartial} with={PanelWrapper}>
        <div className={cx(styles.details)}>
          <div className={cx(styles.detail)}>
            <span>{t('views.orders.partials.order_details.payed_by')}</span>
            <span>
              {
                order?.subject?.()[
                  order?.subject?.().resourceType === 'accounts'
                    ? 'fullName'
                    : 'name'
                ]
              }
            </span>
          </div>
          <div className={cx(styles.detail)}>
            <span>{t('views.orders.partials.order_details.open_date')}</span>
            <span>
              {DateFormatter.toDateTime(order.closeDate, 'dd MMMM yyyy')}
            </span>
          </div>
          <div className={cx(styles.detail)}>
            <span>{t('views.orders.partials.order_details.close_date')}</span>
            <span>
              {DateFormatter.toDateTime(order.openDate, 'dd MMMM yyyy')}
            </span>
          </div>
          <div className={cx(styles.detail, styles.status)}>
            <Status scheme={OrderStatusColors[order.state]}>
              {t(`views.orders.status.${order.state}`)}
            </Status>
          </div>
        </div>

        <div className={cx(styles.lines)}>
          {order
            .orderLines?.()
            .map((line) => <Orderline key={line.id} line={line} />)}

          <div className={cx(styles.line, styles.total)}>
            <div className={cx(styles.data)} />
            <dl className={cx(styles.pricing)}>
              <dt>{t('views.orders.partials.order_details.before_tax')}</dt>
              <dd>{toCurrency(order.totalExVat)}</dd>
              <dt>{t('views.orders.partials.order_details.total')}</dt>
              <dd>{toCurrency(order.totalOrderWorth)}</dd>
            </dl>
          </div>
        </div>

        {order?.invoices?.() && (
          <div className={cx(styles.invoices)}>
            <h4>{t('views.orders.partials.order_details.invoices')}</h4>
            <Table className={cx(styles.table)}>
              <Table.THead>
                <Tr>
                  <Th>{t('views.orders.partials.order_details.reference')}</Th>
                  <Th>{t('views.orders.partials.order_details.date')}</Th>
                  <Th>
                    {t('views.orders.partials.order_details.external_system')}
                  </Th>
                </Tr>
              </Table.THead>
              <Table.TBody>
                {order.invoices?.().map((invoice) => (
                  <Tr key={invoice.id}>
                    <Td>{invoice.reference}</Td>
                    <Td>
                      {DateFormatter.toDateTime(
                        invoice.createdAt,
                        'dd MMMM yyyy'
                      )}
                    </Td>
                    <Td>{invoice.externalSystems}</Td>
                  </Tr>
                ))}
              </Table.TBody>
            </Table>
          </div>
        )}

        {order.transactions?.() && (
          <div className={cx(styles.transactions)}>
            <h4>{t('views.orders.partials.order_details.transactions')}</h4>
            <Table className={cx(styles.table)}>
              <Table.THead>
                <Tr>
                  <Th>{t('views.orders.partials.order_details.reference')}</Th>
                  <Th>{t('views.orders.partials.order_details.date')}</Th>
                  <Th>{t('views.orders.partials.order_details.amount')}</Th>
                  <Th>{t('views.orders.partials.order_details.status')}</Th>
                </Tr>
              </Table.THead>
              <Table.TBody>
                {order.transactions?.().map((transaction) => (
                  <Tr key={transaction.id}>
                    <Td>{transaction.externalReference}</Td>
                    <Td>
                      {DateFormatter.toDateTime(
                        transaction.createdAt,
                        'dd MMMM yyyy'
                      )}
                    </Td>
                    <Td>{toCurrency(transaction.amount / 100)}</Td>
                    <Td>
                      <Status scheme={TransactionColors[transaction.status]}>
                        {t(
                          `views.orders.partials.order_details.transaction_status.${transaction.status}`
                        )}
                      </Status>
                    </Td>
                  </Tr>
                ))}
              </Table.TBody>
            </Table>
          </div>
        )}

        {order.prepaidCodes?.() && (
          <div className={cx(styles.prepaidcodes)}>
            <h4>{t('views.orders.partials.order_details.prepaidcodes')}</h4>
            <Table className={cx(styles.table)}>
              <Table.THead>
                <Tr>
                  <Th>
                    {t('views.orders.partials.order_details.prepaid_code')}
                  </Th>
                  <Th>
                    {t('views.orders.partials.order_details.activated_on')}
                  </Th>
                  <Th>
                    {t('views.orders.partials.order_details.activated_product')}
                  </Th>
                </Tr>
              </Table.THead>
              <Table.TBody>
                {order.prepaidCodes?.().map((code) => (
                  <Tr key={code.id}>
                    <Td>{code.code}</Td>
                    <Td>
                      {DateFormatter.toDateTime(code.createdAt, 'dd MMMM yyyy')}
                    </Td>
                    <Td>{code.activatedProductName}</Td>
                  </Tr>
                ))}
              </Table.TBody>
            </Table>
          </div>
        )}

        <Button
          type="button"
          href={order.links.receipt.href}
          download
          style={{ marginLeft: 'auto' }}
          target="_blank"
        >
          <GetApp />
          <span>
            {t('views.orders.partials.order_details.download_specification')}
          </span>
        </Button>
      </Wrap>
    </div>
  );
};
