import React, { PropsWithChildren, useRef, useState } from 'react';
import { useOnClickOutside } from '@brainstud/universal-components';
import classNames from 'classnames/bind';
import { ActionMenuProps } from './ActionMenuProps';
import styles from './ActionMenu.module.css';

const cx = classNames.bind(styles);

export const ActionMenu = ({
  id,
  className,
  icon,
  children,
  outline,
}: PropsWithChildren<ActionMenuProps>) => {
  const [open, setOpen] = useState(false);

  const actionMenu = useRef<HTMLDivElement>(null);
  useOnClickOutside([actionMenu], () => setOpen(false));

  const Icon = icon || (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="currentColor"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
    </svg>
  );

  return (
    <div
      className={cx(
        styles.root,
        { outline, 'visible-overflow': open },
        className
      )}
      ref={actionMenu}
    >
      <button
        type="button"
        className={cx(styles.button)}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          setOpen((prevOpen) => !prevOpen);
        }}
        aria-controls={id}
        aria-haspopup="true"
      >
        {Icon}
      </button>
      {open && (
        <div id={id} className={styles.popover} role="menu">
          {children}
        </div>
      )}
    </div>
  );
};
