import { useContext } from 'react';
import { IModalContext, ModalContext } from './ModalContext';

/**
 * useModals.
 *
 * Provides methods to open and close a modals.
 * @param nullable When true, this hook returns null when no context is found.
 */
export function useModals(nullable?: false): IModalContext;
export function useModals(nullable?: true): null | IModalContext;
export function useModals(nullable?: boolean): null | IModalContext {
  const context = useContext(ModalContext);

  if (!context && !nullable) {
    throw new Error('Please, initiate the ModalProvider first!');
  }

  return context;
}
