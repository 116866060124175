import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm, useFormField } from '@brainstud/universal-components';
import Placeholder from '@tiptap/extension-placeholder';
import { EditorContent, useEditor } from '@tiptap/react';
import axios from 'axios';
import classNames from 'classnames/bind';
import { shortId } from 'Utils';
import { sanitizer } from 'Utils/Sanitizer';
import { TEXTEDITOR_MAX_MB_UPLOAD_FILESIZE } from '../../Config/constants';
import { useToaster } from '../../Providers';
import { Presets } from './Presets';
import { RichTextEditorContext } from './RichTextEditorContext';
import { MinimalToolbar } from './Toolbars';
import { RichTextEditorProviderProps } from './Types';
import styles from './RichTextEditor.module.css';

const classes = classNames.bind(styles);

/**
 * RichTextEditor.
 *
 * Returns a [TipTap](https://tiptap.dev) based text editor with basic configurations. You can
 * use the basic configuration presets that have been configured or use the `config` property
 * to set specific configurations.
 */
export const RichTextEditor = ({
  id,
  name,
  label,
  preset = 'default',
  config,
  defaultValue,
  rules,
  placeholder,
  disabled = false,
  onChange,
  terms,
  className,
  style,
  isValid,
}: RichTextEditorProviderProps) => {
  const [selectedText, setSelectedText] = useState<string>('');
  const [setToast] = useToaster();

  const [normalizedId] = useState(`editor_${shortId(id)}`);
  const idOrName = id || name;
  const {
    setValue,
    value: formValue,
    locked,
    field,
    valid,
  } = useFormField<string>({
    id: idOrName,
    name,
    defaultValue,
    rules,
  });

  const { messages } = field || {};

  const presetConfig = useMemo(
    () => (preset && preset in Presets ? Presets[preset] : {}),
    [preset]
  );
  const editor = useEditor({
    ...presetConfig,
    ...config,
    extensions: [
      ...(presetConfig?.extensions || []),
      ...(config?.extensions || []),
      ...(placeholder
        ? [
            Placeholder.configure({
              placeholder,
            }),
          ]
        : []),
    ],
    content: formValue || config?.content || presetConfig.content,
    editorProps: {
      handleDrop: (view, event, slice, moved) => {
        const file = event?.dataTransfer?.files?.[0];
        if (!moved && file) {
          const fileSize = parseInt((file.size / 1024 / 1024).toFixed(4), 10);
          if (fileSize <= TEXTEDITOR_MAX_MB_UPLOAD_FILESIZE) {
            const formData = new FormData();
            formData.append('files[]', file);
            axios
              .post('/v1/services/media_upload', formData)
              .then((response) => {
                const fileUrl = response?.data?.data?.[0]?.attributes?.file_url;
                if (fileUrl) {
                  const {
                    state: { tr, schema },
                  } = view;
                  const { selection } = tr;
                  if (selection.empty) {
                    const coordinates = view.posAtCoords({
                      left: event.clientX,
                      top: event.clientY,
                    });
                    const textNode = schema.text(file.name, [
                      schema.marks.link.create({ href: fileUrl }),
                    ]);
                    view.dispatch(
                      tr.insert(coordinates?.pos || selection.$to.pos, textNode)
                    );
                  } else {
                    editor
                      ?.chain()
                      ?.setLink({ href: fileUrl, target: '_blank' });
                  }
                  setToast(
                    'components.rich_text_editor.file_upload.success',
                    'success'
                  );
                }
              });
          } else {
            setToast(
              'components.rich_text_editor.file_upload.errors.filesize_too_large',
              'error'
            );
          }
          return true;
        }
        return false;
      },
    },
    onUpdate: (data) => {
      setValue(data.editor.getHTML());
      const additionalAction = config?.onUpdate || presetConfig.onUpdate;
      additionalAction?.(data);
    },
    onSelectionUpdate: (data) => {
      const { from, to } = data.editor.state.selection;
      setSelectedText(data.editor.state.doc.textBetween(from, to, ' '));
      const additionalAction =
        config?.onSelectionUpdate || presetConfig.onSelectionUpdate;
      additionalAction?.(data);
    },
  });

  useEffect(() => {
    if (editor) {
      onChange?.(formValue || '', editor);
    }
  }, [formValue, editor, onChange]);

  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    if (!!defaultValue && editor && !isInitialized) {
      setIsInitialized(true);
      editor.commands.setContent(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, editor, defaultValue]);

  const { subscribe } = useForm(true) || {};
  useEffect(
    () =>
      subscribe?.('restored-field', (restoredField) => {
        if (
          restoredField.id === id &&
          typeof restoredField.value === 'string'
        ) {
          editor?.commands.setContent(restoredField.value);
        }
        // This second if statement makes sure some answers are loaded correctly. This is only a very small subset of
        // answers. The support for these answers can probably be dropped in 2024. Error occurred between 02-05-2023
        // and 17-05-2023.
        if (
          restoredField.id.includes(`editor_${shortId(id)}`) &&
          restoredField.value !== ''
        ) {
          setValue(restoredField.value);
        }
      }),
    [editor, subscribe, id, setValue]
  );

  useEffect(
    () =>
      subscribe?.('reset', () => {
        editor?.commands.setContent('');
      }),
    [editor, subscribe]
  );

  const backdrop = useRef<HTMLDivElement>(null);
  const context = useMemo(
    () =>
      !editor
        ? null
        : {
            editor,
            selectedText,
            backdrop,
            terms,
            disabled,
          },
    [editor, selectedText, terms, disabled]
  );

  const isInactive = locked || disabled;

  const hasErrors = (messages && messages.length > 0) || isValid === false;

  return (
    <RichTextEditorContext.Provider value={context}>
      <div
        className={classes(
          styles.base,
          'richtexteditor-base__container',
          className,
          {
            'has-errors': hasErrors,
            'is-valid': valid === true || isValid,
            'is-invalid': valid === false,
            'is-required': rules?.includes('required'),
          }
        )}
        style={style}
      >
        {label && (
          <label htmlFor={normalizedId} className={classes(styles.label)}>
            {label}
          </label>
        )}

        <div className={classes(styles.textarea)}>
          {!editor || locked ? null : <MinimalToolbar disabled={disabled} />}

          <div className={styles['editable-container']}>
            {isInactive ? (
              <div
                className={classes('disabled-content')}
                dangerouslySetInnerHTML={{
                  __html: sanitizer(formValue || defaultValue),
                }}
              />
            ) : (
              <EditorContent
                id={normalizedId}
                editor={editor || null}
                className={classes(styles.editor)}
              />
            )}

            <div ref={backdrop} />
          </div>
        </div>
        {!!messages?.length && (
          <div
            role="alert"
            className={classes(styles.message, { 'has-messages': hasErrors })}
          >
            {hasErrors && messages![0]}
          </div>
        )}
      </div>
    </RichTextEditorContext.Provider>
  );
};
