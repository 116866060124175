import React, { FC, PropsWithChildren } from 'react';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import styles from './SelectionMenu.module.css';

const cx = classNames.bind(styles);

type TProps = {
  title?: string;
  open?: boolean;
  className?: string;
  style?: React.CSSProperties;
};

/**
 * This menu pops over the bottom of the page to handle actions for selected data.
 */
export const SelectionMenu: FC<PropsWithChildren<TProps>> = ({
  open,
  title,
  style,
  className,
  children,
}) => {
  const [t] = useTranslator();
  return open ? (
    <div className={cx(styles.base, className)} style={style}>
      <div className={styles.title}>
        <strong>{title || t('components.selection_menu.default_title')}</strong>
      </div>
      {children}
    </div>
  ) : null;
};
