import { useMemo } from 'react';
import { Student } from '@brainstud/academy-api';
import { Table, Td, Th, Tr } from '@brainstud/ui';
import { useTranslator } from 'Providers/Translator';
import { StudentEnrollmentRow } from './StudentEnrollmentRow/StudentEnrollmentRow';

type TProps = {
  data: Student;
};

export const ExpandedStudentsRow = ({ data: student }: TProps) => {
  const [t] = useTranslator();
  const enrollments = useMemo(() => student.enrollments?.() || [], [student]);

  return (
    <Tr className="no-hover">
      <Td colSpan={7}>
        <Table>
          <Table.THead>
            <Tr>
              <Th>{t('students.course')}</Th>
              <Th />
              <Th>{t('coach')}</Th>
              <Th>{t('students.lastActive')}</Th>
            </Tr>
          </Table.THead>
          <Table.TBody>
            {enrollments.map((enrollment) => (
              <StudentEnrollmentRow
                key={enrollment.id}
                enrollment={enrollment}
                studentId={student.id}
              />
            ))}
          </Table.TBody>
        </Table>
      </Td>
    </Tr>
  );
};
