import React, { ReactNode } from 'react';

type Props<WrapperProps extends undefined | {} = undefined> = {
  if: boolean;
  with: (props: WrapperProps & { children: ReactNode }) => JSX.Element;
  props?: WrapperProps;
  children: ReactNode;
};

export const Wrap = <P extends {}>({
  if: condition,
  with: Wrapper,
  props,
  children,
}: Props<P>) =>
  condition ? (
    Wrapper({ ...(props || {}), children } as P & { children: ReactNode })
  ) : (
    <>{children}</>
  );
