import { UseQueryOptions } from 'react-query';
import { useShow } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import { DateTimeString, IResource } from '../Types';

interface IPing extends IResource<'ping'> {
  status: 'online' | 'offline' | 'error';
  timestamp: DateTimeString;
  redirect?: string;
}

export function usePing(
  settings: UseQueryOptions<DataDocument<IPing>, ErrorDocument> = {}
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'ping',
      baseUri: '/ping',
    },
    context
  );
  return useShow<IPing>(request, settings);
}
