import React, { useCallback, useEffect, useState } from 'react';
import { useApi } from '@brainstud/academy-api';
import { UploadBox } from '@brainstud/ui';
import { IAudioBlock } from 'Modules/course-blocks';
import { useTranslator } from 'Providers/Translator';
import styles from './AudioForm.module.css';

export interface AudioFormProps {
  data?: IAudioBlock;
  onChange: (e: Partial<IAudioBlock>) => void;
}

export const AudioForm = ({ data, onChange }: AudioFormProps) => {
  const [t] = useTranslator();
  const { baseUrl, headers: apiHeaders } = useApi();
  const [src, setSrc] = useState(data?.src || '');

  const handleFileUploaded = useCallback((file: any, response: any) => {
    setSrc(response?.data?.[0]?.attributes?.file_url);
  }, []);

  useEffect(() => {
    onChange?.({
      src,
    });
  }, [src]);

  return (
    <div className={styles.base}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label>
        {t('course-editor.content_editor.blocks.audio.upload_file')}
      </label>
      <UploadBox
        url={`${baseUrl}/v1/services/media_upload`}
        headers={{ ...apiHeaders, 'Content-Type': undefined }}
        paramName="files[0]"
        maxFiles={1}
        onAfterFileUpload={handleFileUploaded}
        maxFilesize={10000000} // 10MB
        acceptedFiles=".mp3,.ogg,.aac,.wav,.mid,.opus,.webm,.m4a"
      />
    </div>
  );
};
