import { Dispatch, useEffect } from 'react';
import { ReviewItem } from './AnswerReviewContext';
import { TAnswerReviewActions } from './useAnswerReviewReducer';

export function useReviewSetNavigation(
  dispatch: Dispatch<TAnswerReviewActions>,
  index: number,
  set?: ReviewItem[],
  enrollmentId?: string,
  learningObjectId?: string
) {
  const currentItem = set?.[index] || undefined;
  const nextItem = set?.[index + 1] || undefined;
  const previousItem = set?.[index - 1] || undefined;

  useEffect(() => {
    if (learningObjectId && enrollmentId) {
      dispatch({
        type: 'GO_TO_ITEM',
        payload: {
          learningObjectId,
          enrollmentId,
        },
      });
    }
  }, [learningObjectId, enrollmentId, dispatch]);

  const isFirst = index === 0;
  const isLast = index === (set?.length || 1) - 1;

  return {
    index,
    isFirst,
    isLast,
    currentItem,
    nextItem,
    previousItem,
  };
}
