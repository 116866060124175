import React, { useEffect, useMemo, useState } from 'react';
import type { LearningObject } from '@brainstud/academy-api';
import classNames from 'classnames/bind';
import { CollectionsTable, Container } from 'Components';
import {
  Display,
  SearchSortAndToggle,
} from 'Modules/action-based-learning-panel/Components';
import { Content } from 'Modules/action-based-learning-panel/Layouts';
import {
  ILearningRouteNodeWithResources,
  ILearningSubjectWithResources,
  TNodeListItem,
} from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { LayoutComponent } from 'Views/CollectionViews/Layouts';
import { RouteActionCardsGrid } from './RouteActionCardsGrid';
import styles from './RouteActionCardsView.module.css';

const cx = classNames.bind(styles);

type Props = {
  nodes: ILearningRouteNodeWithResources[];
};

export const RouteActionCardsView: LayoutComponent<Props> = ({ nodes }) => {
  const [gridMode, setGridMode] = useState(true);
  const [filter, setFilter] = useState<string>();
  const [sort, setSort] = useState<null | 'title'>(null);
  const [t] = useTranslator();

  const sortedSets = useMemo(() => {
    if (!nodes?.length) return [];
    const SortItems = (
      items: Array<LearningObject | ILearningSubjectWithResources>
    ) => {
      const filteredItems = [...items];
      if (sort) {
        return filteredItems.sort((a, b) => (b[sort] > a[sort] ? -1 : 1));
      }
      return filteredItems;
    };
    return nodes.map((node) => ({
      ...node,
      scheme: SortItems(node.scheme),
    }));
  }, [nodes, sort]);

  // When searching show grid
  useEffect(() => {
    if (filter && !gridMode) {
      setGridMode(true);
    }
  }, [filter, gridMode]);

  return (
    <Container>
      <Content className={cx(styles.base)}>
        <SearchSortAndToggle
          onSearch={setFilter}
          sortable={{ title: t('title') }}
          onSort={(value) => setSort(value as null | 'title')}
          onToggleView={setGridMode}
          toggleValue={gridMode}
        />
        {filter && (
          <span>
            {t('views.collection.filter')}
            <strong>{`'${filter}'`}</strong>
          </span>
        )}
        <Display
          condition={sortedSets?.length > 0}
          fallback={t('views.collection.no_content')}
        >
          {gridMode ? (
            sortedSets.map((set: TNodeListItem) => (
              <div key={set.id} className={styles.grid}>
                <RouteActionCardsGrid searchTerm={filter} item={set} />
              </div>
            ))
          ) : (
            <CollectionsTable searchTerm={filter} nodes={sortedSets} />
          )}
          {/* FIXME when search is provided by api */}
          {!sortedSets.length &&
            t('views.collection.no_assignments_with_this_search')}
        </Display>
      </Content>
    </Container>
  );
};
