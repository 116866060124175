import { Exception } from './Exception';

/**
 * NotFoundException.
 *
 * Exception that can be thrown when API returns an (unexpected) 404 for a resource.
 */
export class NotFoundException extends Exception {
  public name = 'Resource not found exception';

  public silent = true;

  public level = 'info' as const;

  public template = 'toast' as const;

  public constructor(type: string = 'item') {
    super(`${type} not found`);
    this.props = {
      title: `exceptions.not_found.${type}.title`,
      description: `exceptions.not_found.${type}.description`,
    };
  }
}
