import React, { PropsWithChildren } from 'react';
import ReactDOM from 'react-dom/server';
import { useDropzoneUpload } from '@brainstud/universal-components';
import { CreateNewFolder } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { FileCard } from '../FileCard';
import { UploadBoxProps } from './UploadBoxProps';
import styles from './UploadBox.module.css';

const cx = classNames.bind(styles);

/**
 * This Upload box provides a markup, styling and functionality based on DropzoneJS for uploading files.
 *
 * All configuration options of [dropzonejs](https://dropzonejs.com#configuration-options) are available as props.
 */
export const UploadBox = ({
  quiet,
  expand,
  children,
  onFileRemove,
  preview = true,
  label,
  className,
  style,
  headers,
  ...options
}: PropsWithChildren<UploadBoxProps>) => {
  const [t] = useTranslator();
  const [setToast] = useToaster();
  const { dropContainer, container } = useDropzoneUpload({
    previewTemplate: ReactDOM.renderToStaticMarkup(
      <FileCard hidden={!preview} onRemove={onFileRemove} uploading />
    ),
    ...(preview !== true && !!preview ? { previewsContainer: preview } : {}),
    headers: { ...headers, 'Content-Type': undefined as unknown as string },
    ...options,
    error(file, error) {
      console.error(file, error);
      setToast('components.uploadbox.errors.failed_upload');
    },
  });

  return (
    <div
      className={cx(styles.base, { quiet, expand }, className)}
      style={style}
      ref={container}
    >
      <div className={cx('upload-container', 'dropzone')} ref={dropContainer} />

      {!children ? (
        <button
          type="button"
          className={cx(styles.button, 'file-uploader-clickable')}
        >
          <CreateNewFolder className={cx(styles.foldericon)} />
          {label ? (
            <span className={cx(styles.label)}>{label}</span>
          ) : (
            <div className={cx(styles.instructions)}>
              <span>{t('components.uploadbox.drag_files')}</span>
            </div>
          )}
        </button>
      ) : (
        children
      )}

      <div className={cx(styles.dropFilesMessage)}>
        <svg
          className={cx(styles.hoverIcon)}
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="currentColor"
        >
          <path
            d="M5,10h4v6h6v-6h4l-7-7L5,10z"
            className={cx(styles.hoverIconArrow)}
          />
          <path d="M5,20h14v-2H5V20z" className={cx(styles.hoverIconBottom)} />
        </svg>
      </div>
    </div>
  );
};
