/**
 * ThemeActions.
 *
 * List of constants identifying the actions available in the ThemeReducer.
 */
export default {
  SET_THEME: 'SET_THEME',
  UPDATE_THEME: 'UPDATE_THEME',
  UPDATE_COLORS: 'UPDATE_COLORS',
  UPDATE_STYLES: 'UPDATE_STYLES',
} as const;
