import React from 'react';
import { useDimensions } from '@brainstud/universal-components';
import VideoLink from '../../../Support/VideoLink';
import styles from './VideoBlock.module.css';

interface VideoBlockProps {
  src: string;
  title: string;
}

const VideoBlock = ({ title, src }: VideoBlockProps) => {
  const [container, dimensions] = useDimensions();
  const { width: videoWidth } = dimensions;

  return (
    <div className={styles.base} ref={container}>
      <iframe
        width={videoWidth}
        height={250}
        className={styles.frame}
        title={title}
        src={new VideoLink(src).getEmbedLink()}
        frameBorder="0"
        allowFullScreen
      />
    </div>
  );
};

export default VideoBlock;
