import { DateTimeString } from '../Utils/DateTimeString';
import { IResource } from '../Utils/Resource';
import { UUID } from '../Utils/UUID';
import { EventCalendarEnrollmentGroup } from './EventCalendarEnrollmentGroup';
import { EventCalendarItemEnrollment } from './EventCalendarItemEnrollment';

export interface EventCalendarItem extends IResource<'event_calendar_items'> {
  startDate: DateTimeString;
  endDate: DateTimeString;
  enrollmentCloseDate: DateTimeString;
  title: string;
  description: string;
  /** @deprecated Use .locationDetails instead */
  city: string;
  locationDetails: string;
  enabled: boolean;
  enrollable: boolean;
  category: string;
  maxEnrollments: number;
  eventCalendarEnrollmentGroup?: () => EventCalendarEnrollmentGroup;
  eventCalendarItemEnrollment?: () => EventCalendarItemEnrollment;
}

export interface EventCalendarItemCreateInput {
  title: string;
  description: string;
  start_date: DateTimeString;
  end_date: DateTimeString;
  enrollment_close_date: DateTimeString;
  city: string;
  location_details: string;
  enabled: boolean;
  max_enrollments: number;
  category: UUID;
}

export type EventCalendarItemUpdateInput = Partial<
  Omit<EventCalendarItemCreateInput, 'category'>
>;

export enum EventCalendarItemIncludes {
  current_user_enrollment,
  event_calendar_enrollment_group,
}

export enum EventCalendarItemFilters {}

export enum EventCalendarItemSorts {}
