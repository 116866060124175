import React, { FC, useCallback, useEffect, useState } from 'react';
import { Callout } from '@brainstud/ui';
import {
  DndProviderWithBackend,
  useForm,
} from '@brainstud/universal-components';
import { useBlock } from 'Hooks/useBlock';
import { sanitizer } from 'Utils/Sanitizer';
import DragOption from './DragOption';
import styles from './DragToSortQuestion.module.css';

type Option = {
  id: string;
  label: string;
  position: number;
  answered?: number;
};

type Props = {
  id: string;
  question: string;
  options: Option[];
  feedback: {
    correct: string;
    incorrect: string;
  };
};

/**
 * A content block that renders a drag in the right order question.
 */
export const DragToSortQuestion: FC<Props> = ({
  id,
  question,
  options: defaultOptions,
  feedback,
}) => {
  useEffect(() => {
    Object.keys(defaultOptions).forEach((key) => {
      // eslint-disable-next-line no-param-reassign
      defaultOptions[key].position = +key;
    });
  }, [defaultOptions]);

  const [optionList, setOptionList] = useState<Option[]>(
    [...(defaultOptions || [])].sort(() => Math.random() - 0.5)
  );

  const { isValid, isValidated } = useBlock({
    id,
    touched: true,
    fields: optionList.map((option) => option.id),
    closed: true,
  });

  const toInt = (item: unknown) =>
    typeof item === 'string' ? parseInt(item, 10) : Number(item);
  const { subscribe, setValueById } = useForm();

  useEffect(
    () =>
      subscribe('load', (values) => {
        setOptionList(
          [...(defaultOptions || [])].sort((a, b) =>
            toInt(values[a.id]) < toInt(values[b.id]) ? -1 : 1
          )
        );
      }),
    [subscribe, defaultOptions]
  );

  useEffect(
    () =>
      subscribe('reset', () => {
        setOptionList(
          [...(defaultOptions || [])].sort(() => Math.random() - 0.5)
        );
      }),
    [subscribe, defaultOptions]
  );

  const handleMoveOption = useCallback((dragId: string, hoverIndex: number) => {
    setOptionList((prevOptions) => {
      const dragOptionIdx = prevOptions.findIndex((item) => item.id === dragId);
      const dragOption = { ...prevOptions[dragOptionIdx] };
      const dragOptionRemoved = [
        ...prevOptions.slice(0, dragOptionIdx),
        ...prevOptions.slice(dragOptionIdx + 1),
      ];

      return [
        ...dragOptionRemoved.slice(0, hoverIndex),
        dragOption,
        ...dragOptionRemoved.slice(hoverIndex),
      ];
    });
  }, []);

  const handleDrop = useCallback(() => {
    optionList.forEach((item, index) => setValueById(item.id, `${index}`));
  }, [optionList, setValueById]);

  return (
    <div className={styles.base}>
      <p>{question}</p>
      <DndProviderWithBackend>
        {optionList.map((option, i) => (
          <DragOption
            key={option.id}
            id={option.id}
            index={i}
            label={option.label}
            correctPosition={option.position}
            onMove={handleMoveOption}
            onDrop={handleDrop}
          />
        ))}
      </DndProviderWithBackend>
      <Callout
        correct={isValid}
        incorrect={!isValid}
        margin="2rem"
        hidden={
          !isValid && !feedback.incorrect
            ? true
            : !!(isValid && !feedback.correct) || !isValidated
        }
        className={styles.feedback}
        dangerouslySetInnerHTML={{
          __html: sanitizer(isValid ? feedback?.correct : feedback?.incorrect),
        }}
      />
    </div>
  );
};
