import React from 'react';
import { useCurrentLearningObjectAnswers } from 'Hooks';
import {
  useEnvironmentProvider,
  useLearningObjectProvider,
  useLearningSubjectProvider,
} from '../../../../../Providers';
import { FooterNavigation } from '../../FooterNavigation';
import { useAnswerGroupTurnInConfirmation } from '../HandInQuizHelper/useAnswerGroupTurnInConfirmation';
import { useQuestionNavigation } from '../useQuestionNavigation/useQuestionNavigation';

export const QuestionFooter = () => {
  const { learningObject } = useLearningObjectProvider();
  const { status } = useCurrentLearningObjectAnswers(learningObject);
  const { nextItem, currentItem, previousItem } = useQuestionNavigation();
  const { learningSubject } = useLearningSubjectProvider();
  const handleAnswerGroupSubmission = useAnswerGroupTurnInConfirmation();

  const { isStudentEnvironment } = useEnvironmentProvider();
  const canHandIn =
    !nextItem &&
    (!status || !['TURNED_IN', 'ACCEPTED', 'REJECTED'].includes(status)) &&
    isStudentEnvironment;

  return (
    <FooterNavigation
      nextItem={!canHandIn ? nextItem || learningSubject : undefined}
      previousItem={
        previousItem ||
        (currentItem && 'parent' in currentItem
          ? currentItem.parent
          : undefined)
      }
      currentItem={currentItem}
      home={false}
      onClickNext={
        canHandIn
          ? () => handleAnswerGroupSubmission(currentItem?.id)
          : undefined
      }
    />
  );
};
