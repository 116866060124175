import { Exception } from './Exception';

/**
 * UnauthenticatedException.
 *
 * Exception that can be thrown when the user is not logged in.
 */
export class UnauthenticatedException extends Exception {
  public name = 'User is not logged in.';

  public silent = true;

  public level = 'info' as const;

  public template = 'block' as const;

  public constructor() {
    super('User is not logged in.');
    this.props = {
      title: 'exceptions.unauthenticated.title',
      description: 'exceptions.not_found.description',
    };
  }
}
