import { UseQueryOptions } from 'react-query';
import useIndexCR from '../Factories/useIndexCR';
import useSingleCRUD from '../Factories/useSingleCRUD';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  LearningObject,
  LearningObjectCreateInput,
  LearningObjectFilters,
  LearningObjectIncludes,
  LearningObjectSorts,
  LearningObjectUpdateInput,
  UUID,
} from '../Types';

interface ILearningObjectProps
  extends HookParams<
    typeof LearningObjectIncludes,
    LearningObjectFilters,
    typeof LearningObjectSorts
  > {
  learningObject?: UUID;
}

export type UnenrolledLearningObject = Omit<LearningObject, 'userState'>;

export function useLearningObject(
  { learningObject, ...queryParameters }: ILearningObjectProps = {},
  settings?: UseQueryOptions<
    DataDocument<UnenrolledLearningObject>,
    ErrorDocument
  >
) {
  const context = useApi();
  const {
    parameters: { learningObject: defaultLearningObject },
  } = context;

  const request = new ApiRequest(
    {
      enabled: !!(learningObject || defaultLearningObject),
      baseName: 'v1.learningObjects',
      baseUri: '/v1/learning_objects',
      invalidate: [
        'learning_object',
        'learning_objects',
        'v1.enrollments.learningObjects',
      ],
      uri: `/${learningObject}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<UnenrolledLearningObject, LearningObjectUpdateInput>(
    request,
    settings
  );
}

export function useLearningObjects(
  queryParameters: Omit<ILearningObjectProps, 'learningObject'> = {},
  settings?: UseQueryOptions<
    DataDocument<UnenrolledLearningObject[]>,
    ErrorDocument
  >
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.learningObjects',
      baseUri: '/v1/learning_objects',
      invalidate: [
        'learning_object',
        'learning_objects',
        'v1.enrollments.learningObjects',
      ],
      queryParameters,
    },
    context
  );
  return useIndexCR<UnenrolledLearningObject, LearningObjectCreateInput>(
    request,
    settings
  );
}
