import React, { PropsWithChildren } from 'react';
import { ActionMenu } from '@brainstud/ui';
import classNames from 'classnames/bind';
import styles from './FullMediaCard.module.css';

export interface FullMediaCardProps {
  title: string;
  subtitle: string | JSX.Element;
  background?: string;
  className?: string;
  onClick?: () => void;
}

const cx = classNames.bind(styles);

export const FullMediaCard = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<FullMediaCardProps>
>(({ title, subtitle, className, onClick, background, children }, ref) => {
  const defaultThumbnail = '/media/default_exposition.svg';
  const thumbnail = background || defaultThumbnail;

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
      <div
        className={cx(styles.card, className, {
          isDefault: thumbnail === defaultThumbnail,
        })}
        onClick={onClick}
        style={{ backgroundImage: `url(${thumbnail})` }}
        ref={ref}
      >
        <div className={cx(styles.titles)}>
          <h4>{title}</h4>
          {subtitle && <h5>{subtitle}</h5>}
        </div>
        {children && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <div
            className={styles.actions}
            onClick={(event) => event.preventDefault()}
          >
            <ActionMenu id={title}>{children}</ActionMenu>
          </div>
        )}
      </div>
    </>
  );
});
