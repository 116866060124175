import { IResource } from '../Utils/Resource';
import { UUID } from '../Utils/UUID';
import { AnswerGroup } from './AnswerGroups';
import { LearningSubject, SchemeItem } from './LearningSubject';

type RetryStyleType = 'none' | 'question' | 'quiz' | 'subject';
type FeedbackStyleType =
  | 'none'
  | 'after_quiz'
  | 'after_object'
  | 'after_subject'
  | 'manually_released';

export interface Quiz extends IResource<'quiz'> {
  maxRetries?: number | null;
  retryStyle: RetryStyleType;
  feedbackStyle: FeedbackStyleType;
  type: 'end' | 'practice';
  timeLimitMinutes?: number;
  minimalScore: number;
  learningSubject?: () => LearningSubject;
  poster?: {
    fileName?: string;
    src?: string;
    type?: string;
  };
  answerGroups?: () => AnswerGroup[];
}

export interface QuizCreateInput {
  maxRetries?: number | null;
  retryStyle?: RetryStyleType;
  feedbackStyle: FeedbackStyleType;
  type?: 'end' | 'practice'; // TODO implement quiz types
  timeLimitMinutes?: number;
  minimalScore?: number;
  // Subject
  title: string;
  introduction: string;
  template?: string;
  poster_url?: string;
  layout?: string;
  scheme: SchemeItem[];
  relationships?: {
    learning_object_collection?: UUID;
    learning_route_node?: UUID;
    learning_subject?: UUID;
  };
}

export interface QuizUpdateInput extends QuizCreateInput {}

export enum QuizIncludes {
  learning_subject,
}

export enum QuizFilters {
  enrollment,
}

export enum QuizSorts {}
