import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { TTab } from '../../Blocks';
import { BlockRenderer } from '../BlockRenderer/BlockRenderer';
import styles from './VerticalTabs.module.css';

const cx = classNames.bind(styles);

interface VerticalTabsProps {
  tabs: TTab[];
  onClick?: () => void;
  style?: React.CSSProperties;
}

export const VerticalTabs = ({ tabs, onClick, style }: VerticalTabsProps) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className={cx(styles.base)} style={style}>
      <nav>
        {tabs.map((tab, tabIdx) => (
          <button
            type="button"
            className={cx({ active: tabIdx === activeTab })}
            // eslint-disable-next-line react/no-array-index-key
            key={tabIdx}
            onClick={() => {
              setActiveTab(tabIdx);
              onClick?.();
            }}
          >
            {tab.title}
          </button>
        ))}
      </nav>
      <div style={{ flex: 1 }}>
        {tabs.map((tab, index) =>
          activeTab === index ? (
            <BlockRenderer
              key={tab.title}
              id={tab.block.id}
              block={tab.block}
            />
          ) : null
        )}
      </div>
    </div>
  );
};
