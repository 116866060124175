import React, { useMemo, useState } from 'react';
import { useSchedule } from '@brainstud/academy-api';
import { Button, Checkbox, Input } from '@brainstud/ui';
import { Form } from '@brainstud/universal-components';
import { Grid } from '@mui/material';
import classNames from 'classnames/bind';
import { Loading } from 'Components';
import { useTranslator } from 'Providers/Translator';
import styles from './SettingSchedule.module.css';

const cx = classNames.bind(styles);
/**
 *
 * Currently not in Use, in future this component might be useful
 *
 */
export const SettingSchedule = () => {
  const [t] = useTranslator();
  const [{ data: schedule, createOrUpdate: updateSchedule }, { isLoading }] =
    useSchedule();
  const timeSlots = useMemo(
    () => schedule?.weeklyTimeSlots?.() || [],
    [schedule]
  );
  const [loading, setLoading] = useState(false);

  const weekDays = {
    monday: t('datetime.days.monday'),
    tuesday: t('datetime.days.tuesday'),
    wednesday: t('datetime.days.wednesday'),
    thursday: t('datetime.days.thursday'),
    friday: t('datetime.days.friday'),
    saturday: t('datetime.days.saturday'),
    sunday: t('datetime.days.sunday'),
  };

  type TWeekDays = keyof typeof weekDays;
  type TFormValues = {
    enabledDays: Array<TWeekDays>;
    mondayStart?: string;
    mondayEnd?: string;
    tuesdayStart?: string;
    tuesdayEnd?: string;
    wednesdayStart?: string;
    wednesdayEnd?: string;
    thursdayStart?: string;
    thursdayEnd?: string;
    fridayStart?: string;
    fridayEnd?: string;
    saturdayStart?: string;
    saturdayEnd?: string;
    sundayStart?: string;
    sundayEnd?: string;
  };

  const formValuesToPayload = (values: Partial<TFormValues>) =>
    (Object.keys(weekDays) as Array<TWeekDays>).map((day) => ({
      week_day_string: day,
      enabled: (values.enabledDays ?? []).includes(day),
      time_slot_start: values[`${day}Start`],
      time_slot_end: values[`${day}End`],
    }));

  const formData = useMemo(
    () =>
      timeSlots.reduce<TFormValues>(
        (value, timeSlot) => ({
          ...value,
          enabledDays: timeSlot.enabled
            ? value.enabledDays.concat(timeSlot.weekDayString as TWeekDays)
            : value.enabledDays,
          [`${timeSlot.weekDayString}Start`]:
            `${timeSlot.timeSlotStart}`.substring(0, 5),
          [`${timeSlot.weekDayString}End`]: `${timeSlot.timeSlotEnd}`.substring(
            0,
            5
          ),
        }),
        { enabledDays: [] }
      ),
    [timeSlots]
  );

  const save = async (values: Partial<TFormValues>) => {
    setLoading(true);
    return updateSchedule.mutateAsync(
      { complete_schedule: formValuesToPayload(values) },
      {
        onSettled: () => setLoading(false),
      }
    );
  };

  return isLoading ? (
    <Loading />
  ) : (
    <div className={cx('base')}>
      <h3>{t('menu.schedule')}</h3>
      <p>{t('components.settings_schedule.indicate_when_at_workplace')}</p>

      <Form<TFormValues> onSubmit={save} autoReset={false}>
        {({ values }) => (
          <>
            {(Object.entries(weekDays) as Array<[TWeekDays, string]>).map(
              ([day, label]) => (
                <Grid key={day} container spacing={2}>
                  <Grid item xs={12}>
                    <Checkbox
                      id={`${day}-enabled`}
                      name="enabledDays[]"
                      defaultValue={day}
                      checked={formData.enabledDays.includes(day)}
                      className={cx('checkbox-day')}
                    >
                      <div className={cx('label-text')}>{label}</div>

                      {values.enabledDays &&
                        values.enabledDays.includes(day) && (
                          <>
                            <Grid item xs={12} md={6}>
                              <span className={cx('time-inputs')}>
                                {t('datetime.from')}
                                :
                                <Input
                                  id={`${day}Start`}
                                  name={`${day}Start`}
                                  type="time"
                                  defaultValue={formData[`${day}Start`]}
                                  placeholder={t('datetime.from')}
                                  rules="regex:^[0-9]{2}:[0-9]{2}$"
                                />
                              </span>
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <span className={cx('time-inputs')}>
                                {t('datetime.until')}
                                :
                                <Input
                                  id={`${day}End`}
                                  name={`${day}End`}
                                  type="time"
                                  defaultValue={formData[`${day}End`]}
                                  placeholder={t('datetime.until')}
                                  rules="regex:^[0-9]{2}:[0-9]{2}$"
                                />
                              </span>
                            </Grid>
                          </>
                        )}
                    </Checkbox>
                  </Grid>
                </Grid>
              )
            )}
            <Button loading={loading} className={cx('primaryButton')} link>
              {t('save')}
            </Button>
          </>
        )}
      </Form>
    </div>
  );
};
