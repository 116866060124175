import React, { useCallback, useState } from 'react';
import { useApi, useScorm, UUID } from '@brainstud/academy-api';
import { Button, Input, SidebarModal, UploadBox } from '@brainstud/ui';
import classNames from 'classnames';
import { Loading, RichTextEditor } from 'Components';
import { Form } from 'Modules/universal-components';
import { useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import styles from './ScormUploadModal.module.css';

type TProps = {
  closeModal: () => void;
  courseId: UUID;
  scormId?: UUID;
  primary: boolean;
};

type TFormValues = {
  title: string;
  description: string;
};

const cx = classNames.bind(styles);

export const ScormUploadModal = ({
  closeModal,
  courseId,
  scormId,
  primary,
}: TProps) => {
  const { baseUrl, headers: apiHeaders } = useApi();
  const [temporaryFile, setTemporaryFile] = useState<UUID>();
  const [setToast] = useToaster();
  const [t] = useTranslator();
  const [{ data: scormPackage, createOrUpdate }, { isLoading }] = useScorm(
    { course: courseId, scorm: scormId },
    { enabled: !!scormId }
  );

  const handleSubmit = useCallback(
    (values: TFormValues) =>
      createOrUpdate.mutateAsync(
        {
          file: temporaryFile as string,
          ...values,
          primary,
          ...(!!scormId && { _method: 'patch' }),
        },
        {
          onSuccess: () => {
            setToast(t('modals.scorm.success'), 'success');
            closeModal();
          },
          onError: () => {
            setToast(t('modals.scorm.fail'), 'error');
          },
        }
      ),
    [createOrUpdate, temporaryFile, scormId, setToast, t, closeModal, primary]
  );

  return (
    <SidebarModal
      size="medium"
      closeable
      onClickOutside={closeModal}
      onClose={closeModal}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <Form<TFormValues> onSubmit={handleSubmit}>
          <SidebarModal.Header className={cx(styles.header)}>
            <h2>{t(`modals.scorm.title.${scormId ? 'update' : 'create'}`)}</h2>
            <Button
              type="submit"
              disabled={!scormId && temporaryFile === undefined}
              loading={createOrUpdate.isLoading}
            >
              {t('save')}
            </Button>
          </SidebarModal.Header>
          <div className={cx(styles.inputs)}>
            <Input
              defaultValue={scormPackage?.title}
              placeholder={t('title')}
              name="title"
              label={t('modals.scorm.input.title')}
              type="text"
            />
            <RichTextEditor
              id="description"
              label={t('description')}
              name="description"
              placeholder={t('optional')}
              defaultValue={scormPackage?.description}
            />
          </div>
          {!scormId && (
            <UploadBox
              maxFiles={1}
              paramName="file"
              label={t('views.scorm.upload.upload_box')}
              url={`${baseUrl}/v1/services/temporary_file_upload`}
              headers={{ ...apiHeaders, 'Content-Type': undefined }}
              onAfterFileUpload={(file, response) => {
                setTemporaryFile(response?.data?.id);
              }}
            />
          )}
        </Form>
      )}
    </SidebarModal>
  );
};
