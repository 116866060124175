import React, { useCallback, useState } from 'react';
import {
  OrderAutomationSteps,
  useOrderAutomationProduct,
  useProducts,
  UUID,
} from '@brainstud/academy-api';
import {
  Button,
  Checkbox,
  Dropdown,
  SidebarModal,
  Toggle,
} from '@brainstud/ui';
import classNames from 'classnames/bind';
import { FlexContainer, Loading } from 'Components';
import { Form } from 'Modules/universal-components';
import { useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import styles from './OrderAutomationProductFormModal.module.css';

const cx = classNames.bind(styles);

type Props = {
  /** The UUID of the order automation of which the active state is to be update */
  orderAutomationId?: UUID;
  closeModal: () => void;
};

type TFormValues = {
  roles: string[];
  step: string;
  enabled: boolean;
  product: UUID;
};

/**
 * A modal that allows you to create an order automation.
 */
export const OrderAutomationProductFormModal = ({
  orderAutomationId,
  closeModal,
}: Props) => {
  const [t] = useTranslator();
  const [setToast] = useToaster();

  const [{ create }] = useOrderAutomationProduct(
    {
      orderAutomation: orderAutomationId,
    },
    { enabled: false }
  );

  const [enabled, setEnabled] = useState(true);
  const [editRoles, setEditRoles] = useState<boolean>(false);

  const [{ data: products }, { isLoading }] = useProducts();

  const handleConfirm = useCallback(
    async (values: TFormValues) =>
      create.mutateAsync(
        {
          roles: values.roles,
          step: values.step,
          relationships: {
            product: values.product,
          },
          enabled,
        },
        {
          onSuccess: () => {
            setToast(
              t('modals.order_automations.create.success_message'),
              'success'
            );
            closeModal?.();
          },
          onError: (errorDocument) => {
            if (errorDocument.errors[0].source === undefined) {
              setToast(errorDocument.errors[0].detail, 'error');
            }
          },
        }
      ),
    [enabled, create, setToast, t, closeModal]
  );

  return (
    <SidebarModal
      className={cx(styles.base)}
      closeable
      size="medium"
      onClose={closeModal}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <Form<TFormValues> onSubmit={handleConfirm}>
          <SidebarModal.Header>
            <h1>{t('modals.order_automation_products.create.title')}</h1>
            <Button type="submit">{t('save')}</Button>
          </SidebarModal.Header>
          <FlexContainer gapSize="medium">
            <Toggle
              name="enabled"
              checked={enabled}
              onChange={() => setEnabled((prevValue) => !prevValue)}
            >
              {t('active')}
            </Toggle>

            <Dropdown
              block
              name="step"
              rules={['required']}
              placeholder={t('modals.order_automation_products.select_step')}
              label={t('trigger')}
            >
              {Object.values(OrderAutomationSteps)
                .filter((step) => Number.isNaN(Number(step)))
                .map((step) => (
                  <Dropdown.Option key={step} value={step}>
                    <span>
                      {t(`modals.order_automation_products.steps.${step}`)}
                    </span>
                  </Dropdown.Option>
                ))}
            </Dropdown>

            {/* add checkboxes for rules */}
            {editRoles ? (
              <fieldset>
                <legend>
                  {t('modals.order_automation_products.roles')}{' '}
                  <Button
                    link
                    onClick={() => setEditRoles((prev) => !prev)}
                    type="button"
                  >
                    X
                  </Button>
                </legend>

                <Checkbox
                  small
                  quiet
                  id={0}
                  name="roles[]"
                  defaultValue="student"
                  label={t('student')}
                />
                <Checkbox
                  quiet
                  small
                  id={1}
                  name="roles[]"
                  defaultValue="coach"
                  label={t('coach')}
                />
              </fieldset>
            ) : (
              <div>
                <legend>
                  <strong>{t('modals.order_automation_products.roles')}</strong>{' '}
                  <Button
                    link
                    type="button"
                    onClick={() => setEditRoles((prev) => !prev)}
                  >
                    {t('edit')}
                  </Button>
                </legend>
                <p>{t('modals.order_automation_products.all_roles')}</p>
              </div>
            )}

            <Dropdown
              block
              name="product"
              rules={['required']}
              placeholder={t('modals.order_automation_products.select_product')}
              label={t('product')}
            >
              {Object.values(products)?.map((product) => (
                <Dropdown.Option key={product.id} value={product.id}>
                  <span>{product.name}</span>
                </Dropdown.Option>
              ))}
            </Dropdown>
          </FlexContainer>
        </Form>
      )}
    </SidebarModal>
  );
};
