import React, { useEffect } from 'react';
import { useMe } from '@brainstud/academy-api';
import { Panel, Table, Td, Th, Tr } from '@brainstud/ui';
import { captureMessage } from '@sentry/nextjs';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import { Container } from '../../Components';
import { useSessionStorage, useUserData } from '../../Hooks';
import { getUserFriendlyBrowserNameAndVersion } from '../../Utils/getBrowser';
import styles from './InfoView.module.css';

const classes = classNames.bind(styles);

function simplifyUUID(uuid?: string) {
  return uuid && uuid.includes('-') ? uuid.slice(0, uuid.indexOf('-')) : uuid;
}

/**
 * InfoView.
 *
 * Shows a set of technical information about the user to help with debugging.
 */
export const InfoView = () => {
  const [t] = useTranslator();
  const [me] = useMe();
  const [account, { isLoading }] = useUserData();
  const entityId = account.entity?.().id;
  const { id: accountId, fullName, emailAddress } = account || {};
  const portal = me?.portal?.();
  const portalId = portal?.id;

  const { name, version } = getUserFriendlyBrowserNameAndVersion();
  const browserValue = `${name || ''} ${version || ''}`;

  const [loggedUser, setLoggedUser] = useSessionStorage(
    'logged-user',
    accountId
  );
  useEffect(() => {
    if (!!window && !!accountId && loggedUser !== accountId) {
      setLoggedUser(accountId);
      captureMessage(`${fullName} (${accountId}) requested info for support`, {
        level: 'info',
        user: {
          id: accountId,
          username: fullName,
          email: emailAddress,
        },
        extra: {
          account: accountId,
          fullName,
          entity: entityId,
          portal: portalId,
          browser: browserValue,
        },
      });
    }
  }, [
    accountId,
    fullName,
    emailAddress,
    entityId,
    browserValue,
    portalId,
    setLoggedUser,
    loggedUser,
  ]);

  return (
    <Container className={classes(styles.base)}>
      <Panel pad>
        <h1>{t('views.info.title')}</h1>
        <p>{t('views.info.description')}</p>
        {!isLoading && (
          <Table>
            <Table.THead>
              <Tr>
                <Th>#</Th>
                <Th>{t('views.info.table.item')}</Th>
                <Th>{t('views.info.table.identifier')}</Th>
                <Th>{t('views.info.table.value')}</Th>
              </Tr>
            </Table.THead>
            <Table.TBody>
              <Tr>
                <Td>1</Td>
                <Td>{t('views.info.table.items.identifier')}</Td>
                <Td>
                  <pre>{simplifyUUID(accountId)}</pre>
                </Td>
                <Td>
                  <pre>{simplifyUUID(fullName)}</pre>
                </Td>
              </Tr>
              <Tr>
                <Td>2</Td>
                <Td>{t('views.info.table.items.entity')}</Td>
                <Td>
                  <pre>{simplifyUUID(account.entity?.()?.id || 'Unknown')}</pre>
                </Td>
                <Td>
                  <pre>
                    {simplifyUUID(account.entity?.()?.name || 'Unknown')}
                  </pre>
                </Td>
              </Tr>
              <Tr>
                <Td>3</Td>
                <Td>{t('views.info.table.items.portal')}</Td>
                <Td>
                  <pre>{simplifyUUID(me?.portal?.().id)}</pre>
                </Td>
                <Td>
                  <pre>{simplifyUUID(me?.portal?.().name)}</pre>
                </Td>
              </Tr>
              <Tr>
                <Td>4</Td>
                <Td>{t('views.info.table.items.browser')}</Td>
                <Td colSpan={2} className={styles.browser}>
                  <pre>{browserValue.trim()}</pre>
                </Td>
              </Tr>
            </Table.TBody>
          </Table>
        )}
      </Panel>
    </Container>
  );
};
