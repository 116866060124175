import React, { useCallback, useState } from 'react';
import { Course, usePortalCourses } from '@brainstud/academy-api';
import classNames from 'classnames';
import { Container, Loading, Tabs, TabsItem } from 'Components';
import { useLocalStorage } from 'Hooks';
import { CourseList } from 'Modules/coach-panel/Components';
import { CourseGrid } from 'Modules/coach-panel/Components/CourseGrid/CourseGrid';
import { CourseListProvider } from 'Modules/coach-panel/Providers';
import CoursesSearchAndFilter from 'Modules/coach-panel/Views/Courses/CoursesSearchAndFilter/CoursesSearchAndFilter';
import { useRouter } from 'next/router';
import { useTranslator } from 'Providers/Translator';
import styles from './CourseIndexView.module.css';

type TSelectedCourses = 'myCourses' | 'allCourses';
const cx = classNames.bind(styles);

export const CourseIndexView = () => {
  const [t] = useTranslator();
  const router = useRouter();
  const [{ data: courses }, { isLoading }] = usePortalCourses();
  const [isGridView, setIsGridView] = useState(true);

  const [selectedCoursesTab, setSelectedCoursesTab] =
    useLocalStorage<TSelectedCourses>('selectedCoursesTab', 'myCourses');
  const isEnrolledCourses = selectedCoursesTab === 'myCourses';

  const CoursesInGridOrList = isGridView ? CourseGrid : CourseList;

  const handleCourseClick = useCallback(
    (course: Course) => {
      router.push(
        `${course.opener === 'app' ? '/apps' : '/coach/courses'}/${course.id}`
      );
    },
    [router]
  );

  return (
    <CourseListProvider
      uniqueStorageId="allCourses"
      courses={courses.filter((item) =>
        isEnrolledCourses ? !!item.enrollment : !item.enrollment
      )}
    >
      <Container>
        <h1>{t('components.catalog.title')}</h1>
      </Container>

      <Container className={cx(styles.navigation)}>
        <div>
          <Tabs>
            <TabsItem
              active={selectedCoursesTab === 'myCourses'}
              onClick={() => setSelectedCoursesTab('myCourses')}
            >
              {t('components.catalog.my_programs')}
            </TabsItem>
            <TabsItem
              active={selectedCoursesTab === 'allCourses'}
              onClick={() => setSelectedCoursesTab('allCourses')}
            >
              {t('components.catalog.all_programs')}
            </TabsItem>
          </Tabs>
        </div>

        <CoursesSearchAndFilter
          isGridView={isGridView}
          onToggleViewType={() => setIsGridView((prev) => !prev)}
        />
      </Container>

      {isLoading ? (
        <Loading />
      ) : (
        <>
          {isEnrolledCourses ? (
            <CoursesInGridOrList
              key="enrolledCourses"
              onCourseClick={handleCourseClick}
            />
          ) : (
            <CoursesInGridOrList key="otherCourses" />
          )}
        </>
      )}
    </CourseListProvider>
  );
};
