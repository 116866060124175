import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Student, useMe, useStudents, UUID } from '@brainstud/academy-api';
import {
  Button,
  Callout,
  CollapsibleRow,
  Indicator,
  Panel,
  Table,
  TdCheckbox,
  Th,
  Tr,
} from '@brainstud/ui';
import {
  useDataProvider,
  useDataSelection,
  usePagination,
} from '@brainstud/universal-components';
import { Add, Archive, People } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Container, Loading } from 'Components';
import { useRouter } from 'next/router';
import { useModals, useViewSettings, ViewSettings } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { getUUIDsFromString } from 'Utils/getUUIDsFromString';
import { ArchiveModal, GroupModal } from '../../Modals';
import { SelectionMenu } from '../SelectionMenu/SelectionMenu';
import { ExpandedStudentsRow } from './ExpandedStudentsRow';
import { StudentRow } from './StudentRow';
import styles from './StudentsTable.module.css';

const cx = classNames.bind(styles);

type Props = {
  showingArchived?: boolean;
  isFetching?: boolean;
  total?: number;
};

/**
 * StudentsTable
 *
 * This component expects a student list to be loaded in the DataProvider and then renders a table with the provided
 * students split up in groups.
 */
export const StudentsTable = ({
  showingArchived = false,
  isFetching,
  total = 0,
}: Props) => {
  const [t] = useTranslator();
  const { pathname: pathName, query } = useRouter();
  const { students: studentsInUrl } = query as { students: string };
  const { openModal, closeModal } = useModals();
  const [expandedIds, setExpandedIds] = useState<UUID[]>(
    getUUIDsFromString(studentsInUrl)
  );
  const [initialStudents, { isLoading }] = useDataProvider<Student>();
  const [students, setStudents] = useState<Student[]>([]);
  const [{ update }] = useStudents({}, { enabled: false });
  const [, dispatch] = useViewSettings(ViewSettings.STUDENT_OVERVIEW);

  useEffect(() => {
    setStudents([
      ...new Map(
        initialStudents.map((student) => [student.id, student])
      ).values(),
    ]);
  }, [initialStudents]);

  const { selection, handleToggleSelect, handleToggleSelectAll } =
    useDataSelection();

  const { filter } = usePagination();

  // Check if the selected participants are the coaches own participants (not assisting)
  const [me] = useMe();
  const selectedStudents = useMemo(
    () =>
      selection.map((selectedId: string) =>
        students.find((student) => student.id === selectedId)
      ),
    [students, selection]
  );

  const archiveableSelectedStudents = useMemo(
    () =>
      selectedStudents.filter(
        (item: Student | undefined) =>
          !!item?.coaches?.().some((coach) => coach.id === me?.account?.().id)
      ),
    [selectedStudents, me]
  );

  const isNotArchivable = archiveableSelectedStudents.length === 0;
  const isSelectionPartiallyArchivable =
    archiveableSelectedStudents.length > 0 &&
    archiveableSelectedStudents.length !== selectedStudents.length;

  const handleToggle = useCallback((rowId: UUID) => {
    setExpandedIds((prevExpandedIds) =>
      prevExpandedIds.includes(rowId)
        ? prevExpandedIds.filter((id) => id !== rowId)
        : [...prevExpandedIds, rowId]
    );
  }, []);

  const studentCount = students.length;
  useEffect(() => {
    if (filter !== undefined && Object.keys(filter).length !== 0) {
      setExpandedIds([]);
    }
  }, [filter, studentCount]);

  const handleSort = useCallback(
    (sortOn?: string) => {
      dispatch({ type: 'TOGGLE_SORT', payload: { value: sortOn || '' } });
    },
    [dispatch]
  );

  return isLoading ? (
    <Loading />
  ) : (
    <div className={cx(styles.base)}>
      <Container>
        <strong className={cx(styles.count)}>
          {t('students.total_count', { total })}
        </strong>
        {students.length !== 0 && (
          <Panel>
            <Table onSort={handleSort}>
              <Table.THead>
                <Tr>
                  {isFetching ? (
                    <Th style={{ width: '2rem' }}>
                      {isFetching && <Indicator loading />}
                    </Th>
                  ) : (
                    <TdCheckbox id={students.map((item) => item.id)} />
                  )}
                  <Th sortOn="full_name">{t('student')}</Th>
                  <Th />
                  <Th sortOn="last_online">{t('students.lastActive')}</Th>
                  <Th />
                </Tr>
              </Table.THead>
              <Table.TBody>
                {students.map((student) => (
                  <CollapsibleRow
                    key={student.id}
                    isSelected={selection.includes(student.id)}
                    isExpanded={expandedIds.includes(student.id)}
                    data={student}
                    onToggle={handleToggle}
                    onToggleSelect={handleToggleSelect}
                    rowComponent={StudentRow}
                    expandedRowComponent={ExpandedStudentsRow}
                  />
                ))}
              </Table.TBody>
            </Table>
          </Panel>
        )}
      </Container>
      {!students.length &&
        (!Object.keys(filter || {}).length ? (
          <Container>
            {showingArchived ? (
              <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
                {t('students.no_archived_students')}
              </p>
            ) : (
              <Callout info style={{ margin: '2rem 0' }}>
                <div>{t('students.noStudents')}</div>
                {pathName === '/coach/students' && (
                  <Button
                    to="/coach/courses?expanded=true"
                    style={{ marginTop: '1rem' }}
                  >
                    <span>{t('students.add_course')}</span>
                    <Add fontSize="large" />
                  </Button>
                )}
              </Callout>
            )}
          </Container>
        ) : (
          <Container>
            <p className={cx(styles.no_match_message)}>
              {t('students.no_students_match')}
            </p>
          </Container>
        ))}
      <SelectionMenu open={selection.length > 0}>
        <button
          type="button"
          onClick={() =>
            openModal(GroupModal, {
              students: students.filter((s) => selection.includes(s.id)),
              onComplete: () => handleToggleSelectAll([]),
            })
          }
        >
          <span>{t('students.group')}</span>
          <People />
        </button>
        <button
          type="button"
          onClick={() =>
            openModal(ArchiveModal, {
              accountIds: archiveableSelectedStudents.map(
                (student) => student?.id || ''
              ),
              unarchive: showingArchived,
              handleToggleSelect,
              disabled: isNotArchivable && !isSelectionPartiallyArchivable,
              onComplete: () => handleToggleSelectAll([]),
              closeModal,
              onArchive: update.mutateAsync,
              ...(isNotArchivable || isSelectionPartiallyArchivable
                ? {
                    warning: t(
                      `views.students.${showingArchived ? 'unarchive' : 'archive'}.warning.${isSelectionPartiallyArchivable ? 'partial' : 'full'}`
                    ),
                  }
                : {}),
            })
          }
        >
          <span>
            {showingArchived ? t('students.dearchive') : t('students.archive')}
          </span>
          <Archive />
        </button>
      </SelectionMenu>
    </div>
  );
};
