import {
  LearningObject,
  LearningRouteNode,
  LearningSubject,
} from '@brainstud/academy-api';
import { ILearningRouteGroup } from 'Modules/course-editor/LearningRouteEditor';
import {
  ILearningRouteNodeWithResources,
  ILearningSubjectWithResources,
} from 'Providers';

export type Node =
  | ILearningRouteGroup
  | LearningRouteNode
  | LearningObject
  | LearningSubject
  | ILearningSubjectWithResources
  | ILearningRouteNodeWithResources;

export function isObject(object?: Node): object is LearningObject {
  return object?.resourceType === 'learning_objects';
}

export function isSubject(
  object?: Node
): object is LearningSubject | ILearningSubjectWithResources {
  return object?.resourceType === 'learning_subjects';
}

export function isGroup(object?: Node): object is LearningRouteNode {
  return !!(object && 'scheme' in object);
}

export function isContainer(
  object?: Node
): object is LearningRouteNode | LearningSubject {
  return isGroup(object) || isSubject(object);
}
