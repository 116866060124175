import React from 'react';
import { Container } from 'Components';
import { useTranslator } from 'Providers/Translator';
import styles from './Partners.module.css';

interface PartnerProps {
  title: string;
  image: {
    alt: string;
    src: string;
  };
}

const PartnersBlock = ({ partners }: { partners: PartnerProps[] }) => {
  const [t] = useTranslator();

  return (
    <div className={styles.partners}>
      <Container>
        <h2>{t('components.partners.partners')}</h2>
        <div className={styles.logos}>
          {partners.map((partner) => (
            <img
              alt={partner.title}
              key={partner.title + partner.image}
              src={partner.image?.src}
            />
          ))}
        </div>
      </Container>
    </div>
  );
};

export default PartnersBlock;
