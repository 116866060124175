import { useContext } from 'react';
import { BreadcrumbsContext, IBreadcrumbsContext } from './BreadcrumbsContext';

/**
 * useBreadcrumbsProvider.
 *
 * [DESCRIPTION]
 *
 * @param nullable When true, this hook returns null when no context is found.
 */
export function useBreadcrumbsProvider(nullable?: false): IBreadcrumbsContext;
export function useBreadcrumbsProvider(
  nullable?: true
): null | IBreadcrumbsContext;
export function useBreadcrumbsProvider(nullable = false) {
  const context = useContext(BreadcrumbsContext);

  if (!context && !nullable) {
    throw new Error('Please, initiate the BreadcrumbsProvider first!');
  }

  return context;
}
