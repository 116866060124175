import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  Skill,
  SkillCreate,
  SkillFilters,
  SkillIncludes,
  SkillSorts,
  SkillUpdate,
  UUID,
} from '../Types';

interface ISkillProps
  extends HookParams<
    typeof SkillIncludes,
    typeof SkillFilters,
    typeof SkillSorts
  > {
  skill?: UUID;
}

export function useSkill(
  { skill, ...queryParameters }: ISkillProps = {},
  settings?: UseQueryOptions<DataDocument<Skill>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { skill: defaultSkill },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(skill || defaultSkill),
      baseName: 'v1.skills',
      baseUri: '/v1/skills',
      invalidate: ['skill', 'skills'],
      uri: `/${skill || defaultSkill}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<Skill, SkillCreate, SkillUpdate>(request, settings);
}

export function useSkills(
  { ...queryParameters }: Omit<ISkillProps, 'skill'> = {},
  settings?: UseQueryOptions<DataDocument<Skill[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.skills',
      baseUri: '/v1/skills',
      invalidate: ['skill', 'skills'],
      queryParameters,
    },
    context
  );
  return useIndexCR<Skill, SkillCreate>(request, settings);
}
