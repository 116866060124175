import React, { useEffect, useState } from 'react';
import { IPageIntroductionBlock } from '@brainstud/course-blocks';
import { RichTextEditor } from 'Components';
import { shortId } from 'Utils';

export type SubtitleFormProps = {
  data: IPageIntroductionBlock;
  onChange: (e: Omit<IPageIntroductionBlock, 'id' | 'type'>) => void;
};

export const SubtitleForm = ({ data, onChange }: SubtitleFormProps) => {
  const [subtitleText, setSubtitleText] = useState(data?.content);

  useEffect(() => {
    onChange?.({
      content: subtitleText,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subtitleText]);

  return (
    <RichTextEditor
      id={shortId(data?.id)}
      name={shortId(data?.id)}
      label="Introtekst"
      preset="inline"
      onChange={setSubtitleText}
      defaultValue={data?.content}
    />
  );
};
