import { DateTimeString } from '../Utils/DateTimeString';
import { IResource } from '../Utils/Resource';

export interface Notification extends IResource<'notifications'> {
  link: string;
  message: string;
  readAt: DateTimeString;
  receivedAt: DateTimeString;
}
export interface NotificationCreate {
  link: string;
  message: string;
}

export interface NotificationBulkUpdate {
  _method: 'patch';
  operation: 'mark_all_as_read';
}

export interface NotificationUpdate extends NotificationCreate {
  readAt: DateTimeString;
}

export enum NotificationIncludes {}
export enum NotificationFilters {}
export enum NotificationSort {}
