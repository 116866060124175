import { useCallback, useState } from 'react';
import { UseMutateAsyncFunction } from 'react-query';
import { UUID } from '@brainstud/academy-api';
import { Button, Callout, ContentModal } from '@brainstud/ui';
import { useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';

type TProps = {
  /** The account IDs that get archived/unarchived */
  accountIds?: UUID[];
  /** Indication that we should archive or unarchive the given account IDs */
  unarchive: boolean;
  /** An optional warning text to display in a warning callout */
  warning?: string;
  /** Optionally set the archive button to disabled so the action cannot be performed */
  disabled?: boolean;
  /** A function called on closing of the modal */
  closeModal: () => void;
  /** A function that is called with the given account IDs after the mutation has been processed */
  handleToggleSelect: (accountIds: string[]) => void;
  /** An action to be performed when the modal is submitted, gets called before closing the modal. */
  onComplete?: () => void;
  /** The function to execute when (de)archiving */
  onArchive: UseMutateAsyncFunction<unknown, unknown, Variables>;
};

type Variables = {
  _method: 'patch';
  operation: 'unarchive' | 'archive';
  accounts: string[];
};

/**
 * ArchiveModal.
 *
 * A Modal which can be used to (de)archive account IDs
 */
const ArchiveModal = ({
  accountIds = [],
  unarchive = false,
  warning,
  disabled = false,
  closeModal,
  handleToggleSelect,
  onComplete,
  onArchive,
}: TProps) => {
  const [t] = useTranslator();
  const [setToast] = useToaster();
  const [loading, setLoading] = useState(false);

  const operation = unarchive ? 'unarchive' : 'archive';

  const handleArchive = useCallback(async () => {
    setLoading(true);

    await onArchive(
      {
        _method: 'patch',
        operation,
        accounts: accountIds,
      },
      {
        onSuccess: () => {
          setLoading(false);
          handleToggleSelect(accountIds);
          onComplete?.();
          closeModal();
          setToast(
            t(`modals.archiveModal.${operation}.toastConfirm`),
            'success'
          );
        },
        onError: () => {
          setLoading(false);
          closeModal();
          setToast(t('error'), 'error');
        },
      }
    );
  }, [
    closeModal,
    handleToggleSelect,
    onComplete,
    operation,
    setToast,
    accountIds,
    t,
    onArchive,
  ]);

  return (
    <ContentModal
      onClickOutside
      closeModal={closeModal}
      style={{ maxWidth: '65rem' }}
    >
      <h2>{t(`modals.archiveModal.${operation}.title`)}</h2>
      <p
        dangerouslySetInnerHTML={{
          __html: sanitizer(
            t(`modals.archiveModal.${operation}.body`, {
              count: accountIds.length,
              link: 'https://allyoucanlearn.zendesk.com/hc/nl/articles/4930176329373-Deelnemers-archiveren',
            })
          ),
        }}
      />
      {warning && (
        <Callout warning style={{ marginBottom: '2rem' }}>
          <strong>
            {t(`modals.archiveModal.${operation}.callout_title.warning`)}
          </strong>
          <p>{warning}</p>
        </Callout>
      )}
      <footer>
        <Button
          type="button"
          onClick={handleArchive}
          loading={loading}
          style={{ marginRight: '1rem' }}
          disabled={disabled}
        >
          {t(`modals.archiveModal.${operation}.confirm`)}
        </Button>
        <Button type="button" quiet onClick={closeModal}>
          {t('modals.cancel')}
        </Button>
      </footer>
    </ContentModal>
  );
};

export default ArchiveModal;
