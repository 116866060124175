import { UseQueryOptions } from 'react-query';
import { useIndex } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  DossierCoreTask,
  DossierCoreTaskFilters,
  DossierCoreTaskIncludes,
  DossierCoreTaskSorts,
  HookParams,
} from '../Types';

interface ILearningObjectCollectionDossierCokeTaskProps
  extends HookParams<
    typeof DossierCoreTaskIncludes,
    typeof DossierCoreTaskFilters,
    typeof DossierCoreTaskSorts
  > {
  learningObjectCollection: string;
}

export function useLearningObjectCollectionDossierCoreTasks(
  {
    learningObjectCollection,
    ...queryParameters
  }: ILearningObjectCollectionDossierCokeTaskProps,
  settings?: UseQueryOptions<DataDocument<DossierCoreTask[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      enabled: true,
      baseName: 'v1.learning_object_collections.core_tasks',
      baseUri: `/v1/learning_object_collections/${learningObjectCollection}/core_tasks`,
      invalidate: ['qualifications'],
      queryParameters,
    },
    context
  );
  return useIndex<DossierCoreTask>(request, settings);
}
