import { useMemo } from 'react';
import {
  QueryObserverResult,
  UseMutationResult,
  UseQueryOptions,
} from 'react-query';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import { IResource } from '../Types';
import { IBulkDestroy, IBulkUpdate } from '../Types/Utils/BulkInterfaces';
import { UpdateMethods } from '../Types/Utils/UpdateMethods';
import useCreate from './useCreate';
import useDestroy from './useDestroy';
import useIndex from './useIndex';
import useUpdate from './useUpdate';

type TOutputFormat<
  Resource extends IResource,
  CreateInput,
  BulkUpdateInput,
  BulkDestroyInput,
> = [
  {
    data: Resource[];
    create: UseMutationResult<
      DataDocument<Resource>,
      ErrorDocument,
      CreateInput
    >;
    update: UseMutationResult<
      DataDocument<Resource>,
      ErrorDocument,
      BulkUpdateInput & UpdateMethods
    >;
    destroy: UseMutationResult<
      DataDocument<Resource>,
      ErrorDocument,
      BulkDestroyInput
    >;
  },
  QueryObserverResult<DataDocument<Resource[]>, ErrorDocument>,
];

/**
 * A factory method that can be used for bulk queries
 */
export function useIndexCRUD<
  Resource extends IResource,
  CreateInput,
  BulkUpdateInput extends void | IBulkUpdate,
  BulkDestroyInput extends void | IBulkDestroy = void,
>(
  request: ApiRequest,
  settings?: UseQueryOptions<DataDocument<Resource[]>, ErrorDocument>
): TOutputFormat<Resource, CreateInput, BulkUpdateInput, BulkDestroyInput> {
  const [data, responseIndex] = useIndex<Resource>(request, settings);

  const create = useCreate<Resource, CreateInput>(request);

  const update = useUpdate<Resource, BulkUpdateInput>(request);

  const destroy = useDestroy<Resource, BulkDestroyInput>(request);

  return useMemo(
    () => [
      {
        data,
        create,
        update,
        destroy,
      },
      responseIndex,
    ],
    [data, create, update, destroy, responseIndex]
  );
}
