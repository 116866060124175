import { UseQueryOptions } from 'react-query';
import { useShow } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import { ICourseAuthorization, UUID } from '../Types';

interface ICourseAuthorizationProps {
  course?: UUID;
}

/**
 * useCourseAuthorize.
 *
 * Hook used for authorization for a specific course. Used primarily for external content providers.
 */
export function useCourseAuthorize(
  { course, ...queryParameters }: ICourseAuthorizationProps = {},
  settings?: UseQueryOptions<DataDocument<ICourseAuthorization>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { course: defaultCourse },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(course || defaultCourse),
      baseName: 'v1.courses.authorize',
      baseUri: '/v1/courses',
      uri: `/${course || defaultCourse}/authorize`,
      queryParameters,
    },
    context
  );
  return useShow<ICourseAuthorization>(request, settings);
}
