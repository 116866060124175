import React, { useCallback, useEffect, useState } from 'react';
import { IRichListBlock } from '@brainstud/course-blocks';
import { Dropdown } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import { v4 } from 'uuid';
import { AddItemButton } from '../../../Components/AddItemButton/AddItemButton';
import { ListItemForm } from './ListItemForm';
import styles from './RichListForm.module.css';

export type RichListFormProps = {
  data: IRichListBlock;
  onChange: (e: Partial<IRichListBlock>) => void;
};

const cx = classNames.bind(styles);

type TItem = IRichListBlock['items'][0];

export const RichListForm = ({ data, onChange }: RichListFormProps) => {
  const [t] = useTranslator();
  const [listStyle, setListStyle] = useState(data?.listStyle || 'ul');
  const [items, setItems] = useState<IRichListBlock['items']>(
    data?.items || []
  );

  useEffect(() => {
    onChange?.({
      items,
      listStyle,
      numbered: listStyle === 'ol',
    });
  }, [listStyle, items]);

  const handleAddItem = useCallback(() => {
    setItems((prevItems) => [...prevItems, { id: v4(), content: '' }]);
  }, []);

  const handleUpdateItem = useCallback((idx: number, item?: Partial<TItem>) => {
    if (!item) {
      setItems((prevItems) => {
        prevItems.splice(idx, 1);
        return [...prevItems];
      });
    } else {
      setItems((prevItems) => {
        const newItems = prevItems.slice();
        newItems[idx] = { ...prevItems[idx], ...item };
        return newItems;
      });
    }
  }, []);

  return (
    <div>
      <Dropdown
        onChange={(value) =>
          setListStyle((value as IRichListBlock['listStyle']) || 'ul')
        }
        value={listStyle}
        className={cx(styles.dropdown)}
      >
        <Dropdown.Option value="ul">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="currentColor"
          >
            <path d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM8 19h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zm0-6h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zM7 6c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1z" />
          </svg>
          {/* <span>{t('course-editor.content_editor.blocks.rich_list.options.ul')}</span> */}
        </Dropdown.Option>
        <Dropdown.Option value="ol">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="currentColor"
          >
            <path d="M8 7h12c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zm12 10H8c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1zm0-6H8c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1zM4.5 16h-2c-.28 0-.5.22-.5.5s.22.5.5.5H4v.5h-.5c-.28 0-.5.22-.5.5s.22.5.5.5H4v.5H2.5c-.28 0-.5.22-.5.5s.22.5.5.5h2c.28 0 .5-.22.5-.5v-3c0-.28-.22-.5-.5-.5zm-2-11H3v2.5c0 .28.22.5.5.5s.5-.22.5-.5v-3c0-.28-.22-.5-.5-.5h-1c-.28 0-.5.22-.5.5s.22.5.5.5zm2 5h-2c-.28 0-.5.22-.5.5s.22.5.5.5h1.3l-1.68 1.96c-.08.09-.12.21-.12.32v.22c0 .28.22.5.5.5h2c.28 0 .5-.22.5-.5s-.22-.5-.5-.5H3.2l1.68-1.96c.08-.09.12-.21.12-.32v-.22c0-.28-.22-.5-.5-.5z" />
          </svg>
          {/* <span>{t('course-editor.content_editor.blocks.rich_list.options.ol')}</span> */}
        </Dropdown.Option>
        <Dropdown.Option value="icons">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="currentColor"
          >
            <path d="M4,14h2c0.55,0,1-0.45,1-1v-2c0-0.55-0.45-1-1-1H4c-0.55,0-1,0.45-1,1v2C3,13.55,3.45,14,4,14z M4,19h2c0.55,0,1-0.45,1-1 v-2c0-0.55-0.45-1-1-1H4c-0.55,0-1,0.45-1,1v2C3,18.55,3.45,19,4,19z M4,9h2c0.55,0,1-0.45,1-1V6c0-0.55-0.45-1-1-1H4 C3.45,5,3,5.45,3,6v2C3,8.55,3.45,9,4,9z M9,14h11c0.55,0,1-0.45,1-1v-2c0-0.55-0.45-1-1-1H9c-0.55,0-1,0.45-1,1v2 C8,13.55,8.45,14,9,14z M9,19h11c0.55,0,1-0.45,1-1v-2c0-0.55-0.45-1-1-1H9c-0.55,0-1,0.45-1,1v2C8,18.55,8.45,19,9,19z M8,6v2 c0,0.55,0.45,1,1,1h11c0.55,0,1-0.45,1-1V6c0-0.55-0.45-1-1-1H9C8.45,5,8,5.45,8,6z" />
          </svg>
          {/* <span>{t('course-editor.content_editor.blocks.rich_list.options.icons')}</span> */}
        </Dropdown.Option>
      </Dropdown>
      <div className={cx(styles.items)}>
        {items.map((item, idx) => (
          <ListItemForm
            key={item.id || item.content}
            index={idx}
            item={item}
            listStyle={listStyle}
            onChange={handleUpdateItem}
          />
        ))}
      </div>
      <div className={styles.add}>
        <AddItemButton
          title={`${t('course-editor.content_editor.blocks.rich_list.add_item')} ${items.length + 1}`}
          onClick={handleAddItem}
        />
      </div>
    </div>
  );
};
