import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import {
  ParentType,
  TNodeListItem,
} from 'Providers/LearningRouteProvider/LearningRouteContext';
import { HIDDEN_LAYOUT_NAME } from '../../Views/CollectionViews/Layouts';

/**
 * useRouteNavigation.
 *
 * Based on the IDs provided in the URL it will calculate the previous, current and next item in the learning route.
 * @param list a list of Nodes that is uses to generate the pageTree
 * @param typeToSkip let the pageTree skip a given type
 */
export const useRouteNavigation = (
  list: Array<TNodeListItem>,
  typeToSkip?: 'learning_subjects' | 'learning_objects'
) => {
  const router = useRouter();

  const { learningRouteNodeId, learningSubjectId, learningObjectId } =
    router.query as {
      learningRouteNodeId?: string;
      learningSubjectId?: string;
      learningObjectId?: string;
    };

  const currentItemId =
    learningRouteNodeId || learningSubjectId || learningObjectId;

  const currentItem = list?.find((listItem) => listItem.id === currentItemId);
  const currentItemIndex = list?.findIndex(
    (item) => item.id === currentItem?.id
  );

  const getAdjacentItem = (
    currentIndex: number,
    steps: number
  ): TNodeListItem | undefined => {
    const adjacentIndex = currentIndex + steps;

    const adjacentItem = list[adjacentIndex];
    if (
      adjacentItem?.resourceType === typeToSkip ||
      (adjacentItem &&
        'layout' in adjacentItem &&
        adjacentItem.layout === HIDDEN_LAYOUT_NAME)
    ) {
      return getAdjacentItem(adjacentIndex, steps);
    }
    return adjacentItem;
  };

  const nextItem =
    currentItemIndex !== null && currentItemIndex < list.length - 1
      ? getAdjacentItem(currentItemIndex, 1)
      : undefined;

  const previousItem =
    currentItemIndex !== null && currentItemIndex > 0
      ? getAdjacentItem(currentItemIndex, -1)
      : undefined;

  const getRootItem = useCallback(
    (parent?: ParentType): TNodeListItem | undefined => {
      const item = parent || currentItem;
      if (item) {
        return 'parent' in item && item?.parent
          ? getRootItem(item.parent)
          : item;
      }
      return undefined;
    },
    [currentItem]
  );

  const rootItem = useMemo(() => getRootItem(), [getRootItem]);

  return {
    currentItem,
    nextItem,
    previousItem,
    rootItem,
  };
};
