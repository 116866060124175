export const StatusColors = {
  ACCEPTED: 'green',
  APPROVED: 'green',
  COMPLETED_APPROVED: 'green',
  COMPLETED_REJECTED: 'red',
  CONCEPT: 'grey',
  CREATED: 'grey',
  HANDED_IN: 'yellow',
  IN_PROGRESS: 'yellow',
  IN_PRE_ASSESSMENT: 'blue',
  OPEN: 'grey',
  PRE_ASSESSMENT_REJECTED: 'red',
  PRE_ASSESSMENT_APPROVED: 'yellow',
  PUBLISHED: 'yellow',
  REJECTED: 'red',
  TURNED_IN: 'yellow',
} as const;

export const SampleSetStatusColors = {
  ...StatusColors,
  PRE_ASSESSMENT_APPROVED: 'green',
} as const;
