import React, { CSSProperties, FC, MouseEventHandler } from 'react';
import { AddRounded } from '@mui/icons-material';
import classNames from 'classnames/bind';
import styles from './AddItemButton.module.css';

const cx = classNames.bind(styles);

type Props = {
  title: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  style?: CSSProperties;
};

/**
 *
 */
export const AddItemButton: FC<Props> = ({
  onClick,
  className,
  style,
  title,
}) => (
  <button
    type="button"
    onClick={onClick}
    className={cx(styles.base, className)}
    style={style}
  >
    <AddRounded />
    <span>{title}</span>
  </button>
);
