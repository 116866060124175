import { AnswerFeedback } from '@brainstud/academy-api';
import { Status } from '@brainstud/ui';
import { Thread } from '@brainstud/ui/Comments/Thread';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import { DateFormatter } from 'Utils/DateFormatHelper';
import { ActivityTitle } from '../ActivityTitle';
import styles from '../ActivityFeed.module.css';

const cx = classNames.bind(styles);

type TProps = {
  feedback?: AnswerFeedback;
  isOpen: boolean;
  onOpen: (threadId: string) => void;
};

export const ActivityFeedAnswerFeedback = ({
  feedback,
  isOpen,
  onOpen,
}: TProps) => {
  const [t, { ucf }] = useTranslator();

  const statusOptions = {
    TURNED_IN: ucf(t('states.turned_in')),
    REJECTED: ucf(t('states.rejected')),
    ACCEPTED: ucf(t('states.accepted')),
    OPEN: ucf(t('states.accepted')),
    CONCEPT: ucf(t('states.accepted')),
  };

  return feedback ? (
    <>
      <ActivityTitle id={feedback.id}>
        <span>{t('components.activity_feed.assignment_graded')}</span>
        <span className={cx(styles.timestamp)}>
          {DateFormatter.toTime(feedback.createdAt)}
        </span>
      </ActivityTitle>
      <Thread
        author={feedback.account?.().fullName}
        isExpanded={isOpen}
        onToggleActions={onOpen}
        attachments={feedback.files?.()}
        thread={{
          ...feedback,
          content: feedback.givenFeedback,
          type: 'answer_feedback',
          commentableType: 'answer_feedback',
          resourceType: 'comment',
        }}
        subheader={
          <Status
            inline
            scheme={feedback.givenStatus === 'ACCEPTED' ? 'green' : 'red'}
          >
            {statusOptions[feedback.givenStatus]}
          </Status>
        }
      />
    </>
  ) : null;
};
