import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  LtiResourceLink,
  LtiResourceLinkCreateInput,
  LtiResourceLinkFilters,
  LtiResourceLinkIncludes,
  LtiResourceLinkSorts,
  LtiResourceLinkUpdateInput,
  UUID,
} from '../Types';

interface ILtiResourceLinkProps
  extends HookParams<
    typeof LtiResourceLinkIncludes,
    typeof LtiResourceLinkFilters,
    typeof LtiResourceLinkSorts
  > {
  ltiResourceLink?: UUID;
}

/**
 * LtiResourceLink is a link used to register the integration of a tool deployment in the platform.
 */
export function useLtiResourceLink(
  { ltiResourceLink, ...queryParameters }: ILtiResourceLinkProps = {},
  settings?: UseQueryOptions<DataDocument<LtiResourceLink>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { ltiResourceLink: defaultLtiResourceLink },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(ltiResourceLink || defaultLtiResourceLink),
      baseName: 'v1.ltiResourceLinks',
      baseUri: `/v1/lti_resource_links`,
      uri: `/${ltiResourceLink}`,
      invalidate: ['ltiResourceLinks'],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    LtiResourceLink,
    LtiResourceLinkCreateInput,
    LtiResourceLinkUpdateInput
  >(request, settings);
}

/**
 * LtiResourceLink is a link used to register the integration of a tool deployment in the platform
 */
export function useLtiResourceLinks(
  { ...queryParameters }: Omit<ILtiResourceLinkProps, 'ltiResourceLink'> = {},
  settings?: UseQueryOptions<DataDocument<LtiResourceLink[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.ltiResourceLinks',
      baseUri: `/v1/lti_resource_links`,
      invalidate: ['ltiResourceLinks'],
      queryParameters,
    },
    context
  );
  return useIndexCR<LtiResourceLink, LtiResourceLinkCreateInput>(
    request,
    settings
  );
}
