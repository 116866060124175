import { createReactReducer } from 'Utils/createReactReducer';
import ACTIONS from './LearningObjectActions';

export interface ILearningObjectState {
  varietyId?: string;
}

type TSetVariety = {
  type: typeof ACTIONS.SET_VARIETY;
  payload: {
    id: string;
  };
};

export type TLearningObjectActions = TSetVariety;

export const InitialLearningObjectState: ILearningObjectState = {
  varietyId: undefined,
};

export const LearningObjectReducer = createReactReducer<
  ILearningObjectState,
  TLearningObjectActions
>({
  [ACTIONS.SET_VARIETY]: ({ payload }) => ({
    varietyId: payload.id,
  }),
});
