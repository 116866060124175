import { DateTimeString } from '../Utils/DateTimeString';
import { IResource } from '../Utils/Resource';

export type RatingTypes = 'text' | 'boolean' | 'numeric';

type RatingMap = {
  [key in RatingTypes]: string;
};

export const ratingTypesMap: RatingMap = {
  text: 'Text',
  boolean: 'Boolean',
  numeric: 'Numeric',
};

export interface Feedback extends IResource<'feedback'> {
  ratingType: RatingTypes;
  rating: string | number;
  createdAt: DateTimeString;
  content?: string;
}

export interface FeedbackCreateInput {
  rating_type: RatingTypes;
  rating: string | number;
  content?: string;
}

export interface FeedbackUpdateInput extends FeedbackCreateInput {}
export enum FeedbackIncludes {}
export enum FeedbackFilters {}
export enum FeedbackSorts {}
