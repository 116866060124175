import { useEffect, useRef, useState } from 'react';

/**
 * useSticky
 *
 * Adds sticky behavior observer for a specified element (set by stickyRef), you need to apply position:sticky; to make it work.
 * This hook wil return the ref(Element) and sticky(boolean)
 */
export const useSticky = () => {
  const stickyRef = useRef<HTMLDivElement>(null);
  const [isSticking, setIsSticking] = useState(false);

  useEffect(() => {
    const refElement = stickyRef.current;
    if (refElement) {
      const observer = new IntersectionObserver(
        ([e]) => setIsSticking(e.boundingClientRect.top < 1),
        {
          threshold: [1],
        }
      );
      observer.observe(refElement);
      return () => {
        observer.unobserve(refElement);
      };
    }
  }, []);
  return { stickyRef, isSticking };
};
