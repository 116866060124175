import React, { useCallback, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  useApi,
  useMe,
  useTwoFactorAuthentication,
} from '@brainstud/academy-api';
import axios, { AxiosRequestHeaders } from 'axios';
import { useRouter } from 'next/router';
import { useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import TwoFactorContext from './TwoFactorContext';

type TwoFactorProviderProps = {
  children: React.ReactNode;
};

type TAttributes = {
  accessToken: string;
  expiresAt: string;
  tokenType: string;
};
/**
 * TwoFactorProvider.
 *
 * Enables the ability to enable, remove or change Two Factor Authentication for the current user
 */
export const TwoFactorProvider = ({ children }: TwoFactorProviderProps) => {
  const [verificationCode, setVerificationCode] = useState<string>();
  const [recoveryCodes, setRecoveryCodes] = useState<string[]>();
  const [tfaError, setTfaError] = useState<boolean>();
  const [accessToken, setAccessToken] = useState<string>();
  const [setToast] = useToaster();
  const [me] = useMe();
  const [t] = useTranslator();
  const router = useRouter();

  const queryClient = useQueryClient();

  const isAccountPage = router.pathname.includes(
    '/account/security/two-factor'
  );
  const isRegenerating = router.pathname.includes(
    '/account/security/two-factor/regenerate'
  );
  const { returnTo, accountId, token } = router.query as {
    returnTo: string;
    accountId: string;
    token: string;
  };

  const [{ data: twoFactorAuthentication, create, destroy }, { isLoading }] =
    useTwoFactorAuthentication(
      {
        account: me?.account?.().id || accountId,
        ...(token && { token }),
      },
      { suspense: true }
    );

  const loading = create.isLoading || isLoading;
  const { headers: apiHeaders } = useApi();

  // enable or change 2fa
  const handleTwoFactor = useCallback(
    async (reRouteTo?: string, otpCode?: string) => {
      await create
        .mutateAsync(
          {
            one_time_password: otpCode ?? verificationCode,
            ...(twoFactorAuthentication?.active && {
              _method: 'patch',
              operation: 'refresh_recovery_codes',
            }),
          },
          {
            onError: (response) => {
              if (response.statusCode === 422) {
                setTfaError(true);
                setToast(t('validation.wrong_otp'), 'error', 9000);
              }
            },
          }
        )
        .then((response) => {
          setRecoveryCodes(response.data.recoveryCodes);
          if (!isAccountPage) {
            const responseAttributes = response.included[0]
              ?.attributes as TAttributes;
            setAccessToken(responseAttributes?.accessToken);
          }
          if (reRouteTo) {
            router.push(reRouteTo);
          }
        });
    },
    [
      create,
      verificationCode,
      twoFactorAuthentication?.active,
      setToast,
      t,
      isAccountPage,
      router,
    ]
  );

  const setToken = useCallback(() => {
    axios
      .post(
        `/auth/token?token=${accessToken}`,
        {},
        { headers: apiHeaders as AxiosRequestHeaders }
      )
      .then(() => {
        queryClient.invalidateQueries();
      });
  }, [accessToken, apiHeaders, queryClient]);

  const disableTwoFactor = useCallback(
    async (otpCode: string, reRouteTo?: string) => {
      await destroy.mutateAsync(
        {
          one_time_password: otpCode,
        },
        {
          onSuccess: () => {
            if (reRouteTo) router.replace(reRouteTo);
          },
          onError: (response) => {
            if (response.statusCode === 422) {
              setTfaError(true);
              setToast(t('validation.wrong_otp'), 'error', 9000);
            }
          },
        }
      );
    },
    [destroy, router, setToast, t]
  );

  const state = useMemo(
    () => ({
      twoFactorAuthentication,
      verificationCode,
      setVerificationCode,
      loading,
      handleTwoFactor,
      setToken,
      disableTwoFactor,
      recoveryCodes,
      setRecoveryCodes,
      tfaError,
      returnTo,
      isAccountPage,
      isRegenerating,
    }),
    [
      twoFactorAuthentication,
      verificationCode,
      setVerificationCode,
      loading,
      handleTwoFactor,
      setToken,
      disableTwoFactor,
      recoveryCodes,
      setRecoveryCodes,
      tfaError,
      returnTo,
      isAccountPage,
      isRegenerating,
    ]
  );

  return (
    <TwoFactorContext.Provider value={state}>
      {children}
    </TwoFactorContext.Provider>
  );
};
