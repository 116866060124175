import React, { ReactNode } from 'react';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import styles from './PreviewNfcTag.module.css';

const cx = classNames.bind(styles);

type TProps = {
  title?: string | ReactNode;
  location?: string;
  address?: string;
  city?: string;
  notes?: string;
  locationImage?: string;
};

export const PreviewNfcTag = ({
  title,
  location,
  address,
  city,
  notes,
  locationImage,
}: TProps) => {
  const [t] = useTranslator();
  const googleMapLocation = `${address}+${city}`.replace(/ /g, '+');

  return (
    <div className={cx(styles.base)}>
      <h3 className={cx(styles.title)}>{title}</h3>
      <p>{t('views.courses.collection_edit.nfc_tag.preview.description')}</p>
      <strong>
        {t('views.courses.collection_edit.nfc_tag.preview.location')}
      </strong>
      <div className={cx(styles.location)}>
        {locationImage && (
          <img className={cx(styles.media)} src={locationImage} alt="" />
        )}
        <div className={cx(styles.flex)}>
          <strong>{location}</strong>
          {address && city && (
            <a
              href={`https://www.google.nl/maps/place/${googleMapLocation}`}
              target="_blank"
              rel="noreferrer"
            >
              {address}
              <br />
              {city}
            </a>
          )}
        </div>
      </div>
      {notes && (
        <>
          <strong>
            {t('views.courses.collection_edit.nfc_tag.preview.note')}
          </strong>
          <p>{notes}</p>
        </>
      )}
    </div>
  );
};
