import React, { useEffect, useMemo, useState } from 'react';
import { ISingleChoiceBlock } from '@brainstud/course-blocks';
import { Input, Radio } from '@brainstud/ui';
import { useTranslator } from 'Providers/Translator';
import { shortId } from 'Utils';
import { v4 } from 'uuid';
import { AnswerFeedback, DeleteableInput } from '../../../Components';
import { AddItemButton } from '../../../Components/AddItemButton/AddItemButton';
import { useDynamicList } from '../../../Hooks/useDynamicList';
import styles from './SingleChoice.module.css';

export type SingleChoiceFormProps = {
  data: ISingleChoiceBlock;
  onChange: (data: Omit<ISingleChoiceBlock, 'id' | 'type'>) => void;
};

export const SingleChoiceForm = ({
  data: initialData,
  onChange,
}: SingleChoiceFormProps) => {
  const [t] = useTranslator();

  const [question, setQuestion] = useState(initialData.question);
  const [feedback, setFeedback] = useState(initialData.feedback);
  const [options, { add, update, updateAll, remove }] = useDynamicList<
    (typeof initialData.options)[0]
  >(initialData.options || []);
  const data = useMemo(
    () => ({
      question,
      options,
      feedback,
    }),
    [question, feedback, options]
  );
  useEffect(() => {
    onChange?.(data);
  }, [data]);

  return (
    <>
      <Input
        label={t('course-editor.content_editor.blocks.single_choice.question')}
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
        rules={['required']}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label>{`${t('course-editor.content_editor.blocks.single_choice.options')} *`}</label>
      {options?.map(({ id, correct, title }) => (
        <div key={id} style={{ display: 'flex' }}>
          <Radio
            id={shortId(id)}
            name={shortId(initialData.id)}
            quiet
            defaultValue=""
            checked={correct}
            className={styles.radio}
            onChange={() =>
              updateAll((item) => ({ ...item, correct: id === item.id }))
            }
          />
          <DeleteableInput
            value={title}
            onChange={(e) => update(id, { title: e.target.value })}
            onDelete={() => remove(id)}
          />
        </div>
      ))}
      <AddItemButton
        title={t(
          'course-editor.content_editor.blocks.single_choice.add_option'
        )}
        onClick={() => add({ id: v4(), title: '' })}
      />
      <AnswerFeedback feedback={feedback} onChange={setFeedback} />
    </>
  );
};
