import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import { TFeedbackFormProps } from './Types';
import styles from './FeedbackTypes.module.css';

const cx = classNames.bind(styles);

export const ThankYouFeedback = ({
  title,
  onAnswer,
  active,
}: TFeedbackFormProps) => {
  useEffect(() => {
    if (active) {
      const timer = setTimeout(() => onAnswer(), 1500);
      return () => clearTimeout(timer);
    }
  }, [onAnswer, active]);
  return (
    // FIXME Refactor to button
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className={cx('thank-you')} onClick={() => onAnswer()}>
      <p className={cx('title')}>{title}</p>
    </div>
  );
};
