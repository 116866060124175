import React, { useCallback, useMemo } from 'react';
import {
  useEnrollment,
  useEnrollmentLearningObjectCollection,
} from '@brainstud/academy-api';
import { Dropdown } from '@brainstud/ui';
import classNames from 'classnames';
import { useTranslator } from 'Providers/Translator';
import styles from '../EnrollmentSettingsForm.module.css';

type TProps = {
  /** Overwrite the default enrollmentId */
  enrollmentId?: string;
  className?: string;
};

const cx = classNames.bind(styles);

export const SettingLevel = ({ enrollmentId, className }: TProps) => {
  const [t] = useTranslator();

  const [{ data: enrollment, update: updateEnrollment }] = useEnrollment({
    enrollment: enrollmentId,
  });

  const [{ data: collection }] = useEnrollmentLearningObjectCollection({
    enrollment: enrollmentId,
    learningObjectCollection: enrollment?.learningObjectCollection?.().id,
  });

  const currentLevel = enrollment?.educationLevel?.();
  const educationLevels = useMemo(
    () => collection?.enrollableEducationLevels || [],
    [collection]
  );

  const handleEducationLevelChange = useCallback(
    async (value?: null | string) => {
      await updateEnrollment.mutateAsync({
        education_level: value || undefined,
      });
    },
    [updateEnrollment]
  );

  return (
    <div className={cx(styles.setting, className)}>
      <h4>{t('menu.level')}</h4>
      <Dropdown
        placeholder={
          (currentLevel?.eqfNumber &&
            !educationLevels.some(
              (option) => option.eqfNumber === currentLevel?.eqfNumber
            ) &&
            t('components.settings_level.select_level')) ||
          ''
        }
        loading={updateEnrollment.isLoading}
        value={currentLevel?.eqfNumber?.toString()}
        onChange={handleEducationLevelChange}
        block
      >
        {educationLevels.map((level) => (
          <Dropdown.Option
            key={level.eqfNumber + level.label}
            value={level?.eqfNumber?.toString()}
          >
            {level.label}
          </Dropdown.Option>
        ))}
      </Dropdown>
    </div>
  );
};
