import { ReactNode } from 'react';
import classNames from 'classnames/bind';
import { Link } from 'Components/Link';
import styles from './NavigationLink.module.css';

const classes = classNames.bind(styles);

type Props = {
  exact?: boolean;
  href: string;
  indent?: number;
  children: ReactNode;
};

/**
 * NavigationLink.
 *
 * Link wrapper component for a navigation styled link.
 */
export const NavigationLink = ({
  href,
  exact = true,
  indent = 0,
  children,
}: Props) => (
  <Link
    exact={exact}
    href={href}
    className={classes(styles.base, `indent-${indent}`)}
  >
    {children}
  </Link>
);
