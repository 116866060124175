import { IResource } from '../Utils/Resource';
import { Enrollment } from './Enrollment';
import { SampleSetItem } from './SampleSetItem';

export interface SampleSet extends IResource {
  enrollment?: () => Enrollment;
  items: SampleSetItem[];
}

export interface SampleSetCreateInput {
  enrollment: string;
}

export interface SampleSetUpdateInput {}

export enum SampleSetIncludes {
  enrollment,
  'enrollment.course',
  'enrollment.account',
  items,
  'items.answer',
  'items.learning_object',
  'items.assessor_account',
}

export enum SampleSetFilters {
  account,
  course,
  status,
}

export enum SampleSetSorts {
  created_at,
  account,
  course,
}
