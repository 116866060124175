import React from 'react';
import { Panel } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { Container } from 'Components';
import { EnrollmentSettingsForm } from 'Forms/EnrollmentSettings/EnrollmentSettingsForm';
import { useEnrollmentByCourseId } from 'Hooks';
import { useTranslator } from 'Providers/Translator';
import styles from './EnrollmentSettingsView.module.css';

const cx = classNames.bind(styles);

export const EnrollmentSettingsView = () => {
  const [t] = useTranslator();
  const { enrollment } = useEnrollmentByCourseId();

  return (
    <div className={cx(styles.base)}>
      <Container>
        <h2>{t('settings')}</h2>
        <Panel
          pad
          className={cx(styles.panel)}
          style={{ backgroundImage: 'url(/media/boxes.svg)' }}
        >
          <EnrollmentSettingsForm enrollmentId={enrollment?.id} />
        </Panel>
      </Container>
    </div>
  );
};
