import React, { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useApi } from '@brainstud/academy-api';
import { Button, Input } from '@brainstud/ui';
import { Form } from '@brainstud/universal-components';
import { ArrowBack } from '@mui/icons-material';
import axios, { AxiosRequestHeaders } from 'axios';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';
import { useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import styles from './TwoFactorAuthLoginView.module.css';

const cx = classNames.bind(styles);

export const TwoFactorAuthLoginView = () => {
  const router = useRouter();
  const { token, returnTo } = router.query as {
    token: string;
    returnTo: string;
  };
  const { headers: apiHeaders } = useApi();
  const queryClient = useQueryClient();

  const [t] = useTranslator();
  const [setToast] = useToaster();

  const handleTwoFactorLogin = useCallback(
    async (formData) => {
      await axios
        .post(
          '/auth/login/two-factor',
          {
            one_time_password: formData.verification_code,
            token,
          },
          { headers: apiHeaders as AxiosRequestHeaders }
        )
        .then(() => {
          queryClient.invalidateQueries();
          setToast(t('login.messages.success'), 'success');
          router.push(returnTo || '/');
        })
        .catch(() => {
          setToast(t('security.two_factor.failed'), 'error');
        });
    },
    [apiHeaders, queryClient, returnTo, router, token, t, setToast]
  );

  return (
    <div className={cx(styles.base)}>
      <span className={cx(styles.subtitle)}>
        {t('security.two_factor.login_subtitle')}
      </span>
      <Form
        onSubmit={handleTwoFactorLogin}
        autoReset={false}
        disableAfterSubmit={false}
      >
        <Input
          inline
          type="text"
          autoComplete="off"
          name="verification_code"
          label={t('security.two_factor.verification_code')}
          placeholder={t('security.two_factor.verification_code_placeholder')}
        />
        <div className={cx(styles['button-wrapper'])}>
          <Button type="button" quiet onClick={() => router.back()}>
            <ArrowBack />
            {t('security.two_factor.previous')}
          </Button>
          <Button type="submit">{t('security.two_factor.next')}</Button>
        </div>
      </Form>
    </div>
  );
};
