import { DataDocument } from '../Support';
import { IResourceOrCollection } from '../Types';
import { TJsonSpecResponse } from '../Types/Utils/JsonApiResource';
import clone from './clone';
import { toCamelCase } from './transformCase';

export function transformDropzoneResponse<
  T extends IResourceOrCollection = any,
  AJ extends boolean = false,
>(
  response: string | object,
  asJson?: AJ
): AJ extends true ? TJsonSpecResponse : DataDocument<T> {
  if (typeof response === 'string') {
    try {
      const jsonResponse = JSON.parse(response);
      return asJson
        ? jsonResponse
        : new DataDocument<T>({
            ...jsonResponse,
            data: clone(jsonResponse, toCamelCase),
          });
    } catch (e) {
      throw new Error('Unexpected API response');
    }
  }

  return asJson
    ? (response as any)
    : new DataDocument<T>({
        status: 'data' in response ? 200 : 422,
        statusText:
          'data' in response ? 'OK' : 'Unknown status from Dropzone API',
        ...response,
        data: clone(response, toCamelCase),
      });
}
