import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMe, usePortalLanguages } from '@brainstud/academy-api';
import { DEFAULT_LANGUAGE } from 'Config/constants';
import deepmerge from 'deepmerge';
import { getProperty } from 'dot-prop';
import { ITranslations } from 'Providers/Translator/TranslationsInterface';
import {
  ITranslatorContext,
  TLanguages,
  TranslatorContext,
} from 'Providers/Translator/TranslatorContext';
import { useTranslationLoader } from 'Providers/Translator/useTranslationLoader';
import { DateFormatter } from 'Utils/DateFormatHelper';

/**
 * The translations provider makes the translations file accessible through a `useTranslator` hook.
 */
export const TranslatorProvider = ({ children }: PropsWithChildren<{}>) => {
  const [me, { isLoading: isLoadingMe }] = useMe();

  const locale = me?.account?.().locale;
  const [{ data: portalLanguages }, { isLoading: isLoadingPortalLanguages }] =
    usePortalLanguages();
  const isLoading = isLoadingMe || isLoadingPortalLanguages;
  const portalLanguage = useMemo(
    () =>
      portalLanguages.find((item) => item.language?.().locale === locale) ||
      portalLanguages.find((item) => item.isPrimary),
    [portalLanguages, locale]
  );
  const normalizedLocale =
    locale || portalLanguage?.language?.().locale || DEFAULT_LANGUAGE;

  const translations = useTranslationLoader(
    isLoading ? undefined : normalizedLocale
  );
  const translationOverrides = useMemo<object>(() => {
    try {
      return portalLanguage?.overrides
        ? JSON.parse(portalLanguage.overrides)
        : {};
    } catch (e) {
      return {};
    }
  }, [portalLanguage?.overrides]);

  const portalTranslations = useMemo(
    () =>
      deepmerge.all<ITranslations>([
        translations || {},
        translationOverrides || {},
      ]),
    [translationOverrides, translations]
  );

  const [language, setLanguage] = useState<TLanguages>(
    normalizedLocale as TLanguages
  );

  const translate = useCallback<ITranslatorContext['translate']>(
    (id, data = {}, fallback = undefined) => {
      if (!id) {
        return null;
      }

      let translated;
      try {
        translated = getProperty(
          portalTranslations,
          id,
          typeof fallback === 'string' || fallback === null ? fallback : id
        ) as any;
      } catch (err) {
        translated =
          typeof fallback === 'string' || fallback === null ? fallback : id;
      }

      if (typeof translated === 'string' && translated?.includes('${')) {
        return translated.replace(/\$\{(.*?)}/g, (_, placeholder) =>
          placeholder in data ? data[placeholder] : ''
        );
      }

      return translated;
    },
    [portalTranslations]
  );

  useEffect(() => {
    DateFormatter.setLocale(language, translate);
  }, [language, translate]);

  const state = useMemo<ITranslatorContext>(
    () => ({
      translate,
      language,
      setLanguage,
      translations: portalTranslations,
    }),
    [translate, language, portalTranslations]
  );

  return (
    <TranslatorContext.Provider value={state}>
      {!translations ? null : children}
    </TranslatorContext.Provider>
  );
};
