type TDropdownOption = {
  label?: string;
  value: string;
  selected?: boolean;
};

export type TDropdownState = TDropdownOption[];

type TConnectAction = {
  type: 'connect';
  payload: {
    value: string;
    label?: string;
    selected?: boolean;
  };
};

type TUpdateAction = {
  type: 'toggle' | 'disconnect';
  payload: {
    value: string | null;
  };
};

type TBulkUpdateAction = {
  type: 'toggle-multiple';
  payload: {
    value: null | string | string[];
  };
};

type TBulkSetAction = {
  type: 'select-multiple';
  payload: {
    value?: string | string[] | null;
  };
};

export type TDropdownActionTypes =
  | TConnectAction
  | TUpdateAction
  | TBulkUpdateAction
  | TBulkSetAction;
const initialState: TDropdownState = [];

export function DropdownReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: TDropdownState = initialState,
  action: TDropdownActionTypes
) {
  switch (action.type) {
    case 'connect': {
      const index = state.findIndex(
        (item) => item.value === action.payload.value
      );
      if (index !== -1) {
        const prevState = { ...state[index] };
        state.splice(index, 1, {
          ...prevState,
          ...action.payload,
        });
      } else {
        state.push({
          label: action.payload?.label || action.payload.value,
          value: action.payload.value,
          selected: !!action.payload?.selected,
        });
      }
      return [...state];
    }
    case 'toggle': {
      return state.map((item) =>
        item.value === action.payload.value || item.selected
          ? { ...item, selected: item.value === action.payload.value }
          : item
      );
    }
    case 'toggle-multiple': {
      const values = Array.isArray(action.payload.value)
        ? action.payload.value
        : [action.payload.value];
      values.forEach((value) => {
        const index = state.findIndex((item) => item.value === value);
        if (index !== -1) {
          state.splice(index, 1, {
            ...state[index],
            selected: !state[index].selected,
          });
        }
      });
      return [...state];
    }
    case 'select-multiple': {
      const values = Array.isArray(action.payload.value)
        ? action.payload.value
        : [action.payload.value];
      return state.map((item) => ({
        ...item,
        selected: values.includes(item.value),
      }));
    }
    case 'disconnect': {
      return state.filter((item) => item.value === action.payload.value);
    }
    default:
      throw new Error();
  }
}
