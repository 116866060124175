import { useContext } from 'react';
import { EnvironmentContext } from './EnvironmentContext';

/**
 * useEnvironmentProvider.
 *
 * Returns the environment the user is in.
 */
export function useEnvironmentProvider() {
  const context = useContext(EnvironmentContext);

  if (!context) {
    throw new Error('Please, initiate the AppStateProvider first!');
  }

  return context;
}
