import { createContext, Dispatch } from 'react';
import {
  Course,
  ProductCategory,
  ProductSubCategory,
} from '@brainstud/academy-api';
import { CourseListActions, ICourseListState } from './CourseListReducer';

export interface ICourseListContext extends ICourseListState {
  quickStart: boolean;
  catalog: Course[];
  categories: ProductCategory[];
  subcategories: ProductSubCategory[];
  courses: Course[];
  courseCount: { [p: string]: number } | number;
  totalCourses: number;
  toggleProfile: (val: string) => void;
  reset: () => void;
  dispatch: Dispatch<CourseListActions>;
}

export default createContext<null | ICourseListContext>(null);
