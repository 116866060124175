import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  LearningObject,
  LearningObjectCreateInput,
  LearningObjectFilters,
  LearningObjectIncludes,
  LearningObjectSorts,
  LearningObjectUpdateInput,
  UUID,
} from '../Types';

interface ILearningObjectProps
  extends HookParams<
    typeof LearningObjectIncludes,
    LearningObjectFilters,
    typeof LearningObjectSorts
  > {
  enrollment?: UUID;
  learningObject?: UUID;
}

export function useEnrollmentLearningObject(
  { enrollment, learningObject, ...queryParameters }: ILearningObjectProps = {},
  settings?: UseQueryOptions<DataDocument<LearningObject>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: {
      learningObject: defaultLearningObject,
      enrollment: defaultEnrollment,
    },
  } = context;
  const request = new ApiRequest(
    {
      enabled:
        !!(learningObject || defaultLearningObject) &&
        !!(enrollment || defaultEnrollment),
      baseName: 'v1.enrollments.learningObject',
      baseUri: `/v1/enrollments/${enrollment || defaultEnrollment}/learning_objects`,
      uri: `/${learningObject || defaultLearningObject}`,
      invalidate: ['learning_objects', 'learning_object', 'v1.learningObjects'],
      queryParameters,
      shareable: true,
    },
    context
  );
  return useSingleCRUD<
    LearningObject,
    LearningObjectCreateInput,
    LearningObjectUpdateInput
  >(request, settings);
}

export function useEnrollmentLearningObjects(
  {
    enrollment,
    ...queryParameters
  }: Omit<ILearningObjectProps, 'learningObject'> = {},
  settings?: UseQueryOptions<DataDocument<LearningObject[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { enrollment: defaultEnrollment },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(enrollment || defaultEnrollment),
      baseName: 'v1.enrollments.learningObjects',
      baseUri: `/v1/enrollments/${enrollment || defaultEnrollment}/learning_objects`,
      invalidate: ['learning_objects', 'learning_object', 'v1.learningObjects'],
      queryParameters,
    },
    context
  );
  return useIndexCR<LearningObject, LearningObjectCreateInput>(
    request,
    settings
  );
}
