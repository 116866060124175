import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'Hooks';
import AppContext from './AppContext';

export type AppProviderProps = {
  children?: React.ReactNode;
};

const AppProvider = ({ children }: AppProviderProps) => {
  const history = useHistory();

  const [flash, setFlashMessage] = useState<{
    message?: string;
    type?: 'success' | 'error';
    duration?: number;
  }>({});

  const setFlash = useCallback(
    (
      message: string,
      type: 'success' | 'error',
      redirect?: null | string,
      duration?: number
    ) => {
      if (redirect) history.push(redirect);
      setFlashMessage({ message, type, duration });
    },
    [history]
  );

  const state = useMemo(
    () => ({
      flash,
      setFlash,
    }),
    [flash, setFlash]
  );

  useEffect(() => {
    const unlisten = history.listen(() => {
      setFlashMessage({});
    });
    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    if (flash?.duration) {
      const timer = setTimeout(() => {
        setFlashMessage({});
      }, flash.duration);
      return () => clearTimeout(timer);
    }
    return () => {};
  }, [flash]);

  return <AppContext.Provider value={state}>{children}</AppContext.Provider>;
};

export default AppProvider;
