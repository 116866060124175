import React from 'react';
import { useCertifications } from '@brainstud/academy-api';
import {
  Accordion,
  Callout,
  Panel,
  Status,
  Table,
  Td,
  Th,
  Tr,
} from '@brainstud/ui';
import { Tooltip } from '@brainstud/ui/Static/Tooltip/Tooltip';
import { Description, InfoOutlined } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Loading } from 'Components';
import { useTranslator } from 'Providers/Translator';
import { DateFormatter } from 'Utils/DateFormatHelper';
import styles from './CertificationsDocumentsTables.module.css';

const cx = classNames.bind(styles);

type Props = {
  /** Only retrieve certifications linked to a specific course */
  course?: string;
  /** The number of versions to show per certification (default: all) */
  maxVersions?: number;
};

/**
 * Shows a list of all certifications with versions that contain documents. The documents are rendered in a table.
 */
export const CertificationsDocumentsTables = ({
  course,
  maxVersions,
}: Props) => {
  const [t] = useTranslator();
  const [{ data: certifications }, { isLoading }] = useCertifications({
    include: ['versions', 'versions.certification_documents'],
    filter: {
      // @ts-ignore
      course,
    },
  });

  if (!isLoading && !certifications.length) {
    return (
      <Callout warning margin="5rem 0 0 0">
        {t('components.certification_documents_tables.unavailable')}
      </Callout>
    );
  }

  return isLoading ? (
    <Loading />
  ) : (
    <div className={cx(styles.base)}>
      {certifications.map((certification) => {
        const versionsWithDocuments = [
          ...(certification.certificationVersions?.() || []),
        ]
          .filter((item) => (item.certificationDocuments?.().length || 0) > 0)
          .reverse()
          .slice(0, maxVersions);
        return !versionsWithDocuments || versionsWithDocuments.length === 0 ? (
          <Callout key={certification.id} warning margin="5rem 0 0 0">
            <h4>{certification.title}</h4>
            <p>
              {t(
                'components.certification_documents_tables.version_documents.unavailable'
              )}
            </p>
          </Callout>
        ) : (
          <div key={certification.id}>
            <h4>{certification.title}</h4>
            {versionsWithDocuments.map((version, index) => (
              <div className={cx(styles.version)} key={version.id}>
                <Accordion
                  open={index === 0}
                  quiet
                  heading
                  className={cx(styles['badged-accordion'])}
                  title={
                    <>
                      <span>{version.name}</span>
                      {index === 0 && (maxVersions || 2) > 1 && (
                        <>
                          <Tooltip button={<InfoOutlined />}>
                            <div className={cx('tooltip-wrapper')}>
                              <span className={cx(styles['tooltip-content'])}>
                                {t(
                                  'components.certification_documents_tables.latest_explanation'
                                )}
                              </span>
                            </div>
                          </Tooltip>
                          <Status className={styles.nowrap} scheme="info">
                            {t(
                              'components.certification_documents_tables.latest_version'
                            )}
                          </Status>
                        </>
                      )}
                    </>
                  }
                >
                  <Panel className={cx(styles.set)}>
                    <Table>
                      <Table.THead>
                        <Tr>
                          <Th>
                            {t(
                              'components.certification_documents_tables.th.documents'
                            )}
                          </Th>
                          <Th center>
                            {t(
                              'components.certification_documents_tables.th.last_updated'
                            )}
                          </Th>
                        </Tr>
                      </Table.THead>

                      <Table.TBody>
                        {version.certificationDocuments!().map((row) => (
                          <Tr key={row.id}>
                            <Td href={row.url}>
                              <div>
                                <Description />
                                {row.name}
                              </div>
                            </Td>
                            <Td center>
                              {DateFormatter.toDate(
                                row.updatedAt || row.lastUpdate
                              )}
                            </Td>
                          </Tr>
                        ))}
                      </Table.TBody>
                    </Table>
                  </Panel>
                </Accordion>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};
