import { parseDomain } from 'parse-domain';

/**
 * getRootDomain.
 *
 * Retrieves the domain name with top level domain and returns that string.
 *
 * @param {undefined | string} hostName
 * @returns {string | undefined}
 */
export function getRootDomain(hostName?: string) {
  if (hostName) {
    const parsedDomain = parseDomain(hostName.replace(/:[0-9]{1,4}/, ''));
    if (parsedDomain.type === 'LISTED') {
      return `${parsedDomain.domain}.${parsedDomain.topLevelDomains.join('.')}`;
    }
    if (parsedDomain.type === 'RESERVED') {
      return 'localhost';
    }
  }
  return undefined;
}
