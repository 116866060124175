import { DateTimeString } from '../Utils/DateTimeString';
import { IResource } from '../Utils/Resource';
import { Certification } from './Certification';
import { CertificationDocument } from './CertificationDocument';
import { ExamContent } from './ExamContent';

export interface CertificationVersion
  extends IResource<'certification_versions'> {
  name: string;
  description: string;
  active: boolean;
  isValidated: boolean;
  certification?: () => Certification;
  certificationDocuments?: () => CertificationDocument[];
  examContent?: () => ExamContent;
  createdAt?: DateTimeString;
}

export interface CertificationVersionCreateInput {
  name: string;
  description: string;
  active: boolean;
  is_validated: boolean;
}

export interface CertificationVersionUpdateInput
  extends CertificationVersionCreateInput {}

export enum CertificationVersionIncludes {
  certification,
  certification_documents,
  exam_content,
}

export enum CertificationVersionFilters {
  active,
  certification,
}

export enum CertificationVersionSorts {
  name,
  active,
}
