import React, { MouseEventHandler, ReactNode } from 'react';
import { Button } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';
import styles from './Tab.module.css';

const classes = classNames.bind(styles);

type Props = {
  active?: boolean;
  /** Links to a page without react-router */
  href?: string;
  /** Links to a page with react-router */
  to?: string;
  /** Event handler when clicking on the tab */
  onClick?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  className?: string;
  children: ReactNode | ReactNode[];
};

/**
 * Adds styling as a Tab in a TabList
 */
export const Tab = ({
  href,
  to,
  active,
  onClick,
  children,
  className,
}: Props) => {
  const { pathname } = useRouter();
  const isActive = active !== undefined ? active : pathname === to;
  return (
    <Button
      type="button"
      href={href}
      to={to}
      onClick={onClick}
      style={{ color: 'inherit' }}
      className={classes(styles.base, { active: isActive }, className)}
    >
      {children}
    </Button>
  );
};
