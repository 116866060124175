import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { v4 } from 'uuid';

type TListItem<ListItems extends undefined | Array<{ id: string }>> = Exclude<
  ListItems,
  undefined
>[0];
type TOutput<ListItems extends undefined | Array<{ id: string }>> = readonly [
  ListItems,
  {
    add: (item?: Partial<TListItem<ListItems>>) => void;
    update: (id: string, listItem: Partial<TListItem<ListItems>>) => void;
    updateAll: (
      item:
        | Partial<TListItem<ListItems>>
        | ((item: TListItem<ListItems>) => TListItem<ListItems>)
    ) => void;
    setList: Dispatch<SetStateAction<Exclude<ListItems, undefined>>>;
    remove: (id: string) => void;
  },
];

export function useDynamicList<ListItem extends { id: string }>(
  data: ListItem[],
  defaults?: Partial<Omit<ListItem, 'id'>>
): TOutput<ListItem[]>;
export function useDynamicList<ListItem extends { id: string }>(
  data?: ListItem[],
  defaults?: Partial<Omit<ListItem, 'id'>>,
  isLoading?: boolean
): TOutput<undefined | ListItem[]>;
export function useDynamicList<ListItem extends { id: string }>(
  data?: ListItem[],
  defaults: Partial<Omit<ListItem, 'id'>> = {},
  isLoading = false
): any {
  const [list, setList] = useState(() => (isLoading ? data : data || []));
  const [initialized, setInitialized] = useState(!isLoading);
  useEffect(() => {
    if (!initialized) {
      setInitialized(!isLoading);
      setList(data);
    }
  }, [isLoading, initialized, data]);

  const addToList = useCallback(
    (item?: Partial<ListItem>) => {
      setList((prevList) => [
        ...(prevList || []),
        {
          id: v4(),
          ...defaults,
          ...item,
        } as ListItem,
      ]);
    },
    [defaults]
  );

  const updateListItem = useCallback(
    (id: string, listItem: Partial<ListItem>) => {
      setList((prevList) => {
        const newList = (prevList || []).slice();
        const itemIdx = newList.findIndex((item) => item.id === id);
        newList[itemIdx] = { ...newList[itemIdx], ...listItem };
        return newList;
      });
    },
    []
  );

  const updateAll = useCallback(
    (listItem: Partial<ListItem> | ((item: ListItem) => ListItem)) => {
      setList((prevList) =>
        (prevList || []).map(
          typeof listItem === 'function'
            ? listItem
            : (item) => ({ ...item, ...listItem })
        )
      );
    },
    []
  );

  const removeListItem = useCallback((id: string) => {
    setList((prevList) => (prevList || []).filter((item) => item.id !== id));
  }, []);

  return useMemo(
    () =>
      [
        list,
        {
          add: addToList,
          update: updateListItem,
          updateAll,
          setList,
          remove: removeListItem,
        },
      ] as const,
    [list, addToList, updateListItem, updateAll, setList, removeListItem]
  );
}
