import React, { FC, useEffect, useState } from 'react';
import { IInputBlockProps } from '@brainstud/course-blocks';
import { Dropdown, Input } from '@brainstud/ui';
import { useTranslator } from 'Providers/Translator';
import { isPresent } from 'ts-is-present';
import { v4 } from 'uuid';
import { AnswerFeedback, DeleteableInput } from '../../../Components';
import { AddItemButton } from '../../../Components/AddItemButton/AddItemButton';
import { useDynamicList } from '../../../Hooks/useDynamicList';

type Props = {
  data: IInputBlockProps;
  onChange: (block: Partial<IInputBlockProps>) => void;
};

type TInput = {
  id: string;
  validation: undefined | string;
};

/**
 * For creating open answered questions
 */
export const InputForm: FC<Props> = ({ data, onChange }) => {
  const [t] = useTranslator();
  const [question, setQuestion] = useState(data.question);
  const [fieldCount, setFieldCount] = useState(data.inputFieldsCount || 1);
  const [feedback, setFeedback] = useState(data.feedback);
  const [validationType, setValidationType] = useState<'contains' | 'equals'>(
    !data.equals ? 'contains' : 'equals'
  );
  const [inputs, { add, update, remove }] = useDynamicList<TInput>(
    Array(fieldCount)
      .fill(0)
      .map((val, idx) => ({
        id: v4(),
        validation: data[validationType]?.[idx],
      }))
  );

  useEffect(() => {
    const validationArray = inputs
      .map((item) => item.validation)
      .filter(isPresent);
    onChange?.({
      question,
      inputFieldsCount: fieldCount,
      ...(validationType === 'equals'
        ? { equals: validationArray, contains: undefined }
        : { contains: validationArray, equals: undefined }),
      feedback,
    });
  }, [question, validationType, inputs, feedback, fieldCount]);

  return (
    <>
      <Input
        label={t('course-editor.content_editor.blocks.input.question')}
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
      />
      <Dropdown
        value={validationType || 'equals'}
        onChange={(value) => setValidationType(value as 'equals' | 'contains')}
      >
        <Dropdown.Option value="equals">
          {t('course-editor.content_editor.blocks.input.equals')}
        </Dropdown.Option>
        <Dropdown.Option value="contains">
          {t('course-editor.content_editor.blocks.input.contains')}
        </Dropdown.Option>
      </Dropdown>
      {inputs.map((input) => (
        <div key={input.id}>
          <DeleteableInput
            value={input.validation}
            onChange={(e) => update(input.id, { validation: e.target.value })}
            onDelete={() => {
              remove(input.id);
              setFieldCount((prevCount) => prevCount - 1);
            }}
          />
        </div>
      ))}
      <AddItemButton
        title={t('course-editor.content_editor.blocks.input.add_input')}
        onClick={() => {
          setFieldCount((prevCount) => prevCount + 1);
          add({ id: v4(), validation: '' });
        }}
      />
      <AnswerFeedback onChange={setFeedback} feedback={feedback} />
    </>
  );
};
