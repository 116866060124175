import {
  createContext,
  Dispatch,
  KeyboardEventHandler,
  MouseEventHandler,
  useContext,
} from 'react';
import type { TEmitFn } from 'Hooks/EventHandler';
import { DROPDOWN_GROUP_TOGGLE_EVENT } from './DropdownEvents';
import { TDropdownActionTypes, TDropdownState } from './DropdownReducer';

export interface IDropdownContext {
  name?: string;
  search?: string;
  multiple?: boolean;
  defaultValue?: string | string[] | null;
  state: TDropdownState;
  emit: TEmitFn<{ [DROPDOWN_GROUP_TOGGLE_EVENT]: [boolean] }>;
  dispatch: Dispatch<TDropdownActionTypes>;
  handleToggleSelect: MouseEventHandler<HTMLLIElement> &
    KeyboardEventHandler<HTMLLIElement>;
  handleToggleGroup: (newValues: string[]) => void;
}

export const DropdownContext = createContext<null | IDropdownContext>(null);

export function useDropdownContext() {
  const context = useContext(DropdownContext);

  if (!context) {
    throw Error('The DropdownContext is required for this hook.');
  }

  return context;
}
