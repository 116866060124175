import { useEffect } from 'react';
import { ACTIONS } from './ErrorDataActions';
import { useErrorDataProvider } from './useErrorDataProvider';

/**
 * useErrorScope.
 *
 * Append data to the error scope while this hook is active. With this, you can easily add
 * data to errors when they occur.
 */
export function useErrorScope(
  name: string,
  value?: string | number | boolean | null
) {
  const { dispatch } = useErrorDataProvider();

  useEffect(() => {
    if (name && value) {
      dispatch({
        type: ACTIONS.ADD_EXTRA,
        payload: {
          [name]: value,
        },
      });
      return () => {
        dispatch({
          type: ACTIONS.REMOVE_EXTRA,
          payload: {
            [name]: value,
          },
        });
      };
    }
  }, [name, value, dispatch]);
}
