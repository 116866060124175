import { IResource } from '../Utils/Resource';
import { EventCalendar } from './EventCalendar';

export interface ProductAddOn extends IResource<'product_add_ons'> {
  name: string;
  description?: string;
  priceBumpBeforeVat?: number;
  priceBumpAfterVat?: number;
  vatRate?: number;
  eventCalendar?: () => EventCalendar;
}

export interface ProductAddOnCreateInput {
  name: string;
  description?: string;
  price_bump_before_vat?: number;
  price_bump_after_vat?: number;
  vat_rate?: number;
}

export type ProductAddOnUpdateInput = Partial<ProductAddOnCreateInput>;

export enum ProductAddOnIncludes {
  event_calendar,
  'event_calendar.items',
}

export enum ProductAddOnFilters {}

export enum ProductAddOnSorts {}
