import React from 'react';
import { Accordion } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { useBlock } from 'Hooks/useBlock';
import { BlockRenderer } from '../../../Components/BlockRenderer/BlockRenderer';
import { IAccordionContainer } from './Types';
import styles from './AccordionContainer.module.css';

const cx = classNames.bind(styles);

/**
 * The AccordionContainer shows an accordion with content blocks inside.
 */
export const AccordionContainer = ({
  id,
  title,
  blocks,
}: Omit<IAccordionContainer, 'type'>) => {
  useBlock({ id });

  return (
    <div className={cx(styles.base, 'block-accordion__container')}>
      <Accordion
        title={title}
        className={cx(styles.accordion, 'block_accordion__item')}
      >
        {blocks.map((block) => (
          <BlockRenderer key={block.id} id={block.id} block={block} />
        ))}
      </Accordion>
    </div>
  );
};
