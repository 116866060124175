import { DateTimeString } from '../Utils/DateTimeString';
import { IResource } from '../Utils/Resource';

export interface EventCalendarItemEnrollment
  extends IResource<'event_calendar_item_enrollments'> {
  date: DateTimeString;
  state?: null | 'pending' | 'completed';
}

export interface EventCalendarItemEnrollmentCreateInput {
  email_address: string;
  phone_number?: string;
  state?: null | 'pending';
}

export interface EventCalendarItemEnrollmentUpdateInput
  extends EventCalendarItemEnrollmentCreateInput {}

export enum EventCalendarItemEnrollmentIncludes {}

export enum EventCalendarItemEnrollmentFilters {}

export enum EventCalendarItemEnrollmentSorts {}
