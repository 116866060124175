import { createContext, Dispatch } from 'react';
import { TBreadcrumbsActions } from './BreadcrumbsReducer';

export type TCrumb = {
  /**
   * segment.
   * The segment that this crumb will replace
   */
  segment: string;
  /**
   * text.
   * The text it will replace the crumb with. If `null`, it will be hidden. When undefined, it is loading
   */
  text?: null | string;
  /**
   * href.
   * The uri the crumb will lead to.
   */
  href?: null | string;
};

export interface IBreadcrumbsState {
  crumbs: {
    [segment: string]: undefined | Omit<TCrumb, 'segment'>;
  };
}

export interface IBreadcrumbsContext extends IBreadcrumbsState {
  dispatch: Dispatch<TBreadcrumbsActions>;
}

export const BreadcrumbsContext = createContext<null | IBreadcrumbsContext>(
  null
);
