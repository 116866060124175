import { removePropertyFromObject } from 'Utils/removePropFromObject';
import { ViewSettings } from './ViewSettings';
import type { IViewSettingsState } from './ViewSettingsReducer';

function createApiFilters<T extends TViewSettings>(filters: T['filters']) {
  return Object.values(filters).reduce(
    (filterObject, filter) => ({ ...filterObject, [filter.key]: filter.value }),
    {}
  );
}

export function handleSetAction<T extends TViewSettings>(
  state: IViewSettingsState<T>,
  payload: TSetFilterAction<T>['payload']
): IViewSettingsState<T>['views'] {
  const newFilters: T['filters'] = {
    ...state.views[payload.view]?.filters,
    [payload.key]: payload,
  };

  return {
    ...state.views,
    [payload.view]: {
      ...state.views[payload.view],
      apiFilters: createApiFilters<T>(newFilters),
      filters: newFilters,
    },
  };
}

export function handleToggleAction<T extends TViewSettings>(
  state: IViewSettingsState<T>,
  payload: TToggleFilterAction<T>['payload']
) {
  const { filters } = state.views[payload.view] || {};
  const hasKey = filters && payload.key in filters;
  const newFilters: T['filters'] = hasKey
    ? removePropertyFromObject(payload.key, filters)
    : {
        ...state.views[payload.view]?.filters,
        [payload.key]: payload,
      };

  return {
    ...state.views,
    [payload.view]: {
      ...state.views[payload.view],
      filters: newFilters,
      apiFilters: createApiFilters<T>(newFilters),
    },
  };
}

export function handleRemoveAction<T extends TViewSettings>(
  state: IViewSettingsState<T>,
  { key, view }: TRemoveFilterAction<T>['payload']
) {
  const { filters } = state.views[view] || {};
  const isStringKey = typeof key === 'string';
  const hasKey = filters && isStringKey && key in filters;
  const newFilters = hasKey
    ? removePropertyFromObject(key, filters)
    : filters || {};

  return {
    ...state.views,
    [view]: {
      ...state.views[view],
      filters: newFilters,
      apiFilters: createApiFilters<T>(newFilters),
    },
  };
}

export function handleBatchAction<T extends TViewSettings>(
  state: IViewSettingsState<T>,
  { filters, view }: TToggleBatchAction<T>['payload']
) {
  return {
    ...state.views,
    [view]: filters.reduce(
      (settings, filter) => {
        const newFilters = filter.value
          ? { ...settings.filters, [filter.key]: filter }
          : settings.filters && filter.key in settings.filters
            ? removePropertyFromObject(filter.key, settings.filters)
            : settings.filters || {};

        return {
          ...settings,
          filters: newFilters,
          apiFilters: createApiFilters<T>(newFilters),
        };
      },
      state.views[view] || ({} as NonNullable<T['filters']>)
    ),
  };
}

export function handleSortAction<T extends TViewSettings>(
  state: IViewSettingsState<T>,
  payload: TSortAction['payload']
) {
  return {
    ...state.views,
    [payload.view]: {
      ...state.views[payload.view],
      sort: [payload.value],
    },
  };
}

export function handleResetFiltersAction<T extends TViewSettings>(
  state: IViewSettingsState<T>,
  { view }: TResetFiltersAction['payload']
) {
  return {
    ...state.views,
    [view]: { ...state[view], apiFilters: {}, filters: {} },
  };
}

/**
 * TViewSettings.
 * Used to guide the generic type input on the useViewSetting hook. With this, we can make the entire config typesafe
 * due to restrictions on the input.
 */
export type TViewSettings = {
  filters: Record<string, any>;
  sort: any[];
};

// The Strict boolean makes it possible to set filters with a string whilst having typesafe filters in the state.
export interface IFilter<
  T extends TViewSettings,
  K extends keyof T['filters'],
> {
  key: K;
  value?: T['filters'][K];
  label?: string | null;
}

const ACTIONS = {
  LOAD: 'LOAD_SETTINGS',
  SET: 'SET_FILTER',
  TOGGLE: 'TOGGLE_FILTER',
  REMOVE: 'REMOVE_FILTER',
  SAVED: 'SAVED_SETTINGS',
  BATCH: 'BATCH_FILTERS',
  SORT: 'TOGGLE_SORT',
  RESET_FILTERS: 'RESET_FILTERS',
} as const;

export default ACTIONS;

export type TSetFilterPayload<T extends TViewSettings> = IFilter<
  T,
  keyof T['filters']
> & {
  view: (typeof ViewSettings)[keyof typeof ViewSettings];
};

type TSetFilterAction<T extends TViewSettings> = {
  type: typeof ACTIONS.SET;
  payload: TSetFilterPayload<T>;
};

export type TToggleFilterAction<T extends TViewSettings> = {
  type: typeof ACTIONS.TOGGLE;
  payload: TTogglePayload<T>;
};

export type TRemoveFilterAction<T extends TViewSettings> = {
  type: typeof ACTIONS.REMOVE;
  payload: {
    view: (typeof ViewSettings)[keyof typeof ViewSettings];
    key: keyof T['filters'] | string;
  };
};

export type TLoadViewSettingsAction<T extends TViewSettings> = {
  type: typeof ACTIONS.LOAD;
  payload?: Omit<IViewSettingsState<T>, 'isDirty' | 'isLoaded'>;
};

export type TSaveFilterAction = {
  type: typeof ACTIONS.SAVED;
};

export type TViewNames = (typeof ViewSettings)[keyof typeof ViewSettings];

export type TTogglePayload<T extends TViewSettings> = IFilter<
  T,
  keyof T['filters']
> & {
  view: TViewNames;
};

export type TToggleBatchAction<T extends TViewSettings> = {
  type: typeof ACTIONS.BATCH;
  payload: {
    filters: IFilter<T, keyof T['filters']>[];
    view: TViewNames;
  };
};

export type TSortAction = {
  type: typeof ACTIONS.SORT;
  payload: {
    view: (typeof ViewSettings)[keyof typeof ViewSettings];
    value: string;
  };
};

export type TResetFiltersAction = {
  type: typeof ACTIONS.RESET_FILTERS;
  payload: { view: TViewNames };
};

/**
 * ViewSettingsActions.
 *
 * List of constants identifying the actions available in the ViewSettingsReducer.
 */
export type TViewSettingsActions<TFilter extends TViewSettings> =
  | TSetFilterAction<TFilter>
  | TRemoveFilterAction<TFilter>
  | TLoadViewSettingsAction<TFilter>
  | TSaveFilterAction
  | TToggleFilterAction<TFilter>
  | TToggleBatchAction<TFilter>
  | TSortAction
  | TResetFiltersAction;
