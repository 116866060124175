import React, { useMemo } from 'react';
import { Callout, DraggableArea, DropArea } from '@brainstud/ui';
import {
  DragAndDrop,
  useDimensions,
  useForm,
} from '@brainstud/universal-components';
import classNames from 'classnames/bind';
import { useBlock } from 'Hooks/useBlock';
import { sanitizer } from 'Utils/Sanitizer';
import { toName } from '../../../Utils/toName';
import { IDragAndDropQuestion } from './Types';
import styles from './DragAndDropQuestion.module.css';

const cx = classNames.bind(styles);

interface DragAndDropQuestionProps extends Omit<IDragAndDropQuestion, 'type'> {
  className?: string;
  style?: React.CSSProperties;
}

export const DragAndDropQuestion = ({
  id,
  inputs = [],
  options = [],
  image,
  question,
  feedback,
  className,
  style,
}: DragAndDropQuestionProps) => {
  const { values } = useForm();

  const draggables = useMemo(
    () => [...inputs].sort(() => Math.random() - 0.5),
    []
  );
  const [container, dragAndDropDimensions] = useDimensions<HTMLDivElement>({
    live: false,
  });
  const dragAndDropWidth = dragAndDropDimensions?.width || Infinity;

  const { isValid, isValidated } = useBlock({
    id,
    closed: true,
    touched: !inputs.some(
      (input) =>
        !values[input.id] ||
        values[input.id] === null ||
        values[input.id] === ''
    ),
    fields: inputs.map((input) => input.id),
  });
  return (
    <DragAndDrop>
      <div
        ref={container}
        className={cx(styles.base, className, {
          isNarrowContainer: dragAndDropWidth < 600,
        })}
        style={style}
      >
        <p className={cx('question', 'content-block-question')}>{question}</p>

        {typeof image === 'string' && (
          <img
            src={image}
            alt=""
            className={cx('image', 'content-block-image')}
          />
        )}
        <div className={cx('drag-and-drop', 'content-block-options')}>
          <div className={cx('dragareas', 'content-block-dragareas')}>
            {draggables?.map((dragarea) => (
              <DraggableArea
                key={dragarea.id}
                identifier={dragarea.id}
                name={toName(id)}
                rules={[`equals:${dragarea.correct.join(',')}`]}
                className={cx(styles.dragarea, {
                  'has-image': !!dragarea.image,
                })}
              >
                {typeof dragarea.image === 'string' && (
                  <img
                    src={dragarea.image}
                    alt=""
                    className={cx('dragarea-image')}
                  />
                )}
                <span>{dragarea.label}</span>
              </DraggableArea>
            ))}
          </div>
          <div className={cx('dropzones', 'content-block-dropzones')}>
            {options.map((droparea) => (
              <DropArea
                key={droparea.id}
                name={droparea.id}
                accept={toName(id)}
              >
                {typeof droparea.image === 'string' && (
                  <img
                    src={droparea.image}
                    alt=""
                    className={cx('droppable-image')}
                  />
                )}
                <span>{droparea.label}</span>
              </DropArea>
            ))}
          </div>
        </div>
        <Callout
          correct={isValid}
          incorrect={!isValid}
          margin="2rem"
          hidden={
            !isValid && !feedback?.incorrect
              ? true
              : !!(isValid && !feedback?.correct) || !isValidated
          }
          className={cx('feedback')}
          dangerouslySetInnerHTML={{
            __html: sanitizer(
              isValid ? feedback?.correct : feedback?.incorrect
            ),
          }}
        />
      </div>
    </DragAndDrop>
  );
};

export default DragAndDropQuestion;
