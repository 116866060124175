import { useCallback, useMemo, useState } from 'react';
import {
  Student,
  useCoaches,
  useCoachMandates,
  useCourse,
  useCreateCoachMandateTransfer,
  useMe,
} from '@brainstud/academy-api';
import { Button, ContentModal, Dropdown } from '@brainstud/ui';
import { Loading } from 'Components';
import { Form } from 'Modules/universal-components';
import { useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { isPresent } from 'ts-is-present';
import { NO_COACH } from '../Hooks';
import styles from './DecoupleModal.module.css';

type Props = {
  course: string;
  students: Student[];
  closeModal: () => void;
  /** An action to be performed when the modal is submitted, gets called before closing the modal. */
  onComplete?: () => void;
};

const DecoupleModal = ({ course, students, closeModal, onComplete }: Props) => {
  const [t] = useTranslator();
  const [setToast] = useToaster();
  const [{ destroy }] = useCoachMandates(undefined, { enabled: false });

  const [newCoach, setNewCoach] = useState<string>();
  const [{ data: coaches }] = useCoaches({
    ...(course
      ? {
          filter: {
            course,
          },
        }
      : {}),
  });
  const transfer = useCreateCoachMandateTransfer();

  const [me] = useMe();
  const accountId = me?.account?.().id!;

  const [{ data: courseWithSets }, { isLoading }] = useCourse({ course });

  const mandateIds = useMemo(
    () =>
      students
        .flatMap((student) => student.coachMandates?.())
        .filter(isPresent)
        .filter((item) => !course || item.course?.().id === course)
        .map((coachMandate) => coachMandate.id),
    [students, course]
  );

  const handleDecouple = useCallback(async () => {
    if (!newCoach) {
      setToast(t('modals.unEnrollModal.required'), 'error');
      return Promise.reject(new Error(t('modals.unEnrollModal.required')));
    }

    if (newCoach !== NO_COACH) {
      await transfer.mutateAsync(
        {
          fromAccount: accountId,
          toAccount: newCoach,
          mandates: mandateIds,
        },
        {
          onError: (error) => {
            if (error.statusCode === 403) {
              setToast(t('modals.decoupleModal.toast_unauthorized'), 'error');
            } else {
              setToast(t('modals.decoupleModal.toast_failed'), 'error');
            }
          },
        }
      );
    } else {
      await destroy.mutateAsync({
        mandates: mandateIds,
      });
    }

    closeModal();
    setToast(t('modals.decoupleModal.toast_confirm'), 'success');
    onComplete?.();
  }, [
    newCoach,
    onComplete,
    closeModal,
    setToast,
    t,
    transfer,
    accountId,
    mandateIds,
    destroy,
  ]);

  return (
    <ContentModal
      className={styles.base}
      onClickOutside
      closeable
      style={{ overflow: 'visible' }}
      closeModal={closeModal}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <ContentModal.Header>
            {students.length === 1 ? (
              <h3>
                {t('modals.decoupleModal.single.title', {
                  courseName: courseWithSets?.title,
                })}
              </h3>
            ) : (
              <h3>
                {t('modals.decoupleModal.multiple.title', {
                  studentCount: students.length,
                  courseName: courseWithSets?.title,
                })}
              </h3>
            )}
          </ContentModal.Header>
          {students.length === 1 ? (
            <p>{t('modals.decoupleModal.single.body')}</p>
          ) : (
            <p>{t('modals.decoupleModal.multiple.body')}</p>
          )}
          <Form onSubmit={handleDecouple}>
            <Dropdown
              label={t('modals.decoupleModal.select_coach')}
              block
              className={styles.dropdown}
              onChange={(value) => setNewCoach(value)}
              searchable={coaches.length > 5}
              rules="required"
              defaultValue={NO_COACH}
            >
              <Dropdown.Option value={NO_COACH}>
                {t('modals.unEnrollModal.no_transfer')}
              </Dropdown.Option>
              {coaches.map((coach) => (
                <Dropdown.Option key={coach.id} value={coach.id}>
                  {coach.fullName}
                </Dropdown.Option>
              ))}
            </Dropdown>
            <ContentModal.Footer>
              <Button
                type="submit"
                className={styles.submit}
                error
                loading={destroy.isLoading || transfer.isLoading}
              >
                {t(
                  `modals.decoupleModal.${newCoach ? 'transfer' : 'decouple'}`
                )}
              </Button>
              <Button type="button" quiet onClick={closeModal}>
                {t('modals.cancel')}
              </Button>
            </ContentModal.Footer>
          </Form>
        </>
      )}
    </ContentModal>
  );
};

export default DecoupleModal;
