import { UseQueryOptions } from 'react-query';
import { useShow } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import { CourseStatistic, UUID } from '../Types';

interface Props {
  course?: UUID;
}

export function useCourseStatistics(
  { course }: Props = {},
  settings?: UseQueryOptions<DataDocument<CourseStatistic>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { course: defaultCourse },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(course || defaultCourse),
      baseName: 'v1.courses.statistics',
      baseUri: `/v1/courses/${course || defaultCourse}/statistics`,
    },
    context
  );
  return useShow<CourseStatistic>(request, settings);
}
