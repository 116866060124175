import { useCallback, useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from '@brainstud/universal-components';

export function useCoordinates() {
  const container = useRef<HTMLDivElement>(null);
  const form = useRef<HTMLDivElement>(null);

  const [xStart, setXStart] = useState<number>();
  const [yStart, setYStart] = useState<number>();
  const [xEnd, setXEnd] = useState<number>();
  const [yEnd, setYEnd] = useState<number>();

  const containerWidth = container.current?.clientWidth || 400;
  const containerHeight = container.current?.clientHeight || 200;

  useOnClickOutside([form], () => {
    setXEnd(undefined);
    setYEnd(undefined);
    setXStart(undefined);
    setYStart(undefined);
  });

  useEffect(() => {
    if (container.current) {
      const element = container.current;

      let tracking = false;
      window.addEventListener('keydown', (event) => {
        if (event.key === 'i') {
          tracking = !tracking;
          element.querySelectorAll('img').forEach((img) => {
            img.setAttribute('draggable', tracking ? 'false' : 'true');
            // eslint-disable-next-line no-param-reassign
            img.style.zIndex = tracking ? '10' : '';
            if (!tracking) {
              setXEnd(undefined);
              setYEnd(undefined);
              setXStart(undefined);
              setYStart(undefined);
            }
          });
        }
      });

      const handleStart = (event: MouseEvent) => {
        if (tracking) {
          setXStart(event.offsetX);
          setYStart(event.offsetY);
          setXEnd(undefined);
          setYEnd(undefined);
        }
      };

      const handleEnd = (event: MouseEvent) => {
        if (tracking) {
          setXEnd(event.offsetX);
          setYEnd(event.offsetY);
        }
      };

      element.addEventListener('mousedown', handleStart);
      element.addEventListener('mouseup', handleEnd);
      return () => {
        element.removeEventListener('mousedown', handleStart);
        element.removeEventListener('mouseup', handleEnd);
      };
    }
  }, [container]);

  const isDefined =
    xEnd !== undefined &&
    xStart !== undefined &&
    yStart !== undefined &&
    yEnd !== undefined;
  const toPercentage = useCallback(
    (widthOrHeight: 'width' | 'height', value: number = 0) =>
      widthOrHeight === 'width'
        ? Math.round((value / containerWidth) * 1000) / 10
        : Math.round((value / containerHeight) * 1000) / 10,
    [containerWidth, containerHeight]
  );

  const width = isDefined ? xEnd! - xStart! : 0;
  const height = isDefined ? yEnd! - yStart! : 0;
  const x = width < 0 && !!xStart ? xStart + width : xStart;
  const y = height < 0 && !!yStart ? yStart + height : yStart;

  return [
    container,
    form,
    isDefined
      ? {
          x: toPercentage('width', x),
          y: toPercentage('height', y),
          width: toPercentage('width', Math.abs(width)),
          height: toPercentage('height', Math.abs(height)),
        }
      : undefined,
  ] as const;
}
