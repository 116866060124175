import { IResource } from '../Utils/Resource';
import { ToolkitItem } from './ToolkitItem';

export interface ToolkitCategory extends IResource<'toolkit_categories'> {
  title: string | null;
  description?: string;
  sortOrder: number;
  toolkitItems?: () => ToolkitItem[];
}

export interface ToolkitCategoryCreateInput {
  title: string;
  description?: string;
  sort_order: number;
}

export interface ToolkitCategoryUpdateInput
  extends ToolkitCategoryCreateInput {}

export enum ToolkitCategoryIncludes {
  toolkit,
  toolkit_items,
}

export enum ToolkitCategoryFilters {
  'toolkitItems.itemType',
  'toolkitItems.title',
}

export enum ToolkitCategorySorts {
  title,
  sortOrder,
}
