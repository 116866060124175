import React from 'react';
import { ExternalAssessment as ExternalAssessmentResource } from '@brainstud/academy-api';
import { UploadedFile } from '@brainstud/course-blocks';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import { DateFormatter } from 'Utils/DateFormatHelper';
import { sanitizer } from 'Utils/Sanitizer';
import styles from './ExternalAssessment.module.css';

type Props = Pick<
  ExternalAssessmentResource,
  'name' | 'emailAddress' | 'feedback'
> & {
  date: ExternalAssessmentResource['createdAt'];
  /* The files to show as upload */
  storedFiles?: ReturnType<
    Exclude<ExternalAssessmentResource['storedFiles'], undefined>
  >;
};

const cx = classNames.bind(styles);

/**
 * ExternalAssessment
 *
 * Renders a Panel with all information of a single external assessment
 */
export const ExternalAssessment = ({
  name,
  emailAddress,
  date,
  feedback,
  storedFiles,
}: Props) => {
  const [t] = useTranslator();

  return (
    <div className={styles.base}>
      <div className={cx(styles.details)}>
        <div>
          <strong>{t('review.external_assessment.name')}</strong>
          <p>{name}</p>
        </div>
        <div>
          <strong>{t('review.external_assessment.email_address')}</strong>
          <p>{emailAddress}</p>
        </div>
        <div>
          <strong>{t('review.external_assessment.date')}</strong>
          <p>{DateFormatter.toDate(date)}</p>
        </div>
      </div>
      <div className={cx(styles.content)}>
        <strong>{t('review.external_assessment.assessment')}</strong>
        <div dangerouslySetInnerHTML={{ __html: sanitizer(`${feedback}`) }} />
        {storedFiles && storedFiles.length > 0 && (
          <div className={cx(styles.files)}>
            <strong>{t('review.external_assessment.files')}</strong>
            <div>
              {storedFiles.map((file) => (
                <UploadedFile
                  key={file.id}
                  name={file.originalFileName}
                  src={file.downloadUrl}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
