import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  AccountNote,
  AccountNoteCreateInput,
  AccountNoteFilters,
  AccountNoteIncludes,
  AccountNoteSorts,
  AccountNoteUpdateInput,
  HookParams,
  UUID,
} from '../Types';

interface IAccountNoteProps
  extends HookParams<
    typeof AccountNoteIncludes,
    AccountNoteFilters,
    typeof AccountNoteSorts
  > {
  account?: UUID;
  note?: UUID;
}

export function useAccountNote(
  { account, note, ...queryParameters }: IAccountNoteProps = {},
  settings?: UseQueryOptions<DataDocument<AccountNote>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { account: defaultAccount, note: defaultAccountNote },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(note || defaultAccountNote),
      baseName: 'v1.accounts.notes',
      baseUri: `/v1/accounts/${account || defaultAccount}/notes`,
      uri: `/${note || defaultAccountNote}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    AccountNote,
    AccountNoteCreateInput,
    AccountNoteUpdateInput
  >(request, settings);
}

export function useAccountNotes(
  { account, ...queryParameters }: Omit<IAccountNoteProps, 'note'> = {},
  settings?: UseQueryOptions<DataDocument<AccountNote[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { account: defaultAccount },
  } = context;
  const request = new ApiRequest(
    {
      baseName: 'v1.accounts.notes',
      baseUri: `/v1/accounts/${account || defaultAccount}/notes`,
      queryParameters,
    },
    context
  );
  return useIndexCR<AccountNote, AccountNoteCreateInput>(request, settings);
}
