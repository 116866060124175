import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  EventCalendar,
  EventCalendarCreateInput,
  EventCalendarFilters,
  EventCalendarIncludes,
  EventCalendarSorts,
  EventCalendarUpdateInput,
  HookParams,
  UUID,
} from '../Types';

interface IEventCalendarProps
  extends HookParams<
    typeof EventCalendarIncludes,
    typeof EventCalendarFilters,
    typeof EventCalendarSorts
  > {
  course?: UUID;
  eventCalendar?: UUID;
}

export function useCourseEventCalendar(
  { course, eventCalendar, ...queryParameters }: IEventCalendarProps = {},
  settings?: UseQueryOptions<DataDocument<EventCalendar>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { eventCalendar: defaultEventCalendar, course: defaultCourse },
  } = context;
  const request = new ApiRequest(
    {
      enabled:
        !!(eventCalendar || defaultEventCalendar) &&
        !!(course || defaultCourse),
      baseName: 'v1.eventCalendars',
      baseUri: `/v1/courses/${course || defaultCourse}/event_calendars`,
      invalidate: ['event_calendars', 'event_calendars'],
      uri: `/${eventCalendar || defaultEventCalendar}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    EventCalendar,
    EventCalendarCreateInput,
    EventCalendarUpdateInput
  >(request, settings);
}

export function useCourseEventCalendars(
  {
    course,
    ...queryParameters
  }: Omit<IEventCalendarProps, 'eventCalendar'> = {},
  settings?: UseQueryOptions<DataDocument<EventCalendar[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { course: defaultCourse },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(course || defaultCourse),
      baseName: 'v1.eventCalendars',
      baseUri: `/v1/courses/${course || defaultCourse}/event_calendars`,
      invalidate: ['event_calendars', 'event_calendars'],
      queryParameters,
    },
    context
  );
  return useIndexCR<EventCalendar, EventCalendarCreateInput>(request, settings);
}
