import React from 'react';
import { isObject } from '@brainstud/course-editor/Support/is';
import { Button } from '@brainstud/ui';
import { AddRounded } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { FlexContainer } from 'Components';
import { BackButton } from 'Modules/action-based-learning-panel/Components';
import { LearningSubjectEditRow } from 'Modules/course-editor/Components/NodeOrderEditor/DraggableNodes/LearningSubject/LearningSubjectEditRow';
import {
  ILearningSubjectWithResources,
  useLearningRouteProvider,
  useModals,
} from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import { ContentSelectionModal, SubjectModal } from 'Views';
import { LearningObjectEditRow, NodeOrderEditor } from '../Components';
import { useSubjectEditor } from './Provider';
import styles from './SubjectEditor.module.css';

const cx = classNames.bind(styles);

type Props = {
  subject: ILearningSubjectWithResources;
};

/**
 * Edit the contents of a learning subject
 */
export const SubjectEditor = ({ subject }: Props) => {
  const [t] = useTranslator();
  const { openModal } = useModals();
  const { setNestedItems, nestedItems, learningSubject } = useSubjectEditor();
  const { learningRoute, learningObjectCollection } =
    useLearningRouteProvider();
  // @ts-ignore FIXME When poster is added
  const subjectImage = subject?.poster;
  const { layout: routeLayout } = useLearningRouteProvider();

  return (
    <div className={cx(styles.base)}>
      <BackButton />
      <div className={cx(styles.subjectTitleEdit)}>
        <img
          src={
            !subjectImage ? '/media/abstract-thumbnail.jpg' : subjectImage.src
          }
          alt="Placeholder"
          className={cx(styles.image)}
        />
        <div className={cx(styles.details)}>
          <FlexContainer justifyContent="space-between" row>
            <h2>{subject.title}</h2>
            <Button
              outline
              onClick={() => openModal(SubjectModal, { subject, routeLayout })}
            >
              <span>
                {t('course-editor.learning_route_editor.learning_subject.edit')}
              </span>
            </Button>
          </FlexContainer>
          <div
            className={cx(styles.intro)}
            dangerouslySetInnerHTML={{
              __html: sanitizer(subject?.introduction),
            }}
          />
        </div>
      </div>
      <NodeOrderEditor nodes={nestedItems} onChange={setNestedItems}>
        {nestedItems.map((item, index) =>
          isObject(item) ? (
            <LearningObjectEditRow key={item.id} item={item} index={index} />
          ) : (
            <LearningSubjectEditRow key={item.id} item={item} index={index} />
          )
        )}
        <Button
          type="button"
          quiet
          disabled={!learningSubject}
          onClick={() =>
            openModal(ContentSelectionModal, {
              collectionId: learningObjectCollection?.id,
              learningRouteId: learningRoute?.id,
              subjectId: learningSubject.id,
              learningRoute,
            })
          }
        >
          <AddRounded />
          <span>{t('course-editor.subject_editor.add_item')}</span>
        </Button>
      </NodeOrderEditor>
    </div>
  );
};
