import React from 'react';
import classNames from 'classnames/bind';
import styles from './Badge.module.css';

export interface BadgeProps {
  color:
    | 'black'
    | 'yellow'
    | 'blue'
    | 'green'
    | 'transparent'
    | 'white'
    | 'aqua';
  className?: string;
  circle?: boolean;
  limit?: boolean;
  size?: 'large';
  children: React.ReactNode | string;
}

const cx = classNames.bind(styles);

const Badge = ({
  color,
  circle,
  size,
  className,
  children,
  limit,
}: BadgeProps) => (
  <div
    className={cx(
      styles.base,
      { circular: circle, 'restrict-width': limit },
      size,
      color,
      className
    )}
  >
    <span>{children}</span>
  </div>
);

export default Badge;
