import React, { useEffect, useMemo, useState } from 'react';
import { IMultipleChoiceBlock } from '@brainstud/course-blocks';
import { Checkbox, Input } from '@brainstud/ui';
import { useTranslator } from 'Providers/Translator';
import { v4 } from 'uuid';
import { AnswerFeedback, DeleteableInput } from '../../../Components';
import { AddItemButton } from '../../../Components/AddItemButton/AddItemButton';
import { useDynamicList } from '../../../Hooks/useDynamicList';
import styles from './MultipleChoice.module.css';

export type MultipleChoiceFormProps = {
  data: IMultipleChoiceBlock;
  onChange: (data: Omit<IMultipleChoiceBlock, 'id' | 'type'>) => void;
};

export const MultipleChoiceForm = ({
  data: initialData,
  onChange,
}: MultipleChoiceFormProps) => {
  const [t] = useTranslator();

  const [question, setQuestion] = useState(initialData.question);
  const [feedback, setFeedback] = useState(initialData.feedback);
  const [options, { add, update, remove }] = useDynamicList<
    (typeof initialData.options)[0]
  >(initialData.options || []);
  const data = useMemo(
    () => ({
      question,
      options,
      feedback,
    }),
    [question, feedback, options]
  );
  useEffect(() => {
    onChange?.(data);
  }, [data]);

  return (
    <>
      <Input
        label={t(
          'course-editor.content_editor.blocks.multiple_choice.question'
        )}
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
        rules={['required']}
      />

      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label>{`${t('course-editor.content_editor.blocks.multiple_choice.options')} *`}</label>
      {options?.map(({ id, correct, title }) => (
        <div key={id} style={{ display: 'flex' }}>
          <Checkbox
            id={id}
            quiet
            checked={correct}
            className={styles.checkbox}
            onChange={(e) => update(id, { correct: e.target.checked })}
          />
          <DeleteableInput
            value={title}
            onChange={(e) => update(id, { title: e.target.value })}
            onDelete={() => remove(id)}
          />
        </div>
      ))}
      <AddItemButton
        title={t(
          'course-editor.content_editor.blocks.multiple_choice.add_option'
        )}
        onClick={() => add({ id: v4(), title: '' })}
      />
      <AnswerFeedback feedback={feedback} onChange={setFeedback} />
    </>
  );
};
