import { IResource } from '../Utils/Resource';
import { UUID } from '../Utils/UUID';
import { Product } from './Product';

export interface OrderAutomationProduct
  extends IResource<'order_automation_products'> {
  step: string;
  roles: string[];
  enabled: boolean;
  product?: () => Product;
}

export interface OrderAutomationProductCreate {
  step: string;
  enabled: boolean;
  roles: string[];
  relationships: {
    product: UUID;
  };
}

export interface OrderAutomationProductUpdate {
  enabled: boolean;
}

export enum OrderAutomationProductIncludes {}

export interface OrderAutomationProductFilters {}

export enum OrderAutomationProductSort {}

export enum OrderAutomationSteps {
  'on sign in',
  'on closing',
  'on purchase',
}
