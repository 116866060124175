import React, { useEffect, useRef } from 'react';
import { Callout } from '@brainstud/ui';
import { useBlock } from 'Hooks/useBlock';
import { sanitizer } from 'Utils/Sanitizer';
import { ICalloutBlock } from './Types';
import styles from './CalloutBlock.module.css';

export const CalloutBlock = ({
  id,
  title,
  content,
  icon,
  variety,
}: Omit<ICalloutBlock, 'type'>) => {
  const el = useRef<HTMLDivElement | null>(null);
  useBlock({
    id,
  });

  useEffect(() => {
    if (el?.current) {
      const tags = Array.from(el?.current?.getElementsByTagName('a')) || [];
      [...tags].forEach((anchor) => {
        anchor.setAttribute('target', '_blank');
        anchor.setAttribute('rel', 'noopener noreferrer');
      });
    }
  }, [el]);

  return (
    <div ref={el}>
      <Callout
        className={styles.base}
        dangerouslySetInnerHTML={{
          __html: sanitizer(
            (title ? `<strong style="display:block;">${title}</strong>` : '') +
              content
          ),
        }}
        icon={icon}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(variety ? { [variety]: true } : {})}
      />
    </div>
  );
};
