import { DateTimeString } from '../Utils/DateTimeString';
import { IResource } from '../Utils/Resource';
import { UUID } from '../Utils/UUID';

export type PortfolioExportStatus =
  | 'PENDING'
  | 'PROCESSING'
  | 'COMPLETED'
  | 'EXPIRED'
  | 'FAILED';

export interface PortfolioExport extends IResource<'portfolio_exports'> {
  status: PortfolioExportStatus;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  expiresAt: DateTimeString;
  downloadLink: string;
}

export interface PortfolioExportCreateInput {
  enrollment: UUID;
}

export interface PortfolioExportUpdateInput
  extends Partial<PortfolioExportCreateInput> {}

export enum PortfolioExportsIncludes {
  'enrollment',
  'account',
  'coach',
}

export interface PortfolioExportsFilters {
  account: UUID;
  coach: UUID;
  enrollment: UUID;
  status: string;
}

export enum PortfolioExportsSorts {}
