import React, { useCallback } from 'react';
import {
  useLtiResourceLink,
  useLtiToolProviders,
} from '@brainstud/academy-api';
import { Dropdown } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { ILtiResourceLinkBlock } from 'Modules/course-blocks';
import { useRouter } from 'next/router';
import { useTranslator } from 'Providers/Translator';
import styles from './LtiResourceLinkBlockForm.module.css';

const cx = classNames.bind(styles);

type LtiFormProps = {
  data?: ILtiResourceLinkBlock;
  onChange: (e: Partial<ILtiResourceLinkBlock>) => void;
};

export function LtiResourceLinkBlockForm({ data, onChange }: LtiFormProps) {
  const [t] = useTranslator();
  const router = useRouter();

  const { courseId } = router.query as { courseId: string };

  const [{ data: ltiToolProviders }] = useLtiToolProviders();
  const [{ data: ltiResourceLink, createOrUpdate }] = useLtiResourceLink(
    {
      ltiResourceLink: data?.ltiResourceLinkId,
    },
    { enabled: !!data?.ltiResourceLinkId }
  );

  const handleSubmit = useCallback(
    (value) => {
      createOrUpdate.mutateAsync(
        {
          relationships: {
            ltiToolProvider: value,
            course: courseId,
          },
        },
        {
          onSuccess: (response) => {
            onChange?.({ ltiResourceLinkId: response.data.id });
          },
        }
      );
    },
    [createOrUpdate, courseId, onChange]
  );

  return (
    <div className={cx(styles.base)}>
      <Dropdown
        onChange={handleSubmit}
        value={ltiResourceLink?.ltiToolProvider?.().id}
        placeholder={t(
          'course-editor.content_editor.blocks.lti_resource_link.placeholder'
        )}
        label={t('course-editor.content_editor.blocks.lti_resource_link.name')}
      >
        {ltiToolProviders.map((provider) => (
          <Dropdown.Option key={provider.id} value={provider.id}>
            {provider.title}
          </Dropdown.Option>
        ))}
      </Dropdown>
    </div>
  );
}
