import React from 'react';
import { useBlock } from 'Hooks/useBlock';
import { useTranslator } from 'Providers/Translator';
import { IAudioBlock } from './Types';
import styles from './AudioBlock.module.css';

/**
 * Displays an audio player from the provider src.
 */
const AudioBlock = ({ id, src }: Omit<IAudioBlock, 'type'>) => {
  useBlock({
    id,
  });
  const [t] = useTranslator();

  return (
    <div className={styles.base}>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio controls>
        <source src={src} />
        {t('course-editor.content_editor.blocks.audio.element_unsupported')}
      </audio>
    </div>
  );
};

export { AudioBlock };
