import { Button, ContentModal } from '@brainstud/ui';
import { ArrowForward, GetApp } from '@mui/icons-material';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import { getEmbedLink } from '../Utils';
import styles from './ToolkitView.module.css';

interface MediaItemProps {
  mediaItem: {
    content?: {
      fileName?: string;
      url?: string;
    };
    itemType: string;
    description: string;
    title: string;
  };
  closeModal: () => {};
}

export default ({ mediaItem, closeModal }: MediaItemProps) => {
  const [t] = useTranslator();
  const url = mediaItem?.content?.url || '';

  return (
    <ContentModal onClickOutside onClose={closeModal}>
      <div className={styles.modal}>
        <h3>{mediaItem.title}</h3>
        <div
          dangerouslySetInnerHTML={{ __html: sanitizer(mediaItem.description) }}
        />
        <hr className={styles.divider} />
        {mediaItem.itemType === 'video' && (
          <div className={styles.modalVideoWrapper}>
            <iframe
              title={mediaItem.title}
              width="100%"
              height="100%"
              src={getEmbedLink(url)}
              frameBorder="0"
              allow="autoplay; autoplay"
              allowFullScreen
            />
          </div>
        )}
        {mediaItem.itemType === 'image' && (
          <img src={url} alt="toolkit_image" />
        )}
        {mediaItem.itemType === 'link' && (
          <p>
            {t('modals.toolkitModal.link_will_follow_to')}{' '}
            <a href={url} rel="noopener noreferrer" target="_blank">
              {url}
            </a>
          </p>
        )}
        {mediaItem.itemType === 'document' && (
          <>
            <p>
              {t('modals.toolkitModal.file_name')}:{' '}
              {mediaItem?.content?.fileName || mediaItem.title}
            </p>
            <p>
              {t('modals.toolkitModal.extension')}: &apos;.{' '}
              {url && url.substr(url.lastIndexOf('.') + 1)}
              &apos;
            </p>
          </>
        )}
        <div className={styles.modalElementContainer}>
          <Button type="button" link onClick={() => closeModal()}>
            {t('cancel')}
          </Button>

          {mediaItem.itemType === 'document' ? (
            <Button href={url} download>
              {t('modals.toolkitModal.download_file')}
              <GetApp />
            </Button>
          ) : (
            <div />
          )}

          {mediaItem.itemType === 'link' ? (
            <Button href={url} target="_blank">
              {t('modals.toolkitModal.go_to_url')}
              <ArrowForward />
            </Button>
          ) : (
            <div />
          )}
        </div>
      </div>
    </ContentModal>
  );
};
