import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { DateFormatter } from 'Utils/DateFormatHelper';
import { TimestampProps } from './TimestampProps';
import styles from './Timestamp.module.css';

const cx = classNames.bind(styles);

type TDate = string | number | undefined;

/**
 * Timestamp.
 *
 * Renders a timestamp string with a date and a time
 */
export const Timestamp = ({ timestamp }: TimestampProps) => {
  const [parsedDate, setParsedDate] = useState<{
    date: TDate;
    time: TDate;
  } | null>(null);
  const loading = !parsedDate;

  /**
   * It could happen a lot of dates need to be parsed simultaneously, which is very heavy for the UI.
   * So this is essentially a loading state to give the perception of speed to a user.
   */
  useEffect(() => {
    if (timestamp) {
      setParsedDate({
        date: DateFormatter.toAbbreviatedDate(timestamp, 'dd MMM yyyy'),
        time: DateFormatter.toTime(timestamp),
      });
    }
  }, [timestamp]);

  return timestamp ? (
    <div className={cx(styles.base, { loading })}>
      <span className={styles.date}>{parsedDate?.date}</span>
      <span className={styles.time}>{parsedDate?.time}</span>
    </div>
  ) : (
    <span>-</span>
  );
};
