import { useContext } from 'react';
import { IThemeContext, ThemeContext } from './ThemeContext';

/**
 * useThemeProvider.
 *
 * [DESCRIPTION]
 *
 * @param nullable When true, this hook returns null when no context is found.
 */
export function useThemeProvider(nullable?: false): IThemeContext;
export function useThemeProvider(nullable?: true): null | IThemeContext;
export function useThemeProvider(nullable = false) {
  const context = useContext(ThemeContext);

  if (!context && !nullable) {
    throw new Error('Please, initiate the ThemeProvider first!');
  }

  return context;
}
