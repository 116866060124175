import { useContext } from 'react';
import {
  ISubjectEditorContext,
  SubjectEditorContext,
} from './SubjectEditorContext';

export const useSubjectEditor = () => {
  const ctx = useContext<null | ISubjectEditorContext>(SubjectEditorContext);
  if (!ctx) {
    throw new Error('The SubjectEditor context has not been loaded yet.');
  }
  return ctx;
};
