import React from 'react';
import { Announcement } from '@brainstud/academy-api';
import classNames from 'classnames/bind';
import { AnnouncementDisplay } from 'Components';
import { useLocalStorage } from 'Hooks';
import { Slider } from 'Modules/action-based-learning-panel/Components';
import styles from './AnnouncementSlider.module.css';

const cx = classNames.bind(styles);

type TProps = {
  announcements: Announcement[];
  showClosed?: boolean;
  darkBackground?: boolean;
};

export const AnnouncementsSlider = ({
  announcements,
  showClosed = false,
  darkBackground = false,
}: TProps) => {
  const [seenAnnouncements, setSeenAnnouncements] = useLocalStorage<string[]>(
    'seenAnnouncements',
    []
  );

  const handleClose = (identifier: string) => {
    setSeenAnnouncements((prevValues) => [identifier, ...(prevValues || [])]);
  };

  const notSeenAnnouncements = showClosed
    ? announcements
    : announcements.filter(
        (announcement) =>
          !seenAnnouncements?.includes(
            `${announcement.id}.${announcement.updatedAt}`
          )
      );

  return (
    <div>
      {notSeenAnnouncements.length > 0 &&
        (notSeenAnnouncements.length === 1 ? (
          <AnnouncementDisplay
            className={cx(styles.base)}
            title={notSeenAnnouncements[0].title}
            description={notSeenAnnouncements[0].description}
            type={notSeenAnnouncements[0].type}
            onClose={
              showClosed
                ? undefined
                : () =>
                    handleClose(
                      `${notSeenAnnouncements[0].id}.${notSeenAnnouncements[0].updatedAt}`
                    )
            }
          />
        ) : (
          <Slider
            className={cx(styles.base, {
              [styles.whiteButtons]: darkBackground,
            })}
            minimalButtons
          >
            {notSeenAnnouncements.map((announcement) => (
              <AnnouncementDisplay
                title={announcement.title}
                description={announcement.description}
                type={announcement.type}
                onClose={
                  showClosed
                    ? undefined
                    : () =>
                        handleClose(
                          `${announcement.id}.${announcement.updatedAt}`
                        )
                }
                key={announcement.id}
              />
            ))}
          </Slider>
        ))}
    </div>
  );
};
