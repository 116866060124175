import React, { useCallback, useEffect } from 'react';
import { Button, Toggle } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { VarietySelector } from 'Components';
import {
  useContentEditor,
  useUnsavedContentConfirmation,
} from 'Modules/course-editor';
import { useRouter } from 'next/router';
import { useLearningObjectProvider } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import styles from './LearningObjectEditBar.module.css';

const cx = classNames.bind(styles);

type Props = {
  coach?: boolean;
};

export const LearningObjectEditBar = ({ coach = false }: Props) => {
  const [t] = useTranslator();
  const { save, isUnsaved, isLoading, previewMode, setPreviewMode } =
    useContentEditor();

  const { varieties } = useLearningObjectProvider();
  const { handleUnsavedChangesCheck } = useUnsavedContentConfirmation({});
  const router = useRouter();

  const hasMultipleVarieties = varieties && varieties.length > 1;
  const { preview: isRoutePreview } = router.query as { preview: string };

  useEffect(() => {
    if (isRoutePreview) {
      setPreviewMode(true);
    }
  }, [isRoutePreview, setPreviewMode]);

  const handlePreviewState = useCallback(() => {
    setPreviewMode((prevValue) => !prevValue);
  }, [setPreviewMode]);

  const handleClose = useCallback(
    (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
      if (handleUnsavedChangesCheck(e)) {
        router.back();
      }
    },
    [handleUnsavedChangesCheck, router]
  );

  return (
    <div className={cx(styles.base, { coach })}>
      <div className={cx(styles.inner, { coach })}>
        {hasMultipleVarieties && (
          <VarietySelector style={{ marginRight: '1rem' }} />
        )}
        {/* Currently no Preview as coach, can be implemented later */}
        {!coach && (
          <div className={cx(styles.option)}>
            <Toggle
              name="learningobject_editor_preview"
              className={cx(styles.preview, styles.wrap)}
              onChange={handlePreviewState}
              checked={previewMode}
            >
              {t('views.courses.collection_edit.edit_bar.preview')}
            </Toggle>
          </div>
        )}
        <div className={styles.buttonGroup}>
          <Button
            type="button"
            success
            onClick={save}
            loading={isLoading}
            disabled={!isUnsaved}
            className={cx(styles.button)}
          >
            {t('views.courses.collection_edit.edit_bar.save')}
          </Button>
          <Button
            type="button"
            onClick={handleClose}
            loading={isLoading}
            className={cx(styles.button)}
          >
            {t('views.courses.collection_edit.edit_bar.close')}
          </Button>
        </div>
      </div>
    </div>
  );
};
