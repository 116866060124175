import { IResource } from '../Utils/Resource';

export interface WorkprocessBehavior
  extends IResource<'workprocess_behaviors'> {
  description: string;
  code: string;
}

export interface WorkprocessBehaviourCreateInput {
  description: string;
}

export interface WorkprocessBehaviorUpdateInput
  extends WorkprocessBehaviourCreateInput {}

export enum WorkprocessBehaviourSorts {}

export enum WorkprocessBehaviourIncludes {}

export enum WorkprocessBehaviourFilters {}
