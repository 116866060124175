import React from 'react';
import { Student } from '@brainstud/academy-api';
import classNames from 'classnames';
import { Avatar, FlexContainer, Link } from 'Components';
import { useAccessProvider } from 'Providers';
import styles from './StudentActivity.module.css';

const cx = classNames.bind(styles);

interface IProps {
  student: Student;
}

export const StudentActivity = ({ student }: IProps) => {
  const { hasAccess } = useAccessProvider();
  return (
    <Link
      className={cx(styles.colorlessLink, !hasAccess ? styles.disabled : '')}
      href={`students?students=${student.id}`}
      if={hasAccess}
    >
      <FlexContainer
        row
        className={cx(styles.base)}
        alignItems="center"
        justifyContent="flex-start"
      >
        <Avatar outline name={student.fullName} className={cx(styles.avatar)} />
        <div>
          <span className={cx(styles.fullName)}>{student.fullName}</span>
        </div>
      </FlexContainer>
    </Link>
  );
};
