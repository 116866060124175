import React, { useCallback, useMemo, useState } from 'react';
import { Button } from '@brainstud/ui';
import { Lock } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Container, Loading, VarietySelector } from 'Components';
import { AssignmentDetailsModal } from 'Modules/coach-panel/Modals';
import { useRouter } from 'next/router';
import {
  useAnswerGroupProvider,
  useEnvironmentProvider,
  useLearningObjectProvider,
  useLearningRouteProvider,
  useModals,
} from 'Providers';
import { AnswerProvider } from 'Providers/AnswerProvider';
import { AnswerForm } from 'Providers/AnswerProvider/Form';
import { ContentBlocksProvider } from 'Providers/ContentBlocksProvider';
import { useTranslator } from 'Providers/Translator';
import { getLayout } from 'Utils/getLayout';
import { useIsObjectViewDataLoading } from 'Views/CollectionViews/ObjectViews/useIsObjectViewDataLoading';
import { ActivityFeed } from '../../../../../Components/ActivityFeed';
import { AuthorFeedback } from '../../../Components';
import { withBase } from '../../../Layouts';
import { AssignmentStatus } from './AssignmentStatus';
import styles from './ObjectView.module.css';

const cx = classNames.bind(styles);

const ObjectView = withBase(() => {
  const [t] = useTranslator();
  const router = useRouter();
  const { learningObject, variety, varieties } = useLearningObjectProvider();
  const [activityFeedOpen, setActivityFeedOpen] = useState(false);
  const { layout: routeLayout } = useLearningRouteProvider();

  const { learningObjectId, courseId } = router.query as {
    learningObjectId: string;
    courseId: string;
  };

  const { isCoachEnvironment, isAdminEnvironment } = useEnvironmentProvider();
  const isCoachOrAdmin = isCoachEnvironment || isAdminEnvironment;
  const hasMultipleVarieties = varieties && varieties.length > 1;

  const [showFeedback, setShowFeedback] = useState(false);

  const blocks = useMemo(
    () =>
      (Array.isArray(variety?.content)
        ? variety?.content
        : variety?.content?.components) || [],
    [variety]
  );
  const answers = useMemo(
    () => learningObject?.answers?.() || [],
    [learningObject]
  );

  const hasUnCompletedConditions =
    !!learningObject?.conditions && learningObject?.meta?.isLocked;
  const isLocked = isCoachOrAdmin ? false : hasUnCompletedConditions;
  const isWithProgress = learningObject && 'userState' in learningObject;
  const { openModal } = useModals();

  const isLoading = useIsObjectViewDataLoading();

  const layout = useMemo(
    () => learningObject?.layout || 'panel',
    [learningObject?.layout]
  );

  const toggleActivityFeed = useCallback(
    () => setActivityFeedOpen((open) => !open),
    []
  );

  const Layout = getLayout(
    routeLayout || 'action-cards',
    'object',
    layout
  )?.view;

  if (!isLoading && isLocked) {
    return (
      <div className={cx(styles.lock)}>
        <Lock />
        <p>{t('views.object.lock_explanation')}</p>
        <Button to={`/courses/${courseId}`}>{t('go_back')}</Button>
      </div>
    );
  }

  const { isStudentEnvironment } = useEnvironmentProvider();
  const { currentAnswerGroup } = useAnswerGroupProvider(true) || {};
  const isReadOnly = !isStudentEnvironment;

  return (
    <>
      {isCoachOrAdmin && (
        <div className={cx(styles.bannerBackground)}>
          <Container className={cx(styles.bannerContainer)}>
            {hasMultipleVarieties && <VarietySelector />}
            <Button
              onClick={() =>
                openModal(AssignmentDetailsModal, {
                  assignment: learningObject,
                })
              }
              quiet
            >
              {t('views.courses.qualifications.show_linked_qualifications')}
            </Button>
          </Container>
        </div>
      )}
      {Layout && 'Header' in Layout && Layout.Header && <Layout.Header />}
      {!!isLoading || !routeLayout ? (
        <Loading />
      ) : (
        <ContentBlocksProvider readonly={isReadOnly}>
          <AnswerProvider>
            <div className={cx(styles.contentContainer)}>
              {!currentAnswerGroup && answers && (
                <AssignmentStatus
                  onToggleActivityFeedClick={toggleActivityFeed}
                />
              )}
              <AnswerForm
                className={cx(styles.form)}
                key={router.asPath}
                learningObject={learningObject}
                identifier={learningObjectId}
                onSubmit={() => setShowFeedback(answers.length === 0)}
                autoLoad={
                  !currentAnswerGroup ||
                  (currentAnswerGroup.status === 'CONCEPT'
                    ? ['CONCEPT']
                    : isStudentEnvironment)
                }
              >
                {showFeedback && !isCoachOrAdmin && isWithProgress && (
                  <AuthorFeedback
                    objectId={learningObjectId}
                    xp={learningObject?.userState?.xpAvailable}
                    onFinished={() => setShowFeedback(false)}
                  />
                )}
                <Layout blocks={blocks} />
              </AnswerForm>

              {(isCoachEnvironment || isStudentEnvironment) && (
                <ActivityFeed
                  className={cx(styles.feed)}
                  isOpen={activityFeedOpen}
                  onToggleIsOpen={setActivityFeedOpen}
                />
              )}
            </div>
          </AnswerProvider>
        </ContentBlocksProvider>
      )}
      {Layout && 'Footer' in Layout && Layout.Footer && <Layout.Footer />}
    </>
  );
});

// This component is lazy loaded, which requires a default export.
export default ObjectView;
