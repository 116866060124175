import { UseQueryOptions } from 'react-query';
import axios from 'axios';
import { getRootDomain } from 'Utils';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { THeaders } from '../Providers/ApiProvider/IApiContext';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  Language,
  LanguageCreate,
  LanguageUpdate,
  StoredFile,
  UUID,
} from '../Types';

interface ILanguagesProps {
  language?: UUID;
}

/**
 * Language can be used to get/update a new language in the platform.
 * @param language
 * @param queryParameters
 * @param settings
 */

export function useLanguage(
  { language, ...queryParameters }: ILanguagesProps = {},
  settings?: UseQueryOptions<DataDocument<Language>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { language: defaultLanguage },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!language,
      baseName: 'v1.language',
      baseUri: '/v1/languages',
      uri: `/${language || defaultLanguage}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<Language, LanguageCreate, LanguageUpdate>(
    request,
    settings
  );
}

/**
 * Languages can be used to list/create languages in the platform
 * @param queryParameters
 * @param settings
 */

export function useLanguages(
  { ...queryParameters }: Omit<ILanguagesProps, 'language'> = {},
  settings?: UseQueryOptions<DataDocument<Language[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.languages',
      baseUri: '/v1/languages',
      invalidate: ['v1.languages'],
      queryParameters,
    },
    context
  );
  return useIndexCR<Language, LanguageCreate>(request, settings);
}

export async function getLanguageDownloadLink(
  language: UUID,
  headers: THeaders = {}
) {
  const domainName = process.env.PORTAL_DOMAIN || getRootDomain();
  const result = new DataDocument<StoredFile>(
    await axios.get(`v1/languages/${language}/download_files`, {
      headers: {
        ...(domainName ? { 'X-Portal-Domain': domainName } : {}),
        'X-Feature-Next': '1',
        ...headers,
      },
    })
  );

  return [result.data, result] as const;
}
