import { UseMutationOptions } from 'react-query';
import { useCreate } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import { UUID } from '../Types';

interface SharePortfolioCreateInput {
  email_addresses: string[];
}

interface ISharePortfolioProps {
  enrollment?: UUID;
  answer?: UUID;
}

export function useSharePortfolio(
  { enrollment, answer }: ISharePortfolioProps = {},
  settings?: UseMutationOptions<
    DataDocument<undefined>,
    ErrorDocument,
    SharePortfolioCreateInput
  >
) {
  const context = useApi();
  const {
    parameters: { enrollment: defaultEnrollment },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(enrollment || defaultEnrollment),
      baseName: 'v1.sharePortfolio',
      baseUri: `/v1/share_portfolio/${enrollment || defaultEnrollment}${answer ? `/${answer}` : ''}`,
    },
    context
  );
  return useCreate<undefined, SharePortfolioCreateInput>(request, settings);
}
