import React, { ReactNode } from 'react';
import { ErrorBoundary } from 'Providers/ErrorDataProvider/ErrorBoundary';

type Props = {
  children: ReactNode | ReactNode[];
};

/**
 * A base block surrounding every View
 * @param children
 * @constructor
 */
export const Base = ({ children }: Props) => (
  <ErrorBoundary>{children}</ErrorBoundary>
);
