import { UseQueryOptions } from 'react-query';
import { useIndex } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  FaqAudience,
  FaqAudienceFilters,
  FaqAudienceIncludes,
  FaqAudienceSort,
  HookParams,
  UUID,
} from '../Types';

interface Props
  extends HookParams<
    typeof FaqAudienceIncludes,
    typeof FaqAudienceFilters,
    typeof FaqAudienceSort
  > {
  portal?: UUID;
}

export function useFaqAudiences(
  { portal, ...queryParameters }: Props = {},
  settings?: UseQueryOptions<DataDocument<FaqAudience[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { portal: defaultPortal },
  } = context;
  const request = new ApiRequest(
    {
      baseName: 'v1.portals.faqAudiences',
      baseUri: `/v1/portals/${portal || defaultPortal}/faq_audiences`,
      invalidate: ['faq_audience', 'faq_audiences'],
      queryParameters,
    },
    context
  );
  return useIndex<FaqAudience>(request, settings);
}
