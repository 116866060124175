import { Course, IResource, UUID } from '@brainstud/academy-api';
import { ScormSco } from './ScormSco';

export enum ScormVersion {
  SCORM_12 = 'scorm_12',
  SCORM_2004 = 'scorm_2004',
}

export enum ScormContentState {
  PENDING = 'pending',
  PROCESSING = 'processing',
  AVAILABLE = 'available',
  ERROR = 'error',
}

export const ScormContentStateColor = {
  pending: 'warning',
  processing: 'warning',
  available: 'success',
  error: 'error',
};

export interface Scorm extends IResource<'scorm'> {
  title: string;
  description?: string;
  scormVersion: ScormVersion;
  /** Wheter or not it's the primary SCORM packet of the linked course. */
  primary: boolean;
  /** The aspect ration of the SCORM player for this packet. */
  aspectRatio?: number;
  manifestIdentifier?: string;
  /** Entry url of the SCORM content (SCO's). */
  url?: string;
  fileName?: string;
  /** Content state on server. Shows if the files are available. */
  state: ScormContentState;
  meta?: {
    canBeDeleted: boolean;
  };
  course?: () => Course;
  scormScos?: () => ScormSco[];
}

export interface ScormCreateInput {
  description?: string;
  title?: string;
  file: UUID;
  primary: boolean;
}

export interface ScormUpdateInput extends Omit<ScormCreateInput, 'file'> {}

export enum ScormIncludes {
  course,
  scorm_scos,
}
export enum ScormFilters {}
export enum ScormSorts {}
