import React from 'react';
import { LearningObject } from '@brainstud/academy-api';
import { Status } from '@brainstud/ui';
import { useProgressCalculator } from 'Hooks';
import { ILearningSubjectWithResources } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { isLearningObject } from '../../Utils/isLearningGroupSubjectObject';
import { StatusColors } from '../../Utils/StatusColors';

type Props = {
  item: LearningObject | ILearningSubjectWithResources;
};

/**
 * Shows the status of the LearningObject in a Tag
 * @param item
 * @constructor
 */
export const ObjectStatus = ({ item }: Props) => {
  const { madeObjects, totalObjects } = useProgressCalculator(item);

  const [t, { ucf }] = useTranslator();

  return isLearningObject(item) ? (
    <div>
      {['TURNED_IN', 'REJECTED', 'ACCEPTED'].includes(
        item.progress?.status || item.userState?.status || ''
      ) && (
        <Status
          scheme={
            StatusColors[item.progress?.status! || item.userState?.status!]
          }
        >
          {
            {
              OPEN: ucf(t('states.open')),
              CONCEPT: ucf(t('states.concept')),
              TURNED_IN: ucf(t('states.turned_in')),
              REJECTED: ucf(t('states.rejected')),
              ACCEPTED: ucf(t('states.accepted')),
            }[item.progress?.status || item.userState?.status || 'OPEN']
          }
        </Status>
      )}
    </div>
  ) : (
    <div>{`${madeObjects} / ${totalObjects} ${t('components.card_subject.assignments_made')}`}</div>
  );
};
