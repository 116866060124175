export default {
  'v1.courses.index': ['products', 'products.catalogs'],
  'v1.courses.show': ['learning_object_collections', 'education_profiles'],
  'v1.learningObjectCollections.show': [
    'learning_objects',
    'learning_routes',
    'learning_subjects',
    'toolkits',
    'education_levels',
  ],
  'v1.toolkits': ['toolkit_categories', 'toolkit_categories.toolkit_items'],
  'v1.learningRoutes': ['learning_route_nodes'],
};
