import { useCallback, useContext } from 'react';
import {
  TViewNames,
  TViewSettings,
  TViewSettingsActions,
} from './ViewSettingsActions';
import {
  IViewSettingsContext,
  ViewSettingsContext,
} from './ViewSettingsContext';

// Narrows the type based on a property value.
type ExtractOnProp<T, K extends keyof T, V> = T extends unknown
  ? V extends T[K]
    ? { [P in keyof T]: P extends K ? T[P] & V : T[P] }
    : never
  : never;

/**
 * useViewSettingsProvider.
 *
 * Provides the context of the ViewSettingsProvider. It is recommended to use the `useViewSettings` hook instead, as
 * automates the `view` name addition.
 */
function useViewSettingsProvider<T extends TViewSettings = TViewSettings>() {
  const context = useContext(ViewSettingsContext);

  if (!context) {
    throw new Error('Please, initiate the ViewSettingsProvider first!');
  }

  return context as unknown as IViewSettingsContext<T>;
}

/**
 * useViewSettings.
 *
 * This method can be used to retrieve and set settings for a specific view. The settings are stored in a state on the
 * ViewSettingsProvider.
 */
export function useViewSettings<T extends TViewSettings>(viewName: TViewNames) {
  // A type to select a specific action from the list of ViewSettingsActions.
  type SelectAction<ActionType extends TViewSettingsActions<T>['type']> =
    ExtractOnProp<TViewSettingsActions<T>, 'type', ActionType>;
  // Not all actions have a payload. This type makes sure that if there is a payload, that it will omit the 'view' property from it.
  type Action<K extends TViewSettingsActions<T>['type']> =
    SelectAction<K> extends { payload: any }
      ? { type: K; payload: Omit<SelectAction<K>['payload'], 'view'> }
      : { type: K };

  const context = useViewSettingsProvider<T>();
  const view = context.views[viewName] || {};

  const dispatchWithView = useCallback(
    <S extends TViewSettingsActions<T>['type']>(action: Action<S>) => {
      // @ts-expect-error - The type is not well typable for some reason. But the hook is still typed.
      context?.dispatch({
        type: action.type,
        ...('payload' in action
          ? {
              payload: Array.isArray(action.payload)
                ? action.payload.map((payload) => ({
                    ...payload,
                    view: viewName,
                  }))
                : { ...action.payload, view: viewName },
            }
          : undefined),
      });
    },
    [context, viewName]
  );

  return [view, dispatchWithView] as const;
}
