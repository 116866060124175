import React, { useMemo } from 'react';
import {
  Course,
  StudentFilters,
  StudentSorts,
  TSortType,
  useStudents,
} from '@brainstud/academy-api';
import { Pagination } from '@brainstud/ui';
import { DataProvider, usePagination } from '@brainstud/universal-components';
import { Container, Loading } from 'Components';
import { useViewSettings, ViewSettings } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { StudentsTable } from '../../Components';
import { withContentErrorBoundary } from '../Errors/withContentErrorBoundary';
import { StudentsSearchAndFilter } from './StudentsSearchAndFilter/StudentsSearchAndFilter';

type TFilters = {
  filters: Partial<StudentFilters>;
  sort: TSortType<typeof StudentSorts>[];
};

const StudentsIndexView = withContentErrorBoundary(() => {
  const [t] = useTranslator();
  const [{ apiFilters, sort }] = useViewSettings<TFilters>(
    ViewSettings.STUDENT_OVERVIEW
  );
  const { page, limit } = usePagination();

  const [{ data: students }, { data: document, isLoading, isFetching }] =
    useStudents(
      {
        limit,
        page,
        sort: sort || ['-unseen_answer_count'],
        filter: {
          ...apiFilters,
          archived: apiFilters?.archived ?? false,
        },
      },
      { refetchInterval: 60000, staleTime: 10000 }
    );
  const courses = useMemo(
    () => document?.findByType<Course>('courses') || [],
    [document]
  );

  return (
    <DataProvider data={students}>
      <Container>
        <h1>{t('navigation.myStudents')}</h1>
      </Container>
      <StudentsSearchAndFilter
        courses={courses}
        viewName={ViewSettings.STUDENT_OVERVIEW}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <StudentsTable
            isFetching={isFetching}
            showingArchived={apiFilters?.archived === true}
            total={document?.meta?.total}
          />
          <Container style={{ marginTop: '2rem' }}>
            <Pagination
              totalPages={document?.meta?.lastPage}
              totalResults={`${t('components.pagination.totalResults')} ${document?.meta?.total}`}
              labels={{
                itemsPerPage: t('students.participantsPerPage'),
                results: t('students.totalResults'),
              }}
            />
          </Container>
        </>
      )}
    </DataProvider>
  );
});

export default StudentsIndexView;
