import React, { useCallback, useEffect, useState } from 'react';
import { IBlock, ITabbarContainerBlock } from '@brainstud/course-blocks';
import { Button } from '@brainstud/ui';
import { Add } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import { v4 } from 'uuid';
import { DeleteableInput } from '../../../Components';
import { useDynamicList } from '../../../Hooks/useDynamicList';
import { toName } from '../../../Support/toName';
import { TabContainerForm } from './TabContainerForm';
import styles from './TabbarContainerForm.module.css';

const classes = classNames.bind(styles);

type Props = {
  data: Omit<ITabbarContainerBlock, 'id' | 'type' | 'tabs'>;
  onChange?: (
    data: Omit<ITabbarContainerBlock, 'id' | 'type' | 'tabs'>
  ) => void;
};

/**
 * The edit state of a TabbarContainer content block.
 */
export const TabbarContainerForm = ({
  data: { variant: defaultVariant, blocks: defaultBlocks, style, className },
  onChange,
}: Props) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [variant, setVariant] =
    useState<ITabbarContainerBlock['variant']>(defaultVariant);
  const [t] = useTranslator();

  const createTabContainer = useCallback(
    (blocks: IBlock[] = []) => ({
      id: v4(),
      type: 'tab_container' as const,
      title: t(
        'course-editor.content_editor.blocks.tabbar_container.default_title'
      ),
      blocks,
    }),
    [t]
  );

  const [isLoadingBlocks, setIsLoadingBlocks] = useState(!defaultBlocks);
  const [blocks, { add, update, remove }] = useDynamicList(
    defaultBlocks || [createTabContainer()],
    {
      type: 'tab_container',
      title: t(
        'course-editor.content_editor.blocks.tabbar_container.default_title'
      ),
      blocks: [],
    },
    isLoadingBlocks
  );

  const blockCount = blocks?.length || 0;
  const isVerticalTabList = variant === 'vertical' || blockCount > 3;

  useEffect(() => {
    setIsLoadingBlocks(false);
  }, []);

  useEffect(() => {
    if (!isLoadingBlocks) {
      onChange?.({
        variant,
        blocks,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variant, blocks, isLoadingBlocks]);

  return (
    <div>
      <div className={styles['variant-picker']}>
        <span>{`${t('course-editor.content_editor.blocks.tabbar_container.variant_picker.label')}: `}</span>
        {blockCount <= 3 && (
          <>
            <Button
              link
              disabled={!isVerticalTabList}
              onClick={() => setVariant('horizontal')}
            >
              {t(
                'course-editor.content_editor.blocks.tabbar_container.variant_picker.horizontal'
              )}
            </Button>
            <span> | </span>
          </>
        )}
        <Button
          link
          disabled={isVerticalTabList}
          onClick={() => setVariant('vertical')}
        >
          {t(
            'course-editor.content_editor.blocks.tabbar_container.variant_picker.vertical'
          )}
        </Button>
      </div>
      <div
        className={classes(
          styles.base,
          { vertical: isVerticalTabList },
          className
        )}
        style={style}
      >
        <ul role="tablist" className={styles.tablist}>
          {(blocks || []).map((tabContainerBlock, tabIndex) => (
            <li
              key={tabContainerBlock.id}
              role="tab"
              className={classes(styles.tab, {
                active: tabIndex === activeTabIndex,
              })}
              aria-selected={tabIndex === activeTabIndex}
              onClick={() => setActiveTabIndex(tabIndex)}
              tabIndex={0}
              onKeyDown={(event) =>
                event.key === ' ' && setActiveTabIndex(tabIndex)
              }
            >
              <DeleteableInput
                id={toName(tabContainerBlock.id)}
                value={tabContainerBlock.title}
                onChange={(event) =>
                  update(tabContainerBlock.id, {
                    title: event.target.value,
                  })
                }
                onDelete={
                  blockCount > 1
                    ? () => {
                        setActiveTabIndex(0);
                        remove(tabContainerBlock.id);
                      }
                    : undefined
                }
              />
            </li>
          ))}
          <li>
            <button
              className={styles['add-button']}
              type="button"
              title={t(
                'course-editor.content_editor.blocks.tabbar_container.add_tab'
              )}
              onClick={() => {
                setActiveTabIndex(blockCount);
                add();
              }}
            >
              <Add fontSize="large" />
            </button>
          </li>
        </ul>
        {defaultBlocks?.map((tab, tabIndex) => (
          <TabContainerForm
            key={tab.id}
            id={tab.id}
            title={tab.title}
            blocks={tab.blocks}
            hidden={activeTabIndex !== tabIndex}
            vertical={isVerticalTabList}
          />
        ))}
      </div>
    </div>
  );
};
