import { createContext } from 'react';
import { Profile } from '@brainstud/academy-api';

export interface IProfileContext {
  isLoading: boolean;
  isMine: boolean;
  profile?: Profile;
}

export const ProfileContext = createContext<null | IProfileContext>(null);
