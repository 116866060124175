import { IResource } from '../Utils/Resource';

export interface LtiToolDeployment extends IResource<'lti_tool_deployments'> {}

export interface LtiToolDeploymentCreateInput {
  tag: string;
  contents: string;
}

export interface LtiToolDeploymentUpdateInput
  extends LtiToolDeploymentCreateInput {}

export enum LtiToolDeploymentIncludes {}

export enum LtiToolDeploymentFilters {}

export enum LtiToolDeploymentSorts {}
