import { useCallback, useMemo, useState } from 'react';
import {
  Exam,
  useCoaches,
  useCreateExamAssessor,
} from '@brainstud/academy-api';
import { Button, Callout, Dropdown, FileCard, Status } from '@brainstud/ui';
import { GetApp } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { useTranslator } from 'Providers/Translator';
import { DateFormatter } from 'Utils/DateFormatHelper';
import styles from './ExamProgress.module.css';

type Props = {
  exam: Exam;
};

const ExamProgress = ({ exam }: Props) => {
  const [t] = useTranslator();
  const assessors = useMemo(() => exam?.assessors?.(), [exam]);
  const [{ data: coaches }] = useCoaches();
  const assessorAccount = useMemo(
    () => assessors?.[0]?.account?.(),
    [assessors]
  );
  const uploads = useMemo(() => exam?.uploads?.() || [], [exam]);
  const assessment = useMemo(() => exam?.assessment?.(), [exam]);
  const [selectedCoach, setSelectedCoach] = useState<string>(
    assessorAccount?.id || ''
  );
  const [showSelectCoach, setShowSelectCoach] = useState<boolean>(false);
  const createExamAssessor = useCreateExamAssessor({ exam: exam?.id });

  const calcResult = useMemo(() => {
    if (!assessment) return undefined;
    if (assessment?.gradeType === 'NUMBER')
      return assessment?.gradeResult &&
        parseFloat(`${assessment.gradeResult}`) > 5.4
        ? 'V'
        : 'O';
    return assessment?.gradeResult;
  }, [assessment]);

  const handleEditCoach = useCallback(async () => {
    await createExamAssessor.mutateAsync({
      account: selectedCoach,
      is_primary_assessor: true,
      overwrite: true,
    });
    setShowSelectCoach(false);
  }, [createExamAssessor, selectedCoach]);

  const steps = useMemo(
    () => [...(exam?.stepApprovals?.() || [])].reverse(),
    [exam]
  );
  const findStep = useCallback(
    (step: string) => steps.find((item) => item.step === step),
    [steps]
  );

  const totalDuration = DateFormatter.toDuration(
    findStep('student_start')?.approvalDate,
    findStep('student_handed_in_exam')?.approvalDate
  );

  const resitCount = useMemo(
    () =>
      steps.reduce(
        (count, item) =>
          item.step === 'coach_starts_resit' ? count + 1 : count,
        0
      ),
    [steps]
  );

  return (
    <Grid container spacing={2}>
      <Grid item sm={6}>
        {showSelectCoach ? (
          <>
            <Dropdown
              onChange={(value) => setSelectedCoach(value as string)}
              defaultValue={selectedCoach}
              label={t('exams.examAssessor')}
              searchable={coaches.length > 5}
            >
              {coaches.map((coach) => (
                <Dropdown.Option key={coach.id} value={coach.id}>
                  {[coach.firstName, coach.lastNameInsertion, coach.lastName]
                    .filter(Boolean)
                    .join(' ')}
                </Dropdown.Option>
              ))}
            </Dropdown>
            <div style={{ marginTop: '1rem' }}>
              <Button
                type="button"
                loading={createExamAssessor.isLoading}
                onClick={handleEditCoach}
                style={{ marginRight: '1rem' }}
              >
                {t('exams.send')}
              </Button>
              <Button
                type="button"
                outline
                onClick={() => setShowSelectCoach(false)}
              >
                {t('cancel')}
              </Button>
            </div>
          </>
        ) : (
          <>
            <h4>{t('exams.examAssessor')}</h4>
            <div style={{ marginBottom: '1rem' }}>
              {assessorAccount?.fullName}
            </div>
            {/* TODO: uncomment when there is a solid workflow for assessor coaches within the coach panel */}
            {/* <Button link type="button" quiet onClick={() => setShowSelectCoach(true)}>
                {t('exams.editCoach')}
              </Button> */}
          </>
        )}
        <h4>{t('exams.handedInWork')}</h4>
        {findStep('student_start') && uploads.length > 0 ? (
          <div>
            {uploads?.map((upload) => (
              <FileCard
                key={upload.id}
                href={upload.contentAccessUrl}
                thumbnail={upload.contentAccessUrl}
                fileDate={DateFormatter.toRelativeWithinDays(
                  upload.createdAt
                )?.toString()}
                mimeType={upload.fileMimeType}
              >
                {upload.fileName}
              </FileCard>
            ))}
          </div>
        ) : (
          <div className={styles.details}>
            {t('exams.handedIn.no_files_yet')}
          </div>
        )}
        {resitCount > 0 && (
          <Callout margin="2.4rem 0 0" info>
            {t('exams.resits', { count: resitCount })}
          </Callout>
        )}
      </Grid>
      <Grid item sm={6}>
        <h4>{t('exams.examStatus')}</h4>
        <div className={styles.details}>
          <p>
            {`${t('exams.releasedAt')} `}
            {DateFormatter.toDateTime(exam.signalDate)}
          </p>
          <p>
            {t('exams.examStartedAt')}
            {DateFormatter.toDateTime(
              findStep('student_start')?.approvalDate
            ) || t('exams.noSignalYet')}
          </p>
          {totalDuration && (
            <p>{`${t('exams.durationOfExam')} ${totalDuration}`}</p>
          )}
          {findStep('student_handed_in_exam') && (
            <p>
              {t('exams.handedIn.title')}
              {DateFormatter.toDateTime(
                findStep('student_handed_in_exam')?.approvalDate
              )}
            </p>
          )}
          <h4>{t('exams.valuation.title')}</h4>
          <p>
            {['PRE_ASSESSMENT_APPROVED', 'HANDED_IN'].includes(
              exam.currentStep
            ) && t('exams.valuation.noValuation')}
          </p>
          {calcResult && (
            <Status scheme={calcResult === 'O' ? 'red' : 'green'}>
              {assessment?.gradeType !== 'NUMBER'
                ? t(`exams.valuation.${assessment?.gradeResult}`)
                : `${t(`exams.valuation.${calcResult}`)}: ${assessment?.gradeResult}`}
            </Status>
          )}
          {assessment?.feedback && (
            <>
              <h4>{t('exams.examFeedback')}</h4>
              <p>{assessment.feedback}</p>
            </>
          )}
        </div>
        {assessment?.proofOfParticipationUrl && (
          <Button type="button" href={assessment.proofOfParticipationUrl} quiet>
            <span>{t('exams.valuation.download_proof_of_participation')}</span>
            <GetApp />
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default ExamProgress;
