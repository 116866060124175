import React, {
  ReactNode,
  useEffect,
  useLayoutEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useMe } from '@brainstud/academy-api';
import { THEME_OVERRIDE_KEY } from 'Config/development';
import { useSessionStorage } from 'Hooks';
import kebabcaseKeys from 'kebabcase-keys';
import { ThemeContext } from './ThemeContext';
import { ITheme } from './ThemeInterface';
import { ThemeReducer } from './ThemeReducer';

type Props = {
  editable?: boolean;
  theme?: ITheme;
  children: ReactNode;
};

/**
 * ThemeProvider.
 *
 * Converts a theme object to CSS variables and stylesheet imports. Next to that, it makes everything theme
 * related available throughout the app.
 */
export const ThemeProvider = ({
  theme: propTheme,
  editable,
  children,
}: Props) => {
  const [me] = useMe({ enabled: !propTheme });
  const theme = propTheme ?? me?.theme;

  const styleElement = useRef(document.createElement('style'));
  useEffect(() => {
    styleElement.current.id = 'css_property_colors';
    document.head.appendChild(styleElement.current);
  }, []);

  const [localTheme, setLocalTheme] =
    useSessionStorage<ITheme>(THEME_OVERRIDE_KEY);

  const [{ loaded, colors, styles, assets }, dispatch] = useReducer(
    ThemeReducer,
    {
      loaded: !!localTheme,
      ...(localTheme || {}),
    }
  );

  useEffect(() => {
    if ((localTheme || theme) && !loaded) {
      dispatch({
        type: theme ? 'SET_THEME' : 'UPDATE_THEME',
        payload: (localTheme || theme)!,
      });
    }
  }, [localTheme, theme, loaded]);

  useEffect(() => {
    if (editable && loaded) {
      setLocalTheme({
        colors,
        styles,
        assets,
      });
    }
  }, [setLocalTheme, colors, styles, assets, editable, loaded]);

  useLayoutEffect(() => {
    styleElement.current.innerHTML = `
    :root { 
      ${Object.entries(kebabcaseKeys(colors || {})).reduce(
        (output, [property, value]) =>
          `${output} 
         --${property}: ${value};`,
        ''
      )}
      ${Object.entries(kebabcaseKeys(styles || {})).reduce(
        (output, [property, value]) =>
          `${output} 
         --${property}: ${value};`,
        ''
      )}
    }`;
  }, [colors, styles]);

  const context = useMemo(
    () => ({
      colors,
      styles,
      assets,
      dispatch,
    }),
    [colors, styles, assets]
  );

  const [loadedSheets, setLoadedSheets] = useState<string[]>([]);
  useEffect(() => {
    if (assets?.styles) {
      assets.styles.forEach((stylesheet) => {
        if (!loadedSheets.includes(stylesheet)) {
          const link = document.createElement('link');
          link.type = 'text/css';
          link.rel = 'stylesheet';
          document.head.appendChild(link);
          link.href = stylesheet;
          setLoadedSheets((sheets) => [...sheets, stylesheet]);
        }
      });
    }
  }, [assets, loadedSheets]);

  return (
    <ThemeContext.Provider value={context}>{children}</ThemeContext.Provider>
  );
};
