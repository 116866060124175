import { useCallback, useEffect } from 'react';
import { useSessionStorage } from 'Hooks';
import { ReducedUserExperienceModal } from 'Modals';
import { useWindowSize } from 'Modules/universal-components';
import { useModals } from 'Providers';

/**
 * Checks if a warning needs to be shown to warn the editor for a reduced user experience on mobile devices
 */
export function useReducedUserExperienceWarning() {
  const { openModal, closeModal } = useModals();

  const windowSize = useWindowSize();

  const [hasShownReducedExperienceModal, setHasShownReducedExperienceModal] =
    useSessionStorage('course-editor-reduced-experience-shown', false);
  const handleModalClose = useCallback(() => {
    setHasShownReducedExperienceModal((prev) => !prev);
    closeModal();
  }, [closeModal, setHasShownReducedExperienceModal]);

  useEffect(() => {
    if (
      windowSize?.width &&
      windowSize.width <= 768 &&
      !hasShownReducedExperienceModal
    ) {
      const timer = setTimeout(() => {
        openModal(ReducedUserExperienceModal, {
          onClose: handleModalClose,
        });
      }, 400);
      return () => clearTimeout(timer);
    }
  }, [
    handleModalClose,
    hasShownReducedExperienceModal,
    openModal,
    windowSize.width,
  ]);
}
