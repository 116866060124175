import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  ExamSampleAnswer,
  ExamSampleAnswerCreateInput,
  ExamSampleAnswerFilters,
  ExamSampleAnswerIncludes,
  ExamSampleAnswerSorts,
  ExamSampleAnswerUpdateInput,
  HookParams,
  UUID,
} from '../Types';

interface IProps
  extends HookParams<
    typeof ExamSampleAnswerIncludes,
    typeof ExamSampleAnswerFilters,
    typeof ExamSampleAnswerSorts
  > {
  exam?: UUID;
  sampleAnswer?: UUID;
}

export function useExamSampleAnswer(
  { exam, sampleAnswer, ...queryParameters }: IProps,
  settings?: UseQueryOptions<DataDocument<ExamSampleAnswer>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { exam: defaultExam, sampleAnswer: defaultSampleAnswer },
  } = context;
  const request = new ApiRequest(
    {
      enabled:
        !!(exam || defaultExam) && !!(sampleAnswer || defaultSampleAnswer),
      baseName: 'v1.exams.sampleAnswers',
      baseUri: `/v1/exams/${exam || defaultExam}/sample_answers`,
      invalidate: ['sample_answers', 'sample_answer'],
      uri: `/${sampleAnswer || defaultSampleAnswer}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    ExamSampleAnswer,
    ExamSampleAnswerCreateInput,
    ExamSampleAnswerUpdateInput
  >(request, settings);
}

export function useExamSampleAnswers(
  { exam, ...queryParameters }: Omit<IProps, 'sampleAnswer'>,
  settings?: UseQueryOptions<DataDocument<ExamSampleAnswer[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { exam: defaultExam },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(exam || defaultExam),
      baseName: 'v1.exams.sampleAnswers',
      baseUri: `/v1/exams/${exam || defaultExam}/sample_answers`,
      invalidate: ['sample_answers', 'sample_answer'],
      queryParameters,
    },
    context
  );
  return useIndexCR<ExamSampleAnswer, ExamSampleAnswerCreateInput>(
    request,
    settings
  );
}
