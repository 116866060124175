import { UseMutationOptions } from 'react-query';
import { useCreate } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import { OrderRegistration, UUID } from '../Types';

interface Props {
  order: UUID;
}

export function useOrderRegistration(
  { order }: Props,
  settings?: UseMutationOptions<
    DataDocument<undefined>,
    ErrorDocument,
    OrderRegistration
  >
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.orders.user_details',
      baseUri: `/v1/orders/${order}/pre_registration`,
      invalidate: ['v1.orders', 'order', 'orders'],
    },
    context
  );

  return useCreate<undefined, OrderRegistration>(request, settings);
}
