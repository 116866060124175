import {
  DepreciatedGroupNode,
  LearningObject,
  LearningRouteNode,
  LearningSubject,
} from '@brainstud/academy-api';

export type TLearningNodeSubjectObject =
  | LearningObject
  | LearningSubject
  | LearningRouteNode
  | DepreciatedGroupNode
  | {
      id: string;
      type: string;
    }
  | {
      id: string;
      resourceType: string;
    };

export function isLearningNode(
  item: TLearningNodeSubjectObject
): item is LearningRouteNode {
  return 'resourceType' in item && item.resourceType === 'learning_route_nodes';
}

export function isLearningGroup(
  item: TLearningNodeSubjectObject
): item is DepreciatedGroupNode {
  return 'type' in item && item.type === 'group';
}

export function isLearningSubject(
  item: TLearningNodeSubjectObject
): item is LearningSubject {
  return 'resourceType' in item && item.resourceType === 'learning_subjects';
}

export function isLearningObject(
  item: TLearningNodeSubjectObject
): item is LearningObject {
  return 'resourceType' in item && item.resourceType === 'learning_objects';
}
