import { useMemo } from 'react';
import {
  LearningObjectCollection,
  LearningRoute,
  LearningRouteNode,
} from '@brainstud/academy-api';
import { useEnvironmentProvider } from '../EnvironmentProvider';
import { convertToRouteTree } from './convertToRouteTree';
import { expandNodeToList } from './expandNodeToList';

/**
 * useNodes.
 *
 * This hook restructures a learning object collection API resource with
 * includes to a tree of normalized nodes based on the learning route. It
 * results in a set of arrays that can be used and searched.
 */
export const useNodes = (collection?: LearningObjectCollection) => {
  const { isAdminEnvironment } = useEnvironmentProvider();
  const learningRoutes = collection?.learningRoutes?.();
  // FIXME: Remove or alter .learningRoutes fallback when API provides single correct learning route per collection resource.
  const learningRoute = useMemo(
    () =>
      collection?.learningRoute?.() ||
      (learningRoutes && learningRoutes.length > 1
        ? (learningRoutes.find(
            ({ type }) => type === (isAdminEnvironment ? 'default' : 'custom')
          ) as LearningRoute)
        : learningRoutes?.[0]),
    [collection, learningRoutes, isAdminEnvironment]
  );

  const learningObjects = useMemo(
    () => collection?.learningObjects?.(),
    [collection]
  );
  const learningSubjects = useMemo(
    () => collection?.learningSubjects?.(),
    [collection]
  );
  const collectionItems = useMemo(
    () => [...(learningObjects || []), ...(learningSubjects || [])],
    [learningObjects, learningSubjects]
  );

  const nodes = useMemo(() => {
    if (learningRoute) {
      // FixMe learningRoute?.nodes (DepricatedGroupNode) should be removed once possible?
      const extendedLearningRouteNodes =
        (learningRoute.learningRouteNodes?.() ||
          learningRoute?.nodes?.map((item) => ({
            id: item.id,
            resourceType: 'learning_route_nodes' as const,
            title: item.attributes.title,
            nodeType: item.type,
            position: item.attributes.position,
            introduction: item.attributes.introduction,
            summary: item.attributes.summary,
            image: item.attributes.image,
            scheme: (item.objects || []).map((subitem) => ({
              id: subitem.id,
              type: subitem.type,
            })),
          })) ||
          []) as LearningRouteNode[];
      return convertToRouteTree(extendedLearningRouteNodes, collectionItems);
    }
    return [];
  }, [learningRoute, collectionItems]);

  const list = useMemo(() => nodes?.map(expandNodeToList).flat(), [nodes]);

  return {
    learningRoute,
    learningObjects,
    learningSubjects,
    collectionItems,
    learningRouteNodes: nodes,
    list,
  };
};
