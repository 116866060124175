import { getMe } from '@brainstud/academy-api';
import { CookieSerializeOptions } from 'cookie';
import { ServerResponse } from 'http';
import { NextApiResponse } from 'next';
import { setCookies } from './cookies';
import { getRootDomain } from './getRootDomain';

/**
 * handleCookieAuthentication.
 *
 * Sets the cookies required for an authenticated user.
 *
 * @param {NextApiResponse | ServerResponse} response
 * @param accessToken
 * @returns {Promise<(ServerResponse & {send: Send<any>, json: Send<any>, status: (statusCode: number) => NextApiResponse<any>, redirect: {(url: string): NextApiResponse<any>, (status: number, url: string): NextApiResponse<any>}, setPreviewData: (data: (object | string), options?: {maxAge?: number}) => NextApiResponse<any>, clearPreviewData: () => NextApiResponse<any>, unstable_revalidate: () => void, revalidate: (urlPath: string, opts?: {unstable_onlyGenerated?: boolean}) => Promise<void>}) | ServerResponse>}
 */
export async function handleCookieAuthentication(
  response: NextApiResponse | ServerResponse,
  accessToken: string
) {
  const domainName = getRootDomain(response.req.headers.host);
  const [me] = await getMe(domainName, {
    Authorization: `Bearer ${accessToken}`,
  });
  const account = me.account?.();
  if (account) {
    const options: CookieSerializeOptions = {
      httpOnly: true,
      maxAge: 2592000,
      path: '/',
      sameSite: 'lax',
      domain: domainName,
      secure: process.env.NODE_ENV === 'production',
    };

    setCookies(response, [
      {
        name: 'roles',
        value: account.roles.join(','),
        options,
      },
      {
        name: process.env.AUTH_COOKIE_NAME || 'auth_token',
        value: accessToken,
        options,
      },
    ]);
  } else {
    throw new Error('Unauthorized! Account not found with access token.');
  }
  return response;
}
