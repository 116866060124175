/**
 * Chunks an array to sub arrays of the provided number
 * @param chunkSize
 * @param originalArray
 */
export const chunkArrayPer = <T = unknown>(
  chunkSize: number,
  originalArray: T[]
): Array<Array<T>> => {
  const copy = [...originalArray];

  const nestedArray: any[] = [];
  while (copy.length) {
    nestedArray.push(copy.splice(0, chunkSize));
  }
  return nestedArray;
};
