import { UseMutationOptions } from 'react-query';
import { useCreate } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';

interface IForgotPasswordInput {
  email_address: string;
}

type TForgotPasswordSettings = UseMutationOptions<
  DataDocument<undefined>,
  ErrorDocument,
  IForgotPasswordInput
>;

/**
 * useForgotPassword.
 *
 * Starts process to retrieve lost password.
 */
export function useForgotPassword(settings?: TForgotPasswordSettings) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.account_actions/forgot_password',
      baseUri: '/v1/account_actions/forgot_password',
    },
    context
  );
  return useCreate<undefined, IForgotPasswordInput>(request, settings);
}
