import React, { CSSProperties, PropsWithChildren, useRef } from 'react';
import { BubbleMenu, BubbleMenuProps } from '@tiptap/react';
import classNames from 'classnames/bind';
import { useOnClickOutside } from '../../../Modules/universal-components';
import { useRichTextEditorProvider } from '../useRichTextEditorProvider';
import styles from './EditorTooltip.module.css';

const classes = classNames.bind(styles);

type Props = {
  onClickOutside?: () => void;
  shouldShow: BubbleMenuProps['shouldShow'];
  style?: CSSProperties;
  className?: string;
};

/**
 * EditorModal.
 */
export const EditorTooltip = ({
  onClickOutside,
  shouldShow,
  className,
  style,
  children,
}: PropsWithChildren<Props>) => {
  const container = useRef<HTMLDivElement>(null);
  const { editor } = useRichTextEditorProvider();
  useOnClickOutside([container], onClickOutside || (() => null));
  return (
    <div>
      <BubbleMenu
        editor={editor}
        className={classes(styles.base, className)}
        tippyOptions={{
          offset: [0, 5],
          // @ts-ignore Types not adjusted for css variables. This is correct.
          zIndex: 'var(--z-tooltip)',
          maxWidth: 'none',
        }}
        shouldShow={shouldShow}
      >
        <div
          className={classes(styles.inner, className)}
          style={style}
          ref={container}
        >
          {children}
          <svg
            width="29"
            height="9"
            viewBox="0 0 29 9"
            className={classes('tooltip-arrow')}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M11.6716 7.17158L7.32843 2.82843C5.51742 1.01742 3.06116 0 0.5 0L28.5 0C25.9388 0 23.4826 1.01741 21.6716 2.82842L17.3284 7.17157C15.7663 8.73367 13.2337 8.73367 11.6716 7.17158Z" />
          </svg>
        </div>
      </BubbleMenu>
    </div>
  );
};
