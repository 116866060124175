import React from 'react';
import { Enrollment } from '@brainstud/academy-api';
import classNames from 'classnames/bind';
import { Loading } from 'Components';
import { withBase } from 'Modules/action-based-learning-panel/Layouts';
import { ExamBlock } from 'Modules/action-based-learning-panel/Views';
import {
  ILearningRouteNodeWithResources,
  useLearningRouteProvider,
} from 'Providers';
import { getLayout } from '../../Utils/getLayout';
import styles from './CollectionView.module.css';

const cx = classNames.bind(styles);

type Props = {
  nodes?: ILearningRouteNodeWithResources[];
  enrollment?: Enrollment;
};

export const CollectionView = withBase(
  ({ nodes: modifiedNodes, enrollment }: Props) => {
    const exam = enrollment?.exam?.();
    const { isLoading, nodes, layout } = useLearningRouteProvider();
    const visibleNodes = modifiedNodes || nodes;
    const Layout = getLayout(layout || 'action-cards')?.view;

    return isLoading ? (
      <Loading />
    ) : (
      <div className={cx(styles.base)}>
        {exam && <ExamBlock />}
        {Layout && <Layout nodes={visibleNodes} />}
      </div>
    );
  }
);
