import { v4 } from 'uuid';
import type ErrorDocument from '../../Documents/ErrorDocument/ErrorDocument';
import { TError, TSource } from '../../Documents/ErrorDocument/types';
import { TLinks, TMeta } from '../../Documents/types';
import Resource from '../Resource';

export default class ErrorResource extends Resource {
  public status: string;

  public code: string;

  public title: string;

  public detail: string;

  public source?: TSource;

  public meta?: TMeta;

  public links?: TLinks;

  constructor(data: Partial<TError>, document: ErrorDocument) {
    super(
      {
        id: data.id || v4(),
        type: 'errors',
        ...data,
      },
      document
    );

    this.status = data.status || '501';
    this.title = data.title || 'Action response not expected';
    this.detail =
      data.detail ||
      'We failed to handle your request. Apologies for the inconvenience.';
    this.code = data.code || 'NOT_IMPLEMENTED';
    if (data.source) this.source = data.source;
    if (data.links) this.links = data.links;
    if (data.meta) this.meta = data.meta;
  }
}
