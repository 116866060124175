import React, { FC, useCallback, useState } from 'react';
import {
  Enrollment,
  Exam,
  useExam,
  useExamSampleAnswer,
} from '@brainstud/academy-api';
import { Button, Callout } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { ReactComponent as ArrowRight } from '../../Assets/arrow_right.svg';
import styles from './CreateExam.module.css';

type Props = {
  studentName: string;
  enrollment: Enrollment;
  exam?: Exam;
  onApproved: () => void;
};

/*
 *
 * This component will show a Callout that will allow to create a sample set or approve an exam
 *
 */

const CreateExam: FC<Props> = ({
  studentName,
  enrollment,
  exam,
  onApproved,
}) => {
  const [t] = useTranslator();
  const cx = classNames.bind(styles);
  const [approving, setApproving] = useState(false);
  const [setToast] = useToaster();

  const examStartTarget = enrollment?.examStartTarget;
  const canCreateSampleSet = enrollment?.meta?.canCreateSampleSet;

  const unit = examStartTarget?.type === 'percentage' ? '%' : 'XP';
  const received =
    unit === '%'
      ? enrollment.progress.percentage.completed
      : Number.parseFloat(
          enrollment.progress.xp.totalReceived as unknown as string
        );

  const minimumObjectCount = examStartTarget?.minimumObjectCount || 3;
  const required = examStartTarget?.amount || 80;

  const isResit = exam?.currentStep === 'PRE_ASSESSMENT_REJECTED';

  const examPossible = [
    'PRE_ASSESSMENT_APPROVED',
    'COMPLETED_APPROVED',
  ].includes(exam?.currentStep || '');
  const [{ create: createExam }] = useExam();
  const [{ create: createSampleSet }] = useExamSampleAnswer({
    exam: exam?.id,
  });

  const handleCreateExam = useCallback(async () => {
    if (!isResit) {
      await createExam.mutateAsync(
        {
          enrollment: enrollment.id,
        },
        {
          onError: (response) => {
            setToast(
              t(
                `exams.errors.${response.errors[0].code}`,
                {},
                t('exams.errors.default')
              ),
              'error'
            );
          },
        }
      );
    } else {
      await createSampleSet.mutateAsync(
        {
          action: 'add_sample_set',
        },
        {
          onError: (response) => {
            setToast(
              t(
                `exams.errors.${response.errors[0].code}`,
                {},
                t('exams.errors.default')
              ),
              'error'
            );
          },
        }
      );
    }
  }, [createExam, createSampleSet, enrollment.id, isResit, setToast, t]);

  const approveExam = useCallback(() => {
    setApproving(true);
    onApproved();
  }, [onApproved]);

  return (
    <div className={cx(styles.base)}>
      {isResit && !approving && (
        <Callout info className={cx(styles.root)}>
          <span>{t('exams.rejectedSampleSetCallout')}</span>
          <div className={cx(styles['button-wrapper'])}>
            <Button
              type="button"
              disabled={!canCreateSampleSet}
              onClick={handleCreateExam}
              loading={createSampleSet.isLoading || createExam.isLoading}
              className={cx(styles['create-exam-button'])}
            >
              <span>{t('exams.generateNewSampleSet')}</span>
            </Button>
            {examPossible && (
              <Button quiet onClick={() => approveExam()}>
                <span>{t('exams.release')}</span>
              </Button>
            )}
          </div>
        </Callout>
      )}
      {!isResit && (
        <Callout className={cx(styles.root)} info margin="1rem 0">
          <div>
            {t('exams.hasReceived', {
              name: studentName,
              received,
              required,
              unit,
            })}
            &nbsp;
            {t('exams.minimumAssignmentsDone', { minimumObjectCount })}
          </div>
          <Button
            type="button"
            disabled={!canCreateSampleSet}
            outline
            onClick={handleCreateExam}
            loading={createSampleSet.isLoading || createExam.isLoading}
            className={cx(styles['create-exam-button'])}
          >
            <span>{t('exams.manualStart')}</span>
            <ArrowRight />
          </Button>
        </Callout>
      )}
    </div>
  );
};

export default CreateExam;
