import { useEffect, useRef, useState } from 'react';
import { convertCSStoRGBValues, hexToRgbValues } from '../Utils/Color';

type TColorVariables = {
  [k: string]: string;
};

/**
 * Creates a style element with CSS variables based on color object
 */
export function useCSSVariables(variables: TColorVariables) {
  const styleElement = useRef<HTMLStyleElement>();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    styleElement.current = document.createElement('style');
    document.head.appendChild(styleElement.current);
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (styleElement.current && loaded) {
      let css = ':root{';
      Object.keys(variables).forEach((variable) => {
        if (
          typeof variables[variable] === 'string' &&
          variables[variable].length < 120
        ) {
          const value = convertCSStoRGBValues(
            hexToRgbValues(variables[variable])
          );
          css += `--${variable}: ${value};`;
        }
      });
      css += '}';
      styleElement.current.innerHTML = css;
    }
  }, [variables, loaded]);
}
