import { UseQueryOptions } from 'react-query';
import useIndexCR from '../Factories/useIndexCR';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import { AccountPreference } from '../Types';

type TPreferenceProps = {
  account?: string;
  property?: string;
};

/**
 * Account preferences can be used to save small preferences, like hiding a modal or such.
 * @param properties The account and preference uuid in an object
 * @param settings The Query settings
 * @returns AccountPreference[]
 */
export function useAccountPreferences(
  { account, property }: TPreferenceProps = {},
  settings?: UseQueryOptions<DataDocument<AccountPreference[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { account: defaultAccount },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(account || defaultAccount),
      baseName: 'v1.accounts.preferences',
      baseUri: `/v1/accounts/${account || defaultAccount}/preferences`,
      invalidate: ['accounts', 'account', 'v1.accounts', 'v1.announcements'],
      data: {
        property,
      },
    },
    context
  );

  return useIndexCR<AccountPreference, Partial<AccountPreference>>(
    request,
    settings
  );
}
