import { UseQueryOptions } from 'react-query';
import { useIndex } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import { PaymentMethod, UUID } from '../Types';

interface Props {
  portal?: UUID;
}

export function usePaymentMethods(
  { portal }: Props = {},
  settings?: UseQueryOptions<DataDocument<PaymentMethod[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { portal: defaultPortal },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(portal || defaultPortal),
      baseName: 'v1.portals',
      baseUri: `/v1/portals/${portal || defaultPortal}/payment_methods`,
      invalidate: ['payment_method', 'payment_methods'],
    },
    context
  );
  return useIndex<PaymentMethod>(request, settings);
}
