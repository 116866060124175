import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  Catalog,
  CatalogCreateInput,
  CatalogFilters,
  CatalogIncludes,
  CatalogSorts,
  CatalogUpdateInput,
  HookParams,
  UUID,
} from '../Types';

interface ICatalogProps
  extends HookParams<
    typeof CatalogIncludes,
    CatalogFilters,
    typeof CatalogSorts
  > {
  catalog?: UUID;
  portal?: UUID;
}

export function usePortalCatalog(
  { portal, catalog, ...queryParameters }: ICatalogProps = {},
  settings?: UseQueryOptions<DataDocument<Catalog>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { catalog: defaultCatalog, portal: defaultPortal },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(catalog || defaultCatalog) && !!(portal || defaultPortal),
      baseName: 'v1.catalogs',
      baseUri: `/v1/portals/${portal || defaultPortal}/catalogs`,
      uri: `/${catalog}`,
      invalidate: ['catalog', 'catalogs'],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<Catalog, CatalogCreateInput, CatalogUpdateInput>(
    request,
    settings
  );
}

export function usePortalCatalogs(
  { portal, ...queryParameters }: Omit<ICatalogProps, 'catalog'> = {},
  settings?: UseQueryOptions<DataDocument<Catalog[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { portal: defaultPortal },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(portal || defaultPortal),
      baseName: 'v1.catalogs',
      baseUri: `/v1/portals/${portal || defaultPortal}/catalogs`,
      invalidate: ['catalog', 'catalogs'],
      queryParameters,
    },
    context
  );
  return useIndexCR<Catalog, CatalogCreateInput>(request, settings);
}
