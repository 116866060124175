import React, { useCallback, useState } from 'react';
import { AnswerUpload, useAnswerUpload, UUID } from '@brainstud/academy-api';
import { FileCard } from '@brainstud/ui';
import { useForm } from 'Modules/universal-components';
import { useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';

type TProps = {
  upload: AnswerUpload;
  answerId?: UUID;
};

export const AnswerUploadCard = ({ upload, answerId }: TProps) => {
  const [{ destroy }] = useAnswerUpload(
    { answerUpload: upload.id, answer: answerId },
    { enabled: false }
  );
  const [isDeleted, setIsDeleted] = useState(false);
  const [t] = useTranslator();
  const [setToast] = useToaster();
  const { disabled } = useForm();

  const handleDestroy = useCallback(
    async () =>
      destroy.mutateAsync(undefined, {
        onSuccess: () => {
          setToast(t('course-blocks.file_uploader.delete.success'), 'success');
          setIsDeleted(true);
        },
        onError: () => {
          setToast(t('course-blocks.file_uploader.delete.failed'), 'error');
        },
      }),
    [destroy, setToast, t]
  );

  return isDeleted ? null : (
    <FileCard
      key={upload.id}
      href={upload.contentAccessUrl}
      mimeType={upload?.fileMimeType}
      thumbnail={upload.contentAccessUrl}
      previewUrl={upload?.previewUrl}
      onRemove={disabled ? undefined : handleDestroy}
    >
      {upload.fileNameOriginal}
    </FileCard>
  );
};
