import React, { useCallback, useMemo, useState } from 'react';
import {
  LearningObjectCollection,
  OnboardingScreen,
  useCreateEnrollmentParameters,
} from '@brainstud/academy-api';
import { Checkbox, ContentModal } from '@brainstud/ui';
import { Loading } from 'Components';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import { Slider } from '../Components';
import styles from './IntroductionModal.module.css';

type Props = {
  closeModal: () => void;
  collection: LearningObjectCollection;
};

const IntroductionModal = ({ closeModal, collection }: Props) => {
  const [t] = useTranslator();
  const markAsRead = useCreateEnrollmentParameters();

  // @ts-ignore FIXME Replace with version when loc.onboarding contains version everywhere
  const version = Array.isArray(collection?.onboarding)
    ? 'v1.0.0'
    : collection?.onboarding?.version || 'v1.0.0';

  const [dontShowAgain, setDontShowAgain] = useState(false);

  const handleCloseModal = useCallback(() => {
    if (dontShowAgain) {
      markAsRead.mutate(
        {
          property: 'loc_intro_modal',
          payload: version,
        },
        {
          onSettled: () => closeModal(),
        }
      );
    } else {
      closeModal();
    }
  }, [markAsRead, version, dontShowAgain, closeModal]);

  const screens = useMemo(
    () =>
      ((Array.isArray(collection?.onboarding)
        ? collection?.onboarding
        : collection?.onboarding?.steps) || []) as OnboardingScreen['steps'],
    [collection]
  );

  return (
    <ContentModal className={styles.modal}>
      {!collection ? (
        <Loading />
      ) : (
        <Slider
          speed={500}
          onLast={handleCloseModal}
          labels={{ last: t('introduction.last') }}
        >
          {screens.map((screen, i, arr) => {
            const lastItemInMap = arr.length - 1 === i;
            return (
              <div key={screen.title} style={{ width: '100%' }}>
                <h2>{screen.title}</h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(screen.content),
                  }}
                />
                {lastItemInMap && (
                  <Checkbox
                    quiet
                    id="dont_show_again"
                    name="dont_show_again"
                    onChange={() => setDontShowAgain((prevState) => !prevState)}
                  >
                    {t('introduction.not_open_again')}
                  </Checkbox>
                )}
              </div>
            );
          })}
        </Slider>
      )}
    </ContentModal>
  );
};

export default IntroductionModal;
