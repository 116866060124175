import { UseQueryOptions } from 'react-query';
import useIndexCR from '../Factories/useIndexCR';
import useSingleCRUD from '../Factories/useSingleCRUD';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  Enrollment,
  EnrollmentCreateInput,
  EnrollmentFilters,
  EnrollmentIncludes,
  EnrollmentSorts,
  EnrollmentUpdateInput,
  HookParams,
  UUID,
} from '../Types';

interface IEnrollmentProps
  extends HookParams<
    typeof EnrollmentIncludes,
    typeof EnrollmentFilters,
    typeof EnrollmentSorts
  > {
  enrollment?: UUID;
}

export function useEnrollment(
  { enrollment, ...queryParameters }: IEnrollmentProps = {},
  settings?: UseQueryOptions<DataDocument<Enrollment>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { enrollment: defaultEnrollment },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(enrollment || defaultEnrollment),
      baseName: 'v1.enrollments',
      baseUri: '/v1/enrollments',
      invalidate: [
        'enrollments',
        'enrollment',
        'v1.portals.courses',
        'courses',
      ],
      uri: `/${enrollment || defaultEnrollment}`,
      queryParameters,
    },
    context
  );

  return useSingleCRUD<
    Enrollment,
    EnrollmentCreateInput,
    EnrollmentUpdateInput
  >(request, settings);
}

export function useEnrollments(
  { ...queryParameters }: Omit<IEnrollmentProps, 'enrollment'> = {},
  settings?: UseQueryOptions<DataDocument<Enrollment[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.enrollments',
      baseUri: '/v1/enrollments',
      invalidate: [
        'enrollments',
        'enrollment',
        'v1.portals.courses',
        'courses',
      ],
      queryParameters,
    },
    context
  );
  return useIndexCR<Enrollment, EnrollmentCreateInput>(request, settings);
}
