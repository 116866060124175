import { IResource } from '../Utils/Resource';

export interface StudentGroup extends IResource<'student_groups'> {
  name: string;
}

export interface StudentGroupCreateInput {
  name: string;
}

export interface StudentGroupUpdateInput extends StudentGroupCreateInput {}

export enum StudentGroupIncludes {
  coach_account,
}

export enum StudentGroupFilters {
  coach_account,
}

export enum StudentGroupSorts {}
