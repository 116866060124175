import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  ExternalAssessment,
  ExternalAssessmentCreate,
  ExternalAssessmentIncludes,
  ExternalAssessmentsFilters,
  ExternalAssessmentsSorts,
  ExternalAssessmentUpdate,
  HookParams,
  UUID,
} from '../Types';

interface IExternalAssessmentsProps
  extends HookParams<
    typeof ExternalAssessmentIncludes,
    ExternalAssessmentsFilters,
    typeof ExternalAssessmentsSorts
  > {
  externalAssessment?: UUID;
}

export function useExternalAssessment(
  { externalAssessment, ...queryParameters }: IExternalAssessmentsProps = {},
  settings?: UseQueryOptions<DataDocument<ExternalAssessment>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { externalAssessment: defaultExternalAssessment },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(externalAssessment || defaultExternalAssessment),
      baseName: 'v1.externalAssessments',
      baseUri: '/v1/external_assessments',
      uri: `/${externalAssessment || defaultExternalAssessment}`,
      invalidate: ['external_assessment', 'external_assessments'],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    ExternalAssessment,
    ExternalAssessmentCreate,
    ExternalAssessmentUpdate
  >(request, settings);
}

export function useExternalAssessments(
  {
    ...queryParameters
  }: Omit<IExternalAssessmentsProps, 'externalAssessment'> = {},
  settings?: UseQueryOptions<DataDocument<ExternalAssessment[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.externalAssessments',
      baseUri: '/v1/external_assessments',
      invalidate: ['external_assessment', 'external_assessments'],
      queryParameters,
    },
    context
  );
  return useIndexCR<ExternalAssessment, ExternalAssessmentCreate>(
    request,
    settings
  );
}
