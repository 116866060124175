import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Block } from '@brainstud/academy-api';
import { PrefixPoster } from '@brainstud/action-based-learning-panel/Components';
import { BlockRenderer, Slider } from '@brainstud/course-blocks';
import {
  Button,
  Dropdown,
  FileCard,
  Panel,
  Status,
  Toggle,
} from '@brainstud/ui';
import { Forum } from '@mui/icons-material';
import { Grid } from '@mui/material';
import classNames from 'classnames/bind';
import { Container, Loading, Wrap } from 'Components';
import { ActivityFeed } from 'Components/ActivityFeed';
import { useSessionStorage } from 'Hooks';
import { useRouter } from 'next/router';
import { useBreadcrumbs } from 'Providers';
import { AnswerForm, useAnswerProvider } from 'Providers/AnswerProvider';
import { ContentBlocksProvider } from 'Providers/ContentBlocksProvider';
import { useTranslator } from 'Providers/Translator';
import { DateFormatter } from 'Utils/DateFormatHelper';
import { isEmpty } from 'Utils/isEmpty';
import { useAnswerReview } from '../../../Providers';
import { StatusColors } from '../../../Utils';
import { withContentErrorBoundary } from '../../Errors/withContentErrorBoundary';
import { ExternalAssessment } from './ExternalAssessment';
import styles from './ReviewAnswerView.module.css';

const cx = classNames.bind(styles);

const filterBlocks = (blocks: Block[]) => {
  const matches: Block[] = [];
  if (!Array.isArray(blocks)) return matches;
  blocks.forEach((block) => {
    if (
      [
        'file',
        'file_upload',
        'single_choice',
        'multiple_choice',
        'input',
        'textarea',
        'drag_to_sort',
        'cards_widget',
        'drag_and_drop',
      ].includes(block.type!)
    ) {
      matches.push(block);
    } else if (block.blocks) {
      const newBlocks = filterBlocks(block.blocks);
      if (newBlocks.length) matches.push(...newBlocks);
    }
  });
  return matches;
};

type Props = {
  /** A way to force the verbosity of the assignment text */
  verbose?: boolean;
  /** Whether the ReviewAnswerView is called in a 'shared' context. Someone has been asked to review an answer. */
  shared?: boolean;
  /** Whether the ReviewAnswerView should hide the Activity Feed. */
  hideActivityFeed?: boolean;
};

/**
 * The view for assessing answers, both for learning object answers as sample set answers.
 */
export const ReviewAnswerView = withContentErrorBoundary(
  ({ verbose: forceVerbose, shared, hideActivityFeed }: Props) => {
    const [t] = useTranslator();
    const router = useRouter();
    const { setAnswerIndex } = useAnswerProvider();
    const {
      learningObject,
      variety,
      answers,
      answerId,
      answer,
      setAnswerId,
      isLoading,
      enrollmentId,
    } = useAnswerReview() || {};
    const [activityFeedOpen, setActivityFeedOpen] = useState(false);

    useBreadcrumbs(
      {
        ...(learningObject && {
          [learningObject.id]: { text: learningObject.title, href: null },
        }),
      },
      [learningObject]
    );

    const blocks = useMemo(
      () =>
        (Array.isArray(variety?.content)
          ? variety?.content
          : variety?.content?.components) || ([] as Block[]),
      [variety]
    );

    const filteredBlocks = useMemo(() => {
      if (!blocks) return [];
      return filterBlocks(blocks);
    }, [blocks]);

    const isReviewAsCoach = router.pathname.startsWith('/coach');

    const [verbose, setVerbose] = useSessionStorage(
      'verbose',
      isReviewAsCoach ? filteredBlocks.length === 0 : true
    );
    const isVerbose = !!(verbose || forceVerbose);
    const renderBlocks = useMemo(
      () =>
        isVerbose || filteredBlocks.length === 0 ? blocks : filteredBlocks,
      [isVerbose, blocks, filteredBlocks]
    );

    const hasContent = !isEmpty(variety?.content);
    const isLegacyFileOnlyAnswer =
      isEmpty(answer?.givenAnswer) && !!answer?.uploads && !hasContent;

    const externalAssessments = useMemo(
      () => answer?.externalAssessments?.() || [],
      [answer]
    );

    const handleAnswerChange = useCallback(
      (id?: string) => {
        setAnswerId?.(id);
        setActivityFeedOpen(true);

        if (id && answers) {
          const index = answers.findIndex(
            ({ id: currentAnswerId }) => currentAnswerId === id
          );

          if (index !== -1) setAnswerIndex(index);
        }
      },
      [setAnswerId, answers, setAnswerIndex]
    );

    /**
     * Open once learning object is loaded
     */
    useEffect(() => {
      if (learningObject) setActivityFeedOpen(true);
    }, [learningObject]);

    return !learningObject ? (
      <Loading />
    ) : (
      <div className={cx(styles.base)}>
        {enrollmentId && (
          <div className={cx(styles.settings)}>
            <Container className={cx(styles.container)}>
              {forceVerbose === undefined && (
                <div className={cx(styles.verboseToggle)}>
                  {t('review.verboseDescription')}
                  <Toggle
                    name="verbose"
                    disabled={
                      filteredBlocks.length === 0 || isLegacyFileOnlyAnswer
                    }
                    checked={
                      (verbose || filteredBlocks.length === 0) &&
                      !isLegacyFileOnlyAnswer
                    }
                    onChange={() => setVerbose((prevVerbose) => !prevVerbose)}
                  />
                  <span>{t('review.verboseDescription')}</span>
                </div>
              )}
              {isReviewAsCoach && (
                <div className={cx(styles.activity)}>
                  {answers && answers.length > 1 ? (
                    <Dropdown
                      onChange={handleAnswerChange}
                      value={answerId}
                      small
                    >
                      {answers.map((item) => (
                        <Dropdown.Option key={item.id} value={item.id}>
                          <span className={cx(styles.date)}>
                            {DateFormatter.toDate(item.createdAt)}
                          </span>
                          <Status
                            scheme={StatusColors[item.status || 'TURNED_IN']}
                          >
                            {t(`review.statusOptions.${item.status}`)}
                          </Status>
                        </Dropdown.Option>
                      ))}
                    </Dropdown>
                  ) : (
                    <>
                      {((answers?.length && answers.length > 0) || answer) && (
                        <span>
                          {t('views.coach.review.turned_in', {
                            date: DateFormatter.toDateTime(answer?.createdAt),
                          })}
                        </span>
                      )}
                      {(answers?.length || answer) && (
                        <Status
                          scheme={StatusColors[answer?.status || 'TURNED_IN']}
                        >
                          {t(`review.statusOptions.${answer?.status}`)}
                        </Status>
                      )}
                    </>
                  )}
                  {!hideActivityFeed && (
                    <Button
                      type="button"
                      small
                      outline
                      onClick={() => setActivityFeedOpen((isOpen) => !isOpen)}
                    >
                      <span>{t('views.coach.review.activity')}</span>
                      <Forum />
                    </Button>
                  )}
                </div>
              )}

              {shared && !isReviewAsCoach && !hideActivityFeed && (
                <div className={cx(styles.activity)}>
                  <Button
                    type="button"
                    small
                    outline
                    onClick={() => setActivityFeedOpen((isOpen) => !isOpen)}
                  >
                    <span>{t('views.coach.review.activity')}</span>
                    <Forum />
                  </Button>
                </div>
              )}
            </Container>
          </div>
        )}

        <div className={cx(styles.wrapper)}>
          <Container className={cx(styles.container)}>
            {isLoading && !answer ? (
              <Loading />
            ) : (
              <ContentBlocksProvider answer={answer} readonly>
                {!isLegacyFileOnlyAnswer ? (
                  <AnswerForm
                    className={cx(styles.form)}
                    key={router.asPath}
                    identifier={learningObject.id}
                    disabled
                    learningObject={learningObject}
                  >
                    {!learningObject.layout ||
                    learningObject.layout === 'panel' ? (
                      <Grid
                        container
                        justifyContent="center"
                        spacing={4}
                        className={cx('grid-container')}
                      >
                        {renderBlocks.map((component, i) => (
                          <Grid
                            item
                            key={component.id}
                            md={variety?.layout !== 'rows' && verbose ? 6 : 12}
                            sm={12}
                            xs={12}
                          >
                            <Panel className={cx(styles.panel)}>
                              {i === 0 &&
                                verbose &&
                                (variety?.version === undefined ||
                                  variety.version < 3) && <PrefixPoster />}
                              <BlockRenderer
                                id={learningObject.id}
                                block={component}
                              />
                            </Panel>
                          </Grid>
                        ))}
                        {isReviewAsCoach && externalAssessments.length > 0 && (
                          <Grid
                            item
                            md={variety?.layout !== 'rows' && verbose ? 6 : 12}
                            sm={12}
                            xs={12}
                          >
                            <Panel>
                              <div className={cx(styles.external)}>
                                <h4>{t('review.external_assessment.title')}</h4>
                                <Slider>
                                  {externalAssessments.map(
                                    (externalAssessment) => (
                                      <ExternalAssessment
                                        key={externalAssessment.id}
                                        name={externalAssessment.name}
                                        emailAddress={
                                          externalAssessment.emailAddress
                                        }
                                        date={externalAssessment.createdAt}
                                        feedback={externalAssessment.feedback}
                                        storedFiles={externalAssessment.storedFiles?.()}
                                      />
                                    )
                                  )}
                                </Slider>
                              </div>
                            </Panel>
                          </Grid>
                        )}
                      </Grid>
                    ) : (
                      renderBlocks.map((block) => (
                        <React.Fragment key={block.id}>
                          <Wrap
                            if={!isVerbose}
                            with={({ children }) => <Panel>{children}</Panel>}
                          >
                            <BlockRenderer id={block.id} block={block} />
                          </Wrap>
                          {externalAssessments.length > 0 && (
                            <Panel>
                              <section>
                                <h4>{t('review.external_assessment.title')}</h4>
                                <Slider>
                                  {externalAssessments.map(
                                    (externalAssessment) => (
                                      <ExternalAssessment
                                        key={externalAssessment.id}
                                        name={externalAssessment.name}
                                        emailAddress={
                                          externalAssessment.emailAddress
                                        }
                                        date={externalAssessment.createdAt}
                                        storedFiles={externalAssessment.storedFiles?.()}
                                      />
                                    )
                                  )}
                                </Slider>
                              </section>
                            </Panel>
                          )}
                        </React.Fragment>
                      ))
                    )}
                  </AnswerForm>
                ) : (
                  // Legacy learning answers, with only uploaded files
                  <Panel pad>
                    {answer?.uploads?.().map((uploadedFile) => (
                      <FileCard
                        key={uploadedFile.id}
                        href={uploadedFile.contentAccessUrl}
                        mimeType={uploadedFile.fileMimeType}
                        thumbnail={uploadedFile.previewUrl}
                      >
                        {uploadedFile.fileNameOriginal}
                      </FileCard>
                    ))}
                  </Panel>
                )}
              </ContentBlocksProvider>
            )}
          </Container>

          {!hideActivityFeed && (
            <ActivityFeed
              isOpen={activityFeedOpen}
              onToggleIsOpen={setActivityFeedOpen}
            />
          )}
        </div>
      </div>
    );
  }
);
