import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import { HookParams, UUID } from '../Types';
import {
  Comment,
  CommentCreateInput,
  CommentFilters,
  CommentIncludes,
  CommentSorts,
} from '../Types/Resources/Comment';

interface ICommentProps
  extends HookParams<
    typeof CommentIncludes,
    CommentFilters,
    typeof CommentSorts
  > {
  comment?: UUID;
}

export function useComment(
  { comment, ...queryParameters }: ICommentProps = {},
  settings?: UseQueryOptions<DataDocument<Comment>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { comment: defaultComment },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(comment || defaultComment),
      baseName: 'v1.comments',
      baseUri: '/v1/comments',
      invalidate: [
        'comment',
        'comments',
        'v1.enrollment.learningObject.activities',
      ],
      uri: `/${comment || defaultComment}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<Comment, CommentCreateInput>(request, settings);
}

export function useComments(
  { ...queryParameters }: Omit<ICommentProps, 'comment'> = {},
  settings?: UseQueryOptions<DataDocument<Comment[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.comments',
      baseUri: '/v1/comments',
      invalidate: [
        'comment',
        'comments',
        'v1.enrollment.learningObject.activities',
      ],
      queryParameters,
    },
    context
  );

  return useIndexCR<Comment, CommentCreateInput>(request, settings);
}
