import { IResource } from '../Utils/Resource';
import { UUID } from '../Utils/UUID';
import { LearningObject } from './LearningObject';
import { Quiz } from './Quiz';

export interface LearningSubject extends IResource<'learning_subjects'> {
  title: UUID;
  introduction: string;
  shortIntroduction: string;
  template: string;
  /** @deprecated Use .scheme instead! */
  objects: Array<{
    id: UUID;
    type: string;
    position: number;
    objectType: string;
  }>;
  poster?: {
    fileName?: string;
    src?: string;
    type?: string;
  };
  /** @deprecated  use poster instead */
  thumbnail?: {
    fileName?: string;
    src?: string;
    type?: string;
  };
  scheme: SchemeItem[];
  learningObjects?: () => LearningObject[];
  learningSubjects?: () => LearningSubject[];
  layout?: string;
  quiz?: () => Quiz;
}

export type SchemeItem = {
  id: UUID;
  type: string;
  /** @DEPRECATED Use array index instead */
  position?: number;
  /** @DEPRECATED  */
  objectType?: string;
};

export interface LearningSubjectCreateInput {
  title: string;
  introduction: string;
  template?: string;
  poster_url?: string;
  scheme: SchemeItem[];
  /** @DEPRECATED Use .scheme instead */
  objects?: SchemeItem[];
  relationships?: {
    learning_object_collection?: UUID;
    learning_route_node?: UUID;
    learning_subject?: UUID;
  };
  layout?: string;
}

export interface LearningSubjectUpdateInput
  extends Partial<LearningSubjectCreateInput> {}

export enum LearningSubjectIncludes {
  learning_objects,
  quiz,
}

export enum LearningSubjectFilters {}

export enum LearningSubjectSorts {}
