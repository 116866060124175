import React, { useMemo } from 'react';
import {
  Account,
  Course,
  DateTimeString,
  SampleSet,
  UUID,
} from '@brainstud/academy-api';
import { Callout, Table, Th, Tr } from '@brainstud/ui';
import { useTranslator } from 'Providers/Translator';
import { SampleSetRow } from './SampleSetRow/SampleSetRow';

type Props = {
  /** The samplesets the show the table for */
  sampleSets: SampleSet[];
  /** Sortinghandler */
  setSort: (value: string[]) => void;
};

export type SampleSetRowType = {
  id: UUID;
  course?: Course;
  status: 'SAMPLESET';
  enrollmentId: UUID;
  participant?: Account;
  date: DateTimeString;
};

/**
 * The SampleSetsTable module provides a table for the given samplesets which is used in the coach review page,
 * alongside tables for the exams and answers.
 */
export const SampleSetsTable = ({ sampleSets, setSort }: Props) => {
  const [t] = useTranslator();

  const rows = useMemo<SampleSetRowType[]>(
    () =>
      sampleSets.map((sampleSet) => {
        const enrollment = sampleSet.enrollment?.() || {
          id: '',
          createdAt: '',
        };
        const course = sampleSet.enrollment?.().course?.();
        const account = sampleSet.enrollment?.().account?.();

        return {
          id: sampleSet.id,
          course,
          status: 'SAMPLESET',
          enrollmentId: enrollment.id,
          participant: account,
          date: enrollment.createdAt,
        };
      }),
    [sampleSets]
  );

  return rows.length > 0 ? (
    <Table onSort={(sortOn) => (sortOn ? setSort([sortOn]) : setSort([]))}>
      <Table.THead>
        <Tr>
          <Th sortOn="account">{t('review.participant')}</Th>
          <Th sortOn="course">{t('review.course')}</Th>
          <Th>{t('review.status')}</Th>
          <Th sortOn="created_at">{t('review.date')}</Th>
        </Tr>
      </Table.THead>

      <Table.TBody>
        {rows.map((row) => (
          <SampleSetRow data={row} key={row.id} />
        ))}
      </Table.TBody>
    </Table>
  ) : (
    <Callout info margin="2rem 0">
      {t('review.noReviews.sampleSets')}
    </Callout>
  );
};
