import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  EventCalendar,
  EventCalendarCreateInput,
  EventCalendarFilters,
  EventCalendarIncludes,
  EventCalendarSorts,
  EventCalendarUpdateInput,
  HookParams,
  UUID,
} from '../Types';

interface IEventCalendarProps
  extends HookParams<
    typeof EventCalendarIncludes,
    typeof EventCalendarFilters,
    typeof EventCalendarSorts
  > {
  eventCalendar?: UUID;
}

export function useEventCalendar(
  { eventCalendar, ...queryParameters }: IEventCalendarProps = {},
  settings?: UseQueryOptions<DataDocument<EventCalendar>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { eventCalendar: defaultEventCalendar },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(eventCalendar || defaultEventCalendar),
      baseName: 'v1.eventCalendars',
      baseUri: '/v1/event_calendars',
      uri: `/${eventCalendar || defaultEventCalendar}`,
      invalidate: ['event_calendar', 'event_calendars'],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    EventCalendar,
    EventCalendarCreateInput,
    EventCalendarUpdateInput
  >(request, settings);
}

export function useEventCalendars(
  { ...queryParameters }: Omit<IEventCalendarProps, 'eventCalendar'> = {},
  settings?: UseQueryOptions<DataDocument<EventCalendar[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.eventCalendars',
      baseUri: '/v1/event_calendars',
      invalidate: ['event_calendars', 'event_calendars'],
      queryParameters,
    },
    context
  );
  return useIndexCR<EventCalendar, EventCalendarCreateInput>(request, settings);
}
