/**
 * BreadcrumbsActions.
 *
 * List of constants identifying the actions available in the BreadcrumbsReducer.
 */
export default {
  ADD_CRUMBS: 'ADD_CRUMB',
  REMOVE_CRUMBS: 'REMOVE_CRUMB',
  RESET: 'RESET',
} as const;
