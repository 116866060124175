import React, { useCallback } from 'react';
import { Callout } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { useTwoFactor } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import { TwoFactorVerificationForm } from './Form/TwoFactorVerificationForm';
import styles from './TwoFactorAuthSetupView.module.css';

const cx = classNames.bind(styles);

export const TwoFactorAuthSetupView = () => {
  const [t] = useTranslator();
  const { twoFactorAuthentication, handleTwoFactor, isAccountPage } =
    useTwoFactor();

  const handleSubmit = useCallback(
    (verification_code: string) => {
      handleTwoFactor(undefined, verification_code);
    },
    [handleTwoFactor]
  );

  return (
    <>
      {isAccountPage && <h3>{t('security.two_factor.setup.title')}</h3>}
      <div className={cx(styles.base)}>
        <div
          className={cx(styles['qr-container'], {
            'tfa-required': !isAccountPage,
          })}
        >
          <div className={cx(styles.qr, { 'tfa-required': !isAccountPage })}>
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizer(twoFactorAuthentication?.qrCode),
              }}
            />
            <div
              className={cx(styles['info-wrapper'], {
                'tfa-required': !isAccountPage,
              })}
            >
              <div
                className={cx(styles.info, { 'tfa-required': !isAccountPage })}
              >
                <strong>{twoFactorAuthentication?.secret}</strong>
                <span>{t('security.two_factor.setup.qr_scan_info')}</span>
              </div>
            </div>
          </div>
          <div
            className={cx(styles.verification, {
              'tfa-required': !isAccountPage,
            })}
          >
            <TwoFactorVerificationForm onSubmit={handleSubmit}>
              {isAccountPage && (
                <Callout icon={false} info>
                  <div className={cx(styles['info-callout'])}>
                    <strong>
                      {t('security.two_factor.setup.info_callout_title')}
                    </strong>
                    <span>{t('security.two_factor.setup.info_callout')}</span>
                  </div>
                </Callout>
              )}
            </TwoFactorVerificationForm>
          </div>
        </div>
      </div>

      <div className={cx(styles.mobile)}>
        <Callout info>{t('security.two_factor.mobile')}</Callout>
        <span>{t('security.two_factor.activate_mobile')}</span>
      </div>
    </>
  );
};
