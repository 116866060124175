import React, { useCallback, useEffect, useState } from 'react';
import { GridViewRounded, ViewStream } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import styles from 'Components/GridListToggle/GridListToggle.module.css';

const cx = classNames.bind(styles);

type Props = {
  /** Set the state at load time (true = grid, false = list) */
  defaultValue?: boolean;
  /** sets the value form the parent */
  value?: boolean;
  /** Event handler when the toggle changes */
  onChange: (state: boolean) => void;
};

/**
 * Shows two icons with which you can toggle to either Grid or List view
 */
export const GridListToggle = ({
  defaultValue = true,
  value,
  onChange,
}: Props) => {
  const [t] = useTranslator();
  const [toggle, setToggle] = useState(value || defaultValue);

  const handleToggle = useCallback(
    (state: boolean) => {
      setToggle(state);
      if (onChange && state !== toggle) onChange(state);
    },
    [onChange, toggle]
  );

  useEffect(() => {
    if (typeof value !== 'undefined') setToggle(value);
  }, [value]);

  return (
    <div className={cx(styles.base)}>
      <div className={cx(styles.buttons, { toRight: !toggle })}>
        <button
          type="button"
          title={`${t('components.grid.toggle.display_in_grid')} (${toggle ? t('active') : t('inactive')})`}
          onClick={() => handleToggle(true)}
          className={cx(styles.button, { isActive: toggle })}
        >
          <GridViewRounded fontSize="large" />
        </button>
        <button
          type="button"
          title={`${t('components.grid.toggle.display_in_table')} (${!toggle ? t('active') : t('inactive')})`}
          onClick={() => handleToggle(false)}
          className={cx(styles.button, { isActive: !toggle })}
        >
          <ViewStream fontSize="large" />
        </button>
      </div>
    </div>
  );
};
