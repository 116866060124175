import React, { useCallback, useState } from 'react';
import { GlossaryTerm, useGlossaryTerm } from '@brainstud/academy-api';
import { Button, ConfirmationModal, ContentModal, Input } from '@brainstud/ui';
import { Form } from '@brainstud/universal-components';
import { DeleteOutline, Save } from '@mui/icons-material';
import { useModals, useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import RichEditor from '../../Components/RichEditor/RichEditor';

type Props = {
  term?: GlossaryTerm;
  courseId: string;
  onSuccess: (term: GlossaryTerm) => void;
};

type TFormData = { startLetter: string; term: string; definition: string };

const GlossaryFormModal = ({ term, courseId, onSuccess }: Props) => {
  const [{ createOrUpdate, destroy }] = useGlossaryTerm(
    {
      course: courseId,
      glossaryTerm: term?.id,
    },
    { enabled: false }
  );
  const [t] = useTranslator();
  const { openModal, closeModal } = useModals();
  const [setToast] = useToaster();

  const isNewTerm = !term;

  const handleUpdate = useCallback(
    (formData: TFormData) =>
      createOrUpdate.mutateAsync(formData, {
        onSuccess: ({ data }) => {
          onSuccess?.(data);
          setToast(t('views.course.glossaries.toastUpdateTerm'), 'success');
          closeModal(true);
        },
        onError: () => {
          setToast(t('views.course.glossaries.toastUpdateErrorTerm'), 'error');
        },
      }),
    [createOrUpdate, onSuccess, setToast, t, closeModal]
  );

  const handleDestroy = useCallback(
    async () =>
      destroy.mutateAsync(undefined, {
        onSuccess: () => {
          setToast(t('views.course.glossaries.toastDestroyTerm'), 'success');
          closeModal(true);
        },
        onError: () => {
          setToast(t('views.course.glossaries.toastDestroyErrorTerm'), 'error');
        },
      }),
    [destroy, setToast, t, closeModal]
  );

  const [startLetter, setStartLetter] = useState(term?.startLetter);

  return (
    <ContentModal onClose={closeModal} onClickOutside>
      <Form onSubmit={handleUpdate}>
        <h1>
          {t(
            `views.course.glossaries.form.title.${isNewTerm ? 'create' : 'edit'}`
          )}
        </h1>
        <div style={{ display: 'flex' }}>
          <Input
            size={90}
            name="term"
            label={t('views.course.glossaries.term')}
            defaultValue={term?.term}
            onChange={(e) => setStartLetter(e.target.value[0])}
          />
          <Input
            size={10}
            name="starting_letter"
            label={t('views.course.glossaries.startingLetter')}
            value={startLetter}
            onChange={(e) => setStartLetter(e.target.value)}
          />
        </div>
        <RichEditor
          id="glossary_definition"
          name="definition"
          label={t('views.course.glossaries.definition')}
          defaultValue={term?.definition}
        />
        <ContentModal.Footer>
          <Button type="submit">
            <Save />
            <span>
              {t(
                `views.course.glossaries.form.submit.${isNewTerm ? 'create' : 'edit'}`
              )}
            </span>
          </Button>
          {!isNewTerm && (
            <Button
              type="button"
              error
              onClick={() =>
                openModal(
                  ConfirmationModal,
                  {
                    onClickOutside: true,
                    title: t('views.course.glossaries.areYouSure'),
                    description: t('views.course.glossaries.confirmTitle'),
                    yes: t('views.course.glossaries.sure'),
                    no: t('cancel'),
                    handleConfirm: handleDestroy,
                  },
                  1000
                )
              }
              style={{ marginLeft: 'auto' }}
            >
              <DeleteOutline />
              <span>{t('views.course.glossaries.destroy')}</span>
            </Button>
          )}
        </ContentModal.Footer>
      </Form>
    </ContentModal>
  );
};

export default GlossaryFormModal;
