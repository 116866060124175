import React, { FC, useMemo } from 'react';
import { Callout, Input } from '@brainstud/ui';
import { useForm } from '@brainstud/universal-components';
import { useBlock } from 'Hooks/useBlock';
import { sanitizer } from 'Utils/Sanitizer';
import { toName } from '../../../Utils/toName';
import { IInputBlockProps } from './Types';
import styles from './InputQuestion.module.css';

type Props = Omit<IInputBlockProps, 'type'>;
/**
 * The View component for the Input Question Block.
 */
export const InputQuestion: FC<Props> = ({
  id,
  question,
  equals,
  contains,
  inputFieldsCount = 1,
  feedback,
}) => {
  const name = toName(id);
  const { values } = useForm();
  const fields = useMemo(
    () =>
      Array.from(
        new Array(parseInt(inputFieldsCount as unknown as string, 10)),
        (_val, index) => `${name}_${index}`
      ),
    [inputFieldsCount, name]
  );

  const inputValues = values[name];
  const { isValid, isValidated } = useBlock({
    id,
    fields,
    touched: Array.isArray(inputValues)
      ? inputValues.filter((val) => val).length > 0
      : false,
    open: true,
  });

  const equalsRule = equals?.length
    ? `equals:${typeof equals === 'string' ? equals : equals.join(';')}`
    : null;
  const containsRule = contains?.length
    ? `contains:${contains.join(';').replace(/\s+/g, '')}`
    : null;
  const rules = equalsRule || containsRule || undefined;
  return (
    <div className={styles.base}>
      <p>{question}</p>
      {fields.map((fieldName) => (
        <Input
          key={fieldName}
          id={fieldName}
          name={`${name}[]`}
          rules={rules}
        />
      ))}

      <Callout
        correct={isValid}
        incorrect={!isValid}
        hidden={
          !isValid && !feedback?.incorrect
            ? true
            : !!(isValid && !feedback?.correct) || !isValidated
        }
        className={styles.feedback}
        dangerouslySetInnerHTML={{
          __html: sanitizer(isValid ? feedback?.correct : feedback?.incorrect),
        }}
      />
    </div>
  );
};
