import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AnswerUpload, useApi } from '@brainstud/academy-api';
import { UploadBox } from '@brainstud/ui';
import { useForm, useFormField } from '@brainstud/universal-components';
import classNames from 'classnames/bind';
import { DropzoneFile } from 'dropzone';
import { useBlock } from 'Hooks/useBlock';
import { SuccessResponse } from 'Modules/universal-components';
import { useAnswerProvider } from 'Providers/AnswerProvider';
import { useContentBlocksProvider } from 'Providers/ContentBlocksProvider';
import { useTranslator } from 'Providers/Translator';
import { DateFormatter } from 'Utils/DateFormatHelper';
import { transformDropzoneResponse } from '../../../../academy-api/Utils/tranformDropzoneResponse';
import { AnswerUploadCard } from './AnswerUploadCard';
import { IFileUploadBlock } from './Types';
import styles from './FileUploadQuestion.module.css';

const cx = classNames.bind(styles);

const UPLOAD_TRACKING_DATETIME = '2023-01-23 01:00:00';

/**
 * FileUploadQuestion.
 *
 * Renders a question with an upload box to upload files. Before a file can be uploaded,
 * it requires a concept answer to attach the upload to. Next to that, it needs to track
 * which uploaded file belongs to this specific file upload question.
 */
export const FileUploadQuestion = ({
  id,
  question,
  files,
}: Omit<IFileUploadBlock, 'type'>) => {
  const [t] = useTranslator();
  const { disabled, subscribe, save } = useForm();
  const {
    readonly,
    isLoading,
    states: blockStates,
  } = useContentBlocksProvider();
  const { currentAnswer } = useAnswerProvider();
  const isDisabled = disabled || readonly;

  const { baseUrl } = useApi();
  const [isReset, setIsReset] = useState(false);

  const uploads = useMemo(
    () => (isReset ? [] : currentAnswer?.uploads?.().reverse() || []),
    [currentAnswer, isReset]
  );

  const isSubmitted = useMemo(
    () =>
      blockStates.every((block) => block.touched || block.static) &&
      currentAnswer?.status !== 'CONCEPT' &&
      disabled,
    [blockStates, currentAnswer, disabled]
  );

  /**
   * Manually add reset state to let component know to use empty uploads.
   * This is needed since the answers (and with that the uploads) aren't
   * refetched untill a put (submit or upload) is done. This caused some
   * irregulatities in the user flow when redo-ing an assignment.
   */
  useEffect(() => subscribe('reset', () => setIsReset(true)), [subscribe]);

  const { value: fieldValue, setValue } = useFormField<string>({
    id,
    defaultValue: '',
  });

  const isConcept = currentAnswer?.status === 'CONCEPT';

  const myAnswerUploads = useMemo(
    () =>
      uploads.filter(
        (item) =>
          // Date check to work with answers before block based upload were tracked:
          DateFormatter.isBefore(item.createdAt, UPLOAD_TRACKING_DATETIME) ||
          // old block structure has fields but empty fieldValue:
          !fieldValue ||
          // Actual check:
          fieldValue?.toString().includes(item.id)
      ),
    [uploads, fieldValue]
  );

  useBlock({
    id,
    touched: myAnswerUploads.length > 0,
    open: true,
  });

  const handleEnsureAnswer = useCallback(() => {
    if (!isConcept && !isDisabled) save({ status: 'CONCEPT' });
  }, [isConcept, isDisabled, save]);

  const handleQueueComplete = useCallback(async () => {
    save({ status: 'CONCEPT' });
  }, [save]);

  const handleAfterFileUpload = useCallback(
    (file: DropzoneFile, response: SuccessResponse) => {
      const document = transformDropzoneResponse<AnswerUpload>(response);
      if (document?.data?.id) {
        setValue((prevValue) =>
          `${prevValue || ''},${document.data.id}`.replace(/^,/, '')
        );
        setIsReset(false);
      }
    },
    [setValue]
  );

  return (
    <div className={cx(styles.base)}>
      <p>{question}</p>
      {!disabled && (!files || myAnswerUploads.length < files) && (
        <>
          {(!isSubmitted || readonly) && (
            <UploadBox
              url={`${baseUrl}/v1/answers/${isConcept ? currentAnswer?.id : undefined}/uploads`}
              maxFilesize={99000000}
              maxFiles={files}
              onBeforeUpload={handleEnsureAnswer}
              onAfterUpload={handleQueueComplete}
              onAfterFileUpload={handleAfterFileUpload}
              className={cx({ [styles.disabled]: isDisabled || isLoading })}
            />
          )}
        </>
      )}

      {myAnswerUploads.length !== 0 && (
        <p className={cx(styles.uploaded)}>
          {disabled && t(`course-blocks.blocks.file_upload.delivered`)}
        </p>
      )}

      <div className={cx(styles.uploads)}>
        {myAnswerUploads.map((file) => (
          <AnswerUploadCard
            key={file.id}
            answerId={currentAnswer?.id}
            upload={file}
          />
        ))}
      </div>
    </div>
  );
};
