import { UseQueryOptions } from 'react-query';
import { useShow } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import { SbbDossier } from '../Types';

interface Props {
  query?: string;
}

export function useSbbSearch(
  { query }: Props = {},
  settings?: UseQueryOptions<DataDocument<SbbDossier>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { query: defaultQuery },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(query || defaultQuery),
      baseName: 'v1.certifications.search_sbb',
      baseUri: `/v1/certifications/search_sbb`,
      uri: `?query=${query || defaultQuery}`,
    },
    context
  );
  return useShow<SbbDossier>(request, settings);
}
