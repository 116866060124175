import React, { PropsWithChildren } from 'react';
import { useCourse, useLearningObjectCollection } from '@brainstud/academy-api';
import CourseNavigation from '@brainstud/coach-panel/Layouts/CourseNavigation/CourseNavigation';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import {
  AccessProvider,
  LearningObjectProvider,
  LearningRouteProvider,
  useBreadcrumbs,
  useHeadProvider,
} from 'Providers';
import { ErrorBoundary } from 'Providers/ErrorDataProvider/ErrorBoundary';
import { useDfnTags } from '../../Hooks';
import styles from './CoachCourseLayout.module.css';

const cx = classNames.bind(styles);

type Props = {
  title?: string;
};

/**
 * CourseLayout.
 *
 * Contains the main layout of a Course.
 */
export const CoachCourseLayout = ({
  title,
  children,
}: PropsWithChildren<Props>) => {
  const router = useRouter();
  const { collectionId, courseId } = router.query as {
    collectionId: string;
    courseId: string;
    learningObjectId?: string;
    learningSubjectId?: string;
  };

  const [{ data: course }] = useCourse(
    {
      course: courseId,
      include: ['learning_object_collections'],
    },
    { enabled: false }
  );
  const [{ data: collection }] = useLearningObjectCollection({
    learningObjectCollection: collectionId,
  });
  const currentCollection =
    collection ||
    course
      ?.learningObjectCollections?.()
      .find((item) => item.id === collectionId);

  useHeadProvider(title || 'titles.coach.course.main');

  useBreadcrumbs({
    courses: { href: '/coach/courses' },
  });

  useDfnTags();

  return (
    <main style={{ paddingBottom: '8rem' }}>
      <AccessProvider>
        <LearningRouteProvider>
          <LearningObjectProvider>
            <CourseNavigation />
            <h1>
              <span className={cx(styles.forPrint)}>
                {course?.title}: {currentCollection?.title}
              </span>
            </h1>
            <ErrorBoundary container>{children}</ErrorBoundary>
          </LearningObjectProvider>
        </LearningRouteProvider>
      </AccessProvider>
    </main>
  );
};
