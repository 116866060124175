import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  NotificationPreference,
  NotificationPreferenceCreate,
  NotificationPreferenceFilters,
  NotificationPreferenceIncludes,
  NotificationPreferenceSorts,
  NotificationPreferenceUpdate,
  UUID,
} from '../Types';

interface INotificationPreferencesProps
  extends HookParams<
    typeof NotificationPreferenceIncludes,
    typeof NotificationPreferenceFilters,
    typeof NotificationPreferenceSorts
  > {
  notificationPreference?: UUID;
  account?: UUID;
}

export function useNotificationPreference(
  {
    notificationPreference,
    account,
    ...queryParameters
  }: INotificationPreferencesProps = {},
  settings?: UseQueryOptions<
    DataDocument<NotificationPreference>,
    ErrorDocument
  >
) {
  const context = useApi();
  const {
    parameters: {
      notificationPreference: defaultNotificationPreference,
      account: defaultAccount,
    },
  } = context;
  const request = new ApiRequest(
    {
      enabled:
        !!(notificationPreference || defaultNotificationPreference) &&
        !!(account || defaultAccount),
      baseName: 'v1.accounts.notificationPreferences',
      baseUri: `/v1/accounts/${account || defaultAccount}/notification_preferences`,
      uri: `/${notificationPreference || defaultNotificationPreference}`,
      invalidate: [
        'v1.notificationTypes',
        'notification_preference',
        'notification_preferences',
      ],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    NotificationPreference,
    NotificationPreferenceCreate,
    NotificationPreferenceUpdate
  >(request, settings);
}

export function useNotificationPreferences(
  {
    account,
    ...queryParameters
  }: Omit<INotificationPreferencesProps, 'notificationPreference'> = {},
  settings?: UseQueryOptions<
    DataDocument<NotificationPreference[]>,
    ErrorDocument
  >
) {
  const context = useApi();
  const {
    parameters: { account: defaultAccount },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(account || defaultAccount),
      baseName: 'v1.accounts.notificationPreferences',
      baseUri: `/v1/accounts/${account || defaultAccount}/notification_preferences`,
      invalidate: ['notification_preference', 'notification_preferences'],
      queryParameters,
    },
    context
  );
  return useIndexCR<NotificationPreference, NotificationPreferenceCreate>(
    request,
    settings
  );
}
