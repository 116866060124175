import React from 'react';
import { Status, Td, Tr } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { useAccessProvider } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { DateFormatter } from 'Utils/DateFormatHelper';
import { SampleSetRowType } from '../SampleSetsTable';
import styles from '../row.module.css';

const cx = classNames.bind(styles);

type Props = {
  data: SampleSetRowType;
};

export const SampleSetRow = ({ data }: Props) => {
  const [t] = useTranslator();
  const { hasAccess } = useAccessProvider();

  return (
    <Tr
      key={data.id}
      href={
        hasAccess
          ? `/coach/students/${data.participant?.id}/enrollment/${data.enrollmentId}/exam`
          : undefined
      }
      className={cx(styles.base, { disabled: !hasAccess })}
    >
      <Td>{data.participant?.fullName}</Td>
      <Td>{data.course?.title}</Td>
      <Td>
        <Status scheme="info">
          {t(`review.statusOptions.${data.status}`)}
        </Status>
      </Td>
      <Td>{DateFormatter.toDateTime(data.date)}</Td>
    </Tr>
  );
};
