import React from 'react';
import { Status, Td, Tr } from '@brainstud/ui';
import classNames from 'classnames';
import { Avatar } from 'Components';
import { useAccessProvider } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { DateFormatter } from 'Utils/DateFormatHelper';
import styles from './ReviewWork.module.css';

const cx = classNames.bind(styles);

type Props = {
  studentName?: string;
  courseName?: string;
  status: string;
  timeStamp?: string;
  url: string;
};

export const ReviewWork = ({
  status,
  studentName = '',
  courseName = '',
  timeStamp,
  url,
}: Props) => {
  const [t] = useTranslator();
  const { hasAccess } = useAccessProvider();

  return (
    <Tr
      to={hasAccess ? url : undefined}
      className={cx(styles.base, !hasAccess ? styles.disabled : '')}
    >
      <Td>
        <Avatar outline name={studentName} />
      </Td>
      <Td>
        <h4>{studentName}</h4>
      </Td>
      <Td>
        <span>{courseName}</span>
      </Td>
      <Td>
        <Status scheme="warning">{status}</Status>
      </Td>
      <Td>
        <span>
          {DateFormatter.toRelativeWithinDays(timeStamp) || t('date_unknown')}
        </span>
      </Td>
    </Tr>
  );
};
