import { IResource } from '../Utils/Resource';

export interface StoredFile extends IResource<'stored_files'> {
  originalFileName: string;
  downloadUrl: string;
  title: string;
  description: string;
  fileSize: number;
  state: string;
}

export interface StoredFileCreateInput {
  title?: string;
  description?: string;
}

export interface StoredFileUpdateInput {
  title?: string;
  description?: string;
}

export interface StoredFileDeleteInput {}

export enum StoredFileIncludes {}

export interface StoredFileFilters {}

export enum StoredFileSort {}
