import { IResource } from '../Utils/Resource';
import { ExamMessage } from './ExamContent';

export type ExamContentItem = {
  title: string;
  type: string;
  hasUploadField?: boolean;
  content: string | ExamContentItem[];
  duration?: string;
};

export interface ExamContentVariety extends IResource {
  introduction?: string | null;
  content?: ExamContentItem[];
  messages?: ExamMessage[];
}

export interface ExamContentVarietyCreateInput {
  introduction?: string | null;
  content?: ExamContentItem[];
  messages?: ExamMessage[];
}

export interface ExamContentVarietyUpdateInput
  extends Partial<ExamContentVarietyCreateInput> {}

export enum ExamContentVarietyIncludes {}

export enum ExamContentVarietyFilters {}

export enum ExamContentVarietySorts {}
