import { createContext, Dispatch, SetStateAction } from 'react';
import { DEFAULT_LANGUAGE } from 'Config/constants';
import { ITranslations } from 'Providers/Translator/TranslationsInterface';

export type TLanguages = 'en' | 'nl';

export interface ITranslatorContext {
  /**
   * translate.
   *
   * Receives a dot notation specifying the translation string. When the found translation is a function, it will
   * pass the data to that function, expecting a string in return. If all fails, it uses the fallback string
   * if provided. When fallback is set to 'true', it will simply return the identifier if not found and not complain
   * about missing translations.
   */
  translate: <TOutput = string>(
    input?: string,
    data?: object,
    fallback?: true | null | string,
    original?: boolean
  ) => TOutput;
  /** Set the country notation (eg. nl, en) */
  setLanguage: Dispatch<SetStateAction<TLanguages>>;
  translations: ITranslations;
  language: TLanguages;
}

function defaultTranslator<T = string>(
  input?: string,
  data?: any,
  fallback?: true | string | null
): T {
  return typeof fallback === 'string'
    ? (fallback as unknown as T)
    : (input as unknown as T);
}

export const TranslatorContext = createContext<ITranslatorContext>({
  translate: defaultTranslator,
  setLanguage: () => null,
  translations: {},
  language: DEFAULT_LANGUAGE,
});
