import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  Automation,
  AutomationCreateInput,
  AutomationFilters,
  AutomationIncludes,
  AutomationSorts,
  AutomationUpdateInput,
  HookParams,
  UUID,
} from '../Types';

interface ILearningRouteAutomationProps
  extends HookParams<
    typeof AutomationIncludes,
    AutomationFilters,
    typeof AutomationSorts
  > {
  learningRoute?: UUID;
  automation?: UUID;
}

export function useLearningRouteAutomation(
  {
    learningRoute,
    automation,
    ...queryParameters
  }: ILearningRouteAutomationProps = {},
  settings?: UseQueryOptions<DataDocument<Automation>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: {
      learningRoute: defaultLearningRoute,
      automation: defaultAutomation,
    },
  } = context;

  const request = new ApiRequest(
    {
      enabled:
        !!(learningRoute || defaultLearningRoute) &&
        !!(automation || defaultAutomation),
      baseName: 'v1.learningRoutes.automations',
      baseUri: `/v1/learning_routes/${learningRoute}/automations`,
      invalidate: [
        'learning_routes',
        'learning_route',
        'learning_routes.automations',
        'learning_route.automation',
      ],
      uri: `/${automation || defaultAutomation}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    Automation,
    AutomationCreateInput,
    AutomationUpdateInput
  >(request, settings);
}

export function useLearningRouteAutomations(
  {
    learningRoute,
    ...queryParameters
  }: Omit<ILearningRouteAutomationProps, 'automation'> = {},
  settings?: UseQueryOptions<DataDocument<Automation[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { learningRoute: defaultLearningRoute },
  } = context;

  const request = new ApiRequest(
    {
      enabled: !!(learningRoute || defaultLearningRoute),
      baseName: 'v1.learningRoutes.automations',
      baseUri: `/v1/learning_routes/${learningRoute}/automations`,
      invalidate: [
        'learning_routes',
        'learning_route',
        'learning_routes.automations',
        'learning_route.automation',
      ],
      queryParameters,
    },
    context
  );
  return useIndexCR<Automation, AutomationCreateInput>(request, settings);
}
