import React, { ReactElement, ReactNode, Suspense } from 'react';
import { ApiProvider } from '@brainstud/academy-api';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import { Loading } from 'Components';
import FullScreenLoader from 'Components/Loading/FullScreenLoader';
import { NextPage } from 'next';
import type { AppProps } from 'next/app';
import PlausibleProvider from 'next-plausible';
import { TranslatorProvider } from 'Providers/Translator';
import { DebugPanel } from '../Components/DebugPanel';
import { Base } from '../Layouts';
import {
  AccessProvider,
  BreadcrumbsProvider,
  EnvironmentProvider,
  ErrorDataProvider,
  HeadProvider,
  ModalProvider,
  ModalSwitch,
  OnboardingProvider,
  SystemEventProvider,
  ThemeProvider,
  ToasterProvider,
  ViewSettingsProvider,
} from '../Providers';
import { ErrorBoundary } from '../Providers/ErrorDataProvider/ErrorBoundary';
import '../styles/styles.css';

type TPossiblePageProps = Partial<{
  dark: boolean;
  headless: boolean;
}>;

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps<TPossiblePageProps> & {
  Component: NextPageWithLayout<TPossiblePageProps>;
};

// Why did you render, development performance tracking
if (
  typeof window !== 'undefined' &&
  process.env.NODE_ENV === 'development' &&
  process.env.NEXT_PUBLIC_DID_IT_RENDER === 'true'
) {
  // eslint-disable-next-line no-console
  console.debug(
    'Applying whyDidYouRender, to help you locate unnecessary re-renders during development. See https://github.com/welldone-software/why-did-you-render'
  );
  // See https://github.com/welldone-software/why-did-you-render#options
  whyDidYouRender(React, {
    include: [],
    trackAllPureComponents: true,
    trackHooks: true,
    logOnDifferentValues: true,
    logOwnerReasons: true,
    collapseGroups: true,
    titleColor: 'lightblue',
    diffNameColor: 'orange',
    diffPathColor: 'green',
  });
}

export default function App({ Component, pageProps = {} }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <SystemEventProvider>
      <ApiProvider>
        <Suspense
          fallback={
            <FullScreenLoader>
              <Loading />
            </FullScreenLoader>
          }
        >
          <ErrorBoundary container>
            <TranslatorProvider>
              <ThemeProvider>
                <PlausibleProvider
                  domain={process.env.NEXT_PUBLIC_PLAUSIBLE_DOMAIN ?? ''}
                  taggedEvents
                  customDomain={process.env.NEXT_PUBLIC_PLAUSIBLE_HOST}
                  selfHosted
                  // Discard production to test Plausible on staging and in review apps.
                  // Localhost is discarded by plausible itself.
                  enabled={['staging', 'review'].includes(
                    process.env.NEXT_PUBLIC_APP_ENV || ''
                  )}
                >
                  <ViewSettingsProvider>
                    <ModalProvider>
                      <HeadProvider>
                        <EnvironmentProvider>
                          <BreadcrumbsProvider>
                            <ToasterProvider>
                              <OnboardingProvider>
                                <AccessProvider>
                                  <ErrorDataProvider>
                                    <ErrorBoundary container>
                                      <Base
                                        dark={pageProps.dark}
                                        headless={pageProps.headless}
                                      >
                                        {getLayout(
                                          // eslint-disable-next-line react/jsx-props-no-spreading
                                          <Component {...pageProps} />
                                        )}
                                        <ModalSwitch />
                                        {process.env.NEXT_PUBLIC_APP_ENV !==
                                          'production' && <DebugPanel />}
                                      </Base>
                                    </ErrorBoundary>
                                  </ErrorDataProvider>
                                </AccessProvider>
                              </OnboardingProvider>
                            </ToasterProvider>
                          </BreadcrumbsProvider>
                        </EnvironmentProvider>
                      </HeadProvider>
                    </ModalProvider>
                  </ViewSettingsProvider>
                </PlausibleProvider>
              </ThemeProvider>
            </TranslatorProvider>
          </ErrorBoundary>
        </Suspense>
      </ApiProvider>
    </SystemEventProvider>
  );
}
