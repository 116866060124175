import React from 'react';
import { Button, Toggle } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';
import { useTranslator } from 'Providers/Translator';
import { useSubjectEditor } from './Provider';
import styles from './SubjectEditBar.module.css';

const cx = classNames.bind(styles);

type Props = {
  coach?: boolean;
};

/**
 * A bar for editing the details of a learning subject
 */
export const SubjectEditBar = ({ coach = false }: Props) => {
  const {
    isLoading,
    learningSubject,
    nestedItems,
    handleSave,
    preview,
    setPreview,
  } = useSubjectEditor();
  const router = useRouter();

  const learningObjects = learningSubject?.learningObjects?.() || [];
  const [t] = useTranslator();
  return (
    <div className={cx(styles.base, { coach })}>
      <div className={cx(styles.inner, { coach })}>
        {!coach && (
          <div className={cx('option')}>
            <Toggle
              name="learningsubject_editor__preview"
              className={cx(styles.preview, styles.wrap)}
              checked={preview}
              onChange={() => setPreview((prevPreview) => !prevPreview)}
            >
              {t('course-editor.learning_route_editor.preview')}
            </Toggle>
          </div>
        )}

        <div className={cx(styles.buttonGroup)}>
          <Button
            type="button"
            success
            onClick={handleSave}
            disabled={learningObjects === nestedItems}
            loading={isLoading}
            className={cx(styles.button)}
          >
            <span>{t('course-editor.learning_route_editor.save')}</span>
          </Button>
          <Button
            type="button"
            onClick={() => router.back()}
            className={cx(styles.button)}
          >
            {t('course-editor.learning_route_editor.close')}
          </Button>
        </div>
      </div>
    </div>
  );
};
