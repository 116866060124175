import React from 'react';
import classNames from 'classnames/bind';
import { useBlock } from 'Hooks/useBlock';
import { sanitizer } from 'Utils/Sanitizer';
import styles from './HTMLCodeBlock.module.css';

const cx = classNames.bind(styles);

type Props = {
  id?: string;
  content: string;
};
/**
 * With this block your are able to add custom HTML e.g. iframe, table, img to a learningObject.
 */
export const HTMLCodeBlock = ({ id, content }: Props) => {
  useBlock({ id });
  return (
    <div
      className={cx(styles.base)}
      dangerouslySetInnerHTML={{ __html: sanitizer(content, 'permissive') }}
    />
  );
};
