import React, { useCallback, useState } from 'react';
import { Button, SidebarModal, Status } from '@brainstud/ui';
import { Edit } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Tabs, TabsItem, TileSelector } from 'Components';
import {
  LearningObjectProvider,
  useLearningObjectProvider,
  useModals,
} from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { AssignmentIcon, TheoryIcon } from '../../ContentSelectionModal';
import { AssignmentCoachRemark } from './AssignmentCoachRemark';
import { AssignmentDetails } from './AssignmentDetails';
import { AssignmentQualification } from './AssignmentQualification';
import { TheoryDetails } from './TheoryDetails';
import styles from './AssignmentModal.module.css';

const cx = classNames.bind(styles);

export type TAssignmentModalData = {
  isQuiz: boolean;
  routeLayout?: string;
  isTheory?: boolean;
  learningRouteId?: string;
  nodeId?: string;
  collectionId?: string;
  subjectId?: string;
};

const AssignmentModalInner = () => {
  const [t] = useTranslator();
  const { closeModal, modalData = {} } = useModals();
  const { isQuiz } = modalData as TAssignmentModalData;
  const { learningObject } = useLearningObjectProvider();
  const learningObjectId = learningObject?.id;
  const [isTheory, setIsTheory] = useState<boolean>(modalData.isTheory);
  const [active, setActive] = useState<string>(
    modalData?.isTheory ? 'theory-details' : 'assignment-details'
  );
  const [isEditingType, setIsEditingType] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleTypeChange = useCallback((baseType: string) => {
    const isTheoryType = baseType === 'theory';
    setIsTheory(isTheoryType);
    setIsEditingType(false);
    setActive(isTheoryType ? 'theory-details' : 'assignment-details');
  }, []);

  return (
    <SidebarModal
      onClose={closeModal}
      onClickOutside
      size="medium"
      className={cx(styles.base)}
    >
      <SidebarModal.Header className={cx(styles.modalHeader)}>
        <div className={cx(styles.headerTitle)}>
          <h3>
            {t(
              `views.courses.collection_edit.assignment_modal.title.${!learningObjectId ? 'create' : 'edit'}`
            )}
          </h3>
          <Button type="submit" form={active} loading={isLoading}>
            {t('modals.submit.save')}
          </Button>
        </div>
        <div className={cx(styles.editBaseType)}>
          <Button
            link
            onClick={() => setIsEditingType((prevState) => !prevState)}
          >
            <Status scheme="info">
              <span>
                {t(
                  `views.courses.collection_edit.content_selection_modal.${isTheory ? 'theory' : 'assignment'}`
                )}
              </span>
              <Edit />
            </Status>
          </Button>
        </div>
        {!isEditingType && (
          <div className={cx(styles.tabsContainer)}>
            <Tabs className={cx(styles.tabs)} border>
              <TabsItem
                active={['assignment-details', 'theory-details'].includes(
                  active
                )}
                onClick={() =>
                  setActive(isTheory ? 'theory-details' : 'assignment-details')
                }
              >
                {t(
                  'views.courses.collection_edit.assignment_modal.tabs.details'
                )}
              </TabsItem>
              {learningObjectId && (
                <>
                  <TabsItem
                    active={active === 'assignment-qualifications'}
                    onClick={() => setActive('assignment-qualifications')}
                  >
                    {t(
                      'views.courses.collection_edit.assignment_modal.tabs.qualifications'
                    )}
                  </TabsItem>
                  <TabsItem
                    active={active === 'assignment-coach-remark'}
                    onClick={() => setActive('assignment-coach-remark')}
                  >
                    {t(
                      'views.courses.collection_edit.assignment_modal.tabs.remarks'
                    )}
                  </TabsItem>
                </>
              )}
            </Tabs>
          </div>
        )}
      </SidebarModal.Header>
      {!isEditingType ? (
        <div className={cx(styles.sidebarContent, { quiz: isQuiz })}>
          {(() => {
            switch (active) {
              case 'assignment-details':
              case 'theory-details':
                return isTheory ? (
                  <TheoryDetails setIsLoading={setIsLoading} />
                ) : (
                  <AssignmentDetails setIsLoading={setIsLoading} />
                );
              case 'assignment-qualifications':
                return <AssignmentQualification setIsLoading={setIsLoading} />;
              case 'assignment-coach-remark':
                return <AssignmentCoachRemark setIsLoading={setIsLoading} />;
              default:
                return null;
            }
          })()}
        </div>
      ) : (
        <div className={cx(styles.tiles)}>
          <TileSelector
            name="type"
            className={cx(styles.tile)}
            value="assignment"
            onChange={(event) => handleTypeChange(event.target.value)}
            selected={!isTheory}
            quiet
          >
            <div className={cx(styles.tileContent)}>
              <AssignmentIcon />
              <strong>
                {t(
                  'views.courses.collection_edit.content_selection_modal.assignment'
                )}
              </strong>
            </div>
          </TileSelector>
          <TileSelector
            name="type"
            className={cx(styles.tile)}
            value="theory"
            onChange={(event) => handleTypeChange(event.target.value)}
            selected={isTheory}
            quiet
          >
            <div className={cx(styles.tileContent)}>
              <TheoryIcon />
              <strong>
                {t(
                  'views.courses.collection_edit.content_selection_modal.theory'
                )}
              </strong>
            </div>
          </TileSelector>
        </div>
      )}
    </SidebarModal>
  );
};

export const AssignmentModal = () => (
  <LearningObjectProvider>
    <AssignmentModalInner />
  </LearningObjectProvider>
);
