import React from 'react';
import classNames from 'classnames';
import { useProgressCalculator } from 'Hooks';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ILearningSubjectWithResources } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { HIDDEN_LAYOUT_NAME } from '../../Layouts';
import { ObjectMetaBadge } from '../ObjectRow/ObjectMetaBadge';
import { ObjectRow } from '../ObjectRow/ObjectRow';
import styles from './SubjectRow.module.css';

const cx = classNames.bind(styles);

type TProps = {
  subject: ILearningSubjectWithResources;
  indent?: number;
};

export const SubjectRow = ({ subject, indent = 0 }: TProps) => {
  const router = useRouter();
  const [t, { ucf }] = useTranslator();
  const { xpAvailable } = useProgressCalculator(subject);

  const hasPage = subject.layout !== HIDDEN_LAYOUT_NAME;
  const LinkWrap = hasPage ? Link : 'div';

  return (
    <div className={cx(styles.base)}>
      <LinkWrap
        href={hasPage ? `${router.asPath}/subjects/${subject.id}` : undefined!}
        className={cx(styles.row, { [styles.link]: hasPage })}
      >
        <h3 className={cx(styles.title, styles[`indent-${indent}`])}>
          {ucf(subject.title)}
        </h3>
        <ObjectMetaBadge
          type="xp"
          value={xpAvailable}
          label={`${t('total')}: `}
        />
      </LinkWrap>
      {subject.scheme.map((item) =>
        item.resourceType === 'learning_objects' ? (
          <ObjectRow key={item.id} indent={indent + 1} object={item} />
        ) : (
          <SubjectRow key={item.id} indent={indent + 1} subject={item} />
        )
      )}
    </div>
  );
};
