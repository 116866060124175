export type TRoles =
  | 'unauthenticated'
  | 'guest'
  | 'student'
  | 'coach'
  | 'admin'
  | 'author'
  | 'support'
  | 'academy author'
  | 'academy support'
  | 'academy manager'
  | 'academy sales'
  | 'academy admin'
  | 'academy marketing'
  | 'financial controller';

export const COACH_ROLES: TRoles[] = ['coach'];

export const STUDENT_ROLES: TRoles[] = ['student'];

/**
 * UNLIMITED_ROLES.
 *
 * Users with these roles can work on any academy with this role.
 */
export const UNLIMITED_ROLES: TRoles[] = [
  'admin',
  'support',
  'author',
  'financial controller',
];

/**
 * Manager Roles.
 *
 * Can work within a specific academy.
 */
export const ACADEMY_ROLES: TRoles[] = [
  'academy author',
  'academy support',
  'academy admin',
  'academy manager',
  'academy sales',
  'academy marketing',
];

export const SUPPORT_ROLES: TRoles[] = ['support', 'academy support'];

export const AUTHOR_ROLES: TRoles[] = ['author', 'academy author'];

export const SALES_ROLES: TRoles[] = ['academy sales', 'academy marketing'];

export const FINANCE_ROLES: TRoles[] = ['financial controller'];

export const ADMIN_ROLES: TRoles[] = ['admin', 'academy admin'];

export const MANAGER_ROLES: TRoles[] = ['academy manager'];

/**
 * Admin Roles.
 *
 * Users with an ADMIN type role can work on either a specific academy or any academy. This is
 * a combination of manager roles and unlimited roles.
 */
export const ADMIN_ENV_ROLES: TRoles[] = [...UNLIMITED_ROLES, ...ACADEMY_ROLES];
