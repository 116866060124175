import { DateTimeString } from '../Utils/DateTimeString';
import { IResource } from '../Utils/Resource';
import { Exam } from './Exam';

export type ExamAssessmentGradeTypes = 'TEXT' | 'GRADE' | 'NUMBER';
export type ExamAssessmentGradeResult = 'O' | 'V' | 'G' | number;

export interface ExamAssessment extends IResource<'exam_assessments'> {
  gradeType: ExamAssessmentGradeTypes;
  gradeResult: ExamAssessmentGradeResult;
  feedback: string;
  passed: boolean;
  date: DateTimeString;
  proofOfParticipationUrl: string;
  exam?: () => Exam;
}

export interface ExamAssessmentCreateInput {
  grade_type: ExamAssessmentGradeTypes;
  grade_result: ExamAssessmentGradeResult;
  feedback: string;
}

export interface ExamAssessmentUpdateInput
  extends Partial<ExamAssessmentCreateInput> {}

export enum ExamAssessmentIncludes {
  exam,
}

export enum ExamAssessmentSorts {}

export enum ExamAssessmentFilters {}
