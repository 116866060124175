import React, { useCallback } from 'react';
import { LearningObject } from '@brainstud/academy-api';
import { useLearningRouteEditorProvider } from '@brainstud/course-editor/LearningRouteEditor/Provider';
import { Td, Tr } from '@brainstud/ui';
import { Lock } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { ObjectCardIconTags } from 'Components/Cards/ObjectCard/IconTags';
import { COACH_ROLES } from 'Config/roles';
import {
  filterObjectBadges,
  filterObjectTags,
  useHasAnyRole,
  useObjectTags,
} from 'Hooks';
import { ConditionsModal } from 'Modals';
import { CardTag } from 'Modules/action-based-learning-panel/Components';
import { ObjectStatus } from 'Modules/action-based-learning-panel/Components/ObjectStatus/ObjectStatus';
import { useRouter } from 'next/router';
import {
  useEnvironmentProvider,
  useLearningRouteProvider,
  useModals,
} from 'Providers';
import styles from './LearningObjectRow.module.css';

const cx = classNames.bind(styles);

type Props = {
  object: LearningObject;
  indent?: number;
  locked?: boolean;
};

export const LearningObjectRow = ({ object, indent = 0, locked }: Props) => {
  const { isCoachEnvironment, isAdminEnvironment, isStudentEnvironment } =
    useEnvironmentProvider();
  const { learningObjectCollection } = useLearningRouteProvider();
  const { openModal } = useModals();
  const router = useRouter();
  const { courseId } = router.query as { courseId: string };

  const { preview } = useLearningRouteEditorProvider(true) || {
    preview: false,
  };
  const isCoach = useHasAnyRole(COACH_ROLES);
  const isEditPreview = router.pathname.includes('/edit');
  const redirectUrl =
    locked && !isCoach ? undefined : `/courses/${courseId}/detail/${object.id}`;

  // FixMe this needs to be more specified, since it will also apply to nfc || include NFC check
  const hasUnCompletedConditions =
    !!object.conditions && (isCoach || object.meta?.isLocked);
  const handleRowClick = useCallback(() => {
    if (isCoachEnvironment || isAdminEnvironment) {
      router.push(
        `/${isCoachEnvironment ? 'coach' : 'admin'}/courses/${courseId}/collections/${learningObjectCollection?.id}/content/detail/${object.id}`
      );
    } else {
      return !hasUnCompletedConditions
        ? locked && !isCoach
          ? undefined
          : router.push(`/courses/${courseId}/detail/${object.id}`)
        : openModal(ConditionsModal, {
            object,
            redirectUrl: isCoach ? redirectUrl : undefined,
          });
    }

    // eslint-disable-next-line max-len
  }, [
    courseId,
    hasUnCompletedConditions,
    isAdminEnvironment,
    isCoach,
    isCoachEnvironment,
    learningObjectCollection?.id,
    locked,
    object,
    openModal,
    redirectUrl,
    router,
  ]);

  const tags = useObjectTags(
    object.resourceType === 'learning_objects' ? object : undefined
  );
  const objectCardTags = tags.filter(filterObjectTags);
  const objectCardBadges = tags.filter(filterObjectBadges);

  return (
    <Tr
      className={cx(styles.base)}
      onClick={!isEditPreview ? handleRowClick : undefined}
      indent={indent}
    >
      <Td onClick={!isEditPreview ? handleRowClick : undefined}>
        {object.title}
      </Td>
      {isStudentEnvironment || preview ? (
        <>
          <Td right>
            {objectCardTags
              .filter((tag) => tag.name === 'xp')
              .map((tag) => (
                <CardTag key={tag.name} type={tag.name} value={tag.value} />
              ))}
          </Td>
          <Td className={styles.status}>
            <div className={styles.tags}>
              <ObjectStatus item={object} />
            </div>
          </Td>
          <Td>
            <div className={styles.tags}>
              {hasUnCompletedConditions && (
                <span className={styles.lock}>
                  <Lock />
                </span>
              )}
              {objectCardTags
                .filter((tag) => tag.name !== 'xp')
                .map((tag) => (
                  <CardTag key={tag.name} type={tag.name} value={tag.value} />
                ))}
              <div className={styles.icons}>
                <ObjectCardIconTags
                  tags={objectCardBadges}
                  openOnHover={false}
                />
              </div>
            </div>
          </Td>
        </>
      ) : (
        <Td right className={cx(styles.right)}>
          {object.varieties?.()[0].points
            ? `${object.varieties?.()[0]?.points}XP`
            : ''}
        </Td>
      )}
    </Tr>
  );
};
