import { Course } from '@brainstud/academy-api';

/**
 * getCourseThumbnail.
 *
 * Safely retrieves the thumbnail from a Course resource with a fallback image returned when no
 * image is defined.
 */
export const getCourseThumbnail = (course: Course) => {
  const hasThumbnail =
    (course?.metadata?.media?.thumbnailUrl || course?.thumbnailUrl) &&
    (course?.metadata?.media?.thumbnailUrl?.search(
      /(.jpg|.png|.jpeg|.webp)/
    ) !== -1 ||
      course?.thumbnailUrl?.search(/(.jpg|.png|.jpeg|.webp)/) !== -1);
  const defaultThumbnail = '/media/abstract-thumbnail.jpg';

  return hasThumbnail
    ? course?.metadata?.media?.thumbnailUrl || course?.thumbnailUrl
    : defaultThumbnail;
};
