import React from 'react';
import { Course, Enrollment } from '@brainstud/academy-api';
import { Button } from '@brainstud/ui';
import { CheckCircle } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Container } from 'Components';
import { useGetEnrollmentFeedback } from 'Hooks';
import { CourseFeedbackModal } from 'Modals';
import { useEnvironmentProvider, useModals } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { ReactComponent as PartyPopper } from 'public/media/icons/party_popper.svg';
import styles from './CourseCompletionBanner.module.css';

const cx = classNames.bind(styles);

type Props = {
  viewRating?: () => void;
  course?: Course;
  enrollment?: Enrollment;
};
export const CourseCompletionBanner = ({
  viewRating,
  course,
  enrollment,
}: Props) => {
  const [t] = useTranslator();
  const { isStudentEnvironment } = useEnvironmentProvider();
  const { openModal } = useModals();

  const { hasPassed } = useGetEnrollmentFeedback(enrollment);

  return (
    <div className={cx(styles.base)}>
      <Container className={cx(styles.wrapper)}>
        <div className={cx(styles.content)}>
          {!isStudentEnvironment ? (
            <>
              <CheckCircle fontSize="large" />
              <strong>{t('navigation.student.completed_course')}</strong>
            </>
          ) : (
            <>
              {hasPassed && <PartyPopper />}
              <strong>
                {hasPassed && t('modals.course_feedback.success')}
                {t('navigation.courses.course_completion')}
              </strong>
            </>
          )}
        </div>
        <div className={cx(styles.right)}>
          <Button
            quiet
            small
            type="button"
            onClick={
              !isStudentEnvironment
                ? viewRating
                : () =>
                    openModal(CourseFeedbackModal, {
                      enrollmentId: enrollment?.id,
                      course,
                    })
            }
            className={cx(styles.button)}
          >
            {t('navigation.student.view_rating')}
          </Button>
        </div>
      </Container>
    </div>
  );
};
