import { useContext } from 'react';
import CourseListContext from './CourseListContext';

export const useCourseList = () => {
  const context = useContext(CourseListContext);

  if (!context) {
    throw new Error(
      'You cannot use the CourseList without initializing the Provider first!'
    );
  }

  return context;
};
