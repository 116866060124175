import React from 'react';
import classNames from 'classnames/bind';
import { BlockRenderer } from '../../../Components/BlockRenderer/BlockRenderer';
import { ITabContainerBlock } from './Types';
import styles from './TabContainer.module.css';

const classes = classNames.bind(styles);

interface TabContainerProps
  extends Omit<ITabContainerBlock, 'title' | 'type' | 'id'> {
  /** Indicate the tabcontent is in a vertical tab container. This adds the vertical class to the element. */
  vertical?: boolean;
}

/**
 * The TabContainer contains all information for 1 tab. It can contain other content blocks and should always
 * be used as a direct child of the TabbarContainer.
 */
export const TabContainer = ({
  blocks = [],
  className,
  style,
  vertical = false,
}: TabContainerProps) => (
  <div
    role="tabpanel"
    aria-hidden={false}
    className={classes(styles.base, 'blocks-tabcontainer__base', className, {
      vertical,
      horizontal: !vertical,
    })}
    style={style}
  >
    {blocks.map((block) => (
      <BlockRenderer key={block.id} id={block.id} block={block} />
    ))}
  </div>
);
