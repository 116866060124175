import { IResource } from '../Utils/Resource';

export interface GlossaryTerm extends IResource<'glossary_terms'> {
  term: string;
  definition: string;
  startLetter: string;
}

export interface GlossaryTermCreateInput {
  term: string;
  definition: string;
}

export interface GlossaryTermUpdateInput extends GlossaryTermCreateInput {}

export enum GlossaryTermIncludes {}

export enum GlossaryTermFilters {
  startLtter,
  term,
  definition,
}

export enum GlossaryTermSorts {
  startLetter,
  term,
}
