import React, { PropsWithChildren, useMemo } from 'react';
import classNames from 'classnames/bind';
import { ToggleProps } from './ToggleProps';
import styles from './Toggle.module.css';

const cx = classNames.bind(styles);

/**
 * Shows a toggle button which alternates between true and false states and performs an action or a series of actions accordingly.
 */
export const Toggle = React.forwardRef<
  HTMLInputElement,
  PropsWithChildren<ToggleProps>
>(
  (
    {
      checked,
      defaultChecked,
      disabled,
      onChange,
      children,
      name,
      id,
      onFocus,
      onBlur,
      onSubmit,
      onClick,
      onReset,
      className,
      style,
      onInput,
      onInvalid,
      onMouseOver,
      onBeforeInput,
    },
    ref
  ) => {
    const ensuredName = useMemo(
      () => name || `switch_${Math.random()}`,
      [name]
    );
    return (
      <div
        className={cx(styles.base, className, 'ui-toggle__container')}
        style={style}
      >
        {children && (
          <label
            className={cx(styles.label, 'ui-toggle__label')}
            htmlFor={id || ensuredName}
          >
            {children}
          </label>
        )}
        <div className={cx(styles.toggle, 'ui-toggle__switch')}>
          <input
            ref={ref}
            id={id || ensuredName}
            name={ensuredName}
            type="checkbox"
            className={cx(styles.input, 'ui-toggle__input')}
            checked={checked}
            defaultChecked={defaultChecked}
            disabled={disabled}
            onInput={onInput}
            onInvalid={onInvalid}
            onMouseOver={onMouseOver}
            onBeforeInput={onBeforeInput}
            onFocus={onFocus}
            onBlur={onBlur}
            onSubmit={onSubmit}
            onClick={onClick}
            onReset={onReset}
            onChange={onChange}
          />
          <div className={cx(styles.knob, 'ui-toggle__knob')} />
        </div>
      </div>
    );
  }
);
