import { IResource } from '../Utils/Resource';
import { UUID } from '../Utils/UUID';
import { CatalogProduct } from './CatalogProduct';

export interface Plan extends IResource<'plans'> {
  name: string;
  products?: () => CatalogProduct[];
}

export interface PlanCreateInput {
  name: string;
  relationships?: {
    products?: UUID[];
    subscription_option: UUID;
  };
}

export type PlanUpdateInput = Omit<PlanCreateInput, 'name' | 'catalogId'>;

export enum PlanIncludes {}
export enum PlanFilters {
  name,
}
export enum PlanSort {}
