type ExtendedObject<P extends string, T> = {
  [prop in P]: T;
};

export function hasProp<
  T,
  O extends object = object,
  P extends string = string,
>(prop: P, object: O, type?: string): object is O & ExtendedObject<P, T> {
  return prop in object && (!type || typeof object[prop as string] === type);
}

const DEFAULT_FORM_ERROR = 'Something went wrong submitting the form.';

/**
 * getErrorFromResponse.
 *
 * Tries to get a HTML formatted string from a response that probably contains an error message. Should not
 * be used for 422 errors (invalid input).
 */
export function getErrorFromResponse(response: unknown): string {
  if (typeof response === 'object' && response !== null) {
    if (hasProp<string>('detail', response, 'string')) {
      return response.detail;
    }

    if (hasProp<object>('data', response, 'object')) {
      if (hasProp<string>('message', response.data)) {
        return response.data.message;
      }
    }
    if (hasProp<unknown>('data', response) && Array.isArray(response.data)) {
      return DEFAULT_FORM_ERROR;
    }

    if (hasProp<string | object>('error', response)) {
      return typeof response.error === 'string'
        ? response.error
        : (hasProp<string>('message', response.error) &&
            response.error.message) ||
            (hasProp<string>('detail', response.error) &&
              response.error.detail) ||
            DEFAULT_FORM_ERROR;
    }

    if (hasProp<Array<unknown>>('errors', response)) {
      return response.errors.length > 1
        ? response.errors.map(getErrorFromResponse).join('<br />')
        : getErrorFromResponse(response.errors[0]);
    }
  }
  return DEFAULT_FORM_ERROR;
}
