import { UseQueryOptions } from 'react-query';
import { useIndex } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  FaqCategory,
  FaqCategoryFilters,
  FaqCategoryIncludes,
  FaqCategorySort,
  HookParams,
  UUID,
} from '../Types';

interface Props
  extends HookParams<
    typeof FaqCategoryIncludes,
    typeof FaqCategoryFilters,
    typeof FaqCategorySort
  > {
  portal?: UUID;
}

export function useFaqCategories(
  { portal, ...queryParameters }: Props = {},
  settings?: UseQueryOptions<DataDocument<FaqCategory[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { portal: defaultPortal },
  } = context;
  const request = new ApiRequest(
    {
      baseName: 'v1.portals.faqCategories',
      baseUri: `/v1/portals/${portal || defaultPortal}/faq_categories`,
      invalidate: ['faq_categories', 'faq_category'],
      queryParameters,
    },
    context
  );
  return useIndex<FaqCategory>(request, settings);
}
