import React from 'react';
import classNames from 'classnames/bind';
import { Container } from 'Components';
import { useTranslator } from 'Providers/Translator';
import { useAnswerReview } from '../../Providers';
import styles from './ExternalRatingHeader.module.css';

const cx = classNames.bind(styles);

/*
 * This is the custom header component used for external rating
 */
export const ExternalRatingHeader = () => {
  const [t] = useTranslator();
  const { account, learningObject } = useAnswerReview() || {};

  const courseTitle = learningObject?.course?.().title;

  return (
    <div className={cx(styles.base)}>
      <Container className={styles.inner}>
        <div>
          <span>{t('external_rating.header.title')}</span>
          <h2>{learningObject?.title}</h2>
        </div>
        <div className={cx(styles.info)}>
          <div className={cx(styles.item)}>
            <strong>{t('external_rating.header.participant')}</strong>
            <span>{account?.fullName}</span>
          </div>
          {courseTitle && (
            <div className={cx(styles.item)}>
              <strong>{t('external_rating.header.course')}</strong>
              <span>{courseTitle}</span>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};
