import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ErrorDocument,
  NfcTag,
  ObjectCondition,
  useApi,
  useLearningObject,
  useLearningObjectVariety,
  useObjectCondition,
} from '@brainstud/academy-api';
import { Input, UploadBox } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { Form } from 'Modules/universal-components';
import { useRouter } from 'next/router';
import { useLearningObjectProvider, useModals, useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import { LayoutSelector } from 'Views/Courses/CollectionEditView/LayoutSelector';
import { FormData } from 'Views/Courses/CollectionEditView/LearningRouteEditorView/ContentModals/LearningObjectFormTypes';
import { NfcForm } from 'Views/Courses/CollectionEditView/LearningRouteEditorView/ContentModals/NfcForm';
import { PreviewLearningObject } from 'Views/Courses/CollectionEditView/LearningRouteEditorView/ContentModals/Previews';
import { TAssignmentModalData } from '../AssignmentModal';
import styles from './TheoryDetails.module.css';

const cx = classNames.bind(styles);

export type Props = {
  setIsLoading: (value: boolean) => void;
};

export const TheoryDetails = ({ setIsLoading }: Props) => {
  const [t] = useTranslator();
  const router = useRouter();
  const apiConfig = useApi();
  const [setToast] = useToaster();
  const { learningObject, variety } = useLearningObjectProvider();
  const { modalData } = useModals();
  const { routeLayout, learningRouteId, nodeId, subjectId, isQuiz } =
    modalData as TAssignmentModalData;

  const { collectionId } = router.query as {
    courseId: string;
    collectionId: string;
  };
  const [objectLayout, setObjectLayout] = useState('open');
  const [points, setPoints] = useState(variety?.points || 0);

  const titleInputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (titleInputRef.current) {
      titleInputRef.current.focus();
    }
  }, []);

  // LearningObject
  const [learningObjectTitle, setLearningObjectTitle] = useState(
    learningObject?.title
  );
  const [fileUrl, setFileUrl] = useState(
    learningObject?.metadata?.media?.posterUrl
  );

  const [{ createOrUpdate: createOrUpdateLearningObject }] = useLearningObject(
    {
      learningObject: learningObject?.id,
    },
    { enabled: false }
  );

  useEffect(() => {
    setIsLoading(createOrUpdateLearningObject.isLoading);
  }, [createOrUpdateLearningObject.isLoading, setIsLoading]);

  useEffect(() => {
    if (learningObject?.layout) {
      setObjectLayout(learningObject.layout);
    }
  }, []);

  const nfcTags = useMemo(
    () => learningObject?.nfcTags?.() || [],
    [learningObject]
  );
  const [createdNfcTag, setCreatedNfcTag] = useState<undefined | NfcTag>();
  // Select first nfcTag FIXME add multi nfc tag support
  const nfcTag = useMemo(
    () => createdNfcTag || nfcTags[0],
    [createdNfcTag, nfcTags]
  );

  const [nfcTagEnabled, setNfcTagEnabled] = useState<boolean>(false);

  useEffect(() => {
    if (
      learningObject
        ?.conditions?.()
        .find(
          (condition: ObjectCondition) => condition.type === 'NFC_TAG_SCANNED'
        )
    ) {
      setNfcTagEnabled(true);
    }
  }, [learningObject]);

  const { closeModal } = useModals();
  const objectConditionId =
    learningObject?.resourceType === 'learning_objects' &&
    learningObject
      ?.conditions?.()
      .find((condition) => condition.resourceType !== 'object_condition')?.id;

  const [{ createOrUpdate: createOrUpdateNfcCondition }] = useObjectCondition({
    objectType: 'learning_objects',
    objectIdentifier: learningObject?.id,
    ...(objectConditionId && { condition: objectConditionId }),
  });
  // Create or update NFC condition
  const handleNfcCondition = useCallback(
    async () =>
      createOrUpdateNfcCondition.mutateAsync({
        target: nfcTag?.id,
        learning_route: learningRouteId,
        type: 'NFC_TAG_SCANNED',
      }),
    [createOrUpdateNfcCondition, learningRouteId, nfcTag?.id]
  );

  const [{ update: updateLearningObjectVariety }] = useLearningObjectVariety(
    {
      variety: variety?.id,
      learningObject: learningObject?.id,
    },
    { enabled: false }
  );

  // Create or update a LearningObject
  const handleSubmitLearningObject = useCallback(
    async (formData: Partial<FormData>) => {
      const hasRelationships =
        ((!!nodeId || !!subjectId) && !!collectionId) || !!nfcTag?.id;
      // @ts-ignore FIXME relationships are not assignable to type
      await createOrUpdateLearningObject.mutateAsync(
        {
          base_type: 'theory',
          title: formData.title,
          ...(!learningObject
            ? {
                status: 'CONCEPT',
              }
            : {
                method: '_patch',
                status: learningObject.status,
              }),
          layout: isQuiz ? 'question' : objectLayout, // TODO this should be fixed in Layout.ts
          points,
          metadata: {
            media: {
              poster_url: fileUrl,
            },
          },
          ...(hasRelationships
            ? {
                relationships: {
                  learning_object_collection: collectionId,
                  learning_route_node: nodeId,
                  learning_subject: subjectId,
                  ...(nfcTagEnabled && nfcTag?.id
                    ? {
                        nfc_tags: [nfcTag.id],
                      }
                    : {}),
                },
              }
            : {}),
        },
        {
          onSuccess: () => {
            if (nfcTagEnabled && nfcTag?.id) {
              handleNfcCondition();
            }
            if (
              !(
                updateLearningObjectVariety.isLoading &&
                createOrUpdateLearningObject.isLoading
              )
            ) {
              closeModal();
              setToast(
                'views.courses.collection_edit.theory_modal.update.success',
                'success'
              );
            }
          },
          onError: (data: ErrorDocument) => {
            setToast(
              data.errors.map((error) => error.detail).join('<br />'),
              'error'
            );
          },
        }
      );
      // eslint-disable-next-line max-len
    },
    [
      nodeId,
      subjectId,
      collectionId,
      nfcTag?.id,
      createOrUpdateLearningObject,
      learningObject,
      isQuiz,
      objectLayout,
      points,
      fileUrl,
      nfcTagEnabled,
      updateLearningObjectVariety.isLoading,
      handleNfcCondition,
      closeModal,
      setToast,
    ]
  );

  return (
    <Form<FormData> onSubmit={handleSubmitLearningObject} id="theory-details">
      <div className={cx(styles.wrapper)}>
        {!isQuiz && (
          <NfcForm
            nfcTagEnabled={nfcTagEnabled}
            setNfcTagEnabled={setNfcTagEnabled}
            setCreatedNfcTag={setCreatedNfcTag}
            learningObjectTitle={learningObjectTitle}
            nfcTag={nfcTag}
          />
        )}
        <Input
          label={t('views.courses.collection_edit.theory_modal.form.title')}
          name="title"
          value={learningObjectTitle}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setLearningObjectTitle(e.target.value)
          }
          ref={titleInputRef}
        />
        <Input
          label={t('views.courses.collection_edit.assignment_modal.form.xp')}
          type="number"
          defaultValue="0"
          value={points?.toString()}
          onChange={(e) =>
            setPoints(parseFloat(e.target.value.replace(',', '.')))
          }
          className={cx(styles.pointsInput)}
        />
        {!isQuiz && (
          <>
            <LayoutSelector
              layoutType="object"
              setSelectedLayout={setObjectLayout}
              selectedLayout={objectLayout}
              routeLayout={routeLayout}
              isQuiz={isQuiz}
            />
            <strong>
              {t('views.courses.collection_edit.theory_modal.form.preview')}
            </strong>
            <PreviewLearningObject points={points} fileUrl={fileUrl} />

            <div className={cx(styles.uploadBox)}>
              <UploadBox
                url={`${apiConfig.baseUrl}/v1/services/media_upload`}
                headers={apiConfig.headers}
                paramName="files[0]"
                maxFiles={1}
                label={t('views.courses.collection_edit.form.image')}
                onAfterFileUpload={(
                  file: Dropzone.DropzoneFile,
                  response: any
                ) => {
                  setFileUrl(response?.data?.[0]?.attributes?.file_url);
                }}
              />
            </div>
            <span
              dangerouslySetInnerHTML={{
                __html: sanitizer(
                  t('views.courses.collection_edit.form.image_instructions')
                ),
              }}
            />
          </>
        )}
      </div>
    </Form>
  );
};
