import React from 'react';
import { LayoutComponent } from 'Views';

/**
 * SubjectListView.
 *
 * For now this layoutComponent is not used but already created for testing purposes
 * @todo This View needs to be completed.
 */
export const SubjectListView: LayoutComponent = () => (
  <>
    <div> Awesome content here</div>
    <div>This should be a list yes</div>
  </>
);

SubjectListView.deprecated = true;
