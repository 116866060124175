import React, { useMemo } from 'react';
import { IGroupContainerBlock } from '@brainstud/course-blocks';
import classNames from 'classnames/bind';
import { v4 } from 'uuid';
import { ContentEditor } from '../../../ContentEditor/ContentEditor';
import styles from './GroupForm.module.css';

const cx = classNames.bind(styles);

type TProps = {
  data: IGroupContainerBlock;
  /** Alias the group container to another type */
  title?: string;
};

export const GroupForm = ({ data, title }: TProps) => {
  // Because in the past editor (2021), learning object groups where not grouped by default, ensure that the data is a group:
  const groupBlock: IGroupContainerBlock = useMemo(
    () =>
      ['group_container', 'block_container'].includes(data.type)
        ? data
        : { id: v4(), type: 'group_container', blocks: [data] },
    [data]
  );

  return (
    <div className={cx(styles.base, groupBlock.className)}>
      <ContentEditor
        containerId={groupBlock.id}
        className={`${styles.editor} group-editor`}
        blocks={groupBlock.blocks || []}
        containerType={title || groupBlock.type || 'group_container'}
      />
    </div>
  );
};
