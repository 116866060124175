import { useCallback, useEffect, useRef, useState } from 'react';

type TDefaultValue<S> = S | (() => S);
type TNewValue<S> = S | ((prev: S) => S);

/**
 * useDebouncedState.
 *
 * When an action happens often and causes a heavy load to process, then you may consider using a debounced state. It
 * causes a small delay in the state update and only starts processing once timer hits. Any changes during the timer
 * will restart the timer.
 */
export function useDebouncedState<T>(
  defaultValue: TDefaultValue<T>,
  delay: number = 500
) {
  const [value, setValue] = useState<T>(defaultValue);

  const timer = useRef<ReturnType<typeof setTimeout>>();
  const setDelayedUpdate = useCallback(
    (newValue: TNewValue<T>) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        setValue(newValue);
      }, delay);
    },
    [delay]
  );
  useEffect(
    () => () => {
      clearTimeout(timer.current);
    },
    []
  );

  return [value, setDelayedUpdate] as const;
}
