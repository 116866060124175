import { UseQueryOptions } from 'react-query';
import useIndexCR from '../Factories/useIndexCR';
import useSingleCRUD from '../Factories/useSingleCRUD';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  ToolkitItem,
  ToolkitItemCreateInput,
  ToolkitItemFilters,
  ToolkitItemIncludes,
  ToolkitItemSorts,
  ToolkitItemUpdateInput,
  UUID,
} from '../Types';

interface IToolkitItemProps
  extends HookParams<
    typeof ToolkitItemIncludes,
    typeof ToolkitItemFilters,
    typeof ToolkitItemSorts
  > {
  toolkit?: UUID;
  category?: UUID;
  item?: UUID;
}

export function useToolkitCategoryItem(
  { toolkit, category, item, ...queryParameters }: IToolkitItemProps,
  settings?: UseQueryOptions<DataDocument<ToolkitItem>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { toolkit: defaultToolkit, toolkitCategory: defaultCategory },
  } = context;
  const request = new ApiRequest(
    {
      enabled:
        !!item &&
        !!(toolkit || defaultToolkit) &&
        !!(category || defaultCategory),
      baseName: 'v1.toolkits.categories.items',
      invalidate: [
        'v1.toolkits',
        'toolkitCategories.toolkitItems',
        'toolkitItems',
      ],
      baseUri: `/v1/toolkits/${toolkit || defaultToolkit}/categories/${category || defaultCategory}/items`,
      uri: `/${item}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    ToolkitItem,
    ToolkitItemCreateInput,
    ToolkitItemUpdateInput
  >(request, settings);
}

export function useToolkitCategoryItems(
  { toolkit, category, ...queryParameters }: Omit<IToolkitItemProps, 'item'>,
  settings?: UseQueryOptions<DataDocument<ToolkitItem[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { toolkit: defaultToolkit, toolkitCategory: defaultCategory },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(toolkit || defaultToolkit) && !!(category || defaultCategory),
      baseName: 'v1.toolkits.categories.items',
      invalidate: [
        'v1.toolkits',
        'toolkitCategories.toolkitItems',
        'toolkitItems',
      ],
      baseUri: `/v1/toolkits/${toolkit}/categories/${category}/items`,
      queryParameters,
    },
    context
  );
  return useIndexCR<ToolkitItem, ToolkitItemCreateInput>(request, settings);
}
