import React from 'react';
import { Search } from '@brainstud/ui';
import { HighlightOff } from '@mui/icons-material';
import { Chip } from '@mui/material';
import classNames from 'classnames/bind';
import { FilterSidebarButton } from 'Components';
import { GridListToggle } from 'Components/GridListToggle';
import { useCourseList } from 'Modules/coach-panel/Providers';
import { useTranslator } from 'Providers/Translator';
import CoursesFiltersContent from './CoursesFiltersContent/CoursesFiltersContent';
import styles from './CoursesSearchAndFilter.module.css';

const cx = classNames.bind(styles);

type Props = {
  title?: string;
  isGridView?: boolean;
  onToggleViewType: () => void;
};

const CoursesSearchAndFilter = ({
  title,
  onToggleViewType,
  isGridView,
}: Props) => {
  const [t] = useTranslator();
  const {
    toggleSbuFilter,
    toggleProfile,
    searchQuery,
    setSearchQuery,
    filters,
    educationProfiles,
    reset,
  } = useCourseList();

  const sbuFilterCount = filters.sbu.length;
  const filterCount = sbuFilterCount
    ? filters.profiles.length + sbuFilterCount || sbuFilterCount
    : filters.profiles.length;

  return (
    <div className={cx(styles.root)}>
      {title && <h2>{title}</h2>}
      <div className={cx(styles.actions)}>
        <Search
          className={cx(styles.search)}
          placeholder={t('search')}
          onChange={(e) => setSearchQuery(e.target.value)}
          onClose={() => setSearchQuery('')}
        />
        <FilterSidebarButton
          label={t('components.catalog.filter')}
          title={t('components.catalog.filter_panel.title')}
          onReset={reset}
          count={filterCount}
        >
          <CoursesFiltersContent />
        </FilterSidebarButton>
        <GridListToggle value={isGridView} onChange={onToggleViewType} />
      </div>
      {(filters.sbu.length > 0 || !!filters?.profiles?.length) &&
        searchQuery.length <= 2 && (
          <div className={cx(styles.enabledFilters)}>
            <span>{`${t('components.catalog.filters')}:`}</span>
            <div className={cx(styles.chipList)}>
              {filters.sbu.length > 0 && (
                <Chip
                  label={`${t('components.catalog.sbu')}: ${filters.sbu.map((item) => t(`components.catalog.sbu_${item}`, {}, item))}`}
                  className={cx(styles.chip)}
                  onDelete={() => toggleSbuFilter(null)}
                  deleteIcon={<HighlightOff />}
                />
              )}
              {filters?.profiles.map((profileId) => {
                const profile = educationProfiles?.find(
                  (p) => p.id === profileId
                );
                return (
                  <Chip
                    key={profileId}
                    label={`${profile?.title}`}
                    className={cx(styles.chip)}
                    onDelete={() => toggleProfile(profileId)}
                    deleteIcon={<HighlightOff />}
                  />
                );
              })}
            </div>
          </div>
        )}
    </div>
  );
};
export default CoursesSearchAndFilter;
