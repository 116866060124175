import { useContext } from 'react';
import CourseListContext from './CourseListContext';

export default function useCourseList() {
  const context = useContext(CourseListContext);
  if (!context) {
    throw new Error('Please initialize CourseListProvider first!');
  }
  return context;
}
