import { LearningObject } from '@brainstud/academy-api';

export function normalizeLearningObjectMetadata(object?: LearningObject) {
  const label = object?.type?.label;
  const variety = object?.variety?.() || object?.varieties?.()[0];
  const xp = object?.userState?.xpAvailable || variety?.points;
  const metadata = {
    ...object?.content?.metadata,
    ...(!Array.isArray(variety?.content) ? variety?.content?.metadata : {}),
    ...object?.metadata?.labels,
  };
  const externalRating = object?.metadata?.configuration?.allowExternalRating;

  const nfcUnlocked = object?.nfcTags?.()[0]?.userState?.lastScanned;

  return {
    ...(metadata || {}),
    theory: label === 'theory' || object?.baseType === 'theory',
    groupSize: metadata?.groupSize,
    workplace: metadata?.workplace || object?.type?.label === 'workplace',
    xp,
    nfcUnlocked,
    externalRating: externalRating || false,
  };
}
