import { DateTimeString } from '../Utils/DateTimeString';
import { IResource } from '../Utils/Resource';
import { UUID } from '../Utils/UUID';

export interface InvoiceProfile extends IResource<'invoice_profiles'> {
  /** The name of the invoice profile */
  name: string;
  /** The state of the invoice profile */
  state: string;
  /** The system the invoice was generated for */
  externalSystems: DateTimeString;
  /** The amount of discount a user has received */
  externalId: number;
  /** The type of discount a user has been given */
  reference: string;
  /** The type of discount a user has been given */
  createdAt: string;
}

export interface InvoiceProfileCreate {
  /** name of the invoice profile */
  name: string;
  /** description of the invoice profile */
  description?: string;
  /** Entity to link the invoice profile to */
  entity?: UUID;
  /** the system where the invoice should be created */
  external_system?: string;
  /** an ID that exists in the external system */
  external_id: string;
  /** description of the payment term */
  payment_term?: string;
  /** description of any invoice requirements */
  invoice_requirements?: string;
}

export interface InvoiceProfileUpdate {
  /** name of the invoice profile */
  name: string;
  /** description of the invoice profile */
  description?: string;
}

export enum InvoiceProfileIncludes {}

export interface InvoiceProfileFilters {
  /** filter the invoiceprofiles on a specific linked entity */
  entity: UUID;
}

export enum InvoiceProfileSort {}
