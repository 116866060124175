import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  Plan,
  PlanCreateInput,
  PlanFilters,
  PlanIncludes,
  PlanSort,
  PlanUpdateInput,
  UUID,
} from '../Types';

interface IPlanProps
  extends HookParams<typeof PlanIncludes, typeof PlanFilters, typeof PlanSort> {
  plan?: UUID;
}

export function usePlan(
  { plan, ...queryParameters }: IPlanProps = {},
  settings?: UseQueryOptions<DataDocument<Plan>, ErrorDocument>
) {
  const context = useApi();

  const {
    parameters: { plan: defaultPlan },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(plan || defaultPlan),
      baseName: 'v1.plans',
      baseUri: '/v1/plans',
      invalidate: ['plans', 'plan'],
      uri: `/${plan}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<Plan, PlanCreateInput, PlanUpdateInput>(
    request,
    settings
  );
}

export function usePlans(
  { ...queryParameters }: Omit<IPlanProps, 'plan'> = {},
  settings?: UseQueryOptions<DataDocument<Plan[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.plans',
      baseUri: '/v1/plans',
      invalidate: ['plans', 'plan'],
      queryParameters,
    },
    context
  );
  return useIndexCR<Plan, PlanCreateInput>(request, settings);
}
