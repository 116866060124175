const dateDifference = (
  first: Date | string,
  second: Date | string
): number => {
  const from =
    (typeof first === 'string' ? new Date(first) : first).getTime() / 1000;
  const to =
    (typeof second === 'string' ? new Date(second) : second).getTime() / 1000;

  return Math.round(from - to);
};

export default dateDifference;
