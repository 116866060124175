import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  Product,
  ProductCreateInput,
  ProductFilters,
  ProductIncludes,
  ProductSort,
  ProductUpdateInput,
  UUID,
} from '../Types';

interface IProductProps
  extends HookParams<
    typeof ProductIncludes,
    ProductFilters,
    typeof ProductSort
  > {
  product?: UUID;
}

export function useProduct(
  { product, ...queryParameters }: IProductProps = {},
  settings?: UseQueryOptions<DataDocument<Product>, ErrorDocument>
) {
  const context = useApi();

  const {
    parameters: { product: defaultProduct },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(product || defaultProduct),
      baseName: 'v1.products',
      baseUri: '/v1/products',
      invalidate: ['products', 'product', 'v1.catalogs.products'],
      uri: `/${product}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<Product, ProductCreateInput, ProductUpdateInput>(
    request,
    settings
  );
}

export function useProducts(
  { ...queryParameters }: Omit<IProductProps, 'product'> = {},
  settings?: UseQueryOptions<DataDocument<Product[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.products',
      invalidate: ['products', 'product', 'v1.catalogs.products'],
      baseUri: '/v1/products',
      queryParameters,
    },
    context
  );
  return useIndexCR<Product, ProductCreateInput>(request, settings);
}
