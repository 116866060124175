import React, { useCallback } from 'react';
import { Button } from '@brainstud/ui';
import { ChevronLeft } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { useBreakpoints } from 'Hooks';
import { useRouter } from 'next/router';
import { useTranslator } from 'Providers/Translator';
import styles from './BackButton.module.css';

const cx = classNames.bind(styles);

type Props = {
  /** The URL to go to, defaults to history.back() */
  href?: string;
  /** Function to be fired to check if user indeed wants to go back */
  onBeforeBack?: (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
  ) => boolean;
  className?: string;
};

/**
 * Shows a round button with arrow with which you can return
 * to the previous page.
 */
export const BackButton = ({ href, onBeforeBack, className }: Props) => {
  const [t] = useTranslator();

  const router = useRouter();
  const { return: returnTo } = router.query as { return?: string };
  const hasReturnTo = !!returnTo;

  const { smDown } = useBreakpoints();

  const handleBack = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
    ) => {
      if (!onBeforeBack || onBeforeBack(e)) {
        router.back();
      }
    },
    [onBeforeBack, router]
  );

  if (smDown) return null;

  return (
    <Button
      round
      type="button"
      title={t('go_back')}
      href={hasReturnTo ? returnTo : href}
      onClick={hasReturnTo || href ? undefined : handleBack}
      className={cx('back-button', className)}
    >
      <ChevronLeft style={{ fontSize: '3rem' }} />
    </Button>
  );
};
