import React, { FC, PropsWithChildren } from 'react';
import { ErrorView } from 'Components/Errors/ErrorView';
import { ErrorBoundary } from 'Providers/ErrorDataProvider/ErrorBoundary';

type TProps = {
  /** Overwrite the default explanation message */
  message?: string;
};

/**
 * This component is a wrapper for the ErrorBlock component, which catches all errors and shows a nicer view instead.
 */
export const ContentErrorBoundary: FC<PropsWithChildren<TProps>> = ({
  message,
  children,
}) => (
  // eslint-disable-next-line react/no-unstable-nested-components
  <ErrorBoundary fallback={() => <ErrorView message={message} />}>
    {children}
  </ErrorBoundary>
);
