import { useCallback } from 'react';
import { Answer } from '@brainstud/academy-api';
import { Button } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import { DateFormatter } from 'Utils/DateFormatHelper';
import { ActivityTitle } from '../ActivityTitle';
import activityFeedStyles from '../ActivityFeed.module.css';
import styles from './Answer.module.css';

const cx = classNames.bind({ ...activityFeedStyles, ...styles });

type TProps = {
  answer?: Answer;
  onChangeAnswerId?: (answerId: string) => void;
  isActive: boolean;
};

export const ActivityFeedAnswer = ({
  answer,
  onChangeAnswerId,
  isActive,
}: TProps) => {
  const [t] = useTranslator();

  const handleChangeAnswerId = useCallback(() => {
    if (answer?.id) onChangeAnswerId?.(answer.id);
  }, [onChangeAnswerId, answer]);

  return answer ? (
    <ActivityTitle id={answer.id} className={cx(styles.base, { isActive })}>
      <div className={styles.title}>
        <span>{t('components.activity_feed.assignment_turned_in')}</span>
        <span className={cx(activityFeedStyles.timestamp)}>
          {DateFormatter.toTime(answer.createdAt)}
        </span>
      </div>
      {onChangeAnswerId &&
        (isActive ? (
          <span>{t('components.activity_feed.current_answer')}</span>
        ) : (
          <Button
            type="button"
            link
            onClick={handleChangeAnswerId}
            quiet={isActive}
            disabled={isActive}
          >
            {t('components.activity_feed.view_answer')}
          </Button>
        ))}
    </ActivityTitle>
  ) : null;
};
