/**
 * Regex explained: https://regex101.com/r/xHd2m8/1
 * Tested on:
 * https://www.youtube.com/watch?v=this_is-theid234
 * https://youtu.be/this_is-theid234
 * https://www.youtube.com/embed/this_is-theid234
 * https://player.vimeo.com/video/133713371
 * https://vimeo.com/133713371/0c8c0d4ce1
 * @returns {string}
 */
const VIDEO_URL_REGEX =
  /(\.be\/|[?&](v=)|embed\/|vimeo.com\/(video\/)?)([a-zA-Z0-9\-_]*)/;

function getVideoId(url: string) {
  return (VIDEO_URL_REGEX.exec(url) || []).slice(-1);
}
export function getEmbedLink(url?: string) {
  if (!url) {
    return '';
  }
  const videoId = getVideoId(url);

  if (url.includes('youtube') || url.includes('youtu.be')) {
    return `https://www.youtube.com/embed/${videoId}`;
  }

  if (url.includes('vimeo')) {
    return `https://player.vimeo.com/video/${videoId}?color=ff7300&title=0&byline=0&portrait=0`;
  }
  return url;
}
