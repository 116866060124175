import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  CertificationVersion,
  CertificationVersionCreateInput,
  CertificationVersionFilters,
  CertificationVersionIncludes,
  CertificationVersionSorts,
  CertificationVersionUpdateInput,
  HookParams,
  UUID,
} from '../Types';

interface ICertificationVersionProps
  extends HookParams<
    typeof CertificationVersionIncludes,
    typeof CertificationVersionFilters,
    typeof CertificationVersionSorts
  > {
  certification?: UUID;
  certificationVersion?: UUID;
}

export function useCertificationVersion(
  {
    certification,
    certificationVersion,
    ...queryParameters
  }: ICertificationVersionProps = {},
  settings?: UseQueryOptions<DataDocument<CertificationVersion>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: {
      certificationVersion: defaultCertificationVersion,
      certification: defaultCertification,
    },
  } = context;
  const request = new ApiRequest(
    {
      enabled:
        !!(certificationVersion || defaultCertificationVersion) &&
        !!(certification || defaultCertification),
      baseName: 'v1.certifications.versions',
      baseUri: `/v1/certifications/${certification || defaultCertification}/versions`,
      uri: `/${certificationVersion || defaultCertificationVersion}`,
      invalidate: [
        'versions',
        'version',
        'certification_version',
        'certification_versions',
      ],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    CertificationVersion,
    CertificationVersionCreateInput,
    CertificationVersionUpdateInput
  >(request, settings);
}

export function useCertificationVersions(
  {
    certification,
    ...queryParameters
  }: Omit<ICertificationVersionProps, 'certificationVersion'> = {},
  settings?: UseQueryOptions<
    DataDocument<CertificationVersion[]>,
    ErrorDocument
  >
) {
  const context = useApi();
  const {
    parameters: { certification: defaultCertification },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(certification || defaultCertification),
      baseName: 'v1.certifications.versions',
      baseUri: `/v1/certifications/${certification || defaultCertification}/versions`,
      invalidate: [
        'versions',
        'version',
        'certification_version',
        'certification_versions',
      ],
      queryParameters,
    },
    context
  );
  return useIndexCR<CertificationVersion, CertificationVersionCreateInput>(
    request,
    settings
  );
}
