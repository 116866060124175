import { UseQueryOptions } from 'react-query';
import { useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  Feedback,
  FeedbackCreateInput,
  FeedbackFilters,
  FeedbackIncludes,
  FeedbackSorts,
  FeedbackUpdateInput,
  HookParams,
  UUID,
} from '../Types';

interface IEnrollmentFeedbackProps
  extends HookParams<
    typeof FeedbackIncludes,
    typeof FeedbackFilters,
    typeof FeedbackSorts
  > {
  enrollment?: UUID;
}

export function useEnrollmentFeedback(
  { enrollment, ...queryParameters }: IEnrollmentFeedbackProps = {},
  settings?: UseQueryOptions<DataDocument<Feedback>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { enrollment: defaultEnrollment },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(enrollment || defaultEnrollment),
      baseName: 'v1.enrollments.feedback',
      baseUri: `/v1/enrollments/${enrollment || defaultEnrollment}/feedback`,
      invalidate: [
        'enrollment',
        'enrollments',
        'v1.enrollments',
        'v1.portals.courses',
        'courses',
      ],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<Feedback, FeedbackCreateInput, FeedbackUpdateInput>(
    request,
    settings
  );
}
