import React, { CSSProperties, MouseEventHandler } from 'react';
import { FilterList } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Badge } from '../../../Modules/coach-panel/Components';
import styles from './FilterButton.module.css';

const classes = classNames.bind(styles);

export type Props = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  /** Shows number within the button */
  count?: number;
  /** When the filtering is activated */
  active?: boolean;
  children: string;
  style?: CSSProperties;
  className?: string;
};

/**
 * FilterButton.
 *
 * Shows a button in filter styling. Either to open a panel or drawer to select filtering options.
 */
export const FilterButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ onClick, active, count = 0, style, className, children }: Props, ref) => (
    <button
      type="button"
      className={classes(styles.base, { active }, className)}
      onClick={onClick}
      ref={ref}
      style={style}
    >
      <FilterList className={classes(styles.icon)} />

      <span className={classes(styles.text)}>{children}</span>

      {count > 0 ? (
        <Badge className={classes(styles.badge)} circle>
          {count}
        </Badge>
      ) : null}
    </button>
  )
);
