import { useContext } from 'react';
import { AnswerContext } from './AnswerContext';

/**
 * useAnswerForm.
 *
 * Hook explicitly to be used in combination with data from the AnswerForm component.
 */
export function useAnswerProvider() {
  const context = useContext(AnswerContext);

  if (!context) {
    throw Error('The AnswerProvider is required for this hook');
  }

  return context;
}
