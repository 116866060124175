import React, { useMemo } from 'react';
import { withBase } from '@brainstud/action-based-learning-panel/Layouts';
import { Container, Loading } from 'Components';
import {
  ILearningSubjectWithResources,
  LearningSubjectProvider,
  useLearningRouteProvider,
} from 'Providers';
import { getLayout } from '../../../Utils/getLayout';

type Props = {
  learningSubject?: ILearningSubjectWithResources;
};

export const SubjectView = withBase(({ learningSubject }: Props) => {
  const { layout: routeLayout } = useLearningRouteProvider();
  const {
    pageTree: { currentItem },
  } = useLearningRouteProvider();
  const subject = useMemo(
    () =>
      learningSubject ||
      (currentItem as undefined | ILearningSubjectWithResources),
    [learningSubject, currentItem]
  );

  const Layout = getLayout(routeLayout, 'subject', subject?.layout)?.view;

  return !subject ? (
    <Loading />
  ) : (
    <LearningSubjectProvider learningSubject={subject}>
      <Container>{Layout && <Layout subject={subject} />}</Container>
    </LearningSubjectProvider>
  );
});
