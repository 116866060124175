import { UseQueryOptions } from 'react-query';
import useIndexCR from '../Factories/useIndexCR';
import useSingleCRUD from '../Factories/useSingleCRUD';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  GlossaryTerm,
  GlossaryTermCreateInput,
  GlossaryTermFilters,
  GlossaryTermIncludes,
  GlossaryTermSorts,
  GlossaryTermUpdateInput,
  HookParams,
  UUID,
} from '../Types';

interface Props
  extends HookParams<
    typeof GlossaryTermIncludes,
    typeof GlossaryTermFilters,
    typeof GlossaryTermSorts
  > {
  course?: UUID;
  glossaryTerm?: UUID;
}

export function useGlossaryTerm(
  { course, glossaryTerm, ...queryParameters }: Props = {},
  settings?: UseQueryOptions<DataDocument<GlossaryTerm>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { course: defaultCourse, glossaryTerm: defaultGlossaryTerm },
  } = context;
  const request = new ApiRequest(
    {
      enabled:
        !!(course || defaultCourse) && !!(glossaryTerm || defaultGlossaryTerm),
      baseName: 'v1.courses.glossary_terms',
      baseUri: `/v1/courses/${course || defaultCourse}/glossary_terms`,
      uri: `/${glossaryTerm || defaultGlossaryTerm}`,
      invalidate: ['glossary_term', 'glossary_terms'],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    GlossaryTerm,
    GlossaryTermCreateInput,
    GlossaryTermUpdateInput
  >(request, settings);
}

export function useGlossaryTerms(
  { course, ...queryParameters }: Omit<Props, 'glossaryTerm'> = {},
  settings?: UseQueryOptions<DataDocument<GlossaryTerm[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { course: defaultCourse },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(course || defaultCourse),
      baseName: 'v1.courses.glossary_terms',
      baseUri: `/v1/courses/${course || defaultCourse}/glossary_terms`,
      invalidate: ['glossary_term', 'glossary_terms'],
      queryParameters,
    },
    context
  );
  return useIndexCR<GlossaryTerm, GlossaryTermCreateInput>(request, settings);
}
