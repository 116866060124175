import { UseQueryOptions } from 'react-query';
import { useIndexCR } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  PortfolioExport,
  PortfolioExportCreateInput,
  PortfolioExportsFilters,
  PortfolioExportsIncludes,
  PortfolioExportsSorts,
} from '../Types';

interface IPortfolioExportsProps
  extends HookParams<
    typeof PortfolioExportsIncludes,
    PortfolioExportsFilters,
    typeof PortfolioExportsSorts
  > {}

export function usePortfolioExports(
  { ...queryParameters }: IPortfolioExportsProps = {},
  settings?: UseQueryOptions<DataDocument<PortfolioExport[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.portfolioExports',
      invalidate: [],
      baseUri: '/v1/portfolio_exports',
      queryParameters,
    },
    context
  );
  return useIndexCR<PortfolioExport, PortfolioExportCreateInput>(
    request,
    settings
  );
}
