import React, { CSSProperties, ReactNode, useMemo } from 'react';
import { Button, Panel } from '@brainstud/ui';
import { ReactComponent as EmptyToolkit } from 'Assets/Icons/empty_toolkit.svg';
import classNames from 'classnames';
import { useTranslator } from 'Providers/Translator';
import styles from 'Components/EmptyState/EmptyState.module.css';

const cx = classNames.bind(styles);

type EmptyStateText = {
  title?: string;
  description?: string;
  call_to_action?: string;
  filter_title?: string;
  filter_description?: string;
};

type Props = {
  /** trigger to show the empty state */
  isEmpty: boolean;
  /** add in your active filter */
  isFiltered?: boolean;
  /** Provide a string to link to or a function to execute on CTA button */
  onClickCTA?: string | (() => void);
  /** you can pass custom translation for the message, for this make the translation like the EmptyStateText, you can pass the main translation object */
  messages?: EmptyStateText;
  /** Term(plural) that will be used in the title and message (defaults to "items") */
  term?: string;
  style?: CSSProperties;
  className?: string;
  children?: ReactNode;
};

/**
 * EmptyState.
 *
 * EmptyState handling made simple and uniform,
 * add this component as a wrapper around your view.
 */
export const EmptyState = ({
  className,
  style,
  isEmpty,
  isFiltered,
  onClickCTA,
  messages,
  term = 'items',
  children,
}: Props) => {
  const [t] = useTranslator();

  const translations = useMemo(
    () => ({ ...(t('components.empty_state') as EmptyStateText), ...messages }),
    [t, messages]
  );

  // Make sure that onClickCTA is passed properly
  let to: string | undefined;
  let onClick: (() => void) | undefined;
  if (typeof onClickCTA === 'string') {
    to = onClickCTA;
  } else if (typeof onClickCTA === 'function') {
    onClick = onClickCTA;
  }

  return (
    <>
      {isEmpty || isFiltered ? (
        <Panel pad className={cx(styles.base, { className })} style={style}>
          <EmptyToolkit />
          <h3>
            {isFiltered
              ? t(translations.filter_title)
              : t(translations.title, { term })}
          </h3>
          <span>
            {isFiltered
              ? t(translations.filter_description, { term })
              : onClickCTA
                ? t(translations?.description, { term })
                : messages?.description}
          </span>
          {onClickCTA && (
            <div className={cx(styles.buttonWrapper)}>
              <Button type="button" outline onClick={onClick} to={to}>
                {t(translations?.call_to_action, { term })}
              </Button>
            </div>
          )}
        </Panel>
      ) : (
        children
      )}
    </>
  );
};
