import React, { useCallback, useMemo } from 'react';
import { useExternalAssessment } from '@brainstud/academy-api';
import { ConfirmationModal, Panel } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { Container, Loading } from 'Components';
import { useRouter } from 'next/router';
import { LearningObjectProvider, useBreadcrumbs, useModals } from 'Providers';
import { AnswerProvider } from 'Providers/AnswerProvider';
import { useTranslator } from 'Providers/Translator';
import { ExternalAssessmentSuccessModal } from '../../Modals';
import { AnswerReviewProvider } from '../../Providers';
import { ReviewAnswerView } from '../Review';
import { ExternalFeedbackDrawer } from './ExternalFeedbackDrawer/ExternalFeedbackDrawer';
import { ExternalRatingHeader } from './ExternalRatingHeader';
import styles from './ExternalRatingView.module.css';

const cx = classNames.bind(styles);

export type TExternalRatingFormValues = {
  name: string;
  feedback: string;
  files: string[];
};

export const ExternalRatingView = () => {
  const [t] = useTranslator();

  const router = useRouter();
  const { external_rating, token } = router.query as {
    external_rating: string;
    token: string;
  };

  const [{ data, update }, { isLoading, isError }] = useExternalAssessment({
    externalAssessment: external_rating,
    token,
    include: [
      'answer',
      'answer.learning_object',
      'answer.uploads',
      'answer.learning_object_variety',
      'account',
      'stored_files',
    ],
  });

  const course = useMemo(() => data?.enrollment?.().course?.(), [data]);

  useBreadcrumbs(
    {
      ...(course && { [course.id]: course.title }),
    },
    [course]
  );

  const account = useMemo(() => data?.account?.(), [data]);
  const answer = useMemo(() => data?.answer?.(), [data]);

  const learningObject = useMemo(
    () =>
      answer?.learningObject?.() && {
        ...answer.learningObject(),
        /**
         * This is needed to be able to review the current answer, as hooks deeper
         * in the tree also rely on answers being available.
         */
        answers: answer && (() => [answer]),
      },
    [answer]
  );

  const { openModal, closeModal } = useModals();

  const handlePatchExternalRating = useCallback(
    async ({ name, feedback, files }: TExternalRatingFormValues) =>
      new Promise<boolean>((resolve) => {
        openModal(ConfirmationModal, {
          closeable: true,
          title: t('external_rating.confirm_modal.title'),
          description: t('external_rating.confirm_modal.content'),
          yes: t('external_rating.confirm_modal.submit'),
          no: t('modals.cancel'),
          handleConfirm: () => {
            update.mutateAsync(
              {
                _method: 'patch',
                name,
                feedback,
                relationships: {
                  stored_files: files,
                },
              },
              {
                onSettled: () => closeModal(),
                onSuccess: () => {
                  openModal(ExternalAssessmentSuccessModal);
                  resolve(true);
                },
                onError: () => {
                  resolve(false);
                },
              }
            );
          },
        });
      }),
    [closeModal, openModal, t, update]
  );

  return (
    <div>
      {isError ? (
        <Container>
          <Panel className={cx(styles.panel)}>
            <section>
              <h2>{t('external_rating.no_permission.heading')}</h2>
              <p>{t('external_rating.no_permission.explanation')}</p>
            </section>
          </Panel>
        </Container>
      ) : isLoading ? (
        <Loading />
      ) : (
        <LearningObjectProvider learningObject={learningObject}>
          <AnswerProvider>
            <AnswerReviewProvider account={account} externalRating={data}>
              <ExternalRatingHeader />
              <ReviewAnswerView hideActivityFeed />
              <ExternalFeedbackDrawer onSubmit={handlePatchExternalRating} />
            </AnswerReviewProvider>
          </AnswerProvider>
        </LearningObjectProvider>
      )}
    </div>
  );
};
