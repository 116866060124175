import React, { useCallback, useState } from 'react';
import {
  useLearningObjectCollection,
  usePortalCourses,
} from '@brainstud/academy-api';
import { ActionMenu, Button, Panel, Search } from '@brainstud/ui';
import {
  Add,
  ArrowForward,
  Edit,
  Launch,
  Print,
  Settings,
} from '@mui/icons-material';
import { ReactComponent as EmptyContentSet } from 'Assets/Icons/empty_content_set.svg';
import { ReactComponent as LearningRouteIcon } from 'Assets/Icons/learning_route.svg';
import classNames from 'classnames';
import { Link, Loading } from 'Components';
import { AUTHOR_ROLES } from 'Config/roles';
import { useHasAnyRole } from 'Hooks';
import { DuplicateLearningRouteModal } from 'Modules/coach-panel/Modals';
import { useRouter } from 'next/router';
import {
  useEnvironmentProvider,
  useLearningRouteProvider,
  useModals,
} from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { CollectionList } from './CollectionList';
import { LearningRouteViewSettingsModal } from './LearningRouteViewSettingsModal';
import styles from './CollectionListView.module.css';

const cx = classNames.bind(styles);

export const CollectionListView = () => {
  const [t] = useTranslator();
  const router = useRouter();
  const { openModal } = useModals();

  const { collectionId, courseId } = router.query as {
    collectionId: string;
    courseId: string;
  };

  const {
    nodes,
    isLoading,
    learningRoute,
    isCustomCollection,
    isCustomRoute,
    layout,
    list,
  } = useLearningRouteProvider();
  const [{ data }] = useLearningObjectCollection({
    learningObjectCollection: collectionId,
  });
  const [{ data: courses }] = usePortalCourses();
  const [{ data: learningObjectCollection }] = useLearningObjectCollection({
    learningObjectCollection: collectionId,
    include: [
      'learning_routes',
      'learning_routes.learning_route_nodes',
      'learning_objects',
      'learning_subjects',
    ],
  });

  const isEnrolled = courses.some(
    (item) => item.id === courseId && !!item.enrollment
  );
  const viewUrl = learningObjectCollection?.links?.view?.href;
  const editUrl = `/coach/courses/${courseId}/collections/${collectionId}/routes/${learningRoute?.id}/edit`;

  const { environment } = useEnvironmentProvider();
  const canEdit = useHasAnyRole(AUTHOR_ROLES) && environment === 'admin';

  const [search, setSearch] = useState('');

  const handleOpenLearningRouteSettingsModal = useCallback(() => {
    openModal(LearningRouteViewSettingsModal, {
      layout,
      learningRouteId: learningRoute?.id,
      list,
    });
  }, [openModal, layout, learningRoute?.id, list]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <div className={cx(styles.base)}>
        <div>
          <Search
            onChange={(e) => setSearch(e.target.value)}
            placeholder={t('search')}
          />
          <Button
            type="button"
            className={cx(styles.printButton)}
            onClick={window.print}
            outline
          >
            Print
            <Print />
          </Button>
        </div>

        {canEdit && nodes.length > 0 && (
          <Button
            type="button"
            to={`${router.asPath}/edit`}
            disabled={!data?.links?.edit?.href}
          >
            <span>{t('views.collection.open_course_editor')}</span>
            <ArrowForward />
          </Button>
        )}
        {environment === 'coach' && isEnrolled && (
          <div className={cx(styles.coachActions)}>
            {viewUrl && (
              <Button
                quiet
                href={`/courses/${courseId}?returnTo=${router.asPath}`}
              >
                <span>{t('views.courses.assignments.view_content')}</span>
                <Launch />
              </Button>
            )}
            <ActionMenu id="assignments-actions">
              {editUrl && isCustomCollection && (
                <Link role="menuitem" href={editUrl}>
                  <Edit />
                  <span>{t('views.courses.assignments.edit_content')}</span>
                </Link>
              )}
              {learningRoute && !isCustomCollection && (
                <button
                  role="menuitem"
                  type="button"
                  onClick={() =>
                    !isCustomRoute
                      ? openModal(DuplicateLearningRouteModal, {
                          learningRouteId: learningRoute?.id,
                          learningRouteTitle: learningRoute?.title,
                        })
                      : router.push(editUrl)
                  }
                >
                  <LearningRouteIcon />
                  <span>{t('views.courses.assignments.edit_route')}</span>
                </button>
              )}
              <button
                role="menuitem"
                type="button"
                onClick={handleOpenLearningRouteSettingsModal}
              >
                <Settings />
                <span>{t('views.courses.collection_edit.settings')}</span>
              </button>
            </ActionMenu>
          </div>
        )}
      </div>
      {nodes.length > 0 ? (
        <CollectionList nodes={nodes} search={search} />
      ) : (
        <Panel className={cx(styles.emptyPanel)}>
          <div className={cx(styles.emptyMessage)}>
            <EmptyContentSet />

            <span>
              {t('components.collections_table.no_content.available')}
            </span>
            {canEdit && (
              <Button
                to={`${router.asPath}/edit`}
                disabled={!data?.links?.edit?.href}
              >
                <Add />
                <span>{t('views.collection.add_content')}</span>
              </Button>
            )}
          </div>
        </Panel>
      )}
    </>
  );
};
