import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  LearningSubject,
  LearningSubjectCreateInput,
  LearningSubjectFilters,
  LearningSubjectIncludes,
  LearningSubjectSorts,
  LearningSubjectUpdateInput,
  UUID,
} from '../Types';

interface ILearningSubjectProps
  extends HookParams<
    typeof LearningSubjectIncludes,
    typeof LearningSubjectFilters,
    typeof LearningSubjectSorts
  > {
  enrollment?: UUID;
  learningSubject?: UUID;
}

export function useEnrollmentLearningSubject(
  {
    learningSubject,
    enrollment,
    ...queryParameters
  }: ILearningSubjectProps = {},
  settings?: UseQueryOptions<DataDocument<LearningSubject>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: {
      learningSubject: defaultLearningSubject,
      enrollment: defaultEnrollment,
    },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(
        (learningSubject || defaultLearningSubject) &&
        (enrollment || defaultEnrollment)
      ),
      baseName: 'v1.enrollments.learningSubjects',
      baseUri: `/v1/enrollments/${enrollment || defaultEnrollment}/learning_subjects`,
      uri: `/${learningSubject || defaultLearningSubject}`,
      invalidate: ['learning_subject', 'learning_subjects'],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    LearningSubject,
    LearningSubjectCreateInput,
    LearningSubjectUpdateInput
  >(request, settings);
}

export function useEnrollmentLearningSubjects(
  {
    enrollment,
    ...queryParameters
  }: Omit<ILearningSubjectProps, 'learningSubject'> = {},
  settings?: UseQueryOptions<DataDocument<LearningSubject[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { enrollment: defaultEnrollment },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(enrollment || defaultEnrollment),
      baseName: 'v1.enrollments.learningSubjects',
      baseUri: `/v1/enrollments/${enrollment || defaultEnrollment}/learning_subjects`,
      invalidate: ['learning_subject', 'learning_subjects'],
      queryParameters,
    },
    context
  );
  return useIndexCR<LearningSubject, LearningSubjectCreateInput>(
    request,
    settings
  );
}
