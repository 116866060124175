import React from 'react';
import { Button } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import { ReactComponent as AccountProtected } from 'public/media/icons/account-unprotected.svg';
import styles from './TwoFactorAuthDisabledView.module.css';

const cx = classNames.bind(styles);

export const TwoFactorAuthDisabledView = () => {
  const [t] = useTranslator();
  return (
    <>
      <h3>{t('security.two_factor.disabled.title')}</h3>
      <div className={cx(styles.info)}>
        <AccountProtected className={cx(styles.icon)} />
        <span>{t('security.two_factor.disabled.info')}</span>
      </div>
      <div className={cx(styles['button-wrapper'])}>
        <Button to="/account/security">
          {t('security.two_factor.back_to_security')}
        </Button>
      </div>
    </>
  );
};
