import { createReactReducer } from 'Utils';
import { BlockState } from './ContentBlocksContext';

interface IContentBlocksState {
  states: BlockState[];
  isSubmittable: boolean;
  isStatic: boolean;
  isRead: boolean;
}

type SetBlockStatesAction = {
  type: typeof ACTIONS.SET_BLOCK_STATES;
  payload: BlockState[];
};

type ResetTouchStatesAction = {
  type: typeof ACTIONS.RESET_TOUCHED;
};

type TActions = SetBlockStatesAction | ResetTouchStatesAction;

export const ACTIONS = {
  SET_BLOCK_STATES: 'SET_BLOCK_STATES',
  RESET_TOUCHED: 'RESET_TOUCHED',
} as const;

export const contentBlocksInitialState: IContentBlocksState = {
  states: [],
  isSubmittable: false,
  isStatic: false,
  isRead: false,
};

function handleSetBlockStates(
  payload: BlockState[],
  currentBlockStates: BlockState[]
): BlockState[] {
  return payload.reduce((blocks, { id, ...values }) => {
    const blockId = blocks.findIndex((block) => block.id === id);

    if (blockId === -1) {
      const block: BlockState = {
        id,
        ...values,
        touched: values.touched ?? false,
        submittable: values.submittable ?? false,
        read: values.read ?? false,
      };

      return [...blocks, block];
    }

    const newBlocks = [...blocks];

    newBlocks.splice(blockId, 1, {
      ...currentBlockStates[blockId],
      ...values,
    });

    return newBlocks;
  }, currentBlockStates);
}

export const ContentBlocksReducer = createReactReducer<
  IContentBlocksState,
  TActions
>({
  [ACTIONS.SET_BLOCK_STATES]: ({ payload }, state) => {
    const newBlockStates = handleSetBlockStates(payload, state.states);
    return {
      ...state,
      states: newBlockStates,
      isSubmittable: newBlockStates.every(
        (block) => block.touched || block.static || !block.submittable
      ),
      isStatic:
        newBlockStates.length > 0 &&
        newBlockStates.every((block) => block.loaded && block.static),
      isRead:
        newBlockStates.length > 0 &&
        newBlockStates.every(
          (block) => block.read || block.open || block.closed
        ),
    };
  },
  [ACTIONS.RESET_TOUCHED]: (_, state) => ({
    ...state,
    isSubmittable: false,
    states: state.states.map((block) => ({
      ...block,
      touched: false,
    })),
  }),
});
