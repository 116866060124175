export function isWordDocument(fileNameOrmimeType?: string) {
  return fileNameOrmimeType
    ? fileNameOrmimeType.endsWith('.docx') ||
        fileNameOrmimeType ===
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    : false;
}

export function isExcelDocument(fileNameOrmimeType?: string) {
  return fileNameOrmimeType
    ? fileNameOrmimeType.endsWith('.xlsx') ||
        fileNameOrmimeType ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    : false;
}

export function isPDFDocument(fileNameOrmimeType?: string) {
  return fileNameOrmimeType
    ? fileNameOrmimeType.endsWith('.pdf') ||
        fileNameOrmimeType === 'application/pdf'
    : false;
}

export function isVideo(fileNameOrMimetype?: string, mimeType?: string) {
  return fileNameOrMimetype
    ? /(\.mp4|\.avi|\.mpeg|\.ogv|\.webm)$/.test(fileNameOrMimetype) ||
        fileNameOrMimetype?.includes('video/') ||
        mimeType?.includes('video/')
    : false;
}

export function isImage(fileNameOrMimetype?: string, mimeType?: string) {
  return fileNameOrMimetype
    ? /(\.png|\.jpg|\.jpeg|\.webp|\.gif)$/.test(fileNameOrMimetype) ||
        fileNameOrMimetype?.includes('image/') ||
        mimeType?.includes('image/')
    : false;
}

export function isDocument(fileNameOrmimeType?: string) {
  return (
    isWordDocument(fileNameOrmimeType) ||
    isExcelDocument(fileNameOrmimeType) ||
    isPDFDocument(fileNameOrmimeType)
  );
}
