import { UUID } from '../Utils/UUID';
import { Account, AccountCreateInput } from './Account';
import { Coach } from './Coach';
import { Enrollment } from './Enrollment';
import { ExamSteps } from './Exam';

export interface StudentEnrollment
  extends Omit<Enrollment, 'parameters' | 'asRole' | 'progress'> {
  /** The number of XPs recieved by the student for this enrollment */
  currentXp: number;
  /** @deprecated Use .totalXp instead. */
  targetXp: number;
  /** The total amount of XPs for the student within this course */
  totalXp: number;
  /** The number of answers that are not yet seen by a coach */
  openAnswerCount: number;
  /** The current exam step */
  examStep?: ExamSteps;
  /** The coach for this enrollment */
  coach?: () => Coach;
}

export interface Student
  extends Pick<
    Account,
    | 'id'
    | 'resourceType'
    | 'lastOnline'
    | 'subscriptionEndDate'
    | 'classCode'
    | 'classUnit'
    | 'firstName'
    | 'lastName'
    | 'fullLastName'
    | 'fullName'
    | 'studentGroup'
    | 'coaches'
    | 'coachMandates'
  > {
  enrollments: () => StudentEnrollment[];
  meta: {
    /** The number of answers a coach has not seen yet */
    unseenAnswerCount: number;
    /** The number of actions a coach has not seen yet */
    unseenActionsCount: number;
  };
}
export type StudentCreateInput = AccountCreateInput;
export type StudentUpdateInput = AccountCreateInput;

export interface StudentBulkUpdateInput {
  _method: 'patch';
  operation: 'archive' | 'unarchive';
  accounts: UUID[];
}

export enum StudentIncludes {
  enrollments,
  coaches,
  'enrollments.coach',
}

export interface StudentFilters {
  account: string;
  coach_account: string;
  archived: boolean;
  full_name: string;
  exam_step: string;
  student_group: string;
  learning_object_collection: string;
  course: string;
}

export enum StudentSorts {
  'first_name',
  'full_name',
  'class_unit',
  'last_online',
  'student_group.name',
  'unseen_answer_count',
}
