import { createReactReducer } from 'Utils/createReactReducer';
import ACTIONS, {
  handleBatchAction,
  handleRemoveAction,
  handleResetFiltersAction,
  handleSetAction,
  handleSortAction,
  handleToggleAction,
  IFilter,
  TViewSettings,
  TViewSettingsActions,
} from './ViewSettingsActions';

export interface IViewSettings<T extends TViewSettings = TViewSettings> {
  filters?: Partial<Record<keyof T['filters'], IFilter<T, keyof T['filters']>>>;
  apiFilters?: Partial<T['filters']>;
  sort?: T['sort'];
}

export interface IViewSettingsState<T extends TViewSettings = TViewSettings> {
  isLoaded: boolean;
  isDirty: boolean;
  views: {
    [key: string]: IViewSettings<T> | undefined;
  };
}

export const DefaultViewSettingsState: IViewSettingsState = {
  isLoaded: false,
  isDirty: false,
  views: {},
};

export function ViewSettingsReducerFactory<T extends TViewSettings>() {
  return createReactReducer<IViewSettingsState<T>, TViewSettingsActions<T>>({
    [ACTIONS.LOAD]: ({ payload }) => ({
      ...payload,
      isLoaded: true,
    }),
    [ACTIONS.SET]: ({ payload }, state) => ({
      isDirty: true,
      views: handleSetAction<T>(state, payload),
    }),
    [ACTIONS.TOGGLE]: ({ payload }, state) => ({
      isDirty: true,
      views: handleToggleAction(state, payload),
    }),
    [ACTIONS.REMOVE]: ({ payload }, state) => ({
      isDirty: true,
      views: handleRemoveAction(state, payload),
    }),
    [ACTIONS.BATCH]: ({ payload }, state) => ({
      isDirty: true,
      views: handleBatchAction(state, payload),
    }),
    [ACTIONS.SORT]: ({ payload }, state) => ({
      ...state,
      isDirty: true,
      views: handleSortAction(state, payload),
    }),
    [ACTIONS.SAVED]: () => ({
      isDirty: false,
    }),
    [ACTIONS.RESET_FILTERS]: ({ payload }, state) => ({
      ...state,
      isDirty: false,
      views: handleResetFiltersAction(state, payload),
    }),
  });
}
