import { useEffect, useRef, useState } from 'react';

/**
 * useLoadingState.
 *
 * Creates a state that is automatically reset when dependencies change,
 * or when the setter has been called.
 *
 * @param isLoading {boolean} Will update state again when isLoading changes
 * @param setStateAction Method to execute to set the appropriate state
 */
export function useLoadingState<T>(
  isLoading: boolean,
  setStateAction: (prevValue?: T) => T
) {
  const wasLoading = useRef<undefined | boolean>(isLoading);
  const [value, setValue] = useState(setStateAction());
  useEffect(() => {
    if (wasLoading.current !== isLoading) {
      setValue(setStateAction);
      wasLoading.current = isLoading;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return [value, setValue] as const;
}
