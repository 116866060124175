import React, { CSSProperties } from 'react';
import classNames from 'classnames/bind';
import { useUserData } from 'Hooks';
import { useTranslator } from 'Providers/Translator';
import { Avatar } from '../Avatar';
import { Link } from '../Link';
import styles from './UserBox.module.css';

const cx = classNames.bind(styles);

type Props = {
  style?: CSSProperties;
  className?: string;
};

/**
 * UserBox.
 *
 * Used to show a box about the current user.
 */
export const UserBox = ({ className, style }: Props) => {
  const [t] = useTranslator();
  const [{ fullName }] = useUserData();

  return (
    <Link
      href="/account/details"
      className={cx(styles.base, className)}
      style={style}
    >
      <div className={styles.account}>
        <Avatar className={cx(styles.avatar)} outline />
        <div className={cx(styles['text-block'])}>
          <span className={styles.name}>{fullName}</span>
          <span className={styles.link}>
            {t('components.user_box.my_account')}
          </span>
        </div>
      </div>
    </Link>
  );
};
