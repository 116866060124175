import { useContext } from 'react';
import { LearningSubjectContext } from './LearningSubjectContext';

/**
 * useLearningSubjectProvider.
 *
 * Provides access to the learning subject, as well as the linked quiz if necessary.
 */
export function useLearningSubjectProvider() {
  return useContext(LearningSubjectContext);
}
