import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import { IResource } from '../Types';

type Output<Resource extends IResource> = [
  Resource | undefined,
  QueryObserverResult<DataDocument<Resource>, ErrorDocument>,
];

export default function useShow<Resource extends IResource>(
  request: ApiRequest | null,
  queryConfig?: UseQueryOptions<DataDocument<Resource>, ErrorDocument>
): Output<Resource> {
  const queryKey = request?.getKey('show');
  const response = useQuery<DataDocument<Resource>, ErrorDocument>({
    queryKey,
    queryFn: request?.show.bind(request),
    ...queryConfig,
    enabled:
      !!request &&
      !!request?.enabled &&
      queryConfig?.enabled !== false &&
      request.isRouteValid(),
  });

  return [response?.data?.data, response];
}
