import { UseQueryOptions } from 'react-query';
import { useShow } from '../Factories';
import useIndexCR from '../Factories/useIndexCR';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import { Coach, CoachCreateInput, UUID } from '../Types';

interface ICoachProps {
  coach?: UUID;
  enrollment?: UUID;
}

export function useEnrollmentCoach(
  { coach, enrollment }: ICoachProps = {},
  settings?: UseQueryOptions<DataDocument<Coach>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { coach: defaultCoach, enrollment: defaultEnrollment },
  } = context;
  const request = new ApiRequest(
    {
      baseName: 'v1.enrollments.coaches',
      baseUri: `/v1/enrollments/${enrollment || defaultEnrollment}/coaches`,
      uri: `/${coach || defaultCoach}`,
      invalidate: ['coaches', 'coach'],
    },
    context
  );
  return useShow<Coach>(request, settings);
}

export function useEnrollmentCoaches(
  { enrollment }: ICoachProps = {},
  settings?: UseQueryOptions<DataDocument<Coach[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { enrollment: defaultEnrollment },
  } = context;
  const request = new ApiRequest(
    {
      baseName: 'v1.enrollments.coaches',
      baseUri: `/v1/enrollments/${enrollment || defaultEnrollment}/coaches`,
    },
    context
  );
  return useIndexCR<Coach, CoachCreateInput>(request, settings);
}
