/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { TSortOption } from '@brainstud/course-blocks';
import { Input, InputProps } from '@brainstud/ui';
import { Delete, DragIndicator } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { EditorButton } from '../EditorButton/EditorButton';
import styles from './DeleteableSortOption.module.css';

const cx = classNames.bind(styles);

interface DeleteableSortOptionProps extends InputProps {
  option: TSortOption;
  index: number;
  onDelete?: () => void;
  onChange?: (e: any) => void;
}

/**
 *
 */
export const DeleteableSortOption: FC<DeleteableSortOptionProps> = ({
  onDelete,
  onChange,
  option,
  index,
}) => (
  <Draggable draggableId={option.id} index={index}>
    {(provided) => (
      <div
        {...provided.dragHandleProps}
        {...provided.draggableProps}
        ref={provided.innerRef}
      >
        <div className={cx(styles.base)}>
          <span className={cx(styles.position)}>{index + 1}</span>
          <Input
            defaultValue={option.label}
            tabIndex={index + 1}
            onChange={onChange}
          />
          {onDelete && (
            <EditorButton onClick={onDelete} className={cx(styles.delete)}>
              <Delete fontSize="large" />
            </EditorButton>
          )}
          <EditorButton className={cx(styles.drag)}>
            <DragIndicator fontSize="large" />
          </EditorButton>
        </div>
      </div>
    )}
  </Draggable>
);
