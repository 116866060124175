import React, {
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useOnClickOutside } from '@brainstud/universal-components';
import classNames from 'classnames/bind';
import styles from './Tooltip.module.css';

const classes = classNames.bind(styles);

type Props = {
  /** Sets open state on mount */
  defaultOpen?: boolean;
  /** A controlled open / closed state */
  open?: boolean;
  /** The contents of the button */
  button: ReactNode;
  /** sets a custom height to the button */
  buttonHeight?: string;
  /** Event handler that fires on open state change */
  onChange?: (state: boolean) => void;
  /** Class name */
  className?: string;
};

/**
 * Tooltip.
 */
export const Tooltip = ({
  button,
  buttonHeight,
  defaultOpen,
  open: controlledOpen,
  onChange,
  className,
  children,
}: PropsWithChildren<Props>) => {
  const [open, setOpen] = useState(controlledOpen || defaultOpen);
  const ref = useRef<null | HTMLDivElement>(null);

  const handleToggle = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onChange?.(!open);
      setOpen((prevOpen) => !prevOpen);

      if (open) ref.current?.blur();
    },
    [onChange, open]
  );

  useOnClickOutside([ref], () => {
    setOpen(false);
  });

  return (
    <div ref={ref} className={classes(styles.base, className)}>
      <div className={classes(styles.tooltip, { open })}>{children}</div>
      <button
        type="button"
        className={styles.button}
        onClick={handleToggle}
        style={{ height: buttonHeight }}
      >
        {button}
      </button>
    </div>
  );
};
