import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  Product,
  ProductCreateInput,
  ProductFilters,
  ProductIncludes,
  ProductSort,
  ProductUpdateInput,
  UUID,
} from '../Types';

interface IProductProps
  extends HookParams<
    typeof ProductIncludes,
    ProductFilters,
    typeof ProductSort
  > {
  portal?: UUID;
  product?: UUID;
}

export function usePortalProduct(
  { portal, product, ...queryParameters }: IProductProps = {},
  settings?: UseQueryOptions<DataDocument<Product>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { portal: defaultPortal, product: defaultProduct },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(product || defaultProduct) && !!(portal || defaultPortal),
      baseName: 'v1.portals.products',
      baseUri: `/v1/portals/${portal || defaultPortal}/products`,
      invalidate: ['products', 'product'],
      uri: `/${product}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<Product, ProductCreateInput, ProductUpdateInput>(
    request,
    settings
  );
}

export function usePortalProducts(
  { portal, ...queryParameters }: Omit<IProductProps, 'product'> = {},
  settings?: UseQueryOptions<DataDocument<Product[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { portal: defaultPortal },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(portal || defaultPortal),
      baseName: 'v1.portals.products',
      invalidate: ['products', 'product'],
      baseUri: `/v1/portals/${portal || defaultPortal}/products`,
      queryParameters,
    },
    context
  );
  return useIndexCR<Product, ProductCreateInput>(request, settings);
}
