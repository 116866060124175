import { TEmitFn } from 'Hooks';
import {
  IFormReducerState,
  IFormState,
  TField,
  TFormEvents,
  TInputList,
  TPrimitive,
  TStoreCallbacks,
} from '../Types';

export enum FormActions {
  LOAD = 'LOAD',
  INIT_FIELD = 'INIT_FIELD',
  UPDATE_VALUE = 'UPDATE_VALUE',
  SUBMIT = 'SUBMIT',
  RESET = 'RESET',
  SAVED = 'SAVED',
  CLEAR = 'CLEAR',
  SET_DISABLED = 'SET_DISABLED',
  SET_LOADING = 'SET_LOADING',
  SET_ERROR = 'SET_ERROR',
  SET_PROP = 'SET_PROP',
  SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS',
  APPEND_VALIDATION_ERRORS = 'APPEND_VALIDATION_ERRORS',
  TOGGLE_EVALUATION_STATE = 'TOGGLE_EVALUATION_STATE',
  VALIDATE = 'VALIDATE',
  SAVE = 'SAVE',
}

type TLoadFormAction = {
  type: FormActions.LOAD;
  payload: Partial<IFormState> & { validate?: boolean };
};

type TInitFieldAction = {
  type: FormActions.INIT_FIELD;
  payload: TField;
};

type TSetDisabledAction = {
  type: FormActions.SET_DISABLED;
  payload: boolean;
};

type TSetLoadingAction = {
  type: FormActions.SET_LOADING;
  payload: boolean;
};

type TUpdateValueAction = {
  type: FormActions.UPDATE_VALUE;
  payload: {
    id: string;
    value: TPrimitive | ((oldValue: TPrimitive) => TPrimitive);
  };
};

type TSetPropAction = {
  type: FormActions.SET_PROP;
  payload: Partial<IFormReducerState>;
};

type TSubmitFormAction = {
  type: FormActions.SUBMIT;
  payload?: {
    disabled?: boolean;
  };
};

type TSaveFormAction = {
  type: FormActions.SAVE;
  payload?: {
    context?: { [key: string]: any };
    callbacks?: TStoreCallbacks;
  };
};

type TSetValidationErrors =
  | {
      type: FormActions.SET_VALIDATION_ERRORS;
      payload: {
        messages: {
          [fieldId: string]: string[];
        };
      };
    }
  | {
      type: FormActions.APPEND_VALIDATION_ERRORS;
      payload: {
        messages: {
          [fieldId: string]: string[];
        };
      };
    };

type TSetErrors = {
  type: FormActions.SET_ERROR;
  payload: {
    message: string;
  };
};

type TValidatedFormAction = {
  type: FormActions.TOGGLE_EVALUATION_STATE;
  payload?: {
    fields?: string[];
    visible?: boolean;
  };
};

type TValidateFormAction = {
  type: FormActions.VALIDATE;
  payload: {
    emit: TEmitFn<TFormEvents<TInputList>>;
  };
};

type TResetFormAction = {
  type: FormActions.RESET;
  payload?: {
    disabled?: boolean;
  };
};

type TStoreFormAction = {
  type: FormActions.SAVED;
};

type TClearFormAction = {
  type: FormActions.CLEAR;
};

export type TFormActions =
  | TLoadFormAction
  | TInitFieldAction
  | TUpdateValueAction
  | TSubmitFormAction
  | TSaveFormAction
  | TValidatedFormAction
  | TSetDisabledAction
  | TSetLoadingAction
  | TSetPropAction
  | TValidateFormAction
  | TSetValidationErrors
  | TSetErrors
  | TResetFormAction
  | TStoreFormAction
  | TClearFormAction;
