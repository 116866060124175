import React from 'react';
import { IPanelContainerBlock } from '@brainstud/course-blocks';
import { Panel } from '@brainstud/ui';
import { ContentEditor } from '../../../ContentEditor/ContentEditor';
import styles from './Panel.module.css';

export type PanelFormProps = {
  data: IPanelContainerBlock;
};

/**
 * PanelForm.
 *
 * Create and edit Panel blocks.
 */
export const PanelForm = ({ data }: PanelFormProps) => (
  <Panel className={styles.base}>
    <ContentEditor
      containerId={data.id}
      className={`${styles.editor} panel-editor`}
      blocks={data.blocks || []}
      containerType={data.type}
    />
  </Panel>
);
