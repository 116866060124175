import { useState } from 'react';
import { useAssistantCoach, useCoaches, UUID } from '@brainstud/academy-api';
import { Button, Dropdown } from '@brainstud/ui';
import { ArrowForward } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Loading } from 'Components';
import { useTranslator } from 'Providers/Translator';
import { ReactComponent as Plus } from '../../Assets/plus.svg';
import styles from './CoachAssistant.module.css';

const cx = classNames.bind(styles);

type Props = {
  courseId: UUID;
  learningObjectCollectionId: UUID;
};

const CoachAssistantAdd = ({ courseId, learningObjectCollectionId }: Props) => {
  const [t] = useTranslator();
  const [selectedCoach, setSelectedCoach] = useState<string | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(false);

  const [{ data: coaches }] = useCoaches({
    filter: {
      course: courseId,
    },
  });
  const [{ create }] = useAssistantCoach();

  const couple = async () => {
    setLoading(true);
    await create
      .mutateAsync({
        course: courseId,
        learning_object_collection: learningObjectCollectionId,
        assistant_account: selectedCoach,
      })
      .then(() => {
        setSelectedCoach(undefined);
        setLoading(false);
      });
  };

  return (
    <div className={cx('root', 'add')}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <figure className={styles.avatar}>
            <Plus />
          </figure>
          <Dropdown
            onChange={(value) => setSelectedCoach(value?.toString())}
            value={selectedCoach}
            placeholder={t('coaches.select')}
            block
            searchable={coaches.length > 5}
          >
            {coaches.map((coach) => (
              <Dropdown.Option key={coach.id} value={coach.id}>
                {[coach.firstName, coach.lastNameInsertion, coach.lastName]
                  .filter(Boolean)
                  .join(' ')}
              </Dropdown.Option>
            ))}
          </Dropdown>
        </>
      )}
      <Button
        type="button"
        disabled={!selectedCoach}
        quiet
        onClick={couple}
        loading={loading}
      >
        {t('coaches.add')}
        <ArrowForward />
      </Button>
    </div>
  );
};

export default CoachAssistantAdd;
