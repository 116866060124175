import {
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslator } from 'Providers/Translator';
import { isPresent } from 'ts-is-present';
import { FormContext } from './FormContext';
import { IFormContext, TPrimitive } from './Types';

type TFieldConfig = {
  /** A unique identifier for this field */
  id?: string;
  /** A name to identify a certain field or group of fields */
  name?: string;
  /** Insert a controlled value. Must be combined with an onChange handler to track the changes */
  value?: string;
  /** Insert an uncontrolled value, will follow normal rules of form input */
  defaultValue?: string;
  /** Insert a controlled checked state. Must be combined with an onChange handler to track changes */
  checked?: boolean;
  /** Insert an uncontrolled checked state, which follows normal behavior of a form input, but cannot be changed dynamically after mount */
  defaultChecked?: boolean;
  /** Provide the validation rules applicable to this content field */
  rules?: string | Array<null | undefined | string>;
};

/**
 * useFormField.
 *
 * With this hook, you can connect an input to the Universal Form component. This can be either controlled or uncontrolled.
 *
 * If _controlled_, you are responsible for the correct value/checked state. Therefore, an onChange handler is mandatory!
 *
 * If _uncontrolled_, the value is set on mount, but after that, it is handled by the HTML engine. You cannot control the
 * value of the input from that point.
 *
 * **Do note:** The actual HTMLInputElement _might_ be controlled regardless of the Input _component_ to ensure synchronized
 * state with the Form element (in case the Form state wants to change the HTMLInputElement value).
 *
 * Example usage:
 *
 * ```
 *   const identifier = typeof id === 'number' ? `${name}_${id}` : (id || name);
 *   const { value: controlledValue, setValue, field, locked, valid } = useFormField({
 *     id: name ? identifier : undefined,
 *     name,
 *     value,
 *     defaultValue,
 *     rules,
 *   });
 *
 *   <input value={controlledValue} onChange={(e) => setValue(e.target.value) />}
 * ```
 */
export function useFormField<TValue extends TPrimitive = TPrimitive>({
  id,
  name,
  value,
  defaultValue = '',
  checked,
  defaultChecked,
  rules,
}: TFieldConfig) {
  const [t] = useTranslator();
  const isCheckable = checked !== undefined || defaultChecked !== undefined;
  const isControlled = value !== undefined;

  const [controlledValue, setControlledValue] = useState<TPrimitive>(
    isControlled ? value : defaultValue
  );
  const [controlledChecked, setControlledChecked] = useState<boolean>(
    isCheckable ? !!defaultChecked : true
  );

  const { initialize, update, fields, feedback, subscribe, disabled } =
    useContext<null | IFormContext>(FormContext) || {};
  const field = !!id && fields?.find((item) => item.id === id);
  const fieldValue = field && field?.value;
  const isFormInitialized = !!field;

  // Initialize the input on the Form component
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (!!id && !field && !!initialize && !initialized) {
      setInitialized(true);
      initialize(
        id,
        (name || id).replace('[]', ''),
        controlledChecked ? controlledValue : null,
        Array.isArray(rules) ? rules.filter(isPresent) : rules?.split('|'),
        !!name?.endsWith('[]')
      );
    }
  }, [
    id,
    name,
    controlledChecked,
    controlledValue,
    rules,
    initialize,
    field,
    initialized,
  ]);

  // Provide a setter for setting the controlled value onChange
  const setControlledValueHandler = useCallback(
    (newValue: SetStateAction<TPrimitive>) => {
      setControlledValue(newValue);
      if (!!id && !!update && !isControlled) {
        update(id, newValue);
      }
    },
    [id, update, isControlled]
  );

  // When uncontrolled, update controlledValue when fieldValue changes
  useEffect(() => {
    if (!isControlled && !!fieldValue && fieldValue !== controlledValue) {
      setControlledValue(controlledChecked ? fieldValue : null);
    }
  }, [fieldValue, controlledValue, isControlled, controlledChecked]);

  // Provide a setter for setting the controlled checked state onChange
  const setControlledCheckedHandler = useCallback(
    (newChecked: boolean) => {
      if (!!id && !!update) {
        update(id, newChecked ? controlledValue : null);
      }
      setControlledChecked(newChecked);
    },
    [id, update, controlledValue]
  );

  // When uncontrolled, update controlledChecked when fieldValue changes
  useEffect(() => {
    const expectedChecked = fieldValue !== null;
    if (
      !isControlled &&
      isCheckable &&
      isFormInitialized &&
      controlledChecked !== expectedChecked
    ) {
      setControlledChecked(expectedChecked);
    }
  }, [
    fieldValue,
    isControlled,
    isCheckable,
    controlledChecked,
    isFormInitialized,
  ]);

  // When controlled checked state, update both controlledChecked & formValue on change
  useEffect(() => {
    if (checked !== undefined) {
      setControlledChecked(checked);
      const newValue = checked ? controlledValue : null;
      if (!!id && !!update && fieldValue !== newValue) {
        update(id, newValue);
      }
    }
  }, [checked, controlledValue, fieldValue, id, update]);

  // When controlled value state, update both controlledValue & formValue on change
  useEffect(() => {
    if (isControlled) {
      setControlledValue(value);
      if (fieldValue !== value && !!id) {
        update?.(id, value);
      }
    }
  }, [value, update, id, isControlled, fieldValue]);

  useEffect(() => {
    if (subscribe) {
      return subscribe('reset', () => {
        setControlledValue(defaultValue);
        setControlledChecked(isCheckable ? !!defaultChecked : true);
      });
    }
  }, [isCheckable, subscribe, defaultValue, defaultChecked]);

  return {
    isFormConnected: !!id && fields !== undefined,
    value: controlledValue as undefined | TValue,
    setValue: setControlledValueHandler,
    checked: controlledChecked,
    setChecked: setControlledCheckedHandler,
    field,
    valid:
      field && field.evaluated && feedback === 'full'
        ? field?.valid
        : undefined,
    locked: (field && field.locked) || disabled,
    messages:
      !!field &&
      field?.messages?.map((item) => t(`validation.${item}`, undefined, item)),
  };
}
