import { useStudentGroup, UUID } from '@brainstud/academy-api';
import { Button, ConfirmationModal } from '@brainstud/ui';
import { Loading } from 'Components';
import { useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import classes from './DeleteGroupModal.module.css';

type Props = {
  studentGroupId: UUID;
  closeModal: () => void;
};

export const DeleteGroupModal = ({ studentGroupId, closeModal }: Props) => {
  const [t] = useTranslator();
  const [setToast] = useToaster();
  const [
    { data: studentGroup, destroy: deleteGroup },
    { isLoading, isFetching },
  ] = useStudentGroup({
    studentGroup: studentGroupId,
  });

  const handleSubmit = async () => {
    await deleteGroup.mutateAsync();
    setToast(
      t('modals.studentGroup.deleteGroup.toast.confirm', {
        groupName: studentGroup?.name,
      }),
      'success'
    );
    closeModal();
  };

  return (
    <ConfirmationModal
      onClickOutside
      closeModal={closeModal}
      className={classes.root}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <h3>{t('modals.studentGroup.deleteGroup.title')}</h3>

          <p>
            {t('modals.studentGroup.deleteGroup.body', {
              groupName: studentGroup?.name,
            })}
          </p>

          <div style={{ marginTop: 40 }}>
            <Button
              type="button"
              onClick={handleSubmit}
              loading={isFetching}
              style={{ marginRight: '1rem' }}
            >
              {t('modals.studentGroup.deleteGroup.confirm')}
            </Button>
            <Button type="button" quiet onClick={closeModal}>
              {t('modals.studentGroup.deleteGroup.cancel')}
            </Button>
          </div>
        </>
      )}
    </ConfirmationModal>
  );
};
