import { UseQueryOptions } from 'react-query';
import { useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import { ExamStepApproval, ExamStepApprovalCreate, UUID } from '../Types';

interface IExamStepApprovalProps {
  examStepApproval?: UUID;
  exam?: UUID;
}

export function useExamStepApproval(
  { exam, examStepApproval, ...queryParameters }: IExamStepApprovalProps = {},
  settings?: UseQueryOptions<DataDocument<ExamStepApproval>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: {
      examStepApproval: defaultExamStepApproval,
      exam: defaultExam,
    },
  } = context;
  const request = new ApiRequest(
    {
      enabled:
        !!(examStepApproval || defaultExamStepApproval) &&
        !!(exam || defaultExam),
      baseName: 'v1.examStepApprovals',
      baseUri: `/v1/exams/${exam || defaultExam}/step_approvals`,
      invalidate: ['step_approvals', 'step_approval', 'exam', 'exams'],
      uri: `/${examStepApproval || defaultExamStepApproval}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<ExamStepApproval, ExamStepApprovalCreate, void>(
    request,
    settings
  );
}
