import { ReactNode } from 'react';
import { AccountTabs } from '@brainstud/account-panel/Layouts';
import { useHeadProvider } from '../../Providers';

type Props = {
  children?: ReactNode;
};

/**
 * AccountLayout.
 *
 *
 */
export const AccountLayout = ({ children }: Props) => {
  useHeadProvider('titles.account.main');
  return <AccountTabs>{children}</AccountTabs>;
};
