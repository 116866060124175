import React, { CSSProperties, PropsWithChildren } from 'react';
import classNames from 'classnames/bind';
import styles from './OnboardingTooltip.module.css';

const classes = classNames.bind(styles);

type Props = {
  arrow: 'left' | 'right';
  style?: CSSProperties;
  className?: string;
};

/**
 * OnboardingTooltip.
 *
 * Adds basic tooltip styling to the component for rendering an onbooarding tooltip.
 */
export const OnboardingTooltip = ({
  arrow,
  className,
  style,
  children,
}: PropsWithChildren<Props>) => (
  <div className={classes(styles.base, arrow, className)} style={style}>
    {children}
  </div>
);
