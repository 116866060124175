import { useEffect, useMemo } from 'react';
import {
  useApi,
  useEnrollmentLearningObjectCollection,
  useMe,
} from '@brainstud/academy-api';
import { STUDENT_ROLES } from 'Config/roles';
import {
  useEnrollmentByCourseId,
  useHasAnyRole,
  useSessionStorage,
} from 'Hooks';
import { useRouter } from 'next/router';
import { useModals } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import IntroductionModal from '../Modals/IntroductionModal';

function useCourseInitialization() {
  const [me] = useMe();
  const router = useRouter();
  const isStudent = useHasAnyRole(STUDENT_ROLES);
  const { courseId, collectionId } = router.query as {
    courseId: string;
    collectionId?: string;
  };
  const { setRouteParameters } = useApi();

  const { isLoading, enrollment, course, isError } =
    useEnrollmentByCourseId(courseId);

  const contract = useMemo(() => me?.contract?.(), [me]);

  const [t] = useTranslator();
  useEffect(() => {
    if (
      isStudent &&
      contract &&
      contract.parameters?.mrChadd &&
      course?.metadata?.features?.mrChadd
    ) {
      const script = document.createElement('script');
      script.setAttribute('src', 'https://c.mrchadd.nl/embed.js');
      document.body.appendChild(script);

      window.addEventListener('mrchadd.loaded', () => {
        window.setTimeout(() => {
          // @ts-ignore This is added by an external script
          window.mrchadd.showMessage(
            t('mr_chadd.question_about', { topic: course.title }),
            t('mr_chadd.ask_coaches')
          );
        }, 1000);
      });
    }
  }, [contract, course, isStudent, t]);

  useEffect(() => {
    setRouteParameters((prevParameters) => ({
      ...prevParameters,
      learningObjectCollection: collectionId,
      ...(enrollment
        ? {
            enrollment: enrollment.id,
            learningObjectCollection:
              enrollment.learningObjectCollection?.().id,
          }
        : {}),
      course: courseId,
    }));
    return () => {
      setRouteParameters((prevParameters) => ({
        ...prevParameters,
        enrollment: undefined,
        learningObjectCollection: undefined,
        course: undefined,
      }));
    };
  }, [enrollment, setRouteParameters, courseId, collectionId]);

  const [{ data: collection }] = useEnrollmentLearningObjectCollection({
    include: [
      'learning_objects.conditions',
      'learning_routes.learning_route_nodes.conditions',
      'learning_subjects.quiz',
      'learning_subjects.learning_objects',
    ],
  });
  const { openModal } = useModals();
  const [introductionShown, setIntroductionShown] = useSessionStorage(
    `${courseId}_intro_modal_shown`,
    false
  );
  useEffect(() => {
    if (
      enrollment &&
      collection &&
      collection.onboarding &&
      !introductionShown
    ) {
      const steps = Array.isArray(collection.onboarding)
        ? collection.onboarding
        : collection.onboarding.steps;
      // @ts-ignore FIXME Replace with version when loc.onboarding contains version everywhere
      const version = Array.isArray(collection.onboarding)
        ? 'v1.0.0'
        : collection.onboarding.version || 'v1.0.0';
      if (steps.length > 0) {
        const collectionIntroModal = enrollment.parameters.find(
          (param) => param.property === 'loc_intro_modal'
        );
        if (collectionIntroModal?.payload !== version) {
          setIntroductionShown(true);
          openModal(IntroductionModal, { collection });
        }
      }
    }
  }, [
    collection,
    enrollment,
    introductionShown,
    openModal,
    setIntroductionShown,
  ]);

  return [
    {
      me,
      enrollment,
      course,
      collection,
    },
    {
      isLoading,
      isError,
    },
  ];
}

export default useCourseInitialization;
