import React, { PropsWithChildren } from 'react';
import { AnswerReviewNavigation } from 'Modules/coach-panel/Views/Review';
import { useRouter } from 'next/router';
import {
  LearningObjectProvider,
  useBreadcrumbs,
  useHeadProvider,
} from 'Providers';
import { AnswerReviewLayout } from './AnswerReviewLayout';

type Props = {
  title?: string;
};

export const ExamSamplesetReviewLayout = ({
  title,
  children,
}: PropsWithChildren<Props>) => {
  useHeadProvider(title || 'titles.coach.student.review.answer');

  const router = useRouter();
  const { studentId, enrollmentId } = router.query as {
    studentId: string;
    enrollmentId: string;
  };

  useBreadcrumbs(
    {
      exams: {
        href: `/coach/students/${studentId}/enrollment/${enrollmentId}/exam`,
      },
      review: {
        href: `/coach/students/${studentId}/enrollment/${enrollmentId}/exam`,
      },
      learningObjectId: null,
    },
    [studentId, enrollmentId]
  );

  return (
    <LearningObjectProvider>
      <AnswerReviewLayout>
        <AnswerReviewNavigation />
        {children}
      </AnswerReviewLayout>
    </LearningObjectProvider>
  );
};
