import { useContext } from 'react';
import { ITableContext, TableContext } from './TableContext';

/**
 * The provider is used to communicate between the components within a Table
 */
export function useTableProvider<T extends {} = {}>() {
  const context = useContext<null | ITableContext<T>>(TableContext);
  if (!context) {
    throw new Error(
      'Use the Table subcomponents only within the Table component!'
    );
  }
  return context;
}
