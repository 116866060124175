import React, { CSSProperties, PropsWithChildren } from 'react';
import { useEnrollment } from '@brainstud/academy-api';
import { AnswerReviewProvider } from '@brainstud/coach-panel/Providers';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';
import { AnswerProvider } from 'Providers/AnswerProvider';
import { useDfnTags } from '../../Hooks';
import styles from './AnswerReviewLayout.module.css';

const cx = classNames.bind(styles);

type Props = {
  style?: CSSProperties;
  className?: string;
};

/**
 * AnswerReviewLayout.
 *
 * Contains the main layout of the AnswerReview pages.
 */
export const AnswerReviewLayout = ({
  className,
  style,
  children,
}: PropsWithChildren<Props>) => {
  const router = useRouter();
  const { enrollmentId } = router.query as {
    enrollmentId: string;
    learningObjectId: string;
  };

  const [{ data: enrollment }] = useEnrollment({ enrollment: enrollmentId });

  useDfnTags(enrollment?.course?.().id);

  return (
    <main className={cx(styles.base, className)} style={style}>
      <AnswerProvider>
        <AnswerReviewProvider enrollmentId={enrollmentId}>
          {children}
        </AnswerReviewProvider>
      </AnswerProvider>
    </main>
  );
};
