import { PropsWithChildren } from 'react';
import classNames from 'classnames/bind';
import styles from './ActivityTitle.module.css';

const cx = classNames.bind(styles);

type TProps = PropsWithChildren<{ id?: string; className?: string }>;

export const ActivityTitle = ({ children, id, className }: TProps) => (
  <div className={cx(styles.base, className)} data-activity-id={id}>
    {children}
  </div>
);
