import React from 'react';
import className from 'classnames/bind';
import styles from './ProgressStepper.module.css';

export type ProgressStepperProps = {
  step: number;
  length: number;
};

const cx = className.bind(styles);

const ProgressStepper = ({ step, length }: ProgressStepperProps) => (
  <ol className={cx('stepper')}>
    {[...Array(length)].map((_, i) => (
      /* eslint-disable-next-line react/no-array-index-key */
      <li key={i} className={cx({ active: i + 1 <= step })}>
        {i}
      </li>
    ))}
  </ol>
);

export default ProgressStepper;
