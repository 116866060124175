/**
 * getEnumValues
 *
 * Returns a list with the enum's values as typed values. Removes all indexes (numbers).
 */
type TEnumGenericList = { [key: string]: unknown };

type EnumObject<T> = { [key: string]: T };

export function getEnumValues<T extends string | number>(
  enumObject: EnumObject<T>
): T[] {
  return Object.values(enumObject).filter((key) => !Number.isInteger(key));
}

/**
 * stringToEnumValue
 *
 * Maps a string key to the typed corresponding enum value.
 */
export function stringToEnumValue<T = TEnumGenericList>(
  enumObject: T,
  key?: string
): T[keyof T] {
  if (key === undefined) {
    throw new Error(`Invalid value given to stringToEnumValue ${key}`);
  }
  return enumObject[key?.toUpperCase() as keyof typeof enumObject];
}
