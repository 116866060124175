import React, { PropsWithChildren } from 'react';
import { Panel } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { Container } from 'Components';
import { useTranslator } from 'Providers/Translator';
import styles from './AuthTwoFactorLoginLayout.module.css';

const cx = classNames.bind(styles);

type Props = {
  className?: string;
  title?: string;
};
/**
 * TwoFactorLayout.
 * This layout is used on the account/security/2fa pages and setup 2fa after login pages
 *
 */
export const AuthTwoFactorLoginLayout = ({
  className,
  title,
  children,
}: PropsWithChildren<Props>) => {
  const [t] = useTranslator();

  return (
    <Container className={cx(styles.base, className)}>
      <Panel className={cx(styles.panel)}>
        <h2>{t(title || 'layouts.auth.two_factor_login.title')}</h2>
        {children}
      </Panel>
    </Container>
  );
};
