import { CSSProperties, FC, useRef, useState } from 'react';
import { useOnClickOutside } from '@brainstud/universal-components';
import { MoreVert } from '@mui/icons-material';
import classNames from 'classnames/bind';
import styles from './ActionMenu.module.css';

const cx = classNames.bind(styles);

interface ActionMenuProps {
  /** A unique identifier used for aria linking */
  id: string;
  className?: string;
  style?: CSSProperties;
}

/**
 * Button that shows a dropdown menu with options
 */
export const ActionMenu: FC<ActionMenuProps> = ({
  id,
  className,
  style,
  children,
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside([ref], () => setOpen(false));

  return (
    <div className={cx(styles.root, className)} ref={ref} style={style}>
      <button
        type="button"
        className={cx(styles.button)}
        onClick={() => setOpen((prevOpen) => !prevOpen)}
        aria-controls={id}
        aria-haspopup="true"
      >
        <MoreVert fontSize="large" />
      </button>
      {open && (
        <div id={id} className={styles.popover} role="menu">
          {children}
        </div>
      )}
    </div>
  );
};
