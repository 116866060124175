/**
 * toDecimals.
 *
 * Rounds a number to the specified number of decimals. Zeroes are left out (i.e. 50.004 with 1 decimal becomes 50).
 */
export function toDecimals(
  input: number | string | undefined | null,
  decimals: number
) {
  if (!input) return 0;
  return Number(
    `${Math.round(parseFloat(`${input}e+${decimals}`))}e${-decimals}`
  );
}
