import { AxiosError, AxiosResponse } from 'axios';
import { TError } from './ErrorDocument/types';
import { TJsonApi, TLinks, TMeta } from './types';

export type TErrorResponse = {
  errors: TError | TError[];
};

export default class JsonDocument {
  public statusText: string;

  public statusCode: number;

  public meta?: TMeta;

  public jsonapi?: TJsonApi;

  public links?: TLinks;

  constructor(result: Partial<AxiosResponse | AxiosError>) {
    if ('data' in result) {
      this.statusText = result.statusText || 'OK';
      this.statusCode = result.status || 200;
      this.meta = result.data?.meta;
      this.jsonapi = result.data?.jsonapi;
      this.links = result.data?.links;
    } else if (
      'response' in result &&
      JsonDocument.isApiResponse(result.response)
    ) {
      this.statusText =
        result.status || result.code || 'Action response not expected';
      this.statusCode = result.response.status;
      this.meta = result.response.data?.meta as TMeta;
      this.jsonapi = result.response.data?.jsonapi as TJsonApi;
      this.links = result.response.data?.links as TLinks;
    } else {
      this.statusText = 'No response received';
      this.statusCode = 408;
    }
  }

  protected static isApiResponse(
    response?: AxiosResponse
  ): response is AxiosResponse<Record<string, unknown>> {
    return (
      !!response &&
      'data' in response &&
      typeof response.data === 'object' &&
      !!response.data
    );
  }

  protected static isApiErrorResponse(
    response?: AxiosResponse
  ): response is AxiosResponse<TErrorResponse> {
    return this.isApiResponse(response) && 'errors' in response.data;
  }
}
