import { useMemo } from 'react';
import { useMe, usePortalCourses } from '@brainstud/academy-api';

type Output = {
  /** Whether the subscription model is present and a subscription is required for the coach */
  isSubscriptionRequired: boolean;
  /** To check if user has had subscription before */
  hasHadActiveSubscription: boolean;
  /** Whether there currently is an active subscription */
  isSubscriptionActive: boolean;
  /** Whether there currently is a promotion available */
  subscriptionPromotionAvailable: boolean;
};

/**
 * useActiveSubscription.
 *
 * A hook to determine whether resources should be locked due to invalid subscription
 */
export function useActiveSubscription(): Output {
  const [me] = useMe();
  const [{ data: courses }] = usePortalCourses();

  const isSubscriptionRequired =
    !!me &&
    !me?.availableBusinessModels?.includes('flat_fee') &&
    (me.availableBusinessModels?.includes('subscription') ||
      me.availableBusinessModels?.includes('in_plan'));

  const isSubscriptionActive = !!me?.subscription?.().isActive;

  const hasEnrollments = useMemo(
    () => !!courses?.filter((course) => course.enrollment?.()).length,
    [courses]
  );

  return {
    isSubscriptionRequired,
    hasHadActiveSubscription: hasEnrollments,
    isSubscriptionActive,
    subscriptionPromotionAvailable: !!me?.subscriptionPromotionAvailable,
  };
}
