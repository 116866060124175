import { UseQueryOptions } from 'react-query';
import { useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  SlaProduct,
  SlaProductCreateInput,
  SlaProductIncludes,
  SlaProductsFilters,
  SlaProductsSort,
  SlaProductUpdateInput,
  UUID,
} from '../Types';

interface IProductProps
  extends HookParams<
    typeof SlaProductIncludes,
    typeof SlaProductsFilters,
    typeof SlaProductsSort
  > {
  sla_product?: UUID;
}

export function useSlaProduct(
  { sla_product, ...queryParameters }: IProductProps = {},
  settings?: UseQueryOptions<DataDocument<SlaProduct>, ErrorDocument>
) {
  const context = useApi();

  const {
    parameters: { sla_product: defaultProduct },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(sla_product || defaultProduct),
      baseName: 'v1.sla_products',
      baseUri: '/v1/sla_products',
      invalidate: ['sla_product', 'products', 'v1.products'],
      uri: `/${sla_product}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    SlaProduct,
    SlaProductCreateInput,
    SlaProductUpdateInput
  >(request, settings);
}
