import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  TEmitFn,
  TEventHandlerFn,
  TEventsObject,
  TSubscribeFn,
} from './EventHandlerTypes';

type TEvent<
  TEvents extends TEventsObject,
  EventName extends keyof TEvents = keyof TEvents,
> = {
  name: EventName;
  data: TEvents[EventName];
};

type TSubscriptions<EventNames extends string | number | symbol> = Record<
  EventNames,
  Array<TEventHandlerFn>
>;

/**
 * Creates a custom event handling system in Javascript to execute a set of handlers
 * based on custom subscribed events
 */
export function useEventHandler<TEvents extends TEventsObject = any>() {
  const [subscriptions, setSubscription] = useState<
    Partial<TSubscriptions<keyof TEvents>>
  >({});
  const [events, setEvents] = useState<TEvent<TEvents>[]>([]);

  const subscribe = useCallback<TSubscribeFn<TEvents>>((event, handler) => {
    if (typeof handler !== 'function') return () => null;
    setSubscription((prevSubscriptions) => ({
      ...prevSubscriptions,
      [event]: [...(prevSubscriptions[event as string] || []), handler],
    }));
    return () => {
      setSubscription((prevSubscriptions) => ({
        ...prevSubscriptions,
        [event]: (prevSubscriptions[event as string] || []).filter(
          (anyHandler) => anyHandler !== handler
        ),
      }));
    };
  }, []);

  const emit = useCallback<TEmitFn<TEvents>>((name, ...data) => {
    setEvents((prevEvents) => [...prevEvents, { name, data }]);
  }, []);

  useEffect(() => {
    while (events.length > 0) {
      const event = events.shift();
      if (event && event.name in subscriptions) {
        subscriptions[event.name as string]!.forEach((handler) =>
          handler(...event.data)
        );
      }
    }
  }, [events, subscriptions]);

  return useMemo(
    () => ({
      subscribe,
      emit,
    }),
    [subscribe, emit]
  );
}
