import { UseQueryOptions } from 'react-query';
import useIndexCR from '../Factories/useIndexCR';
import useSingleCRUD from '../Factories/useSingleCRUD';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  Schedule,
  ScheduleCreateInput,
  ScheduleFilters,
  ScheduleIncludes,
  ScheduleSorts,
  UUID,
} from '../Types';

interface IScheduleProps
  extends HookParams<
    typeof ScheduleIncludes,
    typeof ScheduleFilters,
    typeof ScheduleSorts
  > {
  schedule?: UUID;
}

export function useSchedule(
  { schedule, ...queryParameters }: IScheduleProps = {},
  settings?: UseQueryOptions<DataDocument<Schedule>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { schedule: defaultSchedule },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(schedule || defaultSchedule),
      baseName: 'v1.schedules',
      baseUri: '/v1/schedules',
      uri: `/${schedule || defaultSchedule}`,
      invalidate: ['schedule', 'schedules'],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    Schedule,
    ScheduleCreateInput,
    Partial<ScheduleCreateInput>
  >(request, settings);
}

export function useSchedules(
  { ...queryParameters }: IScheduleProps = {},
  settings?: UseQueryOptions<DataDocument<Schedule[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.schedules',
      baseUri: '/v1/schedules',
      queryParameters,
    },
    context
  );
  return useIndexCR<Schedule, ScheduleCreateInput>(request, settings);
}
