import { useEffect, useMemo, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { usePortalCourses } from '@brainstud/academy-api';
import { Button } from '@brainstud/ui';
import { Close, PlayCircleFilled, Publish } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Container, Loading } from 'Components';
import { useAccessProvider, useModals } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import {
  EnrollModal,
  InfoModal,
  TeaserModal,
  UnEnrollModal,
} from '../../Modals';
import { Badge } from '../Badge';
import styles from './CourseInfoPanel.module.css';

export interface CourseInfoPanelProps {
  className?: string;
  course: number | string;
  onClose: () => void;
}

const cx = classNames.bind(styles);

export const CourseInfoPanel = ({
  course: courseId,
  className,
  onClose,
}: CourseInfoPanelProps) => {
  const [t] = useTranslator();
  const { openModal } = useModals();
  const { hasAccess } = useAccessProvider();

  const [{ data: courses }, { isLoading }] = usePortalCourses();
  const course = courses.find((c) => c.id === courseId);
  const factsheetUrl = useMemo(
    () => course?.factsheet?.().downloadUrl,
    [course]
  );

  // Added to animate height at render
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(true);
  }, []);

  // Sets default thumbnail and poster if it does not exist
  const defaultThumbnail = '/media/abstract-thumbnail.jpg';
  const defaultPoster = '/media/abstract-banner.jpg';
  const hasThumbnail =
    course?.metadata?.media?.thumbnailUrl?.search(/(.jpg|.png|.jpeg|.webp)/) !==
      -1 || course?.thumbnailUrl?.search(/(.jpg|.png|.jpeg|.webp)/) !== -1;
  const thumbnail = hasThumbnail
    ? course?.metadata?.media?.thumbnailUrl || course?.thumbnailUrl
    : defaultThumbnail;
  const hasPoster =
    course?.metadata?.media?.posterUrl?.search(/(.jpg|.png|.jpeg|.webp)/) !==
      -1 || course?.posterUrl?.search(/(.jpg|.png|.jpeg|.webp)/) !== -1;
  const poster = hasPoster
    ? course?.metadata?.media?.posterUrl || course?.posterUrl
    : defaultPoster;

  const properties = course?.metadata?.informationLines;

  if (isLoading || !course) return <Loading />;

  return (
    <AnimateHeight
      className={cx('base', className)}
      height={open ? 'auto' : 0}
      duration={500}
      style={{ margin: open ? '10px 0' : 0 }}
    >
      <img alt="" className={styles.background} src={poster} />
      <Container className={styles.container}>
        <figure className={styles.thumbnail}>
          <img src={thumbnail} alt="" />
        </figure>
        {course.metadata?.media?.teaserUrl && (
          <Button
            type="button"
            className={styles.teaserButton}
            link
            onClick={() =>
              openModal(TeaserModal, {
                teaserUrl: course?.metadata?.media?.teaserUrl,
              })
            }
          >
            <PlayCircleFilled
              style={{ fontSize: 32, color: 'rgb(var(--primary-color))' }}
            />
            {t('components.catalog.view_trailer')}
          </Button>
        )}
        <div className={styles.content}>
          <div className={styles.badges}>
            {course.metadata?.informationLines?.sbu && (
              <Badge color="black">{`${course.metadata.informationLines.sbu} SBU`}</Badge>
            )}

            {course.metadata?.informationLines?.learningObjectsCount && (
              <Badge color="green">
                {`${course.metadata.informationLines.learningObjectsCount} ${t('components.catalog.assignments')}`}
              </Badge>
            )}

            {course.metadata?.informationLines?.validatedCertifications && (
              <Badge color="aqua">
                {t('components.catalog.validatedExam')}
              </Badge>
            )}

            {course?.opener === 'scorm' && (
              <Badge color="white">
                {t('components.catalog.external_content')}
              </Badge>
            )}
          </div>
          <Button type="button" link className={styles.close} onClick={onClose}>
            <Close style={{ fontSize: '20px' }} />
          </Button>
          {course?.metadata?.informationLines?.original && (
            <div className={cx(styles.original)}>
              <strong>{course?.metadata?.informationLines?.original}</strong>
              ORIGINAL
            </div>
          )}
          <h1 className={cx(styles.title)}>{course.title}</h1>
          <div
            dangerouslySetInnerHTML={{ __html: sanitizer(course.description) }}
          />
          {course.metadata?.informationLines?.supplies && (
            <section className={styles.supplies}>
              <strong>{t('components.catalog.supplies')}</strong>
              <p>{course.metadata?.informationLines?.supplies}</p>
            </section>
          )}

          <div>
            {(course.educationProfiles?.() || []).map((profile) => (
              <Badge key={profile.id} color="transparent">
                {profile.title}
              </Badge>
            ))}
          </div>

          <div className={styles.actions}>
            <Button
              type="button"
              outline
              className={styles.actionButtons}
              onClick={() => openModal(InfoModal, { course })}
            >
              <span>{t('components.catalog.knowMore')}</span>
            </Button>
            {course.enrollment?.() ? (
              <Button
                type="button"
                error
                className={styles.actionButtons}
                onClick={() => openModal(UnEnrollModal, { course })}
              >
                <span>{t('components.catalog.removeFromCatalog')}</span>
              </Button>
            ) : (
              <Button
                type="button"
                className={styles.actionButtons}
                onClick={() => openModal(EnrollModal, { course })}
                disabled={!hasAccess}
              >
                <span>{t('components.catalog.addToCatalog')}</span>
              </Button>
            )}
            {factsheetUrl && (
              <Button
                type="button"
                outline
                className={styles.actionButtons}
                href={factsheetUrl}
                style={{ marginLeft: 'auto' }}
              >
                <Publish style={{ transform: 'rotate(180deg)' }} />
                <span>{t('components.catalog.downloadFactsheet')}</span>
              </Button>
            )}
          </div>
          <aside>
            {properties?.dossiers && (
              <p>{`${t('components.catalog.files')}: ${properties.dossiers}`}</p>
            )}
            {properties?.developers && (
              <p>{`${t('components.catalog.developers')}: ${properties.developers}`}</p>
            )}
            {properties?.genre && (
              <p>{`${t('components.catalog.genre')}: ${properties.genre}`}</p>
            )}
          </aside>
        </div>
      </Container>
    </AnimateHeight>
  );
};
