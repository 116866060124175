import { Callout } from '@brainstud/ui';
import { useAccessProvider } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import styles from './SubscriptionCallout.module.css';

const SubscriptionCallout = () => {
  const [t] = useTranslator();
  const { hasAccess, hasHadActiveSubscription } = useAccessProvider();

  return hasAccess ? null : (
    <Callout info className={styles.base}>
      {t(
        `components.subscriptionCallout.${hasHadActiveSubscription ? 'extend' : 'start'}`
      )}
    </Callout>
  );
};

export default SubscriptionCallout;
