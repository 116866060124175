import React, { ReactNode } from 'react';
import { Clear, Done, DoneAll, Lock } from '@mui/icons-material';
import { TStatus } from 'Components/Cards/LearningCard/LearningCard';
import { useTranslator } from 'Providers/Translator';
import { ReactComponent as NfcRestricted } from './icons/nfc-restricted.svg';
import styles from './LearningObjectStatus.module.css';

type Props = {
  status: TStatus;
  label: string;
};

const icons: Record<TStatus, ReactNode> = {
  ERROR: <Clear />,
  LOCKED: <Lock />,
  SUCCESS: <DoneAll />,
  WARNING: <Done />,
  NFC_RESTRICTED: <NfcRestricted />,
  NONE: null,
};

/**
 * LearningObjectStatus.
 *
 * Shows an approved/rejected/turned/locked message with icon based on learning object progress status
 */
export const LearningObjectStatus = ({ status, label }: Props) => {
  const [t] = useTranslator();
  const icon: ReactNode = icons[status] ?? null;

  return label || icon ? (
    <div className={styles.base}>
      {icon}
      {label && <span className={styles.label}>{t(label)}</span>}
    </div>
  ) : null;
};
