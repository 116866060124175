import React, { useMemo } from 'react';
import { LearningSubject } from '@brainstud/academy-api';
import { Button } from '@brainstud/ui';
import { ArrowBack, ArrowForward, Home } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';
import { TNodeListItem } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import styles from './FooterNavigation.module.css';

const cx = classNames.bind(styles);

type Props = {
  nextItem?: TNodeListItem | LearningSubject;
  previousItem?: TNodeListItem;
  currentItem?: TNodeListItem;
  rootSegment?: string;
  /** Display a home button to navigate to the root route */
  home?: boolean;
  /** Overwrite the default action with a handle */
  onClickNext?: (uri: {
    nextUri: string;
    rootUri: string;
    previousUri: string;
  }) => void;
  onClickPrevious?: (uri: {
    nextUri: string;
    rootUri: string;
    previousUri: string;
  }) => void;
};
/**
 * FooterNavigation.
 *
 * Adds next and previous buttons to navigate through the learning route.
 */
export const FooterNavigation = ({
  nextItem,
  previousItem,
  currentItem,
  rootSegment,
  home = true,
  onClickNext,
  onClickPrevious,
}: Props) => {
  const router = useRouter();
  const [t] = useTranslator();

  const uriSegmentConfig = useMemo(
    () => ({
      learning_route_nodes: 'chapters',
      learning_subjects: 'subjects',
      learning_objects: 'detail',
      root: '',
    }),
    []
  );

  const currentItemUri = `${uriSegmentConfig[currentItem?.resourceType || 'root']}/${currentItem?.id || ''}`;
  const rootUri = router.asPath.replace(currentItemUri, rootSegment || '');
  const previousUri = previousItem
    ? router.asPath.replace(
        currentItemUri,
        `${uriSegmentConfig[previousItem.resourceType]}/${previousItem.id}`
      )
    : rootUri;
  const nextUri = nextItem
    ? router.asPath.replace(
        currentItemUri,
        `${uriSegmentConfig[nextItem.resourceType]}/${nextItem.id}`
      )
    : rootUri;

  return (
    <div className={cx(styles.base)}>
      {home && (
        <Button outline to={rootUri}>
          <Home />
          <span>{t('views.intro_view.home')}</span>
        </Button>
      )}
      <div className={cx(styles.group, { home })}>
        {(previousItem || !home) && (
          <Button
            type="button"
            disabled={!previousItem && !home}
            outline
            onClick={
              onClickPrevious
                ? () => onClickPrevious({ rootUri, nextUri, previousUri })
                : undefined
            }
            to={!onClickPrevious ? previousUri : undefined}
          >
            <ArrowBack />
            <span>{t('previous')}</span>
          </Button>
        )}
        <Button
          type="button"
          outline
          onClick={
            onClickNext
              ? () => onClickNext({ rootUri, nextUri, previousUri })
              : undefined
          }
          to={!onClickNext ? nextUri : undefined}
        >
          <span>{t(nextItem ? 'next' : 'finish')}</span>
          <ArrowForward />
        </Button>
      </div>
    </div>
  );
};
