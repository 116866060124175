import { useReducer } from 'react';
import { createReactReducer } from 'Utils';
import {
  getFieldWithEvaluation,
  getFormValues,
  getValidatedFormState,
} from '../FormUtils';
import { FormTranslations } from '../translations';
import { FormTranslator } from '../Translator/FormTranslator';
import type { IFormReducerState, TField } from '../Types';
import { FormValidator } from '../Validator/FormValidator';
import { FormActions, TFormActions } from './FormReducerActions';

const FormInitialState: IFormReducerState = {
  empty: true,
  loading: true,
  names: [],
  fields: [],
  defaultFields: {},
  disabled: false,
  dirty: false,
  validated: false,
  feedback: 'standard',
  validator: FormValidator,
  translator: FormTranslator,
  translations: FormTranslations,
  language: 'en',
};

const FormReducer = createReactReducer<IFormReducerState, TFormActions>({
  [FormActions.LOAD]: ({ payload: { validate, ...payload } }, state) => {
    const fields = [
      ...(payload.fields || []),
      ...Object.values(state.defaultFields).filter(
        (defaultField) =>
          !payload.fields?.some((field) => defaultField.id === field.id)
      ),
    ];
    const values = getFormValues(fields);
    return {
      ...payload,
      fields: fields.map((item) => ({
        ...item,
        dirty: false,
        ...(!!validate && item.evaluated
          ? {
              ...getFieldWithEvaluation(
                state,
                item,
                state.validator(item, values[item.name])
              ),
            }
          : {}),
      })),
      empty: fields.length > 0,
      dirty: false,
      loading: false,
      error: undefined,
      loaded: values,
    };
  },
  [FormActions.CLEAR]: () => FormInitialState,
  [FormActions.INIT_FIELD]: ({ payload }, state) => {
    const field = state.fields.find((item) => item.id === payload.id);
    return {
      empty: false,
      names: !state.names.includes(payload.name)
        ? [...state.names, payload.name]
        : state.names,
      ...(!field
        ? {
            fields: [...state.fields, payload],
            defaultFields: {
              ...state.defaultFields,
              [payload.id]: { ...payload },
            },
          }
        : {}),
    };
  },
  [FormActions.UPDATE_VALUE]: ({ payload }, state) => {
    const field = state.fields.find((item) => item.id === payload.id);
    const idProp = !field?.isArray ? 'name' : 'id';
    return !field || state.disabled
      ? {}
      : {
          dirty: state.loading ? state.dirty : true,
          fields: state.fields.reduce<TField[]>((fields, item) => {
            const normalizedValue =
              typeof payload.value === 'function'
                ? payload.value(field.value)
                : payload.value;
            const value =
              field.isArray || item.id === field.id ? normalizedValue : null;
            return [
              ...fields,
              field[idProp] === item[idProp]
                ? {
                    ...item,
                    value,
                    dirty: value !== state.defaultFields[item.id]?.value,
                  }
                : item,
            ];
          }, []),
        };
  },
  [FormActions.SAVE]: ({ payload }, state) => ({
    saved: {
      state: {
        disabled: state.disabled,
        ...getValidatedFormState(state, payload?.context, [
          'id',
          'name',
          'value',
          'rules',
          'isArray',
        ]),
      },
      context: payload?.context,
      callbacks: payload?.callbacks,
    },
  }),
  [FormActions.SUBMIT]: ({ payload }, state) => {
    const validatedState = getValidatedFormState(state, undefined, [
      'id',
      'name',
      'value',
      'rules',
      'isArray',
    ]);
    return {
      ...(!state.disabled
        ? {
            fields: validatedState.fields.map((item) => ({
              ...item,
              evaluated: state.feedback === 'full' ? true : !item.valid,
            })),
            submitted: [...validatedState.fields],
            disabled:
              payload?.disabled ??
              (state.feedback === 'full' ||
                !validatedState.fields.some((item) => !item.valid)),
          }
        : {}),
    };
  },
  [FormActions.VALIDATE]: ({ payload }, state) => {
    const formState = getValidatedFormState(state);
    payload.emit('validate', formState);
    return {
      ...state,
      ...formState,
    };
  },
  [FormActions.TOGGLE_EVALUATION_STATE]: ({ payload }, state) => ({
    fields: state.fields.reduce<TField[]>(
      (fields, item) => [
        ...fields,
        !payload?.fields || payload.fields.includes(item.id)
          ? {
              ...item,
              evaluated:
                payload?.visible === undefined
                  ? state.feedback === 'full'
                    ? true
                    : !item.valid
                  : payload.visible,
            }
          : item,
      ],
      []
    ),
  }),
  [FormActions.SET_PROP]: ({ payload }) => payload,
  [FormActions.APPEND_VALIDATION_ERRORS]: ({ payload }, state) => ({
    disabled: state.feedback === 'full',
    submitted: state.feedback !== 'full' ? undefined : state.submitted,
    fields: state.fields.reduce<TField[]>(
      (fields, item) => [
        ...fields,
        payload?.messages[item.id]
          ? {
              ...item,
              messages: [
                ...(item.messages || []),
                ...payload.messages[item.id],
              ],
            }
          : item,
      ],
      []
    ),
  }),
  [FormActions.SET_VALIDATION_ERRORS]: ({ payload }, state) => ({
    disabled: state.feedback === 'full',
    submitted: state.feedback !== 'full' ? undefined : state.submitted,
    fields: state.fields.reduce<TField[]>(
      (fields, item) => [
        ...fields,
        payload?.messages[item.id]
          ? {
              ...item,
              messages: payload.messages[item.id],
            }
          : item,
      ],
      []
    ),
  }),
  [FormActions.SET_ERROR]: ({ payload }, state) => ({
    error: payload.message,
    disabled: false,
    fields: state.fields.map((item) => ({
      ...item,
      evaluated: false,
    })),
  }),
  [FormActions.SET_DISABLED]: ({ payload }) => ({
    disabled: payload,
  }),
  [FormActions.SET_LOADING]: ({ payload }, state) =>
    payload !== state.loading ? { loading: payload } : null,
  [FormActions.RESET]: ({ payload }, state) => ({
    fields: Object.values(state.defaultFields),
    submitted: undefined,
    disabled: !!payload?.disabled,
    validated: false,
    dirty: false,
    error: undefined,
  }),
  [FormActions.SAVED]: () => ({
    dirty: false,
  }),
});

/**
 * useFormReducer.
 *
 * Store the state for a Form component.
 */
export function useFormReducer(options: Partial<IFormReducerState>) {
  return useReducer(FormReducer, {
    ...FormInitialState,
    ...options,
  });
}
