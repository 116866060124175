import React, { PropsWithChildren } from 'react';
import { StudentNavigation } from '@brainstud/coach-panel/Layouts';
import { useHeadProvider } from '../../Providers';

type Props = {
  title?: string;
};

/**
 * EnrollmentLayout.
 *
 * Contains the main layout of a Enrollment.
 */
export const EnrollmentLayout = ({
  title,
  children,
}: PropsWithChildren<Props>) => {
  useHeadProvider(title);
  return (
    <main>
      <StudentNavigation />
      {children}
    </main>
  );
};
