import { Group } from '@mui/icons-material';
import classNames from 'classnames';
import { useTranslator } from 'Providers/Translator';
import styles from './ObjectMetaBadge.module.css';

const cx = classNames.bind(styles);

type TProps = {
  type: string;
  value?: string | number | boolean;
  label?: string;
};

export const ObjectMetaBadge = ({ type, value, label = '' }: TProps) => {
  const [t] = useTranslator();
  const parsedValue = value ? parseInt(`${value}`, 10) : 0;
  const isGroup = type === 'groupSize' && parsedValue > 1;

  if (type === 'groupSize' && !isGroup) {
    return null;
  }

  return (
    <div className={cx(styles.base, styles[type])}>
      {type === 'xp' &&
        `${label} ${value?.toLocaleString()} ${t('components.card_tag.xp')}`}
      {isGroup && (
        <>
          <Group />
          <span>{value}</span>
        </>
      )}
    </div>
  );
};
