import { DateTimeString } from '../Utils/DateTimeString';
import { IResource } from '../Utils/Resource';
import { LearningObject } from './LearningObject';
import { Shareable } from './Shareable';
import { StoredFile } from './StoredFile';

/**
 * An Exposition is typically a selection of learning objects
 * and files, selected by the user to exhibit his/her work
 * to others.
 */
export interface Exposition extends IResource<'expositions'> {
  /** Title of the exposition */
  title: string;
  /** Short text to describe the exposition */
  description: string;
  /** Thumbnail like image to make the exposition recognizable */
  image: string;
  /** The date the exposition was created */
  createdAt: DateTimeString;
  /** The date the exposition was last updated */
  updatedAt: DateTimeString;
  /** Files uploaded by the user to complement the exposition */
  storedFiles: () => StoredFile[];
  /** The learning objects selected by the user to be shown in the exposition */
  learningObjects: () => LearningObject[];
  /** Shareables that are linked to this Exposition */
  shareables?: () => Shareable[];
}

export interface ExpositionCreateInput
  extends Pick<Exposition, 'title' | 'description'> {
  relationships?: {
    /** The temporarily uploaded file */
    image?: string;
    /** The learning objects linked to this exposition */
    learning_objects?: string[];
    /** A list of temporarily uploaded files to link to this exposition as content */
    stored_files?: string[];
  };
}

export interface ExpositionUpdateInput extends ExpositionCreateInput {}

export enum ExpositionIncludes {
  'learning_objects',
  'learning_objects.last_answer',
  'stored_files',
  'learning_objects.course',
  'learning_objects.course.enrollment',
  'shareables',
}

export interface ExpositionFilters {
  title: string;
}

export enum ExpositionSorts {
  'title',
  'created_at',
  'updated_at',
}
