import { UseMutationOptions } from 'react-query';
import { useCreate } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import { UUID } from '../Types';

interface PrepaidCodeActivation {
  account?: UUID;
  prepaid_code: UUID;
}

interface Props {
  account?: UUID;
}

export function usePrepaidCodeActivation(
  { account }: Props = {},
  settings?: UseMutationOptions<
    DataDocument<undefined>,
    ErrorDocument,
    PrepaidCodeActivation
  >
) {
  const context = useApi();
  const {
    parameters: { account: defaultAccount },
  } = context;
  const request = new ApiRequest(
    {
      baseName: 'v1.services.prepaid_code_activation',
      baseUri: '/v1/services/prepaid_code_activation',
      invalidate: [
        'subscription',
        'subscriptions',
        'order',
        'orders',
        'v1.orders',
        'v1.accounts.orders',
      ],
      data: {
        account: account || defaultAccount,
      },
    },
    context
  );
  return useCreate<undefined, PrepaidCodeActivation>(request, settings);
}
