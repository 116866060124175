import React from 'react';
import { Button } from '@brainstud/ui';
import { Forum } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Container } from 'Components';
import { useAnswerProvider } from 'Providers/AnswerProvider';
import { useContentBlocksProvider } from 'Providers/ContentBlocksProvider';
import { useTranslator } from 'Providers/Translator';
import { DateFormatter } from 'Utils/DateFormatHelper';
import styles from './AssignmentStatus.module.css';

const cx = classNames.bind(styles);

type TProps = {
  onToggleActivityFeedClick: () => void;
};

/**
 * The AssignmentStatus component displays a statusbar with information on the handin status of an assignment.
 * Part of the status can be the feedback of the coach which will be added as a sidebar, which can be
 * toggled by a button inside the statusbar.
 */
export const AssignmentStatus = ({ onToggleActivityFeedClick }: TProps) => {
  const [t, { lc }] = useTranslator();
  const { isStatic } = useContentBlocksProvider();
  const { lastAnswer } = useAnswerProvider();

  const statusOptions = {
    TURNED_IN: lc(t(`states.${isStatic ? 'read' : 'turned_in'}`)),
    REJECTED: lc(t('states.rejected')),
    ACCEPTED: lc(t(`states.${isStatic ? 'read' : 'accepted'}`)),
    OPEN: lc(t('states.accepted')),
    CONCEPT: lc(t('states.accepted')),
  };

  return lastAnswer ? (
    <div className={cx(styles.base, lastAnswer.status)}>
      <Container className={cx(styles.container)}>
        <div className={cx(styles.title)}>
          {t(`components.status.assignment_${isStatic ? 'read' : 'made'}_on`, {
            statusLabel: statusOptions[lastAnswer.status],
            statusDate: DateFormatter.toDateTime(lastAnswer.updatedAt),
          })}
        </div>
        <Button
          type="button"
          onClick={onToggleActivityFeedClick}
          small
          className={cx(styles.button)}
        >
          {`${t('components.activity_feed.activity')} `}
          <Forum />
        </Button>
      </Container>
    </div>
  ) : null;
};
