import { useState } from 'react';
import classNames from 'classnames/bind';
import { Tabs, TabsItem } from 'Components';
import { CommentForm } from 'Components/ActivityFeed/CommentForm';
import { AnswerReviewProvider } from 'Modules/coach-panel/Providers';
import { useTranslator } from 'Providers/Translator';
import { AnswerReviewForm } from '../Form';
import styles from './AnswerReviewTabs.module.css';

const cx = classNames.bind(styles);

type TProps = {
  baseUri: string;
  onSuccess?: () => void;
  answerId?: string;
};

type TTab = 'review' | 'comment';

export const AnswerReviewTabs = ({ baseUri, onSuccess, answerId }: TProps) => {
  const [t] = useTranslator();
  const [currentTab, setCurrentTab] = useState<TTab>('review');

  return (
    <section className={cx(styles.base)}>
      <Tabs className={cx(styles.tabs)}>
        <TabsItem
          active={currentTab === 'review'}
          onClick={() => setCurrentTab('review')}
        >
          {t('exams.assessment')}
        </TabsItem>
        <TabsItem
          active={currentTab === 'comment'}
          onClick={() => setCurrentTab('comment')}
        >
          {t('components.activity_feed.comment')}
        </TabsItem>
      </Tabs>

      <div className={cx(styles.content)}>
        {currentTab === 'comment' ? (
          <CommentForm id={answerId} type="answer" onSuccess={onSuccess} />
        ) : (
          <div className={cx(styles.form)}>
            <AnswerReviewProvider>
              <AnswerReviewForm baseUri={baseUri} onSuccess={onSuccess} />
            </AnswerReviewProvider>
          </div>
        )}
      </div>
    </section>
  );
};
