import React, { useMemo } from 'react';
import {
  Account,
  Course,
  DateTimeString,
  Exam,
  ExamSteps,
  UUID,
} from '@brainstud/academy-api';
import { Callout, Table, Th, Tr } from '@brainstud/ui';
import { useTranslator } from 'Providers/Translator';
import { ExamRow } from './ExamRow';

type Props = {
  /** The exams to show the table for */
  exams: Exam[];
  /** Sortinghandler */
  setSort: (value: string[]) => void;
};

export type ExamRowType = {
  id: UUID;
  course?: Course;
  status: ExamSteps;
  enrollmentId: UUID;
  participant?: Account;
  date: DateTimeString;
};

/**
 * The ExamsTable module provides a table for the given exams which is used in the coach review page,
 * alongside tables for the anwers and samplesets.
 */
export const ExamsTable = ({ exams, setSort }: Props) => {
  const [t] = useTranslator();

  const rows = useMemo<ExamRowType[]>(
    () =>
      exams.map((exam) => {
        const course = exam.enrollment?.().course?.();
        const account = exam.enrollment?.().account?.();
        const enrollment = exam.enrollment?.() || { id: '' };

        return {
          id: exam.id,
          course,
          status: exam.currentStep,
          enrollmentId: enrollment.id,
          participant: account,
          date: exam.signalDate,
        };
      }),
    [exams]
  );

  return rows.length > 0 ? (
    <Table onSort={(sortOn) => (sortOn ? setSort([sortOn]) : setSort([]))}>
      <Table.THead>
        <Tr>
          <Th sortOn="account">{t('review.participant')}</Th>
          <Th sortOn="course">{t('review.course')}</Th>
          <Th>{t('review.status')}</Th>
          <Th sortOn="created_at">{t('review.released_at')}</Th>
        </Tr>
      </Table.THead>

      <Table.TBody>
        {rows.map((row) => (
          <ExamRow data={row} key={row.id} />
        ))}
      </Table.TBody>
    </Table>
  ) : (
    <Callout info margin="2rem 0">
      {t('review.noReviews.exams')}
    </Callout>
  );
};
