import { UseMutationOptions } from 'react-query';
import { useCreate } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import { Exam, ExamAssessorCreateInput, UUID } from '../Types';

interface IProps {
  exam: UUID;
}

export function useCreateExamAssessor(
  { exam }: IProps,
  settings?: UseMutationOptions<
    DataDocument<Exam>,
    ErrorDocument,
    ExamAssessorCreateInput
  >
) {
  const context = useApi();
  const {
    parameters: { exam: defaultExam },
  } = context;
  const request = new ApiRequest(
    {
      baseName: 'v1.exams.assessors',
      baseUri: `/v1/exams/${exam || defaultExam}/assessors`,
      invalidate: ['assessors', 'assessor'],
    },
    context
  );
  return useCreate<Exam, ExamAssessorCreateInput>(request, settings);
}
