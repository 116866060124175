import React from 'react';
import { AnswerGroup } from '@brainstud/academy-api';
import { Callout, Table, Th, Tr } from '@brainstud/ui';
import { useTranslator } from 'Providers/Translator';
import { useQuizReviewables } from '../useQuizReviewables';
import { QuizRow } from './QuizRow';

type Props = {
  answerGroups: AnswerGroup[];
};

export const QuizzesTable = ({ answerGroups }: Props) => {
  const [t] = useTranslator();

  const reviewables = useQuizReviewables(answerGroups);

  return reviewables.length > 0 ? (
    <Table>
      <Table.THead>
        <Tr>
          <Th>{t('review.participant')}</Th>
          <Th>{t('review.course')}</Th>
          <Th>{t('review.quiz')}</Th>
          <Th>{t('review.status')}</Th>
          <Th>{t('review.date')}</Th>
        </Tr>
      </Table.THead>
      <Table.TBody>
        {reviewables.map((reviewable) => (
          <QuizRow data={reviewable} key={reviewable.id} />
        ))}
      </Table.TBody>
    </Table>
  ) : (
    <Callout info margin="2rem 0">
      {t('review.noReviews.quizzes')}
    </Callout>
  );
};
