import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  ProductCategory,
  ProductCategoryCreateInput,
  ProductCategoryFilters,
  ProductCategoryIncludes,
  ProductCategorySorts,
  ProductCategoryUpdateInput,
  UUID,
} from '../Types';

interface IProductCategoryProps
  extends HookParams<
    typeof ProductCategoryIncludes,
    typeof ProductCategoryFilters,
    typeof ProductCategorySorts
  > {
  productCategory?: UUID;
  portal?: UUID;
}

export function useProductCategory(
  { portal, productCategory, ...queryParameters }: IProductCategoryProps = {},
  settings?: UseQueryOptions<DataDocument<ProductCategory>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: {
      productCategory: defaultProductCategory,
      portal: defaultPortal,
    },
  } = context;
  const request = new ApiRequest(
    {
      enabled:
        !!(productCategory || defaultProductCategory) &&
        !!(portal || defaultPortal),
      baseName: 'v1.portals.productCategories',
      baseUri: `/v1/portals/${portal || defaultPortal}/product_categories`,
      uri: `/${productCategory || defaultProductCategory}`,
      invalidate: [
        'product_categories',
        'product_category',
        'category',
        'categories',
      ],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    ProductCategory,
    ProductCategoryCreateInput,
    ProductCategoryUpdateInput
  >(request, settings);
}

export function useProductCategories(
  {
    portal,
    ...queryParameters
  }: Omit<IProductCategoryProps, 'productCategory'> = {},
  settings?: UseQueryOptions<DataDocument<ProductCategory[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { portal: defaultPortal },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(portal || defaultPortal),
      baseName: 'v1.portals.productCategories',
      baseUri: `/v1/portals/${portal || defaultPortal}/product_categories`,
      invalidate: [
        'product_categories',
        'product_category',
        'category',
        'categories',
      ],
      queryParameters,
    },
    context
  );
  return useIndexCR<ProductCategory, ProductCategoryCreateInput>(
    request,
    settings
  );
}
