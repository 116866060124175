/**
 * toCurrency
 *
 * Used to transform a number into a formatted currecy string
 *
 * @param price the number to format to a currency string
 * @param currency the currency used to format the number into a currecy string
 */

export function toCurrency(price: number, currency?: string | null) {
  return new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: currency ?? 'EUR',
  }).format(price);
}
