import { DateTimeString } from '../Utils/DateTimeString';
import { IResource } from '../Utils/Resource';
import { Role } from '../Utils/Roles';
import { UUID } from '../Utils/UUID';
import { Account } from './Account';
import { Coach } from './Coach';
import { CoachMandate } from './CoachMandate';
import { Course } from './Course';
import { EducationLevel } from './EducationLevel';
import { Exam, ExamSteps } from './Exam';
import { Feedback } from './Feedback';
import { LearningObjectCollection } from './LearningObjectCollection';
import { Schedule } from './Schedule';

export interface Enrollment extends IResource<'enrollments'> {
  /** Date at which the student was last active in the enrollment */
  activeAt: DateTimeString | null;
  /** Date at which the enrollment was created */
  createdAt: DateTimeString;
  /** Date at which the enrollment was last updated */
  updatedAt: DateTimeString;
  /** The role with which the enrollment was created (coach or student) */
  asRole: Role;
  /** Whether the enrollment has all required information (coach, collection, level) */
  isValid: boolean;
  /** Whether the enrollment of the participant is completed */
  isCompleted: boolean;
  /** @depreciated A set of parameters to set specific information */
  parameters: Array<{
    property: string;
    payload: string;
  }>;
  /** Details about the progress of the student */
  progress: {
    /** The progress data expressed in XPs */
    xp: {
      /** Total XP available within the course */
      totalAvailable: number;
      /** XP received by creating answers by the student */
      totalReceived: string;
    };
    /** The progress data expressed in percentages */
    percentage: {
      /** Total percentage of the course completed by the student */
      completed: number;
      /** Percentage left to answer go by the student */
      left: number;
    };
    /** The configured required progress to automatically start the exam for this course */
    exam: {
      /** The configured type of requirement */
      type: 'xp' | 'percentage';
      /** The amount required to start the exam procedure automatically */
      amount: number;
    };
  };
  /** The configured required progress to manually start the exam for this course */
  examStartTarget?: {
    /** The configured type of requirement */
    type: 'xp' | 'percentage';
    /** The amount required to start the exam procedure automatically */
    amount: number;
    /** The required number of answered objects before starting an exam manually  */
    minimumObjectCount: number;
  };
  /** The education level of the enrollment */
  educationLevel?: () => EducationLevel;
  /** The course for which this enrollment is valid */
  course?: () => Course;
  /** The learning object collection that has been selected for this student */
  learningObjectCollection?: () => LearningObjectCollection;
  /** The learning object collection available for this enrollment */
  learningObjectCollections?: () => LearningObjectCollection[];
  /** The account to which this enrollment belongs */
  account?: () => Account;
  /** The link to the coach monitoring this enrollment */
  coachMandates?: () => CoachMandate[];
  /** Possibly an exam that has been started with this enrollment */
  exam?: () => Exam;
  /** Possibly an exam that has been started with this enrollment */
  activeExam?: () => Exam;
  /** A schedule configured with this enrollment */
  schedule?: () => Schedule;
  /** All coaches linked to this enrollment */
  coaches?: () => Coach[];
  links?: {
    /** Export link to a zip file */
    portfolioExport?: {
      href: string;
    };
  };
  feedback?: () => Feedback;
  meta?: {
    exam?: {
      currentStep: ExamSteps;
      coachHasSeenAt?: DateTimeString;
    };
    canCreateSampleSet: boolean;
  };
}

export interface EnrollmentCreateInput {
  /** The UUID of the portal */
  portal: UUID;
  /** The UUID of the course */
  course: UUID;
  /** The UUID of the account */
  account?: UUID;
  /** The UUID of the education level */
  education_level?: UUID;
  /** The UUID of the learning object collection */
  learning_object_collection?: UUID;
  /** The role to enroll as */
  role?: Role;
}

export interface EnrollmentUpdateInput {
  // PUT is done via direct property
  education_level?: UUID;
  learning_object_collection?: UUID;
  // PATCH completed_at to indicate if the enrollment is completed
  is_completed?: boolean;
  // PATCH is done via relationships
  relationships?: {
    education_level?: UUID;
    learning_object_collection?: UUID;
  };
}

export enum EnrollmentIncludes {
  exam,
  'exam.assessment',
  education_level,
  course,
  learning_object_collection,
  account,
  coach_mandates,
  active_exam,
  schedule,
  coaches,
  feedback,
}

export enum EnrollmentFilters {
  account,
  course,
  coach_activity,
}

export enum EnrollmentSorts {}
