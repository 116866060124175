import React, { useEffect, useState } from 'react';
import { useDimensions } from '@brainstud/universal-components';
import { useBlock } from 'Hooks/useBlock';
import VideoLink from '../../../Utils/VideoLink';
import { IVideoBlock } from './Types';
import styles from './VideoBlock.module.css';

const VideoBlock = ({
  id,
  title,
  src,
  ratio = [16, 9],
}: Omit<IVideoBlock, 'type'>) => {
  const [container, dimensions] = useDimensions();
  const [videoHeight, setVideoHeight] = useState(320);
  useBlock({
    id,
  });

  const { width: videoWidth } = dimensions;
  useEffect(() => {
    if (videoWidth) {
      setVideoHeight(videoWidth * (ratio[1] / ratio[0]));
    }
  }, [videoWidth, ratio]);

  return (
    <div
      className={styles.base}
      style={{ height: `${videoHeight.toString()}px` }}
      ref={container}
    >
      <iframe
        width={videoWidth}
        height={videoHeight}
        className={styles.frame}
        title={title}
        src={new VideoLink(src).getEmbedLink()}
        frameBorder="0"
        allowFullScreen
      />
    </div>
  );
};

export { VideoBlock };
