import React, { Component, ErrorInfo, FC, ReactNode } from 'react';
import * as Sentry from '@sentry/nextjs';
import { ErrorTransition } from 'Providers/ErrorDataProvider/ErrorTransition';
import { Exception } from '../../Exceptions/Exception';
import { ErrorDataContext } from './ErrorDataContext';
import { ErrorFallback, IErrorFallbackProps } from './ErrorFallback';

type TProps = {
  children?: ReactNode;
  /** Adds a container and panel around block template errors */
  container?: boolean;
  fallback?: FC<IErrorFallbackProps>;
  onError?: (error: Error, info: ErrorInfo) => void;
  onReset?: () => void;
  className?: string;
};

type TState = {
  error: null | Error;
};

const initialState = {
  error: null,
};

/**
 * ErrorBoundary.
 *
 * Catches thrown errors and shows a fallback component to the user based
 * on the error.
 */
export class ErrorBoundary extends Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.handleReset = this.handleReset.bind(this);
    this.state = initialState;
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    const { onError } = this.props;
    if (error instanceof Exception) {
      error?.toSentry({
        'Component Stack': info.componentStack,
      });
    } else {
      Sentry.captureException(error, (scope) => {
        scope.setExtra('Component Stack', info.componentStack);
        return scope;
      });
    }
    onError?.(error, info);
  }

  handleReset(): void {
    const { onReset } = this.props;
    onReset?.();
    this.setState(initialState);
  }

  render(): JSX.Element {
    const { children, fallback: Fallback, className, container } = this.props;
    const { error } = this.state;

    return (
      <ErrorTransition error={error} handleReset={this.handleReset}>
        {error !== null ? (
          <>
            {Fallback === undefined ? (
              <ErrorFallback
                container={container}
                handleReset={this.handleReset}
                className={className}
                error={error}
              />
            ) : (
              <Fallback
                container={container}
                className={className}
                error={error}
                handleReset={this.handleReset}
              />
            )}
          </>
        ) : (
          <>{children}</>
        )}
      </ErrorTransition>
    );
  }
}
ErrorBoundary.contextType = ErrorDataContext;
