import { useContext } from 'react';
import {
  ILearningObjectContext,
  LearningObjectContext,
} from './LearningObjectContext';

/**
 * useLearningObjectProvider.
 *
 * With the useLearningObjectProvider, you can get an learningObject.
 * Next to that you can get the varieties of this learningObject and also the used variety
 * With a dispatch action you can change the used variety when there are multiple varieties
 *
 * @param nullable When true, this hook returns null when no context is found.
 */
export function useLearningObjectProvider(
  nullable?: false
): ILearningObjectContext;
export function useLearningObjectProvider(
  nullable?: true
): null | ILearningObjectContext;
export function useLearningObjectProvider(nullable = false) {
  const context = useContext(LearningObjectContext);

  if (!context && !nullable) {
    throw new Error('Please, initiate the LearningObjectProvider first!');
  }

  return context;
}
