import React, { ReactNode, useEffect, useMemo, useReducer } from 'react';
import { useLocalStorage } from 'Hooks';
import { ViewSettingsContext } from './ViewSettingsContext';
import {
  DefaultViewSettingsState,
  IViewSettings,
  ViewSettingsReducerFactory,
} from './ViewSettingsReducer';

type Props = {
  children: ReactNode;
};

/**
 * ViewSettingsProvider.
 *
 * Provider to keep track of the view settings.
 * This will sync state from and to a storage.
 */
export const ViewSettingsProvider = ({ children }: Props) => {
  const [viewSettings, setViewSettings] =
    useLocalStorage<Record<string, IViewSettings | undefined>>('view-settings');
  const [{ isDirty, isLoaded, views }, dispatch] = useReducer(
    ViewSettingsReducerFactory(),
    DefaultViewSettingsState
  );

  /**
   * Sync back to storage and reset dirty state
   */
  useEffect(() => {
    if (isDirty) {
      setViewSettings(views);
      dispatch({ type: 'SAVED_SETTINGS' });
    }
  }, [setViewSettings, isDirty, views]);

  /**
   * On initial load set viewSettings in storage.
   * We're only interested in the isLoaded property, so only that property is tracked in the dependency array.
   * If we move to an async storage solution, this should work also when setting isLoaded to true.
   * When this happens, also track state.views or remove this as part of the retrieval of data and set directly in the desired storage.
   */
  useEffect(() => {
    if (!isLoaded) {
      dispatch({
        type: 'LOAD_SETTINGS',
        payload: viewSettings ? { views: viewSettings } : undefined,
      });
    }
  }, [setViewSettings, viewSettings, isLoaded]);

  const context = useMemo(() => ({ views, dispatch }), [views]);

  return (
    <ViewSettingsContext.Provider value={context}>
      {children}
    </ViewSettingsContext.Provider>
  );
};
