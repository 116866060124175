import React from 'react';
import styles from './Alert.module.css';

export interface AlertProps {
  title?: string;
  description?: string;
}

const Alert = ({ title, description }: AlertProps) =>
  description && description !== 'empty' ? (
    <div className={styles.alert}>
      <h4>{title}</h4>
      <p>{description}</p>
    </div>
  ) : null;

export default Alert;
