import React from 'react';
import { ContentEditor, useContentEditor } from '@brainstud/course-editor';
import { Panel } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { Container, Loading, TemplateSelector } from 'Components';
import { useHasAnyRole, useReducedUserExperienceWarning } from 'Hooks';
import { PrefixPoster } from 'Modules/action-based-learning-panel/Components';
import { useRouter } from 'next/router';
import {
  useBreadcrumbs,
  useEnvironmentProvider,
  useLearningObjectProvider,
} from 'Providers';
import { AnswerForm, AnswerProvider } from 'Providers/AnswerProvider';
import { ContentBlocksProvider } from 'Providers/ContentBlocksProvider';
import { ObjectHeader } from 'Views/CollectionViews/ObjectViews';
import { useActualObjectLayoutName } from 'Views/CollectionViews/ObjectViews/useActualObjectLayoutName';
import { LearningObjectEditBar } from 'Views/Courses/CollectionEditView/LearningObjectEditView/LearningObjectEditBar';
import { EditorBackButtonWithUnsavedCheck } from './EditorBackButtonWithUnsavedCheck';
import styles from './LearningObjectEditView.module.css';

const cx = classNames.bind(styles);

const LearningObjectEditView = () => {
  const router = useRouter();
  useReducedUserExperienceWarning();
  const { courseId, learningObjectId } = router.query as {
    courseId: string;
    learningObjectId: string;
    returnTo: string;
  };
  const { learningObject, isLoading, variety } = useLearningObjectProvider();
  const { layout: ObjectLayout } = useContentEditor();

  const hasEditAccess = useHasAnyRole(['author', 'coach', 'academy author']);
  const { isAdminEnvironment, isCoachEnvironment } = useEnvironmentProvider();
  const isPanel = useActualObjectLayoutName() === 'panel';

  useBreadcrumbs(
    {
      ...(learningObject ? { [learningObjectId]: learningObject.title } : {}),
      detail: null,
    },
    [learningObject]
  );

  if (!hasEditAccess) {
    router.push(`/courses/${courseId}/detail/${learningObjectId}`);
    return null;
  }

  return isLoading ? (
    <Loading />
  ) : (
    <ContentBlocksProvider readonly>
      <AnswerProvider>
        <AnswerForm
          identifier={learningObjectId}
          learningObject={learningObject}
          autoLoad={false}
        >
          {isCoachEnvironment && <LearningObjectEditBar coach />}
          <Container>
            <section className={cx(styles.base, styles.header)}>
              {isAdminEnvironment && <EditorBackButtonWithUnsavedCheck />}
              <ObjectHeader editable />
            </section>
          </Container>
          {!isPanel && <TemplateSelector />}
          <Container>
            <section className={cx(styles.base)}>
              <ContentEditor
                className={cx({
                  'MuiGrid-container': isPanel,
                  'MuiGrid-spacing-xs-4': isPanel,
                  'grid-container': isPanel,
                })}
              >
                {(data, Component, idx) => (
                  <React.Fragment key={data.id}>
                    {!isPanel ? (
                      <div className={cx(styles.editBlock)}>
                        {idx === 0 &&
                          (variety?.version === undefined ||
                            variety.version < 4) && <PrefixPoster />}
                        {Component}
                      </div>
                    ) : (
                      <div
                        className={cx(
                          'MuiGrid-root',
                          'MuiGrid-item',
                          'MuiGrid-grid-xs-12',
                          'MuiGrid-grid-sm-12',
                          `MuiGrid-grid-md-${ObjectLayout === 'columns' && isPanel ? '6' : '12'}`
                        )}
                      >
                        <Panel className={cx(styles.editBlock)}>
                          {idx === 0 &&
                            (variety?.version === undefined ||
                              variety.version < 4) && <PrefixPoster />}
                          {Component}
                        </Panel>
                      </div>
                    )}
                  </React.Fragment>
                )}
              </ContentEditor>
            </section>
          </Container>
        </AnswerForm>
      </AnswerProvider>
    </ContentBlocksProvider>
  );
};

// This component is lazy loaded, which requires a default export.
export default LearningObjectEditView;
