import React from 'react';
import { Button } from '@brainstud/ui';
import {
  SentimentDissatisfiedOutlined,
  SentimentSatisfied,
  SentimentSatisfiedOutlined,
} from '@mui/icons-material';
import classNames from 'classnames/bind';
import { TFeedbackFormProps } from './Types';
import styles from './FeedbackTypes.module.css';

const cx = classNames.bind(styles);

export const SmileyFeedback = ({ title, onAnswer }: TFeedbackFormProps) => (
  <div className={cx('smiley')}>
    <p className={cx('title')}>{title}</p>
    <div className={cx('buttons-list')}>
      <Button link onClick={() => onAnswer(1)}>
        <SentimentSatisfiedOutlined
          style={{ color: 'rgb(var(--success-color))' }}
        />
      </Button>
      <Button link onClick={() => onAnswer(3)}>
        <SentimentSatisfied style={{ color: 'rgb(var(--warning-color))' }} />
      </Button>
      <Button link onClick={() => onAnswer(5)}>
        <SentimentDissatisfiedOutlined
          style={{ color: 'rgb(var(--error-color))' }}
        />
      </Button>
    </div>
  </div>
);
