import { useMutation, UseMutationOptions } from 'react-query';
import queryClient from '../Providers/ApiProvider/QueryClient';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import { IResource } from '../Types';
import { UpdateMethods } from '../Types/Utils/UpdateMethods';

export default function useUpdate<Resource extends IResource, UpdateInput>(
  request: ApiRequest,
  queryConfig?: UseMutationOptions<
    DataDocument<Resource>,
    ErrorDocument,
    UpdateInput & UpdateMethods,
    any
  >
) {
  return useMutation<
    DataDocument<Resource>,
    ErrorDocument,
    UpdateInput & UpdateMethods
  >(request.update.bind(request), {
    mutationKey: request.getKey('update') || '',
    onSuccess: () =>
      Promise.all<void>([
        queryClient.invalidateQueries([
          {
            [request.baseName]: true,
          },
        ]),
        ...request.invalidate.map((invalidKey) =>
          queryClient.invalidateQueries([{ [invalidKey]: true }])
        ),
      ]).then(),
    ...queryConfig,
  });
}
