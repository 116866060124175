import React from 'react';
import classNames from 'classnames/bind';
import { useBlock } from 'Hooks/useBlock';
import { sanitizer } from 'Utils/Sanitizer';
import { IRichListBlock } from './Types';
import styles from './RichListBlock.module.css';

const cx = classNames.bind(styles);

export const RichListBlock = ({
  id,
  numbered = false,
  items = [],
  style,
  className,
}: Omit<IRichListBlock, 'type'>) => {
  const OrderedOrUnorderedList =
    `${numbered ? 'o' : 'u'}l` as keyof JSX.IntrinsicElements;
  useBlock({
    id,
  });
  return (
    <OrderedOrUnorderedList className={cx('base', className)} style={style}>
      {items.map((listItem, idx) => (
        <li
          key={listItem.id}
          className={cx({ dot: !numbered && !listItem.icon })}
        >
          {!!listItem.icon && <img src={listItem.icon} alt="" />}
          {numbered && !listItem.icon && (
            <span className={styles.numbered}>{idx + 1}</span>
          )}
          <div
            dangerouslySetInnerHTML={{ __html: sanitizer(listItem.content) }}
          />
        </li>
      ))}
    </OrderedOrUnorderedList>
  );
};
