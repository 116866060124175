import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useEnrollment } from '@brainstud/academy-api';
import { Dropdown } from '@brainstud/ui';
import { useTranslator } from 'Providers/Translator';
import { TEnrollmentModalState } from './EnrollmentModal';

export type SelectAssignmentSetProps = {
  enrollmentId?: string;
  onStateChange: (state: TEnrollmentModalState) => void;
};

const SelectAssignmentSet = ({
  enrollmentId,
  onStateChange,
}: SelectAssignmentSetProps) => {
  const [t] = useTranslator();
  const [loading, setLoading] = useState(false);
  const [{ data: enrollment, update: updateEnrollment }, { isLoading }] =
    useEnrollment({ enrollment: enrollmentId });
  const learningObjectCollections = useMemo(
    () =>
      enrollment
        ?.learningObjectCollections?.()
        .filter((collection) => collection.allowNewEnrollments),
    [enrollment]
  );

  const currentCollection = useMemo(
    () => enrollment?.learningObjectCollection?.(),
    [enrollment]
  );

  const collections = useMemo(
    () =>
      learningObjectCollections
        ?.filter((collection) => collection.allowNewEnrollments)
        .reverse() || [],
    [learningObjectCollections]
  );

  const setCollectionId = useCallback(
    (collectionId: string) => {
      setLoading(true);
      return updateEnrollment.mutateAsync(
        { learning_object_collection: collectionId },
        {
          onSettled: () => {
            setLoading(false);
          },
        }
      );
    },
    [updateEnrollment]
  );

  useEffect(() => {
    onStateChange(
      loading || isLoading ? 'loading' : currentCollection ? 'done' : 'blocked'
    );
  }, [loading, isLoading, currentCollection, onStateChange]);

  return (
    <div>
      <Dropdown
        block
        label={t('modals.enrollment.steps[1].input')}
        placeholder={t('modals.enrollment.steps[1].placeholder')}
        disabled={loading}
        rules="required"
        onChange={(option) => option && setCollectionId(option)}
        defaultValue={currentCollection?.id}
      >
        {collections.map((collection) => (
          <Dropdown.Option key={collection.id} value={collection.id}>
            {collection.title}
          </Dropdown.Option>
        ))}
      </Dropdown>
    </div>
  );
};

export default SelectAssignmentSet;
