import React, { useMemo } from 'react';
import { LearningObject, useLearningObject } from '@brainstud/academy-api';
import { ReactComponent as NfcLockedIcon } from 'Assets/Icons/nfc-locked.svg';
import classNames from 'classnames/bind';
import { Loading } from 'Components';
import { useTranslator } from 'Providers/Translator';
import styles from './NfcRequired.module.css';

const cx = classNames.bind(styles);

type Props = {
  object: LearningObject;
};

export const NfcRequired = ({ object }: Props) => {
  // To make sure the nfc include is always present.
  const [{ data: learningObject }, { isLoading }] = useLearningObject({
    learningObject: object.id,
    include: ['nfc_tags'],
  });

  const nfcTags = useMemo(
    () => object?.nfcTags?.() || learningObject?.nfcTags?.() || [],
    [learningObject, object]
  );

  const [t] = useTranslator();
  return isLoading ? (
    <Loading />
  ) : (
    <div className={cx(styles.base)}>
      <p>{t('modals.nfc_required.body')}</p>
      <strong>{t('modals.nfc_required.location')}</strong>

      {nfcTags.map((nfcTag) => (
        <React.Fragment key={nfcTag.id}>
          <div className={cx(styles.location)}>
            {nfcTag.storedFile?.() && (
              <img
                src={nfcTag.storedFile().downloadUrl}
                alt={nfcTag.location}
              />
            )}
            <div className={styles.description}>
              <strong>{nfcTag.location}</strong>
              <a
                href={`https://www.google.com/maps/place/${nfcTag.address}+${nfcTag.city}`.replace(
                  / /g,
                  '+'
                )}
                target="_blank"
                rel="noreferrer"
              >
                <br />
                {nfcTag.address}
                <br />
                {nfcTag.city}
              </a>
            </div>
          </div>
          {nfcTag.notes && (
            <div>
              <h4>{`${t('modals.nfc_required.notes')}:`}</h4>
              <p>{nfcTag.notes}</p>
            </div>
          )}
        </React.Fragment>
      ))}
      <div className={cx(styles.wrapper)}>
        <div className={cx(styles.icon)}>
          <NfcLockedIcon />
        </div>
        <p className={cx(styles.instruction)}>
          {t('modals.nfc_required.scan_instruction')}
        </p>
      </div>
    </div>
  );
};
