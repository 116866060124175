import React, { useMemo, useState } from 'react';
import { Course, useCourse } from '@brainstud/academy-api';
import {
  Button,
  Callout,
  ContentModal,
  Table,
  Td,
  Th,
  Tr,
} from '@brainstud/ui';
import { GetApp } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Loading, Tabs, TabsItem } from 'Components';
import { useTranslator } from 'Providers/Translator';
import { CertificationsDocumentsTables, DossierInfo } from '../Components';
import styles from './InfoModal.module.css';

const cx = classNames.bind(styles);

export interface InfoModalProps {
  course: Course;
  closeModal: () => void;
}

const InfoModal = ({ course, closeModal }: InfoModalProps) => {
  const [t] = useTranslator();
  const [active, setActive] = useState<string>('learningSet');
  const [{ data: courseWithSets }, { isLoading }] = useCourse({
    course: course.id,
    include: ['certifications', 'certifications.dossiers'],
  });
  const coachConditions = course?.coachConditions?.().downloadUrl;
  const collections = useMemo(
    () => courseWithSets?.learningObjectCollections?.() || [],
    [courseWithSets]
  );
  const certifications = useMemo(
    () => courseWithSets?.certifications?.() || [],
    [courseWithSets]
  );

  return (
    <ContentModal
      className={cx(styles.base)}
      onClickOutside
      onClose={closeModal}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <ContentModal.Header withTabs>
            <h3>
              {t('modals.infoModal.title', { title: courseWithSets?.title })}
            </h3>
            <Tabs className={cx(styles.tabs)}>
              <TabsItem
                active={active === 'coreTasks'}
                onClick={() => setActive('coreTasks')}
              >
                {t('modals.infoModal.coreTasks.label')}
              </TabsItem>
              <TabsItem
                active={active === 'learningSet'}
                onClick={() => setActive('learningSet')}
              >
                {t('modals.infoModal.learningSet.label')}
              </TabsItem>
              <TabsItem
                active={active === 'exams'}
                onClick={() => setActive('exams')}
              >
                {t('modals.infoModal.exams.label')}
              </TabsItem>
            </Tabs>
          </ContentModal.Header>
          <div className={cx(styles.tableWrap)}>
            {active === 'coreTasks' && (
              <>
                {!!coachConditions && (
                  <Button href={coachConditions} download>
                    <GetApp fontSize="large" />
                    {t('modals.infoModal.coreTasks.download')}
                  </Button>
                )}

                {certifications.length !== 0 ? (
                  certifications.map((certification) =>
                    certification
                      .certificationDossiers?.()
                      .map((dossier) => (
                        <DossierInfo key={dossier.id} dossier={dossier} />
                      ))
                  )
                ) : (
                  <Callout info>
                    {t('modals.infoModal.coreTasks.notDefined')}
                  </Callout>
                )}
              </>
            )}
            {active === 'learningSet' && (
              <>
                {['app', 'content_package'].includes(
                  course.opener || 'default'
                ) ? (
                  <Callout info>
                    {t('modals.infoModal.learningSet.external_content')}
                  </Callout>
                ) : (
                  <>
                    <p>{t('modals.infoModal.learningSet.title')}</p>
                    {collections.length === 0 ? (
                      <Callout warning>
                        {t('modals.infoModal.learningSet.unavailable')}
                      </Callout>
                    ) : (
                      <Table>
                        <Table.THead>
                          <Tr>
                            <Th className="left">
                              {t('modals.infoModal.learningSet.year')}
                            </Th>
                            <Th right>
                              {t('modals.infoModal.learningSet.assignments')}
                            </Th>
                          </Tr>
                        </Table.THead>
                        <Table.TBody>
                          {collections.map((row) => (
                            <Tr key={row.id}>
                              <Td
                                to={`/coach/courses/${course.id}/collections/${row.id}/content`}
                                target="_blank"
                              >
                                {row.title}
                              </Td>
                              <Td right style={{ width: 180 }}>
                                {row.meta?.learningObjectsCount || '-'}
                              </Td>
                            </Tr>
                          ))}
                        </Table.TBody>
                      </Table>
                    )}
                  </>
                )}
              </>
            )}
            {active === 'exams' && (
              <>
                {certifications.length !== 0 ? (
                  <>
                    <p>{t('modals.infoModal.exams.title')}</p>
                    <CertificationsDocumentsTables
                      course={course.id}
                      maxVersions={1}
                    />
                  </>
                ) : (
                  <Callout info>
                    {t('modals.infoModal.exams.notDefined')}
                  </Callout>
                )}
              </>
            )}
          </div>
        </>
      )}
    </ContentModal>
  );
};

export default InfoModal;
