import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

type Listener = (route: string) => void;

/**
 * useHistory.
 *
 * Keeps record of the current route. If it changes, it will notify all
 * listeners.
 */
export function useHistory() {
  const router = useRouter();
  const [listeners, setListeners] = useState<Listener[]>([]);

  const listen = useCallback((cb: Listener) => {
    const listener = cb;
    setListeners((prevListeners) => [...prevListeners, listener]);
    return () =>
      setListeners((prevState) =>
        prevState.filter((item) => item === listener)
      );
  }, []);

  useEffect(() => {
    listeners.forEach((listener) => {
      listener(router.route);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.route]);

  return {
    ...router,
    listen,
  };
}
