import type { TCrumb } from './BreadcrumbsContext';

type TCrumbData = {
  [segment: string]: undefined | null | string | Omit<TCrumb, 'segment'>;
};

export function toCrumbArray(data: TCrumbData): TCrumb[] {
  return Object.entries(data).reduce<TCrumb[]>(
    (output, [segment, crumb]) => [
      ...output,
      ...(!!segment && typeof segment === 'string'
        ? [
            {
              segment,
              text: typeof crumb === 'object' && !!crumb ? crumb.text : crumb,
              href:
                typeof crumb === 'object' && !!crumb ? crumb.href : undefined,
            },
          ]
        : []),
    ],
    []
  );
}
