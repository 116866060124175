import React, { useEffect, useState } from 'react';
import { useScorms } from '@brainstud/academy-api';
import { Button } from '@brainstud/ui';
import { Dropdown } from '@brainstud/ui/Form Input/Dropdown/Dropdown';
import { DropdownOption } from '@brainstud/ui/Form Input/Dropdown/Option';
import { Add } from '@mui/icons-material';
import classNames from 'classnames';
import { IScormBlock } from 'Modules/course-blocks';
import { useRouter } from 'next/router';
import { useModals } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { ScormUploadModal } from 'Views/Admin/CourseContentView/ScormView/ScormUploadModal';
import styles from './ScormForm.module.css';

const cx = classNames.bind(styles);

type ScormFormProps = {
  data?: IScormBlock;
  onChange: (e: Partial<IScormBlock>) => void;
};

export function ScormForm({ data, onChange }: ScormFormProps) {
  const [t] = useTranslator();
  const router = useRouter();
  const { openModal } = useModals();
  const [scormId, setScormId] = useState(data?.scormId);
  const { courseId } = router.query as { courseId: string };

  const [scorms] = useScorms({ course: courseId });

  useEffect(() => {
    onChange?.({
      scormId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scormId]);

  return (
    <Dropdown
      label={t('course-editor.content_editor.blocks.scorm_package.name')}
      onChange={(value) => value && setScormId(value)}
      className={cx(styles.base)}
      placeholder={t(
        'course-editor.content_editor.blocks.scorm_package.select'
      )}
      block
      searchable
    >
      {scorms.map((scorm) => (
        <DropdownOption value={scorm.id}>{scorm.title}</DropdownOption>
      ))}
      <Button
        type="button"
        onClick={() =>
          openModal(ScormUploadModal, { courseId, primary: false })
        }
        quiet
        block
        outline
      >
        <Add />
        {t('views.scorm.add_package')}
      </Button>
    </Dropdown>
  );
}
