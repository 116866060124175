import { UseQueryOptions } from 'react-query';
import { useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  DossierKnowledgeSkill,
  DossierKnowledgeSkillCreateInput,
  DossierKnowledgeSkillFilters,
  DossierKnowledgeSkillIncludes,
  DossierKnowledgeSkillSorts,
  DossierKnowledgeSkillUpdateInput,
  HookParams,
  UUID,
} from '../Types';

interface IDossierKnowledgeSkillProps
  extends HookParams<
    typeof DossierKnowledgeSkillIncludes,
    typeof DossierKnowledgeSkillFilters,
    typeof DossierKnowledgeSkillSorts
  > {
  dossier?: UUID;
  coreTask?: UUID;
  knowledgeSkill?: UUID;
}

export function useDossierKnowledgeSkill(
  {
    dossier,
    coreTask,
    knowledgeSkill,
    ...queryParameters
  }: IDossierKnowledgeSkillProps = {},
  settings?: UseQueryOptions<DataDocument<DossierKnowledgeSkill>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { dossier: defaultDossier, coreTask: defaultCoreTask },
  } = context;
  const request = new ApiRequest(
    {
      enabled:
        !!(dossier || defaultDossier) &&
        !!(coreTask || defaultCoreTask) &&
        !!knowledgeSkill,
      baseName: 'v1.dossiers.knowledge_skill',
      baseUri: `/v1/dossiers/${dossier || defaultDossier}/core_tasks/${coreTask || defaultCoreTask}/knowledge_skills`,
      uri: `/${knowledgeSkill}`,
      invalidate: ['knowledge_skill', 'knowledge_skills'],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    DossierKnowledgeSkill,
    DossierKnowledgeSkillCreateInput,
    DossierKnowledgeSkillUpdateInput
  >(request, settings);
}
