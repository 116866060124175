import { toColorPalette } from 'Utils/Colors/toColorPalette';
import { createReactReducer } from 'Utils/createReactReducer';
import ACTIONS from './ThemeActions';
import { ITheme, TKeyValuePair } from './ThemeInterface';

export interface IThemeState extends ITheme {
  loaded: boolean;
  customized?: boolean;
}

type TSetThemeAction = {
  type: typeof ACTIONS.SET_THEME;
  payload: Omit<IThemeState, 'loaded'>;
};
type TUpdateThemeAction = {
  type: typeof ACTIONS.UPDATE_THEME;
  payload: Omit<Partial<IThemeState>, 'loaded'>;
};
type TUpdateColorsAction = {
  type: typeof ACTIONS.UPDATE_COLORS;
  payload: TKeyValuePair;
};
type TUpdateStylesAction = {
  type: typeof ACTIONS.UPDATE_STYLES;
  payload: TKeyValuePair;
};

export type TThemeActions =
  | TUpdateThemeAction
  | TSetThemeAction
  | TUpdateColorsAction
  | TUpdateStylesAction;

export const ThemeReducer = createReactReducer<IThemeState, TThemeActions>({
  [ACTIONS.SET_THEME]: ({ payload }) => ({
    loaded: true,
    colors: Object.entries(payload.colors || {}).reduce(
      (output, [colorName, colorValue]) => ({
        ...output,
        ...toColorPalette(colorName, colorValue),
      }),
      {}
    ),
    styles: payload.styles,
    assets: payload.assets,
    version: payload.version,
  }),
  [ACTIONS.UPDATE_THEME]: ({ payload }) => ({
    ...(payload.colors
      ? {
          colors: Object.entries(payload.colors || {}).reduce(
            (output, [colorName, colorValue]) => ({
              ...output,
              ...toColorPalette(colorName, colorValue),
            }),
            {}
          ),
        }
      : {}),
    ...(payload.styles ? { styles: payload.styles } : {}),
    ...(payload.assets ? { assets: payload.assets } : {}),
    ...(payload.version ? { version: payload.version } : {}),
  }),
  [ACTIONS.UPDATE_COLORS]: ({ payload }, { colors }) => ({
    colors: Object.entries(payload || {}).reduce(
      (output, [colorName, colorValue]) => ({
        ...output,
        ...toColorPalette(colorName, colorValue),
      }),
      colors || {}
    ),
  }),
  [ACTIONS.UPDATE_STYLES]: ({ payload }, { styles }) => ({
    styles: {
      ...styles,
      ...payload,
    },
  }),
});
