import React, { useCallback, useMemo } from 'react';
import { LearningObject, LearningSubject } from '@brainstud/academy-api';
import classNames from 'classnames/bind';
import { Link, ObjectCard, SubjectCard } from 'Components';
import { useRouter } from 'next/router';
import { TNodeListItem } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { LearningRouteNode } from './LearningRouteNode/LearningRouteNode';
import styles from './RouteActionCardsGrid.module.css';

const cx = classNames.bind(styles);

type Props = {
  item?: TNodeListItem;
  searchTerm?: string;
  parentHasCondition?: boolean;
};

export const RouteActionCardsGrid = ({
  item,
  searchTerm,
  parentHasCondition,
}: Props) => {
  const [t] = useTranslator();
  const router = useRouter();
  const { courseId } = router.query as { courseId: string };
  let isFound;

  const searchRegex = useMemo(
    () =>
      !!searchTerm && new RegExp(searchTerm?.replace(/[^0-9a-z\s]/gi, ''), 'i'),
    [searchTerm]
  );

  const filterItems = useCallback(
    (passedItem) => {
      if (passedItem.resourceType === 'learning_route_nodes') {
        return passedItem.scheme.filter(
          (object: LearningObject | LearningSubject) => {
            if (searchRegex) {
              if (object.resourceType === 'learning_subjects') {
                const validNestedObjects = object
                  .learningObjects?.()
                  .filter((learningObject: LearningObject) =>
                    searchRegex.test(learningObject.title)
                  );
                return (
                  searchRegex.test(object.title) || validNestedObjects?.length
                );
              }
              if (object.resourceType === 'learning_objects') {
                return searchRegex.test(object.title);
              }
              return false;
            }
            return true;
          }
        );
      }
      if (passedItem.resourceType === 'learning_subjects') {
        const nestedLearningObjects = passedItem.learningObjects?.();
        if (nestedLearningObjects) {
          return nestedLearningObjects.filter(
            (learningObject: LearningObject) =>
              !searchRegex || searchRegex.test(learningObject.title)
          );
        }
      }
    },
    [searchRegex]
  );

  switch (item?.resourceType) {
    case 'learning_route_nodes': {
      const filteredItems = filterItems(item);
      return (!!searchTerm && item.scheme.length === 0) ||
        filteredItems.length === 0 ? null : (
        <LearningRouteNode
          learningRouteNode={item}
          filteredItems={filteredItems}
          searchTerm={searchTerm}
        />
      );
    }
    case 'learning_subjects': {
      isFound = !searchRegex || searchRegex.test(item.title);
      const filteredNestedLearningObjects = filterItems(item) || [];
      if (searchTerm) {
        if (isFound || filteredNestedLearningObjects.length !== 0) {
          return (
            <>
              <div className={cx(styles.item)}>
                <strong className={cx(styles['learning-subject'])}>
                  {t('components.learning_route_item.learning_subject')}
                  <Link
                    key={item.id}
                    href={`/courses/${courseId}/subjects/${item.id}`}
                  >
                    {`'${item.title}'`}
                  </Link>
                  {item.scheme || filteredNestedLearningObjects ? (
                    <span className={cx(styles.counter)}>
                      (
                      {filteredNestedLearningObjects.length === 0
                        ? item.scheme.length
                        : filteredNestedLearningObjects.length}
                      )
                    </span>
                  ) : null}
                </strong>
              </div>
              {filteredNestedLearningObjects.map((subItem: LearningObject) => (
                <div key={subItem.id} className={cx(styles.item)}>
                  <ObjectCard object={subItem} />
                </div>
              ))}
            </>
          );
        }
        return null;
      }
      return (
        <div key={item.id} className={cx(styles.item)}>
          <SubjectCard
            key={item.id}
            subject={item}
            parentHasCondition={parentHasCondition}
          />
        </div>
      );
    }
    case 'learning_objects': {
      isFound = !searchRegex || searchRegex.test(item.title);
      return !isFound ? null : (
        <div key={item.id} className={cx(styles.item)}>
          <ObjectCard object={item} parentHasCondition={parentHasCondition} />
        </div>
      );
    }
    default:
      return null;
  }
};
