/* eslint-disable */
import React, { CSSProperties, PropsWithChildren, useCallback } from 'react';
import classNames from 'classnames/bind';
import styles from './TrThTd.module.css';
import { useTableProvider } from '../Context/useTableProvider';

const classes = classNames.bind(styles);

interface Props extends React.ComponentProps<'th'> {
  sortOn?: string;
  style?: CSSProperties;
  className?: string;
  center?: boolean;
  collapse?: boolean;
  right?: boolean;
}

/**
 * Table head cell
 */
export const Th = ({
  sortOn,
  center,
  right,
  className,
  style,
  collapse,
  children,
  ...props
}: PropsWithChildren<Props>) => {
  const { sortOn: isSortedOn, dispatch } = useTableProvider();

  const handleOrderBy = useCallback(() => {
    dispatch({ type: 'TOGGLE_SORT', payload: sortOn });
  }, []);

  return sortOn ? (
    <th
      className={classes(
        styles.base,
        { center, right, collapse },
        'ui-th__base',
        'ui-th__sortable',
        className
      )}
      style={style}
      {...props}
    >
      <button
        type="button"
        className={classes(styles.button, styles.sort)}
        onClick={handleOrderBy}
      >
        <React.Fragment>{children}</React.Fragment>
        <svg
          width="6"
          height="11"
          viewBox="0 0 6 11"
          xmlns="http://www.w3.org/2000/svg"
          className={classes('icon-sort', 'ui-th__sort-icon')}
        >
          <path
            className={classes({ isActive: isSortedOn === `-${sortOn}` })}
            d="M5.75226 2.25953L3.58807 0.229262C3.26218 -0.0764537 2.73576 -0.0764537 2.40987 0.229262L0.245675 2.25953C-0.280751 2.75338 0.0952682 3.59998 0.83895 3.59998L5.16735 3.59998C5.91103 3.59998 6.27869 2.75338 5.75226 2.25953Z"
          />
          <path
            className={classes({ isActive: isSortedOn === sortOn })}
            d="M0.247736 8.5404L2.41193 10.5707C2.73782 10.8764 3.26424 10.8764 3.59013 10.5707L5.75432 8.5404C6.28075 8.04655 5.90473 7.19995 5.16105 7.19995L0.832654 7.19995C0.088972 7.19995 -0.278691 8.04655 0.247736 8.5404Z"
          />
        </svg>
      </button>
    </th>
  ) : (
    <th
      className={classes(
        styles.base,
        { center, right },
        'ui-th__base',
        className
      )}
      style={style}
      {...props}
    >
      {children}
    </th>
  );
};
