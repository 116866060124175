import { UseQueryOptions } from 'react-query';
import { useIndex, useShow } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  Portal,
  PortalFilters,
  PortalIncludes,
  PortalMeta,
  PortalSort,
  UUID,
} from '../Types';

interface IPortalProps
  extends HookParams<
    typeof PortalIncludes,
    PortalFilters,
    PortalSort,
    PortalMeta
  > {
  portal?: UUID;
}

export function usePortal(
  { portal, ...queryParameters }: IPortalProps = {},
  settings?: UseQueryOptions<DataDocument<Portal>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { portal: defaultPortal },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(portal || defaultPortal),
      baseName: 'v1.portals',
      baseUri: '/v1/portals',
      invalidate: ['portal', 'portals', 'v1.accounts.portals'],
      uri: `/${portal}`,
      queryParameters,
    },
    context
  );
  return useShow<Portal>(request, settings);
}

export function usePortals(
  { ...queryParameters }: Omit<IPortalProps, 'portal'> = {},
  settings?: UseQueryOptions<DataDocument<Portal[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.portals',
      baseUri: '/v1/portals',
      invalidate: ['order', 'portals', 'v1.accounts.portals'],
      queryParameters,
    },
    context
  );
  return useIndex<Portal>(request, settings);
}
