import { useContext } from 'react';
import { ErrorDataContext, IErrorDataContext } from './ErrorDataContext';

/**
 * useErrorDataProvider.
 *
 * [DESCRIPTION]
 *
 * @param nullable When true, this hook returns null when no context is found.
 */
export function useErrorDataProvider(nullable?: false): IErrorDataContext;
export function useErrorDataProvider(nullable?: true): null | IErrorDataContext;
export function useErrorDataProvider(nullable = false) {
  const context = useContext(ErrorDataContext);

  if (!context && !nullable) {
    throw new Error('Please, initiate the ErrorDataProvider first!');
  }

  return context;
}
