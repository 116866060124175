import React, { useMemo } from 'react';
import classNames from 'classnames/bind';
import { CheckboxButtonProps } from './CheckboxButtonProps';
import styles from './CheckboxButton.module.css';

const classes = classNames.bind(styles);

/**
 * Shows a button styled checkbox input.
 */
export const CheckboxButton = React.forwardRef<
  HTMLInputElement,
  CheckboxButtonProps
>(
  (
    {
      id,
      checked,
      defaultChecked,
      name,
      loading,
      label,
      onChange,
      onMouseOver,
      onInput,
      onBeforeInput,
      disabled,
      onFocus,
      onBlur,
      onClick,
      onInvalid,
      onReset,
      onSubmit,
      className,
      style,
    },
    ref
  ) => {
    const ensuredName = useMemo(
      () => name || `checkbox-button_${Math.random()}`,
      [name]
    );

    return (
      <div
        className={classes(styles.base, { loading, disabled }, className)}
        style={style}
      >
        <label htmlFor={id || ensuredName} className={classes(styles.label)}>
          <input
            className={classes(styles.input)}
            id={id || ensuredName}
            name={ensuredName}
            type="checkbox"
            onFocus={onFocus}
            onBlur={onBlur}
            ref={ref}
            defaultChecked={defaultChecked}
            checked={checked}
            onMouseOver={onMouseOver}
            onClick={onClick}
            onBeforeInput={onBeforeInput}
            onSubmit={onSubmit}
            onInput={onInput}
            onInvalid={onInvalid}
            onReset={onReset}
            onChange={onChange}
            disabled={disabled || loading}
          />
          <div className={classes(styles.button)}>
            <svg
              className={classes(styles.icon)}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
            </svg>
            <span className={styles['text-label']}>{label}</span>
          </div>
        </label>
      </div>
    );
  }
);
