import { createContext } from 'react';
import { useAnswer } from '@brainstud/academy-api';
import { TField } from 'Modules/universal-components';

export type UseAnswerReturnType = ReturnType<typeof useAnswer>[0];

interface TRecursiveBlockParts {
  /**
   * blocks.
   * The blocks within this block.
   */
  blocks?: TBlock[];
  /**
   * Inner tabs with blocks as content.
   * @deprecated: Use .blocks instead.
   */
  tabs?: Array<{
    [key: string]: any;
    block: TBlock;
  }>;
  /**
   * Contains a single container block to render the inner blocks.
   * @deprecated: Use .blocks instead.
   */
  block?: Omit<TBlock, 'blocks'> & Required<Pick<TBlock, 'blocks'>>;
}

export interface TBlock extends Partial<TRecursiveBlockParts> {
  id: string;
  type: string;
  [key: string]: any;
}

export type BlockState = {
  id: string;
  type: string;
  loaded?: true;
  touched: boolean;
  read: boolean;

  static?: boolean;
  open?: boolean;
  closed?: boolean;
  submittable: boolean;

  validated?: boolean;
  fields?: TField[];
};

export interface IContentBlocksContext {
  /** True when the learning object contents only consists of static blocks */
  isStatic: boolean;
  /** Holds all state for blocks */
  states: BlockState[];
  /** True whilst loading assignment contents and answers */
  isLoading: boolean;
  /** Method to update the block state of a specific block by ID */
  setBlockById: (id: string, value: Partial<BlockState>) => void;
  /** Disables all interaction with the Assignment */
  readonly?: boolean;
  /** All available blocks for the context */
  blocks: TBlock[];
  /** Whether every block can be considered 'read' (read/open/closed) */
  isRead: boolean;
  /** True when the learning object contents contain blocks that are either touched, static, or unsubmittable */
  isSubmittable: boolean;
  /** Reset touched states for all blocks  */
  resetTouched: () => void;
}

export const ContentBlocksContext = createContext<null | IContentBlocksContext>(
  null
);
