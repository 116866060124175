import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Indicator, Input, InputProps, Textarea } from '@brainstud/ui';
import styles from './AutosaveInput.module.css';

interface AutosaveInputProps extends InputProps {
  defaultValue?: string;
  label?: string;
  name: string;
  onSave?: (value?: string | number) => Promise<any>;
  inputType?: 'input' | 'textarea';
}

export const AutosaveInput = ({
  defaultValue = '',
  label = undefined,
  name,
  onSave,
  inputType = 'input',
}: AutosaveInputProps) => {
  const [value, setValue] = useState(defaultValue);
  const [savedValue, setSavedValue] = useState(defaultValue);
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const dirty = useMemo(() => value !== savedValue, [value, savedValue]);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(true);
  const [error, setError] = useState(false);

  const handleSave = useCallback(() => {
    if (dirty) {
      if (timer.current) clearTimeout(timer.current);
      if (onSave) {
        setLoading(true);
        setSuccess(false);
        onSave(value)
          .then(() => {
            setLoading(false);
            setSuccess(true);
          })
          .catch(() => {
            setLoading(false);
            setError(true);
          });
      }
      setSavedValue(value);
    }
  }, [dirty, value, onSave]);

  useEffect(() => {
    if (dirty) {
      timer.current = setTimeout(() => handleSave(), 1000);
      return () => {
        if (timer.current) clearTimeout(timer.current);
      };
    }
  }, [value, savedValue]);

  return (
    <div className={styles.base}>
      {inputType === 'input' && (
        <Input
          label={label}
          name={name}
          value={value}
          onBlur={handleSave}
          onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            setValue(e.target.value)
          }
        />
      )}
      {inputType === 'textarea' && (
        <Textarea
          label={label}
          name={name}
          value={value}
          onBlur={handleSave}
          onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            setValue(e.target.value)
          }
        />
      )}

      <div className={styles.statusWrap}>
        <Indicator loading={loading} success={success} error={error} />
      </div>
    </div>
  );
};
