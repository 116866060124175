import React, { FC } from 'react';
import {
  LearningRouteEditorProvider,
  SubjectEditBar,
  useSubjectEditor,
} from '@brainstud/course-editor';
import { Container } from 'Components';
import { useEnvironmentProvider } from 'Providers';
import { LearningSubjectEditView, SubjectView } from 'Views';

/**
 * The View shown when editing a learning subject
 * FIXME should be moved to views folder
 */
export const SubjectEditView: FC = () => {
  const { preview, learningSubject } = useSubjectEditor();

  const { isCoachEnvironment } = useEnvironmentProvider();

  return (
    <LearningRouteEditorProvider>
      {isCoachEnvironment && <SubjectEditBar coach />}
      <Container>
        <>
          {preview ? (
            <SubjectView learningSubject={learningSubject} />
          ) : (
            <LearningSubjectEditView />
          )}
        </>
      </Container>
    </LearningRouteEditorProvider>
  );
};
