import { UseQueryOptions } from 'react-query';
import { useShow } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import { LearningObjectCollectionStatistics, UUID } from '../Types';

interface Props {
  learningObjectCollection?: UUID;
}

export function useLearningObjectCollectionStatistics(
  { learningObjectCollection }: Props = {},
  settings?: UseQueryOptions<
    DataDocument<LearningObjectCollectionStatistics>,
    ErrorDocument
  >
) {
  const context = useApi();
  const {
    parameters: { learningObjectCollection: defaultLearningObjectCollection },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(learningObjectCollection || defaultLearningObjectCollection),
      baseName: 'v1.learningObjectCollections.statistics',
      baseUri: `/v1/learning_object_collections/${learningObjectCollection || defaultLearningObjectCollection}/statistics`,
    },
    context
  );
  return useShow<LearningObjectCollectionStatistics>(request, settings);
}
