import React, { useEffect, useRef } from 'react';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';
import { useModals } from '../useModals';
import styles from './Modal.module.css';

const cx = classNames.bind(styles);

/**
 * ModalSwitch.
 *
 * The modal switch renders the modal component with a backdrop when set with the ModalProvider
 */
export const ModalSwitch = () => {
  const { Modal, closeModal } = useModals();
  const { asPath } = useRouter() || {};
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (Modal && container.current) {
      document.documentElement.classList.add('disable-scroll');
      disableBodyScroll(container.current);
      return () => {
        document.documentElement.classList.remove('disable-scroll');
        clearAllBodyScrollLocks();
      };
    }
  }, [Modal, container]);

  useEffect(() => {
    if (Modal && asPath) {
      closeModal();
    }
    // Only run when the path changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asPath]);

  if (Modal) {
    const className = Modal?.data?.className || null;
    return (
      <div ref={container} className={cx(styles.base, className)}>
        {/* eslint-disable react/jsx-props-no-spreading */}
        <Modal.Component closeModal={closeModal} {...(Modal.data || {})} />
      </div>
    );
  }
  return null;
};
