/* eslint-disable no-param-reassign */
import { isPresent } from 'ts-is-present';
import { v4 } from 'uuid';
import { UnknownBlock } from '../Types';

export type PartialSome<T, U extends keyof T> = Partial<T> & Omit<T, U>;

type FindBlockFunc = (
  tree: UnknownBlock[],
  searchId: string,
  reference?: boolean
) => undefined | UnknownBlock;
export const findBlockById: FindBlockFunc = (tree, searchId, reference) =>
  tree.reduce<undefined | UnknownBlock>((result, item) => {
    if (result) return result;
    if (item.id === searchId) {
      return reference ? item : { ...item };
    }
    if (item.blocks) {
      return findBlockById(item.blocks, searchId, reference);
    }
    return result;
  }, undefined);

type AppendBlockFunc = (
  tree: UnknownBlock[],
  block: UnknownBlock,
  containerId?: string
) => UnknownBlock[];
/**
 * Append block to a container block
 */
export const findAndAppendBlock: AppendBlockFunc = (
  tree,
  block,
  containerId
) => {
  if (!containerId) {
    return [...tree, block];
  }
  return tree.map((item) => {
    if (item.id === containerId && item.blocks) {
      item.blocks = [...item.blocks, block];
      return { ...item };
    }
    if (item.blocks) {
      item.blocks = findAndAppendBlock(item.blocks, block, containerId);
    }
    return item;
  });
};
/**
 * Prepend block (add as first item) to a container block
 */
export const findAndPrependBlock: AppendBlockFunc = (
  tree,
  block,
  containerId
) => {
  if (!containerId) {
    return [block, ...tree];
  }
  return tree.map((item) => {
    if (item.id === containerId && item.blocks) {
      item.blocks = [block, ...item.blocks];
      return { ...item };
    }
    if (item.blocks) {
      item.blocks = findAndPrependBlock(item.blocks, block, containerId);
    }
    return item;
  });
};

/**
 * Replace block with another block
 */
export const findAndReplaceBlock = (
  tree: UnknownBlock[],
  searchId: string,
  replaceWith: UnknownBlock
) =>
  tree.map((item) => {
    if (item.id === searchId) {
      return replaceWith;
    }
    if (item.blocks) {
      item.blocks = findAndReplaceBlock(item.blocks, searchId, replaceWith);
    }
    return item;
  });

/**
 * Update values of a block without overwriting the other values
 */
export const findAndUpdateBlock = (
  tree: UnknownBlock[],
  searchId: string,
  replaceWith: UnknownBlock
) =>
  tree.map((item) => {
    if (item.id === searchId) {
      return { ...item, ...replaceWith };
    }
    if (item.blocks) {
      item.blocks = findAndUpdateBlock(item.blocks, searchId, replaceWith);
    }
    return { ...item };
  });

/**
 * Find and remove blocks by ID
 */
export const findAndRemoveBlock = (tree: UnknownBlock[], searchId: string) =>
  tree.reduce<UnknownBlock[]>((result, item) => {
    if (item.id !== searchId) {
      if (item.blocks) {
        item.blocks = findAndRemoveBlock(item.blocks, searchId);
      }
      return [...result, item];
    }
    return [...result];
  }, []);

/**
 * Swap two blocks in place based on ID
 */
export const swapBlocksInTree = (
  tree: UnknownBlock[],
  firstBlockId: string,
  secondBlockId: string
) => {
  const firstBlock = findBlockById(tree, firstBlockId);
  const secondBlock = findBlockById(tree, secondBlockId);
  if (firstBlock && secondBlock) {
    firstBlock.id = 'swapping';
    const firstReplacement = findAndReplaceBlock(
      tree,
      secondBlock.id,
      firstBlock
    );
    const secondReplacement = findAndReplaceBlock(
      firstReplacement,
      firstBlockId,
      secondBlock
    );
    firstBlock.id = firstBlockId;
    return secondReplacement;
  }
  return tree;
};

/**
 * Reduces the list of blocks to a flat list of blocks
 */
export const flattenBlockList = (blocks: UnknownBlock[]): UnknownBlock[] =>
  blocks.reduce<UnknownBlock[]>((blockList, currentValue) => {
    const container =
      currentValue?.blocks ||
      currentValue?.tabs?.map((tab) => tab.block) ||
      currentValue?.block?.blocks;
    return [
      ...blockList,
      container
        ? [currentValue, ...flattenBlockList(container)].flat()
        : currentValue,
    ]
      .flat()
      .filter(isPresent);
  }, []);

export function reIdentifyBlockTree(block: UnknownBlock): UnknownBlock {
  return {
    ...block,
    ...(block.blocks ? { blocks: block.blocks.map(reIdentifyBlockTree) } : {}),
    id: v4(),
  };
}
