import React, { useCallback, useState } from 'react';
import { useApi, useQuiz } from '@brainstud/academy-api';
import { Button, Input, SidebarModal, UploadBox } from '@brainstud/ui';
import { Form } from '@brainstud/universal-components';
import classNames from 'classnames';
import { FlexContainer, RichTextEditor } from 'Components';
import { useRouter } from 'next/router';
import {
  ILearningSubjectWithResources,
  useModals,
  useToaster,
} from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import { PreviewQuiz } from '../Previews';
import styles from './QuizModal.module.css';

const cx = classNames.bind(styles);

type Props = {
  nodeId?: string;
  learningSubject?: ILearningSubjectWithResources;
  parentSubjectId?: string;
};

type FormData = {
  features: string[];
  labels: string[];
  title: string;
  introduction: string;
  quiz_type: 'practice' | 'end';
  minimal_score: number;
};

export const QuizModal = ({
  nodeId,
  learningSubject,
  parentSubjectId,
}: Props) => {
  const [t] = useTranslator();
  const apiConfig = useApi();
  const { closeModal } = useModals();
  const router = useRouter();
  const [setToast] = useToaster();
  const { collectionId } = router.query as { collectionId: string };
  const [title, setTitle] = useState(learningSubject?.title);
  const [fileUrl, setFileUrl] = useState(learningSubject?.poster?.src);
  const quiz = learningSubject?.quiz?.();

  const [{ createOrUpdate: createOrUpdateQuiz }] = useQuiz(
    {
      quiz: quiz?.id,
    },
    { enabled: false }
  );

  const handleSubmit = useCallback(
    async (formData: FormData) => {
      await createOrUpdateQuiz.mutateAsync(
        {
          poster_url: fileUrl,
          scheme: [],
          layout: 'quiz',
          title: formData.title,
          introduction: formData.introduction,
          retryStyle: 'quiz',
          feedbackStyle: 'after_quiz',
          minimalScore: formData.minimal_score,
          ...(nodeId || collectionId
            ? {
                relationships: {
                  ...(collectionId
                    ? { learning_object_collection: collectionId }
                    : {}),
                  ...(nodeId && { learning_route_node: nodeId }),
                  ...(parentSubjectId && { learning_subject: parentSubjectId }),
                },
              }
            : {}),
        },
        {
          onSuccess: () => {
            closeModal();
          },
          onError: () => {
            setToast(
              t('views.courses.collection_edit.quiz_modal.form.error'),
              'error'
            );
          },
        }
      );
    },
    [
      fileUrl,
      nodeId,
      collectionId,
      createOrUpdateQuiz,
      setToast,
      t,
      closeModal,
      parentSubjectId,
    ]
  );

  return (
    <SidebarModal onClose={closeModal} onClickOutside size="medium">
      <Form<FormData> onSubmit={handleSubmit}>
        <SidebarModal.Header>
          <FlexContainer row justifyContent="space-between" alignItems="center">
            <h3>
              {t(
                `views.courses.collection_edit.quiz_modal.title.${!learningSubject ? 'create' : 'edit'}`
              )}
            </h3>
            <Button type="submit" loading={createOrUpdateQuiz.isLoading}>
              {t(`modals.submit.${!learningSubject ? 'create' : 'edit'}`)}
            </Button>
          </FlexContainer>
        </SidebarModal.Header>
        <FlexContainer gapSize="medium">
          <Input
            label={t('views.courses.collection_edit.quiz_modal.form.title')}
            name="title"
            defaultValue={learningSubject?.title}
            placeholder={t(
              'views.courses.collection_edit.quiz_modal.form.title'
            )}
            onChange={(e) => setTitle(e.target.value)}
            rules="required"
          />
          <RichTextEditor
            id="introduction"
            label={t('views.courses.collection_edit.quiz_modal.form.intro')}
            defaultValue={learningSubject?.introduction}
            name="introduction"
            rules={['required']}
          />
          {/* Quiz */}
          <FlexContainer row>
            <Input
              label={t(
                'views.courses.collection_edit.quiz_modal.form.minimal_score'
              )}
              name="minimal_score"
              defaultValue={quiz?.minimalScore?.toString()}
              placeholder="70"
              type="number"
              rules="required"
              max={100}
            />
          </FlexContainer>
          <strong>
            {t('views.courses.collection_edit.quiz_modal.form.thumb_nail')}
          </strong>
          <FlexContainer row justifyContent="space-between">
            <FlexContainer className={cx(styles.preview)}>
              <PreviewQuiz title={title} poster={fileUrl} />
            </FlexContainer>
            <div className={cx(styles.uploadBox)}>
              <UploadBox
                preview={false}
                url={`${apiConfig.baseUrl}/v1/services/media_upload`}
                headers={{ ...apiConfig.headers }}
                paramName="files[0]"
                maxFiles={1}
                label={t('views.courses.collection_edit.form.image')}
                onAfterFileUpload={(file, response) => {
                  setFileUrl(response?.data?.[0]?.attributes?.file_url);
                }}
              />
              <span
                dangerouslySetInnerHTML={{
                  __html: sanitizer(
                    t('views.courses.collection_edit.form.image_instructions')
                  ),
                }}
              />
            </div>
          </FlexContainer>
        </FlexContainer>
      </Form>
    </SidebarModal>
  );
};
