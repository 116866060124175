import { UseQueryOptions } from 'react-query';
import { useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  ObjectCondition,
  ObjectConditionCreate,
  ObjectConditionFilters,
  ObjectConditionIncludes,
  ObjectConditionSort,
  ObjectConditionUpdate,
  UUID,
} from '../Types';

interface IObjectConditionsProps
  extends HookParams<
    ObjectConditionIncludes,
    ObjectConditionFilters,
    ObjectConditionSort
  > {
  objectType?: string;
  condition?: UUID;
  objectIdentifier?: UUID;
}

/**
 * You can add conditions to objects, update and delete a condition is also possible.
 * @param objectType you can add a condition to LearningObjects and LearningRouteNodes
 * @param objectIdentifier on this object the condition is applied
 * @param condition
 * @param queryParameters
 * @param settings
 * @returns ObjectConditions
 */

export function useObjectCondition(
  {
    // TODO extend with nfc_tag_scanned
    objectType,
    objectIdentifier,
    condition,
    ...queryParameters
  }: IObjectConditionsProps,
  settings?: UseQueryOptions<DataDocument<ObjectCondition>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      enabled: !!condition,
      baseName: `v1.${objectType}.conditions`,
      baseUri: `/v1/${objectType}/${objectIdentifier}/conditions`,
      uri: `/${condition}`,
      invalidate: [
        'object_condition',
        'v1.learningObjectCollections',
        'v1.enrollments.learningObjectCollections',
      ],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    ObjectCondition,
    ObjectConditionCreate,
    ObjectConditionUpdate
  >(request, settings);
}
