import React, { useCallback, useMemo, useState } from 'react';
import { ISliderContainerBlock, Slider } from '@brainstud/course-blocks';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import { EditorButton } from '../../../Components';
import { createEmptyContainerBlock } from '../../../Support/createEmptyContainerBlock';
import { GroupForm } from '../Group';
import styles from './SliderForm.module.css';

const cx = classNames.bind(styles);

export type SliderFormProps = {
  data: ISliderContainerBlock;
  onChange: (data: Omit<ISliderContainerBlock, 'id' | 'type'>) => void;
};

export const SliderForm = ({ data, onChange }: SliderFormProps) => {
  const [t] = useTranslator();

  const blocks = useMemo(() => data.blocks || [], [data.blocks]);

  const [defaultActive, setDefaultActive] = useState(0);
  const handleAddSlide = useCallback(
    (idx: number) => {
      const prevBlocks = [...blocks];
      prevBlocks.splice(idx + 1, 0, createEmptyContainerBlock());
      onChange?.({ blocks: prevBlocks });
      setTimeout(() => {
        setDefaultActive(idx + 1);
      }, 500);
    },
    [blocks, onChange]
  );

  const handleRemoveSlide = useCallback(
    (idx: number) => {
      const prevBlocks = [...blocks];
      prevBlocks.splice(idx, 1);
      setDefaultActive(Math.max(0, idx - 1));
      onChange?.({ blocks: prevBlocks });
    },
    [blocks, onChange]
  );

  const [activeSlide, setActiveSlide] = useState(0);

  const handleSlideChange = useCallback((slideIndex: number) => {
    setActiveSlide(slideIndex);
  }, []);

  return (
    <div className={cx(styles.base, 'editor__blocks-slider')}>
      <Slider active={defaultActive} onChange={handleSlideChange}>
        {blocks.map((block) => (
          <GroupForm key={block.id} data={block} title={data.type} />
        ))}
      </Slider>
      <div className={cx(styles.tabActions)}>
        <EditorButton onClick={() => handleRemoveSlide(activeSlide)}>
          <span>
            {t(
              'course-editor.content_editor.blocks.slider_container.remove_slide'
            )}
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="currentColor"
          >
            <path d="M18 13H6c-.55 0-1-.45-1-1s.45-1 1-1h12c.55 0 1 .45 1 1s-.45 1-1 1z" />
          </svg>
        </EditorButton>
        <EditorButton onClick={() => handleAddSlide(activeSlide)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="currentColor"
          >
            <path d="M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z" />
          </svg>
          <span>
            {t(
              'course-editor.content_editor.blocks.slider_container.add_slide'
            )}
          </span>
        </EditorButton>
      </div>
    </div>
  );
};
