import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSyncRefs } from '@brainstud/universal-components';
import classNames from 'classnames/bind';
import { SearchProps } from './SearchProps';
import styles from './Search.module.css';

const cx = classNames.bind(styles);

export const Search = React.forwardRef<HTMLInputElement, SearchProps>(
  (
    {
      placeholder,
      icon,
      block,
      autoComplete,
      autoFocus = false,
      disabled = false,
      onInput,
      onBlur,
      onFocus,
      onClick,
      tabIndex,
      onMouseOver,
      onReset,
      onClose,
      onInvalid,
      value: controlledValue,
      defaultValue,
      debounce = 350,
      onSubmit,
      onChange,
      onKeyUp,
      onKeyDown,
      onBeforeInput,
      className,
      style,
    },
    ref
  ) => {
    const input = useSyncRefs(ref, null);

    const [value, setValue] = useState(defaultValue);

    const actualValue =
      (controlledValue !== undefined ? controlledValue : value) || '';

    // Debounced onChange handler
    const buffer = useRef<ReturnType<typeof setTimeout>>();

    useEffect(() => () => clearTimeout(buffer.current), []);

    const handleChange = useCallback(
      (event) => {
        clearTimeout(buffer.current);
        setValue(event.target.value);
        buffer.current = setTimeout(() => {
          onChange?.(event);
        }, debounce);
      },
      [debounce, onChange]
    );

    const Icon = icon || (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M15.5 14h-.79l-.28-.27c1.2-1.4 1.82-3.31 1.48-5.34-.47-2.78-2.79-5-5.59-5.34-4.23-.52-7.79 3.04-7.27 7.27.34 2.8 2.56 5.12 5.34 5.59 2.03.34 3.94-.28 5.34-1.48l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 0 .41-.41.41-1.08 0-1.49L15.5 14zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
      </svg>
    );

    const handleClose = useCallback<Exclude<SearchProps['onClose'], undefined>>(
      (event) => {
        if (input.current) {
          setValue('');
          onClose?.(event);
          input.current.blur();
        }
      },
      [input, onClose]
    );

    return (
      <div
        className={cx(
          styles.base,
          { block, 'ui__search-expands': block },
          'ui__search-base',
          className
        )}
        style={style}
      >
        <input
          type="search"
          disabled={disabled}
          autoComplete={autoComplete || 'off'}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
          tabIndex={tabIndex}
          onKeyUp={onKeyUp}
          onInput={onInput}
          onReset={onReset}
          onInvalid={onInvalid}
          onSubmit={onSubmit}
          onBeforeInput={onBeforeInput}
          value={actualValue}
          onChange={handleChange}
          onClick={onClick}
          onMouseOver={onMouseOver}
          placeholder={placeholder}
          ref={input}
          className={cx(styles.input, 'ui__search-input')}
        />
        <span className={cx(styles.searchIcon, 'ui__search-icon')}>{Icon}</span>
        {value && value.length > 0 && onClose && (
          <button
            type="button"
            onMouseDown={handleClose}
            className={cx(styles.closeButton, 'ui__search-close')}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className={styles.closeIcon}
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" />
            </svg>
          </button>
        )}
      </div>
    );
  }
);
