import React, {
  KeyboardEvent,
  KeyboardEventHandler,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useForm, useOnClickOutside } from '@brainstud/universal-components';
import classNames from 'classnames/bind';
import { useEventHandler } from 'Hooks';
import { useTranslator } from 'Providers/Translator';
import { Indicator } from '../../Loaders';
import { Search } from '../Search';
import { DropdownContext, IDropdownContext } from './DropdownContext';
import { DROPDOWN_GROUP_TOGGLE_EVENT } from './DropdownEvents';
import { DropdownReducer } from './DropdownReducer';
import { MultiDropdownProps, SingleDropdownProps } from './DropdownTypes';
import { DropdownOption } from './Option';
import styles from './Dropdown.module.css';

const cx = classNames.bind(styles);
const MAGIC_OFFSET_WIDTH = 43;
export type TSelectEvent =
  | MouseEvent<HTMLLIElement>
  | KeyboardEvent<HTMLLIElement>;

/**
 * Shows a dropdown field with a set of options to choose from.
 * Requires the use of `Dropdown.Option` or `Dropdown.Group` as children.
 *
 * When a `name` is given, the Dropdown is connected to the universal Form component (when context is available).
 */
function BaseDropdown({
  id,
  name,
  label,
  placeholder,
  rules,
  loading,
  value: controlledValue,
  defaultValue,
  multiple,
  onChange,
  defaultOpen = false,
  searchable,
  small,
  disabled,
  quiet,
  block,
  className,
  style,
  children,
  defaultUp,
}: (SingleDropdownProps | MultiDropdownProps) & { multiple?: boolean }) {
  const [t] = useTranslator();
  const [state, dispatch] = useReducer(DropdownReducer, []);
  const selected = useMemo(
    () => state.filter((item) => item.selected),
    [state]
  );
  const values = useMemo(() => selected.map((item) => item.value), [selected]);
  const isControlled = controlledValue !== undefined;
  const [search, setSearch] = useState<undefined | string>();
  const [hasOpenGroup, setHasOpenGroup] = useState(false);
  const { subscribe, emit } = useEventHandler();
  const [identifier] = useState(
    id || `dropdown_${Math.round(Math.random() * 100000)}`
  );

  useEffect(() => {
    if (controlledValue !== undefined) {
      if (!multiple) {
        dispatch({
          type: 'toggle',
          payload: {
            value: !Array.isArray(controlledValue)
              ? controlledValue
              : controlledValue[0],
          },
        });
      } else {
        dispatch({
          type: 'select-multiple',
          payload: {
            value: controlledValue,
          },
        });
      }
    }
    // eslint-disable-next-line react/destructuring-assignment
  }, [controlledValue, multiple]);

  const [isOpen, setOpen] = useState(defaultOpen);
  const handleToggleOpen = useCallback(() => {
    if (!loading) {
      setOpen((prevIsOpen) => !prevIsOpen);
      setSearch(undefined);
    }
  }, [loading]);

  const handleOnChange = useCallback(
    (value: string | string[], event?: TSelectEvent) => {
      onChange?.(value as unknown as string & string[], event);
    },
    [onChange]
  );

  const handleToggleGroup = useCallback(
    (newValues: string[]) => {
      if (!isControlled) {
        dispatch({
          type: 'toggle-multiple',
          payload: {
            value: newValues,
          },
        });
      }

      (handleOnChange as MultiDropdownProps['onChange'])?.(
        newValues.reduce(
          (prev, current) =>
            prev.includes(current)
              ? prev.filter((item) => item !== current)
              : [...prev, current],
          values
        )
      );
    },
    [handleOnChange, isControlled, values]
  );

  const handleToggleSelect = useCallback<
    IDropdownContext['handleToggleSelect']
  >(
    (event) => {
      event.preventDefault();
      const newValue =
        event.currentTarget.dataset.value || event.currentTarget.textContent;
      const isPlaceholder = placeholder === newValue;
      if (!('key' in event) || event.key === 'Enter') {
        if (!isControlled) {
          dispatch({
            type: isPlaceholder || !multiple ? 'toggle' : 'toggle-multiple',
            payload: {
              value: newValue,
            },
          });
        }

        if (!multiple) {
          handleToggleOpen();
          (handleOnChange as SingleDropdownProps['onChange'])?.(
            !isPlaceholder && newValue !== null ? newValue : undefined,
            event
          );
        } else if (isPlaceholder || newValue === null) {
          (handleOnChange as MultiDropdownProps['onChange'])?.([], event);
        } else {
          (handleOnChange as MultiDropdownProps['onChange'])?.(
            values.includes(newValue)
              ? values.filter((item) => item !== newValue)
              : [...values, newValue],
            event
          );
        }
      }
    },
    [
      dispatch,
      handleToggleOpen,
      multiple,
      handleOnChange,
      values,
      isControlled,
      placeholder,
    ]
  );

  // Set error state when dropdown is required
  const { subscribe: onFormEvent } =
    useForm<{ [key: string]: string | string[] | null | undefined }>(true) ||
    {};
  const [isInvalid, setIsInvalid] = useState(false);
  const isRequired = rules?.includes('required');

  const validateRequired = useCallback(() => {
    setIsInvalid(values.length === 0);
  }, [values]);

  useEffect(() => {
    if (onFormEvent && isRequired) {
      const submitUnsubscribe = onFormEvent('submit', validateRequired);
      const validateUnsubscribe = onFormEvent('validate', validateRequired);

      return () => {
        submitUnsubscribe();
        validateUnsubscribe();
      };
    }
  }, [values, isRequired, onFormEvent, validateRequired]);

  // Reset to default value when Form reset event fires
  useEffect(() => {
    if (onFormEvent && !isControlled) {
      const subscriptions = [
        ...(name
          ? [
              onFormEvent('load', (loadedValues) => {
                const normalizedName = name.replace('[]', '');
                if (normalizedName in loadedValues) {
                  if (Array.isArray(loadedValues[normalizedName])) {
                    dispatch({
                      type: 'select-multiple',
                      payload: {
                        value: loadedValues[normalizedName],
                      },
                    });
                  } else {
                    dispatch({
                      type: 'toggle',
                      payload: {
                        value: loadedValues[normalizedName] as string,
                      },
                    });
                  }
                }
              }),
            ]
          : []),
        onFormEvent('reset', () => {
          setIsInvalid(false);
          dispatch({
            type: 'select-multiple',
            payload: {
              value: defaultValue,
            },
          });
        }),
      ];
      return () => {
        subscriptions.map((unsubscribe) => unsubscribe());
      };
    }
  }, [onFormEvent, defaultValue, isControlled]);

  const dropdown = useRef<HTMLDivElement>(null);
  const button = useRef<HTMLButtonElement>(null);
  const drawer = useRef<HTMLUListElement>(null);

  // Calculate whether to show the options above or be low the input
  const spaceAbove = dropdown.current
    ? dropdown.current.getBoundingClientRect().top
    : 0;
  const spaceBelow =
    window.innerHeight -
    (dropdown.current ? dropdown.current.getBoundingClientRect().bottom : 0);
  const spaceRightSide =
    window.innerWidth -
    (button.current ? button.current.getBoundingClientRect().right : 0);
  const rightAligned =
    spaceRightSide < (drawer.current?.getBoundingClientRect().width || 200);
  const upsideDown =
    (spaceAbove >= Math.min(320, (([children].flat().length || 0) + 1) * 40) &&
      spaceBelow < Math.min(320, (([children].flat().length || 0) + 1) * 40)) ||
    defaultUp;

  const [width, setWidth] = useState(0);
  useEffect(() => {
    if (!block && drawer.current) {
      const drawerChildren: NodeListOf<Element> =
        drawer.current.querySelectorAll('[role="option"]');
      if (drawerChildren) {
        setWidth((prevWidth) =>
          [...Array.from(drawerChildren)].reduce(
            (newWidth, item) =>
              Math.max(item.clientWidth + MAGIC_OFFSET_WIDTH, prevWidth),
            prevWidth
          )
        );
      }
    }
  }, [small, drawer, block]);

  const valueLabel =
    selected?.map((item) => item.label).join(', ') || placeholder;

  const handleSearch = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (event) => {
      setSearch(event.currentTarget.value.toLowerCase());
    },
    []
  );

  const handleSearchClose = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event?.stopPropagation();
      setSearch(undefined);
      setOpen(true);
    },
    []
  );

  // Close dropdown on click outside
  useOnClickOutside(dropdown, () => {
    setSearch(undefined);
    setOpen(false);
  });

  const [storedScrollTop, setStoredScrollTop] = useState<number>(0);
  useEffect(
    () =>
      subscribe(DROPDOWN_GROUP_TOGGLE_EVENT, (openState: boolean) => {
        setHasOpenGroup((prevOpen) => !prevOpen);
        if (openState) {
          setStoredScrollTop(drawer?.current?.scrollTop || 0);
          drawer?.current?.scroll({ top: 0, behavior: 'smooth' });
        }
      }),
    [subscribe, storedScrollTop]
  );
  useEffect(() => {
    if (!hasOpenGroup && storedScrollTop) {
      drawer?.current?.scroll({ top: storedScrollTop });
    }
  }, [hasOpenGroup, storedScrollTop]);

  const defaultOrControlledValue = defaultValue || controlledValue;
  const context = useMemo(
    () => ({
      name,
      search,
      handleToggleSelect,
      handleToggleGroup,
      emit,
      multiple,
      state,
      defaultValue: defaultOrControlledValue,
      dispatch,
    }),
    [
      dispatch,
      name,
      state,
      search,
      multiple,
      handleToggleSelect,
      handleToggleGroup,
      defaultOrControlledValue,
      emit,
    ]
  );

  /**
   * Do this only once.
   * Do not do this when the value is controlled, as it may trigger an unwanted onChange event.
   */
  useEffect(() => {
    if (defaultValue) handleOnChange(defaultValue);
  }, []);

  return (
    <DropdownContext.Provider value={context}>
      <div
        ref={dropdown}
        className={cx(
          styles.base,
          'ui-dropdown__base',
          'dropdown-base',
          {
            isOpen,
            'has-errors': isInvalid,
            'dropdown-is-open': isOpen,
            'is-quiet': quiet,
            'dropdown-has-errors': isInvalid,
            'is-required': rules?.includes('required'),
            'is-right-aligned': rightAligned,
            small,
            disabled,
            quiet,
            block,
          },
          className
        )}
        style={style}
      >
        {label && (
          <label
            id={`${identifier}_label`}
            className={cx(styles.label, 'ui-dropdown__label', 'dropdown-label')}
            htmlFor={identifier}
          >
            {label}
          </label>
        )}

        <button
          type="button"
          id={identifier}
          aria-haspopup="listbox"
          aria-labelledby={label && `${identifier}_label`}
          aria-expanded={isOpen}
          disabled={disabled}
          className={cx(
            styles.button,
            'ui-dropdown__button',
            'dropdown-button'
          )}
          style={
            !block && !quiet
              ? { width: width > MAGIC_OFFSET_WIDTH ? `${width}px` : 'auto' }
              : undefined
          }
          onClick={handleToggleOpen}
          ref={button}
        >
          <span
            className={cx(styles.valueText)}
            // eslint-disable-next-line jam3/no-sanitizer-with-danger
            dangerouslySetInnerHTML={{ __html: valueLabel || '' }}
          />

          {loading ? (
            <Indicator loading className={cx('chevron-down', { loading })} />
          ) : (
            <svg
              className={cx('chevron-down')}
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
            </svg>
          )}
        </button>

        {/* Dropdown drawer */}
        <ul
          ref={drawer}
          tabIndex={-1}
          role="listbox"
          style={{
            transform: upsideDown
              ? 'translateY(calc(-100% - 50px))'
              : undefined,
            width: !block
              ? width > MAGIC_OFFSET_WIDTH
                ? `${width}px`
                : 'auto'
              : 'calc(100% - 2rem)',
          }}
          className={cx(
            styles.drawer,
            'ui-dropdown__drawer',
            'dropdown-drawer',
            {
              visible: isOpen,
              upsideDown,
              'group-open': hasOpenGroup,
            }
          )}
        >
          {searchable && isOpen && (
            <li
              key={-1}
              className={cx(styles['search-input'], 'ui-dropdown__search')}
            >
              <Search
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                block
                onKeyUp={handleSearch}
                onClose={handleSearchClose}
              />
            </li>
          )}

          {!!placeholder && !rules?.includes('required') && (
            <DropdownOption>{placeholder}</DropdownOption>
          )}

          {isRequired && state.length === 0 && <li>{placeholder}</li>}

          {children}
        </ul>
        {isInvalid && (
          <span className={cx(styles.error)}>
            {t('validation.is_required')}
          </span>
        )}
      </div>
    </DropdownContext.Provider>
  );
}

export const Dropdown = ({ children, ...props }: SingleDropdownProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <BaseDropdown {...props}>{children}</BaseDropdown>
);
export const MultiDropdown = ({ children, ...props }: MultiDropdownProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <BaseDropdown {...props} multiple>
    {children}
  </BaseDropdown>
);
