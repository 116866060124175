import React, { useMemo } from 'react';
import { useGlossaryTerms } from '@brainstud/academy-api';
import { ContentModal } from '@brainstud/ui';
import { Loading } from 'Components';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';

type Props = {
  /** The identifier of the glossary term */
  glossaryKey: string;
  /** Method automagically provided to close the modal */
  closeModal: () => void;
  /** Optional courseId for when the modal is triggered from the AnswerReview as a coach */
  courseId?: string;
};

/**
 * Shows the definition of a glossary term
 */
export function GlossaryModal({ glossaryKey, closeModal, courseId }: Props) {
  const [{ data: glossary }, { isLoading }] = useGlossaryTerms({
    course: courseId,
  });
  const [t] = useTranslator();

  const item = useMemo(
    () => glossary.find((i) => i.id === glossaryKey),
    [glossary, glossaryKey]
  );

  return (
    <ContentModal onClickOutside onClose={closeModal}>
      {isLoading && <Loading />}
      {!isLoading && !item ? (
        <p>{t('components.glossary_modal.definition_not_found')}</p>
      ) : (
        <>
          <h3>{item?.term}</h3>
          <div
            dangerouslySetInnerHTML={{ __html: sanitizer(item?.definition) }}
          />
        </>
      )}
    </ContentModal>
  );
}
