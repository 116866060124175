import { FC, ReactElement } from 'react';

/**
 * toLayout.
 *
 * Transforms a component into a 'getLayout' function.
 */
export function toLayout<T extends {} = {}>(Layout: FC, props?: T) {
  return (Component: ReactElement) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Layout {...props}>{Component}</Layout>
  );
}
