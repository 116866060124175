import React, {
  ChangeEvent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Enrollment } from '@brainstud/academy-api';
import { Accordion, Checkbox, Dropdown, Search } from '@brainstud/ui';
import { Chip } from '@mui/material';
import classNames from 'classnames/bind';
import { FilterSidebarButton } from 'Components';
import { GridListToggle } from 'Components/GridListToggle';
import { useTranslator } from 'Providers/Translator';
import styles from './MetaActionsBar.module.css';

const classes = classNames.bind(styles);

type TSortValues = 'created_at' | '-created_at';
type Props = {
  enrollments: Enrollment[];
  onSearch: (value?: string) => void;
  onSort: (value: TSortValues) => void;
  onToggleViewType: (type: boolean) => void;
  onFilterChange: (courseIds: string[]) => void;
};

/**
 * Shows filters possible for the portfolio answers
 */
export const MetaActionsBar = ({
  onSearch,
  onSort,
  onToggleViewType,
  enrollments = [],
  onFilterChange,
}: PropsWithChildren<Props>) => {
  const [t] = useTranslator();

  const [sortOrder, setSortOrder] = useState<TSortValues>('created_at');
  const handleSortOrderChange = useCallback(
    (orderBy: TSortValues) => {
      setSortOrder(orderBy);
      onSort?.(orderBy);
    },
    [onSort]
  );

  const [search, setSearch] = useState<string>();
  useEffect(() => {
    onSearch?.(search);
  }, [search, onSearch]);

  const [filters, setFilters] = useState<string[]>([]);
  const handleToggleFilter = useCallback(
    (courseId: string | undefined) => {
      if (courseId) {
        setFilters((prevFilters) => {
          const newFilters = prevFilters.includes(courseId)
            ? prevFilters.filter((course) => course !== courseId)
            : [...prevFilters, courseId];
          onFilterChange?.(newFilters);
          return newFilters;
        });
      }
    },
    [onFilterChange]
  );

  return (
    <div className={styles.base}>
      <FilterSidebarButton
        className={classes(styles.filters)}
        count={filters.length}
        label={t('views.portfolio.filterPanel.filters')}
        title={t('views.portfolio.filterPanel.filters')}
        onReset={() => setFilters([])}
      >
        <Dropdown
          name="sort_attribute"
          label={t('views.portfolio.filterPanel.sortOn')}
          value={sortOrder}
          onChange={(newValue) =>
            handleSortOrderChange(newValue as TSortValues)
          }
          rules="required"
          block
        >
          <Dropdown.Option value="created_at">
            {t('views.portfolio.filterPanel.sort.newOld')}
          </Dropdown.Option>
          <Dropdown.Option value="-created_at">
            {t('views.portfolio.filterPanel.sort.oldNew')}
          </Dropdown.Option>
        </Dropdown>

        {enrollments && (
          <Accordion
            quiet
            open
            title={t('views.portfolio.filterPanel.courses')}
            className={classes(styles.accordion)}
          >
            {enrollments.map((enrollment) => {
              const course = enrollment.course?.();
              return (
                <Checkbox
                  key={course?.id}
                  id={course?.id}
                  quiet
                  checked={filters?.includes(course?.id as string)}
                  onChange={() => handleToggleFilter(course?.id)}
                  name={course?.title}
                >
                  {course?.title}
                </Checkbox>
              );
            })}
          </Accordion>
        )}
      </FilterSidebarButton>

      <Search
        placeholder={t('views.portfolio.search')}
        onClose={() => onSearch('')}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setSearch(e.target.value || '')
        }
        style={{ marginLeft: '1rem' }}
      />

      <GridListToggle onChange={onToggleViewType} />

      {filters.length > 0 && (
        <div className={classes(styles['enabled-filters'])}>
          {filters.map((courseId) => {
            const enrollment = enrollments?.find(
              (item) => item.course?.()?.id === courseId
            );
            const course = enrollment?.course?.();
            return (
              <Chip
                key={course?.id}
                className={classes(styles.chip)}
                label={course?.title}
                onDelete={() => handleToggleFilter(course?.id)}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
