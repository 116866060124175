import React, { useMemo } from 'react';
import { ToolkitItemType, useToolkit } from '@brainstud/academy-api';
import { Panel, Table, Td, Th, Tr } from '@brainstud/ui';
import { Description, Image, Link, Movie } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Loading } from 'Components';
import { useModals } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import { ToolkitItemModal } from './ToolkitItemModal';
import styles from './ToolkitItems.module.css';

const cx = classNames.bind(styles);

const Icon = ({ type }: { type: ToolkitItemType }) => {
  switch (type) {
    case 'video':
      return <Movie />;
    case 'image':
      return <Image />;
    case 'link':
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      return <Link />;
    case 'document':
    case 'file':
      return <Description />;
    default:
      return <Description />;
  }
};

type TProps = {
  id: string;
  filter?: string;
};

export const ToolkitItems = ({ id, filter = '' }: TProps) => {
  const [{ data: toolkit }, { isLoading }] = useToolkit({
    toolkit: id,
  });

  const { openModal } = useModals();
  const [t] = useTranslator();

  const categories = useMemo(
    () => toolkit?.toolkitCategories?.() || [],
    [toolkit]
  );
  const items = useMemo(
    () =>
      categories
        .map((category) => category.toolkitItems?.() || [])
        .flat()
        .filter(
          (item) =>
            filter === '' ||
            item.title.toLowerCase().indexOf(filter.toLowerCase()) >= 0
        ),
    [categories, filter]
  );
  return isLoading || !toolkit ? (
    <Panel>
      <Loading />
    </Panel>
  ) : (
    <div className={styles.base}>
      {items.length > 0 ? (
        <Panel>
          <Table
            className={cx(styles.table)}
            data={items.filter(
              (item) =>
                filter === '' ||
                item.title.toLowerCase().indexOf(filter.toLowerCase()) >= 0
            )}
          >
            <Table.THead>
              <Tr>
                <Th center style={{ width: '2rem' }} />
                <Th>{t('views.toolkit.document')}</Th>
                <Th style={{ width: '50%' }}>
                  {t('views.toolkit.description')}
                </Th>
              </Tr>
            </Table.THead>
            <Table.TBody>
              {items
                .filter(
                  (item) =>
                    filter === '' ||
                    item.title.toLowerCase().indexOf(filter.toLowerCase()) >= 0
                )
                .map((toolkitItem) => (
                  <Tr
                    key={toolkitItem.id}
                    onClick={() =>
                      openModal(ToolkitItemModal, { mediaItem: toolkitItem })
                    }
                  >
                    <Td center>
                      <Icon type={toolkitItem.itemType} />
                    </Td>
                    <Td
                      onClick={() =>
                        openModal(ToolkitItemModal, { mediaItem: toolkitItem })
                      }
                    >
                      {toolkitItem.title}
                    </Td>
                    <Td style={{ width: '65%' }}>
                      <span
                        style={{
                          display: 'inline-block',
                          width: '60rem',
                          maxWidth: '100%',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {sanitizer(toolkitItem.description, 'plainText')}
                      </span>
                    </Td>
                  </Tr>
                ))}
            </Table.TBody>
          </Table>
        </Panel>
      ) : (
        <Panel pad>
          <h3>{t('views.toolkit.no_data.title')}</h3>
          <p>{t('views.toolkit.no_data.description')}</p>
        </Panel>
      )}
    </div>
  );
};

export default ToolkitItems;
