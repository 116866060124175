import React, { useCallback, useMemo } from 'react';
import { useNotifications } from '@brainstud/academy-api';
import { Button, Pagination, Panel } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { Container, Loading, NotificationItem } from 'Components';
import { usePagination } from 'Modules/universal-components';
import { useEnvironmentProvider } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import styles from './NotificationsView.module.css';

const cx = classNames.bind(styles);

type Props = {
  /** Render view as partial of another view */
  partial?: boolean;
};

/**
 * NotificationsView.
 *
 * Shows a list of all notifications
 */
export const NotificationsView = ({ partial }: Props) => {
  const [t] = useTranslator();
  const { environment } = useEnvironmentProvider();
  const { page, limit } = usePagination();
  const [{ data: notifications, update }, { isLoading, data: request }] =
    useNotifications({
      page,
      limit,
    });

  const markAllAsRead = useCallback(() => {
    update.mutate({
      _method: 'patch',
      operation: 'mark_all_as_read',
    });
  }, [update]);

  const backToLink = {
    coach: '/coach',
    admin: '/admin',
    student: '/catalog',
    guest: '/',
  }[environment || 'guest'];

  const unreadNotifications = useMemo(
    () =>
      partial
        ? notifications.filter((notification) => notification.readAt === null)
        : notifications,
    [partial, notifications]
  );

  const hasUnreadNotifications =
    unreadNotifications && unreadNotifications.length > 0;
  const hasNotifications = notifications.length > 0;

  return (
    <Container className={cx(styles.base, { isPartial: partial })}>
      <header>
        <h1>{t('notifications.title')}</h1>
        {hasUnreadNotifications && (
          <Button type="button" quiet onClick={markAllAsRead}>
            {t('menu.mark_all_notifications_read')}
          </Button>
        )}
      </header>

      <Panel className={cx(styles.panel, { empty: !hasUnreadNotifications })}>
        <div
          className={cx(styles.panelContainer, { empty: !hasNotifications })}
        >
          {isLoading ? (
            <Loading />
          ) : hasUnreadNotifications ? (
            <>
              {unreadNotifications.map((notification) => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                />
              ))}
            </>
          ) : (
            <p>
              {hasNotifications
                ? t('notifications.no_unread_notifications')
                : t('notifications.no_notifications')}
            </p>
          )}
        </div>
        {partial && (
          <div className={cx(styles.footer)}>
            <Button
              to="/notifications"
              type="button"
              quiet
              className={cx(styles.backlink)}
            >
              {t('view_all')}
            </Button>
          </div>
        )}
      </Panel>
      {!partial && (
        <>
          <Button to={backToLink} style={{ margin: '1rem 0' }}>
            {t('notifications.go_to')}
          </Button>
          {request?.meta?.total > 25 && (
            <Pagination
              totalPages={request?.meta?.lastPage}
              totalResults={`${t('components.pagination.totalResults')} ${request?.meta?.total || 0}`}
              onPageChange={() =>
                document
                  .querySelector('#scroller')
                  ?.scroll({ top: 0, left: 0, behavior: 'smooth' })
              }
              labels={{
                itemsPerPage: t('components.pagination.resultsPerPage'),
              }}
            />
          )}
        </>
      )}
    </Container>
  );
};
