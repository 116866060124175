import React, { useMemo } from 'react';
import { usePortalCourses } from '@brainstud/academy-api';
import classNames from 'classnames/bind';
import { Loading } from 'Components';
import { useTranslator } from 'Providers/Translator';
import { Badge } from '../Badge';
import styles from './CourseCard.module.css';

export interface CatalogCourseCardProps {
  courseId: string;
  isOpened: boolean;
  isNew: boolean;
  className?: string;
  onClick: () => void;
}

const cx = classNames.bind(styles);

export const CourseCard = React.forwardRef(
  (
    { courseId, isOpened, isNew, className, onClick }: CatalogCourseCardProps,
    ref: any
  ) => {
    const [t] = useTranslator();
    const [{ data: courses }, { isLoading }] = usePortalCourses();
    const course = useMemo(
      () => courses.find((c) => c.id === courseId),
      [courses, courseId]
    );

    const defaultThumbnail = '/media/abstract-thumbnail.jpg';

    const enrollment = useMemo(() => course?.enrollment?.(), [course]);
    const hasThumbnail =
      (course?.metadata?.media?.thumbnailUrl || course?.thumbnailUrl) &&
      (course?.metadata?.media?.thumbnailUrl?.search(
        /(.jpg|.png|.jpeg|.webp)/
      ) !== -1 ||
        course?.thumbnailUrl?.search(/(.jpg|.png|.jpeg|.webp)/) !== -1);
    const thumbnail = hasThumbnail
      ? course?.metadata?.media?.thumbnailUrl || course?.thumbnailUrl
      : defaultThumbnail;
    const profiles = useMemo(
      () => course?.educationProfiles?.() || [],
      [course]
    );
    const isComingSoon = course?.status === 'COMING_SOON';

    if (isLoading || !course) return <Loading />;

    return (
      <div
        ref={ref}
        className={cx(styles.card, className, { isActive: isOpened })}
        style={{ backgroundImage: `url(${thumbnail}?width=640)` }}
        onClick={onClick}
        onKeyDown={onClick}
        role="button"
        tabIndex={0}
      >
        <div className={styles.batchTopLeft}>
          {isNew && (
            <Badge color="yellow">{t('components.courseCard.new')}</Badge>
          )}
        </div>

        {isComingSoon && !enrollment && (
          <Badge color="yellow" className={styles.badgeTopLeft}>
            {t('components.courseCard.comingSoon')}
          </Badge>
        )}

        {course.metadata?.informationLines?.sbu && (
          <Badge color="black" className={styles.badgeTopRight}>
            {`${course.metadata.informationLines.sbu} SBU`}
          </Badge>
        )}

        <h4>{course.title}</h4>

        {profiles.length > 0 && (
          <div className={cx('profiles-bar')}>
            <Badge limit color="white">
              {profiles[0].title}
            </Badge>
            {profiles.length > 1 && (
              <Badge color="white" circle>
                +{profiles.length - 1}
              </Badge>
            )}
          </div>
        )}
      </div>
    );
  }
);
