import { IResource } from '../Utils/Resource';
import { UUID } from '../Utils/UUID';
import { EventCalendarItem } from './EventCalendarItem';

export interface EventCalendar extends IResource {
  title: string;
  description: string;
  enabled: boolean;
  items?: () => EventCalendarItem[];
}

export interface EventCalendarCreateInput {
  title: string;
  description?: string;
  enabled?: boolean;
  portal?: UUID;
  subject_id?: UUID;
  subject_type?: string;
}

export type EventCalendarUpdateInput = Partial<
  Omit<EventCalendarCreateInput, 'portal' | 'subject_id' | 'subject_type'>
>;

export enum EventCalendarIncludes {
  items,
  'items.current_user_enrollment',
}

export enum EventCalendarFilters {
  title,
  enabled,
}

export enum EventCalendarSorts {
  title,
}
