import React from 'react';
import classNames from 'classnames/bind';
import { useBlock } from 'Hooks/useBlock';
import { IRichTitleBlock } from './Types';
import styles from './RichTitleBlock.module.css';

const cx = classNames.bind(styles);

const RichTitleBlock = ({
  id,
  prefix,
  content,
  level = 2,
  className,
}: Omit<IRichTitleBlock, 'type'>) => {
  const Heading = `h${level}` as keyof JSX.IntrinsicElements;
  useBlock({
    id,
  });
  return (
    <Heading className={cx(styles.base, `rich-title-${className}`)}>
      {prefix && <span className={cx('prefix')}>{prefix}</span>}
      {content}
    </Heading>
  );
};

export { RichTitleBlock };
