import { createContext } from 'react';

export interface IAccessContext {
  hasAccess: boolean;
  /** Whether the subscription model is present and a subscription is required for the coach */
  isSubscriptionRequired: boolean;
  /** To check if user has had subscription before */
  hasHadActiveSubscription: boolean;
  /** Whether there currently is an active subscription */
  isSubscriptionActive: boolean;
  /** Whether there currently is a promotion available */
  subscriptionPromotionAvailable: boolean;
}

export const AccessContext = createContext<null | IAccessContext>(null);
