import { DateTimeString } from '../Utils/DateTimeString';
import { IResource } from '../Utils/Resource';
import { UUID } from '../Utils/UUID';
import { EducationLevel } from './EducationLevel';
import { LearningObject } from './LearningObject';
import { LearningRoute } from './LearningRoute';
import { LearningSubject } from './LearningSubject';
import { Toolkit } from './Toolkit';

export type LearningObjectCollectionStatus =
  | 'CONCEPT'
  | 'PUBLISHED'
  | 'COMING_SOON'
  | 'ARCHIVED';

export type OnboardingScreen = {
  version?: string;
  steps: Array<{
    id: string;
    title: string;
    content: string;
  }>;
};

export interface LearningObjectCollection
  extends IResource<'learning_object_collections'> {
  title: string;
  type: string;
  status: LearningObjectCollectionStatus;
  state: string;
  description: string;
  introduction: string;
  allowNewEnrollments: boolean;
  enrollableEducationLevels: Array<{
    label: string;
    eqfNumber: number;
  }>;
  onboarding?: OnboardingScreen;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  archived?: boolean;
  educationLevels: () => EducationLevel[];
  toolkits: () => Toolkit[];
  learningRoute?: () => LearningRoute;
  /** @deprecated Deprecated: Use .learningRoute instead */
  learningRoutes?: () => LearningRoute[];
  learningObjects?: () => LearningObject[];
  learningSubjects?: () => LearningSubject[];
  links: {
    edit: {
      /** This link enrolls in the proper collection and opens the appropriate course viewer */
      href: string;
    };
    view: {
      /** This url enrolls the coach into the proper collection and opens the appropriate course viewer */
      href: string;
    };
  };
}

export interface LearningObjectCollectionCreateInput {
  title: string;
  status: LearningObjectCollectionStatus;
  description?: string;
  introduction?: string;
  onboarding?: OnboardingScreen | null;
  course: UUID;
  allow_new_enrollments?: boolean;
  strict_mode?: boolean;
  use_interim_final_assignment?: boolean;
  create_default_learning_route?: boolean;
  relationships?: {
    education_levels?: Array<{
      id: UUID;
      custom_label?: string;
    }>;
  };
}

export interface LearningObjectCollectionUpdateInput
  extends Partial<
    Omit<LearningObjectCollectionCreateInput, 'create_default_learning_route'>
  > {
  archived?: boolean;
}

export enum LearningObjectCollectionIncludes {
  learning_objects,
  'learning_objects.varieties',
  'learning_objects.conditions',
  'learning_objects.nfc_tags',
  'learning_subjects.quiz',
  'learning_subjects.quiz.answer_groups',
  'learning_subjects.learning_objects',
  learning_route,
  learning_routes,
  'learning_route.learning_route_nodes',
  'learning_routes.learning_route_nodes',
  'learning_routes.learning_route_nodes.conditions',
  learning_subjects,
  education_levels,
  toolkit,
  nfc_tags,
  authors,
}

export enum LearningObjectCollectionFilters {
  title,
  status,
  course,
}

export enum LearningObjectCollectionSorts {}
