import { UseMutationOptions } from 'react-query';
import { useCreate } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import { ILoginInput, ILoginResponse } from '../Types';

/**
 * useLogin
 *
 * Hook that can send a login POST request.
 */
export function useLogin(
  settings?: UseMutationOptions<
    DataDocument<ILoginResponse>,
    ErrorDocument,
    ILoginInput
  >
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.login',
      baseUri: '/v1/login',
    },
    context
  );
  return useCreate<ILoginResponse, ILoginInput>(request, settings);
}
