import { DateTimeString } from '../Utils/DateTimeString';
import { IResource } from '../Utils/Resource';
import { Catalog } from './Catalog';
import { Contract } from './Contract';
import { SubEntity } from './SubEntity';

export interface Entity extends IResource<'entities'> {
  name: string;
  externalIdentifier: string;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  catalog?: () => Catalog;
  subEntities?: () => SubEntity[];
  contract?: () => Contract;
  meta?: {
    uniqueActiveUsers?: number;
    subEntitiesCount?: number;
  };
}

export interface EntityCreateInput {
  name: string;
}

export interface EntityFilters {}

export enum EntityIncludes {
  catalog,
  sub_entities,
  sub_entitiesCount,
  contract,
}

export enum EntitySorts {
  name,
  unique_active_users,
}
