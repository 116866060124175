import React, { useState } from 'react';
import { Dropdown } from '@brainstud/ui';
import { useWindowSize } from '@brainstud/universal-components';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import { BlockRenderer } from '../../../Components/BlockRenderer/BlockRenderer';
import { TabContainer } from './TabContainer';
import { ITabbarContainerBlock, ITabContainerBlock, TTab } from './Types';
import styles from './TabbarContainer.module.css';

const classes = classNames.bind(styles);

/**
 * Checks if a subblock is a legacy type of block
 */
function isLegacyTab(tab: ITabContainerBlock | TTab): tab is TTab {
  return (tab as ITabContainerBlock).type === undefined;
}

/**
 * The Tab Bar Container can display a set of TabContainer blocks. It is impossible to add other blocks
 * than TabContainer blocks to the TabbarContainer block. Within a TabContainer block, you can add other
 * blocks.
 */
export const TabbarContainer = ({
  variant,
  tabs: legacyTabs,
  blocks,
  style,
  className,
}: Omit<ITabbarContainerBlock, 'id' | 'type'>) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const windowSize = useWindowSize();
  const [t] = useTranslator();

  const tabs: Array<ITabContainerBlock | TTab> = blocks || legacyTabs || [];
  const activeTab = tabs[activeIndex];
  const isVerticalTabbar = variant === 'vertical' || tabs.length > 3;

  if (tabs.length < 0) {
    return null;
  }

  return (
    <div
      className={classes(
        styles.base,
        'blocks-tabbarcontainer__base',
        className,
        {
          vertical: isVerticalTabbar,
          'blocks-tabbarcontainer__vertical': isVerticalTabbar,
          'blocks-tabbarcontainer__horizontal': !isVerticalTabbar,
        }
      )}
      style={style}
    >
      {(windowSize.width !== undefined && windowSize.width < 585 && (
        <Dropdown
          block
          placeholder={t('course-blocks.blocks.tabbar.placeholder')}
          onChange={(value) => {
            setActiveIndex(parseInt(value as string, 10));
          }}
        >
          {tabs.map((tab, tabIdx) => (
            <Dropdown.Option key={tab.title} value={tabIdx.toString()}>
              {tab.title}
            </Dropdown.Option>
          ))}
        </Dropdown>
      )) || (
        <ul role="tablist" className={styles.tablist}>
          {(blocks || []).map((tabContainerBlock, tabIndex) => (
            <li
              key={tabContainerBlock.id}
              role="tab"
              className={classes(styles.tab, {
                active: tabIndex === activeIndex,
              })}
              aria-selected={tabIndex === activeIndex}
              onClick={() => setActiveIndex(tabIndex)}
              tabIndex={0}
              onKeyDown={(event) =>
                event.key === ' ' && setActiveIndex(tabIndex)
              }
            >
              {tabContainerBlock.title}
            </li>
          ))}
        </ul>
      )}

      {isLegacyTab(activeTab) ? (
        <section role="presentation">
          <BlockRenderer id={activeTab.block.id} block={activeTab.block} />
        </section>
      ) : (
        <TabContainer
          blocks={activeTab?.blocks || []}
          style={activeTab?.style}
          className={activeTab?.className}
          vertical={isVerticalTabbar}
        />
      )}
    </div>
  );
};
