import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@brainstud/ui';
import { ViewList, ViewModule } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { TViewType } from '../../Providers/CourseListProvider/CourseListReducer';
import styles from './ViewToggle.module.css';

const cx = classNames.bind(styles);

interface ViewToggleProps {
  value?: TViewType;
  onToggle: (val: 'grid' | 'list') => void;
  className?: string;
}

/**
 * A toggle to switch the view from a grid to list or vice versa
 */
export const ViewToggle = ({
  value: defaultValue = 'grid',
  onToggle,
  className,
}: ViewToggleProps) => {
  const [view, setView] = useState<TViewType>(defaultValue);

  const handleToggle = useCallback(() => {
    onToggle(view === 'grid' ? 'list' : 'grid');
    setView((prevView) => (prevView === 'grid' ? 'list' : 'grid'));
  }, [onToggle, view]);

  useEffect(() => {
    setView(defaultValue);
  }, [defaultValue]);

  return (
    <Button
      type="button"
      onClick={handleToggle}
      className={cx(styles.base, className)}
    >
      {view === 'grid' && <ViewList />}
      {view === 'list' && <ViewModule />}
    </Button>
  );
};
