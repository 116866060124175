import Highlight from '@tiptap/extension-highlight';
import { Table } from '@tiptap/extension-table';
import { TableCell } from '@tiptap/extension-table-cell';
import { TableHeader } from '@tiptap/extension-table-header';
import { TableRow } from '@tiptap/extension-table-row';
import Underline from '@tiptap/extension-underline';
import { EditorOptions } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { DefinitionExtension } from './Extensions/DefinitionExtension/DefinitionExtension';
import { Link } from './Extensions';

type TPresetList = {
  [key: string]: Partial<EditorOptions>;
};

/**
 * A set of preset configurations you can use in the RichTextEditor.
 * @type {{default: {extensions: Extension<StarterKitOptions, any>[]}, minimal: {extensions: (Extension<StarterKitOptions, any> | Mark<UnderlineOptions, any> | Mark<HighlightOptions, any>)[]}, course_content: {extensions: (Extension<StarterKitOptions, any> | Mark<UnderlineOptions, any> | Mark<HighlightOptions, any> | Extension<any, any> | Node<TableOptions, any> | Node<TableRowOptions, any> | Node<TableHeaderOptions, any> | Node<TableCellOptions, any> | Mark<LinkOptions, any>)[]}}}
 */
export const Presets: TPresetList = {
  default: {
    extensions: [StarterKit, Link],
  },
  inline: {
    extensions: [
      StarterKit.configure({
        orderedList: false,
        bulletList: false,
      }),
      Link,
      Highlight,
    ],
  },

  minimal: {
    extensions: [
      StarterKit.configure({
        strike: false,
      }),
      Underline,
      Highlight,
    ],
  },
  course_content: {
    extensions: [
      StarterKit.configure({
        strike: false,
      }),
      Underline,
      Highlight,
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableHeader,
      TableCell,
      DefinitionExtension,
      Link.configure({
        HTMLAttributes: {
          target: '_blank',
        },
        openOnClick: false,
      }),
    ],
  },
};
