import React, { PropsWithChildren, Suspense } from 'react';
import { Panel } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { Loading, Wrap } from 'Components';
import { AccountTabs } from 'Modules/account-panel/Layouts';
import { useRouter } from 'next/router';
import { TwoFactorProvider, useBreadcrumbs } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import styles from './TwoFactorLayout.module.css';

const cx = classNames.bind(styles);

type Props = {
  title?: string;
};
/**
 * TwoFactorLayout.
 *
 * This layout is used on the account/security/2fa pages and setup 2fa after login pages
 */
export const TwoFactorLayout = ({
  title,
  children,
}: PropsWithChildren<Props>) => {
  const [t] = useTranslator();
  const router = useRouter();
  // determines if the layout is accessed though /account since this layout is also used on the /login route
  const isAccountPage = router.pathname.startsWith('/account');

  useBreadcrumbs({
    disable: { href: null },
    security: { href: null },
    setup: { href: null },
    'two-factor': { href: null },
  });

  return (
    <Wrap with={AccountTabs} if={isAccountPage}>
      <TwoFactorProvider>
        <Panel
          pad
          className={cx(styles.panel, { 'tfa-required': !isAccountPage })}
        >
          <Suspense fallback={<Loading />}>
            <h2>{t(title || 'layouts.account.two_factor.titles.fallback')}</h2>
            {children}
          </Suspense>
        </Panel>
      </TwoFactorProvider>
    </Wrap>
  );
};
