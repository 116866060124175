import { Mark, mergeAttributes } from '@tiptap/core';

export interface DefinitionOptions {
  HTMLAttributes: Partial<HTMLElement>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    definition: {
      /**
       * Set a link mark
       */
      setDefinition: (attributes: { 'data-key': string }) => ReturnType;
      /**
       * Toggle a link mark
       */
      toggleDefinition: (attributes?: { 'data-key'?: string }) => ReturnType;
      /**
       * Unset a link mark
       */
      unsetDefinition: () => ReturnType;
    };
  }
}

export const DefinitionExtension = Mark.create<DefinitionOptions>({
  name: 'definition',
  priority: 1000,
  keepOnSplit: false,

  onCreate() {},

  onDestroy() {},

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      'data-key': 'undefined',
    };
  },

  parseHTML() {
    return [{ tag: 'dfn[data-key]' }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'dfn',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },

  addCommands() {
    return {
      setDefinition:
        (attributes) =>
        ({ chain }) =>
          chain()
            .setMark(this.name, attributes)
            .setMeta('preventAutolink', true)
            .run(),

      toggleDefinition:
        (attributes) =>
        ({ chain }) =>
          chain()
            .toggleMark(this.name, attributes, { extendEmptyMarkRange: true })
            .setMeta('preventAutolink', true)
            .run(),

      unsetDefinition:
        () =>
        ({ chain }) =>
          chain()
            .unsetMark(this.name, { extendEmptyMarkRange: true })
            .setMeta('preventAutolink', true)
            .run(),
    };
  },
});
