import React, { CSSProperties, ReactNode } from 'react';
import { Check } from '@mui/icons-material';
import classNames from 'classnames/bind';
import styles from './VerticalTabList.module.css';

const cx = classNames.bind(styles);

type Props = {
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
  completed?: boolean;
  active?: boolean;
  onClick?: () => void;
};

export const VerticalTab = ({
  className,
  style,
  children,
  completed = false,
  active = false,
  onClick,
}: Props) => (
  // FIXME Refactor to button
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
  <div
    style={style}
    role="button"
    onClick={onClick}
    className={cx(
      {
        item: true,
        completed,
        active,
      },
      className
    )}
  >
    {completed && <Check />}
    {children}
  </div>
);
