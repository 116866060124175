import { IResource } from '../Utils/Resource';

export interface Interest extends IResource<'interests'> {
  title: string;
}

export interface InterestUpdate {
  title: string;
}

export interface InterestCreate extends InterestUpdate {}

export enum InterestIncludes {}

export enum InterestFilters {
  title,
  portal,
}

export enum InterestSorts {
  type,
}
