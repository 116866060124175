import { UseQueryOptions } from 'react-query';
import {
  HookParams,
  useApi,
  useIndex,
  useSingleCRUD,
  UUID,
} from '@brainstud/academy-api';
import {
  ApiRequest,
  DataDocument,
  ErrorDocument,
} from '@brainstud/academy-api/Support';
import {
  Announcement,
  AnnouncementCreateInput,
  AnnouncementFilters,
  AnnouncementIncludes,
  AnnouncementSorts,
  AnnouncementUpdateInput,
} from '@brainstud/academy-api/Types/Resources/Announcement';

interface IAnnouncementProps
  extends HookParams<
    typeof AnnouncementIncludes,
    AnnouncementFilters,
    typeof AnnouncementSorts
  > {
  announcement?: UUID;
}

export function useAnnouncement(
  { announcement, ...queryParameters }: IAnnouncementProps = {},
  settings?: UseQueryOptions<DataDocument<Announcement>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { announcement: defaultAnnouncement },
  } = context;

  const request = new ApiRequest(
    {
      enabled: !!(announcement || defaultAnnouncement),
      baseName: 'v1.announcements',
      baseUri: '/v1/announcements',
      invalidate: ['announcements', 'v1.announcements'],
      uri: `/${announcement}`,
      queryParameters,
    },
    context
  );

  return useSingleCRUD<
    Announcement,
    AnnouncementCreateInput,
    AnnouncementUpdateInput
  >(request, settings);
}

export function useAnnouncements(
  { ...queryParameters }: Omit<IAnnouncementProps, 'announcement'> = {},
  settings?: UseQueryOptions<DataDocument<Announcement[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.announcements',
      invalidate: ['announcements', 'v1.announcements'],
      baseUri: '/v1/announcements',
      queryParameters,
    },
    context
  );

  return useIndex<Announcement>(request, settings);
}
