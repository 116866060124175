export { ReactComponent as CalloutIcon } from './CalloutIcon.svg';
export { ReactComponent as AccordionIcon } from './AccordionIcon.svg';
export { ReactComponent as ColumnIcon } from './ColumnIcon.svg';
export { ReactComponent as DragAndDropIcon } from './DragAndDropIcon.svg';
export { ReactComponent as DragToSortIcon } from './DragToSortIcon.svg';
export { ReactComponent as MultipleChoiceIcon } from './MultipleChoiceIcon.svg';
export { ReactComponent as PanelIcon } from './PanelIcon.svg';
export { ReactComponent as RichListIcon } from './RichListIcon.svg';
export { ReactComponent as RichMediaIcon } from './RichMediaIcon.svg';
export { ReactComponent as RichTitleIcon } from './RichTitleIcon.svg';
export { ReactComponent as SliderIcon } from './SliderIcon.svg';
export { ReactComponent as SingleChoiceIcon } from './SingleChoiceIcon.svg';
export { ReactComponent as VideoIcon } from './VideoIcon.svg';
export { ReactComponent as RichImageIcon } from './RichImageIcon.svg';
export { ReactComponent as FunctionsIcon } from './FunctionsIcon.svg';
export { ReactComponent as GroupIcon } from './GroupIcon.svg';
export { ReactComponent as FileUploadIcon } from './FileUploadIcon.svg';
export { ReactComponent as InputIcon } from './InputIcon.svg';
export { ReactComponent as TextareaIcon } from './TextareaIcon.svg';
export { ReactComponent as CardsWidgetIcon } from './CardsWidgetIcon.svg';
export { ReactComponent as TabbarIcon } from './TabbarIcon.svg';
export { ReactComponent as ReactWidgetIcon } from './ReactWidgetIcon.svg';
export { ReactComponent as HtmlBlockIcon } from './HtmlBlockIcon.svg';
export { ReactComponent as AudioIcon } from './AudioIcon.svg';
