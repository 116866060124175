import React from 'react';
import { DateTimeString, LearningObject } from '@brainstud/academy-api';
import { Td, TdCheckbox, Tr } from '@brainstud/ui';
import { DateFormatter } from 'Utils/DateFormatHelper';
import { AnswerStatus } from './AnswerStatus';
import styles from './AnswerRow.module.css';

type Props = {
  learningObject?: LearningObject;
  points?: number;
  status: string;
  date: DateTimeString;
  href: string;
};

/**
 * Shows a table row with all information about a certain answer
 */
export const AnswerRow = ({
  learningObject,
  status,
  date,
  points,
  href,
}: Props) => (
  <Tr href={href} className={styles.clickable}>
    {learningObject && <TdCheckbox id={learningObject?.id} />}
    <Td href={href} style={{ maxWidth: '250px', whiteSpace: 'break-spaces' }}>
      {learningObject?.title}
    </Td>
    <td>{points && points > 0 && `${points} XP`}</td>
    <td>
      <AnswerStatus value={status} />
    </td>
    <td>{DateFormatter.toDateTime(date)}</td>
  </Tr>
);
