import { UseQueryOptions } from 'react-query';
import { useIndex, useIndexCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  Mentee,
  MenteeBulkUpdateInput,
  MenteeFilters,
  MenteeIncludes,
  MenteeSorts,
  UUID,
} from '../Types';

interface IMenteeProps
  extends HookParams<typeof MenteeIncludes, MenteeFilters, typeof MenteeSorts> {
  mentee?: UUID;
}

export function useMentee(
  { mentee, ...queryParameters }: IMenteeProps = {},
  settings?: UseQueryOptions<DataDocument<Mentee[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { mentee: defaultMentee },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(mentee || defaultMentee),
      baseName: 'v1.mentees',
      baseUri: '/v1/mentees',
      invalidate: ['mentee', 'mentees'],
      uri: `/${mentee || defaultMentee}`,
      queryParameters,
    },
    context
  );
  return useIndex<Mentee>(request, settings);
}

export function useMentees(
  { ...queryParameters }: Omit<IMenteeProps, 'mentee'> = {},
  settings?: UseQueryOptions<DataDocument<Mentee[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.mentees',
      baseUri: '/v1/mentees',
      invalidate: ['mentee', 'mentees'],
      queryParameters,
    },
    context
  );

  return useIndexCRUD<Mentee, {}, MenteeBulkUpdateInput>(request, settings);
}
