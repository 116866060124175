import { IResource } from '../Utils/Resource';
import { ToolkitCategory } from './ToolkitCategory';
import { ToolkitItem } from './ToolkitItem';

export interface Toolkit extends IResource<'toolkits'> {
  /** @depreciated Use the role to assign an appropriate (fixed) title */
  title?: string;
  /** @depreciated Use the role to assign an appropriate (fixed) description */
  description?: string;
  /** The account role for which this toolkit is meant */
  requiredRole?: 'student' | 'coach';
  toolkitCategories?: () => ToolkitCategory[];
  toolkitItems?: () => ToolkitItem[];
}

export interface ToolkitCreateInput {
  /** @depreciated Use the role to assign an appropriate (fixed) description */
  title?: string;
  /** @depreciated Use the role to assign an appropriate (fixed) description */
  description?: string;
  requiredRole?: 'student' | 'coach';
  subject_type: string;
  subject_id: string;
}

export interface ToolkitUpdateInput
  extends Omit<ToolkitCreateInput, 'subjectType' | 'subjectId'> {}

export enum ToolkitIncludes {}

export enum ToolkitFilters {
  subject,
  title,
}

export enum ToolkitSorts {
  title,
}
