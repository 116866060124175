import React from 'react';
import {
  Code,
  FontDownload,
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatStrikethrough,
  FormatUnderlined,
  TableChart,
} from '@mui/icons-material';
import classNames from 'classnames/bind';
import { EditorButton } from '../../EditorButton';
import { useRichTextEditorProvider } from '../../useRichTextEditorProvider';
import { DefinitionButton } from '../Buttons/DefinitionButton/DefinitionButton';
import { LinkButton } from '../Buttons/LinkButton/LinkButton';
import styles from './Toolbar.module.css';

const cx = classNames.bind(styles);

type Props = {
  disabled?: boolean;
};

/**
 * MinimalToolbar
 *
 * Used within the RichTextEditor to show the styling buttons. It renders a set of
 * buttons with a minimal look.
 */
export const MinimalToolbar = ({ disabled }: Props) => {
  const { editor } = useRichTextEditorProvider();

  return (
    <div className={cx(styles.base, 'toolbar__base')}>
      {disabled ? (
        <>
          <EditorButton disabled>
            <FormatBold />
          </EditorButton>
          <EditorButton disabled>
            <FormatItalic />
          </EditorButton>
          <EditorButton disabled>
            <FormatUnderlined />
          </EditorButton>
        </>
      ) : (
        <>
          {editor.can().toggleBold?.() && (
            <EditorButton
              onClick={() => editor.chain().focus().toggleBold().run()}
              active={editor.isActive('bold')}
            >
              <FormatBold />
            </EditorButton>
          )}
          {editor.can().toggleItalic?.() && (
            <EditorButton
              onClick={() => editor.chain().focus().toggleItalic().run()}
              active={editor.isActive('italic')}
            >
              <FormatItalic />
            </EditorButton>
          )}
          {editor.can().toggleStrike?.() && (
            <EditorButton
              onClick={() => editor.chain().focus().toggleStrike().run()}
              active={editor.isActive('strike')}
            >
              <FormatStrikethrough />
            </EditorButton>
          )}

          {editor.can().toggleUnderline?.() && (
            <EditorButton
              onClick={() => editor.chain().focus().toggleUnderline().run()}
              active={editor.isActive('underline')}
            >
              <FormatUnderlined />
            </EditorButton>
          )}

          {editor.can().toggleCode?.() && (
            <EditorButton
              onClick={() => editor.chain().focus().toggleCode().run()}
              active={editor.isActive('code')}
            >
              <Code />
            </EditorButton>
          )}

          {editor.can().toggleBulletList?.() && (
            <EditorButton
              onClick={() => editor.chain().focus().toggleBulletList().run()}
              active={editor.isActive('bulletList')}
            >
              <FormatListBulleted />
            </EditorButton>
          )}

          {editor.can().toggleOrderedList?.() && (
            <EditorButton
              onClick={() => editor.chain().focus().toggleOrderedList().run()}
              active={editor.isActive('orderedList')}
            >
              <FormatListNumbered />
            </EditorButton>
          )}

          <LinkButton />

          <DefinitionButton />

          {editor.can().insertTable?.() && (
            <EditorButton
              onClick={() =>
                editor
                  .chain()
                  .focus()
                  .insertTable({
                    rows: 3,
                    cols: 3,
                    withHeaderRow: true,
                  })
                  .run()
              }
              active={editor.isActive('table')}
            >
              <TableChart />
            </EditorButton>
          )}
          {editor.can().addRowAfter?.() && (
            <EditorButton
              onClick={() => editor.chain().focus().addRowAfter().run()}
            >
              <svg width="24" height="24" viewBox="0 0 24 24">
                <path d="M22,10c0,1.097 -0.903,2 -2,2l-16,0c-1.097,0 -2,-0.903 -2,-2l0,-7l2,0l0,2l4,0l0,-2l2,0l0,2l4,0l0,-2l2,0l0,2l4,0l0,-2l2,0l0,7m-18,0l4,0l0,-3l-4,0l0,3m6,0l4,0l0,-3l-4,0l0,3m10,0l0,-3l-4,0l0,3l4,0" />
                <path
                  className="plus"
                  d="M11,14l2,0l0,3l3,0l0,2l-3,0l0,3l-2,0l0,-3l-3,0l0,-2l3,0l0,-3Z"
                />
              </svg>
            </EditorButton>
          )}
          {editor.can().addColumnAfter?.() && (
            <EditorButton
              onClick={() => editor.chain().focus().addColumnAfter().run()}
            >
              <svg width="24" height="24" viewBox="0 0 24 24">
                <path
                  className="plus"
                  d="M11,22l-9,0m13,-11l3,0l0,-3l2,0l0,3l3,0l0,2l-3,0l0,3l-2,0l0,-3l-3,0l0,-2Z"
                />
                <path d="M11,2c1.097,-0 2,0.903 2,2l0,16c0,1.097 -0.903,2 -2,2l-9,0l0,-20l9,0m-7,8l0,4l7,0l0,-4l-7,0m0,6l0,4l7,0l0,-4l-7,0m0,-12l0,4l7,0l0,-4l-7,0" />
              </svg>
            </EditorButton>
          )}

          {editor.can().mergeOrSplit?.() && (
            <EditorButton
              onClick={() => editor.chain().focus().mergeOrSplit().run()}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className={cx('merge-split', {
                  'is-split': editor.can().splitCell(),
                })}
              >
                <path
                  className={cx(styles.arrow, 'arrow-right')}
                  d="M8,13l0,2l3,-3l-3,-3l0,2l-5,0l0,2l5,0"
                />
                <path
                  className={cx(styles.arrow, 'arrow-left')}
                  d="M16,11l0,-2l-3,3l3,3l0,-2l5,0l0,-2l-5,0Z"
                />
                <path d="M5,10l-2,0l0,-6l8,0l0,2l-6,0l0,4m14,8l-6,0l0,2l8,0l0,-6l-2,0l0,4m-14,0l0,-4l-2,0l0,6l8,0l0,-2l-6,0m16,-14l-8,0l0,2l6,0l0,4l2,0l0,-6" />
              </svg>
            </EditorButton>
          )}

          {editor.can().toggleHighlight?.() && (
            <EditorButton
              onClick={() => editor.chain().focus().toggleHighlight().run()}
              active={editor.isActive('highlight')}
            >
              <FontDownload />
            </EditorButton>
          )}
        </>
      )}
    </div>
  );
};
