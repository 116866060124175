import { TReturn, useStorageEngine } from './useStorageEngine';

/**
 * useLocalStorage.
 *
 * Stores value in the local storage of the browser. Value persist between different browser sessions.
 * __Note__: During SSR the value is not available!
 */
export function useLocalStorage<T>(key: string, defaultValue: T): TReturn<T>;
export function useLocalStorage<T>(key?: string): TReturn<undefined | T>;
export function useLocalStorage<T = undefined>(
  key?: string,
  defaultValue?: T
): TReturn<undefined | T> {
  return useStorageEngine<undefined | T>('localStorage', key, defaultValue);
}
