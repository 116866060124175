/**
 * isEmpty.
 *
 * Checks if an object, array, number or string is empty, null or undefined.
 */
export function isEmpty(
  variable: string | object | null | undefined | number | unknown[]
) {
  if (!variable) {
    return true;
  }

  if (typeof variable === 'number') {
    return variable !== 0 && !variable;
  }

  if (Array.isArray(variable)) {
    return variable.length === 0;
  }

  if (typeof variable === 'object') {
    return Object.keys(variable || {}).length === 0;
  }

  return variable.trim() === '';
}
