import React, { useCallback } from 'react';
import { Button, ConfirmationModal } from '@brainstud/ui';
import { useForm } from '@brainstud/universal-components';
import { ArrowUpward, Redo } from '@mui/icons-material';
import classNames from 'classnames/bind';
import {
  useEnvironmentProvider,
  useLearningObjectProvider,
  useModals,
} from 'Providers';
import { useContentBlocksProvider } from 'Providers/ContentBlocksProvider';
import { useTranslator } from 'Providers/Translator';
import { useAnswerReview } from '../../../coach-panel/Providers';
import { useContentEditor } from '../../../course-editor';
import styles from './SubmitAssignment.module.css';

const cx = classNames.bind(styles);

export const SubmitAssignment = () => {
  const { isSubmittable, isLoading } = useContentBlocksProvider();
  const { learningObject } = useLearningObjectProvider();
  const [t] = useTranslator();
  const { disabled, submit } = useForm();

  const { isStudentEnvironment } = useEnvironmentProvider();
  const { openModal, closeModal } = useModals();
  const isReviewing = useAnswerReview() !== null;
  const { isContentEditor } = useContentEditor();

  const lastAnswer = learningObject?.lastAnswer?.();
  const finished = lastAnswer && disabled;
  const isRejected = lastAnswer?.status === 'REJECTED';

  const handleSubmit = useCallback(() => {
    if (!isSubmittable) {
      openModal(ConfirmationModal, {
        closeable: true,
        question: t('components.submit_assignment.confirm_incomplete'),
        yes: t('confirm'),
        no: t('cancel'),
        handleConfirm: () => {
          closeModal();
          submit();
        },
      });
    } else {
      submit();
    }
  }, [closeModal, isSubmittable, openModal, submit, t]);

  return isReviewing || isContentEditor ? null : (
    <div className={cx(styles.base)}>
      {disabled && (
        <Button
          type="reset"
          loading={isLoading}
          outline={!isRejected}
          className={cx({ 'is-hidden': !finished })}
        >
          <span>
            {t(
              `course-blocks.submit_assignment.${isRejected ? 'try_again' : 'start_over'}`
            )}
          </span>
          {!isRejected && <Redo />}
        </Button>
      )}

      {!disabled && (
        <Button
          type="button"
          loading={isLoading}
          disabled={!isStudentEnvironment}
          className={cx({ 'is-hidden': finished })}
          onClick={handleSubmit}
        >
          <span>{t('course-blocks.submit_assignment.submit')}</span>
          <ArrowUpward />
        </Button>
      )}

      {!isStudentEnvironment && (
        <div>
          <small>{t('course-blocks.submit_assignment.authors_note')}</small>
        </div>
      )}
    </div>
  );
};
