import React, { useCallback, useEffect, useReducer } from 'react';
import {
  AccountProfilePermissionOption,
  useAccount,
  useAccountProfile,
  useAccountProfiles,
  useApi,
} from '@brainstud/academy-api';
import {
  toCamelCase,
  toSnakeCase,
} from '@brainstud/academy-api/Utils/transformCase';
import { Button, Dropdown, Panel } from '@brainstud/ui';
import { DropdownOption } from '@brainstud/ui/Form Input/Dropdown/Option';
import classNames from 'classnames/bind';
import { Loading } from 'Components';
import { Form } from 'Modules/universal-components';
import { useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import {
  AccountProfilePermissionReducer,
  AccountProfilePermissions,
  profilePermissionDefault,
} from 'Views/Account/AccountProfilePermissionReducer';
import styles from './AccountProfilePermissions.module.css';

const cx = classNames.bind(styles);

const permissionKeys = [
  'generalPermission',
  'avatarPermission',
  'contactPermission',
  'skillsAndInterestsPermission',
] as const;

export const AccountProfilePermissionsResourceView = () => {
  const [t] = useTranslator();
  const [setToast] = useToaster();
  const { invalidateQueries } = useApi();
  const [{ data: account }, { isLoading }] = useAccount({
    include: ['profile'],
  });

  const [permissionChanges, dispatch] = useReducer(
    AccountProfilePermissionReducer,
    profilePermissionDefault
  );

  useEffect(() => {
    dispatch({
      type: 'updatePermissions',
      payload: account?.profile?.() ?? {},
    });
  }, [account]);

  const create = useAccountProfiles({ account: account?.id });
  const [{ update }] = useAccountProfile(
    {
      account: account?.id,
      accountProfile: account?.profile?.().id,
    },
    { enabled: false }
  );

  const handleSubmit = useCallback(() => {
    (account?.profile?.() ? update : create).mutateAsync(
      {
        ...(account?.profile?.() ? { _method: 'patch' } : {}),
        ...permissionChanges,
      },
      {
        onSuccess() {
          setToast(
            'views.account.account_profile_permissions.success',
            'success'
          );
          invalidateQueries([
            'v1.account',
            'v1.accounts',
            'account',
            'accounts',
          ]);
        },
        onError() {
          setToast('views.account.account_profile_permissions.error', 'error');
        },
      }
    );
  }, [account, permissionChanges, update, create, setToast, invalidateQueries]);

  const position = Object.keys(AccountProfilePermissionOption).indexOf(
    permissionChanges.generalPermission
  );
  const allowedOptions = Object.keys(AccountProfilePermissionOption).slice(
    position
  );

  const handleChange = useCallback(
    (
      section: keyof AccountProfilePermissions,
      value: AccountProfilePermissionOption
    ) => {
      dispatch({
        type: 'updatePermission',
        payload: { permissionType: section, value },
      });
    },
    []
  );

  return isLoading || !account ? (
    <Loading />
  ) : (
    <Panel pad className={cx(styles.base)}>
      <div className={cx(styles.background)}>
        <div>
          <h2>{t('views.account.account_profile_permissions.title')}</h2>
          <p>{t('views.account.account_profile_permissions.description')}</p>
        </div>

        <Form onSubmit={handleSubmit}>
          {permissionKeys.map((section) => (
            <Dropdown
              key={section}
              name={section}
              onChange={(value) =>
                handleChange(section, value as AccountProfilePermissionOption)
              }
              label={t(
                `views.account.account_profile_permissions.${toSnakeCase(section)}`
              )}
              value={
                permissionChanges[section] ||
                account?.profile?.()[toCamelCase(section)]
              }
            >
              {Object.keys(AccountProfilePermissionOption).map((option) => (
                <DropdownOption
                  disabled={
                    section !== 'generalPermission' &&
                    !allowedOptions.includes(option)
                  }
                  key={option}
                  value={option}
                >
                  {t(
                    `views.account.account_profile_permissions.permission_level.${option}`
                  )}
                </DropdownOption>
              ))}
            </Dropdown>
          ))}
          <div>
            <Button type="submit">
              <span>{t('save')}</span>
            </Button>
          </div>
        </Form>
      </div>
    </Panel>
  );
};
