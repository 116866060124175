import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { IDragToSortQuestion, TSortOption } from '@brainstud/course-blocks';
import { Input } from '@brainstud/ui';
import { useDebounce } from '@brainstud/universal-components';
import { useTranslator } from 'Providers/Translator';
import { shortId } from 'Utils';
import { AnswerFeedback, DeleteableSortOption } from '../../../Components';
import { AddItemButton } from '../../../Components/AddItemButton/AddItemButton';
import { useDynamicList } from '../../../Hooks/useDynamicList';

export type DragToSortFormProps = {
  data: IDragToSortQuestion;
  onChange: (data: Omit<IDragToSortQuestion, 'id' | 'type'>) => void;
};

export const DragToSortForm = ({ data, onChange }: DragToSortFormProps) => {
  const [t] = useTranslator();
  const [question, setQuestion] = useState(data.question);
  const [feedback, setFeedback] = useState(data.feedback);

  const [
    draggables,
    { add: addInput, update: updateInput, remove: removeInput, setList },
  ] = useDynamicList<TSortOption>(data.options);

  const debouncedData = useDebounce(
    useMemo(
      () => ({
        question,
        options: draggables,
        feedback,
      }),
      [question, draggables, feedback]
    ),
    100
  );

  useEffect(() => {
    onChange(debouncedData);
  }, [debouncedData]);

  const handleDragEnd = useCallback(
    ({ destination, source }: DropResult) => {
      // Element got dropped outside of list
      if (!destination) return;

      const updatedDraggables = [...draggables];
      updatedDraggables.splice(source.index, 1);
      updatedDraggables.splice(destination.index, 0, draggables[source.index]);

      Object.keys(updatedDraggables).forEach((key) => {
        updatedDraggables[key].position = +key;
      });

      setList(updatedDraggables);
    },
    [draggables, setList]
  );

  return (
    <div>
      <Input
        label={t('course-editor.content_editor.blocks.drag_to_sort.question')}
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
      />
      <span>
        {t('course-editor.content_editor.blocks.drag_to_sort.draggables')}
      </span>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="options" type="droppableRoute">
          {(provided) => (
            <div
              ref={provided.innerRef}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...provided.droppableProps}
            >
              {draggables.map((drag, index) => (
                <DeleteableSortOption
                  key={drag.id}
                  option={drag}
                  index={index}
                  onChange={(e) =>
                    updateInput(drag.id, { label: e.target.value })
                  }
                  onDelete={() => removeInput(drag.id)}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <AddItemButton
        title={t(
          'course-editor.content_editor.blocks.drag_to_sort.add_draggable'
        )}
        onClick={() =>
          addInput({
            id: shortId(),
            label: '',
            position: draggables.length,
          })
        }
      />
      <AnswerFeedback feedback={feedback} onChange={setFeedback} />
    </div>
  );
};
