import React, { useCallback } from 'react';
import { MediaContentModal } from '@brainstud/ui/Modals/MediaContentModal';
import { ZoomIn } from '@mui/icons-material';
import { useBlock } from 'Hooks/useBlock';
import { useModals } from 'Providers';
import { sanitizer } from 'Utils/Sanitizer';
import { SourceTag } from '../../../Components/SourceTag/SourceTag';
import { IImageBlock } from './Types';
import styles from './ImageBlock.module.css';

export const ImageBlock = ({
  id,
  images = [],
  caption,
}: Omit<IImageBlock, 'type'>) => {
  useBlock({
    id,
  });

  const { openModal } = useModals();

  const handleViewImage = useCallback(
    ({
      src,
      description,
      source,
    }: {
      src: string;
      description?: string;
      source?: string;
    }) => {
      openModal(MediaContentModal, {
        src,
        description,
        source,
      });
    },
    [openModal]
  );

  return (
    <figure className={styles.base}>
      <div className={styles.images}>
        {images.map((image) => (
          <React.Fragment key={image.id || image.src}>
            <button
              type="button"
              className={styles.enlarge}
              onClick={() =>
                handleViewImage({
                  src: image.src,
                  description: image.alt,
                  source: image.source,
                })
              }
            >
              <img
                key={image.src}
                src={image.src}
                alt={image.alt}
                style={
                  images.length > 1
                    ? { width: `${100 / images.length}%` }
                    : undefined
                }
              />
              <ZoomIn fontSize="large" className={styles.icon} />
            </button>
            {image.source && <SourceTag source={image.source} />}
          </React.Fragment>
        ))}
      </div>
      {caption && (
        <figcaption dangerouslySetInnerHTML={{ __html: sanitizer(caption) }} />
      )}
    </figure>
  );
};
