import { DateTimeString } from '../Utils/DateTimeString';
import { IResource } from '../Utils/Resource';
import { UUID } from '../Utils/UUID';
import { Answer, AnswerStatus } from './Answer';
import { Course } from './Course';
import {
  Block,
  LearningObjectContent,
  LearningObjectVariety,
} from './LearningObjectVariety';
import { LearningSubject } from './LearningSubject';
import { NfcTag } from './NfcTag';
import { ObjectCondition } from './ObjectConditions';
import { Quiz } from './Quiz';
import { Shareable } from './Shareable';

export type LearningObjectStatus = 'CONCEPT' | 'PUBLISHED' | 'ARCHIVED';
export type LearningObjectBaseType = 'assignment' | 'theory';
export type LearningObjectContentTemplate = 'open' | 'quiz' | 'panel';

export interface LearningObject extends IResource<'learning_objects'> {
  /** The title of the learning object */
  title: string;
  /** Indicates the type of the learning object */
  baseType?: LearningObjectBaseType;
  /** Contains the lifecycle status (concept / published / archived) of the learning object */
  status: LearningObjectStatus;
  /** @deprecated Use layout instead */
  contentTemplate?: LearningObjectContentTemplate;
  /** The Layout that should be used to display the learning object */
  layout?: string;
  /** @deprecated Use .metadata instead */
  type?: {
    /** @deprecated Use .metadata instead */
    label?: string;
    /** @deprecated Use .metadata instead */
    name?: string;
    /** @deprecated Use .metadata instead */
    iconUrl?: string;
  };
  /** Instructions for the teacher to help with assessment/teaching the learning object */
  teacherRemarks?: string;
  /** Based on the enrollment provided, the userState shows the progress of that enrollment */
  userState?: {
    /** The total points available for this learning object */
    xpAvailable: number;
    /** The points the user has received for the learning object */
    xpReceived: number;
    /** The state of the latest answer given by the learning object */
    status: AnswerStatus;
    /** Whether the learning object has been unlocked or not by the user yet */
    isLocked: boolean;
  };
  progress?: {
    status: 'OPEN' | 'CONCEPT' | 'TURNED_IN' | 'ACCEPTED' | 'REJECTED';
    date: DateTimeString;
  };
  /** Contains a flexible set of learning object metadata, such as labels and media. */
  metadata?: {
    labels?: {
      /** Contains the number of people required to complete the contents of the learning object */
      groupSize?: number;
      /** Indicates whether a desktop computer is required for this learning object */
      desktopRequired?: boolean;
      /** Indicates whether this learning object is considered a concluding learning object */
      interimFinalAssignment?: boolean;
      /** Indicates whether the learning object can be made from home */
      remoteLearningSuitable?: boolean;
      /** Indicates whether the learning object is required for finishing the course */
      required?: boolean;
      /** Contains a description of the tools required to finish the learning object */
      tools?: string;
      /** Indicates whether the learning object requires a meetup */
      meetup?: boolean;
      /** Indicates whether the learning object is a checkpoint */
      checkpoint?: boolean;
      /** Indicates whether the learning object is located at a workplace */
      workplace?: boolean;
    };
    media?: {
      /** The image shown before opening the learning object */
      posterUrl?: string;
      /** A video which replaces the poster at the top of an opened learning object */
      videoUrl?: string;
    };
    configuration?: {
      /* Allow an external rating for this learningObject */
      allowExternalRating?: boolean;
    };
  };
  /** Date at which learning object was created */
  createdAt: DateTimeString;
  /** Date at which learning object was last updated */
  updatedAt: DateTimeString;
  /** The learning subject to which the learning object belongs */
  learningSubject?: () => LearningSubject;
  /** The varieties contained within the learning object */
  varieties?: () => LearningObjectVariety[];
  /** The primary learning object variety */
  variety: () => LearningObjectVariety;
  /** The answers given by the current user */
  answers?: () => Answer[];
  /** The course the learning object belongs to */
  course?: () => Course;
  /** The last answer the student has given */
  lastAnswer?: () => Answer;
  /** @deprecated use object.conditions type = "NFC_TAG_SCANNED" instead */
  prerequisites: {
    /** This enables the possibility to have/access NFC tags */
    nfcTagsScanned?: boolean;
  };
  /** NFC tags that are linked to this learningObject */
  nfcTags?: () => NfcTag[];
  /** Conditions that are linked to this learningObject */
  conditions?: () => ObjectCondition[];
  /** Shareables that are linked to this learningObject */
  shareables?: () => Shareable[];
  /** @deprecated Use .metadata.media instead */
  poster?: {
    type: 'embed' | 'iframe' | 'image';
    src: string;
    fileName: string;
  };
  /** @deprecated Use .variety().content instead. */
  content?: LearningObjectContent;
  /** @deprecated Use `.metadata.labels` instead. */
  tags?: Array<{ name: string; [key: string]: string | number | boolean }>;
  quiz?: () => Quiz;
}

export interface LearningObjectCreateInput {
  /** The title of the learning object */
  title: string;
  /** Indicates the type of the learning object */
  base_type: LearningObjectBaseType;
  /** Contains the lifecycle status (concept / published / archived) of the learning object */
  status: LearningObjectStatus;
  /** Creates a primary variety with this as content. Deprecated to send object, so array is expected. */
  content?: Block[] | LearningObjectContent;
  /** @deprecated Use layout instead */
  content_template?: LearningObjectContentTemplate;
  /** The Layout that should be used to display the learning object */
  layout?: string;
  /** Set the number of experience points a student receives for completing this learning object */
  points?: number;
  /** Contains a flexible set of learning object metadata, such as labels and media. */
  metadata?: {
    labels?: {
      /** Contains the number of people required to complete the contents of the learning object */
      group_size?: number;
      /** Indicates whether a desktop computer is required for this learning object */
      desktop_required?: boolean;
      /** Indicates whether this learning object is considered a concluding learning object */
      interim_final_assignment?: boolean;
      /** Indicates whether the learning object can be made from home */
      remote_learning_suitable?: boolean;
      /** Indicates whether the learning object is required for finishing the course */
      required?: boolean;
      /** Contains a description of the tools required to finish the learning object */
      tools?: string;
      /** Indicates whether the learning object requires a meetup */
      meetup?: boolean;
      /** Indicates whether the learning object is a checkpoint */
      checkpoint?: boolean;
      /** Indicates whether the learning object is located at a workplace */
      workplace?: boolean;
    };
    media?: {
      /** The image shown before opening the learning object */
      poster_url?: string;
      /** A video which replaces the poster at the top of an opened learning object */
      video_url?: string;
    };
    configuration?: {
      /* Allow an external rating for this learningObject */
      allow_external_rating?: boolean;
    };
  };
  prerequisites: {
    /** This enables the possibility to have/access NFC tags */
    nfc_tags_scanned: boolean;
  };
  relationships?: {
    /** The learning object collection to which the learning object will be added */
    learning_object_collection: UUID;
    /** The learning route node to which the learning object will be added */
    learning_route_node?: UUID;
    /** The learning subject to which the learning object will be added */
    learning_subject?: UUID;
    /** The learning object type that will be attached to the learning object */
    learning_object_type?: UUID;
    /** NFC tags that are linked to this learningObject */
    nfc_tags?: Array<UUID>;
  };
}

export interface LearningObjectUpdateInput
  extends Partial<LearningObjectCreateInput> {}

export enum LearningObjectIncludes {
  learning_subject,
  varieties,
  answers,
  'answers.answer_group',
  'answers.external_assessments',
  'answers.external_assessments.stored_files',
  'varieties.dossier_workprocesses',
  'varieties.dossier_workprocess_behaviours',
  'varieties.dossier_knowledge_skills',
  nfc_tags,
  shareables,
  quiz,
  'quiz.answer_groups',
}

export interface LearningObjectFilters {
  status: string;
  title: string;
  learning_subject: UUID;
  learning_object_collection: UUID;
  'quiz.answer_groups.latest': true | false;
  'quiz.answer_groups.status': AnswerStatus | '';
}

export enum LearningObjectSorts {
  status,
  title,
}
