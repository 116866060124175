import { useContext } from 'react';
import { DataContext } from './DataContext';
import { TDataContext } from './Types';

export function useDataProvider<T extends object = any>() {
  const context = useContext<undefined | TDataContext<T>>(DataContext);
  if (!context) {
    throw new Error('Initialize the DataProvider before using this hook!');
  }
  return context;
}
