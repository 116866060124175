import { UseQueryOptions } from 'react-query';
import useIndexCR from '../Factories/useIndexCR';
import useSingleCRUD from '../Factories/useSingleCRUD';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  Course,
  CourseCreateInput,
  CourseFilters,
  CourseIncludes,
  CourseSorts,
  CourseUpdateInput,
  HookParams,
  UUID,
} from '../Types';

interface ICourseProps
  extends HookParams<typeof CourseIncludes, CourseFilters, typeof CourseSorts> {
  course?: UUID;
  portal?: UUID;
}

export function usePortalCourse(
  { course, portal, ...queryParameters }: ICourseProps = {},
  settings?: UseQueryOptions<DataDocument<Course>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { course: defaultCourse, portal: defaultPortal },
  } = context;

  const request = new ApiRequest(
    {
      enabled: !!(course || defaultCourse) && !!(portal || defaultPortal),
      baseName: 'v1.portals.courses',
      baseUri: `/v1/portals/${portal || defaultPortal}/courses`,
      invalidate: ['course', 'courses'],
      uri: `/${course}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<Course, CourseCreateInput, CourseUpdateInput>(
    request,
    settings
  );
}

export function usePortalCourses(
  { portal, ...queryParameters }: Omit<ICourseProps, 'course'> = {},
  settings?: UseQueryOptions<DataDocument<Course[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { portal: defaultPortal },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(portal || defaultPortal),
      baseName: 'v1.portals.courses',
      invalidate: ['course', 'courses'],
      baseUri: `/v1/portals/${portal || defaultPortal}/courses`,
      queryParameters,
    },
    context
  );
  return useIndexCR<Course, CourseCreateInput>(request, settings);
}
