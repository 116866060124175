import { Button, Panel } from '@brainstud/ui';
import { Container } from 'Components';
import { useTranslator } from 'Providers/Translator';
import { ReactComponent as SleepingLaptop } from '../../../Assets/sleeping_laptop.svg';
import styles from './LoggedOutView.module.css';

/**
 * View which is shown when user is logged out of the current session (after certain timeout).
 */
export const LoggedOutView = () => {
  const [t] = useTranslator();

  return (
    <Container className={styles.base}>
      <Panel className={styles.panel}>
        <div className={styles.texts}>
          <h3>{t('loggedOut.title')}</h3>
          <p>{t('loggedOut.description')}</p>
          <Button href="/account/start">{t('loggedOut.login')}</Button>
        </div>
        <SleepingLaptop className={styles.sleeping_laptop} />
      </Panel>
    </Container>
  );
};
