import { IResource } from '../Utils/Resource';

export enum FeatureSubscriptionOption {
  never = 'never',
  always = 'always',
  event = 'event',
}

/**
 * A Feature is a part of the platform that can be enabled or disabled for the users of a Portal or its users.
 */
export interface Feature extends IResource<'features'> {
  /** A human readable key in snake_case */
  key: string;
  /** The title */
  title: string;
  /** The state of the feature (portal scoped) */
  enabled: FeatureSubscriptionOption;
}

export type FeatureCreateInput = {};

export type FeatureUpdateInput = {
  enabled: FeatureSubscriptionOption;
};

export enum FeatureIncludes {}

export enum FeatureFilters {}

export enum FeatureSort {}
