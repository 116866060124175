import { ChangeEvent, useMemo, useState } from 'react';
import {
  Student,
  useStudentGroupAccounts,
  useStudentGroups,
  UUID,
} from '@brainstud/academy-api';
import {
  Button,
  Callout,
  ConfirmationModal,
  Dropdown,
  Input,
} from '@brainstud/ui';
import { Loading } from 'Components';
import { useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import classes from './GroupModal.module.css';

type Props = {
  /** The selected students to perform the grouping action on. */
  students: Student[];
  /** The function that is called upon closing the modal. */
  closeModal: () => void;
  /** An action to be performed when the modal is submitted, gets called before closing the modal. */
  onComplete?: () => void;
};

const GroupModal = ({ students, closeModal, onComplete }: Props) => {
  const [t] = useTranslator();
  const [setToast] = useToaster();
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState<string | undefined>(undefined);
  const [studentGroupId, setStudentGroupId] = useState<UUID | undefined>(
    undefined
  );
  const [{ data: groups, create: createGroup }, { isLoading }] =
    useStudentGroups();
  const [{ create: addGroupAccounts, destroy: deleteGroupAccounts }] =
    useStudentGroupAccounts(
      {
        studentGroup: studentGroupId,
      },
      { enabled: false }
    );
  const [name, setName] = useState('');

  const studentIds = useMemo(() => students.map((item) => item.id), [students]);

  const selectedStudentGroupId = useMemo(() => {
    const ids = students.map((student) => student.studentGroup?.()?.id);
    const uniqueIds = new Set(ids);
    return uniqueIds.size === 1 ? uniqueIds.values().next().value : undefined;
  }, [students]);

  const handleCreate = async () => {
    const { data } = await createGroup.mutateAsync({ name });
    setStudentGroupId(data.id);

    await addGroupAccounts.mutateAsync({
      students: studentIds,
    });

    setToast(t('modals.groupModal.toastConfirmCreate'), 'success');
  };

  const handleDegroup = async () => {
    await deleteGroupAccounts.mutateAsync({
      students: studentIds,
    });

    setToast(t('modals.groupModal.toastConfirmDegroup'), 'success');
  };

  const handleMove = async () => {
    setStudentGroupId(action);

    await addGroupAccounts.mutateAsync({
      students: studentIds,
    });

    setToast(t('modals.groupModal.toastConfirmMove'), 'success');
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (action === 'create') {
      await handleCreate();
    } else if (action === 'degroup') {
      await handleDegroup();
    } else {
      await handleMove();
    }
    onComplete?.();
    closeModal();
  };

  const options = [
    { value: 'create', label: t('modals.groupModal.create') },
    ...(selectedStudentGroupId
      ? [{ value: 'degroup', label: t('modals.groupModal.degroup') }]
      : []),
    ...groups.map((group) => ({
      value: group.id,
      label: t('modals.groupModal.move', { group: group.name }),
    })),
  ];

  return (
    <ConfirmationModal
      onClickOutside
      closeModal={closeModal}
      className={classes.root}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <h3>{t('modals.groupModal.title')}</h3>
          <p>{t('modals.groupModal.body')}</p>
          <Callout
            info
            dangerouslySetInnerHTML={{
              __html: sanitizer(t('modals.groupModal.callout')),
            }}
          />
          <Dropdown
            id="action"
            style={{ marginTop: '1rem' }}
            label={t('modals.groupModal.actions')}
            placeholder={t('modals.groupModal.selectAction')}
            value={studentGroupId || action}
            searchable={options.length > 5}
            onChange={(value) => {
              if (value === 'create') {
                setAction(value);
                setStudentGroupId(undefined);
              } else if (value === 'degroup') {
                setAction(value);
                setStudentGroupId(selectedStudentGroupId);
              } else {
                setAction('move');
                setStudentGroupId(value);
              }
            }}
          >
            {options.map((option) => (
              <Dropdown.Option key={option.value} value={option.value}>
                {option.label}
              </Dropdown.Option>
            ))}
          </Dropdown>
          {action === 'create' && (
            <Input
              label={t('modals.groupModal.name')}
              name="group-name"
              value={name}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setName(e.target.value)
              }
            />
          )}
          <div style={{ marginTop: '4rem' }}>
            <Button
              type="button"
              onClick={handleSubmit}
              loading={loading}
              style={{ marginRight: '1rem' }}
            >
              {t('modals.groupModal.confirm')}
            </Button>
            <Button type="button" quiet onClick={closeModal}>
              {t('modals.cancel')}
            </Button>
          </div>
        </>
      )}
    </ConfirmationModal>
  );
};

export default GroupModal;
