import React, { ReactNode, useEffect, useMemo, useReducer } from 'react';
import { useQueryClient } from 'react-query';
import { usePing } from '@brainstud/academy-api/Hooks/usePing';
import { ADMIN_ENV_ROLES, COACH_ROLES } from 'Config/roles';
import { useRouter } from 'next/router';
import { HTTP_UNAUTHORIZED } from '../../Config/http';
import { useHasAnyRole } from '../../Hooks/Roles';
import ACTIONS from './EnvironmentActions';
import { EnvironmentContext } from './EnvironmentContext';
import {
  EnvironmentReducer,
  InitialEnvironmentState,
  TEnvironments,
} from './EnvironmentReducer';

type Props = {
  children: ReactNode;
};

interface IBaseApiResource {
  id: string;
  type: string;
  attributes: {
    [key: string]: unknown;
  };
  relationships?: {
    [key: string]: unknown;
  };
}

interface IBaseApiResponse {
  statusCode: number;
  data: IBaseApiResource | Array<IBaseApiResource>;
  includes?: Array<IBaseApiResource>;
}

/**
 * EnvironmentProvider.
 *
 * Keeps track of the current environment the user is in.
 */
export const EnvironmentProvider = ({ children }: Props) => {
  const { pathname } = useRouter();
  const isAdmin = useHasAnyRole(ADMIN_ENV_ROLES);
  const isCoach = useHasAnyRole(COACH_ROLES);
  const isGuest = useHasAnyRole('unauthenticated');
  const primaryRole: TEnvironments =
    (isAdmin && 'admin') ||
    (isCoach && 'coach') ||
    (!isGuest && 'student') ||
    'guest';
  const [state, dispatch] = useReducer(
    EnvironmentReducer,
    InitialEnvironmentState
  );

  useEffect(() => {
    dispatch({
      type: ACTIONS.AUTO_SET_ENVIRONMENT,
      payload: {
        pathname,
        role: primaryRole,
      },
    });
  }, [pathname, primaryRole]);

  // When API responds with a 401, check whether user still has proper credentials.
  const queryClient = useQueryClient();
  const [data, { refetch }] = usePing({
    staleTime: 10 * 60 * 1000, // Refetch on window focus after 5 minutes
    refetchInterval: 30 * 60 * 1000, // Ping every 30 minutes
    refetchOnWindowFocus: true,
  });
  const { redirect } = data || {};
  const queryCache = queryClient.getQueryCache();
  useEffect(
    () =>
      queryCache.subscribe((result) => {
        if (result) {
          const response = queryCache.get(result.query.queryHash);
          const responseData = response?.state.error as IBaseApiResponse;
          if (responseData?.statusCode === HTTP_UNAUTHORIZED) {
            refetch();
          }
        }
      }),
    [refetch, queryCache]
  );

  // If user does not have valid credentials, push to redirect link.
  const router = useRouter();
  useEffect(() => {
    if (redirect && router.asPath !== redirect) {
      router.push(redirect).then(() => {
        queryClient.invalidateQueries();
      });
    }
  }, [queryClient, redirect, router]);

  const {
    environment,
    prevEnvironment,
    isAdminEnvironment,
    isStudentEnvironment,
    isCoachEnvironment,
    isGuestEnvironment,
  } = state;

  const context = useMemo(
    () => ({
      environment,
      prevEnvironment,
      isAdminEnvironment,
      isStudentEnvironment,
      isCoachEnvironment,
      isGuestEnvironment,
      baseEnvironment: primaryRole,
      isLoggedIn: !isGuest,
      dispatch,
    }),
    [
      environment,
      prevEnvironment,
      isAdminEnvironment,
      isStudentEnvironment,
      isCoachEnvironment,
      isGuestEnvironment,
      primaryRole,
      isGuest,
    ]
  );

  return (
    <EnvironmentContext.Provider value={context}>
      {children}
    </EnvironmentContext.Provider>
  );
};
