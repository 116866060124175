import React, { useCallback, useMemo, useState } from 'react';
import { Enrollment, Exam, useExamStepApproval } from '@brainstud/academy-api';
import { Button, ContentModal, Status } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { useModals } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import styles from './ExamModal.module.css';

const cx = classNames.bind(styles);

type Props = {
  exam: Exam;
  enrollment: Enrollment;
};

export const ExamModal = ({ exam, enrollment }: Props) => {
  const { closeModal } = useModals();
  const [t] = useTranslator();

  const contentVariety = useMemo(() => exam?.examContentVariety?.(), [exam]);
  const courseTitle = useMemo(() => enrollment?.course?.().title, [enrollment]);

  const [{ create: startExam }] = useExamStepApproval({
    exam: exam.id,
  });
  const [loading, setLoading] = useState(false);
  const handleStartExam = useCallback(() => {
    setLoading(true);
    return startExam
      .mutateAsync({
        step: 'student_start',
      })
      .then(() => {
        setLoading(false);
      });
  }, [startExam]);

  return (
    <ContentModal
      onClickOutside={!loading}
      onClose={!loading ? closeModal : undefined}
      closeable={!loading}
    >
      <div className={cx('modal')}>
        <div className={cx('modal-element-container')}>
          <h2>{t('views.exam.modal.title', { name: courseTitle })}</h2>
          <div>
            <Status className={cx('exam-tag')}>
              {t('views.collection.exam_block.exam')}
            </Status>
          </div>
        </div>
        {contentVariety?.introduction ? (
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizer(contentVariety?.introduction),
            }}
          />
        ) : (
          <div>
            <p>{t('views.exam.modal.intro', { name: courseTitle })}</p>
            <h2>{t('views.exam.modal.before_you_start')}</h2>
            <p>{t('views.exam.modal.generic_advice')}</p>
          </div>
        )}

        <div className={cx('modal-element-container')}>
          <Button link disabled={loading} onClick={() => closeModal()}>
            {t('close')}
          </Button>
          <Button
            to={`/courses/${enrollment?.course?.().id}/exam/${exam.id}`}
            loading={loading}
            onClick={async () => {
              await handleStartExam();
              closeModal();
              return true;
            }}
          >
            {t('views.exam.modal.start_exam')}
          </Button>
        </div>
      </div>
    </ContentModal>
  );
};
