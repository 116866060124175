import { Plan } from '@brainstud/academy-api/Types/Resources/Plan';
import { TRoles } from 'Config/roles';
import type { DateTimeString } from '../Utils/DateTimeString';
import type { IResource } from '../Utils/Resource';
import type { Catalog } from './Catalog';
import type { Course } from './Course';
import type { PriceModel, Product, TProductType } from './Product';
import type { SubscriptionOption } from './SubscriptionOption';

export interface CatalogProduct extends IResource<'catalog_products'> {
  name: string;
  title: string;
  state: string;
  description: string;
  posterUrl: string;
  priceModel: string;
  productType: TProductType;
  thumbnailUrl: string;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  opener?: string;
  /** Hides product from catalog. Used for SLA kind of products */
  listed: boolean;
  /** @deprecated Makes it invisible in catalog and disables purchase */
  enabled: boolean;
  /** Makes it visible in the catalog, but disables enrollment or purchase */
  locked: boolean;
  /** Makes it only available to people that already purchased it. */
  deprecated?: boolean;
  vatRate?: string;
  priceBeforeVat?: number;
  priceAfterVat?: number;
  currency?: string;
  product?: () => Product;
  catalog?: () => Catalog;
  plans?: () => Plan[];
  productModels?: () => Array<Course | SubscriptionOption>;
}

export interface CatalogProductCreateInput {
  enabled?: boolean;
  listed?: boolean;
  locked?: boolean;
  roles?: TRoles[];
  price_model?: PriceModel;
  price?: number;
  currency?: string;
  vat_rate?: string;
  relationships: {
    product: string;
  };
}

export type CatalogProductUpdateInput = {
  enabled?: boolean;
  listed?: boolean;
  locked?: boolean;
};

export enum CatalogProductIncludes {
  plans,
  catalog,
  product,
}

export interface CatalogProductFilters {
  name: string;
  price_models: string[];
  product_types: string[];
  listed: boolean;
  enabled: boolean;
  locked: boolean;
}
export enum CatalogProductSorts {
  created_at,
  updated_at,
}
