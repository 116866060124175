import React, { createContext } from 'react';
import { LearningObject } from '@brainstud/academy-api';
import { IBlock } from '@brainstud/course-blocks';

export interface IEditorContext {
  isContentEditor: true;
  learningObject?: LearningObject;
  setBlocks: React.Dispatch<React.SetStateAction<IBlock<string>[]>>;
  blocks: IBlock[];
  layout: 'rows' | 'columns';
  points: number;
  setLayout: React.Dispatch<React.SetStateAction<IEditorContext['layout']>>;
  save: () => void;
  addBlock: (block: Omit<IBlock, 'id'>, containerId?: string) => string;
  updateBlock: (block: IBlock) => void;
  swapBlocks: (firstId: string, secondId: string) => void;
  removeBlock: (block: string | IBlock) => void;
  isUnsaved: boolean;
  isLoading: boolean;
  /** Whether the preview mode of the editor should be shown. */
  previewMode: boolean;
  setPreviewMode: React.Dispatch<React.SetStateAction<boolean>>;
  activeBlock: null | string;
  activeEditor: null | string;
  setActiveBlock: React.Dispatch<
    React.SetStateAction<IEditorContext['activeBlock']>
  >;
  setActiveEditor: React.Dispatch<
    React.SetStateAction<IEditorContext['activeEditor']>
  >;
  setPoints: (xp: string) => void;
  handleOpenBlockModal: (containerId?: string) => void;
}

const DefaultContext = {
  isContentEditor: false,
  previewMode: false,
};

type EmptyContext = typeof DefaultContext;

export const ContentEditorContext = createContext<
  EmptyContext | IEditorContext
>(DefaultContext);
