import React, { useMemo } from 'react';
import { Button } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { useTwoFactor } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { ReactComponent as AccountProtected } from 'public/media/icons/account-protected.svg';
import styles from './TwoFactorAuthConfirmationView.module.css';

const cx = classNames.bind(styles);

export const TwoFactorAuthConfirmationView = () => {
  const { isAccountPage, returnTo, setToken } = useTwoFactor();
  const [t] = useTranslator();

  const handleConfirmation = useMemo(() => {
    if (isAccountPage) {
      return '/account/security';
    }
    setToken();
    return returnTo || '/';
  }, [isAccountPage, returnTo, setToken]);

  return (
    <>
      {isAccountPage && <h3>{t('security.two_factor.confirmation.title')}</h3>}
      <div className={cx(styles.info, { 'tfa-required': !isAccountPage })}>
        <AccountProtected className={cx(styles.icon)} />
        {!isAccountPage && (
          <h3>{t('security.two_factor.confirmation.title')}</h3>
        )}
        <span>{t('security.two_factor.confirmation.info')}</span>
      </div>
      <div
        className={cx(styles['button-wrapper'], {
          'tfa-required': !isAccountPage,
        })}
      >
        <Button to={handleConfirmation}>
          {t(
            `security.two_factor.${isAccountPage ? 'back_to_security' : 'continue'}`
          )}
        </Button>
      </div>
    </>
  );
};
