import { useMemo } from 'react';
import { isPresent } from 'ts-is-present';
import { TBlock } from './ContentBlocksContext';

type TCallbackFn = (block: TBlock) => TBlock;

/**
 * blockTreeToListReducer.
 *
 * This method reduces the complex blocks structure to a linear flat array of blocks.
 * With that array, you can more easily iterate over all blocks and find the appropriate block
 * to modify or read values from.
 */
function blockTreeToListReducer(
  blocks: TBlock[],
  callback?: TCallbackFn
): TBlock[] {
  return blocks.reduce<TBlock[]>((prevValue, currentValue) => {
    // Check for inner blocks in 3 separate locations, where `.blocks` is preferred.
    const container =
      currentValue.blocks ||
      currentValue.tabs?.map((tab) => tab.block) || // Legacy tabs container block
      currentValue?.block?.blocks; // Legacy inner container block
    return [
      ...prevValue,
      container
        ? blockTreeToListReducer(container, callback)
        : (callback && callback(currentValue)) || currentValue,
    ]
      .flat()
      .filter(isPresent);
  }, []);
}

/**
 * Recursively flattens the block tree of an learning object.  Skips the container blocks.
 * @param blockTree
 * @return Block[] A flat list of the blocks in the assignment without container blocks
 */
export function useBlocksHelper(blockTree: TBlock[]) {
  return useMemo(() => blockTreeToListReducer(blockTree), [blockTree]);
}
