import { UseQueryOptions } from 'react-query';
import axios from 'axios';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { THeaders } from '../Providers/ApiProvider/IApiContext';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  PortalLanguage,
  PortalLanguageCreate,
  PortalLanguageUpdate,
  UUID,
} from '../Types';

export async function getPortalLanguages(portal: UUID, headers: THeaders = {}) {
  const domainName = process.env.PORTAL_DOMAIN;
  const result = new DataDocument<PortalLanguage[]>(
    await axios.get(`/v1/portals/${portal}/languages`, {
      headers: {
        ...(domainName ? { 'X-Portal-Domain': domainName } : {}),
        'X-Feature-Next': '1',
        ...headers,
      },
    })
  );

  return [result.data, result] as const;
}

interface IPortalLanguagesProps {
  portal?: UUID;
  language?: UUID;
}

/**
 * Portal languages can be used to set the available languages of a specific portal
 * @param portal
 * @param language
 * @param queryParameters
 * @param settings
 */

export function usePortalLanguage(
  { portal, language, ...queryParameters }: IPortalLanguagesProps = {},
  settings?: UseQueryOptions<DataDocument<PortalLanguage>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { portal: defaultPortal },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!language,
      baseName: 'v1.portals.language',
      baseUri: `/v1/portals/${portal || defaultPortal}/languages`,
      uri: `/${language}`,
      invalidate: ['v1.portals.languages'],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    PortalLanguage,
    PortalLanguageCreate,
    PortalLanguageUpdate
  >(request, settings);
}

/**
 * Portal languages can be used to set the available languages of a specific portal
 * @param portal
 * @param queryParameters
 * @param settings
 */

export function usePortalLanguages(
  { portal, ...queryParameters }: Omit<IPortalLanguagesProps, 'language'> = {},
  settings?: UseQueryOptions<DataDocument<PortalLanguage[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { portal: defaultPortal },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(portal || defaultPortal),
      baseName: 'v1.portals.languages',
      baseUri: `/v1/portals/${portal || defaultPortal}/languages`,
      invalidate: ['v1.portals.languages'],
      queryParameters,
    },
    context
  );
  return useIndexCR<PortalLanguage, PortalLanguageCreate>(request, settings);
}
