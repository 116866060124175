import { useCallback, useMemo, useState } from 'react';
import { Button, Callout } from '@brainstud/ui';
import axios, { AxiosError } from 'axios';
import { RichTextEditor } from 'Components';
import {
  ADMIN_ENV_ROLES,
  AUTHOR_ROLES,
  COACH_ROLES,
  STUDENT_ROLES,
  UNLIMITED_ROLES,
} from 'Config/roles';
import { useHasAnyRole, useUserData } from 'Hooks';
import { Form } from 'Modules/universal-components';
import { TNoteArgs } from 'pages/api/feedback/note';
import { useTranslator } from 'Providers/Translator';
import styles from './Form.module.css';

type Props = {
  onCancel?: () => void;
  projectId?: string;
  label: string;
  placeholder?: string;
};

type TFeedbackForm = {
  feedback: string;
};

/**
 * FeedbackForm.
 *
 * Shows a form where a user of the platform can pitch their own idea or suggestion to make the software better.
 */
export const FeedbackForm = ({
  onCancel,
  projectId,
  label,
  placeholder,
}: Props) => {
  const [t] = useTranslator();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);
  const [{ fakeAccountEmail, id, entity, portal }] = useUserData();
  const isBrainstud = useHasAnyRole(UNLIMITED_ROLES);
  const portalName = portal?.name.toLowerCase();
  const isClient = !portalName || !/(you can)|(unlimited)/i.test(portalName);
  const isAdmin = useHasAnyRole(ADMIN_ENV_ROLES);
  const isAuthor = useHasAnyRole(AUTHOR_ROLES);
  const isCoach = useHasAnyRole(COACH_ROLES) && !isAdmin;
  const isStudent = useHasAnyRole(STUDENT_ROLES) && !isCoach && !isAdmin;
  const isGuest = useHasAnyRole('unauthenticated');

  const customerSegments = useMemo(
    () => [
      ...(isClient ? ['d2eefd7f-18ca-489e-8fc0-ecfcc9d5a12c'] : []),
      ...(isBrainstud ? ['e5e12018-aae6-4176-9858-445d7266b136'] : []),
      ...(isAdmin ? ['4711c433-eb5f-41f7-ba51-b3f26d73595a'] : []),
      ...(isAuthor ? ['6eff70b8-d9ff-4f66-bc4e-35b61e3ae3d5'] : []),
      ...(isCoach ? ['44c0b265-82de-483f-94af-c341ee53b975'] : []),
      ...(isStudent ? ['32b38a5b-613b-47f9-9370-200943fd1c56'] : []),
      ...(isGuest ? ['cd0d8940-a622-482e-93ac-1be159923216'] : []),
    ],
    [isAdmin, isAuthor, isBrainstud, isClient, isCoach, isGuest, isStudent]
  );

  const handleSubmit = useCallback(
    async (data: TFeedbackForm) => {
      setIsLoading(true);

      return axios
        .post<any, any, TNoteArgs>('/feedback/note', {
          feedback: data.feedback,
          email: fakeAccountEmail,
          accountId: id,
          url: window.location.href,
          entity: entity?.()?.name,
          projectId,
          segments: customerSegments,
        })
        .then((response) => response)
        .catch((response) =>
          response instanceof AxiosError ? response.response : response
        )
        .finally(() => setIsLoading(false));
    },
    [fakeAccountEmail, projectId, entity, id, customerSegments]
  );

  const hanleOnSubmitSuccess = useCallback(() => {
    setIsSuccessfullySubmitted(true);
  }, []);

  if (isSuccessfullySubmitted) {
    return (
      <div className={styles.callout}>
        <Callout success>{t('modals.feedback.project.result.success')}</Callout>
      </div>
    );
  }

  return fakeAccountEmail ? (
    <Form<TFeedbackForm>
      onSubmit={handleSubmit}
      onSuccess={hanleOnSubmitSuccess}
    >
      <RichTextEditor
        id="feedback"
        className={styles.textarea}
        label={label}
        placeholder={placeholder}
      />
      <div className={styles.buttons}>
        <Button
          type="submit"
          loading={isLoading}
          small
          className={styles.submit}
        >
          {t('modals.feedback.project.submit')}
        </Button>
        <Button
          type="button"
          quiet
          className={styles.cancel}
          onClick={onCancel}
          small
        >
          {t('modals.feedback.project.cancel')}
        </Button>
      </div>
    </Form>
  ) : null;
};
