import { UseMutationOptions } from 'react-query';
import { useCreate } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import { Exam, ExamSignalCreateInput, UUID } from '../Types';

interface IProps {
  exam: UUID;
}

export function useCreateExamSignal(
  { exam }: IProps,
  settings?: UseMutationOptions<
    DataDocument<Exam>,
    ErrorDocument,
    ExamSignalCreateInput
  >
) {
  const context = useApi();
  const {
    parameters: { exam: defaultExam },
  } = context;
  const request = new ApiRequest(
    {
      baseName: 'v1.exams.signal',
      baseUri: `/v1/exams/${exam || defaultExam}/signal`,
      invalidate: ['exams', 'exam', 'v1.exams'],
    },
    context
  );
  return useCreate<Exam, ExamSignalCreateInput>(request, settings);
}
