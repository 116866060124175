import React, { useCallback, useMemo, useState } from 'react';
import { useEnrollment, useMe, usePortalCourses } from '@brainstud/academy-api';
import { Button, Callout, Checkbox, ContentModal } from '@brainstud/ui';
import { Grid } from '@mui/material';
import { FlexContainer, Loading } from 'Components';
import { useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import styles from './EnrollModal.module.css';

export interface EnrollModalProps {
  course: any;
  closeModal: () => void;
}

const EnrollModal = ({ course, closeModal }: EnrollModalProps) => {
  const [t] = useTranslator();
  const [me] = useMe();
  const [setToast] = useToaster();
  const [loading, setLoading] = useState<boolean>(false);
  const [agreeConditions, setAgreeConditions] = useState<boolean>(false);
  const [{ create: createEnrollment }] = useEnrollment();

  const [{ data: courses }] = usePortalCourses();
  const portalCourse = useMemo(
    () => courses.find((item) => item.id === course.id),
    [courses]
  );
  const coachConditions = portalCourse?.coachConditions?.().downloadUrl;
  const noExam = useMemo(() => !portalCourse?.examsEnabled, [portalCourse]);

  const enroll = useCallback(async () => {
    setLoading(true);
    await createEnrollment.mutateAsync(
      {
        course: course.id,
        portal: me?.portal?.().id as string,
      },
      {
        onSettled: () => {
          setLoading(false);
          closeModal();
          setToast(t('modals.enrollModal.toastConfirm'), 'success');
        },
      }
    );
  }, [createEnrollment, course, closeModal, me, setToast, t]);

  return (
    <ContentModal onClickOutside onClose={closeModal} className={styles.base}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <FlexContainer>
            <h2>{t('modals.enrollModal.title')}</h2>
            <Grid container style={!noExam ? { marginBottom: '4rem' } : {}}>
              {!!coachConditions && (
                <Grid item xs={1}>
                  <Checkbox
                    className={styles.agree}
                    quiet
                    checked={agreeConditions}
                    onChange={() =>
                      setAgreeConditions((prevAgreement) => !prevAgreement)
                    }
                  />
                </Grid>
              )}
              <Grid item xs={coachConditions ? 11 : 12}>
                {t(
                  `modals.enrollModal.text.${coachConditions ? 'conditional' : 'unconditional'}`
                )}
                {!!coachConditions && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: sanitizer(
                        t('modals.enrollModal.conditions', {
                          link: coachConditions,
                        }),
                        'inlineOnly'
                      ),
                    }}
                  />
                )}
              </Grid>
            </Grid>
            {noExam && (
              <Callout warning>
                <h4>{t('modals.enrollModal.exam')}</h4>
                <p>{t('modals.enrollModal.noExam')}</p>
              </Callout>
            )}
          </FlexContainer>
          <ContentModal.Footer>
            <Button
              type="button"
              disabled={!!coachConditions && !agreeConditions}
              onClick={enroll}
            >
              {t('modals.enrollModal.enroll')}
            </Button>
            <Button type="button" quiet onClick={closeModal}>
              {t('modals.cancel')}
            </Button>
          </ContentModal.Footer>
        </>
      )}
    </ContentModal>
  );
};

export default EnrollModal;
