import React, { ReactNode, useMemo } from 'react';
import { useAccount, useMe, useProfile } from '@brainstud/academy-api';
import { Button, Panel } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { Container, Loading } from 'Components';
import { FEATURES } from 'Config/features';
import { usePortalFeatureCheck } from 'Hooks';
import { useRouter } from 'next/router';
import { useBreadcrumbs, useHeadProvider } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { isUUID } from 'Utils/isUuid';
import { ProfileContext } from './ProfileContext';
import styles from './ProfileLayout.module.css';

const cx = classNames.bind(styles);

type Props = {
  title?: string;
  children?: ReactNode;
};

/**
 * ProfileLayout.
 *
 * Provides a basic markup for the page and the minimum required data for any profile related page.
 */
export const ProfileLayout = ({ title, children }: Props) => {
  useHeadProvider(title);

  const router = useRouter();
  const [t] = useTranslator();

  const { profileId } = router.query as { profileId: string };
  const [me] = useMe();
  const [{ data: account }] = useAccount(
    {
      account: me?.account?.().id,
      include: ['profile'],
    },
    { enabled: !profileId }
  );

  const isFileUploadEnabled = usePortalFeatureCheck(FEATURES.ACCOUNT_FILES);

  const [profile, { isLoading, error }] = useProfile({
    slug: !isUUID(profileId) ? profileId : undefined,
    profile:
      !profileId || isUUID(profileId)
        ? profileId || account?.profile?.()?.id
        : undefined,
    include: ['account', 'account.expositions'],
    showStoredFilesCount: isFileUploadEnabled,
  });

  const isMine = me?.account?.().id === profile?.account?.().id;

  useBreadcrumbs(
    {
      profiles: null,
      ...(profileId ? { [profileId]: null } : {}),
      environment: {
        text: profile?.account?.().fullName,
        href: `/profiles/${profileId || profile?.id}`,
      },
    },
    [profile, profileId],
    true
  );

  const context = useMemo(
    () => ({
      isMine,
      isLoading,
      profile,
    }),
    [isLoading, isMine, profile]
  );

  return (
    <ProfileContext.Provider value={context}>
      <main className={cx(styles.base)}>
        {!error ? (
          <>{isLoading ? <Loading /> : children}</>
        ) : (
          <Container gap>
            <Panel pad>
              <h1>{t('views.account.profile.errors.not_found.title')}</h1>
              <p>{t('views.account.profile.errors.not_found.description')}</p>
              <Button href="/">{t('go_home')}</Button>
            </Panel>
          </Container>
        )}
      </main>
    </ProfileContext.Provider>
  );
};
