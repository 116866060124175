import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BlockRenderer, IReactWidgetBlock } from '@brainstud/course-blocks';
import { Input } from '@brainstud/ui';
import classNames from 'classnames/bind';
import type { JsonTypes } from 'jsoneditor-react';
import { useTranslator } from 'Providers/Translator';
import { JsonEditor } from '../../../Components';
import { toName } from '../../../Support/toName';
import { useCoordinates } from './useCoordinates';
import styles from './ReactWidgetForm.module.css';

const classes = classNames.bind(styles);

type Props = {
  data: Omit<IReactWidgetBlock, 'type' | 'read'>;
  onChange?: (data: Omit<Props['data'], 'id'>) => void;
};

/**
 * A form to configure the React widget.
 */
export const ReactWidgetForm = ({
  data: { id, url: defaultUrl, props: defaultProps },
  onChange,
}: Props) => {
  const [t] = useTranslator();
  const [url, setUrl] = useState(defaultUrl);
  const [props, setProps] = useState<any>(defaultProps);

  const block = useMemo(
    () => ({
      id,
      type: 'react_widget',
      url,
      props,
    }),
    [id, url, props]
  );

  useEffect(() => {
    onChange?.({
      url,
      props,
      wideness: 'max',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, props]);

  const handleChange = useCallback((output: JsonTypes) => {
    setProps(output);
  }, []);

  const [widget, form, dimensions] = useCoordinates();

  return (
    <div ref={form} className={classes(styles.base)}>
      <Input
        id={toName(id)}
        label={t('course-editor.content_editor.blocks.react_widget.url_label')}
        value={url}
        onChange={(event) => setUrl(event.target.value)}
      />
      <div className={styles.preview} ref={widget}>
        <BlockRenderer id={id} block={block} spacing={false} />
        {!!dimensions && (
          <div
            className={styles.selection}
            style={{
              top: `${dimensions.y}%`,
              left: `${dimensions.x}%`,
              width: `${dimensions.width}%`,
              height: `${dimensions.height}%`,
            }}
          />
        )}
      </div>
      {!!dimensions && (
        <pre className={styles.details}>
          {`{
  x: ${dimensions.x},
  y: ${dimensions.y},
  width: ${dimensions.width},
  height: ${dimensions.height},
}`}
        </pre>
      )}
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label>
        {t('course-editor.content_editor.blocks.react_widget.props_label')}
      </label>
      <JsonEditor
        defaultValue={props || {}}
        onChange={handleChange}
        modes={['tree', 'text']}
      />
    </div>
  );
};
