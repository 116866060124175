import React, { PropsWithChildren } from 'react';
import {
  AutoStoriesOutlined,
  DesktopMacOutlined,
  Group,
  House,
  LocationOn,
  Shield,
} from '@mui/icons-material';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import styles from './CardTag.module.css';

const cx = classNames.bind(styles);

type TProps = {
  type: string;
  value?: number | string | boolean;
  className?: string;
  style?: React.CSSProperties;
};

export const CardTag = ({
  type,
  className,
  style,
  value = 0,
}: PropsWithChildren<TProps>) => {
  const [t] = useTranslator();
  const numberValue = parseFloat(value.toString());
  const isGroup = type === 'groupSize' && numberValue > 1;

  if (type === 'groupSize' && !isGroup) return null;

  return (
    <div className={cx(styles.base, type, className)} style={style}>
      {isGroup && (
        <>
          <span>{value}</span>
          <Group />
        </>
      )}
      {type === 'theory' && (
        <>
          <AutoStoriesOutlined />
          <span>{t('components.card_tag.theory')}</span>
        </>
      )}
      {type === 'desktopRequired' && <DesktopMacOutlined />}
      {type === 'interimFinalAssignment' && <Shield />}
      {type === 'remoteLearningSuitable' && <House />}
      {type === 'xp' && (
        <span>{`${value?.toLocaleString()} ${t('components.card_tag.xp')}`}</span>
      )}
      {type === 'workplace' && <LocationOn />}
    </div>
  );
};
