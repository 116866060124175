import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  Quiz,
  QuizCreateInput,
  QuizFilters,
  QuizIncludes,
  QuizSorts,
  QuizUpdateInput,
  UUID,
} from '../Types';

interface IQuizProps
  extends HookParams<
    typeof QuizIncludes,
    typeof QuizFilters,
    typeof QuizSorts
  > {
  quiz?: UUID;
}

export function useQuiz(
  { quiz, ...queryParameters }: IQuizProps = {},
  settings?: UseQueryOptions<DataDocument<Quiz>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { quiz: defaultQuiz },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(quiz || defaultQuiz),
      baseName: 'v1.quizzes',
      baseUri: '/v1/quizzes',
      uri: `/${quiz}`,
      invalidate: ['quiz', 'quizzes', 'learning_subjects', 'learning_subject'],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<Quiz, QuizCreateInput, QuizUpdateInput>(
    request,
    settings
  );
}

export function useQuizzes(
  { ...queryParameters }: IQuizProps = {},
  settings?: UseQueryOptions<DataDocument<Quiz[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.quizzes',
      baseUri: '/v1/quizzes',
      invalidate: ['quiz', 'quizzes'],
      queryParameters,
    },
    context
  );
  return useIndexCR<Quiz, QuizCreateInput>(request, settings);
}
