/**
 * useOnMount
 */
import { useEffect, useRef } from 'react';

export function useOnMount(
  effect: Parameters<typeof useEffect>[0],
  deps: Parameters<typeof useEffect>[1]
) {
  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) {
      return effect();
    }
    isMounted.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
