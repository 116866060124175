import { MouseEvent, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import axios from 'axios';
import { useRouter } from 'next/router';
import { useTranslator } from 'Providers/Translator';
import { useToaster } from '../Providers';

/**
 * useHandleLogout.
 *
 * Easy handler for logging out the user
 */
export function useHandleLogout() {
  const queryClient = useQueryClient();
  const [setFlash] = useToaster();
  const router = useRouter();
  const [t] = useTranslator();
  return useCallback(
    async (
      message: string | MouseEvent<any>,
      scheme: 'error' | 'warning' | 'success' = 'success'
    ) => {
      const toastMessage =
        typeof message === 'string' ? message : 'logout.successful';
      await axios.get('/auth/logout');
      queryClient.removeQueries();
      await router.push('/auth?loggedOut=true');
      window.scrollTo(0, 0);
      setFlash(t(toastMessage), scheme);
    },
    [queryClient, setFlash, router, t]
  );
}
