import { FC, useCallback, useState } from 'react';
import {
  Exam,
  useCoaches,
  useCreateExamAssessor,
  useCreateExamSignal,
  useMe,
} from '@brainstud/academy-api';
import { Button, Callout } from '@brainstud/ui';
import { Check } from '@mui/icons-material';
import { Loading } from 'Components';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import styles from './SelectExamAssessor.module.css';

type Props = {
  exam: Exam;
};

const SelectExamAssessor: FC<Props> = ({ exam }) => {
  const [t] = useTranslator();
  const examSignal = useCreateExamSignal({ exam: exam?.id });
  const createExamAssessor = useCreateExamAssessor({ exam: exam?.id });
  const [, { isLoading }] = useCoaches(undefined, { enabled: false });
  const [me] = useMe();
  const [selectedCoach] = useState<string>(me?.account?.().id || '');

  const handleApproveSample = useCallback(async () => {
    await createExamAssessor.mutateAsync({
      account: selectedCoach,
      is_primary_assessor: true,
    });
    await examSignal.mutateAsync({ signal: 'APPROVED' });
  }, [selectedCoach]);

  return isLoading ? (
    <Loading />
  ) : (
    <div className={styles.base}>
      <Callout warning>
        <div
          dangerouslySetInnerHTML={{
            __html: sanitizer(t('exams.selectAssessorCallout')),
          }}
        />
      </Callout>

      <div className={styles.form}>
        {/* TODO: uncomment when there is a solid workflow for assessor coaches within the coach panel */}
        {/* <Dropdown
          onChange={({ value }) => setSelectedCoach(value)}
          value={selectedCoach}
          label={t('exams.assessor')}
        >
          {coaches.map((coach) => (
            <DropdownOption
              key={coach.id}
              value={coach.id}
            >
              {[coach.firstName, coach.lastNameInsertion, coach.lastName].filter(Boolean).join(' ')}
            </DropdownOption>
          ))}
        </Dropdown> */}
        <Button
          type="button"
          onClick={handleApproveSample}
          loading={createExamAssessor.isLoading || examSignal.isLoading}
          className={styles.submit}
        >
          {t('exams.release')}
          <Check />
        </Button>
      </div>
    </div>
  );
};

export default SelectExamAssessor;
