import { UseQueryOptions } from 'react-query';
import { useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  AnswerUpload,
  AnswerUploadCreateInput,
  AnswerUploadFilters,
  AnswerUploadIncludes,
  AnswerUploadSorts,
  AnswerUploadUpdateInput,
  HookParams,
  UUID,
} from '../Types';

interface IAnswerUploadProps
  extends HookParams<
    typeof AnswerUploadIncludes,
    AnswerUploadFilters,
    typeof AnswerUploadSorts
  > {
  answerUpload?: UUID;
  answer?: UUID;
}

export function useAnswerUpload(
  { answerUpload, answer, ...queryParameters }: IAnswerUploadProps = {},
  settings?: UseQueryOptions<DataDocument<AnswerUpload>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { AnswerUpload: defaultAnswerUpload },
  } = context;

  const request = new ApiRequest(
    {
      enabled: !!(answerUpload || defaultAnswerUpload),
      baseName: 'v1.answers.uploads',
      baseUri: `v1/answers/${answer}/uploads`,
      uri: `/${answerUpload}`,
      queryParameters,
    },
    context
  );

  return useSingleCRUD<
    AnswerUpload,
    AnswerUploadCreateInput,
    AnswerUploadUpdateInput
  >(request, settings);
}
