import { DateTimeString } from '../Utils/DateTimeString';
import { IResource } from '../Utils/Resource';
import { UUID } from '../Utils/UUID';
import { Entity } from './Entity';
import { InvoiceProfile } from './InvoiceProfile';
import { OrderAutomationProduct } from './OrderAutomationProduct';
import { Portal } from './Portal';

export interface OrderAutomation extends IResource<'order_automations'> {
  orderType: 'continuous' | 'one off';
  billingInterval: string;
  billingDayOfMonth: number;
  subject: string;
  reference: string;
  active: boolean;
  instructionSet: string;
  createAt: DateTimeString;
  updatedAt: DateTimeString;
  startDate: DateTimeString;
  endDate: DateTimeString;

  portal?: () => Portal;
  invoiceProfile?: () => InvoiceProfile;
  entity?: () => Entity;
  orderAutomationProducts?: () => OrderAutomationProduct[];
}

export interface OrderAutomationCreate {
  subject: string;
  reference: string;
  day_of_month: number;
  interval: 'month' | 'year';
  invoice_profile: UUID;
  start_date: DateTimeString;
  end_date?: DateTimeString;
  relationships: {
    portal: UUID;
    entity: UUID;
  };
}

export interface OrderAutomationUpdate {
  /**
   * active.
   * Update the active state of an order automation.
   */
  active?: boolean;
  subject: string;
  reference: string;
  invoice_profile?: string;
  start_date: DateTimeString;
  end_date?: DateTimeString;
}

export enum OrderAutomationIncludes {
  entity,
  products,
  invoice_profile,
  order_automation_products,
  'order_automation_products.product',
}

export interface OrderAutomationFilters {
  /** filter on a specific subject */
  subject: string;

  /** filter on specific linked entities (comma seperated) */
  entities: string;

  /** filter on linked entity name */
  'entity.name': string;

  /** filter on a specific portals based on UUID */
  portal: UUID;
}

export enum OrderAutomationSort {}
