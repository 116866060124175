import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  Order,
  OrderCreate,
  OrderFilters,
  OrderIncludes,
  OrderSort,
  OrderUpdate,
  UUID,
} from '../Types';

interface IOrderProps
  extends HookParams<typeof OrderIncludes, OrderFilters, typeof OrderSort> {
  account?: UUID;
  order?: UUID;
}

export function useAccountOrder(
  { order, account, ...queryParameters }: IOrderProps = {},
  settings?: UseQueryOptions<DataDocument<Order>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { order: defaultOrder, account: defaultAccount },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(order || defaultOrder),
      baseName: 'v1.accounts.orders',
      baseUri: `/v1/accounts/${account || defaultAccount}/orders`,
      invalidate: ['order', 'orders', 'v1.orders'],
      uri: `/${order}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<Order, OrderCreate, OrderUpdate>(request, settings);
}

export function useAccountOrders(
  { account, ...queryParameters }: Omit<IOrderProps, 'order'> = {},
  settings?: UseQueryOptions<DataDocument<Order[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { account: defaultAccount },
  } = context;
  const request = new ApiRequest(
    {
      baseName: 'v1.accounts.orders',
      baseUri: `/v1/accounts/${account || defaultAccount}/orders`,
      invalidate: ['order', 'orders', 'v1.orders'],
      queryParameters,
    },
    context
  );
  return useIndexCR<Order, OrderCreate>(request, settings);
}
