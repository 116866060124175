/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { PropsWithChildren, useMemo } from 'react';
import classNames from 'classnames/bind';
import styles from './Card.module.css';

const cx = classNames.bind(styles);

const CardContext = React.createContext({});

type CardProps = {
  style?: React.CSSProperties;
  className?: string;
  focusable?: boolean;
  hoverable?: boolean;
  locked?: boolean;
  flat?: boolean;
  selectable?: boolean;
  selected?: boolean;
  deactivated?: boolean;
  disabled?: boolean;
  onClick?: () => void;
};

export const Card = ({
  children,
  style,
  className,
  focusable,
  hoverable,
  locked,
  flat,
  selectable,
  selected,
  deactivated,
  disabled,
  onClick,
}: PropsWithChildren<CardProps>) => {
  const clickable = Boolean(onClick);

  const context = useMemo(
    () => ({
      children,
      style,
      className,
      focusable,
      hoverable,
      locked,
      flat,
      selectable,
      selected,
      deactivated,
      disabled,
      onClick,
    }),
    [
      children,
      style,
      className,
      focusable,
      hoverable,
      locked,
      flat,
      selectable,
      selected,
      deactivated,
      disabled,
      onClick,
    ]
  );

  return (
    <div
      className={cx(
        'base',
        {
          clickable,
          hoverable,
          focusable: focusable || clickable,
          selectable,
          'is-locked': locked,
          'is-flat': flat,
          'is-selected': selected,
          'is-disabled': deactivated || disabled,
        },
        className
      )}
      style={style}
      onClick={onClick}
      onKeyUp={(e) => {
        if (e.keyCode === 13 && onClick) onClick();
      }}
    >
      <CardContext.Provider value={context}>
        {children}

        {locked && <div className={styles.locker}>{locked}</div>}
      </CardContext.Provider>
    </div>
  );
};

export default Card;
export { CardContext };
