import React from 'react';
import { Panel } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { BlockRenderer } from '../../../Components/BlockRenderer/BlockRenderer';
import { IPanelContainerBlock } from './Types';
import styles from './PanelContainer.module.css';

const cx = classNames.bind(styles);

export const PanelContainer = ({
  blocks,
  style,
}: Omit<IPanelContainerBlock, 'id' | 'type'>) => (
  <Panel className={cx(styles.base)} style={style}>
    {blocks.map((block) => (
      <BlockRenderer key={block.id} id={block.id} block={block} explicit />
    ))}
  </Panel>
);
