import React from 'react';
import { Block } from '@brainstud/academy-api';
import classNames from 'classnames/bind';
import { Container, Loading } from 'Components';
import { Tips } from 'Modules/action-based-learning-panel/Components';
import { BlockRenderer } from 'Modules/course-blocks';
import { useLearningObjectProvider, useLearningRouteProvider } from 'Providers';
import type { LayoutComponent } from 'Views/CollectionViews/Layouts';
import { LinearFooter } from './LinearFooter';
import styles from './LinearView.module.css';

const cx = classNames.bind(styles);

type Props = {
  blocks: Block[];
};

export const LinearView: LayoutComponent<Props> = ({ blocks }) => {
  const { pageTree } = useLearningRouteProvider();
  const { currentItem } = pageTree;
  const { variety } = useLearningObjectProvider();

  const tips = !Array.isArray(variety?.content)
    ? variety?.content?.tips
    : variety?.tips;

  return !currentItem ? (
    <Loading />
  ) : (
    <Container>
      {tips && (
        <div className={cx(styles.tipsWrapper)}>
          <Tips>{tips}</Tips>
        </div>
      )}
      <h1 className={cx(styles.title)}>{currentItem?.title}</h1>
      {blocks.map((block) => (
        <BlockRenderer key={block.id} id={currentItem?.id} block={block} />
      ))}
      <LinearFooter />
    </Container>
  );
};
