import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  NfcTag,
  NfcTagCreate,
  NfcTagFilters,
  NfcTagIncludes,
  NfcTagSorts,
  NfcTagUpdate,
  UUID,
} from '../Types';

interface INfcTagsProps
  extends HookParams<
    typeof NfcTagIncludes,
    typeof NfcTagFilters,
    typeof NfcTagSorts
  > {
  nfcTag?: UUID;
}

export function useNfcTag(
  { nfcTag, ...queryParameters }: INfcTagsProps = {},
  settings?: UseQueryOptions<DataDocument<NfcTag>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { nfcTag: defaultNfcTag },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(nfcTag || defaultNfcTag),
      baseName: 'v1.nfcTags',
      baseUri: '/v1/nfc_tags',
      uri: `/${nfcTag || defaultNfcTag}`,
      invalidate: ['nfc_tag', 'nfc_tags'],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<NfcTag, NfcTagCreate, NfcTagUpdate>(request, settings);
}

export function useNfcTags(
  { ...queryParameters }: Omit<INfcTagsProps, 'nfcTag'> = {},
  settings?: UseQueryOptions<DataDocument<NfcTag[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.nfcTags',
      baseUri: '/v1/nfc_tags',
      invalidate: ['nfc_tag', 'nfc_tags'],
      queryParameters,
    },
    context
  );
  return useIndexCR<NfcTag, NfcTagCreate>(request, settings);
}
