import { StatusColor } from '@brainstud/ui';

export type TColorScheme = {
  [key: string]: StatusColor;
};

/**
 * Default status colors
 * Use these primarily. When the design uses different colors, make a new color object for your use case.
 */
export const StatusColors: TColorScheme = {
  CONCEPT: 'info',
  COMING_SOON: 'warning',
  PUBLISHED: 'success',
  LIVE: 'success',
  ARCHIVED: 'error',
  ACTIVE: 'success',
  DISABLED: 'grey',
  PAUSED: 'warning',
  PENDING: 'yellow',
  COMPLETED: 'green',
};

export const LearningObjectStatusColors: TColorScheme = {
  OPEN: 'grey',
  CONCEPT: 'primary',
  TURNED_IN: 'yellow',
  ACCEPTED: 'green',
  REJECTED: 'red',
};

export const OrderStatusColors = {
  payment_created: 'info',
  pending: 'grey',
  fulfilled: 'success',
  paid: 'green',
  pending_payment_method: 'warning',
  withdrawn: 'error',
};

export const TransactionColors = {
  paid: 'success',
  open: 'grey',
  expired: 'grey',
  canceled: 'grey',
  blocked: 'error',
  failed: 'error',
  settled: 'green',
  chargeback: 'error',
  refunded: 'warning',
  'refund processing': 'warning',
  'partially refunded': 'warning',
  pending: 'grey',
};

export const OrderAutomationColors: TColorScheme = {
  paused: 'warning',
  pending: 'grey',
  archived: 'grey',
  active: 'success',
};
