import React, { useMemo } from 'react';
import { Notification, useNotification } from '@brainstud/academy-api';
import {
  FiberManualRecordRounded,
  QueryBuilderRounded,
} from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Link } from 'Components';
import { useAccessProvider } from 'Providers';
import { DateFormatter } from 'Utils/DateFormatHelper';
import styles from './Notifications.module.css';

const cx = classNames.bind(styles);

type TNotificationProps = {
  notification: Notification;
};

export const NotificationItem = ({ notification }: TNotificationProps) => {
  const [{ update }] = useNotification(
    { notification: notification.id },
    { enabled: false }
  );

  const { hasAccess } = useAccessProvider();

  const notificationLink = /^https?:\/\//i.test(notification.link)
    ? new URL(notification.link).pathname
    : notification.link;

  const El = useMemo(
    () => (notificationLink ? Link : 'div'),
    [notificationLink]
  );

  return (
    <El
      className={cx('item', notification.readAt ? 'read' : 'unread', {
        disabled: !hasAccess,
      })}
      onClick={() => {
        update.mutate({ readAt: new Date().toDateString() });
      }}
      href={notificationLink}
      if={hasAccess}
    >
      <FiberManualRecordRounded
        className={cx('icon', { hidden: notification.readAt })}
        fontSize="small"
      />
      <div className={cx('content')}>
        <p>{notification.message}</p>
        <small>
          <QueryBuilderRounded />{' '}
          {DateFormatter.toRelativeWithinDays(notification.receivedAt, 3)}
        </small>
      </div>
    </El>
  );
};
