import { UseQueryOptions } from 'react-query';
import { useCreate } from '../Factories';
import useSingleCRUD from '../Factories/useSingleCRUD';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  AccountProfile,
  AccountProfileCreateInput as CreateInput,
  AccountProfileFilters as Filters,
  AccountProfileIncludes as Includes,
  AccountProfileSorts as Sorts,
  AccountProfileUpdateInput as UpdateInput,
  HookParams,
  UUID,
} from '../Types';

interface IAccountProfileProps
  extends HookParams<typeof Includes, typeof Filters, typeof Sorts> {
  account?: UUID;
  accountProfile?: UUID;
}

/**
 * Every account can manage its own profile  one at a time.
 * @param account
 * @param accountProfile
 * @param queryParameters
 * @param settings
 * @returns Account
 */
export function useAccountProfile(
  { account, accountProfile, ...queryParameters }: IAccountProfileProps = {},
  settings?: UseQueryOptions<DataDocument<AccountProfile>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { account: defaultAccount },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(account || defaultAccount) && !!accountProfile,
      baseName: 'v1.accounts.profile',
      baseUri: `/v1/accounts/${account || defaultAccount}/profiles`,
      uri: `/${accountProfile}`,
      invalidate: ['v1.accounts.profile', 'v1.account'],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<AccountProfile, CreateInput, UpdateInput>(
    request,
    settings
  );
}

/**
 * Every account can create a profile
 * @param queryParameters
 * @param settings
 * @returns AccountProfile
 */
export function useAccountProfiles({
  account,
  ...queryParameters
}: IAccountProfileProps = {}) {
  const context = useApi();
  const {
    parameters: { account: defaultAccount },
  } = context;
  const request = new ApiRequest(
    {
      baseName: 'v1.accounts.profiles',
      baseUri: `/v1/accounts/${account || defaultAccount}/profiles`,
      queryParameters,
    },
    context
  );
  return useCreate<AccountProfile, CreateInput>(request);
}
