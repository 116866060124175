import { IResource } from '../Utils/Resource';
import { UUID } from '../Utils/UUID';
import { LearningObject } from './LearningObject';

export type AutomationTrigger =
  | 'assignment_approved'
  | 'xp_updated'
  | 'exam_completed';
type AutomationAction = 'enrollment_completion';

export const automationTriggerMap: Record<
  AutomationTrigger,
  AutomationTrigger
> = {
  assignment_approved: 'assignment_approved',
  xp_updated: 'xp_updated',
  exam_completed: 'exam_completed',
};

export interface Automation extends IResource<'learning_route_automations'> {
  trigger: AutomationTrigger;
  action: AutomationAction;
  triggerModels?: () => LearningObject[];
}

export type TTriggerModelTypes =
  | 'learning_route_nodes'
  | 'learning_objects'
  | 'learning_subjects';
export type TTriggerModelReference = { id: UUID; type?: TTriggerModelTypes };
export interface AutomationCreateInput {
  trigger: AutomationTrigger;
  action: AutomationAction;
  relationships?: {
    trigger_models?: Array<TTriggerModelReference>;
  };
}

export interface AutomationUpdateInput extends Partial<AutomationCreateInput> {}
export enum AutomationIncludes {}
export interface AutomationFilters {}
export enum AutomationSorts {}
