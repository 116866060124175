import React, { useCallback } from 'react';
import { UUID } from '@brainstud/academy-api';
import { Status, Td, TdCheckbox, Tr } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { useAccessProvider } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { DateFormatter } from 'Utils/DateFormatHelper';
import { AnswerRowType } from '../AnswersTable';
import styles from '../row.module.css';

const cx = classNames.bind(styles);

type Props = {
  data: AnswerRowType;
  selection: string[];
  handleToggleSelect: (ids: string[]) => void;
  handleStartReview: (ids: UUID[]) => void;
};

export const AnswerRow = ({
  data,
  selection,
  handleToggleSelect,
  handleStartReview,
}: Props) => {
  const [t] = useTranslator();
  const { hasAccess } = useAccessProvider();

  const handleCheckAnswer = useCallback(() => {
    if (hasAccess) {
      handleToggleSelect([data.id]);
    }
  }, [handleToggleSelect, hasAccess, data.id]);

  const handleReviewAnswer = useCallback(() => {
    handleStartReview([data.id]);
  }, [handleStartReview, data.id]);

  return (
    <Tr key={data.id} className={cx(styles.base, { disabled: !hasAccess })}>
      <TdCheckbox
        id={data.id}
        center
        checked={selection.includes(data.id)}
        onChange={handleCheckAnswer}
        disabled={!hasAccess}
      />
      <Td onClick={hasAccess ? handleReviewAnswer : undefined}>
        {data.assignment}
      </Td>
      <Td>{data.participant}</Td>
      <Td>{data.xp}</Td>
      <Td>
        <Status scheme="warning">
          {t(`review.statusOptions.${data.status}`)}
        </Status>
      </Td>
      <Td>{DateFormatter.toDateTime(data.date)}</Td>
    </Tr>
  );
};
