import React, { ReactNode, useCallback, useMemo, useReducer } from 'react';
import ModalActions from './ModalActions';
import { IModalContext, ModalContext } from './ModalContext';
import { ModalReducer } from './ModalReducer';

type Props = {
  children: ReactNode;
};

/**
 * ModalProvider.
 *
 * Keeps track of active modals.
 */
export const ModalProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(ModalReducer, {
    queue: [],
  });

  const { currentModal } = state;

  const handleOpenModal = useCallback<IModalContext['openModal']>(
    (Component, data = {}, priority = 0) => {
      dispatch({
        type: ModalActions.OPEN_MODAL,
        payload: {
          Component,
          data,
          priority,
        },
      });
    },
    []
  );

  const handleCloseModal = useCallback<IModalContext['closeModal']>(
    (closeAll: boolean = false) => {
      dispatch({
        type: closeAll
          ? ModalActions.CLOSE_ALL_MODALS
          : ModalActions.CLOSE_MODAL,
      });
    },
    []
  );

  const context = useMemo(
    () => ({
      Modal: currentModal,
      openModal: handleOpenModal,
      closeModal: handleCloseModal,
      modalData: currentModal?.data || {},
    }),
    [currentModal, handleOpenModal, handleCloseModal]
  );

  return (
    <ModalContext.Provider value={context}>{children}</ModalContext.Provider>
  );
};
