import { createReactReducer } from 'Utils/createReactReducer';
import ACTIONS from './BreadcrumbsActions';
import type { IBreadcrumbsState, TCrumb } from './BreadcrumbsContext';

export const BreadcrumbsInitialState: IBreadcrumbsState = {
  crumbs: {},
};

type TAddCrumbsAction = {
  type: typeof ACTIONS.ADD_CRUMBS;
  payload: Array<TCrumb>;
};

type TRemoveCrumbsAction = {
  type: typeof ACTIONS.REMOVE_CRUMBS;
  payload: Array<Pick<TCrumb, 'segment'>>;
};

type TResetAction = {
  type: typeof ACTIONS.RESET;
};

export type TBreadcrumbsActions =
  | TAddCrumbsAction
  | TRemoveCrumbsAction
  | TResetAction;

export const BreadcrumbsReducer = createReactReducer<
  IBreadcrumbsState,
  TBreadcrumbsActions
>({
  [ACTIONS.ADD_CRUMBS]: (action, state) => ({
    crumbs: {
      ...state.crumbs,
      ...action.payload.reduce(
        (output, { href, text, segment }) => ({
          ...output,
          [segment]: {
            href,
            text,
          },
        }),
        {}
      ),
    },
  }),
  [ACTIONS.REMOVE_CRUMBS]: (action, state) => ({
    crumbs: {
      ...state.crumbs,
      ...action.payload.reduce(
        (output, { segment }) => ({
          ...output,
          [segment]: undefined,
        }),
        {}
      ),
    },
  }),
  [ACTIONS.RESET]: () => BreadcrumbsInitialState,
});
