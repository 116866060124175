import { IResource } from '../Utils/Resource';
import { UUID } from '../Utils/UUID';

export interface EducationProfile extends IResource<'education_profiles'> {
  title: string;
  description: string;
}

export interface EducationProfileCreateInput {
  title: string;
  description?: string;
  portal?: UUID;
}

export type EducationProfileUpdateInput = Omit<
  EducationProfileCreateInput,
  'portal'
>;

export enum EducationProfileIncludes {
  portals,
  courses,
}

export enum EducationProfileFilters {
  title,
  portal,
}

export enum EducationProfileSorts {
  title,
}
