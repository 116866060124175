/**
 * EnvironmentActions.
 *
 * List of constants identifying the actions available in the EnvironmentReducer.
 */
export default {
  SET_ENVIRONMENT: 'SET_ENVIRONMENT',
  AUTO_SET_ENVIRONMENT: 'AUTO_SET_ENVIRONMENT',
  SET_RETURN_TO: 'SET_RETURN_TO',
} as const;
