import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  Exam,
  ExamCreateInput,
  ExamFilters,
  ExamIncludes,
  ExamSorts,
  ExamUpdateInput,
  HookParams,
  UUID,
} from '../Types';

interface IExamProps
  extends HookParams<
    typeof ExamIncludes,
    typeof ExamFilters,
    typeof ExamSorts
  > {
  exam?: UUID;
}

export function useExam(
  { exam, ...queryParameters }: IExamProps = {},
  settings?: UseQueryOptions<DataDocument<Exam>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { exam: defaultExam },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(exam || defaultExam),
      baseName: 'v1.exams',
      baseUri: '/v1/exams',
      uri: `/${exam || defaultExam}`,
      invalidate: ['exam', 'exams'],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<Exam, ExamCreateInput, ExamUpdateInput>(
    request,
    settings
  );
}

export function useExams(
  { ...queryParameters }: Omit<IExamProps, 'exam'> = {},
  settings?: UseQueryOptions<DataDocument<Exam[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.exams',
      baseUri: '/v1/exams',
      invalidate: ['exam', 'exams'],
      queryParameters,
    },
    context
  );
  return useIndexCR<Exam, ExamCreateInput>(request, settings);
}
