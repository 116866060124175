import { useMemo } from 'react';
import { AnswerGroup, AnswerStatus, UUID } from '@brainstud/academy-api';
import { ReviewItem } from '../../../Providers/AnswerReviewProvider';

/**
 * useQuizReviewables.
 *
 * Remaps an answer group to a object list containing information about
 * a set that can be reviewed.
 */

export type QuizReviewablesType = {
  id: UUID;
  resourceType: 'answer_groups';
  enrollmentId?: UUID;
  courseName?: string;
  title: string;
  progress: boolean;
  participant?: string;
  date?: string;
  status?: AnswerStatus;
  userState: undefined;
  reviewSet: ReviewItem[];
};

export function useQuizReviewables(answerGroups: AnswerGroup[]) {
  return useMemo<QuizReviewablesType[]>(
    () =>
      answerGroups.map((answerGroup) => {
        const account = answerGroup.account?.();
        const enrollment = answerGroup.enrollment?.();
        const learningSubject = answerGroup.learningSubject?.();

        const isReviewed = ['ACCEPTED', 'REJECTED'].includes(
          answerGroup.status
        );
        const reviewSet = answerGroup
          .answers?.()
          .filter((answer) => isReviewed || answer.status === 'TURNED_IN')
          .map((answer) => ({
            learningObjectId: answer.learningObject?.().id,
            enrollmentId: enrollment?.id,
          })) as ReviewItem[];

        return {
          id: answerGroup.id,
          resourceType: 'answer_groups' as const,
          enrollmentId: enrollment?.id,
          courseName: enrollment?.course?.().title,
          title: learningSubject?.title,
          progress: true,
          participant: account?.fullName,
          date: answerGroup?.completedAt,
          status: answerGroup?.status,
          userState: undefined,
          reviewSet,
        };
      }),
    [answerGroups]
  );
}
