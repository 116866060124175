import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

/**
 * useOnUnmount.
 *
 * Runs a callback method when a component is unmounting with dependency safety.
 */
export function useOnUnmount(
  callback: EffectCallback,
  dependencies: DependencyList
): void {
  const isUnmounting = useRef(false);

  useEffect(
    () => () => {
      isUnmounting.current = true;
    },
    []
  );

  useEffect(
    () => () => {
      if (isUnmounting.current) {
        callback();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    dependencies
  );
}
