import React from 'react';
import classNames from 'classnames/bind';
import { IndicatorProps } from './IndicatorProps';
import styles from './Indicator.module.css';

const cx = classNames.bind(styles);

export const Indicator = ({
  loading,
  success,
  error,
  className,
  style,
}: IndicatorProps) => (
  <div className={cx(styles.base, { success, error }, className)} style={style}>
    {success && !error && (
      <svg
        className={cx(styles.icon)}
        width="6"
        height="4"
        viewBox="0 0 6 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.22959 3.70738L5.09844 0.838525L4.75991 0.5L2.22959 3.03607L1.23122 2.0377L0.898438 2.37623L2.22959 3.70738Z"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="currentColor"
        />
      </svg>
    )}
    {error && (
      <svg
        className={cx(styles.icon)}
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill="currentColor"
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <circle cx="12" cy="19" r="2" />
        <path d="M12 3c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2s2-.9 2-2V5c0-1.1-.9-2-2-2z" />
      </svg>
    )}
    {!success && !error && (
      <svg
        className={cx(styles.icon, styles.loadingIcon, { loading })}
        width="6"
        height="2"
        viewBox="0 0 6 2"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.980469"
          y="0.520508"
          width="4.032"
          height="1.024"
          rx="0.512"
        />
      </svg>
    )}
  </div>
);
