import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  OrderAutomation,
  OrderAutomationCreate,
  OrderAutomationFilters,
  OrderAutomationIncludes,
  OrderAutomationSort,
  OrderAutomationUpdate,
  UUID,
} from '../Types';

interface IOrderAutomationsProps
  extends HookParams<
    typeof OrderAutomationIncludes,
    OrderAutomationFilters,
    typeof OrderAutomationSort
  > {
  orderAutomation?: UUID;
}

export function useOrderAutomation(
  { orderAutomation, ...queryParameters }: IOrderAutomationsProps = {},
  settings?: UseQueryOptions<DataDocument<OrderAutomation>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { order: defaultOrder },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(orderAutomation || defaultOrder),
      baseName: 'v1.order_automation',
      baseUri: '/v1/order_automations',
      invalidate: ['v1.order_automations'],
      uri: `/${orderAutomation}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    OrderAutomation,
    OrderAutomationCreate,
    OrderAutomationUpdate
  >(request, settings);
}

export function useOrderAutomations(
  { ...queryParameters }: Omit<IOrderAutomationsProps, 'orderAutomation'> = {},
  settings?: UseQueryOptions<DataDocument<OrderAutomation[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.order_automations',
      baseUri: '/v1/order_automations',
      invalidate: [],
      queryParameters,
    },
    context
  );
  return useIndexCR<OrderAutomation, OrderAutomationCreate>(request, settings);
}
