import React, { useCallback, useState } from 'react';
import {
  IColumnContainerBlock,
  IGroupContainerBlock,
} from '@brainstud/course-blocks';
import classNames from 'classnames/bind';
import { EditorButton } from '../../../Components';
import { createEmptyContainerBlock } from '../../../Support/createEmptyContainerBlock';
import { GroupForm } from '../Group';
import styles from './ColumnForm.module.css';

const cx = classNames.bind(styles);

export type ColumnFormProps = {
  data: IColumnContainerBlock;
  onChange: (data: Omit<IColumnContainerBlock, 'id' | 'type'>) => void;
};

export const ColumnForm = ({ data, onChange }: ColumnFormProps) => {
  const [blocks, setBlocks] = useState<IGroupContainerBlock[]>(
    data.blocks || []
  );
  const [removedBlock, setRemovedBlockContent] = useState<IGroupContainerBlock>(
    createEmptyContainerBlock()
  );
  const handleAddBlock = useCallback(() => {
    setBlocks([...blocks, removedBlock]);
    setRemovedBlockContent(createEmptyContainerBlock());
    onChange?.({ blocks: [...blocks, removedBlock] });
  }, [blocks, removedBlock]);

  const handleRemoveBlock = useCallback(() => {
    const prevBlocks = blocks.slice();
    const lastBlock = prevBlocks.splice(blocks.length - 1, 1);
    setRemovedBlockContent(lastBlock[0]);
    setBlocks(prevBlocks);
    onChange?.({ blocks: prevBlocks });
  }, [blocks]);

  const handleSetColumnType = useCallback(
    (count: number) => {
      if (blocks.length === 2 && count === 3) {
        handleAddBlock();
      } else if (blocks.length === 3 && count === 2) {
        handleRemoveBlock();
      }
    },
    [handleAddBlock, handleRemoveBlock, blocks]
  );

  return (
    <div className={cx(styles.base, 'editor__blocks-column')}>
      <div className={cx(styles.tabs)}>
        {blocks.map((block) => (
          <div key={block.id} className={cx(styles.tab)}>
            <GroupForm key={block.id} data={block} title={data.type} />
          </div>
        ))}
      </div>
      <div className={cx(styles.tabActions)}>
        <EditorButton
          onClick={() => handleSetColumnType(2)}
          className={cx({ isActive: blocks.length === 2 })}
        >
          <svg
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path d="M11.5,18V6c0-0.6-0.4-1-1-1H4C3.5,5,3,5.4,3,6v12c0,0.5,0.5,1,1,1h6.5C11.1,19,11.5,18.5,11.5,18z" />
            <path d="M21,18V6c0-0.6-0.5-1-1-1h-6.5c-0.6,0-1,0.4-1,1v12c0,0.5,0.4,1,1,1H20C20.6,19,21,18.5,21,18z" />
          </svg>
        </EditorButton>
        <EditorButton
          onClick={() => handleSetColumnType(3)}
          className={cx({ isActive: blocks.length === 3 })}
        >
          <svg
            height="24px"
            width="24px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path d="M14.67,6v12c0,0.55-0.45,1-1,1h-3.33c-0.55,0-1-0.45-1-1V6c0-0.55,0.45-1,1-1h3.33C14.22,5,14.67,5.45,14.67,6z M16.67,19 H20c0.55,0,1-0.45,1-1V6c0-0.55-0.45-1-1-1h-3.33c-0.55,0-1,0.45-1,1v12C15.67,18.55,16.11,19,16.67,19z M8.33,18V6 c0-0.55-0.45-1-1-1H4C3.45,5,3,5.45,3,6v12c0,0.55,0.45,1,1,1h3.33C7.89,19,8.33,18.55,8.33,18z" />
          </svg>
        </EditorButton>
      </div>
    </div>
  );
};
