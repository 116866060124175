import { useDestroy, useUpdate } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest } from '../Support';
import {
  Shareable,
  ShareableDeleteInput,
  ShareableUpdateInput,
  UUID,
} from '../Types';

interface IAccountShareableProps {
  shareable: UUID;
}

export function useDeleteShareable({
  shareable,
  ...queryParameters
}: IAccountShareableProps) {
  const context = useApi();
  const request = new ApiRequest(
    {
      enabled: false,
      baseName: 'v1.account.shareables',
      baseUri: '/v1/shareables',
      uri: `/${shareable}`,
      invalidate: [
        'v1.enrollments.learningObjects',
        'v1.enrollments.learningObject',
        'v1.expositions',
        'v1.profile',
      ],
      queryParameters,
    },
    context
  );
  return useDestroy<Shareable, ShareableDeleteInput>(request);
}

export function useUpdateShareable({
  shareable,
  ...queryParameters
}: IAccountShareableProps) {
  const context = useApi();
  const request = new ApiRequest(
    {
      enabled: false,
      baseName: 'v1.account.shareables',
      baseUri: '/v1/shareables',
      uri: `/${shareable}`,
      invalidate: [
        'v1.enrollments.learningObjects',
        'v1.enrollments.learningObject',
        'v1.expositions',
        'v1.profile',
      ],
      queryParameters,
    },
    context
  );
  return useUpdate<Shareable, ShareableUpdateInput>(request);
}
