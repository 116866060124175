import { useContext } from 'react';
import {
  IRichTextEditorProviderContext,
  RichTextEditorContext,
} from './RichTextEditorContext';

/**
 * useRichTextEditorProviderProvider.
 *
 * Gives access to a initialized editor instance from TipTap.
 *
 * @param nullable When true, this hook returns null when no context is found.
 */
export function useRichTextEditorProvider(
  nullable?: false
): IRichTextEditorProviderContext;
export function useRichTextEditorProvider(
  nullable?: true
): null | IRichTextEditorProviderContext;
export function useRichTextEditorProvider(nullable = false) {
  const context = useContext(RichTextEditorContext);

  if (!context && !nullable) {
    throw new Error(
      'Please, initiate the RichTextEditorProviderProvider first!'
    );
  }

  return context;
}
