import { IResource } from '../Utils/Resource';
import { NotificationPreference } from './NotificationPreference';

export interface NotificationType extends IResource<'notification_types'> {
  key: string;
  category: string;
  preference: () => NotificationPreference;
  meta?: {
    defaultPreference?: {
      email: boolean;
      platform: boolean;
    };
  };
}

export enum NotificationTypeIncludes {
  preference,
}

export enum NotificationTypeFilters {}

export enum NotificationTypeSorts {
  category,
}
