import { IResource } from '../Utils/Resource';
import { DateTimeString, DossierKnowledgeSkill, DossierWorkProcess } from '..';

export interface DossierCoreTask extends IResource<'dossier_core_tasks'> {
  code: string;
  title: string;
  complexity: string;
  skills: string;
  responsibility: string;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  knowledgeSkills?: () => DossierKnowledgeSkill[];
  workprocesses?: () => DossierWorkProcess[];
}

export interface DossierCoreTaskCreateInput {
  title: string;
  code: string;
  complexity?: string;
  responsibility?: string;
}

export interface DossierCoreTaskUpdateInput {}

export enum DossierCoreTaskIncludes {
  knowledge_skills,
  workprocesses,
  'workprocesses.behaviours',
  'workprocesses.learning_objects',
  'knowledge_skills.learning_objects',
}

export enum DossierCoreTaskFilters {}

export enum DossierCoreTaskSorts {}
