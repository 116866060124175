import { DateTimeString, LearningObject } from '@brainstud/academy-api';
import { IResource } from '../Utils/Resource';
import { WorkprocessBehavior } from './WorkprocessBehavior';

export interface DossierWorkProcess
  extends IResource<'dossier_work_processes'> {
  behaviour: string;
  code: string;
  description: string;
  result: string;
  title: string;
  behaviours?: () => WorkprocessBehavior[];
  learningObjects?: () => LearningObject[];
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
}

export interface DossierWorkProcessCreateInput {
  title: string;
  code: string;
  description?: string;
  result?: string;
}

export interface DossierWorkProcessUpdateInput
  extends DossierWorkProcessCreateInput {}

export enum DossierWorkProcessIncludes {
  behaviors,
}

export enum DossierWorkProcessSorts {}

export enum DossierWorkProcessFilters {}
