import React from 'react';
import { useLanguages } from '@brainstud/academy-api';
import { Button, Panel, Table, Td, Th, Tr } from '@brainstud/ui/';
import { Add } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Loading } from 'Components';
import { useModals } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { LanguageFormModal } from './LanguageFormModal';
import { LanguageRow } from './LanguageRow';
import styles from './Languages.module.css';

const cx = classNames.bind(styles);

export const LanguagesIndexView = () => {
  const [t] = useTranslator();
  const { openModal } = useModals();

  const [{ data: languages }, { isLoading }] = useLanguages();

  return (
    <>
      <header className={cx(styles.heading)}>
        <h1>{t('views.languages.title')}</h1>
        <Button type="button" onClick={() => openModal(LanguageFormModal)}>
          <Add />
          <span>{t('views.languages.add_language')}</span>
        </Button>
      </header>
      <Panel>
        {isLoading ? (
          <Loading />
        ) : (
          <Table>
            <Table.THead>
              <Tr>
                <Th>{t('views.languages.language')}</Th>
                <Th right collapse />
              </Tr>
            </Table.THead>
            <Table.TBody>
              {languages.length > 0 ? (
                languages.map((language) => (
                  <LanguageRow key={language.id} language={language} />
                ))
              ) : (
                <Tr>
                  <Td colSpan={10} center>
                    <p>{t('views.languages.no_results')}</p>
                  </Td>
                </Tr>
              )}
            </Table.TBody>
          </Table>
        )}
      </Panel>
    </>
  );
};
