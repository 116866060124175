import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import {
  DataContext,
  DataDispatcherContext,
  DataSelectionContext,
} from './DataContext';
import { DataReducer } from './DataReducer';
import { IDataItem } from './Types';

type TProps<T extends IDataItem> = {
  data?: T[];
  loading?: boolean;
};

/**
 * A DataProvider helps with filtering and sorting an array of objects. Primary use case is a large dataset that
 * is displayed in a table with multiple filter, selection and sorting options.
 */
export const DataProvider = <T extends IDataItem>({
  data: initialData = [] as T[],
  loading = false,
  children,
}: PropsWithChildren<TProps<T>>) => {
  const [{ filters, sort }, dispatch] = useReducer(DataReducer, {
    filters: {},
  });
  const data = useMemo(() => {
    let set = [...initialData];
    if (filters) {
      set = set.filter((item) =>
        Object.values(filters).every((filter) => filter(item))
      );
    }
    if (sort) {
      set = set.sort(sort.method);
      if (sort.direction === 'DESC') {
        set.reverse();
      }
    }
    return set;
  }, [initialData, filters, sort]);

  const [selection, setSelection] = useState<string[]>([]);
  const handleToggleSelect = useCallback((ids: string[]) => {
    setSelection((prevSelected) => {
      const append: string[] = [];
      ids.forEach((id) => {
        if (!prevSelected.includes(id)) {
          append.push(id);
        }
      });
      return [...prevSelected.filter((item) => !ids.includes(item)), ...append];
    });
  }, []);
  const handleToggleSelectAll = useCallback((ids: string[] = []) => {
    setSelection((prevSelected) => {
      if (prevSelected.length >= ids.length) {
        return [];
      }
      return ids;
    });
  }, []);

  useEffect(() => {
    setSelection([]);
  }, [filters]);

  const selectionState = useMemo(
    () => ({
      selection,
      handleToggleSelect,
      handleToggleSelectAll,
    }),
    [selection, handleToggleSelect, handleToggleSelectAll]
  );

  const dataState = useMemo(
    () =>
      [
        data,
        {
          isLoading: loading,
          filters,
          sort,
          dispatch,
        },
      ] as const,
    [data, loading, filters, sort]
  );

  return (
    <DataContext.Provider value={dataState}>
      <DataSelectionContext.Provider value={selectionState}>
        <DataDispatcherContext.Provider value={dispatch}>
          {children}
        </DataDispatcherContext.Provider>
      </DataSelectionContext.Provider>
    </DataContext.Provider>
  );
};
