import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@brainstud/ui';
import { ChevronLeft } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { useBreakpoints } from 'Hooks';
import { useRouter } from 'next/router';
import { useTranslator } from 'Providers/Translator';
import styles from './BackButton.module.css';

const cx = classNames.bind(styles);

type Props = {
  href?: string;
  returnCheck?: (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
  ) => boolean;
};

export default function BackButton({
  href,
  returnCheck,
}: Props): JSX.Element | null {
  const [t] = useTranslator();
  const router = useRouter();
  const [returnTo, setReturnTo] = useState<null | string>(null);
  useEffect(() => {
    if (URLSearchParams) {
      const params = new URLSearchParams(window.location.search);
      if (params.has('returnTo')) {
        setReturnTo(params.get('returnTo'));
      }
    }
  }, []);

  const handleBack = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
    ) => {
      if (!returnCheck || returnCheck(e)) {
        router.back();
      }
    },
    [router, returnCheck]
  );

  const { smDown } = useBreakpoints();

  if (smDown) return null;

  return (
    <Button
      round
      type="button"
      title={t('go_back')}
      href={returnTo || href}
      onClick={!!returnTo || href ? undefined : handleBack}
      className={cx('back-button')}
    >
      <ChevronLeft style={{ fontSize: '3rem' }} />
    </Button>
  );
}
