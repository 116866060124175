import React, { useCallback, useMemo } from 'react';
import { Answer, AnswerStatus, UUID } from '@brainstud/academy-api';
import { Callout, Indicator, Table, TdCheckbox, Th, Tr } from '@brainstud/ui';
import { Edit } from '@mui/icons-material';
import { SelectionMenu } from 'Modules/coach-panel/Components';
import { useDataSelection } from 'Modules/universal-components';
import { useRouter } from 'next/router';
import { useTranslator } from 'Providers/Translator';
import { useReviewState } from '../../useReviewState';
import { AnswerRow } from './AnswerRow';

type Props = {
  /** The answers to show the table for */
  answers: Answer[];
  /** Whether a loading indicator should be shown or not */
  loading: boolean;
  /** Sortinghandler */
  setSort: (value: string[]) => void;
};

export type AnswerRowType = {
  id: UUID;
  enrollmentId: UUID;
  learningObjectId?: UUID;
  xp?: number;
  assignment?: string;
  participant?: string;
  date: string;
  status: AnswerStatus;
};

/**
 * The AnswersTable module provides a table for the given answers which is used in the coach review page,
 * alongside tables for the exams and samplesets.
 */
export const AnswersTable = ({ answers, loading, setSort }: Props) => {
  const [t] = useTranslator();
  const router = useRouter();

  const rows = useMemo<AnswerRowType[]>(
    () =>
      answers.map((answer) => {
        const learningObject = answer.learningObject?.();
        const account = answer.account?.();
        const variety = answer.learningObjectVariety?.();
        const enrollment = answer.enrollment?.() || { id: '' };

        return {
          id: answer.id,
          enrollmentId: enrollment.id,
          learningObjectId: learningObject?.id,
          xp: variety?.points,
          assignment: learningObject?.title,
          participant: account?.fullName,
          date: answer.createdAt,
          status: answer.status,
        };
      }),
    [answers]
  );

  const { selection, handleToggleSelect } = useDataSelection();

  const [, setReviewSet] = useReviewState();
  const handleStartReview = useCallback(
    (ids: UUID[]) => {
      const objectEnrollmentIds = rows
        .filter(
          (row) =>
            ids.includes(row.id) &&
            row.learningObjectId !== undefined &&
            row.enrollmentId !== undefined
        )
        .map((row) => ({
          learningObjectId: row.learningObjectId as string,
          enrollmentId: row.enrollmentId,
        }));
      setReviewSet({
        origin: router.pathname,
        index: 0,
        reviewSet: objectEnrollmentIds,
      });
      const startWith = objectEnrollmentIds[0];
      router.push(
        `/coach/review/${startWith.enrollmentId}/${startWith.learningObjectId}/answer`
      );
    },
    [rows, setReviewSet, router]
  );

  return rows.length > 0 ? (
    <>
      <Table onSort={(sortOn) => (sortOn ? setSort([sortOn]) : setSort([]))}>
        <Table.THead>
          <Tr>
            {loading ? (
              <Th style={{ width: '2rem' }}>
                {loading && <Indicator loading />}
              </Th>
            ) : (
              <TdCheckbox id={rows.map((item) => item.id)} />
            )}
            <Th sortOn="learning_object.title">{t('review.assignment')}</Th>
            {/* FIXME add sortOn="account.fullName" when backend support this */}
            <Th>{t('review.participant')}</Th>
            <Th sortOn="points">{t('review.xp')}</Th>
            <Th>{t('review.status')}</Th>
            <Th sortOn="created_at">{t('review.date')}</Th>
          </Tr>
        </Table.THead>

        <Table.TBody>
          {rows.map((row) => (
            <AnswerRow
              data={row}
              selection={selection}
              handleToggleSelect={handleToggleSelect}
              handleStartReview={handleStartReview}
              key={row.id}
            />
          ))}
        </Table.TBody>
      </Table>

      <SelectionMenu open={selection.length > 0}>
        <button type="button" onClick={() => handleStartReview(selection)}>
          <span>{t('review.start')}</span>
          <Edit />
        </button>
      </SelectionMenu>
    </>
  ) : (
    <Callout info margin="2rem 0">
      {t('review.noReviews.assignments')}
    </Callout>
  );
};
