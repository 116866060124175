import { IResource } from '../Utils/Resource';
import { ProductSubCategory } from './ProductSubCategory';

export interface ProductCategory extends IResource<'product_categories'> {
  label: string;
  labelPlural: string;
  productSubCategories?: () => ProductSubCategory[];
}

export interface ProductCategoryCreateInput {
  label: string;
  labelPlural: string;
}

export type ProductCategoryUpdateInput = Partial<ProductCategoryCreateInput>;

export enum ProductCategoryIncludes {
  'product_sub_categories',
}

export enum ProductCategoryFilters {}
export enum ProductCategorySorts {}
