import { useEffect, useLayoutEffect, useState } from 'react';

interface ScrollPosition {
  x: number;
  y: number;
}

function getScrollPosition(element?: { current: HTMLElement }) {
  const isBrowser = typeof window !== 'undefined';
  if (!isBrowser) return { x: 0, y: 0 };

  const target = element ? element.current : document.body;
  const position = target.getBoundingClientRect();

  return { x: -position.left, y: -position.top };
}

/**
 * useScrollPosition.
 *
 * Gets the X and Y coordinates of the current scroll position with getBoundingClientRect, by default on the
 * document body.
 *
 * @param {(p: ScrollPosition) => void} callback
 * @param {(p: ScrollPosition) => boolean} condition
 * @returns {ScrollPosition}
 */
export function useScrollPosition(
  callback?: (p: ScrollPosition) => void,
  condition?: (p: ScrollPosition) => boolean
): ScrollPosition {
  const isBrowser = typeof window !== 'undefined';
  const [position, setScrollPosition] =
    useState<ScrollPosition>(getScrollPosition());

  useEffect(() => {
    let requestRunning: number | null = null;
    function handleScroll() {
      if (isBrowser && requestRunning === null) {
        requestRunning = window.requestAnimationFrame(() => {
          setScrollPosition(getScrollPosition());
          requestRunning = null;
        });
      }
    }

    if (isBrowser) {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [isBrowser]);

  useLayoutEffect(() => {
    if (callback && (!condition || (condition && condition(position)))) {
      callback(position);
    }
  }, [callback, position, condition]);

  return position;
}
