import { UseQueryOptions } from 'react-query';
import { useIndex } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import { VatRate } from '../Types';

export function useVatRatesIndex(
  settings?: UseQueryOptions<DataDocument<VatRate[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.vatRate',
      baseUri: '/v1/vat_rates',
      invalidate: ['vat_rates', 'vat_rate'],
    },
    context
  );
  return useIndex<VatRate>(request, settings);
}
