import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  Interest,
  InterestCreate,
  InterestFilters,
  InterestIncludes,
  InterestSorts,
  InterestUpdate,
  UUID,
} from '../Types';

interface IInterestProps
  extends HookParams<
    typeof InterestIncludes,
    typeof InterestFilters,
    typeof InterestSorts
  > {
  interest?: UUID;
}

export function useInterest(
  { interest, ...queryParameters }: IInterestProps = {},
  settings?: UseQueryOptions<DataDocument<Interest>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { interest: defaultInterest },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(interest || defaultInterest),
      baseName: 'v1.interests',
      baseUri: '/v1/interests',
      invalidate: ['interest', 'interests'],
      uri: `/${interest || defaultInterest}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<Interest, InterestCreate, InterestUpdate>(
    request,
    settings
  );
}

export function useInterests(
  { ...queryParameters }: Omit<IInterestProps, 'interest'> = {},
  settings?: UseQueryOptions<DataDocument<Interest[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.interests',
      baseUri: '/v1/interests',
      invalidate: ['interest', 'interests'],
      queryParameters,
    },
    context
  );
  return useIndexCR<Interest, InterestCreate>(request, settings);
}
