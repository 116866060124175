import { LearningObject, LearningObjectVariety } from '@brainstud/academy-api';
import { IResource } from '../Utils/Resource';

export interface DossierKnowledgeSkill
  extends IResource<'dossier_knowledge_skill'> {
  code: string;
  description: string;
  varieties?: () => LearningObjectVariety[];
  learningObjects?: () => LearningObject[];
}

export interface DossierKnowledgeSkillCreateInput {
  code: string;
  description?: string;
}

export interface DossierKnowledgeSkillUpdateInput
  extends DossierKnowledgeSkillCreateInput {}

export enum DossierKnowledgeSkillFilters {}

export enum DossierKnowledgeSkillSorts {}

export enum DossierKnowledgeSkillIncludes {}
