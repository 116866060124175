import {
  useAnswerGroupProvider,
  useLearningObjectProvider,
  useLearningSubjectProvider,
} from '../../../Providers';

/**
 * useIsObjectViewDataLoading.
 *
 * Simplifies loading state for specific sections in the ObjectView templates.
 */
export function useIsObjectViewDataLoading() {
  const { isLoading: isLoadingObject, learningObject } =
    useLearningObjectProvider(true) || {};
  const { isLoading: isLoadingSubject } = useLearningSubjectProvider();
  const { isLoading: isLoadingAnswerGroup } = useAnswerGroupProvider(true);

  const isLoadingQuiz =
    learningObject?.quiz && (isLoadingSubject || isLoadingAnswerGroup);

  return isLoadingObject || isLoadingQuiz
    ? isLoadingObject || isLoadingSubject || isLoadingAnswerGroup
    : undefined;
}
