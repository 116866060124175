import { useEffect } from 'react';
/**
 * AlwaysScroll
 *
 * This will apply the root styling of overflow-y: scroll,
 * so you no longer have a layout shift on the page where this is used.
 *
 */
export function useAlwaysScroll() {
  return useEffect(() => {
    const htmlElement = document?.documentElement;
    if (window && htmlElement) {
      const computedStyle = window.getComputedStyle(htmlElement);
      const baseOverflowValue = computedStyle.getPropertyValue('overflow-y');
      htmlElement.style.overflowY = 'scroll';
      // on unMount reset overFlow to previous value
      return () => {
        htmlElement.style.overflowY = baseOverflowValue;
      };
    }
  }, []);
}
