import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  Certification,
  CertificationCreateInput,
  CertificationFilters,
  CertificationIncludes,
  CertificationSorts,
  CertificationUpdateInput,
  HookParams,
  UUID,
} from '../Types';

interface ICertificationProps
  extends HookParams<
    typeof CertificationIncludes,
    CertificationFilters,
    typeof CertificationSorts
  > {
  certification?: UUID;
}

export function useCertification(
  { certification, ...queryParameters }: ICertificationProps = {},
  settings?: UseQueryOptions<DataDocument<Certification>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { certification: defaultCertification },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(certification || defaultCertification),
      baseName: 'v1.certifications',
      baseUri: '/v1/certifications',
      invalidate: ['certification', 'certifications'],
      uri: `/${certification || defaultCertification}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    Certification,
    CertificationCreateInput,
    CertificationUpdateInput
  >(request, settings);
}

export function useCertifications(
  { ...queryParameters }: Omit<ICertificationProps, 'certification'> = {},
  settings?: UseQueryOptions<DataDocument<Certification[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.certifications',
      baseUri: '/v1/certifications',
      invalidate: ['certification', 'certifications'],
      queryParameters,
    },
    context
  );
  return useIndexCR<Certification, CertificationCreateInput>(request, settings);
}
