/**
 * arrayWrap.
 *
 * If an array is provided, it will return the array. If a single item is provided, it wraps that item in an array.
 * If undefined is provided, it will return an empty array.
 */
export function arrayWrap<T>(item?: T | T[]): T[] {
  if (item === undefined) {
    return [];
  }

  return Array.isArray(item) ? item : [item];
}
