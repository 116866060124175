import { DateTimeString } from '../Utils/DateTimeString';
import { IResource } from '../Utils/Resource';
import { UUID } from '../Utils/UUID';
import { StoredFile } from './StoredFile';

export interface NfcTag extends IResource<'nfc_tags'> {
  notes: string;
  location: string;
  address: string;
  city: string;
  expiresAt: string;
  redirectTo: string;
  tagContents: string;
  storedFile?: () => StoredFile;
  /** Meta data about the users' interaction with this tag. Only available when loaded through enrollments learning object endpoint. */
  userState?: {
    /** The date the tag was scanned last on */
    lastScanned: DateTimeString;
  };
}

export interface NfcTagCreate {
  notes: string;
  location: string;
  address: string;
  city: string;
  relationships?: {
    stored_file?: UUID;
  };
}

export interface NfcTagUpdate extends NfcTagCreate {}

export enum NfcTagIncludes {
  stored_file,
}

export enum NfcTagFilters {
  learning_object,
  learning_subject,
  portal,
}

export enum NfcTagSorts {}
