import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  ExamAssessment,
  ExamAssessmentCreateInput,
  ExamAssessmentFilters,
  ExamAssessmentIncludes,
  ExamAssessmentSorts,
  ExamAssessmentUpdateInput,
  HookParams,
  UUID,
} from '../Types';

interface IProps
  extends HookParams<
    typeof ExamAssessmentIncludes,
    typeof ExamAssessmentFilters,
    typeof ExamAssessmentSorts
  > {
  exam: UUID;
  assessment: UUID;
}

export function useExamAssessment(
  { exam, assessment, ...queryParameters }: IProps,
  settings?: UseQueryOptions<DataDocument<ExamAssessment>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      enabled: !!exam,
      baseName: 'v1.exams.assessments',
      baseUri: `/v1/exams/${exam}/assessments`,
      uri: `/${assessment}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    ExamAssessment,
    ExamAssessmentCreateInput,
    ExamAssessmentUpdateInput
  >(request, settings);
}

export function useExamAssessments(
  { exam, ...queryParameters }: Omit<IProps, 'assessment'>,
  settings?: UseQueryOptions<DataDocument<ExamAssessment[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { exam: defaultExam },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(exam || defaultExam),
      baseName: 'v1.exams.assessments',
      baseUri: `/v1/exams/${exam || defaultExam}/assessments`,
      invalidate: ['assessment', 'assessments'],
      queryParameters,
    },
    context
  );
  return useIndexCR<ExamAssessment, ExamAssessmentCreateInput>(
    request,
    settings
  );
}
