import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import { TClearToast, TCreateToast, ToasterContext } from './ToasterContext';
import styles from './ToasterProvider.module.css';

type Props = {
  children: ReactNode;
};

const DEFAULT_SCHEME = 'primary';
const DEFAULT_DURATION = 5000;
const FADEOUT_ANIMATION_DURATION = 200;

type ToastSchemes = 'success' | 'warning' | 'error' | 'info' | 'primary';
type ToastProps = {
  message: string;
  scheme: ToastSchemes;
  duration?: number;
};

const cx = classNames.bind(styles);
/**
 * ToasterProvider.
 *
 * Ability to show messages as a toast floating over the page.
 */
export const ToasterProvider = ({ children }: Props) => {
  const [t] = useTranslator();
  const [toast, setToast] = useState<ToastProps>();
  const timer = useRef<number>();
  const [fadingOut, setFadingOut] = useState<boolean>();

  const createToast = useCallback<TCreateToast>(
    (message, scheme = DEFAULT_SCHEME, duration = DEFAULT_DURATION) => {
      setToast({
        message,
        scheme,
        duration,
      });
    },
    []
  );

  const clearToasts = useCallback<TClearToast>(() => {
    setToast(undefined);
  }, []);

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      setFadingOut(false);
    }

    if (toast?.duration) {
      timer.current = window.setTimeout(
        () => setFadingOut(true),
        toast.duration
      );
    }

    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, [toast]);

  useEffect(() => {
    if (fadingOut) {
      timer.current = window.setTimeout(
        () => setToast(undefined),
        FADEOUT_ANIMATION_DURATION
      );
    }

    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, [fadingOut]);

  const context = useMemo(
    () => [createToast, clearToasts] as const,
    [createToast, clearToasts]
  );

  return (
    <ToasterContext.Provider value={context}>
      {children}
      {toast && (
        <div
          className={cx(
            styles.toast,
            styles[toast.scheme],
            fadingOut ? styles['fading-out'] : ''
          )}
        >
          {t(toast.message, {}, true)}
        </div>
      )}
    </ToasterContext.Provider>
  );
};
