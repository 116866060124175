import React, { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames/bind';
import { useBlock } from 'Hooks/useBlock';
import { sanitizer } from 'Utils/Sanitizer';
import { IRichMediaBlock } from './Types';
import styles from './RichMediaBlock.module.css';

const cx = classNames.bind(styles);

export const RichMediaBlock = ({
  id,
  title,
  content = '',
}: Omit<IRichMediaBlock, 'type'>) => {
  const el = useRef<HTMLDivElement>(null);
  const { markAsRead } = useBlock({ id, read: false });
  const { ref, inView } = useInView({ threshold: 1.0, triggerOnce: true });

  const isTable = !!content?.startsWith('<table>');

  useEffect(() => {
    if (el.current) {
      const tags = el.current.getElementsByTagName('a');
      Array.from(tags).forEach((anchor) => {
        anchor.setAttribute('target', '_blank');
        anchor.setAttribute('rel', 'noopener noreferrer');
      });
    }
  }, [el]);

  useEffect(() => {
    if (inView) markAsRead();
  }, [inView]);

  return (
    <div
      className={cx(styles.base, 'block-rich_media__container')}
      style={{ overflowX: isTable ? 'auto' : 'visible' }}
    >
      {title && (
        <h3 className={cx(styles.title, 'block-rich_media__title')}>{title}</h3>
      )}
      <div
        ref={el}
        className={cx(styles.content, 'block-rich_media__content')}
        dangerouslySetInnerHTML={{
          __html: sanitizer(content.replace('colwidth=', 'width=')),
        }}
      />
      {/* Needed to check if an user has scrolled to the bottom of the page */}
      <span ref={ref} />
    </div>
  );
};
