import React, { MouseEventHandler, PropsWithChildren } from 'react';
import classNames from 'classnames/bind';
import styles from './EditorButton.module.css';

const cx = classNames.bind(styles);

type TProps = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  style?: React.CSSProperties;
};

export const EditorButton = ({
  onClick,
  className,
  style,
  children,
}: PropsWithChildren<TProps>) => (
  <button
    type="button"
    onClick={onClick}
    className={cx(styles.base, 'editor-button__base', className)}
    style={style}
  >
    {children}
  </button>
);
