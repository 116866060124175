import React, { useCallback, useMemo } from 'react';
import {
  useAnnouncements,
  useAnswerGroups,
  useAnswers,
  useExams,
  useMe,
  useNotifications,
  usePortalCourses,
  useStudents,
} from '@brainstud/academy-api';
import { useSampleSets } from '@brainstud/academy-api/Hooks/useSampleSets';
import { Button, Panel, Table } from '@brainstud/ui';
import { ReactComponent as Mailbox } from 'Assets/Icons/mailbox.svg';
import { ReactComponent as NoCourses } from 'Assets/Icons/no-courses.svg';
import { ReactComponent as NoStudents } from 'Assets/Icons/no-students.svg';
import classNames from 'classnames';
import {
  Container,
  Link,
  Loading,
  NotificationItem,
  Tabs,
  TabsItem,
} from 'Components';
import { AnnouncementsSlider } from 'Components/AnnouncementDisplay/AnnouncementsSlider';
import { CourseRow } from 'Components/CourseRow/CourseRow';
import { useLocalStorage } from 'Hooks';
import { QuizzesTable } from 'Modules/coach-panel/Views/Review/ReviewIndexView/Tables/QuizzesTable';
import { useRouter } from 'next/router';
import { useAccessProvider, useEnvironmentProvider } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { getCourseThumbnail } from 'Utils';
import { PanelHeader } from './PanelHeader/PanelHeader';
import { ReviewWork } from './ReviewWork/ReviewWork';
import { StudentActivity } from './StudentActivity/StudentActivity';
import styles from './CoachDashboardView.module.css';

const cx = classNames.bind(styles);

type TTabValues = 'exams' | 'sampleSets' | 'answers' | 'quizzes';

export const CoachDashboardView = () => {
  const [t] = useTranslator();
  const [reviewTab, setReviewTab] = useLocalStorage<TTabValues>(
    'selectedReviewTab',
    'exams'
  );
  const [answerLabelFilter] = useLocalStorage<string[]>('answerLabelFilters');

  const router = useRouter();

  const [me] = useMe();
  const { hasAccess } = useAccessProvider();

  const [{ data: courses }, { isLoading: coursesLoading }] = usePortalCourses(
    {}
  );
  const [{ data: notifications }, { isLoading: notificationsLoading }] =
    useNotifications({
      limit: 5,
    });

  const unreadNotifications = useMemo(
    () => notifications.filter((notification) => notification.readAt === null),
    [notifications]
  );

  const enrolledCourses = useMemo(
    () =>
      // sliced to six to only display the first six items on the dashboard.
      courses.filter((course) => course.enrollment?.()).slice(0, 6),
    [courses]
  );

  const [{ data: students }, { isLoading: studentsLoading }] = useStudents({
    limit: 5,
    filter: {
      archived: false,
    },
  });

  const [
    { data: answers },
    { data: answersDocument, isLoading: answersLoading },
  ] = useAnswers({
    filter: {
      status: 'TURNED_IN',
      ...(answerLabelFilter && answerLabelFilter?.length > 0
        ? { 'learning_object.metadata.labels': answerLabelFilter }
        : {}),
    },
    include: ['account'],
    limit: 5,
    sort: ['created_at'],
  });

  const [
    { data: sampleSets },
    { data: sampleSetsDocument, isLoading: sampleSetsLoading },
  ] = useSampleSets({
    filter: {
      status: 'incomplete',
    },
    include: ['enrollment', 'enrollment.course', 'enrollment.account'],
    limit: 5,
    sort: ['created_at'],
  });

  const [{ data: exams }, { data: examsDocument, isLoading: examsLoading }] =
    useExams({
      include: [
        'enrollment',
        'enrollment.course',
        'enrollment.account',
        'step_approvals',
      ],
      filter: {
        current_step: 'HANDED_IN',
        coach_account: me?.account?.().id as string,
      },
      limit: 5,
      sort: ['created_at'],
    });

  const [
    { data: answerGroups },
    { data: answerGroupsDocument, isLoading: answerGroupsLoading },
  ] = useAnswerGroups({
    include: [
      'answers',
      'answers.learning_object',
      'enrollment',
      'enrollment.course',
      'account',
      'learning_subject',
    ],
    filter: {
      status: 'TURNED_IN',
    },
    limit: 5,
  });

  const { environment } = useEnvironmentProvider();
  const [announcements, { isLoading: announcementsLoading }] = useAnnouncements(
    {
      filter: { active: true },
      sort: ['-type'],
    }
  );

  const environmentAnnouncements = announcements.filter(
    (announcement) =>
      !announcement.roles ||
      (environment && announcement.roles.includes(environment?.toString()))
  );

  const reviewWork = {
    answers,
    sampleSets,
    exams,
    quizzes: answerGroups,
  }[reviewTab as TTabValues];

  const handleTabClick = useCallback(
    (value: TTabValues) => {
      setReviewTab(value);
    },
    [setReviewTab]
  );

  const [, setSelectedCoursesTab] = useLocalStorage<'myCourses' | 'allCourses'>(
    'selectedCoursesTab',
    'myCourses'
  );

  const handleExploreCourses = () => {
    setSelectedCoursesTab('allCourses');
    router.push('/coach/courses');
  };

  const loading =
    coursesLoading ||
    notificationsLoading ||
    studentsLoading ||
    answersLoading ||
    sampleSetsLoading ||
    examsLoading ||
    answerGroupsLoading ||
    announcementsLoading;

  return (
    <Container className={cx(styles.base)}>
      {loading ? (
        <Loading />
      ) : (
        <>
          {!!announcements && (
            <AnnouncementsSlider announcements={environmentAnnouncements} />
          )}
          <div className={cx(styles.columns)}>
            <div className={cx(styles.column)} id="courses">
              <PanelHeader
                title={t('views.coach.dashboard.course_activity')}
                to="courses"
              />
              {/* My Courses */}
              <Panel className={cx(styles.panel)}>
                <div className={cx(styles.panelContainer)}>
                  {enrolledCourses.length > 0 ? (
                    <>
                      <div className={cx(styles.panelContent)}>
                        {enrolledCourses.map((course) => (
                          <Link
                            key={course.id}
                            href={
                              course.opener !== 'app'
                                ? `/coach/courses/${course.id}`
                                : `/apps/${course.id}`
                            }
                            if={hasAccess}
                          >
                            <CourseRow
                              title={course.title}
                              thumbnail={getCourseThumbnail(course)}
                              participants={course?.meta?.studentsCount}
                              sbu={course.metadata?.informationLines?.sbu}
                            />
                          </Link>
                        ))}
                      </div>
                      <div className={cx(styles.courseButtonRow)}>
                        <span>
                          {t(
                            'views.coach.dashboard.explore_courses_description'
                          )}
                        </span>
                        <Button type="button" onClick={handleExploreCourses}>
                          {t('views.coach.dashboard.explore_courses')}
                        </Button>
                      </div>
                    </>
                  ) : (
                    <div className={cx(styles.fillerDiv)}>
                      <NoCourses />
                      <div className={cx(styles.centered)}>
                        <h3>{t('views.coach.dashboard.empty.courses')}</h3>
                        <p className={cx(styles.centered)}>
                          {t('views.coach.dashboard.empty.courses_description')}
                        </p>
                      </div>
                      <Button type="button" to="courses">
                        {t('views.coach.dashboard.explore_courses')}
                      </Button>
                    </div>
                  )}
                </div>
              </Panel>
            </div>
            {/* My participants */}
            <div className={cx(styles.column)}>
              {courses.length > 0 && (
                <>
                  <PanelHeader
                    title={t('views.coach.dashboard.student_activity')}
                    to="students"
                  />
                  <Panel className={cx(styles.panel)}>
                    {students.length > 0 ? (
                      <div className={cx(styles.panelContent)}>
                        {students.map((student) => (
                          <StudentActivity key={student.id} student={student} />
                        ))}
                      </div>
                    ) : (
                      <div className={cx(styles.fillerDiv)}>
                        <NoStudents />
                        <div className={cx(styles.centered)}>
                          <h3>{t('views.coach.dashboard.empty.students')}</h3>
                          <p className={cx(styles.centered)}>
                            {t(
                              'views.coach.dashboard.empty.students_description'
                            )}
                          </p>
                        </div>
                      </div>
                    )}
                  </Panel>
                </>
              )}
              <PanelHeader
                title={t('views.coach.dashboard.notification_activity')}
                to="/notifications"
              />
              {/* Notifications */}
              <Panel className={cx(styles.panel)}>
                {notifications.length > 0 ? (
                  <>
                    {unreadNotifications.slice(0, 3).map((notification) => (
                      <NotificationItem
                        key={notification.id}
                        notification={notification}
                      />
                    ))}
                  </>
                ) : (
                  <div className={cx(styles.fillerDiv)}>
                    <Mailbox />
                    <p className={cx(styles.centered)}>
                      {t('views.coach.dashboard.empty.notifications')}
                    </p>
                  </div>
                )}
              </Panel>
            </div>
          </div>
          {/* Review work */}
          <div className={cx(styles.reviewSection)}>
            <PanelHeader
              classname={cx(styles.reviewHeader)}
              title={t('views.coach.dashboard.review_work')}
              to="review"
            />
            <div>
              <Panel className={cx(styles.panel)}>
                <div className={cx(styles.reviewTabs)}>
                  <Tabs>
                    <TabsItem
                      active={reviewTab === 'exams'}
                      onClick={() => handleTabClick('exams')}
                    >
                      {`${t('navigation.reviewTabs.exams')} (${examsDocument?.meta?.total || 0})`}
                    </TabsItem>
                    <TabsItem
                      active={reviewTab === 'sampleSets'}
                      onClick={() => handleTabClick('sampleSets')}
                    >
                      {`${t('navigation.reviewTabs.samplesets')} (${sampleSetsDocument?.meta?.total || 0})`}
                    </TabsItem>
                    <TabsItem
                      active={reviewTab === 'answers'}
                      onClick={() => handleTabClick('answers')}
                    >
                      {`${t('navigation.reviewTabs.assignments')} (${answersDocument?.meta?.total || 0})`}
                    </TabsItem>
                    {answerGroups.length > 0 && (
                      <TabsItem
                        active={reviewTab === 'quizzes'}
                        onClick={() => handleTabClick('quizzes')}
                      >
                        {`${t('navigation.reviewTabs.quizzes')} (${answerGroupsDocument?.meta?.total || 0})`}
                      </TabsItem>
                    )}
                  </Tabs>
                </div>
                {reviewWork.length === 0 && (
                  <p className={cx(styles.centered)}>
                    {t(`views.coach.dashboard.empty.${reviewTab}`)}
                  </p>
                )}
                <Table className={cx(styles.reviewTable)} quiet>
                  <Table.TBody>
                    {reviewTab === 'exams' &&
                      exams.map((exam) => (
                        <ReviewWork
                          key={exam.id}
                          studentName={exam.enrollment?.().account?.().fullName}
                          courseName={exam.enrollment?.().course?.().title}
                          status={t(`states.exam.${exam.currentStep}`)}
                          timeStamp={
                            exam
                              .stepApprovals?.()
                              .find(
                                (step) => step.step === 'student_handed_in_exam'
                              )?.approvalDate || t('date_unknown')
                          }
                          url={`/coach/students/${exam.enrollment?.().account?.().id}/enrollment/${exam.enrollment?.().id}/exam`}
                        />
                      ))}
                    {reviewTab === 'sampleSets' &&
                      sampleSets.map((sampleSet) => (
                        <ReviewWork
                          key={sampleSet.id}
                          studentName={
                            sampleSet.enrollment?.().account?.().fullName
                          }
                          courseName={sampleSet.enrollment?.().course?.().title}
                          status={t('review.statusOptions.SAMPLESET')}
                          timeStamp={sampleSet.enrollment?.().createdAt}
                          url={`/coach/students/${sampleSet.enrollment?.().account?.().id}/enrollment/${sampleSet.enrollment?.().id}/exam`}
                        />
                      ))}
                    {reviewTab === 'answers' &&
                      answers.map((answer) => (
                        <ReviewWork
                          key={answer.id}
                          studentName={answer.account?.().fullName}
                          courseName={answer.learningObject?.().title}
                          status={t(`portfolio.statusOptions.${answer.status}`)}
                          timeStamp={answer.createdAt}
                          url={`/coach/review/${answer.enrollment?.().id}/${answer.learningObject?.().id}/answer`}
                        />
                      ))}
                    {reviewTab === 'quizzes' && (
                      <QuizzesTable answerGroups={answerGroups} />
                    )}
                  </Table.TBody>
                </Table>
              </Panel>
            </div>
          </div>
        </>
      )}
    </Container>
  );
};
