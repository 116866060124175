import { TField, TGenericValueList } from '../Types';

/**
 * getFormValues.
 *
 * Returns the values of the fields provided. When a field is an array, it will combine those values to an array.
 */
export function getFormValues<
  TOutput extends TGenericValueList = TGenericValueList,
>(fields: TField[]): TOutput {
  return fields.reduce(
    (valueList, field) => ({
      ...valueList,
      ...(field.value
        ? {
            [field.name]: field.isArray
              ? [...(valueList[field.name] || []), field.value]
              : field.value,
          }
        : {}),
    }),
    {}
  ) as TOutput;
}
