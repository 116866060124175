import React, { ReactNode, useMemo } from 'react';
import useCourseInitialization from '@brainstud/action-based-learning-panel/Hooks/useCourseInitialization';
import { ProgressSection } from '@brainstud/action-based-learning-panel/Layouts';
import { CourseNavigation } from '@brainstud/action-based-learning-panel/Layouts/CourseNavigation/CourseNavigation';
import { useRouter } from 'next/router';
import { LearningRouteProvider, useBreadcrumbs } from 'Providers';
import { CourseCompletionBanner, Loading } from '../../Components';
import { useDfnTags } from '../../Hooks';
import { useHeadProvider } from '../../Providers';

type Props = {
  children?: ReactNode;
};

/**
 * CourseLayout.
 *
 * Shows the navigation section within a course
 */
export const CourseLayout = ({ children }: Props) => {
  const [{ course, enrollment }, { isLoading }] = useCourseInitialization();
  useDfnTags();

  const router = useRouter();
  const { returnTo } = router.query as { returnTo?: string };

  const returnToSplit = useMemo(
    () => returnTo?.split('/').slice(1),
    [returnTo]
  );

  const coursesUrl = returnToSplit
    ? `/${returnToSplit[0]}/${returnToSplit[1]}`
    : '/catalog';

  const courseUrl =
    returnToSplit && course ? `${coursesUrl}/${course.id}` : undefined;

  useBreadcrumbs(
    {
      courses: { href: coursesUrl },
      detail: null,
      exam: null,
      ...(course && { [course.id]: { text: course.title, href: courseUrl } }),
    },
    [course]
  );

  useHeadProvider(course?.title || 'titles.courses.main');

  return (
    <LearningRouteProvider
      enrollmentId={enrollment?.id}
      learningObjectCollectionId={enrollment?.learningObjectCollection?.().id}
    >
      <ProgressSection title={course?.title} />
      <CourseNavigation />
      {enrollment?.isCompleted && (
        <CourseCompletionBanner course={course} enrollment={enrollment} />
      )}
      {isLoading ? <Loading /> : children}
    </LearningRouteProvider>
  );
};
