import { useEffect } from 'react';
import { useBreadcrumbsProvider } from 'Providers';
import BreadcrumbsActions from './BreadcrumbsActions';
import { TCrumb } from './BreadcrumbsContext';
import { toCrumbArray } from './toCrumbData';

type TCrumbData = {
  [segment: string]: undefined | null | string | Omit<TCrumb, 'segment'>;
};

/**
 * useBreadcrumbs.
 *
 * With this hook, you can easily append data to the breadcrumbs configuration. When
 * a value is set to `null`, it will explicitly remove it. When left undefined,
 * it will expect a value in the future (i.e. loading state).
 *
 * useBreadcrumbs usage:
 *
 * ```
 *  useBreadcrumbs({
 *    [key: string]: undefined | null | string | {
 *      text?: null | string,
 *      href?: null | string,
 *    }
 *  });
 * ```
 */
export function useBreadcrumbs(
  crumbs: TCrumbData,
  deps: unknown[] = [],
  unmount: boolean = false
) {
  const { dispatch } = useBreadcrumbsProvider();

  useEffect(() => {
    const crumbList = toCrumbArray(crumbs);
    dispatch({
      type: BreadcrumbsActions.ADD_CRUMBS,
      payload: crumbList,
    });

    if (unmount) {
      return () => {
        dispatch({
          type: BreadcrumbsActions.REMOVE_CRUMBS,
          payload: crumbList,
        });
      };
    }

    // Special case where the breadcrumbs only need to be updated when provided dependencies change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);
}
