import React, { useCallback } from 'react';
import {
  ShareableCreateInput,
  useEnrollmentLearningObject,
  useShareEnrollmentLearningObject,
  UUID,
} from '@brainstud/academy-api';
import { AutocompleteInput, Button, SidebarModal, Table } from '@brainstud/ui';
import { TBody } from '@brainstud/ui/Static/Table/SubComponents/TBody';
import { Form } from '@brainstud/universal-components';
import classNames from 'classnames/bind';
import { Loading } from 'Components';
import { useShareablesWithTarget } from 'Hooks/ShareablesWithTarget/useShareablesWithTarget';
import { useShareable } from 'Hooks/useShareable';
import { useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { ShareableTableRow } from './ShareableTableRow';
import styles from './ShareModal.module.css';

const cx = classNames.bind(styles);

type TProps = {
  enrollmentId: UUID;
  learningObjectId: UUID;
  closeModal: () => void;
};

type TCreateFormInput = {
  account: string;
  rights: string;
};

export const ShareModal = ({
  enrollmentId,
  learningObjectId,
  closeModal,
}: TProps) => {
  const [t] = useTranslator();
  const [setToast] = useToaster();
  const create = useShareEnrollmentLearningObject({
    enrollment: enrollmentId,
    learningObject: learningObjectId,
  });

  const [{ data: learningObject }, { isLoading: isLoadingLearningObject }] =
    useEnrollmentLearningObject({
      enrollment: enrollmentId,
      learningObject: learningObjectId,
      include: ['shareables'],
    });

  const onSubmit = useCallback(
    (values: ShareableCreateInput) =>
      create.mutateAsync(values, {
        onSuccess: () => {
          setToast(t('views.portfolio.share.success'), 'success');
        },
        onError: () => {
          setToast(t('views.portfolio.share.failure'), 'error', 10000);
        },
      }),
    [create, setToast, t]
  );

  const shareables = useShareablesWithTarget('accounts', learningObject);
  const { handleSubmit, handleChangeShare, handleSearch } = useShareable({
    onSubmit,
    shareables,
  });

  return (
    <SidebarModal
      onClickOutside
      onClose={closeModal}
      className={cx(styles.base)}
    >
      <SidebarModal.Header>
        <h2>{t('views.portfolio.share_my_answers')}</h2>
      </SidebarModal.Header>

      <Form<TCreateFormInput> onSubmit={handleSubmit} language="nl">
        <div className={styles.buttons}>
          <AutocompleteInput
            label={t('views.portfolio.share_with_user')}
            name="account"
            data={handleSearch}
            onChange={handleChangeShare}
            placeholder={t('name')}
            className={cx(styles.autocomplete)}
            multiple
          >
            {({ onReset, values }) => (
              <Button
                outline
                loading={create.isLoading}
                type="button"
                disabled={!Object.keys(values).length}
                onClick={async () => {
                  const result = await handleSubmit();
                  if (result) onReset();
                }}
              >
                {t('views.portfolio.share.action')}
              </Button>
            )}
          </AutocompleteInput>
        </div>
      </Form>

      {isLoadingLearningObject ? (
        <Loading />
      ) : (
        <>
          {shareables.length === 0 ? (
            <p>{t('views.portfolio.share.empty')}</p>
          ) : (
            <div className={styles.shares}>
              <strong>{t('views.portfolio.list_of_users')}</strong>
              <Table className={cx(styles.table)}>
                <TBody>
                  {shareables.map((shareable) => (
                    <ShareableTableRow
                      id={shareable.id}
                      key={shareable.id}
                      name={shareable.target?.().fullName}
                      access={shareable.access}
                    />
                  ))}
                </TBody>
              </Table>
            </div>
          )}
        </>
      )}
    </SidebarModal>
  );
};
