import React, { ChangeEvent, MouseEventHandler, useCallback } from 'react';
import { LearningObject } from '@brainstud/academy-api';
import classNames from 'classnames/bind';
import { COACH_ROLES } from 'Config/roles';
import {
  filterObjectBadges,
  filterObjectTags,
  useHasAnyRole,
  useObjectTags,
} from 'Hooks';
import { ConditionsModal } from 'Modals';
import { CardTag } from 'Modules/action-based-learning-panel/Components';
import VideoLink from 'Modules/action-based-learning-panel/Utils/VideoLink';
import { useRouter } from 'next/router';
import { useEnvironmentProvider, useModals } from 'Providers';
import { Checkmark } from '../../Checkmark';
import { LearningCard } from '../LearningCard';
import { TProgressState } from '../LearningCard/LearningCard';
import { ReactComponent as FallbackImage } from './fallback-image.svg';
import { ObjectCardIconTags } from './IconTags';
import styles from './ObjectCard.module.css';

const cx = classNames.bind(styles);

export type ObjectCardProps = {
  object: LearningObject;
  parentHasCondition?: boolean;
  /** Override the redirectUrl */
  targetUrl?: string;
  /** Sets status of checkmark on the card */
  checked?: boolean;
  /** Handler for change event on the checkbox */
  onCheck?: (event: ChangeEvent<HTMLInputElement>) => void;
};

export type TStatus =
  | 'OPEN'
  | 'CONCEPT'
  | 'TURNED_IN'
  | 'ACCEPTED'
  | 'REJECTED';

/**
 * ObjectCard.
 *
 * Show learningObjects in card form
 */
export const ObjectCard = ({
  object,
  parentHasCondition,
  targetUrl,
  onCheck,
  checked,
}: ObjectCardProps) => {
  const router = useRouter();
  const isEditPreview = router.pathname.includes('/edit');
  const { courseId } = router.query as {
    collectionId: string;
    courseId: string;
  };
  const { openModal } = useModals();
  const isCoach = useHasAnyRole(COACH_ROLES);
  const isTheory =
    object.baseType === 'theory' || object.type?.label === 'theory';
  const nfcCondition = object
    .conditions?.()
    .find((condition) => condition.type === 'NFC_TAG_SCANNED');
  const isNfcTagRestricted =
    nfcCondition || object.prerequisites?.nfcTagsScanned;
  const tags = useObjectTags(object);

  const poster =
    object?.metadata?.media?.posterUrl ||
    // @ts-ignore Check if media .poster or .posterUrl is correct
    object?.metadata?.media?.poster ||
    object?.poster?.src;
  const link = new VideoLink(poster);
  const { isStudentEnvironment, isCoachEnvironment, isAdminEnvironment } =
    useEnvironmentProvider();
  const hasCorrectEnvironment =
    isCoachEnvironment || isAdminEnvironment || isStudentEnvironment;

  // FIXME: This needs to be more specified, since it will also apply to nfc || include NFC check
  // FIXME: isStudentEnvironment should be replaced with object.meta.isLocked but isn't filled using conditions anymore?
  const hasUnCompletedConditions =
    (!!object.conditions || isNfcTagRestricted) &&
    hasCorrectEnvironment &&
    object.meta?.isLocked;

  const isLocked =
    hasUnCompletedConditions &&
    object.conditions?.()[0]?.type !== 'NFC_TAG_SCANNED';
  const redirectUrl =
    parentHasCondition && isStudentEnvironment && !isCoach
      ? undefined
      : targetUrl ||
        `/courses/${courseId || object.course?.().id}/detail/${object.id}`;

  const handleClick = useCallback<MouseEventHandler>(
    (e) => {
      e.preventDefault();

      if (hasUnCompletedConditions) {
        openModal(ConditionsModal, {
          object,
          redirectUrl:
            isAdminEnvironment ||
            isCoachEnvironment ||
            (isStudentEnvironment && isCoach)
              ? redirectUrl
              : undefined,
        });
      } else if (redirectUrl) {
        router.push(redirectUrl, undefined, { shallow: true });
      }
      // eslint-disable-next-line max-len
    },
    [
      hasUnCompletedConditions,
      isAdminEnvironment,
      isCoach,
      isCoachEnvironment,
      isStudentEnvironment,
      object,
      openModal,
      redirectUrl,
      router,
    ]
  );

  /**
   * Get the status state and label based on the LearningObject progress status
   */
  const getStatus = useCallback(
    (status: TStatus): TProgressState => {
      switch (status) {
        case 'ACCEPTED':
          return {
            state: 'SUCCESS',
            label: `components.card_object.${isTheory ? 'read' : 'accepted'}_on`,
          };
        case 'TURNED_IN':
          return {
            state: 'WARNING',
            label: 'components.card_object.turned_in_on',
          };
        case 'REJECTED':
          return {
            state: 'ERROR',
            label: 'components.card_object.rejected_on',
          };
        default:
          if (isNfcTagRestricted) {
            return {
              state: 'NFC_RESTRICTED',
              label: 'components.card_object.nfc_restricted_on',
            };
          }

          if (isLocked) {
            return {
              state: 'LOCKED',
              label: 'components.card_object.locked_on',
            };
          }

          return { state: 'NONE', label: '' };
      }
    },
    [isLocked, isNfcTagRestricted, isTheory]
  );

  return (
    <button
      type="button"
      onClick={isEditPreview ? undefined : handleClick}
      className={cx(styles.base)}
    >
      <LearningCard
        title={object.title}
        status={getStatus(
          object.progress?.status || object.lastAnswer?.().status || 'OPEN'
        )}
        leftOverlay={
          <ObjectCardIconTags
            tags={tags.filter(filterObjectBadges)}
            isLocked={isLocked}
          />
        }
        posterUrl={link.getThumbnail()}
        fallbackImage={<FallbackImage />}
        subheader={tags.filter(filterObjectTags).map((tag) => (
          <CardTag key={tag.name} type={tag.name} value={tag?.value} />
        ))}
        rightOverlay={
          onCheck && (
            <div className={styles.checkbox}>
              <Checkmark onChange={onCheck} checked={checked} />
            </div>
          )
        }
      />
    </button>
  );
};
