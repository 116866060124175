import React, { PropsWithChildren } from 'react';
import classNames from 'classnames/bind';
import { useInitialization } from 'Hooks';
import { useModalOnHash } from 'Hooks/useModalOnHash';
import { FeedbackModal } from 'Modals/FeedbackModal/FeedbackModal';
import { useRouter } from 'next/router';
import { ErrorBoundary } from 'Providers/ErrorDataProvider/ErrorBoundary';
import { Header } from '../Header';
import styles from './Base.module.css';

const classes = classNames.bind(styles);

type Props = {
  /** Removes header from rendering */
  headless?: boolean;
  /** Sets background to dark */
  dark?: boolean;
};

/**
 * Base.
 * Renders the default Header, Menu and Footer for a page.
 */
export const Base = ({
  headless,
  dark,
  children,
}: PropsWithChildren<Props>) => {
  useInitialization();
  useModalOnHash('give-feedback', FeedbackModal);
  const router = useRouter();

  return (
    <div
      className={classes(styles.base, {
        [styles.dark]: dark,
        'is-dark': dark,
        [styles.light]: !dark,
        headless,
      })}
    >
      {!headless && <Header />}
      <ErrorBoundary container key={router.pathname}>
        {children}
      </ErrorBoundary>
    </div>
  );
};
export default Base;
