import React, { ReactNode, useCallback, useState } from 'react';
import classNames from 'classnames/bind';
import { IHTMLBaseProperties } from '../../Types/ElementTypes';
import { BannerProps } from './BannerProps';
import styles from './Banner.module.css';

const cx = classNames.bind(styles);

/**
 * Shows a banner across the screen
 */
export const Banner = ({
  primary,
  info,
  warning,
  success,
  error,
  quiet,
  onClose,
  closeable,
  style,
  className,
  children,
}: BannerProps) => {
  const [closed, setClosed] = useState(false);

  const handleClose = useCallback(() => {
    setClosed(true);
    onClose?.();
  }, [onClose]);

  const isCloseable = !!onClose || closeable;

  return (
    <div
      style={style}
      className={cx(
        styles.base,
        {
          'is-primary': primary,
          'is-info': info,
          'is-success': success,
          'is-warning': warning,
          'is-error': error,
          'is-quiet': quiet,
          'is-closed': closed,
        },
        className
      )}
    >
      <div className={cx(styles.inner, 'ui-banner__inner', { closeable })}>
        {children}
      </div>

      {isCloseable && (
        <button
          type="button"
          className={cx(styles['close-button'], 'ui-banner__close')}
          onClick={handleClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <path
              fill="currentColor"
              d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
            />
          </svg>
        </button>
      )}
    </div>
  );
};

Banner.Actions = ({
  children,
  className,
  style,
}: IHTMLBaseProperties & { children: ReactNode }) => (
  <div className={cx(styles.actions, className)} style={style}>
    {children}
  </div>
);
