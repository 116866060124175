import { useContext } from 'react';
import {
  ISystemEventContext,
  SystemEventContext,
  TEventsObject,
} from './SystemEventContext';

/**
 * useSystemEventProvider.
 *
 * The system event handler is perfect in situations where you want to handle logic on unmount, as the system
 * event handler does not unmount, so the unmount event is still handled properly.
 */
export function useSystemEventProvider<TEvents extends TEventsObject>(
  prefix?: string
): ISystemEventContext<TEvents> {
  const context = useContext(SystemEventContext);

  if (!context) {
    throw new Error('Please, initiate the SystemEventProvider first!');
  }

  return prefix
    ? ({
        emit: (name, ...data) =>
          context.emit(`${prefix}.${String(name)}`, ...data),
        subscribe: (name, handler) =>
          context.subscribe(`${prefix}.${String(name)}`, handler),
      } as ISystemEventContext<TEvents>)
    : context;
}
