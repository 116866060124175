import React, { useMemo, useState } from 'react';
import { Accordion, Button, Checkbox, Dropdown, Search } from '@brainstud/ui';
import { Grid } from '@mui/material';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import { useCourseList } from '../../../../Providers';
import { TSortAttribute } from '../../../../Providers/CourseListProvider/CourseListContext';
import styles from './CoursesFiltersContent.module.css';

const cx = classNames.bind(styles);

/**
 * CoursesFiltersContent.
 * Contains the filter options for courses.
 */
const CoursesFiltersContent = () => {
  const [t] = useTranslator();
  const {
    catalog,
    filters,
    sortAttribute,
    toggleProfile,
    toggleSbuFilter,
    setSortAttribute,
    educationProfiles,
  } = useCourseList();

  const sbuValues = [
    ...new Set(
      catalog?.map((course) => course?.metadata?.informationLines?.sbu)
    ),
  ];
  const [showMore, setShowMore] = useState(false);
  const [profileSearchQuery, setProfileSearchQuery] = useState('');

  // profiles filter search and show more show less
  const profiles = useMemo(() => {
    if (profileSearchQuery.length > 1) {
      return educationProfiles.filter((profile) =>
        profile.title.toLowerCase().includes(profileSearchQuery.toLowerCase())
      );
    }
    return showMore ? educationProfiles : educationProfiles.slice(0, 6);
  }, [educationProfiles, profileSearchQuery, showMore]);

  return (
    <div className={cx(styles.base)}>
      <Dropdown
        name="sort_attribute"
        label={t('components.catalog.sort_on')}
        placeholder={t('components.catalog.sort_on_placeholder')}
        value={sortAttribute}
        onChange={(value) => setSortAttribute(value as TSortAttribute)}
        rules="required"
        className={cx(styles.dropdown)}
      >
        <Dropdown.Option value="createdAt">
          {t('components.catalog.sort_createdAt')}
        </Dropdown.Option>
        <Dropdown.Option value="title">
          {t('components.catalog.sort_title')}
        </Dropdown.Option>
        <Dropdown.Option value="sbu">
          {t('components.catalog.sbu')}
        </Dropdown.Option>
      </Dropdown>
      <Accordion
        quiet
        title={t('components.catalog.filter_panel.profiles')}
        className={cx(styles.accordion)}
      >
        <div className={cx(styles['filter-content'])}>
          <Search
            placeholder={t('components.catalog.filter_panel.search_profiles')}
            onChange={(e) => setProfileSearchQuery(e.target.value)}
            className={cx(styles.search)}
            block
          />
          <Grid>
            {profiles.map((profile) => (
              <Grid item key={profile.id} xs={12}>
                <Checkbox
                  quiet
                  id={profile.title}
                  className={cx(styles.profile)}
                  checked={filters?.profiles.includes(profile.id)}
                  onChange={() => toggleProfile(profile.id)}
                  name={profile.title}
                >
                  {profile.title}
                </Checkbox>
              </Grid>
            ))}
            {profiles.length > 5 && (
              <Button
                link
                onClick={() => setShowMore((prevShowMore) => !prevShowMore)}
                className={cx(styles['link-button'])}
              >
                {t(
                  `components.catalog.filter_panel.show.${!showMore ? 'more' : 'less'}`
                )}
              </Button>
            )}
          </Grid>
        </div>
      </Accordion>
      <Accordion
        quiet
        title={t('components.catalog.filter_panel.other')}
        className={cx(styles.accordion)}
      >
        <div className={cx(styles['filter-content'])}>
          {sbuValues.map((sbuValue, idx) => (
            <Checkbox
              quiet
              id={idx}
              name="sbu"
              key={sbuValue || 'none'}
              className={cx(styles.sbuInput)}
              checked={filters.sbu.includes(sbuValue || 'none')}
              defaultValue={sbuValue || 'none'}
              onChange={() => toggleSbuFilter(sbuValue || 'none')}
            >
              {`${sbuValue || t('components.catalog.sbu_none')}`}
            </Checkbox>
          ))}
        </div>
      </Accordion>
    </div>
  );
};

export default CoursesFiltersContent;
