import { useContext } from 'react';
import { FormContext } from './FormContext';
import { IFormContext, TInputList } from './Types';

type TOutput<TInput extends TInputList> = {
  setValueById: IFormContext<TInput>['update'];
  values: IFormContext<TInput>['values'];
  fields: IFormContext<TInput>['fields'];
  subscribe: IFormContext<TInput>['subscribe'];
  save: IFormContext<TInput>['save'];
  reset: IFormContext<TInput>['reset'];
  disabled: boolean;
  validate: IFormContext<TInput>['handleValidation'];
  submit: IFormContext<TInput>['handleSubmit'];
  validateFields: IFormContext<TInput>['handleValidateFields'];
};

/**
 * This hook gives access to the methods and content of the FormProvider
 *
 * @param nullable {true} When set to true, it will simply return `null` if FormProvider is not initialized.
 *
 * @throws {Error} When the hook is used without being wrapped in a FormProvider
 */
export function useForm<TFormInput extends TInputList = TInputList>(
  nullable?: false
): TOutput<TFormInput>;
export function useForm<TFormInput extends TInputList = TInputList>(
  nullable?: true
): Partial<TOutput<TFormInput>>;
export function useForm<TFormInput extends TInputList = TInputList>(
  nullable = false
) {
  const context = useContext<null | IFormContext<TFormInput>>(FormContext);
  if (!context) {
    if (!nullable) {
      throw new Error('The useForm hook is used without the FormProvider.');
    }
    return {};
  }

  const {
    update,
    save,
    fields,
    reset,
    disabled,
    values,
    subscribe,
    handleValidation,
    handleSubmit,
    handleValidateFields,
  } = context;

  return {
    setValueById: update,
    values,
    fields,
    subscribe,
    save,
    reset,
    disabled:
      disabled || (fields.length > 0 && fields.every((field) => field.locked)),
    validate: handleValidation,
    submit: handleSubmit,
    validateFields: handleValidateFields,
  } as TOutput<TFormInput>;
}
