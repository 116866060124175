import { UseMutationOptions } from 'react-query';
import { useCreate } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  EnrollmentParameter,
  EnrollmentParameterCreateInput,
  UUID,
} from '../Types';

interface IEnrollmentParameterProps {
  enrollment?: UUID;
}

export function useCreateEnrollmentParameters(
  { enrollment, ...queryParameters }: IEnrollmentParameterProps = {},
  settings?: UseMutationOptions<
    DataDocument<EnrollmentParameter>,
    ErrorDocument,
    EnrollmentParameterCreateInput
  >
) {
  const context = useApi();
  const {
    parameters: { enrollment: defaultEnrollment },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(enrollment || defaultEnrollment),
      baseName: 'v1.enrollmentParameter',
      baseUri: `/v1/enrollments/${enrollment || defaultEnrollment}/parameters`,
      invalidate: ['parameters', 'enrollments', 'enrollment', 'v1.enrollments'],
      queryParameters,
    },
    context
  );
  return useCreate<EnrollmentParameter, EnrollmentParameterCreateInput>(
    request,
    settings
  );
}
