import React, { ReactNode } from 'react';
import classNames from 'classnames/bind';
import styles from './Tag.module.css';

const cx = classNames.bind(styles);

type Props = {
  scheme?:
    | 'TURNED_IN'
    | 'warning'
    | 'REJECTED'
    | 'error'
    | 'APPROVED'
    | 'ACCEPTED'
    | 'success'
    | string;
  children: ReactNode;
  className?: string;
};

/**
 * Shows a small badge indicating a warning, error or success state based on the scheme provided
 * @param className
 * @param children
 * @param scheme
 */
export const Tag = ({ className = '', children, scheme }: Props) => (
  <div
    className={cx(styles.base, className, {
      warning: ['TURNED_IN', 'warning'].includes(scheme || ''),
      error: ['REJECTED', 'error'].includes(scheme || ''),
      success: ['APPROVED', 'ACCEPTED', 'success'].includes(scheme || ''),
    })}
  >
    {children}
  </div>
);
