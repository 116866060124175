import React, { useEffect, useMemo, useState } from 'react';
import { ICardsWidgetQuestion } from '@brainstud/course-blocks';
import { Input } from '@brainstud/ui';
import { useDebounce } from '@brainstud/universal-components';
import { Grid } from '@mui/material';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import styles from './CardsWidget.module.css';

const cx = classNames.bind(styles);

export type CardsWidgetFormProps = {
  data: ICardsWidgetQuestion;
  onChange: (data: Omit<ICardsWidgetQuestion, 'id' | 'type'>) => void;
};

export const CardsWidgetForm = ({ data, onChange }: CardsWidgetFormProps) => {
  const [t] = useTranslator();
  const [question, setQuestion] = useState(data.question);
  const [label, setLabel] = useState(data.label);
  const [color, setColor] = useState(data.cardStyles?.backgroundColor);

  const debouncedData = useDebounce(
    useMemo(
      () => ({
        question,
        label,
        cardStyles: { backgroundColor: color },
        styles: { backgroundColor: color },
      }),
      [question, label, color]
    ),
    100
  );

  useEffect(() => {
    onChange(debouncedData);
  }, [debouncedData]);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={5}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label>
            {t('course-editor.content_editor.blocks.cards_widget.question')}
          </label>
        </Grid>
        <Grid item xs={4}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label>
            {t('course-editor.content_editor.blocks.cards_widget.add_label')}
          </label>
        </Grid>
        <Grid item xs={3}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label>
            {t('course-editor.content_editor.blocks.cards_widget.color')}
          </label>
        </Grid>

        <Grid item xs={5}>
          <Input
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
        </Grid>

        <Grid item xs={4}>
          <Input value={label} onChange={(e) => setLabel(e.target.value)} />
        </Grid>

        <Grid item xs={3}>
          <Input
            className={cx(styles.color)}
            type="color"
            value={color}
            onChange={(e) => setColor(e.target.value)}
          />
        </Grid>
      </Grid>
    </div>
  );
};
