import { createContext, FunctionComponent } from 'react';

export type TModal = {
  /** The component to render as a modal */
  Component: FunctionComponent<any>;
  /** The priority the modal should get over other modals */
  priority: number;
  /** The data that should be provided to the modal as props */
  data?: {
    [key: string]: any;
  };
  /** number of modals to close */
  closeAll?: boolean;
};

type CloseModalFunction = {
  (): void;
  (closeAll?: boolean): void;
};

export interface IModalContext {
  /** The modal that is currently active */
  Modal?: TModal;
  /** Method to activate a modal based on a component */
  openModal: (
    Component: TModal['Component'],
    data?: TModal['data'],
    priority?: TModal['priority']
  ) => void;
  /** Method to deactivate the current modal and open the next modal in the queue or close all modals passing `CloseModal: true` */
  closeModal: CloseModalFunction;
  modalData?: TModal['data'];
}

export const ModalContext = createContext<null | IModalContext>(null);
