import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AnswerFeedback,
  AnswerFeedbackStatus,
  useAnswerFeedback,
  useAnswerGroup,
  useApi,
} from '@brainstud/academy-api';
import { Button, FileCard, Radio, Status, UploadBox } from '@brainstud/ui';
import { Form, TFormProps } from '@brainstud/universal-components';
import {
  Add,
  ArrowForward,
  Check,
  ExpandLess,
  ExpandMore,
  Save,
  Undo,
} from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Container } from 'Components';
import { useAnswerGroupProvider } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import { RichTextEditor } from '../../../Components';
import { useAnswerReview } from '../../../Providers';
import styles from './AnswerReviewDrawer.module.css';

const cx = classNames.bind(styles);

export type TAnswerReviewItem = Omit<
  AnswerFeedback,
  'resourceType' | 'id' | 'createdAt' | 'updatedAt'
> &
  Partial<Pick<AnswerFeedback, 'id' | 'createdAt' | 'updatedAt'>>;

type TDefault = {
  feedback: string;
  status: AnswerFeedbackStatus;
};
type TDefaultResponse = ReturnType<
  ReturnType<typeof useAnswerFeedback>[0]['create']['mutateAsync']
>;

type Props<
  T extends {} = TDefault,
  OnFeedbackReturnType extends ReturnType<
    Exclude<TFormProps['onSubmit'], undefined>
  > = TDefaultResponse,
> = {
  title?: string;
  onFeedbackSubmit?: (feedback: T, files: string[]) => OnFeedbackReturnType;
  baseUri?: string;
  feedback?: TAnswerReviewItem[];
};

export function AnswerReviewDrawer<
  FormOutput extends { [key: string]: string } = TDefault,
  FormResponse extends ReturnType<
    Exclude<TFormProps['onSubmit'], undefined>
  > = TDefaultResponse,
>({
  title,
  onFeedbackSubmit,
  baseUri,
  feedback,
}: Props<FormOutput, FormResponse>) {
  const [t, { lc }] = useTranslator();
  const { baseUrl, headers: apiHeaders } = useApi();
  const answerReviewContext = useAnswerReview();
  const {
    index,
    answer,
    answerId,
    reviewSet,
    answers,
    collapsed,
    setCollapsed,
    isLoading,
    nextItem,
    origin,
  } = answerReviewContext || {};
  const [redo, setRedo] = useState(false);
  const [files, setFiles] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const isLatestAnswer = answers?.[0]?.id === answerId;
  const isLimitedFeedbackSet = !!feedback;

  useEffect(() => {
    setRedo(false);
  }, [answerId]);

  const feedbackIsRelationship = typeof answer?.feedback === 'function';
  const feedbackSet = useMemo(
    () =>
      feedback || (feedbackIsRelationship ? answer?.feedback?.() || [] : []),
    [answer, feedback, feedbackIsRelationship]
  );

  const isPreviousAnswer = !isLatestAnswer && !!answer?.status;
  const hasFeedback = isLimitedFeedbackSet
    ? feedbackSet.length > 0
    : (feedbackSet.length > 0 && !redo) ||
      (isLatestAnswer && answer?.status !== 'TURNED_IN') ||
      isPreviousAnswer;
  const lastFeedback =
    hasFeedback || redo ? feedbackSet[feedbackSet.length - 1] : undefined;
  const currentStatus =
    lastFeedback?.givenStatus ||
    (!isLimitedFeedbackSet ? answer?.status : 'TURNED_IN');

  const [{ data: latestFeedbackCoach, create }] = useAnswerFeedback({
    answer: answerId,
    answerFeedback: lastFeedback?.id,
  });

  const latestFeedbackFiles = useMemo(
    () => latestFeedbackCoach?.files?.() || [],
    [latestFeedbackCoach]
  );
  const hasWrittenFeedback = (lastFeedback?.givenFeedback || '').trim() !== '';

  const [givenFeedback, setGivenFeedback] = useState(
    lastFeedback?.givenFeedback
  );

  const isFeedbackFormVisible =
    (redo || !lastFeedback) &&
    isLatestAnswer &&
    (redo || currentStatus === 'TURNED_IN');

  type Handler = Exclude<TFormProps<FormOutput>['onSubmit'], undefined>;
  const handleFeedbackSubmission = useCallback<Handler>(
    (data) => {
      if (onFeedbackSubmit) {
        return onFeedbackSubmit({ ...data, feedback: givenFeedback }, files);
      }
      return create.mutateAsync({
        feedback: givenFeedback || '',
        status: (data.status as AnswerFeedbackStatus) || answer?.status,
        relationships: {
          files,
        },
      });
    },
    [onFeedbackSubmit, create, givenFeedback, answer?.status, files]
  );

  const handleReview = useCallback(
    async (data: FormOutput) => {
      setLoading(true);
      const response = await handleFeedbackSubmission(data);
      setGivenFeedback(undefined);
      setRedo(false);
      setLoading(false);
      return response;
    },
    [handleFeedbackSubmission]
  );

  const { currentAnswerGroup } = useAnswerGroupProvider(true) || {};
  const [hasSeenAnswerGroup, setHasSeenAnswerGroup] = useState(false);
  const [{ createOrUpdate }] = useAnswerGroup(
    {
      answerGroup: currentAnswerGroup?.id,
    },
    { enabled: false }
  );
  useEffect(() => {
    if (
      !hasSeenAnswerGroup &&
      currentAnswerGroup
        ?.answers?.()
        .every((item) => item.status !== 'TURNED_IN')
    ) {
      setHasSeenAnswerGroup(true);
      createOrUpdate.mutateAsync({
        coach_viewed: true,
      });
    }
  }, [createOrUpdate, currentAnswerGroup, hasSeenAnswerGroup]);

  const hasNeededContext =
    typeof index !== 'undefined' && reviewSet && setCollapsed;

  return isLoading || !hasNeededContext ? null : (
    <div className={cx(styles.base, { collapsed })}>
      <Form<FormOutput> onSubmit={handleReview}>
        {({ values }) => (
          <Container>
            <header>
              <h3>{`${title || t('review.assess')} (${index + 1}/${reviewSet.length})`}</h3>
              <Button
                type="button"
                quiet
                small
                onClick={() => setCollapsed((val) => !val)}
              >
                {collapsed ? (
                  <ExpandLess fontSize="large" />
                ) : (
                  <ExpandMore fontSize="large" />
                )}
              </Button>
            </header>
            {!collapsed && (
              <>
                <div className={styles.form}>
                  {isFeedbackFormVisible ? (
                    <>
                      <div className={styles['assessment-radio-buttons']}>
                        <Radio
                          id="reject_score"
                          name="status"
                          label={t('review.deny')}
                          defaultValue="REJECTED"
                          className={styles.rejected}
                        />
                        <Radio
                          id="accept_score"
                          name="status"
                          defaultValue="ACCEPTED"
                          label={t('review.approve')}
                          className={styles.approve}
                        />
                      </div>
                      {!currentAnswerGroup && (
                        <div>
                          <RichTextEditor
                            label={t('review.optionalFeedback')}
                            preset="minimal"
                            placeholder={t('review.feedbackPlaceholder')}
                            defaultValue={givenFeedback}
                            onChange={setGivenFeedback}
                            className={cx(styles.textarea)}
                          />
                          {!onFeedbackSubmit && (
                            <UploadBox
                              label=""
                              url={`${baseUrl}/v1/services/temporary_file_upload`}
                              headers={{
                                ...apiHeaders,
                                'Content-Type': undefined,
                              }}
                              paramName="file"
                              quiet
                              className={styles.upload}
                              onAfterFileUpload={(file, response) => {
                                if (response?.data?.id) {
                                  setFiles((prevFiles) => [
                                    ...prevFiles,
                                    response?.data?.id as string,
                                  ]);
                                }
                              }}
                            >
                              <Button type="button" quiet small>
                                <Add />
                                <span>{t('review.upload_file')}</span>
                              </Button>
                            </UploadBox>
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    // Feedback is given & no redo
                    <>
                      <div>
                        {currentStatus ? (
                          <Status
                            scheme={
                              [currentStatus].includes('ACCEPTED')
                                ? 'green'
                                : 'red'
                            }
                          >
                            {t(`states.${lc(currentStatus)}`)}
                          </Status>
                        ) : (
                          <span className={cx(styles['no-rating-given'])}>
                            {t('review.no_rating_given')}
                          </span>
                        )}
                      </div>
                      <div>
                        <strong>{t('review.given_feedback')}</strong>
                        <div
                          className={cx({
                            noWrittenFeedback: !hasWrittenFeedback,
                          })}
                        >
                          {hasWrittenFeedback ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: sanitizer(lastFeedback?.givenFeedback),
                              }}
                            />
                          ) : (
                            <p>{t('review.no_written_feedback')}</p>
                          )}
                        </div>
                        {latestFeedbackFiles?.map((file) => (
                          <FileCard
                            href={file.downloadUrl}
                            key={file.id}
                            thumbnail={file.downloadUrl}
                          >
                            {file.originalFileName}
                          </FileCard>
                        ))}
                      </div>
                    </>
                  )}
                </div>
                <footer>
                  {isFeedbackFormVisible ? (
                    <>
                      {redo && (
                        <Button
                          type="button"
                          quiet
                          onClick={() => setRedo(false)}
                        >
                          {t('cancel')}
                        </Button>
                      )}
                      <Button
                        type="submit"
                        loading={loading}
                        disabled={values.status === undefined}
                      >
                        <span>{t('review.save')}</span>
                        <Save />
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        type="button"
                        onClick={() => setRedo(true)}
                        outline
                        disabled={!isLatestAnswer}
                      >
                        <Undo />
                        <span>{t('review.redo')}</span>
                      </Button>
                      {nextItem ? (
                        <Button
                          to={`${baseUri}/${nextItem.enrollmentId}/${nextItem.learningObjectId}/answer`}
                          type="button"
                        >
                          <span>{t('review.next')}</span>
                          <ArrowForward />
                        </Button>
                      ) : (
                        <Button to={origin} type="button">
                          <span>{t('review.finish')}</span>
                          <Check />
                        </Button>
                      )}
                    </>
                  )}
                </footer>
              </>
            )}
          </Container>
        )}
      </Form>
    </div>
  );
}
