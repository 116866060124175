import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Button } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { Loading } from 'Components';
import { useRouter } from 'next/router';
import { useToaster, useTwoFactor } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { ReactComponent as ContentCopy } from 'public/media/icons/contenCopy.svg';
import styles from './TwoFactorAuthRecoveryView.module.css';

const cx = classNames.bind(styles);

export const TwoFactorAuthRecoveryView = () => {
  const [t] = useTranslator();
  const [setToast] = useToaster();
  const [disabled, setDisabled] = useState(true);
  const router = useRouter();
  const { returnTo: providedReturnTo } = router.query as {
    returnTo: string;
  };

  const ref = useRef<HTMLDivElement>(null);
  const {
    recoveryCodes,
    loading,
    isAccountPage,
    isRegenerating,
    setRecoveryCodes,
  } = useTwoFactor();

  const copyRecoveryCodes = useCallback(() => {
    if (recoveryCodes) {
      const availableRecoveryCodes = recoveryCodes.join('  ');
      navigator.clipboard.writeText(availableRecoveryCodes);
      setToast(t('security.two_factor.recovery.copy'), 'success');
    }
  }, [recoveryCodes, setToast, t]);

  useEffect(() => {
    const recoveryCodeElement = ref.current;
    recoveryCodeElement?.addEventListener('click', copyRecoveryCodes);
    return () =>
      recoveryCodeElement?.removeEventListener('click', copyRecoveryCodes);
  }, [copyRecoveryCodes, ref]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisabled(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  const returnTo = useMemo(
    () => (isAccountPage ? '/account/security' : providedReturnTo),
    [isAccountPage, providedReturnTo]
  );

  const handleSubmit = useCallback(() => {
    if (!isAccountPage) {
      setRecoveryCodes(undefined);
    } else {
      router.push(returnTo);
    }
  }, [isAccountPage, returnTo, router, setRecoveryCodes]);

  return loading ? (
    <Loading />
  ) : (
    <>
      <h3 className={cx(styles.title)}>
        {t(
          `security.two_factor.recovery.${isRegenerating ? 'regenerate' : 'title'}`
        )}
      </h3>
      <div className={cx(styles.wrapper, { 'tfa-required': !isAccountPage })}>
        <div className={cx(styles.info)}>
          {isRegenerating && (
            <strong>{t('security.two_factor.recovery.title')}</strong>
          )}
          <span>{t('security.two_factor.recovery.info')}</span>
        </div>
        <div className={cx(styles['recover-codes-wrapper'])}>
          <div className={cx(styles.recoveryCodes)} ref={ref}>
            {recoveryCodes?.map((recoveryCode) => (
              <span key={recoveryCode}>{recoveryCode}</span>
            ))}
          </div>
          <Button
            quiet
            className={cx(styles.copy)}
            disabled={!recoveryCodes}
            onClick={copyRecoveryCodes}
          >
            <ContentCopy />
          </Button>
        </div>
      </div>
      <div className={cx(styles['button-wrapper'])}>
        <Button
          disabled={disabled}
          className={cx(styles.next)}
          onClick={handleSubmit}
        >
          <span>
            {isAccountPage
              ? t(`security.two_factor.back_to_security`)
              : t('next')}
          </span>
        </Button>
      </div>
    </>
  );
};
