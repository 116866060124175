import React, { useMemo } from 'react';
import { LearningObject } from '@brainstud/academy-api';
import { isObject } from '@brainstud/course-editor/Support/is';
import { Badge, Button, ContentModal } from '@brainstud/ui';
import { ArrowForward } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { ProgressStepper } from 'Modules/blended-learning-catalog-panel/Components';
import { ILearningRouteNodeWithResources, useModals } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { NfcRequired } from './NfcRequired';
import styles from './ConditionsModal.module.css';

const cx = classNames.bind(styles);

type Props = {
  object: LearningObject | ILearningRouteNodeWithResources;
  redirectUrl?: string;
  setIsUnlocked?: (unlocked: boolean) => void;
};

/**
 * ConditionsModal.
 *
 * In this modal the current condition(s) of a LearningObject or LearningRouteNode are shown.
 */
export const ConditionsModal = ({
  object,
  redirectUrl,
  setIsUnlocked,
}: Props) => {
  const [t] = useTranslator();
  const { closeModal } = useModals();

  const nfcCondition = object
    .conditions?.()
    .find((condition) => condition.type === 'NFC_TAG_SCANNED');
  const hasNfcTagRestriction = useMemo(() => {
    if (object?.resourceType === 'learning_objects') {
      return (
        // object.conditions?.().some((condition) => condition.type === 'NFC_TAG_SCANNED')
        nfcCondition || object.prerequisites?.nfcTagsScanned
        // && object.userState?.isLocked // FIXME Backend need to set this to true when nfc is added to existing object
      );
    }
  }, [nfcCondition, object?.resourceType]);

  /*
   *  FIXME when conditions.meta is filled, hasConditionsAndNfc and CurrentStep can also be made working,
   *  FIXME then you can check if the Object.conditions are satisfied,
   *  FIXME and check if there is also an hasNfcTagRestriction if so show the hasNfcTagRestriction content,
   */
  const hasConditionsAndNfc =
    object?.conditions?.() &&
    object?.conditions?.().length > 1 &&
    hasNfcTagRestriction;

  return (
    <ContentModal onClose={closeModal} className={cx(styles.base)}>
      <ContentModal.Header>
        <h2>{t('modals.conditions_modal.title', { title: object?.title })}</h2>
      </ContentModal.Header>
      {hasConditionsAndNfc && (
        <ProgressStepper step={!hasNfcTagRestriction ? 1 : 2} length={2} />
      )}
      {!hasNfcTagRestriction ? (
        <div className={cx(styles['condition-wrapper'])}>
          <span>{t('modals.conditions_modal.learning_object')}</span>
          <div className={cx(styles.conditions)}>
            {object?.conditions &&
              object.conditions?.().map((condition, index) => {
                const conditionTarget = condition?.target && condition.target();
                return (
                  <div className={cx(styles.condition)} key={condition.id}>
                    <Badge circle color="primary">
                      {index + 1}
                    </Badge>
                    <span>
                      {t(
                        `modals.conditions_modal.${conditionTarget?.resourceType}`
                      )}
                      <strong className={cx(styles['target-title'])}>
                        {conditionTarget?.title}
                      </strong>
                      {t(`modals.conditions_modal.${condition.type}`)}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        <>{isObject(object) && <NfcRequired object={object} />}</>
      )}
      {(redirectUrl || setIsUnlocked) && (
        <Button
          outline
          to={redirectUrl}
          onClick={() =>
            setIsUnlocked ? (setIsUnlocked(true), closeModal()) : undefined
          }
          className={cx(styles['coach-button'])}
        >
          {t('modals.conditions_modal.is_coach')}
          <ArrowForward />
        </Button>
      )}
      <ContentModal.Footer>
        <Button quiet onClick={() => closeModal()}>
          {t('modals.close')}
        </Button>
      </ContentModal.Footer>
    </ContentModal>
  );
};
