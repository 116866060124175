type ValueOf<T> = T[keyof T];

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

type TMetaObject = { [key: string]: string | string[] };
export type TMetaItem = string | TMetaObject;

type TGenericIncludeList = { [key: string]: unknown };
type TGenericFiltersList = { [key: string]: any };
type TGenericSorts = string[];

/**
 * Exported to be able to use the sort type in views.
 */
export type TSortType<TSort> =
  | keyof TSort
  | ValueOf<{
      [Field in keyof TSort]: Field extends string ? `-${Field}` : never;
    }>;

export interface HookParams<
  TIncludes = TGenericIncludeList,
  TFilters = TGenericFiltersList,
  TSorts = TGenericSorts,
  TMeta extends TMetaItem | TMetaItem[] = {},
> {
  /** Adds includes to the request as `?include=string,string,...` */
  include?: (keyof TIncludes)[];
  /**
   *  Adds filter to the request as `?filter[field]=string|boolean`
   *  Currently not possible to add number as it is an enum. When all refactored to interface, then filter can support
   *  numbers as well.
   * */
  filter?: {
    [Field in keyof TFilters]?: TFilters[Field] extends number
      ? Field extends string
        ? string
        : never
      : TFilters[Field];
  };
  /**
   *  Adds fields to the request as `?fields[field]=string`
   * */
  fields?: {
    [key: string]: string[];
  };
  /**
   * Adds sorting to the request as `?sort=string|-string`
   */
  sort?: TSortType<TSorts>[];
  /**
   * Adds pagination to the request as `?page=number`
   */
  page?: number;
  /**
   * Adds limit for items per page `?limit=number`
   */
  limit?: number;
  /**
   * Request extra metadata
   */
  meta?: TMeta;
  /**
   * Adds token to the request as query parameter `?token=string`
   */
  token?: string;
  /**
   * Set the endpoint to use the inset shared in the api URL, e.g: api/v#/shared/expositions
   */
  shared?: boolean;
  /**
   * Change the invalidation parameters for this request
   */
  invalidate?: string[] | null;
  /**
   * Adds a locale to the requests as query parameter `?locale=string`
   */
  locale?: string;
  /**
   * Add all translations the backend currently has
   */
  requestAllTranslations?: boolean;
  /**
   * Includes storedFileCount in the meta of expositions.
   */
  showStoredFilesCount?: boolean;
}
