import { useCallback, useEffect, useState } from 'react';

type TTranslationObject = {
  [key: string]: string | string[] | TTranslationObject;
};

/**
 * This hook provides functionality to load translation files based on a given locale.
 * @param locale The locale to fetch the translations for
 * @returns The loaded translations
 */
export const useTranslationLoader = (locale?: string) => {
  const [translations, setTranslations] = useState<TTranslationObject>();
  const loadTranslationDynamically = useCallback(
    async (loadLocale: string) =>
      setTranslations(
        // Use the relative syntax to keep Storybook from crashing
        (await import(`../../Translations/${loadLocale}`)).default
      ),
    []
  );

  useEffect(() => {
    if (locale) loadTranslationDynamically(locale);
  }, [loadTranslationDynamically, locale]);

  return translations;
};
