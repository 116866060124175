import React from 'react';
import { Callout, Radio } from '@brainstud/ui';
import { useForm } from '@brainstud/universal-components';
import { useBlock } from 'Hooks/useBlock';
import { sanitizer } from 'Utils/Sanitizer';
import { ISingleChoiceBlock } from './Types';
import styles from './SingleChoiceQuestion.module.css';

export const SingleChoiceQuestion = ({
  id,
  question,
  options,
  feedback,
}: Omit<ISingleChoiceBlock, 'type'>) => {
  const { values } = useForm();
  const { isValid, isValidated } = useBlock({
    id,
    touched: !!values[id],
    closed: true,
  });

  return (
    <div className={styles.base}>
      <p className={styles.question}>{question}</p>

      {options?.map((option, index) => (
        <Radio
          id={index}
          key={option.id}
          name={id}
          prefix={`${String.fromCharCode(65 + index)}.`}
          defaultValue={option.id}
          rules={option.correct ? 'checked' : 'unchecked'}
        >
          <div>
            {option.title}
            {option.image && <img src={option.image} alt="" />}
          </div>
        </Radio>
      ))}

      {feedback && (
        <Callout
          correct={isValid}
          incorrect={!isValid}
          hidden={
            !isValid && !feedback.incorrect
              ? true
              : !!(isValid && !feedback.correct) || !isValidated
          }
          className={styles.feedback}
          dangerouslySetInnerHTML={{
            __html: sanitizer(
              isValid ? feedback?.correct : feedback?.incorrect
            ),
          }}
        />
      )}
    </div>
  );
};
