import { useCallback, useMemo } from 'react';
import {
  Course,
  useCoaches,
  useCreateCoachMandateTransfer,
  useEnrollment,
  useMe,
  UUID,
} from '@brainstud/academy-api';
import { useRouter } from 'next/router';
import { useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';

type UseUnenrollProps = {
  newCoachId: UUID | undefined;
  course?: Course | undefined;
  redirectOnSuccess?: boolean;
  closeModal?: () => void;
};

export const NO_COACH = 'no_coach';

/**
 * Hook to unenroll properly with a modal and toast message
 */
export function useUnenroll({
  newCoachId,
  course,
  redirectOnSuccess = false,
  closeModal,
}: UseUnenrollProps) {
  const [t] = useTranslator();
  const [me] = useMe();
  const [setToast] = useToaster();
  const [{ data: coaches }, { isLoading: coachesLoading }] = useCoaches({
    ...(course
      ? {
          filter: {
            course: course?.id,
          },
        }
      : {}),
  });
  const transfer = useCreateCoachMandateTransfer();
  const router = useRouter();

  const accountId = me?.account?.().id;
  const enrollment = useMemo(() => course?.enrollment?.(), [course]);

  const [{ destroy }] = useEnrollment(
    { enrollment: enrollment?.id },
    { enabled: false }
  );
  const loading = transfer.isLoading || destroy.isLoading;

  const handleUnenroll = useCallback(async () => {
    if (!newCoachId) {
      // Rejecting with an error refreshes the loading state
      return Promise.reject(new Error());
    }

    if (newCoachId !== NO_COACH && accountId) {
      await transfer.mutateAsync({
        fromAccount: accountId,
        toAccount: newCoachId,
        course: course?.id,
      });
    }

    await destroy.mutateAsync(undefined, {
      onSettled: () => {
        closeModal?.();
      },
      onSuccess: () => {
        if (redirectOnSuccess) {
          router.replace('/coach/courses');
        }
        setToast(t('components.unenroll.confirmation.success'), 'success');
      },
      onError: () => {
        setToast(t('components.unenroll.confirmation.error'), 'error');
      },
    });
  }, [destroy, transfer, setToast, course, newCoachId, closeModal, accountId]);

  return {
    loading,
    handleUnenroll,
    coaches: coaches.filter((item) => item.id !== accountId),
    coachesLoading,
  };
}
