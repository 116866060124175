import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  Answer,
  AnswerCreateInput,
  AnswerFilters,
  AnswerIncludes,
  AnswerSorts,
  AnswerUpdateInput,
  HookParams,
  UUID,
} from '../Types';

interface IAnswerProps
  extends HookParams<typeof AnswerIncludes, AnswerFilters, typeof AnswerSorts> {
  answer?: UUID;
}

export function useAnswer(
  { answer, ...queryParameters }: IAnswerProps = {},
  settings?: UseQueryOptions<DataDocument<Answer>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { answer: defaultAnswer },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(answer || defaultAnswer),
      baseName: 'v1.answers',
      baseUri: '/v1/answers',
      invalidate: [
        'answer',
        'answers',
        'learning_objects',
        'learning_object',
        'v1.enrollments.learningObjects',
        'v1.enrollments.learningObjectCollections',
      ],
      uri: `/${answer}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<Answer, AnswerCreateInput, AnswerUpdateInput>(
    request,
    settings
  );
}

export function useAnswers(
  { ...queryParameters }: Omit<IAnswerProps, 'answer'> = {},
  settings?: UseQueryOptions<DataDocument<Answer[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.answers',
      invalidate: [
        'answer',
        'answers',
        'learning_objects',
        'learning_object',
        'v1.enrollments.learningObjects',
        'v1.enrollments.learningObjectCollections',
        'v1.enrollments',
        'enrollments',
        'enrollment',
      ],
      baseUri: '/v1/answers',
      queryParameters,
    },
    context
  );
  return useIndexCR<Answer, AnswerCreateInput>(request, settings);
}
