import { UseQueryOptions } from 'react-query';
import { useIndex } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  Entity,
  EntityFilters,
  EntityIncludes,
  EntitySorts,
  HookParams,
  UUID,
} from '../Types';

interface IEntityProps
  extends HookParams<typeof EntityIncludes, EntityFilters, typeof EntitySorts> {
  portal?: UUID;
}

type SimpleEntity = Pick<
  Entity,
  'id' | 'resourceType' | 'name' | 'externalIdentifier'
>;

export function usePortalEntitiesIndex(
  { portal, ...queryParameters }: Omit<IEntityProps, 'entity'> = {},
  settings?: UseQueryOptions<DataDocument<SimpleEntity[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { portal: defaultPortal },
  } = context;
  const request = new ApiRequest(
    {
      baseName: 'v1.portals.entities',
      baseUri: `/v1/portals/${portal || defaultPortal}/entities`,
      queryParameters,
    },
    context
  );
  return useIndex<SimpleEntity>(request, settings);
}
