import { IResource } from '../Utils/Resource';

export interface Skill extends IResource<'skills'> {
  title: string;
}

export interface SkillUpdate {
  title: string;
}

export interface SkillCreate extends SkillUpdate {}

export enum SkillIncludes {}

export enum SkillFilters {
  title,
  portal,
}

export enum SkillSorts {
  type,
}
