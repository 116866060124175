import { ComponentType } from 'react';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import { Panel } from '../Panel';
import styles from './EmptyResourcePanel.module.css';

const cx = classNames.bind(styles);

type TProps = {
  icon?: ComponentType;
  title: string;
  subtitle?: string;
};

/**
 * Generic component to
 */
export const EmptyResourcePanel = ({ icon: Icon, title, subtitle }: TProps) => {
  const [t] = useTranslator();

  return (
    <Panel className={cx(styles.base)}>
      {Icon && <Icon />}
      <p className={cx(styles.title)}>{t(title)}</p>
      {subtitle && <p className={cx(styles.subtitle)}>{t(subtitle)}</p>}
    </Panel>
  );
};
