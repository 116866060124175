import React, { ReactNode } from 'react';
import { SubscriptionBanner } from 'Components';
import { AccessProvider, useHeadProvider } from 'Providers';
import { ErrorBoundary } from 'Providers/ErrorDataProvider/ErrorBoundary';

type TProps = {
  title?: string;
  children?: ReactNode;
};

/**
 * CoachMainLayout.
 *
 * Is shown on the index pages of the coach environment.
 */
export const CoachMainLayout = ({ title, children }: TProps) => {
  useHeadProvider(title);
  return (
    <div style={{ paddingBottom: '8rem' }}>
      <AccessProvider>
        <SubscriptionBanner />
        <ErrorBoundary container>{children}</ErrorBoundary>
      </AccessProvider>
    </div>
  );
};
