import React from 'react';
import classNames from 'classnames/bind';
import { Container, NodeCard } from 'Components';
import { useUserData } from 'Hooks';
import {
  ILearningRouteNodeWithResources,
  useLearningRouteProvider,
} from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import { LayoutComponent } from 'Views/CollectionViews/Layouts';
import styles from './RouteChapterCardsView.module.css';

const cx = classNames.bind(styles);

type Props = {
  nodes: ILearningRouteNodeWithResources[];
};

export const RouteChapterCardsView: LayoutComponent<Props> = ({ nodes }) => {
  const [t, { currentDayPeriod }] = useTranslator();
  const [{ firstName }] = useUserData();
  const { learningObjectCollection } = useLearningRouteProvider();

  const title = t(`views.collection.chapter.greetings.${currentDayPeriod}`, {
    name: firstName ? ` ${firstName}` : '',
  });
  const description =
    learningObjectCollection?.description ||
    learningObjectCollection?.introduction;

  return (
    <Container className={styles.base} size="large">
      <div className={styles.welcome}>
        <h2>{title}</h2>
        {description && (
          <div
            className={styles.subtext}
            dangerouslySetInnerHTML={{ __html: sanitizer(description) }}
          />
        )}
      </div>

      <div className={cx(styles.grid)}>
        {nodes.map((node: ILearningRouteNodeWithResources) => (
          <NodeCard key={node.id} learningRouteNode={node} />
        ))}
      </div>
    </Container>
  );
};
