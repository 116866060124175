import { IResource } from '../Utils/Resource';

export type SlaType = 'product_top_up' | 'fixed_sla_fee';

export interface SlaProduct extends IResource<'sla_products'> {
  name: string;
  description: string;
  type: SlaType;
}

export interface SlaProductCreateInput {
  name: string;
  description: string;
  type: SlaType;
}

export type SlaProductUpdateInput = Partial<SlaProductCreateInput>;

export enum SlaProductIncludes {}
export enum SlaProductsFilters {}
export enum SlaProductsSort {}
