import { useCallback } from 'react';
import { TCommentableType } from '@brainstud/academy-api';
import { useComments } from '@brainstud/academy-api/Hooks/useComments';
import { Button } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { RichTextEditor } from 'Components';
import { Form } from 'Modules/universal-components';
import { useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import styles from './CommentForm.module.css';

const cx = classNames.bind(styles);

type TCreateComment = {
  content: string;
};

type TProps = {
  id?: string;
  type: TCommentableType;
  onSuccess?: () => void;
};

export const CommentForm = ({ id = '', type, onSuccess }: TProps) => {
  const [setToast] = useToaster();
  const [{ create: createComment }] = useComments({}, { enabled: false });
  const [t] = useTranslator();

  const handleCreateComment = useCallback(
    ({ content }: TCreateComment) =>
      createComment.mutateAsync(
        {
          content,
          relationships: {
            commentable: {
              id,
              type,
            },
          },
        },
        {
          onSuccess,
          onError: () => setToast(t('error'), 'error'),
        }
      ),
    [createComment, id, type, setToast, onSuccess, t]
  );

  return id && type ? (
    <div className={cx(styles.base, 'comment-form__base')}>
      <Form<TCreateComment> onSubmit={handleCreateComment}>
        <RichTextEditor
          name="content"
          id="assignment_comment"
          preset="minimal"
          placeholder={t('components.activity_feed.form.comment_placeholder')}
        />
        <Button type="submit" small loading={createComment.isLoading}>
          {t('components.activity_feed.comment')}
        </Button>
      </Form>
    </div>
  ) : null;
};
