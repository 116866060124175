import React, { useCallback } from 'react';
import { Exposition } from '@brainstud/academy-api';
import { Button, Panel } from '@brainstud/ui';
import { Email, Phone, School, Share } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Avatar, Container, FlexContainer, Link, Loading } from 'Components';
import { FullMediaCard } from 'Modules/blended-learning-catalog-panel/Components';
import { useRouter } from 'next/router';
import { useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { AccountProfilePermissionsResourceView } from '../Account';
import { useProfileProvider } from './ProfileLayout';
import styles from './Profile.module.css';

const cx = classNames.bind(styles);

export const ProfileResourceView = () => {
  const [t] = useTranslator();
  const { pathname } = useRouter();
  const [setToast] = useToaster();

  const { profile, isLoading, isMine } = useProfileProvider();
  const shareables =
    profile
      ?.account?.()
      .shareables?.()
      .filter((shareable) => shareable.shared?.()) || [];

  // Sharing method to copy URL of the profile.
  const handleShare = useCallback(() => {
    navigator.clipboard.writeText(
      `${window.location.origin}/profiles/${profile?.slug || profile?.id}`
    );
    setToast('views.account.link_copied', 'success');
  }, [setToast, profile?.id, profile?.slug]);

  return (
    <Container className={cx(styles.base)}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Panel
            pad
            className={cx(styles.background)}
            style={{
              ...(profile?.bannerUrl
                ? { backgroundImage: `url(${profile?.bannerUrl})` }
                : {}),
            }}
          >
            <Avatar
              outline
              size="large"
              url={profile?.avatarUrl}
              name={profile?.account?.().fullName}
              className={cx(styles.avatar, {
                'has-avatar': !!profile?.avatarUrl,
              })}
            />

            <h1>{profile?.account?.().fullName}</h1>

            {(profile?.account?.().emailAddress ||
              profile?.account?.().phoneNumber) && (
              <FlexContainer row alignItems="center" gapSize="small">
                {profile?.account?.().emailAddress && (
                  <>
                    <Email className={cx(styles.icon)} fontSize="small" />
                    <span>{profile?.account?.().emailAddress}</span>
                  </>
                )}

                {profile?.account?.().phoneNumber && (
                  <>
                    <Phone className={cx(styles.icon)} fontSize="small" />
                    <span>{profile?.account?.().phoneNumber}</span>
                  </>
                )}
              </FlexContainer>
            )}
            <FlexContainer row alignItems="center" gapSize="small">
              {profile
                ?.account?.()
                .entities?.()
                .map((entity) => (
                  <React.Fragment key={entity.id}>
                    <School className={cx(styles.icon)} fontSize="small" />
                    <span>{entity.name}</span>
                  </React.Fragment>
                ))}
              {profile?.account?.().education && (
                <span>{profile?.account?.().education}</span>
              )}
              {profile?.account?.().sector && (
                <span>{profile?.account?.().sector}</span>
              )}
            </FlexContainer>
            {isMine && (
              <FlexContainer row justifyContent="flex-start">
                <div>
                  <Button
                    className={cx(styles.profile)}
                    outline
                    href="/account/details"
                  >
                    {t('views.account.profile.edit_profile')}
                  </Button>
                </div>
                <Button
                  outline
                  className={cx(styles.share)}
                  onClick={handleShare}
                >
                  <Share />
                </Button>
              </FlexContainer>
            )}
          </Panel>

          {pathname === '/account/profile' && !profile && (
            <Container gap>
              <AccountProfilePermissionsResourceView />
            </Container>
          )}

          {shareables.length > 0 && profile && (
            <div>
              <h2>{t('expositions')}</h2>
              <FlexContainer row wrap className={cx(styles.expositions)}>
                {shareables.map((shareable) => {
                  const exposition = shareable.shared?.();
                  return (
                    <div key={shareable.id}>
                      <Link
                        href={`/profiles/${profile.slug || profile.id}/expositions/${exposition.id}`}
                        className={cx(styles['no-underline'])}
                      >
                        <FullMediaCard
                          title={exposition.title}
                          subtitle={
                            <>
                              <strong>
                                {exposition.meta?.learningObjectCount}
                              </strong>
                              {` ${t('assignments')} `}
                              {(exposition.meta?.storedFilesCount ||
                                exposition.meta?.storedFilesCount === 0) && (
                                <>
                                  <strong>
                                    {exposition.meta?.storedFilesCount}
                                  </strong>
                                  {` ${t('files')}`}
                                </>
                              )}
                            </>
                          }
                          background={(exposition as Exposition).image}
                        />
                      </Link>
                    </div>
                  );
                })}
              </FlexContainer>
            </div>
          )}

          {!!profile?.biography && profile.biography.trim() !== '' && (
            <div>
              <h2>{t('biography')}</h2>
              <Panel pad>{profile?.biography}</Panel>
            </div>
          )}

          {(profile?.account?.().skills?.() ||
            profile?.account?.().interests?.()) && (
            <div>
              <h2>{t('skills_and_interests')}</h2>
              <Panel pad>
                <FlexContainer gapSize="large">
                  {profile?.account?.().skills?.() && (
                    <FlexContainer gapSize="small">
                      <strong>{`${t('skills')}:`}</strong>
                      <FlexContainer row wrap gapSize="small">
                        {profile
                          ?.account?.()
                          .skills?.()
                          .map((skill) => (
                            <span key={skill.id} className={cx(styles.tag)}>
                              {skill.title}
                            </span>
                          ))}
                      </FlexContainer>
                    </FlexContainer>
                  )}
                  {profile?.account?.().interests?.() && (
                    <FlexContainer gapSize="small">
                      <strong>{`${t('interests')}:`}</strong>
                      <FlexContainer row wrap gapSize="small">
                        {profile
                          ?.account?.()
                          .interests?.()
                          .map((interest) => (
                            <span key={interest.id} className={cx(styles.tag)}>
                              {interest.title}
                            </span>
                          ))}
                      </FlexContainer>
                    </FlexContainer>
                  )}
                </FlexContainer>
              </Panel>
            </div>
          )}
        </>
      )}
    </Container>
  );
};
