import React from 'react';
import { LearningObject } from '@brainstud/academy-api';
import { useCurrentLearningObjectAnswers } from '../../Hooks';
import { LearningObjectStatusColors } from '../../Utils/StatusColors';
import { RoundLink } from '../../Views/CollectionViews/ObjectViews/QuestionView/QuestionHeaderNavigation/RoundLink';
import { useIsObjectViewDataLoading } from '../../Views/CollectionViews/ObjectViews/useIsObjectViewDataLoading';

type Props = {
  to: string;
  learningObject: LearningObject;
  index: number;
};

/**
 * LearningObjectCircle.
 *
 * Circle to navigate between quiz questions
 */
export const LearningObjectCircle = ({ learningObject, index, to }: Props) => {
  const { status } = useCurrentLearningObjectAnswers(learningObject);
  const isLoading = useIsObjectViewDataLoading();

  return (
    <RoundLink
      href={to}
      key={learningObject.id}
      scheme={isLoading ? 'grey' : LearningObjectStatusColors[status]}
    >
      <strong>{index + 1}</strong>
    </RoundLink>
  );
};
