import React, { CSSProperties, PropsWithChildren } from 'react';
import { ContentErrorBoundary } from '@brainstud/coach-panel/Views';

type Props = {
  style?: CSSProperties;
  className?: string;
};

/**
 * CourseLayout.
 *
 * Contains the main layout of a Course.
 */
export const CoachLayout = ({
  className,
  style,
  children,
}: PropsWithChildren<Props>) => (
  <main className={className} style={style}>
    <ContentErrorBoundary>{children}</ContentErrorBoundary>
  </main>
);
