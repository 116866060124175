import React, { useCallback, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useTranslator } from 'Providers/Translator';

const Formula = dynamic(() => import('./DynamicFormula'), {
  ssr: false,
});

export type FormulaFormProps = {
  data: Partial<any>;
  onChange: (e: Partial<any>) => void;
};

export const FormulaForm = ({ data, onChange }: FormulaFormProps) => {
  const [t] = useTranslator();

  const [formula, setFormula] = useState(data.formula || '');
  const handleChange = useCallback((value: string) => {
    setFormula(value);
  }, []);

  useEffect(() => {
    if (formula) {
      onChange({
        formula,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formula]);

  return (
    <div>
      <p>
        <strong>
          {t('course-editor.content_editor.blocks.formula.formula')}
        </strong>
      </p>
      <Formula defaultValue={formula} onChange={handleChange} />
    </div>
  );
};
