export default function clone<T = any>(
  src: any,
  renamePropertyNameFunction?: (input: string) => string,
  skipPropertyConversion: string[] = []
): T {
  if (src == null || typeof src !== 'object') {
    return src as unknown as T;
  }
  if (typeof window !== 'undefined') {
    if (src instanceof FormData) {
      return src as unknown as T;
    }
  }

  if (src instanceof Date) {
    return new Date(src.getTime()) as unknown as T;
  }

  if (src instanceof RegExp) {
    return new RegExp(src) as unknown as T;
  }

  if (src instanceof Array) {
    return src.map((item) =>
      clone(item, renamePropertyNameFunction, skipPropertyConversion)
    ) as unknown as T;
  }

  if (src instanceof Object) {
    const hasRenameFunction = typeof renamePropertyNameFunction === 'function';

    return Object.keys(src).reduce(
      (createdObject, propertyName) => {
        if (skipPropertyConversion.includes(propertyName)) {
          // eslint-disable-next-line no-param-reassign
          createdObject[propertyName] = src[propertyName];
          return createdObject;
        }

        const name = hasRenameFunction
          ? renamePropertyNameFunction(propertyName)
          : propertyName;

        // eslint-disable-next-line no-param-reassign
        createdObject[name] = clone(
          src[propertyName],
          renamePropertyNameFunction,
          skipPropertyConversion
        );

        return createdObject;
      },
      {} as { [key: string]: any }
    ) as unknown as T;
  }

  throw new Error(`Unable to copy. ${src} isn't supported.`);
}
