/**
 * ModalActions.
 *
 * List of constants identifying the actions available in the ModalReducer.
 */
export default {
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  CLOSE_ALL_MODALS: 'CLOSE_ALL_MODALS',
} as const;
