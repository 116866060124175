import { IResource } from '../Utils/Resource';

export interface FaqAudience extends IResource<'faq_audiences'> {
  title: string;
  faqItems?: () => FaqItem[];
}

export enum FaqAudienceIncludes {
  faqItems,
  'faqItems.faqCategory',
}
export enum FaqAudienceFilters {}
export enum FaqAudienceSort {
  title,
}

export interface FaqCategory extends IResource {
  title: string;
  faqItems?: () => FaqItem[];
}

export enum FaqCategoryIncludes {
  faqItems,
  'faqItems.faqAudience',
}

export enum FaqCategoryFilters {}

export enum FaqCategorySort {
  title,
}

export interface FaqItem extends IResource {
  question: string;
  answer: string;
  faqAudience?: () => FaqAudience;
  faqCategory?: () => FaqCategory;
}

export enum FaqItemIncludes {
  faqAudience,
  faqCategory,
}

export enum FaqItemFilters {}

export enum FaqItemSort {
  title,
}
