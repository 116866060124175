import { isPresent } from 'ts-is-present';
import type { TFormProps } from '../Types';

/**
 * A default simple translator function
 */
export const FormTranslator: Exclude<TFormProps['translator'], undefined> = (
  errors,
  translations
) =>
  errors
    .map((error) => {
      if (!error.message) {
        return undefined;
      }
      const translation = translations[error.message]
        ? translations[error.message]
        : error.message;
      return typeof translation === 'string'
        ? translation
        : (translation({ parameter: error.parameter }) as unknown as string);
    })
    .filter(isPresent);
