import { DateTimeString } from '../Utils/DateTimeString';
import { IResource } from '../Utils/Resource';
import { UUID } from '../Utils/UUID';
import { WeeklyTimeSlot } from './WeeklyTimeSlot';

export interface Schedule extends IResource<'schedules'> {
  enabled?: boolean;
  createdAt?: DateTimeString;
  weeklyTimeSlots?: () => Array<WeeklyTimeSlot>;
}

export interface ScheduleCreateInput {
  enrollment?: UUID;
  complete_schedule: Array<{
    week_day_string:
      | 'monday'
      | 'tuesday'
      | 'wednesday'
      | 'thursday'
      | 'friday'
      | 'saturday'
      | 'sunday'
      | string;
    enabled: boolean;
  }>;
}

export enum ScheduleIncludes {
  'weekly_time_slots',
}

export enum ScheduleFilters {}

export enum ScheduleSorts {}
