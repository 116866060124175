import { IResource } from '../Utils/Resource';
import { Account } from './Account';

export interface AccountNote extends IResource<'account_notes'> {
  note: string;
  author?: () => Account;
}

export interface AccountNoteCreateInput {
  note: string;
}

export interface AccountNoteUpdateInput extends AccountNoteCreateInput {}

export enum AccountNoteIncludes {
  account,
  author,
}

export interface AccountNoteFilters {}

export enum AccountNoteSorts {}
