import React, { useEffect, useState } from 'react';
import { IFileUploadBlock } from '@brainstud/course-blocks';
import { Dropdown, Input } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import styles from './FileUploadForm.module.css';

const cx = classNames.bind(styles);

type TProps = {
  data: IFileUploadBlock;
  onChange: (data: Partial<IFileUploadBlock>) => void;
};

export const FileUploadForm = ({ data, onChange }: TProps) => {
  const [t] = useTranslator();
  const [question, setQuestion] = useState(data?.question || '');
  const [accept, setAccept] = useState(data?.accept || '*');
  const [files, setFiles] = useState(data?.files);

  useEffect(() => {
    onChange?.({
      question,
      accept,
      files,
    });
  }, [question, accept, files]);

  return (
    <div className={cx(styles.base)}>
      <Input
        label={t('course-editor.content_editor.blocks.file_upload.question')}
        onChange={(e) => setQuestion(e.target.value)}
        value={question}
        rules={['required']}
      />
      <div className={cx(styles.columns)}>
        <Input
          type="number"
          style={{ width: '200px' }}
          label={t('course-editor.content_editor.blocks.file_upload.files')}
          onChange={(e) => setFiles(parseInt(e.target.value, 10))}
          value={files?.toString()}
        />
        <Dropdown
          label={t('course-editor.content_editor.blocks.file_upload.accept')}
          onChange={(value) => value && setAccept(value)}
          value={accept as string}
        >
          <Dropdown.Option value="*">
            {t('course-editor.content_editor.blocks.file_upload.options.all')}
          </Dropdown.Option>
          <Dropdown.Option value={'image/*'}>
            {t('course-editor.content_editor.blocks.file_upload.options.image')}
          </Dropdown.Option>
          <Dropdown.Option value="video/*">
            {t('course-editor.content_editor.blocks.file_upload.options.video')}
          </Dropdown.Option>
          <Dropdown.Option value=".docx,.pdf,.pages,.keynote,.pptx,.xlsx,.xls,.doc,.txt,.rtf,.ppt">
            {t(
              'course-editor.content_editor.blocks.file_upload.options.document'
            )}
          </Dropdown.Option>
          <Dropdown.Option value="application/pdf">
            {t('course-editor.content_editor.blocks.file_upload.options.pdf')}
          </Dropdown.Option>
        </Dropdown>
      </div>
    </div>
  );
};
