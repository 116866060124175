import { Button } from '@brainstud/ui';
import { ArrowBackIos } from '@mui/icons-material';
import style from './BackButton.module.css';

type Props = {
  to?: string;
  onClick?: () => void;
};

const BackButton = ({ to, onClick }: Props) => (
  <Button type="button" className={style.base} quiet to={to} onClick={onClick}>
    <ArrowBackIos fontSize="large" />
  </Button>
);

export default BackButton;
