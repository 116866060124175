import { useContext } from 'react';
import { IPaginationContext, PaginationContext } from './PaginationContext';

/**
 * Provides the pagination context to set pagination settings
 * @param nullable {true} When set to true, it will not throw an error when context does not exist, simply returns
 * null then.
 * @throws Error when the context is not present and nullable is false/undefined
 */
export function usePagination(nullable?: false): IPaginationContext;
export function usePagination(nullable?: true): null | IPaginationContext;
export function usePagination(nullable = false) {
  const context = useContext(PaginationContext);

  if (!context && !nullable) {
    throw new Error('Please, initiate the PaginationProvider first!');
  }

  return context;
}
