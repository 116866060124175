export const FormTranslations = {
  en: {
    required: () => 'This field is required.',
    regex: ({ parameter }: any) =>
      `This field does not have the required format (${parameter}).`,
    min: ({ parameter }: any) =>
      `This field requires at least ${parameter} characters.`,
    max: ({ parameter }: any) =>
      `This field has a maximum of ${parameter} characters.`,
    in_array: ({ parameter }: any) =>
      `This field needs to have one of the following values: "${parameter.split(',').join('", "')}".`,
    is_email: 'This field should contain a valid email address.',
    equals: 'The expected input was not equal to the received input.',
    selected: () => 'This option should have been selected.',
    unselected: () => 'This option should not have been selected.',
    correct: () => 'This is the correct option.',
    incorrect: () => 'This is not the correct option.',
  },
  nl: {
    required: 'Dit veld is verplicht.',
    regex: ({ parameter }: any) =>
      `Dit veld voldoet niet aan de gestelde eisen (${parameter}).`,
    min: ({ parameter }: any) =>
      `Dit veld moet minimaal ${parameter} tekens bevatten.`,
    max: ({ parameter }: any) => `Dit veld bevat meer dan ${parameter} tekens.`,
    in_array: ({ parameter }: any) =>
      `Dit veld moet één van de volgende opties zijn: "${parameter.split(',').join('", "')}".`,
    is_email: () => 'Dit veld dient een geldig e-mailadres te bevatten.',
    equals: () =>
      'De ingevulde waarde was niet gelijk aan de verwachtte waarde.',
    selected: () => 'Deze had aangevinkt moeten zijn.',
    unselected: () => 'Deze had niet aangevinkt moeten zijn.',
    correct: () => 'Dit is de juiste optie.',
    incorrect: () => 'Deze optie is incorrect.',
  },
};
