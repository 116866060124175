import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useApi } from '@brainstud/academy-api';
import {
  IDragAndDropQuestion,
  TDraggable,
  TDropArea,
} from '@brainstud/course-blocks';
import { Button, Input, UploadButton } from '@brainstud/ui';
import { useDebounce } from '@brainstud/universal-components';
import { Add, DeleteForever } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { useTranslator } from 'Providers/Translator';
import { shortId } from 'Utils';
import { AnswerFeedback, DeleteableInput } from '../../../Components';
import { AddItemButton } from '../../../Components/AddItemButton/AddItemButton';
import { useDynamicList } from '../../../Hooks/useDynamicList';
import styles from './DragAndDrop.module.css';

export type DragAndDropFormProps = {
  data: IDragAndDropQuestion;
  onChange: (data: Omit<IDragAndDropQuestion, 'id' | 'type'>) => void;
};

export const DragAndDropForm = ({ data, onChange }: DragAndDropFormProps) => {
  const [t] = useTranslator();
  const { baseUrl, headers: apiHeaders } = useApi();
  const [question, setQuestion] = useState(data.question);
  const [feedback, setFeedback] = useState(data.feedback);

  const [
    dropareas,
    { add: addDropArea, update: updateDropArea, remove: removeDropArea },
  ] = useDynamicList<TDropArea>(data.options, {
    label: '',
    value: shortId(),
  });
  const [
    draggables,
    { add: addInput, update: updateInput, remove: removeInput },
  ] = useDynamicList<TDraggable>(data.inputs, {
    label: '',
  });

  const debouncedData = useDebounce(
    useMemo(
      () => ({
        question,
        inputs: draggables,
        options: dropareas,
        feedback,
      }),
      [question, draggables, dropareas, feedback]
    ),
    100
  );

  useEffect(() => {
    onChange(debouncedData);
  }, [debouncedData]);

  return (
    <div className={styles.base}>
      <Input
        label={t('course-editor.content_editor.blocks.drag_and_drop.question')}
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
        rules={['required']}
      />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label>
            {t('course-editor.content_editor.blocks.drag_and_drop.dropzones')}
          </label>
        </Grid>
        <Grid item xs={6}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label>
            {t('course-editor.content_editor.blocks.drag_and_drop.draggables')}
          </label>
        </Grid>
        {dropareas?.map((dropArea) => {
          const draggablesForThisArea = draggables.filter((input) =>
            input.correct.includes(dropArea.id)
          );
          return (
            <Fragment key={dropArea.id}>
              <Grid item xs={6}>
                <div className={styles.sectioned}>
                  <DeleteableInput
                    value={dropArea.label}
                    onChange={(e) =>
                      updateDropArea(dropArea.id, { label: e.target.value })
                    }
                    onDelete={
                      draggablesForThisArea.length === 0
                        ? () => removeDropArea(dropArea.id)
                        : undefined
                    }
                  />
                  {!!dropArea.image && (
                    <>
                      <img
                        src={`${dropArea.image}`}
                        alt={dropArea.label}
                        className={styles['uploaded-image']}
                      />

                      <Button
                        quiet
                        onClick={() => {
                          updateDropArea(dropArea.id, { image: '' });
                        }}
                        type="button"
                      >
                        <DeleteForever />
                      </Button>
                    </>
                  )}
                  {!dropArea.image && (
                    <UploadButton
                      url={`${baseUrl}/v1/services/media_upload`}
                      headers={apiHeaders}
                      paramName="files[0]"
                      maxFiles={1}
                      className={styles.uploadButton}
                      small
                      quiet
                      onAfterFileUpload={(file: any, response: any) => {
                        updateDropArea(dropArea.id, {
                          image: response?.data?.[0]?.attributes?.file_url,
                        });
                      }}
                    >
                      <Add fontSize="large" />
                      {t(
                        'course-editor.content_editor.blocks.drag_and_drop.add_image'
                      )}
                    </UploadButton>
                  )}
                </div>
              </Grid>
              <Grid item xs={6}>
                {draggablesForThisArea.map((drag) => (
                  <div key={drag.id} className={styles.sectioned}>
                    <DeleteableInput
                      value={drag.label}
                      onChange={(e) =>
                        updateInput(drag.id, { label: e.target.value })
                      }
                      onDelete={() => removeInput(drag.id)}
                    />
                    {!!drag.image && (
                      <>
                        <img
                          src={`${drag.image}`}
                          alt={drag.label}
                          className={styles['uploaded-image']}
                        />

                        <Button
                          quiet
                          onClick={() => {
                            updateInput(drag.id, { image: '' });
                          }}
                          type="button"
                        >
                          <DeleteForever />
                        </Button>
                      </>
                    )}
                    {!drag.image && (
                      <UploadButton
                        url={`${baseUrl}/v1/services/media_upload`}
                        headers={apiHeaders}
                        paramName="files[0]"
                        maxFiles={1}
                        className={styles.uploadButton}
                        small
                        quiet
                        onAfterFileUpload={(file: any, response: any) => {
                          updateInput(drag.id, {
                            image: response?.data?.[0]?.attributes?.file_url,
                          });
                        }}
                      >
                        <Add fontSize="large" />
                        {t(
                          'course-editor.content_editor.blocks.drag_and_drop.add_image'
                        )}
                      </UploadButton>
                    )}
                  </div>
                ))}
                <AddItemButton
                  title={t(
                    'course-editor.content_editor.blocks.drag_and_drop.add_draggable'
                  )}
                  onClick={() =>
                    addInput({ id: shortId(), correct: [dropArea.id] })
                  }
                />
              </Grid>
            </Fragment>
          );
        })}
        <Grid item xs={6}>
          <Button
            small
            type="button"
            onClick={() => addDropArea({ id: shortId(), label: '' })}
          >
            <Add />
            <span>
              {t(
                'course-editor.content_editor.blocks.drag_and_drop.add_droppable'
              )}
            </span>
          </Button>
        </Grid>
      </Grid>
      <AnswerFeedback feedback={feedback} onChange={setFeedback} />
    </div>
  );
};
