import React from 'react';
import { Block } from '@brainstud/academy-api';
import { Panel } from '@brainstud/ui';
import { Grid } from '@mui/material';
import classNames from 'classnames/bind';
import { Container } from 'Components';
import { AVAILABLE_TAGS, useObjectTags } from 'Hooks';
import {
  BackButton,
  CardTag,
  PrefixPoster,
} from 'Modules/action-based-learning-panel/Components';
import { Content } from 'Modules/action-based-learning-panel/Layouts';
import { BlockRenderer } from 'Modules/course-blocks';
import { useRouter } from 'next/router';
import { useLearningObjectProvider } from 'Providers';
import { LayoutComponent } from 'Views/CollectionViews/Layouts';
import { ObjectHeader } from 'Views/CollectionViews/ObjectViews/ObjectHeader';
import styles from 'Views/CollectionViews/ObjectViews/PanelView/PanelView.module.css';

const cx = classNames.bind(styles);

type Props = {
  blocks: Block[];
};

export const PanelView: LayoutComponent<Props> = ({ blocks }) => {
  const { learningObject, variety } = useLearningObjectProvider();
  const tags = useObjectTags(learningObject);
  const router = useRouter();
  const { learningObjectId } = router.query as { learningObjectId: string };

  return (
    <Container>
      <Content className={cx(styles.base)}>
        <BackButton />
        <ObjectHeader />
        <div className={cx(styles['tag-wrapper'])}>
          {tags
            .filter(
              (tag) =>
                AVAILABLE_TAGS.includes(tag.name) &&
                !(tag.name === 'xp' && Math.ceil(Number(tag.value)) === 0)
            )
            .map((tag) => (
              <CardTag key={tag.name} type={tag.name} value={tag?.value} />
            ))}
        </div>
        <Grid
          container
          justifyContent="center"
          spacing={4}
          className={cx('grid-container')}
        >
          {blocks.map((component, i) => (
            <Grid
              item
              key={component.id}
              md={variety?.layout !== 'rows' ? 6 : 12}
              sm={12}
              xs={12}
            >
              <Panel className={cx(styles.panel)}>
                {i === 0 &&
                  (variety?.version === undefined || variety.version < 4) && (
                    <PrefixPoster />
                  )}
                <BlockRenderer id={learningObjectId} block={component} />
              </Panel>
            </Grid>
          ))}
        </Grid>
      </Content>
    </Container>
  );
};

PanelView.deprecated = true;
