import { IResource } from '../Utils/Resource';
import { CatalogProduct } from './CatalogProduct';
import type { Entity } from './Entity';
import type { Portal } from './Portal';
import type { SubEntity } from './SubEntity';

export interface Catalog extends IResource<'catalogs'> {
  name: string;
  enabled: boolean;
  remarks?: string;
  products?: () => CatalogProduct[];
  owner?: () => Portal | Entity | SubEntity;
  meta?: {
    subjectType?: string;
  };
}

export interface CatalogCreateBaseInput {
  enabled: boolean;
  remarks?: string;
  relationships?: {
    entity?: string;
    sub_entity?: string;
  };
}

export interface CatalogUpdateInput {
  enabled?: string;
  remarks?: string;
}

interface CatalogContractCreateInput extends CatalogCreateBaseInput {
  contract: string;
}

interface CatalogTargetCreateInput extends CatalogCreateBaseInput {
  model_type?: string;
  model_id?: string;
}

// Require or the contract, or the model_type and model_id.
export type CatalogCreateInput =
  | CatalogContractCreateInput
  | CatalogTargetCreateInput;

export enum CatalogIncludes {
  products,
  owner,
}
export interface CatalogFilters {
  enabled: boolean;
  'products.price_types': string[];
  'products.model_types': string[];
  owner_types: Array<'entity' | 'sub_entity' | 'portal'>;
  entity: string;
  sub_entity: string;
  portal: string;
}
export enum CatalogSorts {
  enabled,
}
