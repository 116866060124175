import React from 'react';
import { useBlock } from 'Hooks/useBlock';
import dynamic from 'next/dynamic';
import { IFormulaBlock } from './Types';

const DynamicFormula = dynamic(
  () => import('../../../Components/Formula/Formula'),
  {
    ssr: false,
  }
);

export const FormulaBlock = ({ id, formula }: Omit<IFormulaBlock, 'type'>) => {
  useBlock({
    id,
    read: true,
  });
  return <DynamicFormula defaultValue={formula} />;
};
