import { TRoles } from 'Config/roles';
import { arrayWrap } from 'Utils/arrayWrap';
import { useUserData } from '../UserData';

/**
 * useHasAnyRole.
 *
 * Checks the list of user roles if any roles match.
 */
export function useHasAnyRole(roles: TRoles | TRoles[]): boolean {
  const [{ roles: accountRoles }] = useUserData();
  const expectedRoles = arrayWrap(roles);
  return !accountRoles
    ? expectedRoles.includes('unauthenticated')
    : expectedRoles.some((item) => accountRoles.includes(item));
}
