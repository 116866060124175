import { ITranslations } from 'Providers/Translator';
import { DateTimeString } from '../Utils/DateTimeString';
import { IResource } from '../Utils/Resource';
import { AccountType } from './Account';
import { Catalog } from './Catalog';
import { Contract } from './Contract';
import { Entity } from './Entity';
import { PortalDomain } from './PortalDomain';

interface GuideInterface {
  type: string;
  items: {
    type: string;
    title: string;
    documentUrl?: string;
    vimeoId?: string;
    posterUrl?: string;
    categories: Array<string>;
    roles: Array<string>;
  };
  categories: Array<{ id: string; label: string }>;
}

export interface Portal extends IResource<'portals'> {
  name: string;
  description: null | string;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
  allowNonBillableOrders: boolean;
  guides: Array<GuideInterface>;
  parameters: Array<{ type: string; data: any }>;
  entities?: () => Entity[];
  portalDomains?: () => PortalDomain[];
  catalog?: () => Catalog;
  contract?: () => Contract;
  accessRequirements: {
    accountTypes: Array<AccountType | 'guest'>;
  };
  information: {
    [key: string]: any;
  };
  routes: {
    main: string;
    student: string;
    coach: string;
  };
  hero?: {
    title?: string;
    text?: string;
    image?: string;
  };
  contactInformation?: {
    companyName?: string;
    address?: {
      street?: string;
      postalCode?: string;
      city?: string;
      countryCode?: string;
    };
    imprint?: string;
    phoneNumber?: string;
    emailAddress?: string;
  };
  languageOverride: Partial<ITranslations>;
  features: string[];
  meta?: {
    users?: {
      online?: number;
    };
    courses?: {
      available: number;
    };
    orders?: {
      lastMonth?: number;
    };
    entities?: {
      total: number;
    };
  };
}

export enum PortalIncludes {
  entities,
  portal_domains,
  parameters,
  catalog,
  features,
  contract,
}

export interface PortalFilters {
  /** filter the portals on their name */
  name: string;
}

export interface PortalSort {
  /** sort the portals on their name */
  name: string;
}

export type PortalMeta = {
  stats: Array<'users' | 'courses' | 'orders' | 'entities'>;
};
