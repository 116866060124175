import React, { ReactNode } from 'react';
import { Button, ContentModal } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import styles from './Tips.module.css';

type ModalProps = {
  content: ReactNode | string;
  closeModal: () => void;
};

const cx = classNames.bind(styles);

/**
 * This modal is shown when opening the tips for the participant
 */
export const TipsModal = ({ content, closeModal }: ModalProps) => {
  const [t] = useTranslator();
  return (
    <ContentModal onClose={closeModal} onClickOutside>
      <h3 className={cx('modal-title')}>{`${t('menu.tips')}!`}</h3>
      {typeof content === 'string' ? (
        <div
          className={cx('modal-content')}
          dangerouslySetInnerHTML={{
            __html: sanitizer(content, undefined, { ADD_ATTR: ['target'] }),
          }}
        />
      ) : (
        { content }
      )}

      <Button
        type="button"
        className={cx('modal-close')}
        link
        onClick={closeModal}
      >
        {t('close')}
      </Button>
    </ContentModal>
  );
};
