import { CamelToSnakeCase } from '../Utils/CamelCaseToSnakeCase';
import { IResource } from '../Utils/Resource';

export type ToolkitItemType = 'link' | 'video' | 'image' | 'document' | 'file';

export type ToolkitContent = {
  fileName?: string;
  url: string;
};

/**
 * A toolkit item contains information about a file, video, link or image to be downloaded by a
 * user. Often used as supporting materials in a course.
 */
export interface ToolkitItem extends IResource<'toolkit_items'> {
  /** Title to show to the user to indicate the content */
  title: string;
  /** The type of content in this item */
  itemType: ToolkitItemType;
  /** A description about the content of this item */
  description?: string;
  /** An object containing more information about the content of the item, such as the URL */
  content: ToolkitContent;
  /** A thumbnail that can be uploaded for the content item */
  thumbnailLink?: string;
}

export interface ToolkitItemCreateInput
  extends CamelToSnakeCase<
    Omit<ToolkitItem, 'id' | 'resourceType' | 'content'>
  > {
  required_role?: string;
  /** An object containing more information about the content of the item, such as the URL */
  item_content?: CamelToSnakeCase<ToolkitContent>;
}

export interface ToolkitItemUpdateInput extends ToolkitItemCreateInput {}

export enum ToolkitItemIncludes {
  toolkit_categories,
}

export enum ToolkitItemFilters {
  item_type,
  title,
  content,
}

export enum ToolkitItemSorts {
  title,
  item_type,
}
