import { UseQueryOptions } from 'react-query';
import { useIndex } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  NotificationType,
  NotificationTypeFilters,
  NotificationTypeIncludes,
  NotificationTypeSorts,
} from '../Types';

interface INotificationTypesProps
  extends HookParams<
    typeof NotificationTypeIncludes,
    typeof NotificationTypeFilters,
    typeof NotificationTypeSorts
  > {}

export function useNotificationTypes(
  { ...queryParameters }: INotificationTypesProps = {},
  settings?: UseQueryOptions<DataDocument<NotificationType[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.notificationTypes',
      baseUri: '/v1/notification_types',
      queryParameters,
    },
    context
  );
  return useIndex<NotificationType>(request, settings);
}
