import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  StudentGroup,
  StudentGroupCreateInput,
  StudentGroupFilters,
  StudentGroupIncludes,
  StudentGroupSorts,
  StudentGroupUpdateInput,
  UUID,
} from '../Types';

interface IStudentGroupProps
  extends HookParams<
    typeof StudentGroupIncludes,
    typeof StudentGroupFilters,
    typeof StudentGroupSorts
  > {
  studentGroup?: UUID;
}

export function useStudentGroup(
  { studentGroup, ...queryParameters }: IStudentGroupProps = {},
  settings?: UseQueryOptions<DataDocument<StudentGroup>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { studentGroup: defaultStudentGroup },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(studentGroup || defaultStudentGroup),
      baseName: 'v1.studentGroups',
      baseUri: '/v1/student_groups',
      uri: `/${studentGroup || defaultStudentGroup}`,
      invalidate: [
        'student_group',
        'student_groups',
        'students',
        'v1.students',
      ],
      queryParameters,
    },
    context
  );

  return useSingleCRUD<
    StudentGroup,
    StudentGroupCreateInput,
    StudentGroupUpdateInput
  >(request, settings);
}

export function useStudentGroups(
  { ...queryParameters }: Omit<IStudentGroupProps, 'studentGroup'> = {},
  settings?: UseQueryOptions<DataDocument<StudentGroup[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.studentGroups',
      baseUri: '/v1/student_groups',
      invalidate: [
        'student_group',
        'student_groups',
        'students',
        'v1.students',
      ],
      queryParameters,
    },
    context
  );
  return useIndexCR<StudentGroup, StudentGroupCreateInput>(request, settings);
}
