import { createReactReducer } from '../../../../Utils';

export type TDataList = {
  [key: string]: string;
};

interface IAutocompleteState {
  values: TDataList;
  options: {
    filtered: TDataList;
    original: TDataList;
  };
  inputValue: string;
  isDrawerOpen: boolean;
  isFetching: boolean;
  isStaticData: boolean;
  error?: Error;
}

export const InitialAutocompleteState: IAutocompleteState = {
  values: {},
  options: {
    filtered: {},
    original: {},
  },
  inputValue: '',
  isDrawerOpen: false,
  isFetching: false,
  isStaticData: true,
};

type TSetOptions = {
  type: 'SET_OPTIONS';
  payload: TDataList;
};
type TSetApiError = {
  type: 'SET_API_ERROR';
  payload: Error;
};
type TAddValue = {
  type: 'ADD_VALUE';
  payload: { [key: string]: string };
};
type TRemoveValue = {
  type: 'REMOVE_VALUE';
  payload: string;
};
type TChangeInputValue = {
  type: 'CHANGE_INPUT_VALUE';
  payload: string;
};
type TSimpleActions = {
  type: 'OPEN_DRAWER' | 'CLOSE_DRAWER' | 'TOGGLE_DRAWER' | 'START_FETCHING';
};

type TResetInput = {
  type: 'RESET_INPUT';
};

type TActions =
  | TSetOptions
  | TAddValue
  | TRemoveValue
  | TChangeInputValue
  | TSimpleActions
  | TSetApiError
  | TResetInput;

function simplifyString(string: string = '') {
  return string.toLowerCase().replaceAll(/\s-\\'\/"/g, '');
}

function searchOnValue(object: TDataList, searchString?: string) {
  const simplifiedSearchString = simplifyString(searchString);
  if (simplifiedSearchString.length === 0) {
    return object;
  }
  return Object.keys(object).reduce(
    (result, key) =>
      simplifyString(object[key]).includes(simplifiedSearchString)
        ? {
            ...result,
            [key]: object[key],
          }
        : result,
    {}
  );
}

function filterOutKey(object: TDataList, filteredKeys: string[] = []) {
  if (filteredKeys.length === 0) {
    return object;
  }
  return Object.keys(object).reduce(
    (result, key) =>
      !filteredKeys.includes(key)
        ? {
            ...result,
            [key]: object[key],
          }
        : result,
    {}
  );
}

export const AutocompleteInputReducer = createReactReducer<
  IAutocompleteState,
  TActions
>({
  START_FETCHING: () => ({
    isFetching: true,
  }),
  SET_OPTIONS: ({ payload }, { values }) => ({
    options: {
      filtered: filterOutKey(payload, Object.keys(values)),
      original: payload,
    },
    isFetching: false,
  }),
  SET_API_ERROR: ({ payload: error }) => ({
    error,
    isFetching: false,
  }),
  ADD_VALUE: ({ payload }, { values, options }) => {
    const filteredOptions = filterOutKey(
      options.filtered,
      Object.keys(payload)
    );
    return {
      inputValue: '',
      isDrawerOpen: Object.keys(filteredOptions).length > 0,
      options: {
        ...options,
        filtered: filterOutKey(options.filtered, Object.keys(payload)),
      },
      values: {
        ...values,
        ...payload,
      },
    };
  },
  REMOVE_VALUE: ({ payload }, { values, options }) => ({
    options: {
      ...options,
      filtered: {
        ...options.filtered,
        [payload]: options.original[payload],
      },
    },
    values: Object.keys(values).reduce(
      (newValues, key) =>
        key === payload
          ? newValues
          : {
              ...newValues,
              [key]: values[key],
            },
      {}
    ),
  }),
  CHANGE_INPUT_VALUE: ({ payload: searchString }, { options, values }) => ({
    inputValue: searchString,
    isDrawerOpen: searchString.length > 0,
    options: {
      ...options,
      filtered: searchOnValue(
        filterOutKey(options.original, Object.keys(values)),
        searchString
      ),
    },
  }),
  OPEN_DRAWER: () => ({
    isDrawerOpen: true,
  }),
  CLOSE_DRAWER: () => ({
    isDrawerOpen: false,
  }),
  TOGGLE_DRAWER: (_, { isDrawerOpen }) => ({
    isDrawerOpen: !isDrawerOpen,
  }),
  RESET_INPUT: () => ({
    values: {},
    inputValue: '',
  }),
});
