import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDimensions } from '@brainstud/universal-components';
import classNames from 'classnames/bind';
import { AccordionProps } from './AccordionProps';
import styles from './Accordion.module.css';

const cx = classNames.bind(styles);

/**
 * Shows a button with the title, when clicked on, it will animate the height to show the children
 */
export const Accordion = ({
  open: defaultOpen,
  title,
  heading,
  disabled,
  onClick,
  quiet,
  style,
  className,
  children,
}: AccordionProps) => {
  const [open, setState] = useState(defaultOpen);

  useEffect(() => {
    if (defaultOpen) setState(defaultOpen);
  }, [defaultOpen]);

  const [height, setHeight] = useState(defaultOpen ? 'none' : '0');
  const [contentElement, { scrollHeight }] = useDimensions();

  useEffect(() => {
    if (open) {
      setHeight(`${scrollHeight}px`);
      const timer = setTimeout(() => setHeight('none'), 400);
      return () => clearTimeout(timer);
    }
    const timer = setTimeout(() => setHeight('0'), 400);
    return () => clearTimeout(timer);
  }, [open, scrollHeight]);

  const handleClick = useCallback<MouseEventHandler>(
    (event) => {
      setState((current) => !current);
      if (onClick) onClick(event);
    },
    [onClick]
  );

  const [id] = useState(Math.floor(Math.random() * 1000));

  return (
    <div
      style={style}
      className={cx(
        styles.base,
        {
          'is-quiet': quiet,
          'is-open': !!open,
          'is-heading': heading,
          'accordion-is-open': !!open,
        },
        'accordion-base',
        className
      )}
    >
      <button
        id={`accordion_btn_${id}`}
        className={cx(styles.heading, 'accordion-heading')}
        type="button"
        disabled={disabled}
        onClick={handleClick}
        aria-expanded={open}
        aria-controls={`accordion_${id}`}
      >
        <span className={cx(styles.title, 'accordion-title')}>{title}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
          className={cx(styles.chevron, 'accordion-chevron')}
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
        </svg>
      </button>
      <div
        id={`accordion_${id}`}
        style={{ maxHeight: height }}
        ref={contentElement}
        role="region"
        aria-labelledby={`accordion_btn_${id}`}
        className={cx(styles.contents, 'accordion-content')}
        aria-hidden={open ? 'true' : 'false'}
      >
        {children}
      </div>
    </div>
  );
};
