import React, { PropsWithChildren } from 'react';
import { ContentModal } from '@brainstud/ui';
import { Wrap } from 'Components/Wrap';

type TProps = {
  text: string;
  closeModal?: () => void;
  preformatted?: boolean;
};

const Pre = ({ children }: PropsWithChildren<{}>) => <pre>{children}</pre>;

export const LongTextModal = ({ text, closeModal, preformatted }: TProps) => (
  <ContentModal closeable onClose={closeModal}>
    <Wrap if={!!preformatted} with={Pre}>
      {text}
    </Wrap>
  </ContentModal>
);
