import { createContext } from 'react';
import {
  Course,
  EducationProfile,
  Enrollment,
  ProductCategory,
  UUID,
} from '@brainstud/academy-api';

export type TSortAttribute = 'createdAt' | 'title' | 'sbu' | '';
export type TSortOrder = 'ascending' | 'descending';
export type TViewType = 'grid' | 'list';

export interface CourseListInterface {
  catalog?: Course[];
  categories: ProductCategory[];
  courses?: Course[];
  enrollments?: Enrollment[];
  courseCount: { [p: string]: number } | number;
  educationProfiles: EducationProfile[];
  toggleSbuFilter: (val: null | string) => void;
  setCategoryFilter: (val: null | UUID) => void;
  toggleProfile: (val: UUID) => void;
  setSortAttribute: (val: 'createdAt' | 'title' | 'sbu' | '') => void;
  setSortOrder: (val: ((order: TSortOrder) => TSortOrder) | TSortOrder) => void;
  setSearchQuery: (val: string) => void;
  searchQuery: string;
  quickStart: boolean;
  filters: {
    category: null | UUID;
    profiles: UUID[];
    sbu: string[];
  };
  sortAttribute: TSortAttribute;
  sortOrder: TSortOrder;
  setViewType: (view: TViewType) => void;
  viewType: TViewType;
  reset: () => void;
}

export default createContext<null | CourseListInterface>(null);
