import React, { useCallback } from 'react';
import { UUID } from '@brainstud/academy-api';
import { useLtiToolProvider } from '@brainstud/academy-api/Hooks/useLtiToolProviders';
import {
  Button,
  Input,
  Radio,
  RadioButtonGroup,
  Textarea,
} from '@brainstud/ui';
import classNames from 'classnames/bind';
import { Loading } from 'Components';
import { Form } from 'Modules/universal-components';
import { useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import styles from './IntegrationsForm.module.css';

const cx = classNames.bind(styles);

type TProps = {
  ltiToolProviderId?: UUID;
  formId?: string;
  onSuccess?: () => void;
};

/**
 * Form to create or update a LtiToolProvider
 */
export const LtiToolProviderForm = ({
  ltiToolProviderId,
  formId,
  onSuccess,
}: TProps) => {
  const [t] = useTranslator();
  const [setToast] = useToaster();
  const [{ data: ltiToolProvider, createOrUpdate }, { isLoading }] =
    useLtiToolProvider(
      {
        ltiToolProvider: ltiToolProviderId,
      },
      { enabled: !!ltiToolProviderId }
    );

  const handleSubmit = useCallback(
    (formData) => {
      createOrUpdate.mutateAsync(formData, {
        onSuccess: () => {
          setToast(
            t('forms.integrations.lti.create.lti_tool_provider.success'),
            'success'
          );
          onSuccess?.();
        },
        onError: () => {
          setToast(
            t('forms.integrations.lti.create.lti_tool_provider.error'),
            'error'
          );
        },
      });
    },
    [createOrUpdate, onSuccess, setToast, t]
  );

  return (
    <div className={cx(styles.base)}>
      {ltiToolProviderId && isLoading ? (
        <Loading />
      ) : (
        <Form
          onSubmit={handleSubmit}
          id={formId}
          className={cx(styles.form)}
          autoReset={false}
          disableAfterSubmit={false}
        >
          <Input
            label={t('title')}
            name="title"
            defaultValue={ltiToolProvider?.title}
            rules="required"
          />
          <RadioButtonGroup
            id="lti_version"
            label={`${t('forms.integrations.lti.lti_version')} *`}
            defaultValue={ltiToolProvider?.ltiVersion}
            rules="required"
          >
            <Radio id="lti_version_0" label="1.0.1" defaultValue="1.0.1" />
            <Radio id="lti_version_3" label="1.3.0" defaultValue="1.3.0" />
          </RadioButtonGroup>
          <Input
            label={t('forms.integrations.lti.client_id')}
            name="client_id"
            defaultValue={ltiToolProvider?.clientId}
          />
          <Input
            label={t('forms.integrations.lti.launch_url')}
            name="launch_url"
            defaultValue={ltiToolProvider?.launchUrl}
          />
          <Input
            label={t('forms.integrations.lti.login_url')}
            name="login_url"
            defaultValue={ltiToolProvider?.loginUrl}
          />
          <Textarea
            label={t('forms.integrations.lti.secret')}
            name="secret"
            defaultValue={ltiToolProvider?.secret}
          />

          <div>
            <Button type="submit" loading={createOrUpdate.isLoading}>
              {t('submit')}
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
};
