import React from 'react';
import classNames from 'classnames/bind';
import { BlockRenderer } from '../../../Components';
import { IColumnContainerBlock } from './Types';
import styles from './ColumnContainer.module.css';

const cx = classNames.bind(styles);

export const ColumnContainer = ({
  blocks,
}: Omit<IColumnContainerBlock, 'type'>) => (
  <div className={cx(styles.base, 'blocks__columns-container')}>
    {blocks.map((block) => (
      <BlockRenderer
        key={block?.id}
        id={block?.id}
        block={block}
        spacing={false}
      />
    ))}
  </div>
);
