import { useReducer } from 'react';
import { createReactReducer } from '../../../../Utils/createReactReducer';
import { TDragItem } from './DragAndDropContext';
import { ListReducerActions, TListReducerActions } from './ListReducerActions';

interface IListsState<T = { identifier: string }> {
  lists: {
    [key: string]: T[];
  };
  isTouched: boolean;
}

const ListInitialState = {
  lists: {},
  isTouched: false,
};

const ListReducer = createReactReducer<
  IListsState<TDragItem>,
  TListReducerActions<TDragItem>
>({
  [ListReducerActions.CREATE_LIST]: ({ payload }, { lists }) => ({
    lists: {
      ...lists,
      [payload.listName]: [],
    },
  }),
  [ListReducerActions.REMOVE_LIST]: ({ payload }, { lists }) => ({
    lists: Object.keys(lists).reduce<typeof lists>(
      (result, itemName) =>
        itemName === payload.listName
          ? result
          : { ...result, [itemName]: lists[itemName] },
      {}
    ),
  }),
  [ListReducerActions.ADD_TO_LIST]: ({ payload }, { lists }) => ({
    lists: {
      ...lists,
      ...(lists[payload.listName]?.some(
        (item) => item.identifier === payload.item.identifier
      )
        ? {}
        : {
            [payload.listName]: [
              ...(lists[payload.listName] || []),
              { ...payload.item, dropArea: payload.listName },
            ],
          }),
    },
    isTouched: true,
  }),
  [ListReducerActions.MOVE_ITEM]: ({ payload }, { lists }) => {
    const isSameList = lists[payload.toList]?.some(
      (item) => item.identifier === payload.item.identifier
    );
    return isSameList
      ? {}
      : {
          lists: Object.entries(lists).reduce<typeof lists>(
            (result, [listName, values]) => ({
              ...result,
              ...(payload.toList === listName
                ? {
                    [listName]: [
                      ...(values || []),
                      { ...payload.item, dropArea: listName },
                    ],
                  }
                : {
                    [listName]: (values || []).filter(
                      (item) => item.identifier !== payload.item.identifier
                    ),
                  }),
            }),
            {}
          ),
        };
  },
  [ListReducerActions.REMOVE_FROM_LIST]: ({ payload }, { lists }) => ({
    lists: {
      ...lists,
      [payload.listName]: lists[payload.listName].filter(
        (item) => item.identifier !== payload.item.identifier
      ),
    },
  }),
  [ListReducerActions.REMOVE_FROM_ALL_LIST]: ({ payload }, { lists }) => ({
    lists: Object.entries(lists).reduce<typeof lists>(
      (result, [key, values]) => ({
        ...result,
        [key]: values.filter(
          (item) => item.identifier === payload.item.identifier
        ),
      }),
      {}
    ),
  }),
  [ListReducerActions.RESET]: (_, { lists }) => ({
    lists: Object.keys(lists).reduce(
      (result, listName) => ({
        ...result,
        [listName]: [],
      }),
      {}
    ),
  }),
});

export function useListReducer() {
  return useReducer(ListReducer, ListInitialState);
}
