import React from 'react';
import { Accordion } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { useBlock } from 'Hooks/useBlock';
import { sanitizer } from 'Utils/Sanitizer';
import { IAccordionBlock } from './Types';
import styles from './AccordianBlock.module.css';

const cx = classNames.bind(styles);

/**
 * @deprecated Use AccordionContainer instead
 *
 * This content block will be removed when there are no content blocks left with
 * the type 'accordion'.
 */
export const AccordionBlock = ({
  id,
  content,
  style,
}: Omit<IAccordionBlock, 'type'>) => {
  useBlock({
    id,
  });

  return (
    <div className={cx('block-accordion__container')} style={style}>
      {(content || []).map((item) => (
        <div key={item.id} className={styles.accordion}>
          <Accordion title={item.title} className={cx('block_accordion__item')}>
            {item.description && (
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizer(item.description || ''),
                }}
              />
            )}
          </Accordion>
        </div>
      ))}
    </div>
  );
};
