import React, { FC } from 'react';
import { Input, InputProps } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { EditorButton } from '../EditorButton/EditorButton';
import styles from './DeleteableInput.module.css';

const cx = classNames.bind(styles);

interface DeleteableInputProps extends InputProps {
  onDelete?: () => void;
}

/**
 * DeleteableInput.
 *
 * This is an ordinary input, but with the option to press an X that triggers an `onDelete`
 * event.
 */
export const DeleteableInput: FC<DeleteableInputProps> = ({
  onDelete,
  ...input
}) => (
  <div className={cx(styles.base)}>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <Input {...input} />
    {onDelete && (
      <EditorButton onClick={onDelete} className={cx(styles.delete)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="currentColor"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" />
        </svg>
      </EditorButton>
    )}
  </div>
);
