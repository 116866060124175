import React, { ChangeEvent, useEffect, useState } from 'react';
import { IHTMLCodeBlock } from '@brainstud/course-blocks';
import { Textarea } from '@brainstud/ui';
import { useTranslator } from 'Providers/Translator';

export interface HTMLCodeFormProps {
  data: IHTMLCodeBlock;
  onChange: (e: Partial<IHTMLCodeBlock>) => void;
}

export const HTMLCodeForm = ({ data, onChange }: HTMLCodeFormProps) => {
  const [t] = useTranslator();
  const [content, setContent] = useState(data.content);

  useEffect(() => {
    onChange?.({ content });
  }, [content]);

  return (
    <Textarea
      label={t('course-editor.content_editor.blocks.html_code_block.name')}
      onChange={(e: ChangeEvent<HTMLInputElement>) =>
        setContent(e.target.value)
      }
      value={data?.content}
    />
  );
};
