import { DataDocument } from '../Support';
import { IResourceOrCollection } from '../Types';
import { TJsonSpecResponse } from '../Types/Utils/JsonApiResource';
import clone from './clone';
import { toCamelCase } from './transformCase';

export function transformFetchResponse<
  T extends IResourceOrCollection = any,
  AJ extends boolean = false,
>(
  response: Response,
  asJson?: AJ
): Promise<AJ extends true ? TJsonSpecResponse : DataDocument<T>> {
  return asJson
    ? response.json()
    : response.json().then(
        (data) =>
          new DataDocument<T>({
            ...response,
            data: clone(data, toCamelCase),
          })
      );
}
