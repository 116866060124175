import { UseQueryOptions } from 'react-query';
import { useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  LearningObject,
  LearningObjectCreateInput,
  LearningObjectFilters,
  LearningObjectIncludes,
  LearningObjectSorts,
  LearningObjectUpdateInput,
  UUID,
} from '../Types';

interface ILearningObjectProps
  extends HookParams<
    typeof LearningObjectIncludes,
    LearningObjectFilters,
    typeof LearningObjectSorts
  > {
  exposition?: UUID;
  learningObject?: UUID;
}

export function useExpositionLearningObject(
  { exposition, learningObject, ...queryParameters }: ILearningObjectProps = {},
  settings?: UseQueryOptions<DataDocument<LearningObject>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: {
      learningObject: defaultLearningObject,
      enrollment: defaultEnrollment,
    },
  } = context;
  const request = new ApiRequest(
    {
      enabled:
        !!(learningObject || defaultLearningObject) &&
        !!(exposition || defaultEnrollment),
      baseName: 'v1.expositions.learningObjects',
      baseUri: `/v1/expositions/${exposition || defaultEnrollment}/learning_objects`,
      uri: `/${learningObject || defaultLearningObject}`,
      invalidate: ['learning_objects', 'learning_object', 'v1.learningObjects'],
      queryParameters,
      shareable: true,
    },
    context
  );
  return useSingleCRUD<
    LearningObject,
    LearningObjectCreateInput,
    LearningObjectUpdateInput
  >(request, settings);
}
