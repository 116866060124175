import React, { useRef } from 'react';
import { Button } from '@brainstud/ui';
import { ChevronRight } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import { TFeedbackFormProps } from './Types';
import styles from './FeedbackTypes.module.css';

const cx = classNames.bind(styles);

export const TextareaFeedback = ({ title, onAnswer }: TFeedbackFormProps) => {
  const control = useRef<HTMLTextAreaElement>(null);
  const [t] = useTranslator();

  return (
    <div className={cx('textarea')}>
      <textarea ref={control} placeholder={title} />
      <Button
        quiet
        className={cx('next-button')}
        onClick={() => onAnswer(control.current?.value || '')}
      >
        <span>{t('components.feedback.send')}</span>
        <ChevronRight />
      </Button>
    </div>
  );
};
