import { useState } from 'react';
import { Coach, Course, UUID } from '@brainstud/academy-api';
import { Button, ConfirmationModal, Dropdown } from '@brainstud/ui';
import { Form } from 'Modules/universal-components';
import { useTranslator } from 'Providers/Translator';
import { NO_COACH, useUnenroll } from '../Hooks';
import styles from './UnEnrollModal.module.css';

type Props = {
  course: Course;
  closeModal: () => void;
};

const UnEnrollModal = ({ course, closeModal }: Props) => {
  const [t] = useTranslator();
  const [newCoachId, setNewCoachId] = useState<UUID | undefined>();
  const { handleUnenroll, coaches, loading, coachesLoading } = useUnenroll({
    newCoachId,
    course,
    closeModal,
  });

  return (
    <ConfirmationModal
      className={styles.base}
      onClickOutside
      closeModal={closeModal}
    >
      <div className={styles.container}>
        <h2>{t('modals.unEnrollModal.title')}</h2>
        <p>{t('modals.unEnrollModal.body')}</p>
        <Form onSubmit={handleUnenroll}>
          <Dropdown
            className={styles.dropdown}
            value={newCoachId}
            disabled={coachesLoading}
            onChange={setNewCoachId}
            placeholder={t('modals.unEnrollModal.coaches_default')}
            searchable={coaches.length > 5}
            rules="required"
          >
            {coaches.map((coach: Coach) => (
              <Dropdown.Option key={coach.id} value={coach.id}>
                {[coach.firstName, coach.lastNameInsertion, coach.lastName]
                  .filter(Boolean)
                  .join(' ')}
              </Dropdown.Option>
            ))}
            <Dropdown.Option value={NO_COACH}>
              {t('modals.unEnrollModal.no_transfer')}
            </Dropdown.Option>
          </Dropdown>
          <div className={styles.buttons}>
            <Button
              type="submit"
              loading={loading}
              error
              className={styles.submit}
            >
              {t('modals.unEnrollModal.confirm')}
            </Button>
            <Button type="button" loading={loading} quiet onClick={closeModal}>
              {t('modals.cancel')}
            </Button>
          </div>
        </Form>
      </div>
    </ConfirmationModal>
  );
};

export default UnEnrollModal;
