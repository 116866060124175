import { createContext, RefObject } from 'react';
import { Editor } from '@tiptap/react';

export interface IRichTextEditorProviderContext {
  editor: Editor;
  selectedText: string;
  backdrop: RefObject<HTMLDivElement>;
  terms?: Array<{
    id: string;
    term: string;
  }>;
  disabled: boolean;
}

export const RichTextEditorContext =
  createContext<null | IRichTextEditorProviderContext>(null);
