/**
 * STAGING_ENDPOINTS.
 *
 * This is a list of API endpoints pointing to a staging environment. This list is used to
 * toggle to different staging environments during review sessions.
 *
 * @type {string[]}
 */
export const STAGING_ENDPOINTS = [
  'https://stage-api.unlimited.software/api',
  'https://playground-one.brainstud.dev/api',
  'https://playground-two.brainstud.dev/api',
];

/**
 * API DOMAIN COOKIE.
 *
 * During a build you can change the API endpoint it talks to by setting this cookie.
 */
export const API_DOMAIN_COOKIE = 'api_endpoint';

/**
 * THEME OVERWRITE KEY
 *
 * Used to save a theme in the localStorage.
 */
export const THEME_OVERRIDE_KEY = 'theme-override';
