import { DateTimeString } from '../Utils/DateTimeString';
import { IResource } from '../Utils/Resource';
import { UUID } from '../Utils/UUID';
import { Enrollment } from './Enrollment';
import { ExamAssessment } from './ExamAssessment';
import { ExamAssessor } from './ExamAssessor';
import { ExamContent } from './ExamContent';
import { ExamContentVariety } from './ExamContentVariety';
import { ExamSampleAnswer } from './ExamSampleAnswer';
import { ExamStepApproval } from './ExamStepApproval';
import { ExamUpload } from './ExamUpload';

export type ExamSteps =
  | 'CREATED'
  | 'IN_PRE_ASSESSMENT'
  | 'PRE_ASSESSMENT_APPROVED'
  | 'PRE_ASSESSMENT_REJECTED'
  | 'IN_PROGRESS'
  | 'HANDED_IN'
  | 'COMPLETED_APPROVED'
  | 'COMPLETED_REJECTED';

export interface Exam extends IResource<'exams'> {
  currentStep: ExamSteps;
  signalDate: DateTimeString;
  currentSampleSetIteration: number;
  initiator: string;
  coachHasSeenAt: null | string;
  examContent?: () => ExamContent;
  examContentVariety?: () => ExamContentVariety;
  stepApprovals?: () => ExamStepApproval[];
  sampleAnswers?: () => ExamSampleAnswer[];
  uploads?: () => ExamUpload[];
  assessment?: () => ExamAssessment;
  assessors?: () => ExamAssessor[];
  enrollment?: () => Enrollment;
}

export interface ExamCreateInput {
  enrollment?: UUID;
}

export interface ExamUpdateInput extends Partial<ExamCreateInput> {
  has_seen: boolean;
}

export enum ExamIncludes {
  'exam_content',
  'exam_content_variety',
  'step_approvals',
  'sample_answers',
  'uploads',
  'assessment',
  'sample_answers.learning_object',
  'sample_answers.answer',
  'assessors',
  'assessors.account',
  'assessors.assigned_by_account',
  'enrollment',
  'enrollment.account',
  'enrollment.course',
}

export enum ExamFilters {
  current_step,
  coach_account,
  course,
  query,
}

export enum ExamSorts {
  'created_at',
}
