import React, { CSSProperties, ReactNode, useCallback } from 'react';
import { Help } from '@mui/icons-material';
import classNames from 'classnames/bind';
import styles from './Popover.module.css';

const classes = classNames.bind(styles);

type Props = {
  title?: string;
  style?: CSSProperties;
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
};

/**
 * Popover.
 *
 * Adds a small explanatory popover. Can be clickable to open a modal with more explanations for example. This component
 * should primarily be used for indicative reasons. It automatically adds a Help icon when clickable.
 */
export const Popover = ({
  title,
  onClick,
  className,
  style,
  children,
}: Props) => {
  const handleOnClickEvent = useCallback(
    (event: { stopPropagation: () => void }) => {
      event.stopPropagation();
      onClick?.();
    },
    [onClick]
  );

  const Wrapper = onClick ? 'button' : 'div';
  const props =
    Wrapper === 'button'
      ? ({ type: 'button', onClick: handleOnClickEvent } as const)
      : {};
  return (
    <div className={classes(styles.base, className)} style={style}>
      <div className={styles.content}>{children}</div>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Wrapper {...props} className={classes(styles.popover)}>
        {!!onClick && <Help />}
        <span>{title}</span>
      </Wrapper>
    </div>
  );
};
