import React, { useCallback, useRef, useState } from 'react';
import { Heading } from '@brainstud/coach-panel/Layouts';
import { Button, Panel } from '@brainstud/ui';
import { useOnClickOutside } from '@brainstud/universal-components';
import { Close } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import { FilterButton } from '../FilterButton';
import styles from './FilterPanelButton.module.css';

const cx = classNames.bind(styles);

type TProps = {
  label: string;
  title?: string;
  onReset?: () => void;
  count?: number;
  className?: string;
  children: React.ReactNode;
};

/**
 * FilterPanelButton.
 *
 * With this component you can add a button that will toggle a panel onClick
 * The contents of the panel can be added in the parent component as children
 */
export const FilterPanelButton = ({
  label,
  title,
  children,
  onReset,
  count = 0,
  className,
}: TProps) => {
  const [t] = useTranslator();

  // Logic for handling opening / closing of filter panel
  const [openPanel, setOpenPanel] = useState(false);
  const panelRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  useOnClickOutside([panelRef, buttonRef], () => {
    setOpenPanel(false);
  });

  const handleTogglePanel = useCallback(() => {
    setOpenPanel((prevState) => !prevState);
  }, []);

  return (
    <div className={cx(styles.base)}>
      <FilterButton
        ref={buttonRef}
        onClick={handleTogglePanel}
        active={openPanel}
        count={count}
      >
        {label || t('components.filter_buttons.label')}
      </FilterButton>

      {openPanel && (
        <div ref={panelRef} className={styles.container}>
          <Panel className={cx(styles.panel)}>
            <Heading className={cx(styles.heading)}>
              {title && <h3>{title}</h3>}
              {onReset && (
                <Button
                  type="button"
                  link
                  className={cx(styles['link-button'], 'link-button')}
                  onClick={onReset}
                >
                  {t('components.filter_buttons.reset')}
                </Button>
              )}
              <Button
                type="button"
                link
                className={cx(styles['close-button'])}
                onClick={handleTogglePanel}
              >
                <Close />
              </Button>
            </Heading>
            <div className={cx(styles.inner, className)}>{children}</div>
          </Panel>
        </div>
      )}
    </div>
  );
};
