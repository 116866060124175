import React from 'react';
import { LtiResourceLink } from '@brainstud/academy-api';
import { Button, Callout, ContentModal } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import styles from './LtiModal.module.css';

const cx = classNames.bind(styles);

type TProps = {
  ltiResourceLink?: LtiResourceLink;
  closeModal: () => void;
};

/**
 * A modal to confirm you are opening external LTI content in a new tab/window.
 */
export const LaunchLtiResourceModal = ({
  ltiResourceLink,
  closeModal,
}: TProps) => {
  const [t] = useTranslator();

  return (
    <ContentModal closeable onClose={closeModal} className={cx(styles.launch)}>
      <ContentModal.Header>
        <h1>{t('modals.integrations.lti.title')}</h1>
      </ContentModal.Header>
      <p>{t('modals.integrations.lti.open_external_course')}</p>

      {!ltiResourceLink && (
        <Callout error>
          <p>{t('modals.integrations.lti.invalid')}</p>
        </Callout>
      )}
      <ContentModal.Footer>
        <Button
          type="button"
          target="_blank"
          to={ltiResourceLink?.links?.launchResource.href || ''}
          disabled={!ltiResourceLink}
        >
          {t('modals.integrations.lti.to_course')}
        </Button>
        <Button quiet type="button" onClick={closeModal}>
          {t('close')}
        </Button>
      </ContentModal.Footer>
    </ContentModal>
  );
};
