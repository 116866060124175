import { DateTimeString } from '../Utils/DateTimeString';
import { IResource } from '../Utils/Resource';
import { UUID } from '../Utils/UUID';
import { Account } from './Account';
import { Entity } from './Entity';
import { InvoiceProfile } from './InvoiceProfile';
import { OrderLine } from './OrderLine';
import { OrderPreRegistrationForm } from './OrderRegistration';
import { PrepaidCode } from './PrepaidCode';
import { Transaction } from './Transaction';

export interface Order extends IResource<'orders'> {
  billable: boolean;
  closeDate: DateTimeString;
  code: string;
  fulfillmentMethod: string;
  openDate: DateTimeString;
  paymentMethod: string;
  remarks: string;
  state: string;
  totalOrderWorth: number;
  totalExVat: number;
  preRegistrationRequired: boolean;
  preRegistrationForm?: OrderPreRegistrationForm;
  preRegistrationReceived: boolean;
  orderLines?: () => OrderLine[];
  invoices?: () => InvoiceProfile[];
  transaction?: () => Transaction;
  transactions?: () => Transaction[];
  prepaidCodes?: () => PrepaidCode[];
  subject?: () => Account | Entity;
  links: {
    receipt: {
      href: string;
      meta: {
        type: 'download';
      };
    };
    redirect?: {
      href: string;
    };
  };
}

export interface OrderCreate {
  state?: 'pending';
  subject?: string;
  /**
   * payment_method.
   * Preferred way of payment.
   */
  payment_method?: 'invoice' | 'transaction' | 'prepaid';
  portal?: UUID;
  fulfillment_method?: 'direct activation' | 'prepaid codes';
  order_lines: Array<{
    catalog_product: UUID;
    quantity?: number;
    product_add_on?: UUID;
  }>;
}

export interface OrderUpdate {
  /**
   * pay_via.
   * Input for the prepaid payment. Typically, the prepaid code.
   */
  pay_via?: string;
  /**
   * payment_method.
   * Preferred way of payment.
   */
  payment_method: 'invoice' | 'transaction' | 'prepaid';
}

export enum OrderIncludes {
  order_lines,
  subject,
  invoices,
  transactions,
  prepaid_codes,
  'order_lines.product_add_on',
  'order_lines.prepaid_codes',
  'order_lines.prepaid_codes.product',
}
export interface OrderFilters {
  /** filter the orders on a specific linked entities */
  entities: string;

  /** filter on one or more portals based on UUID (comma seperated) */
  portals: string;

  /** filter by close_date within the given timeframe.  */
  from: string;
  to: string;

  /** filter on one or more states of the order */
  states: string;

  /** filter on one or more methods */
  payment_methods: string;

  /** whether the order was created by an entity or an account */
  subject_type: 'entity' | 'account';

  /** filter on billable-ness */
  billable: true | false;

  /** filter on auto-renewing orders */
  type: 'continuous' | 'one_off';
}
export enum OrderSort {}
