import { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { HeadContext } from './HeadContext';

type TOptions = {
  overwrite?: boolean;
};

/**
 * useHeadProvider.
 *
 * With the useHeadProvider, you can easily set values for the html <head>.
 */
export function useHeadProvider(
  title?: string,
  { overwrite }: TOptions = { overwrite: false }
) {
  const context = useContext(HeadContext);
  const [path, setPath] = useState<string>();
  const { asPath } = useRouter();

  useEffect(() => {
    if ((asPath !== path || overwrite) && context && title !== context.title) {
      context.setTitle(title);
      setPath(asPath);
    }
  }, [title, context, overwrite, asPath, path]);

  if (!context) {
    throw new Error('Please initiate HeadProvider first!');
  }

  return context;
}
