import { useContext } from 'react';
import { ToasterContext } from './ToasterContext';

/**
 * useToaster.
 *
 * Display short messages to the user.
 *
 * @returns {readonly [((message: string, scheme?: ToastSchemes, duration?: number) => void), (() => void)]}
 */
export function useToaster() {
  const context = useContext(ToasterContext);

  if (!context) {
    throw new Error(
      'The Toaster cannot be used without initializing the ToasterProvider.'
    );
  }

  return context;
}
