import { DateTimeString } from '../Utils/DateTimeString';
import { IResource } from '../Utils/Resource';
import { Certification } from './Certification';

export interface CertificationDocument
  extends IResource<'certification_documents'> {
  /** The title of the document */
  name: string;
  /** The url to the document */
  url: string;
  /** @deprecated Use .updatedAt instead */
  lastUpdate: DateTimeString;
  /** The date this document was created */
  createdAt: DateTimeString;
  /** The date this document was last updated */
  updatedAt: DateTimeString;
  certification?: () => Certification;
}

export interface CertificationDocumentCreateInput {
  name: string;
  url: string;
}

export interface CertificationDocumentUpdateInput
  extends CertificationDocumentCreateInput {}

export enum CertificationDocumentIncludes {
  certification,
}

export enum CertificationDocumentFilters {
  certification,
  certification_version,
  required_role,
}

export enum CertificationDocumentSorts {}
