import React, { useCallback, useEffect, useState } from 'react';
import { IBlock, Spacing } from '@brainstud/course-blocks';
import { Button } from '@brainstud/ui';
import { useTranslator } from 'Providers/Translator';
import { ToolObject } from '../../../ToolArray';
import useDebounce from '../../Support/useDebounce';
import { useContentEditor } from '../Provider';
import TuneButton from '../Tunes/TuneButton';
import styles from './Renderers.module.css';

type TProps = {
  index: number;
  data: IBlock & { wideness?: string };
  blocks: IBlock[];
  onSubmit: () => void;
};

export const EditBlockRenderer = ({
  index,
  data,
  onSubmit,
  blocks,
}: TProps) => {
  const { updateBlock } = useContentEditor();
  const tool = ToolObject[data?.type];

  const [state, setState] = useState(data);
  const [touched, setTouched] = useState(false);
  const blockWideness = data.wideness;
  const handleChange = useCallback(
    (changes) => {
      setTouched(true);
      setState((prevState) => ({
        id: prevState.id,
        type: prevState.type,
        style: prevState.style,
        wideness: blockWideness,
        ...changes,
      }));
    },
    [blockWideness]
  );

  const debouncedState = useDebounce(state, 300);

  useEffect(() => {
    if (touched) updateBlock(debouncedState);
    setTouched(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedState]);

  const [t] = useTranslator();
  const Block = tool?.form?.();
  const isEditable = !!tool?.isEditable;

  const { backgroundColor, ...spacingStyle } = data.style || {};
  const { paddingTop, paddingBottom, ...colorStyle } = data.style || {};

  return (
    <Spacing
      type={data.type}
      wideness={data.wideness}
      style={spacingStyle}
      className={styles.base}
    >
      <div className={styles.tuneBar}>
        <TuneButton index={index} block={data} blocks={blocks} />
      </div>
      {Block ? (
        <Block data={{ ...data, style: colorStyle }} onChange={handleChange} />
      ) : (
        <pre
          style={{
            background: '#c3c3c3',
            width: '100%',
            display: 'flex',
            color: 'red',
            alignItems: 'center',
          }}
        >
          {t('course-editor.content_editor.blocks.unknown')}
        </pre>
      )}
      {!isEditable && (
        <Button
          type="button"
          onClick={onSubmit}
          loading={state !== debouncedState}
        >
          {t('course-editor.content_editor.blocks.apply')}
        </Button>
      )}
    </Spacing>
  );
};
