import { UseQueryOptions } from 'react-query';
import { useShow } from '../Factories';
import useIndexCR from '../Factories/useIndexCR';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  Coach,
  CoachCreateInput,
  CoachFilters,
  CoachIncludes,
  CoachSorts,
  HookParams,
  UUID,
} from '../Types';

interface ICoachProps
  extends HookParams<typeof CoachIncludes, CoachFilters, typeof CoachSorts> {
  coach?: UUID;
}

export function useCoach(
  { coach, ...queryParameters }: ICoachProps = {},
  settings?: UseQueryOptions<DataDocument<Coach>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { coach: defaultCoach },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(coach || defaultCoach),
      baseName: 'v1.coaches',
      baseUri: '/v1/coaches',
      invalidate: ['coach', 'coaches'],
      uri: `/${coach || defaultCoach}`,
      queryParameters,
    },
    context
  );
  return useShow<Coach>(request, settings);
}

export function useCoaches(
  { ...queryParameters }: Omit<ICoachProps, 'coach'> = {},
  settings?: UseQueryOptions<DataDocument<Coach[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.coaches',
      baseUri: '/v1/coaches',
      invalidate: ['coach', 'coaches'],
      queryParameters,
    },
    context
  );
  return useIndexCR<Coach, CoachCreateInput>(request, settings);
}
