import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import * as Sentry from '@sentry/nextjs';
import { useEventHandler, useOnUnmount } from 'Hooks';
import { useTranslator } from 'Providers/Translator';
import { FormActions } from './FormReducer/FormReducerActions';
import { getErrorFromResponse } from './FormUtils/getErrorFromResponse';
import { FormContext } from './FormContext';
import { useFormReducer } from './FormReducer';
import { getFormValues } from './FormUtils';
import type {
  IFormContext,
  IFormHandlers,
  IFormMethods,
  IFormState,
  TFormEvents,
  TFormProps,
  TFormResponse,
  TFormSavedState,
  TInputList,
} from './Types';

/**
 * Handles input / output for the custom Form Components from the UI library
 */
export function Form<
  TFormInput extends TInputList = TInputList,
  TFormSuccessResponse extends TFormResponse = TFormResponse,
  TFormErrorResponse extends TFormResponse = TFormResponse,
>({
  action,
  loading,
  validate: shouldValidate = true,
  onSubmit,
  onError,
  onSuccess,
  onChange,
  onSave,
  onLoad,
  onReset,
  onRestore,
  onRestored,
  onValidationError,
  onUnmount,
  language: propLanguage,
  disabled: defaultDisabled = false,
  enctype,
  method = 'POST',
  id,
  className,
  style,
  feedback = 'standard',
  autoReset = true,
  children,
  translator: customTranslator,
  validator: customValidator,
  disableAfterSubmit,
}: TFormProps<TFormInput, TFormSuccessResponse, TFormErrorResponse>) {
  const [, { language: appLanguage }] = useTranslator();
  const language = propLanguage || appLanguage;
  const element = useRef<HTMLFormElement>(null);

  const [metaState, dispatch] = useFormReducer({
    language,
    feedback,
    ...(defaultDisabled ? { disabled: defaultDisabled } : {}),
    ...(loading ? { loading } : {}),
    ...(customTranslator ? { translator: customTranslator } : {}),
    ...(customValidator ? { validator: customValidator } : {}),
  });

  const { fields, disabled, dirty, loaded, defaultFields, submitted, saved } =
    metaState;

  const [t] = useTranslator();

  const { subscribe, emit } = useEventHandler<TFormEvents<TFormInput>>();

  const values = useMemo<Partial<TFormInput>>(
    () => getFormValues(fields),
    [fields]
  );

  useEffect(() => {
    if (loaded) {
      emit('load', loaded as TFormInput);
    }
  }, [emit, loaded]);

  useEffect(() => {
    let timer: null | ReturnType<typeof setTimeout> = null;
    if (dirty) {
      timer = setTimeout(() => {
        emit('dirty', fields);
      }, 500);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [fields, emit, dirty]);

  const messages = useMemo<IFormState['messages']>(
    () =>
      fields.reduce(
        (messageList, field) => ({
          ...messageList,
          ...(field.messages && field.messages.length > 0
            ? {
                [field.name]: [
                  ...(messageList[field.name] || []),
                  ...field.messages,
                ],
              }
            : {}),
        }),
        {}
      ),
    [fields]
  );

  const state = useMemo<IFormState<TFormInput>>(
    () => ({
      values,
      fields,
      messages,
      disabled,
    }),
    [values, fields, messages, disabled]
  );

  /**
   * Initialize a field into the form
   * @type function
   * @param id {string} A unique identifier for the field
   * @param name {string} A name with which fields can be grouped.
   * @param value {string} The value of the field.
   * @param fieldRules {string|array<string>} The rules that apply to this field
   * @param isArray {boolean} Whether this field should be treated as an array
   */
  const initialize = useCallback<IFormMethods['initialize']>(
    (fieldId, name, value, fieldRules, isArray) => {
      // Normalize and add rules
      const normalizedRuleList = Array.isArray(fieldRules)
        ? fieldRules
        : fieldRules
          ? fieldRules.split('|')
          : [];
      const field = {
        id: fieldId,
        name,
        value,
        rules: normalizedRuleList,
        isArray,
      };
      dispatch({
        type: FormActions.INIT_FIELD,
        payload: field,
      });
      emit('init', field);
    },
    [dispatch, emit]
  );

  /**
   * Updates the value of the field group, but leaves the default value alone.
   * @type function
   * @param id {string} The unique identifier of the field group
   * @param value {string} The default value of the field group
   */
  const update = useCallback<IFormMethods['update']>(
    (fieldId, value) => {
      if (value === undefined) return;
      dispatch({
        type: FormActions.UPDATE_VALUE,
        payload: {
          id: fieldId,
          value,
        },
      });
      emit('update', { fieldId, value });
    },
    [dispatch, emit]
  );

  useEffect(() => {
    emit('change', values as TFormInput);
  }, [emit, values]);

  const reset = useCallback<IFormMethods['reset']>(
    (event) => {
      dispatch({
        type: FormActions.RESET,
        payload: {
          disabled: defaultDisabled,
        },
      });
      emit('reset');
      if (!event) {
        element.current?.reset();
      }
      return true;
    },
    [dispatch, defaultDisabled, emit]
  );

  const handleToggleEvaluationState = useCallback<
    IFormHandlers['handleValidation']
  >(
    (fieldRange) => {
      dispatch({
        type: FormActions.TOGGLE_EVALUATION_STATE,
        payload: {
          fields: fieldRange?.map((item) =>
            typeof item === 'string' ? item : item.id
          ),
        },
      });
    },
    [dispatch]
  );

  const handleValidation = useCallback(
    (submittedFields: Exclude<typeof submitted, undefined>) => {
      const isInvalid = submittedFields.some((item) => item.valid === false);
      if (shouldValidate && isInvalid) {
        return onValidationError?.(values, messages);
      }
      if (onSubmit) {
        try {
          const process = onSubmit(values as TFormInput);
          if (!!process && 'then' in process) {
            process
              .then((httpResponse) => {
                if (
                  !!httpResponse &&
                  (httpResponse.status || httpResponse.statusCode || 300) <= 299
                ) {
                  emit('success', httpResponse);
                } else if (httpResponse) {
                  emit('error', httpResponse);
                }
              })
              .catch((errorResponse) => {
                emit('error', errorResponse);
              });
          } else {
            emit('success', process);
          }
        } catch (e) {
          emit('error', e);
        }
      }
    },
    [emit, messages, onSubmit, onValidationError, shouldValidate, values]
  );

  const handleValidateFields = useCallback(() => {
    if (!disabled) {
      dispatch({ type: FormActions.VALIDATE, payload: { emit } });
    }
  }, [disabled, dispatch, emit]);

  useEffect(() => {
    if (submitted) {
      emit('submit', submitted);
      dispatch({
        type: FormActions.SET_PROP,
        payload: {
          submitted: undefined,
        },
      });
    }
  }, [submitted, emit, dispatch]);

  const handleSuccess = useCallback(
    (httpResponse = {}) => {
      if (autoReset) {
        reset();
      }
      return onSuccess ? onSuccess(httpResponse) : null;
    },
    [autoReset, onSuccess, reset]
  );

  const handleError = useCallback(
    (httpResponse) => {
      if (
        httpResponse &&
        httpResponse?.status !== 422 &&
        httpResponse?.statusCode !== 422
      ) {
        const message = getErrorFromResponse(httpResponse);
        Sentry.captureMessage('An unexpected form error response occurred.', {
          extra: {
            httpResponse,
            message,
          },
        });

        // An unexpected error message from the server.
        dispatch({
          type: FormActions.SET_ERROR,
          payload: {
            message,
          },
        });
        return onError ? onError(httpResponse) : null;
      }
      const errors = httpResponse?.data?.errors || httpResponse?.errors;
      if (errors) {
        if (Array.isArray(errors)) {
          // JSON:API Compliant
          dispatch({
            type: FormActions.APPEND_VALIDATION_ERRORS,
            payload: {
              messages: errors.reduce((errorList, item) => {
                const identifier =
                  item?.source?.pointer || item?.source?.parameter;
                const pointer = identifier && identifier.split('/').slice(1);
                const errorFields = fields.filter(
                  (field) =>
                    field.name === pointer[0] || field.name === identifier
                );
                const fieldId =
                  errorFields[pointer[1] || errorFields.length - 1]?.id;
                return {
                  ...errorList,
                  [fieldId]: [t(item.detail)],
                };
              }, {}),
            },
          });
        } else {
          // Non-JSON:API Compliant
          dispatch({
            type: FormActions.SET_VALIDATION_ERRORS,
            payload: {
              messages: Object.keys(errors).reduce((errorList, key) => {
                const pointer = key.split('.');
                const errorFields = fields.filter(
                  (field) => field.name === pointer[0]
                );
                const fieldId =
                  errorFields[pointer[1] || errorFields.length - 1]?.id;
                return {
                  ...errorList,
                  [fieldId]: errors[key],
                };
              }, {}),
            },
          });
        }
      }
      return onValidationError ? onValidationError() : null;
    },
    [onValidationError, onError, dispatch, fields, t]
  );

  const handleSubmit = useCallback<IFormHandlers['handleSubmit']>(
    (event) => {
      if (event) {
        event.preventDefault();
      }
      if (!disabled) {
        dispatch({
          type: FormActions.SUBMIT,
          payload: { disabled: disableAfterSubmit },
        });
      }
    },
    [disabled, dispatch, disableAfterSubmit]
  );

  const save = useCallback<IFormMethods['save']>(
    (args, callbacks) => {
      dispatch({
        type: FormActions.SAVE,
        payload: {
          context: args,
          callbacks,
        },
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (saved) {
      emit(
        'save',
        saved.state as TFormSavedState<TFormInput>,
        saved.context,
        saved.callbacks
      );
      dispatch({
        type: FormActions.SET_PROP,
        payload: {
          saved: undefined,
        },
      });
    }
  }, [saved, emit, dispatch]);

  const handleRestore = useCallback(
    (restorableState: Partial<IFormState>) => {
      dispatch({
        type: FormActions.LOAD,
        payload: restorableState,
      });
      (restorableState.fields || []).forEach((restorableField) => {
        emit('restored-field', restorableField);
      });
      emit('restore', restorableState);
    },
    [dispatch, emit]
  );

  /* Build the form context from methods, handlers and data */
  const methods = useMemo<IFormMethods>(
    () => ({
      initialize,
      update,
      subscribe,
      save,
      reset,
    }),
    [initialize, update, subscribe, reset, save]
  );

  const handlers = useMemo<IFormHandlers>(
    () => ({
      handleValidation: handleToggleEvaluationState,
      handleSubmit,
      handleValidateFields,
    }),
    [handleSubmit, handleToggleEvaluationState, handleValidateFields]
  );

  const data = useMemo(
    () => ({
      ...state,
      defaultFields,
    }),
    [state, defaultFields]
  );

  const context = useMemo<IFormContext<TFormInput>>(
    () => ({
      feedback,
      ...data,
      ...methods,
      ...handlers,
    }),
    [data, feedback, methods, handlers]
  );

  useEffect(() => {
    const subscriptions = [
      subscribe('submit', handleValidation),
      subscribe('success', handleSuccess),
      subscribe('error', handleError),
      ...(onChange ? [subscribe('change', onChange)] : []),
      ...(onRestore ? [subscribe('restore', onRestore)] : []),
      ...(onSave ? [subscribe('save', onSave)] : []),
    ];
    return () => {
      subscriptions.forEach((unsubscribe) => unsubscribe?.());
    };
  }, [
    subscribe,
    handleSuccess,
    handleError,
    onChange,
    onRestore,
    onRestored,
    handleValidation,
    onSave,
  ]);

  useEffect(() => {
    dispatch({
      type: FormActions.SET_DISABLED,
      payload: defaultDisabled,
    });
  }, [defaultDisabled, dispatch]);

  useEffect(() => {
    dispatch({
      type: FormActions.SET_LOADING,
      payload: !!loading,
    });
  }, [loading, dispatch]);

  useEffect(() => {
    if (onLoad) {
      const loadedState = onLoad();
      if (loadedState) {
        handleRestore(loadedState);
      }
    }
  }, [onLoad, handleRestore]);

  useOnUnmount(() => {
    onUnmount?.(metaState);
  }, [metaState, onUnmount]);

  return (
    <FormContext.Provider value={context}>
      <form
        id={id}
        action={action}
        className={className}
        style={style}
        method={method}
        ref={element}
        onSubmit={handleSubmit}
        onReset={(event) => {
          if (onReset?.(event) !== false) {
            reset(event);
          }
        }}
        encType={enctype}
      >
        {typeof children === 'function' ? children(context) : children}
      </form>
    </FormContext.Provider>
  );
}
