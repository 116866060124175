import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  HookParams,
  Toolkit,
  ToolkitCreateInput,
  ToolkitFilters,
  ToolkitIncludes,
  ToolkitSorts,
  ToolkitUpdateInput,
  UUID,
} from '../Types';

interface IToolkitProps
  extends HookParams<
    typeof ToolkitIncludes,
    typeof ToolkitFilters,
    typeof ToolkitSorts
  > {
  toolkit?: UUID;
}

export function useToolkit(
  { toolkit, ...queryParameters }: IToolkitProps = {},
  settings?: UseQueryOptions<DataDocument<Toolkit>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { toolkit: defaultToolkit },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(toolkit || defaultToolkit),
      invalidate: ['toolkits', 'toolkit'],
      baseName: 'v1.toolkits',
      baseUri: '/v1/toolkits',
      uri: `/${toolkit || defaultToolkit}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<Toolkit, ToolkitCreateInput, ToolkitUpdateInput>(
    request,
    settings
  );
}

export function useToolkits(
  { ...queryParameters }: Omit<IToolkitProps, 'toolkit'> = {},
  settings?: UseQueryOptions<DataDocument<Toolkit[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.toolkits',
      baseUri: '/v1/toolkits',
      invalidate: ['toolkits', 'toolkit'],
      queryParameters,
    },
    context
  );
  return useIndexCR<Toolkit, ToolkitCreateInput>(request, settings);
}
