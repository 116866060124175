import React, { useCallback, useEffect, useState } from 'react';
import { Checkbox, Input } from '@brainstud/ui';
import { Edit, InsertLink, LinkOff } from '@mui/icons-material';
import { useTranslator } from 'Providers/Translator';
import { EditorButton } from '../../../EditorButton';
import { EditorTooltip, EditorTooltipButton } from '../../../EditorTooltip';
import { useRichTextEditorProvider } from '../../../useRichTextEditorProvider';

/**
 * LinkButton.
 *
 * These buttons can be shown when a rich text editor can toggle a link.
 */
export const LinkButton = () => {
  const { editor } = useRichTextEditorProvider();
  const [t] = useTranslator();

  const [linkUrl, setLinkUrl] = useState<string>();
  const [isOpen, setIsOpen] = useState(false);
  const [openBlank, setOpenBlank] = useState(false);

  const previousUrl = editor.getAttributes('link').href;
  const handleClose = useCallback(() => {
    const prevUrl = editor.getAttributes('link').href;
    setIsOpen(false);
    if ((!linkUrl || linkUrl === '') && !prevUrl) {
      editor.commands.unsetLink();
    }
    setLinkUrl(undefined);
  }, [editor, linkUrl]);

  const handleAddLink = useCallback(() => {
    editor
      .chain()
      .focus()
      .setLink({ href: linkUrl || '', target: openBlank ? '_blank' : null })
      .run();
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, [editor, linkUrl, openBlank]);

  const handleRemoveLink = useCallback(() => {
    editor.commands.unsetLink();
  }, [editor]);

  useEffect(() => {
    if (isOpen && editor.isActive('link')) {
      setOpenBlank(editor.getAttributes('link').target === '_blank');
      editor.commands.extendMarkRange('link');
    }
  }, [editor, isOpen]);

  return (
    <>
      <div>
        {editor.can().toggleLink?.({ href: '' }) && (
          <EditorButton
            active={editor.isActive('link')}
            onClick={handleAddLink}
          >
            <InsertLink />
          </EditorButton>
        )}
      </div>

      <EditorTooltip
        shouldShow={({ editor: instance }) => instance.isActive('link')}
        onClickOutside={!isOpen ? undefined : handleClose}
      >
        {!isOpen ? (
          <>
            <EditorTooltipButton onClick={() => setIsOpen(true)}>
              <Edit />
              <span>{t('edit')}</span>
            </EditorTooltipButton>
            <EditorTooltipButton onClick={handleRemoveLink}>
              <LinkOff />
            </EditorTooltipButton>
          </>
        ) : (
          <div>
            <div style={{ display: 'flex', gap: '.8rem' }}>
              <Input
                type="text"
                value={linkUrl || previousUrl}
                onChange={(e) => setLinkUrl(e.target.value)}
              />
              <EditorTooltipButton onClick={handleAddLink}>
                {t('save')}
              </EditorTooltipButton>
            </div>
            <Checkbox
              small
              checked={openBlank}
              onChange={(e) => setOpenBlank(e.target.checked)}
              quiet
            >
              Open link in new tab
            </Checkbox>
          </div>
        )}
      </EditorTooltip>
    </>
  );
};
