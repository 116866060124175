import React, { useCallback } from 'react';
import { ConfirmationModal } from '@brainstud/ui';
import { useRouter } from 'next/router';
import { useModals } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { useContentEditor } from '../ContentEditor/Provider/useContentEditor';

type Props = {
  /** Routes to specific URL when user confirms */
  backTo?: string;
  /** Question shown when in confirmation modal  */
  prompt?: string;
  /** Confirm button text */
  confirm?: string;
  /** Cancel button text */
  cancel?: string;
};

/**
 * useUnsavedContentConfirmation.
 *
 * Hook that can be used to verify if user want to trash
 * unsaved changes in the content editor
 */
export const useUnsavedContentConfirmation = ({
  backTo,
  prompt,
  confirm,
  cancel,
}: Props) => {
  const [t] = useTranslator();
  const { openModal, closeModal } = useModals();
  const router = useRouter();

  const { isUnsaved } = useContentEditor();

  /**
   * Verifies whether user knows there are unsaved changes
   */
  const handleUnsavedChangesCheck = useCallback(
    (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
      if (isUnsaved) {
        e.preventDefault();
        openModal(ConfirmationModal, {
          question:
            prompt ||
            t('course-editor.content_editor.confirmation.confirm_leave'),
          yes:
            confirm || t('course-editor.content_editor.confirmation.confirm'),
          no: cancel || t('course-editor.content_editor.confirmation.cancel'),
          handleConfirm: () => {
            closeModal();
            if (backTo) {
              window.location.href = backTo;
            } else {
              router.back();
            }
          },
        });
        return false;
      }
      return true;
    },
    [
      isUnsaved,
      backTo,
      closeModal,
      openModal,
      t,
      prompt,
      confirm,
      cancel,
      router,
    ]
  );

  return { handleUnsavedChangesCheck, isUnsaved };
};
