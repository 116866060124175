import { UUID } from '../Utils/UUID';
import { Account } from './Account';
import { Coach } from './Coach';
import { Enrollment } from './Enrollment';
import { ExamSteps } from './Exam';
import { Subscription } from './Subscription';

export interface MenteeEnrollment extends Enrollment {
  /** The number of XPs recieved by the student for this enrollment */
  currentXp: number;
  /** The total amount of XPs for the student within this course */
  totalXp: number;
  /** The current exam step */
  examStep?: ExamSteps;
  /** Coach for the enrollment */
  coach?: () => Coach;
}

/**
 * While similar to a Student resource, a mentee possesses certain differences from a traditional student.
 * A primary coach (or mentor) has the capability to access more comprehensive information about a student,
 * even if the student has not officially enrolled in a course facilitated by the coach.
 * The information requested is tailored to offer a broader overview of the student's progress,
 * with a focus beyond grading and exercise progression.
 */
export interface Mentee
  extends Pick<
    Account,
    'id' | 'resourceType' | 'lastOnline' | 'fullName' | 'classUnit'
  > {
  enrollments?: () => MenteeEnrollment[];
  subscription?: () => Subscription;
}

export enum MenteeIncludes {
  enrollments,
  'enrollments.course',
  'enrollments.coach',
  subscription,
}

export interface MenteeFilters {
  full_name?: string;
  archived?: boolean;
}

export enum MenteeSorts {
  full_name,
  class_unit,
  last_online,
}

export interface MenteeBulkUpdateInput {
  _method: 'patch';
  operation: 'archive' | 'unarchive';
  accounts: UUID[];
}
