import React from 'react';
import { Callout } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { ErrorBoundary } from 'Providers/ErrorDataProvider/ErrorBoundary';
import { sanitizer } from 'Utils/Sanitizer';
import useApp from '../AppProvider/useApp';
import styles from './Base.module.css';

const cx = classNames.bind(styles);

interface Props {
  small?: boolean;
  children?: React.ReactNode;
}

const Base = ({ small, children }: Props) => {
  const { flash } = useApp();

  return (
    <ErrorBoundary>
      <section role="main" className={cx(styles.base, { small })}>
        {flash.message && (
          <div className={cx(styles.flash)}>
            <Callout
              {...{ [flash.type || 'info']: true }}
              dangerouslySetInnerHTML={{ __html: sanitizer(flash.message) }}
            />
          </div>
        )}

        {children}
      </section>
    </ErrorBoundary>
  );
};

export default Base;
