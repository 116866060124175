import React, { useMemo } from 'react';
import { toSnakeCase } from '@brainstud/academy-api/Utils/transformCase';
import classNames from 'classnames/bind';
import { LearningCard } from 'Components/Cards/LearningCard';
import { ObjectCardIconTags } from 'Components/Cards/ObjectCard/IconTags';
import {
  AVAILABLE_TAGS,
  BADGES,
  filterObjectBadges,
  filterObjectTags,
  TTag,
} from 'Hooks';
import { CardTag } from 'Modules/action-based-learning-panel/Components';
import { useForm } from 'Modules/universal-components';
import styles from './PreviewLearningObject.module.css';

const cx = classNames.bind(styles);

type TProps = {
  points: number;
  fileUrl: string | undefined;
};

/**
 * Shows a preview of the learningObject card
 */
export const PreviewLearningObject = ({ points, fileUrl }: TProps) => {
  const { values } = useForm();
  const tags: TTag[] = useMemo(
    () =>
      [...BADGES, ...AVAILABLE_TAGS].reduce<TTag[]>(
        (output, tag) => [
          ...output,
          ...(values[toSnakeCase(tag)]
            ? [
                {
                  name: tag,
                  type: tag,
                  value: ['true', 'false'].includes(
                    values?.[toSnakeCase(tag)]?.toString().toLowerCase() || ''
                  )
                    ? undefined
                    : values?.[toSnakeCase(tag)]?.toString().toLowerCase(),
                },
              ]
            : []),
        ],
        []
      ),
    [values]
  );

  return (
    <div className={cx(styles.base)}>
      <LearningCard
        title={(values.title as string) || ''}
        status={{ state: 'SUCCESS', label: '' }}
        leftOverlay={
          <ObjectCardIconTags tags={tags?.filter(filterObjectBadges)} />
        }
        posterUrl={fileUrl}
        subheader={
          <>
            {points > 0 && <CardTag key="xp" type="xp" value={points} />}
            {tags.filter(filterObjectTags).map((tag) => (
              <CardTag key={tag.name} type={tag.name} value={tag.value} />
            ))}
          </>
        }
      />
    </div>
  );
};
