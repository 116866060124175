import { AxiosError } from 'axios';
import ErrorResource from '../../Resource/ErrorResource/ErrorResource';
import JsonDocument from '../JsonDocument';

export default class ErrorDocument extends JsonDocument {
  /**
   * Contains all information about the errors send from the API
   * @type {ErrorResource[]}
   */
  public errors: ErrorResource[] = [];

  /**
   * Primarily, use the `errors` property to read the errors from. Use the data property only if
   * the response is not sending an JSON:API spec error.
   * @type {unknown}
   */
  public data: unknown;

  constructor(error: AxiosError) {
    super(error);
    const { response } = error;

    if (ErrorDocument.isApiErrorResponse(response)) {
      if (Array.isArray(response.data.errors)) {
        this.errors = response.data.errors.map(
          (item) => new ErrorResource(item, this)
        );
      } else if (response.data.errors) {
        try {
          this.errors = [new ErrorResource(response.data.errors, this)];
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('Unexpected error response', response.data);
        }
      }
    } else if (ErrorDocument.isApiResponse(response)) {
      this.errors = [
        new ErrorResource(
          {
            code: error.code,
            status: response.status.toString(),
            title: response.statusText,
            meta: response,
          },
          this
        ),
      ];
    } else {
      this.errors = [
        new ErrorResource(
          {
            code: error.code,
            status: error.status,
            title: error.status,
            meta: error,
          },
          this
        ),
      ];
    }
    this.data = response?.data;
  }
}
