import { UUID } from '@brainstud/academy-api';
import { isUUID } from './isUuid';

/**
 * getUUIDsFromString.
 *
 * Returns all UUIDs from a string.
 * E.g. all UUIDs from a URL search param
 */
export function getUUIDsFromString(input?: string): UUID[] {
  const uuids = input?.split(',');
  if (!input || typeof uuids === 'undefined') {
    return [];
  }

  return uuids.filter(isUUID);
}
