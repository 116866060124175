import { EffectCallback, useEffect } from 'react';
import { RouterEvent, useRouter } from 'next/router';

/**
 * useOnRouteChange
 *
 * Hook that simplifies the use of the NextJS router events property.
 */
export function useOnRouteChange(
  callback: EffectCallback,
  event: RouterEvent = 'routeChangeStart'
) {
  const router = useRouter();
  useEffect(() => {
    router.events.on(event, callback);
    return () => {
      router.events.off(event, callback);
    };
  }, [router, callback, event]);
}
