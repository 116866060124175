/**
 * Returns the string with the first character as uppercase
 * @param {string} string
 * @returns {string}
 */
function toUpperCaseWord(string: string) {
  return string.substr(0, 1).toUpperCase() + string.substr(1);
}

/**
 * Returns string with first character as lowercase
 * @param {string} string
 * @returns {string}
 */
function toLowerCaseWord(string: string) {
  return string.substr(0, 1).toLowerCase() + string.substr(1);
}

/**
 * Returns each word with uppercase character
 * @param {string} string
 * @param {string} delimiter
 * @returns {string}
 */
function toPascal(string: string, delimiter = ' ') {
  return string
    .split(delimiter)
    .map((v) => toUpperCaseWord(v))
    .join('');
}

/**
 * Converts a string to a camel case string.
 * @param {string} string The string that will be converted.
 * @return {string}
 */
export function toCamelCase(string: string) {
  if (
    string.match(
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
    )
  ) {
    return string;
  }

  return toLowerCaseWord(toPascal(toPascal(toPascal(string, '_'), '-')));
}

/**
 * Converts a hyphen string to a pascal case string.
 * @param {string} string The string that will be converted.
 */
export function toPascalCase(string: string) {
  return toPascal(toPascal(toPascal(string), '-'), '_');
}

/**
 * Converts a camel case string to snake case. When the original strings characters are only capitals it skips this process.
 * @param {string} string The string that will be converted.
 * @returns {string}
 */
export function toSnakeCase(string: string) {
  if (string === string.toUpperCase()) return string;
  return string
    .replace(/(.)([A-Z][a-z]+)/g, '$1_$2')
    .replace(/([a-z0-9])([A-Z])/g, '$1_$2')
    .toLowerCase();
}
