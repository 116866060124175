import { useMe } from '@brainstud/academy-api';
import { ApiException } from 'Exceptions';

/**
 * usePortalFeatureCheck.
 *
 * Based on the v1.me route, it will check whether a feature is enabled or disabled.
 *
 * @param feature key name of the feature as a human readable identifier.
 * @param blocking boolean to indicate this check is blocking so a 403 error is thrown, default value is false.
 */
export function usePortalFeatureCheck(
  feature: string,
  blocking: boolean = false
): boolean {
  const [me] = useMe();
  const portal = me?.portal?.();

  const result = !!portal && portal.features.includes(feature);

  if (blocking && !result) {
    throw new ApiException({
      statusCode: 403,
      statusText: 'forbidden',
      data: {},
      errors: [],
    });
  }

  return result;
}
