import React, { ChangeEventHandler, useCallback, useMemo } from 'react';
import {
  Course,
  StudentFilters,
  StudentGroup,
  StudentSorts,
  TSortType,
  useCoaches,
  useMe,
} from '@brainstud/academy-api';
import { Checkbox, Dropdown } from '@brainstud/ui';
import { TSelectEvent } from '@brainstud/ui/Form Input/Dropdown/Dropdown';
import { DeleteForever } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { TFilterInput, useModals, useViewSettings } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { TViewNames } from 'Providers/ViewSettingsProvider/ViewSettingsActions';
import { DeleteGroupModal } from '../../../../Modals/StudentGroup/DeleteGroupModal';
import styles from './StudentsFilterContent.module.css';

const cx = classNames.bind(styles);

type Props = {
  courses?: Course[];
  studentGroups: StudentGroup[];
  onFilterChange: (filter: TFilter) => void;
  viewName: TViewNames;
};

type TFilter =
  | TFilterInput<Partial<StudentFilters>>
  | TFilterInput<Partial<StudentFilters>>[];

const EXAM_STEPS_VALUE = [
  'PRE_ASSESSMENT_APPROVED',
  'IN_PROGRESS',
  'HANDED_IN',
  'COMPLETED_APPROVED',
  'COMPLETED_REJECTED',
];
const SAMPLE_STEPS_VALUE = ['IN_PRE_ASSESSMENT', 'PRE_ASSESSMENT_REJECTED'];

type TFilters = {
  filters: Partial<StudentFilters>;
  sort: TSortType<typeof StudentSorts>[];
};
const StudentsFilterContent = ({
  courses,
  studentGroups,
  onFilterChange,
  viewName,
}: Props) => {
  const [me] = useMe();
  const [t] = useTranslator();
  const { openModal } = useModals();
  const [{ apiFilters }, dispatch] = useViewSettings<TFilters>(viewName);

  const myAccountId = me?.account?.().id!;
  // Retrieve coaches that I assist to
  const [{ data: parentCoaches }] = useCoaches(
    {
      include: ['assistant_coaches'],
      filter: {
        assistant_coach: myAccountId,
      },
    },
    { enabled: !!myAccountId }
  );

  const examSteps = useMemo(
    () => (apiFilters?.exam_step as string)?.split(',') || [],
    [apiFilters?.exam_step]
  );

  const studentGroupDrowdownValues = useMemo(
    () =>
      studentGroups.map((group) => ({
        value: group.id,
        label: group.name,
      })),
    [studentGroups]
  );

  const handleToggleFilter = useCallback(
    (filterInput: TFilter) => {
      onFilterChange?.(filterInput);
    },
    [onFilterChange]
  );

  const studentDropDownValues = useMemo(
    () => [
      {
        value: myAccountId,
        label: t('students.myStudents'),
      },
      ...(parentCoaches
        ? parentCoaches
            .filter((coach) => coach.id !== myAccountId)
            .map((coach) => ({ value: coach.id, label: coach.fullName }))
        : []),
      {
        value: 'archived',
        label: t('students.archivedStudents'),
      },
    ],
    [myAccountId, parentCoaches, t]
  );

  const handleOnChangeCoach = useCallback(
    (value: string | undefined, event?: TSelectEvent) => {
      if (value === 'archived') {
        handleToggleFilter([
          { key: 'archived', value: true },
          { key: 'coach_account' },
        ]);
      } else {
        handleToggleFilter([
          { key: 'archived' },
          {
            key: 'coach_account',
            value,
            label: event?.currentTarget.textContent,
          },
        ]);
      }
    },
    [handleToggleFilter]
  );

  const handleToggleExamStep = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >(
    (event) => {
      const { value } = event.target;
      let currentExamStepValues =
        (apiFilters?.exam_step as string)?.split(',') || [];
      const hasValue = currentExamStepValues.some((item: string) =>
        value.includes(item)
      );

      if (hasValue) {
        currentExamStepValues = currentExamStepValues.filter(
          (item: string) => !value.includes(item)
        );
      } else {
        const newValues = value.split(',');
        currentExamStepValues = [
          ...new Set([...currentExamStepValues, ...newValues]),
        ];
      }

      /**
       * Setting this explicitly to be able to select multiple exam steps, instead of toggling on/off
       */
      if (!currentExamStepValues.length) {
        dispatch({ type: 'REMOVE_FILTER', payload: { key: 'exam_step' } });
      } else {
        const hasSampleStep = currentExamStepValues.some((item) =>
          SAMPLE_STEPS_VALUE.includes(item)
        );

        const hasExamStep = currentExamStepValues.some((item) =>
          EXAM_STEPS_VALUE.includes(item)
        );

        const label = [
          hasExamStep && t('students.statusFilter.exam'),
          hasSampleStep && t('students.statusFilter.sample'),
        ]
          .filter(Boolean)
          .join(', ');

        dispatch({
          type: 'SET_FILTER',
          payload: {
            key: 'exam_step',
            value: currentExamStepValues.join(','),
            label,
          },
        });
      }
    },
    [apiFilters, dispatch, t]
  );

  const handleChangeStudentGroup = useCallback(
    (value?: string, event?: TSelectEvent) => {
      if (!value) {
        handleToggleFilter({
          key: 'student_group',
          value,
          label: event?.currentTarget.textContent,
        });
      } else {
        dispatch({
          type: 'SET_FILTER',
          payload: {
            key: 'student_group',
            value,
            label: event?.currentTarget.textContent,
          },
        });
      }
    },
    [dispatch, handleToggleFilter]
  );

  const coachFilterValue =
    apiFilters?.coach_account || (apiFilters?.archived && 'archived');

  return (
    <>
      <div className={cx(styles.dropdowns)}>
        <Dropdown
          label={t('students.select_students')}
          placeholder={t('students.allStudents')}
          onChange={handleOnChangeCoach}
          block
          value={(coachFilterValue as string) || ''}
        >
          {studentDropDownValues.map((option) => (
            <Dropdown.Option
              key={`${option.label}-${option.value}`}
              value={option.value}
            >
              {option.label}
            </Dropdown.Option>
          ))}
        </Dropdown>
        <Dropdown
          block
          label={t('students.select_studentGroups')}
          placeholder={t('students.allGroups')}
          value={apiFilters?.student_group}
          onChange={handleChangeStudentGroup}
        >
          {studentGroupDrowdownValues.map((option) => (
            <Dropdown.Option
              value={option.value}
              text={option.label}
              key={option.value}
            >
              <div className={cx(styles.group)}>
                <span>{option.label}</span>
                <button
                  type="button"
                  className={styles['group-delete-button']}
                  onClick={(event) => {
                    event.stopPropagation();
                    openModal(DeleteGroupModal, {
                      studentGroupId: option.value,
                    });
                  }}
                >
                  <DeleteForever />
                </button>
              </div>
            </Dropdown.Option>
          ))}
        </Dropdown>
        {!!courses && (
          <Dropdown
            label={t('students.courses')}
            placeholder={t('students.allCourses')}
            onChange={(value, event) =>
              handleToggleFilter({
                key: 'course',
                value,
                label: event?.currentTarget.textContent,
              })
            }
            value={apiFilters?.course as string}
            block
          >
            {courses.map((course) => (
              <Dropdown.Option key={course.id} value={course.id}>
                {course.title}
              </Dropdown.Option>
            ))}
          </Dropdown>
        )}
      </div>
      <div className={cx(styles.status)}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>{t('students.statusFilter.label')}</label>
        <Checkbox
          quiet
          onChange={handleToggleExamStep}
          defaultValue={SAMPLE_STEPS_VALUE.join(',')}
          defaultChecked={examSteps.some((item) =>
            SAMPLE_STEPS_VALUE.includes(item)
          )}
        >
          {t('students.statusFilter.sample')}
        </Checkbox>
        <Checkbox
          quiet
          defaultValue={EXAM_STEPS_VALUE.join(',')}
          onChange={handleToggleExamStep}
          defaultChecked={examSteps.some((item) =>
            EXAM_STEPS_VALUE.includes(item)
          )}
        >
          {t('students.statusFilter.exam')}
        </Checkbox>
      </div>
    </>
  );
};

export default StudentsFilterContent;
