import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Button } from '@brainstud/ui';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { KeenSliderInstance, useKeenSlider } from 'keen-slider/react';
import { Spacing } from '../Spacing/Spacing';
import 'keen-slider/keen-slider.min.css';
import styles from './Slider.module.css';

const cx = classNames.bind(styles);

type SliderState = {
  active: number;
  total: number;
  isFirst: boolean;
  isLast: boolean;
  goToSlide: Dispatch<SetStateAction<number>>;
};

type Props = {
  /** Transition speed in milliseconds */
  speed?: number;
  /**
   * Handler that is fired when previous button is pressed on first slide.
   * If undefined, it hides the previous button on first slide
   */
  onFirst?: () => void;
  /**
   * Handler that is fired when next button is pressed on last slide (e.g. to close modal).
   * If undefined, it hides the next button on last slide
   */
  onLast?: () => void;
  /** Event handler that is fired when active slide is changed */
  onChange?: (idx: number, slider: null | KeenSliderInstance) => void;
  /** The current active slide */
  active?: number;
  /** Whether the slider should be draggable to the next slide (disables text selection) */
  draggable?: boolean;
  /** Overwrite the footer with your own version */
  footer?: (state: SliderState) => JSX.Element;
  children: ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

/**
 * A KeenSlider based Slider component with appropriate default configurations and basic configuration options
 */
export const Slider = ({
  speed = 500,
  active: controlledActive = 0,
  draggable = false,
  className,
  style,
  onChange,
  footer,
  onFirst,
  onLast,
  children,
}: Props) => {
  const slides = useMemo(
    () => (!Array.isArray(children) ? [children] : children || []),
    [children]
  );

  const [initial] = useState(controlledActive);
  const [active, setActive] = useState(controlledActive);
  const isControlled = controlledActive !== undefined;
  const [slider, setSlider] = useState<null | KeenSliderInstance>(null);
  const [options, setOptions] = useState({
    initial,
    drag: draggable,
    created: (instance: KeenSliderInstance) => setSlider(instance),
    slides: slides.length || 1,
  });
  const [sliderRef] = useKeenSlider<HTMLDivElement>(options);
  const container = useRef<HTMLDivElement>(null);
  const containerWidth = container.current?.clientWidth;

  useEffect(() => {
    if (slider) {
      slider.moveToIdx?.(active);
      onChange?.(active, slider);
    }
  }, [active, slider]);

  useEffect(() => {
    if (isControlled) {
      setActive(controlledActive);
    }
  }, [isControlled, controlledActive]);

  useEffect(() => {
    if (slider) {
      setOptions((prevOptions) => ({
        ...prevOptions,
        initial,
        slides: slides.length,
      }));
    }
  }, [slider, slides, initial]);

  useEffect(() => {
    if (slider && Math.ceil(slider.size) !== Math.floor(containerWidth || 0)) {
      slider.update(options, active);
    }
  }, [slider, containerWidth, options, active]);

  const isFirst = active <= 0;
  const isLast = active + 1 >= slides.length;

  return (
    <div ref={container} className={cx(styles.base, className)} style={style}>
      <div
        ref={sliderRef}
        className={cx(styles.slider, 'keen-slider', { draggable })}
      >
        {slides.map((child, index) => (
          <div
            // TODO: remove this index and supply something like an ID
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={cx(styles.slide, 'keen-slider__slide', {
              active: index === active,
              inactive: index !== active,
              slider__active: index === active,
              slider__inactive: index !== active,
            })}
            style={{ transitionDuration: `${speed}ms` }}
          >
            {child}
          </div>
        ))}
      </div>

      {footer ? (
        footer({
          active,
          total: slides.length,
          isFirst,
          isLast,
          goToSlide: setActive,
        })
      ) : (
        <Spacing wideness="medium" implicit>
          <div className={cx(styles.footer)}>
            <Button
              type="button"
              quiet
              small
              disabled={isFirst && !onFirst}
              onClick={() =>
                isFirst ? onFirst?.() : setActive((prevState) => prevState - 1)
              }
            >
              <ArrowBack fontSize="large" />
            </Button>

            <div className={cx(styles.pages)}>
              <span className={cx(styles['page-active'])}>{active + 1}</span>
              <span>/</span>
              <span>{slides.length}</span>
            </div>

            <Button
              type="button"
              quiet
              small
              disabled={isLast && !onLast}
              onClick={() =>
                isLast ? onLast?.() : setActive((prevState) => prevState + 1)
              }
            >
              <ArrowForward fontSize="large" />
            </Button>
          </div>
        </Spacing>
      )}
    </div>
  );
};
