import { UseMutationOptions } from 'react-query';
import { useCreate } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import { ContentFeedbackCreateInput } from '../Types';

type Settings = UseMutationOptions<
  DataDocument<undefined>,
  ErrorDocument,
  ContentFeedbackCreateInput
>;

export function useCreateContentFeedback(settings?: Settings) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.contentFeedback',
      baseUri: '/v1/content_feedback',
      invalidate: ['content_feedback'],
    },
    context
  );
  return useCreate<undefined, ContentFeedbackCreateInput>(request, settings);
}
