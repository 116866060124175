import React, { useCallback } from 'react';
import { useApi, useLanguage } from '@brainstud/academy-api';
import { Button, Input, SidebarModal } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { Form } from 'Modules/universal-components';
import { useModals, useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import styles from './LanguageFormModal.module.css';

const cx = classNames.bind(styles);

/**
 * Modal to create or modify a language in the platform.
 */
export const LanguageFormModal = ({ languageId }: { languageId: string }) => {
  const [t] = useTranslator();
  const [setToast] = useToaster();
  const { closeModal } = useModals();
  const { invalidateQueries } = useApi();

  const [{ data: language, createOrUpdate }] = useLanguage({
    language: languageId,
  });

  const baseTranslationKey = `views.languages.actions.${language ? 'update' : 'create'}`;

  const handleSubmit = useCallback(
    (values: { label: string; locale: string }) => {
      createOrUpdate.mutateAsync(
        {
          ...values,
          ...(language ? { locale: undefined } : {}),
        },
        {
          onSuccess: () => {
            setToast(t(`${baseTranslationKey}.success`), 'success');
            invalidateQueries(['v1.languages']);
            closeModal();
          },
          onError: () => {
            setToast(t(`${baseTranslationKey}.error`), 'error');
          },
        }
      );
    },
    [
      baseTranslationKey,
      closeModal,
      createOrUpdate,
      invalidateQueries,
      language,
      setToast,
      t,
    ]
  );

  return (
    <SidebarModal
      size="medium"
      onClickOutside
      onClose={closeModal}
      className={cx(styles.base)}
    >
      <SidebarModal.Header>
        <div className={cx(styles.header)}>
          <h1>{t(`${baseTranslationKey}.title`)}</h1>
          <div>
            <Button
              type="submit"
              loading={createOrUpdate.isLoading}
              form="create_language"
            >
              {t(`${baseTranslationKey}.submit`)}
            </Button>
          </div>
        </div>
      </SidebarModal.Header>
      <div className={cx(styles.gap)}>
        <span>{t(`${baseTranslationKey}.description`)}</span>
        <Form id="create_language" onSubmit={handleSubmit}>
          <div className={cx(styles.gap)}>
            <Input
              rules={['required']}
              type="text"
              label={t('label')}
              name="label"
              defaultValue={language?.label}
            />
            <Input
              rules={['required', 'min:2']}
              type="text"
              label={t('locale')}
              name="locale"
              disabled={!!language}
              defaultValue={language?.locale}
            />
          </div>
        </Form>
      </div>
    </SidebarModal>
  );
};
