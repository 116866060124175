import { useMemo } from 'react';
import { useAnswer } from '@brainstud/academy-api';
import { Callout, Panel } from '@brainstud/ui';
import { Container, Loading } from 'Components';
import { useTranslator } from 'Providers/Translator';
import { useAnswerReview } from '../../../Providers';
import { withContentErrorBoundary } from '../../Errors/withContentErrorBoundary';
import styles from './ReviewSkillsView.module.css';

export const ReviewSkillsView = withContentErrorBoundary(() => {
  const [t] = useTranslator();
  const { answerId } = useAnswerReview() || {};
  const [{ data: answer }, { isLoading }] = useAnswer({
    answer: answerId,
  });

  const skills = useMemo(
    () => [
      ...(answer?.learningObjectVariety?.().dossierWorkprocessBehaviours?.() ||
        []),
      ...(answer?.learningObjectVariety?.().dossierKnowledgeSkills?.() || []),
    ],
    [answer]
  );

  return isLoading ? (
    <Loading />
  ) : (
    <Container className={styles.base}>
      {skills?.length ? (
        <Panel className={styles.panel}>
          <h4>{t('review.skills.title')}</h4>
          <ul>
            {skills.map((skill) => (
              <li key={skill.id}>{skill.description}</li>
            ))}
          </ul>
        </Panel>
      ) : (
        <Callout info>{t('review.noSkills')}</Callout>
      )}
    </Container>
  );
});
