/* eslint-disable no-param-reassign */
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { DndProviderWithBackend } from './DndProviderWithBackend';
import { DragAndDropContext, IDragAndDropContext } from './DragAndDropContext';
import { DragLayer } from './DragLayer';
import { ListReducerActions } from './ListReducerActions';
import { useListReducer } from './useListsReducer';

type Props = {
  children?: ReactNode;
};

/**
 * Instantiates the DragAndDrop Provider that keeps track (via lists) of which item has been dragged to which droparea.
 */
export const DragAndDrop = ({ children }: Props) => {
  const [{ lists: list }, dispatch] = useListReducer();
  const createList = useCallback<IDragAndDropContext['createList']>(
    (name) => {
      dispatch({
        type: ListReducerActions.CREATE_LIST,
        payload: { listName: name },
      });
    },
    [dispatch]
  );

  const deleteList = useCallback<IDragAndDropContext['deleteList']>(
    (name) => {
      dispatch({
        type: ListReducerActions.REMOVE_LIST,
        payload: { listName: name },
      });
    },
    [dispatch]
  );

  const addToList = useCallback<IDragAndDropContext['addToList']>(
    (name, item) => {
      if (name) {
        dispatch({
          type: ListReducerActions.ADD_TO_LIST,
          payload: { listName: name, item },
        });
      }
    },
    [dispatch]
  );

  const findInLists = useCallback<IDragAndDropContext['findInLists']>(
    (dragItem, lists) =>
      Object.keys(lists).filter((name) =>
        lists[name].some((item) => item.identifier === dragItem.identifier)
      ),
    []
  );

  const moveToList = useCallback<IDragAndDropContext['moveToList']>(
    (listName, item) => {
      if (listName) {
        dispatch({
          type: ListReducerActions.MOVE_ITEM,
          payload: {
            toList: listName,
            item,
          },
        });
      }
    },
    [dispatch]
  );

  const removeFromList = useCallback<IDragAndDropContext['removeFromList']>(
    (name, item) => {
      dispatch({
        type: ListReducerActions.REMOVE_FROM_LIST,
        payload: { listName: name, item },
      });
    },
    [dispatch]
  );

  const removeFromAllLists = useCallback<
    IDragAndDropContext['removeFromAllLists']
  >(
    (item) => {
      dispatch({
        type: ListReducerActions.REMOVE_FROM_ALL_LIST,
        payload: { item },
      });
    },
    [dispatch]
  );

  const resetLists = useCallback(() => {
    dispatch({ type: ListReducerActions.RESET });
  }, [dispatch]);

  const state = useMemo(
    () => ({
      list,
      createList,
      moveToList,
      deleteList,
      addToList,
      findInLists,
      removeFromList,
      removeFromAllLists,
    }),
    [
      list,
      createList,
      moveToList,
      deleteList,
      addToList,
      findInLists,
      removeFromList,
      removeFromAllLists,
    ]
  );

  const inputElement = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (!inputElement.current) return;
    const element = inputElement.current.form;
    element?.addEventListener('reset', resetLists);
    return () => {
      element?.removeEventListener('reset', resetLists);
    };
  }, [inputElement, resetLists]);

  return (
    <DragAndDropContext.Provider value={state}>
      <input type="hidden" ref={inputElement} />
      <DndProviderWithBackend>
        {children}
        <DragLayer />
      </DndProviderWithBackend>
    </DragAndDropContext.Provider>
  );
};
