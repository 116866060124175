import { DateTimeString } from '../Utils/DateTimeString';
import { IResource } from '../Utils/Resource';

export interface ExamUpload extends IResource<'exam_uploads'> {
  fileName: string;
  fileMimeType: string;
  contentAccessUrl: string;
  createdAt: DateTimeString;
}

export interface ExamUploadCreateInput {
  file_name?: string;
  files: File[];
}

export interface ExamUploadUpdateInput extends ExamUploadCreateInput {}

export enum ExamUploadIncludes {}

export enum ExamUploadFilters {}

export enum ExamUploadSorts {}
