import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useApi } from '@brainstud/academy-api';
import { IImageBlock } from '@brainstud/course-blocks';
import { Button, Input, Textarea, UploadBox } from '@brainstud/ui';
import { useTranslator } from 'Providers/Translator';
import { v4 as uuid } from 'uuid';
import styles from './RichImageForm.module.css';

export type RichImageFormProps = {
  data: Partial<IImageBlock>;
  onChange: (e: Partial<IImageBlock>) => void;
};

export const RichImageForm = ({ data, onChange }: RichImageFormProps) => {
  const { baseUrl, headers: apiHeaders } = useApi();
  const [t] = useTranslator();
  const image = data?.images?.[0];
  const [id] = useState(image?.id || uuid());
  const [url, setUrl] = useState(image?.src);
  const [fileName, setFileName] = useState<undefined | string>();
  const [alt, setAlt] = useState(image?.alt);
  const [source, setSource] = useState(image?.source);

  const handleFileUploaded = useCallback((file: any, response: any) => {
    setUrl(response?.data?.[0]?.attributes?.file_url);
    setFileName(file.name);
  }, []);

  useEffect(() => {
    if (url) {
      onChange({
        caption: '',
        images: [
          {
            id,
            src: url,
            alt,
            source,
          },
        ],
      });
    }
  }, [id, url, source, alt]);

  return (
    <div className={styles.row}>
      <div>
        {!url ? (
          <>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>
              {t('course-editor.content_editor.blocks.rich_image.upload_image')}
            </label>
            <UploadBox
              url={`${baseUrl}/v1/services/media_upload`}
              headers={{ ...apiHeaders, 'Content-Type': undefined }}
              paramName="files[0]"
              maxFiles={1}
              onAfterFileUpload={handleFileUploaded}
            />
          </>
        ) : (
          <>
            {fileName && (
              <p>
                <strong>{fileName}</strong>
              </p>
            )}
            {url && (
              <img
                src={url}
                style={{ maxHeight: 200, objectFit: 'cover' }}
                alt={data?.images?.[0]?.alt || ''}
              />
            )}
            <Button type="button" quiet onClick={() => setUrl(undefined)}>
              {t('course-editor.content_editor.blocks.rich_image.delete_image')}
            </Button>
          </>
        )}
      </div>
      <div>
        <Input
          label={t('course-editor.content_editor.blocks.rich_image.source')}
          onChange={(event) => setSource(event.target.value)}
          value={data?.images?.[0]?.source || ''}
        />
        <Textarea
          label={t(
            'course-editor.content_editor.blocks.rich_image.description'
          )}
          className={styles.description}
          value={data?.images?.[0]?.alt}
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
            setAlt(event.target.value)
          }
        />
      </div>
    </div>
  );
};
