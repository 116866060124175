import React from 'react';
import classNames from 'classnames/bind';
import styles from './FlexContainer.module.css';

const cx = classNames.bind(styles);

export type FlexContainerProps = {
  /**
   * Optional indicator to set the flex direaction to row. Without this indicator
   * the flex-direction defaults to row.
   */
  row?: boolean;
  /**
   * @deprecated 25-05-2023
   * Set the direction for the flex container. Defaults to column.
   */
  direction?: 'column' | 'row';
  /* Set the gapsize between items. Defaults to medium size. */
  gapSize?: 'small' | 'medium' | 'large';
  /* Choose the alignment for the items */
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | '';
  /* Align the items vertically */
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | '';
  /** Indicates that the content should be wrapped */
  wrap?: boolean;
  className?: string;
  children: React.ReactNode;
};

/**
 * This component was created to make the gapsize uniform across pages by utilizing the flexbox CSS system
 * and reducing the amount of places where the same flexbox configuration is used in the CSS.
 *
 * @deprecated Please, simply use html elements with a class.
 */
export const FlexContainer = ({
  row,
  direction = 'column',
  gapSize = 'medium',
  justifyContent = '',
  alignItems = '',
  wrap,
  className,
  children,
}: FlexContainerProps) => (
  <div
    className={cx(
      styles.base,
      className,
      styles[direction],
      styles[gapSize],
      styles[`justify-${justifyContent}`],
      styles[`align-${alignItems}`],
      {
        row,
        wrap,
      }
    )}
  >
    {children}
  </div>
);
