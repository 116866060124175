import { useMutation, UseMutationOptions } from 'react-query';
import queryClient from '../Providers/ApiProvider/QueryClient';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import { IResource } from '../Types';

export default function useCreate<
  Resource extends IResource | undefined,
  CreateInput,
  ErrorOutput extends ErrorDocument = ErrorDocument,
>(
  request: ApiRequest,
  queryConfig?: UseMutationOptions<
    DataDocument<Resource>,
    ErrorOutput,
    CreateInput,
    any
  >
) {
  return useMutation<DataDocument<Resource>, ErrorOutput, CreateInput, any>(
    request.create.bind(request),
    {
      mutationKey: request.getKey('create') || '',
      onSuccess: () =>
        Promise.all<void>([
          queryClient.invalidateQueries([
            {
              [request.baseName]: true,
            },
          ]),
          ...request.invalidate.map((invalidKey) =>
            queryClient.invalidateQueries([{ [invalidKey]: true }])
          ),
        ]).then(),
      ...queryConfig,
    }
  );
}
