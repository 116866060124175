/**
 * isPromise.
 *
 * Simple check to see if a variable is a promise.
 *
 * @param input
 * @returns {input is PromiseLike<unknown>}
 */
export function isPromise<O = unknown>(
  input: unknown
): input is PromiseLike<O> {
  return (
    !!input && typeof input === 'object' && input !== null && 'then' in input
  );
}
