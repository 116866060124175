import { DateTimeString } from '../Utils/DateTimeString';
import { IResource } from '../Utils/Resource';

export interface AnswerUpload extends IResource<'answer_uploads'> {
  fileNameOriginal: string;
  fileSize: number;
  fileMimeType: string;
  contentAccessUrl: string;
  previewUrl?: string;
  createdAt: DateTimeString;
  updatedAt: DateTimeString;
}

export interface AnswerUploadCreateInput {
  file_name_original: string;
  files: File[];
}

export interface AnswerUploadUpdateInput
  extends Partial<AnswerUploadCreateInput> {}

export enum AnswerUploadIncludes {}

export enum AnswerUploadSorts {}

export interface AnswerUploadFilters {}
