import type { Reducer } from 'react';

interface Action<T = any> {
  type: T;
  payload?: unknown;
}

type Handlers<State, Types extends string, Actions extends Action<Types>> = {
  readonly [Type in Types]: (
    action: Extract<Actions, Action<Type>>,
    state: State
  ) => null | Partial<State>;
};

/**
 * createReducer.
 *
 * With this method, you can easily create a typesafe reducer to use in `useReducer`.
 *
 * Example usage:
 *
 * ```
 * createReducer<STATE, ACTIONS>({
 *   ACTION: (action, state) => {
 *     return { ... action.payload } // <- automatically combined with existing- state
 *   }
 * })
 * ```
 */
export const createReactReducer = <
  State extends {},
  Actions extends Action<Types>,
  Types extends string = Actions['type'],
>(
  handlers: Handlers<State, Types, Actions>,
  logName?: string
): Reducer<State, Actions> => {
  if (!handlers) {
    throw new Error('You need to provide a set of handlers');
  }
  return function reducer(state, action: Actions) {
    if (action.type in handlers) {
      if (logName) {
        // eslint-disable-next-line no-console
        console.log(`[${logName}:${action.type}]`, action.payload);
      }
      const result = handlers[action.type as Types](
        action as Extract<Actions, Action<Types>>,
        state as State
      );

      return result !== null
        ? {
            ...state,
            ...result,
          }
        : state;
    }
    return state;
  };
};
