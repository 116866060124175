import { Product } from '@brainstud/academy-api/Types/Resources/Product';
import { IResource } from '../Utils/Resource';
import { UUID } from '../Utils/UUID';
import type { Plan } from './Plan';

export interface SubscriptionOption extends IResource<'subscription_options'> {
  name: string;
  description: string;
  duration: string;
  plan?: () => Plan;
  products?: () => Product[];
}

export interface SubscriptionOptionCreateInput {
  name: string;
  description?: string;
  duration: string;
  relationships?: {
    product?: UUID;
    plan?: UUID;
  };
}

export type SubscriptionOptionUpdateInput = Omit<
  SubscriptionOptionCreateInput,
  'product' | 'plan'
>;

export enum SubscriptionOptionIncludes {
  plan,
  'plan.products',
  products,
}
export interface SubscriptionOptionFilters {
  product_type: string;
}
export enum SubscriptionOptionSorts {}
