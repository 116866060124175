import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IBlock } from '@brainstud/course-blocks';
import { Button } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { useLearningRouteProvider } from 'Providers/LearningRouteProvider/useLearningRouteProvider';
import { useTranslator } from 'Providers/Translator';
import { v4 } from 'uuid';
import { ToolObject } from '../../ToolArray';
import { useContentEditor } from './Provider/useContentEditor';
import { EditBlockRenderer, PreviewBlockRenderer } from './Renderers';
import styles from './ContentEditor.module.css';

const cx = classNames.bind(styles);

type TProps = {
  containerId?: string;
  containerType?: string;
  blocks?: IBlock[];
  children?: (block: IBlock, component: ReactNode, idx?: number) => ReactNode;
  className?: string;
};

export const ContentEditor = ({
  containerId,
  containerType,
  blocks,
  children,
  className,
}: TProps) => {
  const { isLive } = useLearningRouteProvider();
  const isProduction = process.env.NEXT_PUBLIC_APP_ENV === 'production';
  const {
    blocks: defaultBlocks,
    setActiveEditor,
    activeEditor,
    activeBlock,
    setActiveBlock,
    isLoading,
    previewMode,
    handleOpenBlockModal,
  } = useContentEditor();
  const actualBlocks = useMemo(
    () => [...(blocks || defaultBlocks || [])],
    [blocks, defaultBlocks]
  );
  const isPrimary = !containerId;
  const isEditable = !isProduction || (!previewMode && !isLive);

  const [id] = useState(v4());
  const handleActivation = useCallback(
    (e: FocusEvent) => {
      if (setActiveEditor) {
        e.stopPropagation();
        setActiveEditor(id);
      }
    },
    [id]
  );
  const handleDeactivation = useCallback(
    (e: FocusEvent) => {
      e.stopPropagation();
      setActiveEditor((prevState) => (prevState === id ? null : prevState));
    },
    [id]
  );

  const handleSubmit = useCallback(() => {
    setActiveBlock(null);
  }, []);

  const [t] = useTranslator();

  const container = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (container.current) {
      const element = container.current;
      element.addEventListener('focusin', handleActivation);
      element.addEventListener('focusout', handleDeactivation);
      element.dispatchEvent(new Event('focusin'));
      return () => {
        element.removeEventListener('focusin', handleActivation);
        element.removeEventListener('focusout', handleDeactivation);
      };
    }
  }, [container]);
  const isActive = activeEditor === id || (!containerId && !activeEditor);

  const blockTitle = t(
    `course-editor.content_editor.blocks.${containerType}.name`,
    {},
    null
  );

  const handleAddBlocks = useCallback(() => {
    handleOpenBlockModal(containerId);
  }, [containerId, handleOpenBlockModal]);

  return (
    <div
      className={cx(
        styles.base,
        { isActive, isPrimary, isInner: !isPrimary },
        'is-editing',
        className
      )}
      ref={container}
    >
      {actualBlocks.map((block, idx) => {
        const tool = ToolObject[block.type];
        const RenderComponent =
          (block.id === activeBlock || !!tool?.isEditable) && !previewMode ? (
            <EditBlockRenderer
              index={idx}
              data={block}
              onSubmit={handleSubmit}
              blocks={actualBlocks}
            />
          ) : (
            <PreviewBlockRenderer
              index={idx}
              block={block}
              blocks={actualBlocks}
              editable={!previewMode}
              onEdit={() => setActiveBlock(block.id)}
            />
          );
        return children ? (
          children(block, RenderComponent, idx)
        ) : (
          <div key={block.id} className={cx(styles.block)}>
            {RenderComponent}
          </div>
        );
      })}
      {isEditable && (
        <div className={cx(styles.toolbar, { isHidden: !isActive })}>
          <div className={cx(styles.blockBar)}>
            <Button
              type="button"
              block={isPrimary}
              round={!isPrimary}
              loading={isLoading}
              className={cx(styles.blockBarButton, { isSmall: !isPrimary })}
              onClick={handleAddBlocks}
              title={t('course-editor.content_editor.open_block', {
                to: blockTitle
                  ? ` ${t('course-editor.content_editor.to')} ${blockTitle}`
                  : '',
              })}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="currentColor"
              >
                <path d="M18,13h-5v5c0,0.55-0.45,1-1,1l0,0c-0.55,0-1-0.45-1-1v-5H6c-0.55,0-1-0.45-1-1l0,0c0-0.55,0.45-1,1-1h5V6 c0-0.55,0.45-1,1-1l0,0c0.55,0,1,0.45,1,1v5h5c0.55,0,1,0.45,1,1l0,0C19,12.55,18.55,13,18,13z" />
              </svg>
              {containerType && blockTitle !== 'null' && (
                <span className={styles.type}>{blockTitle}</span>
              )}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
