import React, { useCallback, useEffect, useState } from 'react';
import { Course } from '@brainstud/academy-api';
import { Callout } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { Container } from 'Components';
import { useCourseList } from 'Modules/coach-panel/Providers';
import { useTranslator } from 'Providers/Translator';
import { CourseInfoPanel } from '../CourseInfoPanel';
import CourseListItem from './CourseListItem';
import styles from './CourseListItem.module.css';

const cx = classNames.bind(styles);

type TProps = {
  onCourseClick?: (course: Course) => void;
};

export const CourseList = ({ onCourseClick }: TProps) => {
  const { courses } = useCourseList();
  const [t] = useTranslator();
  const [openListItem, setOpenListItem] = useState<string | number>();

  useEffect(() => {
    if (!openListItem) return;
    document.getElementById(`row-${openListItem}`)?.scrollIntoView();
  }, [openListItem]);

  const toggleCourseInfoPanelOnRow = useCallback(
    (courseId?: string | number) => {
      if (openListItem === courseId) {
        setOpenListItem(undefined);
      } else {
        setOpenListItem(courseId);
      }
    },
    [openListItem]
  );

  const handleCourseClick = useCallback(
    (course: Course) => {
      if (onCourseClick) {
        onCourseClick(course);
      } else {
        toggleCourseInfoPanelOnRow(course.id);
      }
    },
    [onCourseClick, toggleCourseInfoPanelOnRow]
  );

  return (
    <div>
      {courses?.length === 0 && (
        <Container>
          <Callout info>{t('components.catalog.no_results')}</Callout>
        </Container>
      )}
      {courses?.map((course) => (
        <React.Fragment key={course.id}>
          <Container className={cx(styles.unpadded)}>
            <button
              type="button"
              className={cx('item', { open: course.id === openListItem })}
              onClick={() => handleCourseClick(course)}
              id={`row-${course.id}`}
            >
              <CourseListItem courseId={course.id} />
            </button>
          </Container>

          {openListItem === course.id && (
            <CourseInfoPanel
              className={cx(styles.infoPanel)}
              course={course.id}
              onClose={() => handleCourseClick(course)}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};
