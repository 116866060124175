import { DateTimeString } from '../Utils/DateTimeString';
import { IResource } from '../Utils/Resource';
import { UUID } from '../Utils/UUID';
import { Account } from './Account';
import { Course } from './Course';
import { LearningObjectCollection } from './LearningObjectCollection';
import { Portal } from './Portal';

export interface CoachMandate extends IResource<'coach_mandates'> {
  mandateDate: DateTimeString;
  unseenAnswersCount?: number;
  coach?: () => Account;
  course?: () => Course;
  portal?: () => Portal;
  studentAccount?: () => Account;
  learningObjectCollection?: () => LearningObjectCollection;
}

export interface CoachMandateCreateInput {
  coach: string;
  enrollment?: string;
}

export interface CoachMandateUpdateInput extends CoachMandateCreateInput {}

export interface CoachMandateBulkDestroyInput {
  mandates: UUID[];
}

export enum CoachMandateIncludes {
  'course',
  'account',
  'coach_account',
  'portal',
  'learning_object_collection',
}

export enum CoachMandateFilters {}

export enum CoachMandateSorts {}
