import React, { ChangeEvent, CSSProperties } from 'react';
import classNames from 'classnames/bind';
import styles from './Checkmark.module.css';

const classes = classNames.bind(styles);

type Props = {
  checked?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  style?: CSSProperties;
  className?: string;
};

/**
 * Checkmark.
 *
 * Simple checkbox component with styling and CSS properties that can modify the (clickable area) size. The
 * component will _stop propagation_, so it can be safely used within a button or link.
 *
 * __Note: This component is not connected to the Form element.__
 */
export const Checkmark = ({ checked, onChange, className, style }: Props) => (
  // eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
  <label
    className={classes(styles.base, className)}
    style={style}
    onClick={(event) => event.stopPropagation()}
  >
    <div className={classes(styles.square, 'ui-checkmark__square', className)}>
      <input
        type="checkbox"
        onChange={onChange}
        checked={checked}
        className={styles.input}
      />
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.icon}
      >
        <path
          fill="currentColor"
          className={classes('square-checkmark')}
          d="M3.2,7.69L4.21,6.68L6.363,8.833L11.796,3.4L12.8,4.41L6.363,10.847L3.2,7.69Z"
        />
      </svg>
    </div>
  </label>
);
