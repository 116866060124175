import React, { FC, useCallback, useEffect, useState } from 'react';
import { RichTextEditor } from 'Components';
import { useTranslator } from 'Providers/Translator';
import { ReactComponent as CorrectAnswerIcon } from '../../Assets/svgs/correct_answer.svg';
import { ReactComponent as WrongAnswerIcon } from '../../Assets/svgs/wrong_answer.svg';
import styles from './AnswerFeedback.module.css';

type Props = {
  feedback?: {
    correct: string;
    incorrect: string;
    hint?: string;
  };
  onChange: (feedback: { correct: string; incorrect: string }) => void;
};

/**
 * Renders two rich text fields with 'correct' and 'incorrect' feedback messages. It will
 * pass on those values to the onChange handler.
 */
export const AnswerFeedback: FC<Props> = ({
  feedback: initialFeedback,
  onChange,
}) => {
  const [t] = useTranslator();
  const [feedback, setFeedback] = useState(
    initialFeedback || {
      correct: '',
      incorrect: '',
    }
  );
  const handleChange = useCallback(
    (type: 'correct' | 'incorrect', content: string) => {
      setFeedback((prevFeedback) => ({
        ...prevFeedback,
        [type]: content,
      }));
    },
    []
  );

  useEffect(() => {
    onChange?.(feedback);
  }, [feedback]);

  const handleCorrectFeedback = useCallback(
    (value: string) => handleChange('correct', value),
    [handleChange]
  );
  const handleIncorrectFeedback = useCallback(
    (value: string) => handleChange('incorrect', value),
    [handleChange]
  );

  return (
    <div className={styles.base}>
      <div className={styles.feedbackBox}>
        <h4>
          <span>{t('course-editor.content_editor.feedback.incorrect')}</span>
          <WrongAnswerIcon />
        </h4>
        <RichTextEditor
          defaultValue={feedback?.incorrect}
          onChange={handleIncorrectFeedback}
        />
      </div>
      <div className={styles.feedbackBox}>
        <h4>
          <span>{t('course-editor.content_editor.feedback.correct')}</span>
          <CorrectAnswerIcon />
        </h4>
        <RichTextEditor
          defaultValue={feedback?.correct}
          onChange={handleCorrectFeedback}
        />
      </div>
    </div>
  );
};
