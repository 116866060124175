import React from 'react';
import { useBlock } from 'Hooks/useBlock';
import { sanitizer } from 'Utils/Sanitizer';
import { IPageIntroductionBlock } from './Types';
import styles from './PageIntroductionBlock.module.css';

export const PageIntroductionBlock = ({
  id,
  content,
}: Omit<IPageIntroductionBlock, 'type'>) => {
  useBlock({
    id,
  });
  return (
    <div className={styles.base}>
      <div dangerouslySetInnerHTML={{ __html: sanitizer(content) }} />
    </div>
  );
};
