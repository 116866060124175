import { IResource } from '../Utils/Resource';
import { LtiToolProvider } from './LtiToolProvider';

export interface LtiResourceLink extends IResource<'lti_resource_links'> {
  launchUrl: string;
  loginUrl: string;
  secret: string;

  ltiToolProvider: () => LtiToolProvider;
  content?: () => string[];
  links: {
    /** Launch the LTI course */
    launchResource: {
      href: string;
    };
    /** Used to authenticate a launch */
    authenticateResource: {
      href: string;
    };
    /** Keyset encryption */
    keyset: {
      href: string;
    };
    /** The outcome of the launch */
    outcome: {
      href: string;
    };
  };
}

export interface LtiResourceLinkCreateInput {}

export interface LtiResourceLinkUpdateInput extends LtiResourceLinkCreateInput {
  launchUrl?: string;
  loginUrl?: string;
  secret?: string;

  relationships: {
    ltiToolProvider: string;
    course?: string;
    learningObject?: string;
  };
}

export enum LtiResourceLinkIncludes {}

export enum LtiResourceLinkFilters {}

export enum LtiResourceLinkSorts {}
