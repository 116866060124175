import React from 'react';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import { useModal } from '../useModal';
import { MediaContentModalProps } from './MediaContentModalProps';
import styles from './MediaContentModal.module.css';

const cx = classNames.bind(styles);

/**
 * A modal that can be used to show media content
 */
export const MediaContentModal = ({
  onKeyUp,
  src,
  description,
  source,
  className,
  style,
  onClose,
  closeable = true,
  closeModal,
  onClickOutside = true,
}: MediaContentModalProps) => {
  const {
    handleClose,
    ref: modalElement,
    hasCloseButton,
    animate,
  } = useModal({
    onClose,
    onKeyUp,
    closeable,
    closeModal,
    onClickOutside,
  });

  const [t] = useTranslator();

  return (
    <div
      className={cx(styles.base, { 'animate-in': animate }, className)}
      ref={modalElement}
      style={style}
    >
      {hasCloseButton && (
        <button type="button" className={cx('btn-close')} onClick={handleClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path fill="none" d="M0 0h24v24H0V0z" />
            <path
              className={cx('cross')}
              d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"
            />
          </svg>
        </button>
      )}
      {source && (
        <div className={cx(styles.source)}>
          {t('modals.media_content_modal.source')}
          {': '}
          {source}
        </div>
      )}
      <figure>
        <img src={src} alt={description} />
        {description && (
          <figcaption className={cx(styles.description)}>
            {t('modals.media_content_modal.description')}
            {': '}
            {description}
          </figcaption>
        )}
      </figure>
    </div>
  );
};
