import { PropsWithChildren, useMemo, useState } from 'react';
import { Answer } from '@brainstud/academy-api';
import { useAnswerReview } from 'Modules/coach-panel/Providers';
import { useLearningObjectProvider } from 'Providers';
import { AnswerContext, IAnswerContext } from './AnswerContext';

export const AnswerProvider = ({ children }: PropsWithChildren<{}>) => {
  const { answer: reviewAnswer } = useAnswerReview() || {};
  const [savedAnswer, setSavedAnswer] = useState<Answer>();
  const [answerIndex, setAnswerIndex] = useState<number>(0);
  const { learningObject } = useLearningObjectProvider();
  const answers = learningObject?.answers?.();
  const [selectedAnswerId, setSelectedAnswerId] = useState<string>();
  const lastAnswer = learningObject?.lastAnswer?.();

  const currentAnswer = useMemo(
    () =>
      answers?.find((item) => item.id === selectedAnswerId) ||
      savedAnswer ||
      reviewAnswer ||
      (answers && answers.length > 0 ? answers[answerIndex] : undefined),
    [savedAnswer, reviewAnswer, answers, answerIndex, selectedAnswerId]
  );

  const context = useMemo<IAnswerContext>(
    () => ({
      currentAnswer,
      setSavedAnswer,
      setAnswerIndex,
      answerIndex,
      setSelectedAnswerId,
      answers: answers || [],
      lastAnswer,
    }),
    [
      currentAnswer,
      lastAnswer,
      answers,
      setSavedAnswer,
      setAnswerIndex,
      answerIndex,
    ]
  );

  return (
    <AnswerContext.Provider value={context}>{children}</AnswerContext.Provider>
  );
};
