import { ReactElement } from 'react';
import classNames from 'classnames/bind';
import { LearningObjectStatus } from 'Modules/action-based-learning-panel/Components';
import styles from './LearningCard.module.css';

const cx = classNames.bind(styles);

export type TLearningCardProps = {
  /** Image to be displayed as thumbnail */
  posterUrl?: string;
  /** Image to be displayed as a fallback when no thumbnail is available */
  fallbackImage?: ReactElement;
  /** Card title */
  title: string;
  /** Component which is rendered beneath the header. It will scale the header accordingly. */
  subheader?: ReactElement | ReactElement[];
  /** Status to be displayed */
  status: TProgressState;
  /** Space which can be used to overlay the left part of the header (thumbnail) with React component(s) */
  leftOverlay?: ReactElement | ReactElement[];
  /** Space which can be used to overlay the right part of the header (thumbnail) with React component(s) */
  rightOverlay?: ReactElement | ReactElement[];
};

export type TProgressState = { state: TStatus; label: string };

const VALID_PROGRESS_STATUSSES = [
  'NONE',
  'SUCCESS',
  'WARNING',
  'ERROR',
  'LOCKED',
  'NFC_RESTRICTED',
] as const;
export type TStatus = (typeof VALID_PROGRESS_STATUSSES)[number];

/**
 * LearningCard.
 *
 * This is a generic card which can be used for displaying information in
 * the same way for Object/Subject/Quiz cards
 */
export const LearningCard = ({
  posterUrl,
  fallbackImage,
  title,
  subheader,
  status,
  leftOverlay,
  rightOverlay,
}: TLearningCardProps) => {
  const isLocked = status.state === 'LOCKED';

  return (
    <div className={cx(styles.base, { isLocked })}>
      <div className={cx(styles.content)}>
        <div className={cx(styles.header)}>
          <div
            className={cx(styles.posterWrapper, {
              [styles.fallback]: !posterUrl,
            })}
          >
            {posterUrl ? (
              <img src={posterUrl} alt={title} />
            ) : (
              <div className={styles.fallbackImage}>{fallbackImage}</div>
            )}
            {leftOverlay && (
              <div className={cx(styles.leftOverlay)}>{leftOverlay}</div>
            )}
            {rightOverlay && (
              <div className={cx(styles.rightOverlay)}>{rightOverlay}</div>
            )}
            <div className={cx(styles.progress, status.state.toLowerCase())}>
              <LearningObjectStatus
                status={status.state}
                label={status.label}
              />
            </div>
          </div>
          {subheader && <div className={cx(styles.subheader)}>{subheader}</div>}
        </div>
        <div className={cx(styles.title)}>{title}</div>
      </div>
    </div>
  );
};
