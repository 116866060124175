import React, { useMemo } from 'react';
import { StudentEnrollment, UUID } from '@brainstud/academy-api';
import { Progress, Status, Td, Tr } from '@brainstud/ui';
import { Timestamp } from '@brainstud/ui/Static/Timestamp';
import { SupervisorAccount } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { CompletedBadge } from 'Components';
import { useAccessProvider } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { toDecimals } from 'Utils';
import { StatusColors } from '../../../Utils';
import { Badge } from '../../Badge';
import styles from './StudentEnrollmentRow.module.css';

const cx = classNames.bind(styles);

type Props = {
  enrollment: StudentEnrollment;
  studentId: UUID;
};

const StatusSteps = Object.keys(StatusColors);

export const StudentEnrollmentRow = ({ enrollment, studentId }: Props) => {
  const [t] = useTranslator();
  const { hasAccess } = useAccessProvider();
  const currentStep = enrollment?.meta?.exam?.currentStep;
  const canViewExam =
    hasAccess &&
    enrollment.examStep &&
    StatusSteps.includes(enrollment.examStep);

  const statusLink = useMemo(
    () =>
      canViewExam
        ? `/coach/students/${studentId}/enrollment/${enrollment.id}/exam`
        : enrollment?.isCompleted
          ? `/coach/students/${studentId}/enrollment/${enrollment.id}/portfolio`
          : undefined,
    [canViewExam, enrollment.id, enrollment?.isCompleted, studentId]
  );

  const coach = enrollment.coach?.();
  const lastOnline = enrollment.activeAt;

  return (
    <Tr className={cx(styles.base, { disabled: !hasAccess })}>
      <Td
        className={cx(styles.link)}
        to={
          hasAccess
            ? `/coach/students/${studentId}/enrollment/${enrollment.id}/portfolio`
            : undefined
        }
      >
        <div className={cx(styles.title)}>
          <div>
            {enrollment.openAnswerCount > 0 && (
              <Badge color="red" circle className={cx(styles.badge)}>
                {enrollment.openAnswerCount}
              </Badge>
            )}
            <strong>{enrollment.course?.().title}</strong>
          </div>
          <div className={cx(styles.progress)}>
            <Progress
              value={enrollment.currentXp}
              max={enrollment.totalXp}
              label={
                <span>
                  <strong>{toDecimals(enrollment.currentXp, 1)}</strong>
                  {` / ${toDecimals(enrollment.totalXp, 1)}XP`}
                </span>
              }
            />
          </div>
        </div>
      </Td>
      <Td to={statusLink} className={cx(styles.status)} collapse>
        <>
          {enrollment?.isCompleted ? (
            <CompletedBadge label={t('states.completed')} />
          ) : (
            enrollment.examStep &&
            StatusSteps.includes(enrollment.examStep) && (
              <Status
                className={cx(styles.status, {
                  'unseen-exam-status': !enrollment?.meta?.exam?.coachHasSeenAt,
                })}
                scheme={StatusColors[currentStep || 'OPEN']}
              >
                {t(`exam.status.${currentStep || 'UNKNOWN'}`)}
              </Status>
            )
          )}
        </>
      </Td>
      <Td className={cx(styles.coach)}>
        {coach ? (
          <div>
            <SupervisorAccount />
            <span>{coach.fullName}</span>
          </div>
        ) : (
          '-'
        )}
      </Td>
      <Td collapse>
        <Timestamp timestamp={lastOnline} />
      </Td>
    </Tr>
  );
};
