import { Course } from '@brainstud/academy-api';
import dateDifference from './dateDifference';

const SIX_MONTHS = 6 * 30 * 24 * 3600;

export const isNew = (course: Course) =>
  dateDifference(new Date(), course.createdAt) < SIX_MONTHS;

export const educationProfilesTitles = (course: Course) =>
  course.educationProfiles?.().map((profile) => profile.title);

export const isCompleted = (course: Course) =>
  course.enrollment?.().isCompleted;
