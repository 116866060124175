import { Course } from '@brainstud/academy-api';
import {
  ActionMenu,
  Indicator,
  Status,
  Table,
  Td,
  Th,
  Tr,
} from '@brainstud/ui';
import {
  Add,
  Archive,
  DeleteForever,
  Edit,
  Lock,
  MenuBook,
} from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Link } from 'Components';
import { CourseChangeStatusModal } from 'Modules/admin-panel/Modals';
import { StatusColors } from 'Modules/admin-panel/Utils';
import { IPaginationContext } from 'Modules/universal-components';
import { useModals } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { DateFormatter } from 'Utils/DateFormatHelper';
import styles from './TableLayout.module.css';

const cx = classNames.bind(styles);

type Props = {
  onSort: (term: IPaginationContext['sort']) => void;
  isFetching: boolean;
  courses: Course[];
};

export const TableLayout = ({ onSort, isFetching, courses }: Props) => {
  const [t] = useTranslator();
  const { openModal } = useModals();

  return (
    <Table
      className={cx(styles.base)}
      onSort={(type) => onSort(type as IPaginationContext['sort'])}
    >
      <Table.THead>
        <Tr>
          <Th>{isFetching && <Indicator loading />}</Th>
          <Th sortOn="title">
            {t('views.courses.coursesIndex.layouts.tableLayout.headers.title')}
          </Th>
          <Th sortOn="created_at">
            {t('views.courses.coursesIndex.layouts.tableLayout.headers.date')}
          </Th>
          <Th sortOn="status" center>
            {t('views.courses.coursesIndex.layouts.tableLayout.headers.status')}
          </Th>
          <Th className={cx('small')} center>
            {t('views.courses.coursesIndex.layouts.tableLayout.headers.action')}
          </Th>
        </Tr>
      </Table.THead>
      <Table.TBody>
        {courses.map((course) => (
          <Tr key={course.id}>
            <Td>
              <img
                width={70}
                height={52}
                className={cx(styles.thumbnail)}
                src={`${course.metadata?.media?.thumbnailUrl || course.thumbnailUrl}?width=140`}
                alt=""
              />
            </Td>
            <Td
              to={
                course.meta?.policy?.update
                  ? `/admin/courses/${course.id}`
                  : undefined
              }
              className={cx(styles.tdTitle)}
            >
              {course.title}
            </Td>
            <Td className={cx(styles.createdAt)}>
              {DateFormatter.toDate(course.createdAt)}
            </Td>
            <Td center>
              <Status scheme={StatusColors[course.status]}>
                {t(`states.${course.status.toLowerCase()}`)}
              </Status>
            </Td>
            <Td className={cx(styles.action)} center>
              {course.meta?.policy?.update ? (
                <ActionMenu id="policy_update">
                  <Link
                    role="menuitem"
                    href={`/admin/courses/${course.id}/edit`}
                  >
                    <Edit />
                    <span>
                      {t(
                        'views.courses.coursesIndex.layouts.tableLayout.action_menu.edit_course'
                      )}
                    </span>
                  </Link>
                  {course.opener !== 'scorm' && (
                    <Link
                      role="menuitem"
                      href={`/admin/courses/${course.id}/collections/create`}
                    >
                      <Add />
                      <span>
                        {t(
                          'views.courses.coursesIndex.layouts.tableLayout.action_menu.add_collection'
                        )}
                      </span>
                    </Link>
                  )}
                  {['CONCEPT', 'ARCHIVED'].includes(course.status) && (
                    <button
                      role="menuitem"
                      type="button"
                      onClick={() =>
                        openModal(CourseChangeStatusModal, {
                          courseId: course.id,
                          status: 'PUBLISHED',
                        })
                      }
                    >
                      <MenuBook />
                      <span>
                        {t(
                          'views.courses.coursesIndex.layouts.tableLayout.action_menu.publish_course'
                        )}
                      </span>
                    </button>
                  )}
                  {['PUBLISHED'].includes(course.status) && (
                    <button
                      role="menuitem"
                      type="button"
                      onClick={() =>
                        openModal(CourseChangeStatusModal, {
                          courseId: course.id,
                          status: 'ARCHIVED',
                        })
                      }
                    >
                      <Archive />
                      <span>
                        {t(
                          'views.courses.coursesIndex.layouts.tableLayout.action_menu.archive_course'
                        )}
                      </span>
                    </button>
                  )}
                  {['CONCEPT'].includes(course.status) && (
                    <button
                      role="menuitem"
                      className={cx(styles.error)}
                      type="button"
                      onClick={() =>
                        openModal(CourseChangeStatusModal, {
                          courseId: course.id,
                          status: 'DELETED',
                        })
                      }
                    >
                      <DeleteForever />
                      <span>
                        {t(
                          'views.courses.coursesIndex.layouts.tableLayout.action_menu.delete_course'
                        )}
                      </span>
                    </button>
                  )}
                </ActionMenu>
              ) : (
                <Lock />
              )}
            </Td>
          </Tr>
        ))}
      </Table.TBody>
    </Table>
  );
};
