import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  EducationProfile,
  EducationProfileCreateInput,
  EducationProfileFilters,
  EducationProfileIncludes,
  EducationProfileSorts,
  EducationProfileUpdateInput,
  HookParams,
  UUID,
} from '../Types';

interface IEducationProfileProps
  extends HookParams<
    typeof EducationProfileIncludes,
    typeof EducationProfileFilters,
    typeof EducationProfileSorts
  > {
  educationProfile?: UUID;
}

export function useEducationProfile(
  { educationProfile, ...queryParameters }: IEducationProfileProps = {},
  settings?: UseQueryOptions<DataDocument<EducationProfile>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { educationProfile: defaultEducationProfile },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(educationProfile || defaultEducationProfile),
      baseName: 'v1.educationProfiles',
      baseUri: '/v1/education_profiles',
      invalidate: ['education_profile', 'education_profiles'],
      uri: `/${educationProfile}`,
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    EducationProfile,
    EducationProfileCreateInput,
    EducationProfileUpdateInput
  >(request, settings);
}

export function useEducationProfiles(
  { ...queryParameters }: Omit<IEducationProfileProps, 'educationProfile'> = {},
  settings?: UseQueryOptions<DataDocument<EducationProfile[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.educationProfiles',
      baseUri: '/v1/education_profiles',
      invalidate: ['education_profile', 'education_profiles'],
      queryParameters,
    },
    context
  );
  return useIndexCR<EducationProfile, EducationProfileCreateInput>(
    request,
    settings
  );
}
