import { UseQueryOptions } from 'react-query';
import { useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  DossierCoreTask,
  DossierCoreTaskCreateInput,
  DossierCoreTaskFilters,
  DossierCoreTaskIncludes,
  DossierCoreTaskSorts,
  DossierCoreTaskUpdateInput,
  HookParams,
  UUID,
} from '../Types';

interface ICertificationDossierCoreTaskProps
  extends HookParams<
    typeof DossierCoreTaskIncludes,
    typeof DossierCoreTaskFilters,
    typeof DossierCoreTaskSorts
  > {
  dossier?: UUID;
  coreTask?: UUID;
}

export function useCertificationDossierCoreTask(
  {
    dossier,
    coreTask,
    ...queryParameters
  }: ICertificationDossierCoreTaskProps = {},
  settings?: UseQueryOptions<DataDocument<DossierCoreTask>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { dossier: defaultDossier },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(dossier || defaultDossier) && !!coreTask,
      baseName: 'v1.dossiers.core_tasks',
      baseUri: `/v1/dossiers/${dossier || defaultDossier}/core_tasks`,
      uri: `/${coreTask}`,
      invalidate: ['core_tasks', 'core_task'],
      queryParameters,
    },
    context
  );
  return useSingleCRUD<
    DossierCoreTask,
    DossierCoreTaskCreateInput,
    DossierCoreTaskUpdateInput
  >(request, settings);
}

export function useCertificationDossierCoreTasks(
  {
    dossier,
    ...queryParameters
  }: Omit<ICertificationDossierCoreTaskProps, 'coreTask'> = {},
  settings?: UseQueryOptions<DataDocument<DossierCoreTask[]>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { dossier: defaultDossier },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(dossier || defaultDossier),
      baseName: 'v1.dossiers.core_task',
      baseUri: `/v1/dossiers/${dossier || defaultDossier}/core_tasks`,
      invalidate: ['core_tasks', 'core_task'],
      queryParameters,
    },
    context
  );
  return useIndexCR<DossierCoreTask, DossierCoreTaskCreateInput>(
    request,
    settings
  );
}
