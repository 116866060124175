import React, { PropsWithChildren } from 'react';
import classNames from 'classnames/bind';
import styles from './Content.module.css';

const cx = classNames.bind(styles);

type Props = {
  style?: React.CSSProperties;
  className?: string;
};

const Content = ({ className, style, children }: PropsWithChildren<Props>) => (
  <section className={cx(styles.base, className)} style={style}>
    {children}
  </section>
);

export default Content;
