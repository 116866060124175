import { FC, useCallback, useEffect, useState } from 'react';
import { PortfolioExport, usePortfolioExports } from '@brainstud/academy-api';
import {
  Button,
  ContentModal,
  LoadingDots,
  Status,
  StatusColor,
  Table,
  Td,
  Th,
  Tr,
} from '@brainstud/ui';
import { Publish } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Loading } from 'Components';
import { useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { DateFormatter } from 'Utils/DateFormatHelper';
import styles from './PortfolioExportsModal.module.css';

interface IPortfolioExportsModal {
  enrollmentId: string;
  closeModal: () => void;
}

type StatusColors = {
  [k: string]: StatusColor;
};

const cx = classNames.bind(styles);

/**
 * The modal shows a form with which you can duplicate an exisitng learning object collection.
 */
export const PortfolioExportsModal: FC<IPortfolioExportsModal> = ({
  enrollmentId,
  closeModal,
}) => {
  const [t] = useTranslator();
  const [setToast] = useToaster();
  const [refetchConfig, setRefetchConfig] = useState<
    undefined | { refetchInterval: number; staleTime: number }
  >();

  const FIFTEEN_MINUTES = 60 * 16 * 1000;

  const [{ data: portfolioExports, create: createExport }, { isLoading }] =
    usePortfolioExports(
      {
        filter: { enrollment: enrollmentId },
      },
      refetchConfig
    );

  const lastCreatedAt =
    portfolioExports[portfolioExports.length - 1]?.createdAt;

  const [isWithinFifteenMinutes, setIsWithinFifteenMinutes] =
    useState<boolean>();
  useEffect(() => {
    if (!refetchConfig && !!lastCreatedAt) {
      setIsWithinFifteenMinutes(
        new Date().getTime() - new Date(lastCreatedAt).getTime() <
          FIFTEEN_MINUTES
      );
    }
  }, [refetchConfig, lastCreatedAt, FIFTEEN_MINUTES]);

  const statusColors: StatusColors = {
    PENDING: 'yellow',
    PROCESSING: 'yellow',
    COMPLETED: 'green',
    EXPIRED: 'grey',
    FAILED: 'red',
  };

  useEffect(() => {
    if (!isLoading) {
      if (
        portfolioExports.filter((result: PortfolioExport) =>
          ['PENDING', 'PROCESSING'].includes(result.status)
        ).length > 0
      ) {
        setRefetchConfig({ refetchInterval: 5000, staleTime: 1000 });
      } else {
        setRefetchConfig(undefined);
      }
    }
  }, [portfolioExports, isLoading]);

  const handleCreateExport = useCallback(() => {
    createExport.mutate(
      { enrollment: enrollmentId },
      {
        onSuccess: () => {
          setToast(t('modals.portfolioExports.confirmation'), 'success');
        },
        onError: () => {
          setToast(t('modals.portfolioExports.notPossible'), 'error');
        },
      }
    );
  }, [enrollmentId, createExport, setToast, t]);

  return (
    <ContentModal onClickOutside onClose={closeModal} className={styles.base}>
      <h3>{t('modals.portfolioExports.title')}</h3>
      <p>{t('modals.portfolioExports.body')}</p>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {portfolioExports.length > 0 && (
            // <Panel>
            <Table>
              <Table.THead>
                <Tr>
                  <Th>{t('modals.portfolioExports.export_created')}</Th>
                  <Th>{t('modals.portfolioExports.export_expires')}</Th>
                  <Th>{t('modals.portfolioExports.export_status')}</Th>
                  <Th>{t('modals.portfolioExports.export_action')}</Th>
                </Tr>
              </Table.THead>
              <Table.TBody>
                {(portfolioExports as PortfolioExport[])
                  .filter((item: PortfolioExport) => item.status !== 'EXPIRED')
                  .map((row) => (
                    <Tr key={row.id}>
                      <Td>{DateFormatter.toDate(row.createdAt, 'dd MMMM')}</Td>
                      <Td>{DateFormatter.toDate(row.expiresAt, 'dd MMMM')}</Td>
                      <Td>
                        {['PENDING', 'PROCESSING'].includes(row.status) ? (
                          <LoadingDots />
                        ) : (
                          <Status
                            scheme={statusColors[row.status || 'PENDING']}
                          >
                            {t(
                              `modals.portfolioExports.status.${row.status || 'PENDING'}`
                            )}
                          </Status>
                        )}
                      </Td>
                      <Td
                        href={
                          row.status === 'COMPLETED'
                            ? row.downloadLink
                            : undefined
                        }
                      >
                        <Publish
                          className={cx(styles.download, {
                            disabled: row.status !== 'COMPLETED',
                          })}
                          fontSize="large"
                        />
                      </Td>
                    </Tr>
                  ))}
              </Table.TBody>
            </Table>
            // </Panel>
          )}
          <Button
            type="button"
            style={{ marginTop: '1rem' }}
            disabled={!!refetchConfig || !!isWithinFifteenMinutes}
            onClick={handleCreateExport}
          >
            <span>{t('modals.portfolioExports.createExport')}</span>
          </Button>
        </>
      )}
    </ContentModal>
  );
};
