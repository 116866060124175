import React, { ChangeEvent, useCallback } from 'react';
import { LearningObject } from '@brainstud/academy-api';
import classNames from 'classnames/bind';
import { LearningCard, TProgressState } from 'Components/Cards/LearningCard';
import { filterObjectTags, useObjectTags } from 'Hooks';
import { CardTag } from 'Modules/action-based-learning-panel/Components';
import { useTranslator } from 'Providers/Translator';
import { Checkmark } from '../../Checkmark';
import { ReactComponent as FallbackImage } from './fallback-image.svg';
import styles from './ContentCard.module.css';

const cx = classNames.bind(styles);
type Props = {
  object: LearningObject | undefined;
  answerStatus: string;
  onCheck?: (event: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
};

export const ContentCard = ({
  object,
  answerStatus,
  onCheck,
  checked,
}: Props) => {
  const [t] = useTranslator();

  const tags = useObjectTags(object);

  const getStatus = useCallback(
    () =>
      (({
        TURNED_IN: {
          state: 'WARNING',
          label: t('components.content_card.status.turned_in'),
        },
        REJECTED: {
          state: 'ERROR',
          label: t('components.content_card.status.rejected'),
        },
        ACCEPTED: {
          state: 'SUCCESS',
          label: t('components.content_card.status.accepted'),
        },
      })[answerStatus] as TProgressState) || undefined,
    [answerStatus, t]
  );

  const poster =
    object?.metadata?.media?.posterUrl || object?.poster?.type === 'image'
      ? object?.poster?.src
      : '';

  return !object ? null : (
    <div className={cx(styles.base)}>
      <LearningCard
        posterUrl={poster}
        fallbackImage={<FallbackImage />}
        title={object.title}
        status={getStatus()}
        subheader={
          <>
            {object.userState && `${object.userState.xpAvailable}XP`}
            {tags.filter(filterObjectTags).map((tag) => (
              <CardTag key={tag.name} type={tag.name} value={tag?.value} />
            ))}
          </>
        }
        rightOverlay={
          onCheck && (
            <div className={styles.checkbox}>
              <Checkmark onChange={onCheck} checked={checked} />
            </div>
          )
        }
      />
    </div>
  );
};

export default ContentCard;
