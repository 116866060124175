import React, { useCallback, useState } from 'react';
import { Exam, useExamStepApproval } from '@brainstud/academy-api';
import { Button, Callout, ConfirmationModal } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import styles from './ConfirmModal.module.css';

const cx = classNames.bind(styles);

type Props = {
  exam: Exam;
  closeModal: () => void;
};

export const ConfirmModal = ({ exam, closeModal }: Props) => {
  const [t] = useTranslator();

  const [loading, setLoading] = useState(false);

  const [{ create: approve }] = useExamStepApproval({ exam: exam.id });
  const [errorMessage, setErrorMessage] = useState<string>();
  const handleFinish = useCallback(async () => {
    setLoading(true);
    approve
      .mutateAsync({
        step: 'student_handed_in_exam',
      })
      .then(() => {
        setLoading(false);
        closeModal();
        window.location.href = '#/';
      })
      .catch(() => {
        setLoading(false);
        setErrorMessage(t('views.exam.error'));
      });
  }, [approve, closeModal, t]);

  return (
    <ConfirmationModal
      handleConfirm={handleFinish}
      className={cx(styles.base)}
      closeModal={closeModal}
    >
      <p>{t('views.exam.confirm')}</p>
      {errorMessage && (
        <Callout error style={{ margin: '2rem 0', textAlign: 'left' }}>
          {errorMessage}
        </Callout>
      )}
      <div className={cx(styles.buttons)}>
        <Button
          outline
          disabled={loading}
          style={{ marginRight: '2rem' }}
          onClick={closeModal}
        >
          {t('views.exam.confirm_no')}
        </Button>
        <Button loading={loading} onClick={handleFinish}>
          {t('views.exam.confirm_yes')}
        </Button>
      </div>
    </ConfirmationModal>
  );
};
