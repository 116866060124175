import { useTranslator } from 'Providers/Translator';
import styles from './Subheader.module.css';

type Props = {
  imageUrls: string[];
  madeObjects: number;
  totalObjects: number;
};

const MAX_IMAGES = 3;

export const SubjectCardSubHeader = ({
  imageUrls,
  totalObjects,
  madeObjects,
}: Props) => {
  const [t] = useTranslator();
  const hasMoreImages = imageUrls.length > MAX_IMAGES;
  const images = imageUrls.slice(0, MAX_IMAGES);

  return (
    <div className={styles.base}>
      <div className={styles.thumbnails}>
        {images.map((image) => (
          <img key={image} src={image} alt="" />
        ))}
        {hasMoreImages && (
          <div className={styles.more}>
            <span>+ {imageUrls.length - MAX_IMAGES}</span>
          </div>
        )}
      </div>
      <div className={styles.progress}>
        <div className={styles.progressBar}>
          <div
            className={styles.progressed}
            style={{ width: `${madeObjects / (totalObjects / 100)}%` }}
          />
        </div>
        <div className={styles.assignments}>
          {`${Math.max(0, madeObjects)} / ${Math.max(0, totalObjects)} ${t('components.card_subject.assignments_made')}`}
        </div>
      </div>
    </div>
  );
};
