import React, { useCallback, useEffect, useState } from 'react';
import { Course } from '@brainstud/academy-api';
import { Callout } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { Container } from 'Components';
import { useBreakpoints } from 'Hooks';
import { useCourseList } from 'Modules/coach-panel/Providers';
import { useTranslator } from 'Providers/Translator';
import { DateFormatter } from 'Utils/DateFormatHelper';
import { chunkArrayPer } from '../../Utils';
import { CourseCard } from '../CourseCard';
import { CourseInfoPanel } from '../CourseInfoPanel';
import styles from './CourseGrid.module.css';

const cx = classNames.bind(styles);

type TProps = {
  onCourseClick?: (course: Course) => void;
};

export const CourseGrid = ({ onCourseClick }: TProps) => {
  const { courses } = useCourseList();
  const [t] = useTranslator();
  const [openCourseId, setOpenCourseId] = useState<string | number>();
  const [row, setRow] = useState<number>();

  useEffect(() => {
    if (!row) return;
    document.getElementById(`row-${row}`)?.scrollIntoView();
  }, [row]);

  const toggleCourseInfoPanelOnRow = useCallback(
    (rowNr?: number, courseId?: string | number) => {
      if (openCourseId === courseId) {
        setOpenCourseId(undefined);
        setRow(undefined);
      } else {
        setOpenCourseId(courseId);
        setRow(rowNr);
      }
    },
    [openCourseId]
  );

  const handleCourseClick = useCallback(
    (course: Course, rowNr?: number) => {
      if (onCourseClick) {
        onCourseClick(course);
      } else {
        toggleCourseInfoPanelOnRow(rowNr, course.id);
      }
    },
    [onCourseClick, toggleCourseInfoPanelOnRow]
  );

  const { mdUp, smDown } = useBreakpoints();

  return (
    <div className={cx(styles.grid, { hasInfoPanel: Boolean(openCourseId) })}>
      <div className={styles.flexContainer}>
        {courses?.length === 0 && (
          <Container>
            <Callout info>{t('components.catalog.no_results')}</Callout>
          </Container>
        )}
        {!!courses?.length &&
          chunkArrayPer(4, courses).map((group, i) => (
            <React.Fragment key={group[0].id}>
              <div id={`row-${i}`} className={styles.flexGroup}>
                {group.map((course) => (
                  <CourseCard
                    key={course.id}
                    courseId={course.id}
                    className={cx('card', {
                      isActive: openCourseId === course.id,
                    })}
                    isOpened={openCourseId === course.id}
                    isNew={DateFormatter.isWithinDays(course.createdAt, 180)}
                    onClick={() => handleCourseClick(course, i)}
                  />
                ))}
              </div>

              {mdUp && row === i && !!openCourseId && (
                <CourseInfoPanel
                  course={openCourseId}
                  onClose={() => toggleCourseInfoPanelOnRow()}
                />
              )}
            </React.Fragment>
          ))}
      </div>
      {smDown && !!openCourseId && (
        <CourseInfoPanel
          course={openCourseId}
          onClose={() => toggleCourseInfoPanelOnRow()}
        />
      )}
    </div>
  );
};
