import { ChangeEventHandler, useCallback } from 'react';
import { ActionMenu, Checkbox, Chip, Search } from '@brainstud/ui';
import { GetApp } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { FilterSidebarButton, InlineList } from 'Components';
import { IPaginationContext } from 'Modules/universal-components';
import { useTranslator } from 'Providers/Translator';
import styles from './SearchAndFilter.module.css';

const cx = classNames.bind(styles);

type TProps = {
  onToggleArchived: (archive: boolean) => void;
  onSearchClear: () => void;
  onSearch: ChangeEventHandler<HTMLInputElement>;
  searchQuery?: string;
  onFilterClear: () => void;
  showArchived: boolean;
  filter: IPaginationContext['filter'];
};

export const MenteesSearchAndFilter = ({
  onSearch,
  onSearchClear,
  showArchived,
  onToggleArchived,
  searchQuery,
  onFilterClear,
  filter = {},
}: TProps) => {
  const [t] = useTranslator();
  const filters = Object.keys(filter).filter(
    (activeFilter) => activeFilter === 'archived' && filter[activeFilter]
  );

  const handleOnToggleArchived = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >(
    (e) => {
      onToggleArchived(e.target.checked);
    },
    [onToggleArchived]
  );

  return (
    <section className={cx(styles.base)}>
      <div className={cx(styles.header)}>
        <FilterSidebarButton
          className={cx(styles.filter)}
          label={t('students.filter')}
          title={t('students.filter_panel.title')}
          onReset={onFilterClear}
          count={filters.length}
        >
          <Checkbox
            checked={showArchived}
            onChange={handleOnToggleArchived}
            quiet
          >
            {t('students.archivedStudents')}
          </Checkbox>
        </FilterSidebarButton>
        <Search
          onChange={onSearch}
          placeholder={t('search')}
          defaultValue={searchQuery}
          onClose={onSearchClear}
        />
        <ActionMenu id="mentees-actions" className={cx(styles.actionMenu)}>
          <a
            role="menuitem"
            href={`${window.location.origin}/exports/mentees_overview`}
          >
            <GetApp />
            <span>{t('students.export_list')}</span>
          </a>
        </ActionMenu>
      </div>
      {filters.length > 0 && (
        <div className={cx(styles.filters)}>
          <span>{`${t('components.catalog.filters')}:`}</span>
          <InlineList>
            {filters.map((activeFilter) => (
              <Chip
                key={activeFilter}
                label={t(`students.filters.${activeFilter}`)}
                OnClose={onFilterClear}
              />
            ))}
          </InlineList>
        </div>
      )}
    </section>
  );
};
