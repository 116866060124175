import { useCallback } from 'react';
import { Button } from '@brainstud/ui';
import { useRouter } from 'next/router';
import { useTranslator } from 'Providers/Translator';
import { TErrorViewProps } from './Types';

/**
 * BlockExceptionTemplate.
 *
 * Shows an error block based on the error title, description and (possible) image.
 */
export const BlockExceptionTemplate = ({
  title,
  description,
  image,
  className,
  handleReset,
}: TErrorViewProps) => {
  const [t] = useTranslator();
  const router = useRouter();

  const handleGoHome = useCallback(async () => {
    await router.push('/');
    handleReset();
  }, [router, handleReset]);

  const handleBack = useCallback(async () => {
    await router.back();
    handleReset();
  }, [router, handleReset]);

  return (
    <div className={`error-block__container ${className || ''}`.trim()}>
      <div>
        {title && <h2>{t(title)}</h2>}
        {description && <p>{t(description)}</p>}
        <div style={{ display: 'flex', gap: '1rem', marginTop: '2rem' }}>
          <Button type="button" onClick={handleBack}>
            {t('go_back')}
          </Button>
          <Button type="button" quiet onClick={handleGoHome}>
            {t('go_home')}
          </Button>
        </div>
      </div>
      {image && (
        <div>
          <img src={image} alt="" />
        </div>
      )}
    </div>
  );
};
