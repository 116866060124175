import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { FormatColorFill } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import styles from './ColorPicker.module.css';

const cx = classNames.bind(styles);

type ColorPickerProps = {
  /** Provide a name for the input to make te label clickable */
  name: string;
  /** The label is shown above the input */
  label: string;
  /** Pass in a set of preset colors to make those 'favorite' colors easily accessible */
  presets?: string[];
  /** Event handler that fires with the last value */
  onChange?: (value?: string) => void;
  /** Set a value on mount */
  defaultValue?: string;
  className?: string;
  style?: CSSProperties;
};

/**
 * ColorPicker.
 *
 * Select a color based on theme colors, or select a custom color. When the
 * color changes, the `onChange` method will fire with the new color.
 */
export const ColorPicker = ({
  name,
  label,
  presets = [],
  defaultValue,
  className,
  onChange,
  style,
}: ColorPickerProps) => {
  const [t] = useTranslator();
  const [inputValue, setInputValue] = useState<undefined | string>(
    defaultValue
  );
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    onChange?.(inputValue);
  }, [inputValue]);

  return (
    <div className={cx(styles.base, className)} style={style}>
      <label htmlFor={name}>{label}</label>
      <div className={cx(styles['presets-wrapper'])}>
        {presets.length > 0 && (
          <div className={cx(styles.presets)}>
            {presets.map((color) => (
              // eslint-disable-next-line jsx-a11y/control-has-associated-label
              <button
                key={color}
                type="button"
                className={cx(styles.preset, {
                  selected: inputValue === color,
                })}
                onClick={() => setInputValue(color)}
                style={{ backgroundColor: color }}
              />
            ))}
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button
              type="button"
              className={cx(styles.preset, styles.transparent, {
                selected: !inputValue,
              })}
              onClick={() => setInputValue(undefined)}
            />
          </div>
        )}
        <button
          type="button"
          className={cx(styles['color-custom'])}
          onClick={() => inputRef.current?.click()}
        >
          <span>{t('course-editor.component.color_picker.custom_color')}</span>
          <FormatColorFill />
        </button>
      </div>
      <input
        className={cx(styles['color-custom-input'], {
          hidden: !inputValue?.startsWith('#'),
        })}
        type="text"
        value={inputValue || '#FFF'}
        name={name}
        onChange={(event) => setInputValue(event.target.value)}
        onClick={() => inputRef.current?.click()}
        style={{
          backgroundColor: inputValue || 'transparent',
          color: 'rgb(var(--dark-text-color))',
        }}
      />
      <input
        className={cx(styles['color-input'])}
        type="color"
        value={inputValue?.startsWith('#') ? inputValue : '#FFF'}
        onChange={(event) => setInputValue(event.target.value)}
        ref={inputRef}
      />
    </div>
  );
};
