import React from 'react';
import {
  FontDownload,
  FormatBold,
  FormatItalic,
  FormatStrikethrough,
  FormatUnderlined,
} from '@mui/icons-material';
import { Editor } from '@tiptap/react';
import classNames from 'classnames/bind';
import { ToolbarItem } from './ToolbarItem';
import styles from './Toolbar.module.css';

const cx = classNames.bind(styles);

type Props = {
  /** A TipTap editor instance */
  editor?: null | Editor;
};

/**
 * MinimalToolbar
 *
 * Used within the RichTextEditor to show the styling buttons. It renders a set of
 * buttons with a minimal look.
 *
 * @param {Editor} editor
 */
export const MinimalToolbar = ({ editor }: Props) => {
  if (!editor) {
    return null;
  }

  return (
    <div className={cx(styles.base)}>
      {editor.can().toggleBold?.() && (
        <ToolbarItem
          onClick={() => editor.chain().focus().toggleBold().run()}
          active={editor.isActive('bold')}
        >
          <FormatBold />
        </ToolbarItem>
      )}
      {editor.can().toggleItalic?.() && (
        <ToolbarItem
          onClick={() => editor.chain().focus().toggleItalic().run()}
          active={editor.isActive('italic')}
        >
          <FormatItalic />
        </ToolbarItem>
      )}
      {editor.can().toggleStrike?.() && (
        <ToolbarItem
          onClick={() => editor.chain().focus().toggleStrike().run()}
          active={editor.isActive('strike')}
        >
          <FormatStrikethrough />
        </ToolbarItem>
      )}

      {editor.can().toggleUnderline?.() && (
        <ToolbarItem
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          active={editor.isActive('underline')}
        >
          <FormatUnderlined />
        </ToolbarItem>
      )}

      {editor.can().toggleHighlight?.() && (
        <ToolbarItem
          onClick={() => editor.chain().focus().toggleHighlight().run()}
          active={editor.isActive('highlight')}
        >
          <FontDownload />
        </ToolbarItem>
      )}
    </div>
  );
};
