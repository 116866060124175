import { MenteeEnrollment, StudentEnrollment } from '@brainstud/academy-api';
import { Status } from '@brainstud/ui';
import { useTranslator } from 'Providers/Translator';

type TProps = {
  enrollments?: Array<StudentEnrollment | MenteeEnrollment>;
};

const ACTIVE_EXAM_STEPS = [
  'PRE_ASSESSMENT_APPROVED',
  'IN_PROGRESS',
  'HANDED_IN',
];
const ACTIVE_SAMPLE_SET = [
  'CREATED',
  'IN_PRE_ASSESSMENT',
  'PRE_ASSESSMENT_REJECTED',
];

export const StudentExamStepSummary = ({ enrollments = [] }: TProps) => {
  const [t] = useTranslator();

  const exams = enrollments.reduce(
    (results, item) =>
      ACTIVE_EXAM_STEPS.includes(item?.meta?.exam?.currentStep || '')
        ? results + 1
        : results,
    0
  );

  const sampleSets = enrollments.reduce(
    (results, item) =>
      ACTIVE_SAMPLE_SET.includes(item?.meta?.exam?.currentStep || '')
        ? results + 1
        : results,
    0
  );

  return (
    <>
      {exams > 0 && (
        <Status scheme="yellow">
          {t('components.studentEnrollmentsRow.exams')} {`(${exams})`}
        </Status>
      )}
      {sampleSets > 0 && (
        <Status scheme="blue">
          {t('components.studentEnrollmentsRow.samplesets')} {`(${sampleSets})`}
        </Status>
      )}
    </>
  );
};
