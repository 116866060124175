import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMe } from '@brainstud/academy-api';
import { SidebarModal } from '@brainstud/ui';
import axios from 'axios';
import { useTranslator } from 'Providers/Translator';
import { Loading, Tabs, TabsItem } from '../../Components';
import { TProductLaneProject } from '../../pages/api/feedback/projects';
import { useModals } from '../../Providers';
import { FeedbackForm } from './Form';
import { Project } from './Project';
import styles from './FeedbackModal.module.css';

type TTab = 'newIdeas' | 'inDevelopment';

/**
 * FeedbackModal.
 *
 * A modal which asks users to provide feedback on the Brainstud software and platform.
 * It shows possible projects to stimulate targeted feedback and features already in development.
 */
export const FeedbackModal = () => {
  const [me] = useMe();
  const [t] = useTranslator();
  const { closeModal } = useModals();
  const [activeTab, setActiveTab] = useState<TTab>('newIdeas');
  const user = me?.account?.();
  const [projects, setProjects] = useState<TProductLaneProject[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showNewIdeaForm, setShowNewIdeaForm] = useState(false);

  const tagListRegex = useMemo(
    () =>
      new RegExp(`#${user?.roles.join('|#').replaceAll('academy ', '')}`, 'i'),
    [user?.roles]
  );
  const activeProjects = useMemo(
    () =>
      projects.filter(
        (item) =>
          ['planned', 'started'].includes(item.state) &&
          tagListRegex.test(item.description)
      ),
    [projects, tagListRegex]
  );
  const openIdeas = useMemo(
    () =>
      projects.filter(
        (item) =>
          ['backlog'].includes(item.state) &&
          tagListRegex.test(item.description)
      ),
    [projects, tagListRegex]
  );

  useEffect(() => {
    // Immediately invoke the function to fetch projects
    (async function getProjects() {
      const response =
        await axios.get<TProductLaneProject[]>('/feedback/projects');
      setProjects(response.data);
      setIsLoading(false);
    })();
  }, []);

  const handleTabChange = useCallback((tab: TTab) => {
    setActiveTab(tab);
  }, []);

  const handleShowNewIdeaForm = useCallback(() => {
    setShowNewIdeaForm(true);
  }, []);

  return (
    <SidebarModal
      className={styles.base}
      closeable
      onClickOutside
      onClose={closeModal}
      size="medium"
    >
      <header>
        <h2>{t('modals.feedback.title')}</h2>
      </header>

      <section>
        <div className={styles.intro}>
          {showNewIdeaForm ? (
            <FeedbackForm
              onCancel={() => setShowNewIdeaForm(false)}
              label={t('modals.feedback.description.cta')}
              placeholder={t('modals.feedback.description.placeholder')}
            />
          ) : (
            <p>
              <span>{t('modals.feedback.description.prepend')}</span>{' '}
              <button
                type="button"
                onClick={handleShowNewIdeaForm}
                className={styles.cta}
              >
                {t('modals.feedback.description.cta')}
              </button>
            </p>
          )}
        </div>

        <Tabs>
          <TabsItem
            active={activeTab === 'newIdeas'}
            onClick={() => handleTabChange('newIdeas')}
          >
            {t('modals.feedback.tabs.new_ideas')}
          </TabsItem>
          {activeProjects.length > 0 && (
            <TabsItem
              active={activeTab === 'inDevelopment'}
              onClick={() => handleTabChange('inDevelopment')}
            >
              {t('modals.feedback.tabs.in_development')}
            </TabsItem>
          )}
        </Tabs>

        {isLoading ? (
          <Loading />
        ) : (
          <>
            {activeTab === 'newIdeas' && (
              <>
                {openIdeas.length > 0 ? (
                  openIdeas.map((item) => (
                    <Project project={item} key={item.id} enableFeedback />
                  ))
                ) : (
                  <p>{t('modals.feedback.tabs.no_new_ideas')}</p>
                )}
              </>
            )}

            {activeTab === 'inDevelopment' && (
              <>
                {activeProjects.map((item) => (
                  <Project project={item} key={item.id} />
                ))}
              </>
            )}
          </>
        )}
      </section>
    </SidebarModal>
  );
};
