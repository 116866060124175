import {
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { TShareableAccessType } from '@brainstud/academy-api';
import { useEnrollmentLearningObjectActivity } from '@brainstud/academy-api/Hooks/useEnrollmentLearningObjectActivity';
import { LoadingDots } from '@brainstud/ui';
import { Close } from '@mui/icons-material';
import { ReactComponent as EmptyToolkit } from 'Assets/Icons/empty_toolkit.svg';
import classNames from 'classnames/bind';
import { Loading } from 'Components';
import { useEnrollmentByCourseId } from 'Hooks';
import { useRouter } from 'next/router';
import { useLearningObjectProvider } from 'Providers';
import { useAnswerProvider } from 'Providers/AnswerProvider';
import { useTranslator } from 'Providers/Translator';
import { ActivityGroup } from './ActivityGroup';
import { ActivityFeedAnswer } from './Answer';
import { ActivityFeedAnswerFeedback } from './AnswerFeedback';
import { ActivityFeedComment } from './Comments';
import { ActivityFeedFooter } from './Footer';
import { ActivityFeedShareable } from './Shareable';
import { groupByCreatedAt } from './utils';
import styles from './ActivityFeed.module.css';

const cx = classNames.bind(styles);

type TProps = {
  isOpen: boolean;
  onToggleIsOpen: Dispatch<SetStateAction<boolean>>;
  className?: string;
};

/**
 * Shows all activity for an assignment
 */
export const ActivityFeed = ({ isOpen, onToggleIsOpen, className }: TProps) => {
  const router = useRouter();
  const [t] = useTranslator();
  const container = useRef<HTMLDivElement>(null);
  const feed = useRef<HTMLDivElement>(null);
  const [openThreads, setOpenThreads] = useState<string[]>([]);
  const { learningObject } = useLearningObjectProvider();
  const { enrollment } = useEnrollmentByCourseId(
    router.query.courseId as string
  );
  const { currentAnswer, setSelectedAnswerId, setAnswerIndex, answers } =
    useAnswerProvider();

  const enrollmentId = enrollment?.id || (router.query.enrollmentId as string);
  const learningObjectId = learningObject?.id || '';

  const [activities, { isLoading, isFetching }] =
    useEnrollmentLearningObjectActivity(
      { enrollment: enrollmentId, learningObject: learningObjectId },
      { enabled: isOpen && !!enrollmentId && !!learningObjectId }
    );

  const accessLevel: TShareableAccessType | undefined =
    learningObject?.meta?.access;

  const groupedActivities = useMemo(
    () => groupByCreatedAt(activities),
    [activities]
  );

  const handleToggleIsOpen = useCallback(
    () => onToggleIsOpen((prevIsOpen) => !prevIsOpen),
    [onToggleIsOpen]
  );

  const scrollToTarget = useCallback(() => {
    const dates = Object.keys(groupedActivities);
    const lastDate = dates.length && groupedActivities[dates[dates.length - 1]];
    const target = lastDate ? lastDate[lastDate.length - 1] : null;

    if (!target || !target.id) return;

    const activityElement = feed.current?.querySelector(
      `[data-activity-id="${target.id}"]`
    );

    const threadElement = feed.current?.querySelector(
      `[data-thread-id="${target.id}"]`
    );

    const element = activityElement || threadElement;

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [groupedActivities]);

  const onTransitionEnd = useCallback(
    (event: TransitionEvent) => {
      if (event.target !== container.current || !isOpen) return;
      scrollToTarget();
    },
    [scrollToTarget, isOpen]
  );

  useEffect(() => {
    const currentContainer = container.current;
    currentContainer?.addEventListener('transitionend', onTransitionEnd);

    return () => {
      currentContainer?.removeEventListener('transitionend', onTransitionEnd);
    };
  }, [onTransitionEnd]);

  const handleToggleComments = useCallback((activityId: string) => {
    setOpenThreads((prevOpenThreads) =>
      prevOpenThreads.includes(activityId)
        ? prevOpenThreads.filter((threadId) => threadId !== activityId)
        : [...prevOpenThreads, activityId]
    );
  }, []);

  const handleChangeAnswerId = useCallback(
    (id: string) => {
      const index = answers?.findIndex((answer) => answer.id === id) || -1;
      setSelectedAnswerId(id);
      if (index > -1) setAnswerIndex(index);
    },
    [setSelectedAnswerId, setAnswerIndex, answers]
  );

  return (
    <div
      className={cx(styles.base, className, { show: isOpen })}
      ref={container}
    >
      <div className={cx(styles.close)}>
        <button
          type="button"
          className={cx(styles.closeButton)}
          onClick={handleToggleIsOpen}
        >
          <Close fontSize="large" />
        </button>
      </div>
      {isLoading ? (
        <Loading />
      ) : activities.length > 0 ? (
        <>
          <div className={cx(styles.feed)} ref={feed}>
            {Object.entries(groupedActivities).map(
              ([date, resourcesPerDate]) => (
                <ActivityGroup key={date} date={date}>
                  {resourcesPerDate.map((resource) => (
                    <Fragment key={resource.id}>
                      {resource.resourceType === 'answer_feedback' ? (
                        <ActivityFeedAnswerFeedback
                          feedback={resource}
                          onOpen={handleToggleComments}
                          isOpen={openThreads.includes(resource.id)}
                        />
                      ) : resource.resourceType === 'comments' ? (
                        <ActivityFeedComment
                          comment={resource}
                          onOpen={handleToggleComments}
                          isOpen={openThreads.includes(resource.id)}
                        />
                      ) : resource.resourceType === 'answers' ? (
                        <ActivityFeedAnswer
                          answer={resource}
                          onChangeAnswerId={handleChangeAnswerId}
                          isActive={resource.id === currentAnswer?.id}
                        />
                      ) : resource.resourceType === 'shareables' ? (
                        <ActivityFeedShareable shareable={resource} />
                      ) : null}
                    </Fragment>
                  ))}
                </ActivityGroup>
              )
            )}
            {isFetching ? <LoadingDots /> : null}
          </div>
          <footer className={cx(styles.footer)}>
            <ActivityFeedFooter
              accessLevel={accessLevel}
              onSuccess={scrollToTarget}
            />
          </footer>
        </>
      ) : (
        <div className={cx(styles.empty)}>
          <EmptyToolkit />
          <p>{t('components.activity_feed.empty_message')}</p>
        </div>
      )}
    </div>
  );
};
