import React, { PropsWithChildren } from 'react';
import classNames from 'classnames/bind';
import { BadgeProps } from './BadgeProps';
import styles from './Badge.module.css';

const cx = classNames.bind(styles);

/**
 * A badge is used to indicate a certain status of another resource, such as a learning object or course.
 * @param color Sets the color scheme of the badge
 * @param circle Makes the badge circular
 * @param size Set the 't-shirt' size of the badge
 * @param width Sets maximum width of the badge
 * @param className
 * @param style
 * @param children
 */
export const Badge = ({
  color,
  circle,
  size,
  className,
  children,
  width,
  style,
}: PropsWithChildren<BadgeProps>) => (
  <div
    style={{ maxWidth: width, ...style }}
    className={cx(
      styles.base,
      { circular: circle, 'restrict-width': !!width },
      size,
      color,
      className
    )}
  >
    {children}
  </div>
);
