import React, { useCallback, useMemo } from 'react';
import { LearningObject } from '@brainstud/academy-api';
import { Accordion, Callout, Panel } from '@brainstud/ui';
import classNames from 'classnames';
import {
  ILearningRouteNodeWithResources,
  ILearningSubjectWithResources,
} from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import { ObjectRow } from './ObjectRow';
import { SubjectRow } from './SubjectRow';
import styles from './CollectionList.module.css';

const cx = classNames.bind(styles);

type TProps = {
  nodes: ILearningRouteNodeWithResources[];
  search: string;
};

export const CollectionList = ({ nodes, search }: TProps) => {
  const [t] = useTranslator();
  const handleNestedObject = useCallback(
    (item: LearningObject | ILearningSubjectWithResources) => {
      if (item.resourceType === 'learning_subjects' && search) {
        return item.scheme.some((subItem) =>
          new RegExp(search, 'gi').test(subItem.title)
        );
      }
    },
    [search]
  );

  const sortedNodes = useMemo(() => {
    if (search !== '') {
      // Title of the nodes
      return (
        nodes
          ?.filter(
            (learningRouteNode) =>
              new RegExp(search, 'gi').test(learningRouteNode.title) ||
              // Title of learningObject and Subjects
              learningRouteNode.scheme.some((schemeItem) =>
                new RegExp(search, 'gi').test(schemeItem.title)
              ) ||
              // Title of LearningObject inside Subjects
              learningRouteNode.scheme.some(handleNestedObject)
          )
          // actual filtering of all the learningObjects, learningSubjects and nested learningObjects
          .map(
            (node): ILearningRouteNodeWithResources => ({
              ...node,
              scheme: node.scheme
                .map((schemeItem) => {
                  if (schemeItem.resourceType === 'learning_subjects') {
                    return {
                      ...schemeItem,
                      scheme: schemeItem.scheme.filter((subjectObject) =>
                        new RegExp(search, 'ig').test(subjectObject.title)
                      ),
                    };
                  }
                  return schemeItem;
                })
                .filter((filterItem) => {
                  if (filterItem.resourceType === 'learning_objects') {
                    return new RegExp(search, 'ig').test(filterItem.title);
                  }
                  return filterItem;
                }),
            })
          )
      );
    }
    return nodes;
  }, [handleNestedObject, nodes, search]);

  return (
    <div className={cx(styles.base)}>
      {sortedNodes.map((node) => (
        <Accordion
          open
          quiet
          heading
          key={node.id}
          title={<h2>{node.title}</h2>}
        >
          {node?.introduction &&
            typeof node?.introduction !== 'string' &&
            node.introduction.title !== null &&
            node.introduction.content !== null && (
              <Callout icon={false} className={cx(styles.introduction)}>
                <h4>
                  {node.introduction.title ||
                    t('components.card_introduction.default_title')}
                </h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(node.introduction.content),
                  }}
                />
              </Callout>
            )}
          <Panel>
            {node.scheme.map((schemeItem) =>
              schemeItem.resourceType === 'learning_objects' ? (
                <ObjectRow key={schemeItem.id} object={schemeItem} />
              ) : (
                <SubjectRow key={schemeItem.id} subject={schemeItem} />
              )
            )}
          </Panel>
        </Accordion>
      ))}
    </div>
  );
};
