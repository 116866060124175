import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie-player';
import { useTranslator } from 'Providers/Translator';
import animationData from './animation.json';
import { LoadingProps } from './LoadingProps';
import styles from './Loading.module.css';

/**
 * Loading.
 *
 * Shows a loader to indicate that some data is loading. Should be shown only one time per page.
 * The loader does give textual feedback that changes over time (every 6 seconds).
 *
 * Note: It is one of the very few UI components that has build in text.
 */
export const Loading = ({ style, className }: LoadingProps) => {
  const [t] = useTranslator();
  const [warningLevel, setWarningLevel] = useState(0);
  const messages = [
    t('components.loading.loading', {}, ''),
    t('components.loading.bit_more_patience', {}, ''),
    t('components.loading.slow_internet_or_something_is_wrong', {}, ''),
    t('components.loading.something_went_wrong', {}, ''),
  ];

  const isOffline = !window.navigator.onLine;

  useEffect(() => {
    const interval = setInterval(() => {
      setWarningLevel((prevWarningLevel) => prevWarningLevel + 1);
    }, 12040);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={`${styles.base} ${className}`} style={style}>
      <Lottie
        animationData={animationData}
        play={warningLevel < 3}
        loop
        style={{ width: '64px', height: '64px', margin: '0 auto' }}
      />
      <p>
        {isOffline
          ? t('components.loading.no_connection')
          : messages[Math.min(3, warningLevel)]}
      </p>
    </div>
  );
};
