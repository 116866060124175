import React, { useEffect, useState } from 'react';
import { IRichTitleBlock } from '@brainstud/course-blocks';
import { Dropdown, Input } from '@brainstud/ui';
import classNames from 'classnames/bind';
import { useDebounce } from 'Modules/universal-components';
import { useTranslator } from 'Providers/Translator';
import styles from './RichTitleForm.module.css';

const classes = classNames.bind(styles);

export type RichTitleFormProps = {
  data?: IRichTitleBlock;
  onChange: (e: Partial<IRichTitleBlock>) => void;
};

export const RichTitleForm = ({ data, onChange }: RichTitleFormProps) => {
  const [level, setLevel] = useState(`${data?.level || 2}`);
  const [prefix, setPrefix] = useState(data?.prefix || '');
  const [title, setTitle] = useState(data?.content || '');
  const [variant, setVariant] = useState(data?.className || 'default');

  const debouncedTitle = useDebounce(title, 300);
  const debouncedPrefix = useDebounce(prefix, 300);

  useEffect(() => {
    onChange?.({
      prefix: debouncedPrefix,
      content: debouncedTitle,
      className: variant,
      level: parseInt(level, 10) || 2,
    });
    // eslint-disable-next-line
  }, [debouncedTitle, debouncedPrefix, level, variant]);

  const [t] = useTranslator();

  return (
    <div className={styles.base}>
      <Dropdown
        className={classes(styles.dropdown, styles.level)}
        label={t('course-editor.content_editor.blocks.rich_title.input.level')}
        value={level}
        onChange={(value) => value && setLevel(value)}
      >
        <Dropdown.Option value="1">H1</Dropdown.Option>
        <Dropdown.Option value="2">H2</Dropdown.Option>
        <Dropdown.Option value="3">H3</Dropdown.Option>
        <Dropdown.Option value="4">H4</Dropdown.Option>
      </Dropdown>

      <Input
        className={classes(styles.input, styles.prefix)}
        label={t('course-editor.content_editor.blocks.rich_title.input.prefix')}
        value={prefix}
        onChange={(e) => setPrefix(e.target.value)}
      />

      <Input
        className={classes(styles.input, styles.title)}
        label={t('course-editor.content_editor.blocks.rich_title.input.title')}
        onChange={(e) => setTitle(e.target.value)}
        value={title}
      />

      <Dropdown
        className={classes(styles.dropdown, styles.variant)}
        label={t(
          'course-editor.content_editor.blocks.rich_title.input.variant'
        )}
        onChange={(value) =>
          value && setVariant(value as 'section' | 'default')
        }
        defaultValue={variant}
      >
        <Dropdown.Option value="default">
          <div className={styles.light} />
        </Dropdown.Option>
        <Dropdown.Option value="section">
          <div className={styles.dark} />
        </Dropdown.Option>
      </Dropdown>
    </div>
  );
};
