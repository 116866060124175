import { UseMutationOptions } from 'react-query';
import { useCreate } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import { CoachMandateTransferCreateInput } from '../Types';

type Settings = UseMutationOptions<
  DataDocument<undefined>,
  ErrorDocument,
  CoachMandateTransferCreateInput
>;

export function useCreateCoachMandateTransfer(settings?: Settings) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.coachMandates.transfer',
      baseUri: '/v1/services/coaches/mandate_transfer',
      invalidate: ['coach_mandate', 'coach_mandates', 'v1.students'],
    },
    context
  );
  return useCreate<undefined, CoachMandateTransferCreateInput>(
    request,
    settings
  );
}
