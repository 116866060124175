import React from 'react';
import { AnswersView } from '@brainstud/blended-learning-catalog-panel/Views/PortfolioView/AnswersView/AnswersView';
import { DataProvider } from '@brainstud/universal-components';
import { usePortalFeatureCheck } from 'Hooks';
/**
 * AnswersView.
 *
 * Shows all Answers for the portfolio of the participant.
 */
export const PortfolioAnswersView = () => {
  usePortalFeatureCheck('portfolio_feature', true);

  return (
    <DataProvider data={[]}>
      <AnswersView />
    </DataProvider>
  );
};
