import { Groups } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { Avatar } from 'Components';
import { Badge } from '../Badge';
import styles from './StudentInfo.module.css';

const cx = classNames.bind(styles);

type TProps = {
  fullName: string;
  lastOnline: string;
  classUnit?: string | null;
  unseenAnswerCount?: number;
};

export const StudentInfo = ({
  fullName,
  lastOnline,
  classUnit,
  unseenAnswerCount = 0,
}: TProps) => (
  <div className={cx(styles.base)}>
    <div className={cx(styles.avatar)}>
      <Avatar name={fullName} lastOnline={lastOnline} />
    </div>
    <div className={cx(styles.info)}>
      <strong>{fullName}</strong>

      {(unseenAnswerCount > 0 || classUnit) && (
        <div className={cx(styles.meta)}>
          {unseenAnswerCount > 0 && (
            <Badge color="red" circle>
              {unseenAnswerCount}
            </Badge>
          )}

          {classUnit && (
            <>
              <Groups />
              <span>{classUnit}</span>
            </>
          )}
        </div>
      )}
    </div>
  </div>
);
