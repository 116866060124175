import { UseMutationOptions } from 'react-query';
import { useCreate } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import { Shareable, ShareableCreateInput, UUID } from '../Types';

interface IShareEnrollmentLearningObjectProps {
  enrollment: UUID;
  learningObject: UUID;
}

/**
 * useShareEnrollmentLearningObject.
 *
 * Create a shareable for a EnrollmentlearningObject.
 */
export function useShareEnrollmentLearningObject(
  { enrollment, learningObject }: IShareEnrollmentLearningObjectProps,
  settings?: UseMutationOptions<
    DataDocument<Shareable>,
    ErrorDocument,
    ShareableCreateInput
  >
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.enrollment.learning_object.share',
      baseUri: `/v1/enrollments/${enrollment}/learning_objects/${learningObject}/actions/share`,
      invalidate: [
        'v1.enrollments.learningObject',
        'v1.enrollments.learningObjects',
      ],
    },
    context
  );
  return useCreate<Shareable, ShareableCreateInput>(request, settings);
}
