import React, { useMemo } from 'react';
import classNames from 'classnames/bind';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import { TField, useForm } from '../../../universal-components';
import styles from './ExtraAnswerMatches.module.css';

const classes = classNames.bind(styles);

type Props = {
  identifiers: string[];
};

/**
 * ExtraAnswerMatches.
 *
 * When any form field match (based on `id`), then it will show the value of those fields. Except
 * for values that aren't strings or are empty strings.
 */
export const ExtraAnswerMatches = ({ identifiers }: Props) => {
  const [t] = useTranslator();
  const { fields } = useForm();

  const matchedFields = useMemo(
    () =>
      identifiers.reduce<TField[]>((output, identifier) => {
        const matchedField = fields.find(
          (item) => item.id.includes(identifier) && item.value !== ''
        );
        return matchedField ? [...output, matchedField] : output;
      }, []),
    [identifiers, fields]
  );

  return matchedFields.length > 0 ? (
    <div className={classes(styles.base)}>
      <small>{t('course-blocks.extra_answer_matches.explanation')}</small>
      {matchedFields.map((field) => (
        <div key={field.id} className={classes(styles.match)}>
          {typeof field.value === 'string' && (
            <div dangerouslySetInnerHTML={{ __html: sanitizer(field.value) }} />
          )}
        </div>
      ))}
    </div>
  ) : null;
};
