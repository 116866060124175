import React, { useCallback } from 'react';
import { useLtiResourceLink } from '@brainstud/academy-api';
import { Button } from '@brainstud/ui';
import { PlayArrow } from '@mui/icons-material';
import classNames from 'classnames';
import { LaunchLtiResourceModal } from 'Modals';
import { useModals } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { ILtiResourceLinkBlock } from './Types';
import styles from './LtiResourceLinkBlock.module.css';

const cx = classNames.bind(styles);

export const LtiResourceLinkBlock = ({
  ltiResourceLinkId,
}: ILtiResourceLinkBlock) => {
  const [t] = useTranslator();
  const { openModal, closeModal } = useModals();

  const [{ data: ltiResourceLink }] = useLtiResourceLink({
    ltiResourceLink: ltiResourceLinkId,
  });

  const handleClick = useCallback(() => {
    openModal(LaunchLtiResourceModal, {
      ltiResourceLink,
      closeModal,
    });
  }, [closeModal, ltiResourceLink, openModal]);

  return (
    <Button
      quiet
      outline
      block
      type="button"
      disabled={ltiResourceLink === undefined}
      className={cx(styles.button)}
      onClick={handleClick}
    >
      <div className={cx(styles.base)}>
        <div className={cx(styles.text)}>
          <span className={cx(styles.title)}>
            {ltiResourceLink?.ltiToolProvider?.().title}
          </span>
          <span className={cx(styles.message)}>
            {t('course-blocks.lti_resource_link.external_content')}
          </span>
        </div>
        <PlayArrow className={cx(styles.play)} />
      </div>
    </Button>
  );
};
