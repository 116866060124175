import React, { useCallback, useState } from 'react';
import { Mentee, UUID } from '@brainstud/academy-api';
import { useMentees } from '@brainstud/academy-api/Hooks/useMentees';
import {
  CollapsibleRow,
  Indicator,
  Panel,
  Table,
  Td,
  TdCheckbox,
  Th,
  Tr,
} from '@brainstud/ui';
import { Archive } from '@mui/icons-material';
import { ReactComponent as NoMenteesIcon } from 'Assets/Icons/no-mentees.svg';
import classNames from 'classnames/bind';
import { ArchiveModal } from 'Modules/coach-panel/Modals';
import { useDataSelection } from 'Modules/universal-components';
import { useRouter } from 'next/router';
import { useModals } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { getUUIDsFromString } from 'Utils/getUUIDsFromString';
import { SelectionMenu } from '../SelectionMenu/SelectionMenu';
import { ExpandedMenteeRow } from './ExpandedMenteeRow';
import { MenteesTableRow } from './MenteesTableRow';
import styles from './MenteesTable.module.css';

const cx = classNames.bind(styles);

type TProps = {
  mentees: Mentee[];
  total?: number;
  onSort: (sort: string[]) => void;
  isFetching: boolean;
  showingArchived: boolean;
  searchQuery?: string;
};

/**
 * MenteesTable.
 *
 * Displays provided mentees in a table.
 */
export const MenteesTable = ({
  total = 0,
  mentees,
  onSort,
  isFetching,
  showingArchived,
  searchQuery,
}: TProps) => {
  const [t] = useTranslator();
  const { query } = useRouter();
  const { students: studentsInUrl } = query as { students?: string };
  const [expandedIds, setExpandedIds] = useState<UUID[]>(
    getUUIDsFromString(studentsInUrl)
  );
  const { selection, handleToggleSelect } = useDataSelection();
  const { openModal, closeModal } = useModals();
  const [{ update }] = useMentees(undefined, { enabled: false });

  const handleOnSort = useCallback(
    (sortOn?: string) => {
      onSort(sortOn ? [sortOn] : []);
    },
    [onSort]
  );

  const handleOnToggle = useCallback((menteeId: string) => {
    setExpandedIds((prevExpandedIds) =>
      prevExpandedIds.includes(menteeId)
        ? prevExpandedIds.filter((id) => id !== menteeId)
        : [...prevExpandedIds, menteeId]
    );
  }, []);

  return (
    <div className={cx(styles.base)}>
      <strong className={cx(styles.count)}>
        {t('views.coach.mentees.total_count', { total })}
      </strong>
      <Panel>
        <Table onSort={handleOnSort}>
          {mentees.length > 0 && (
            <Table.THead>
              <Tr>
                {isFetching ? (
                  <Th className={cx(styles.loading)}>
                    <Indicator loading />
                  </Th>
                ) : (
                  <TdCheckbox id={mentees.map(({ id }) => id)} />
                )}
                <Th sortOn="full_name">{t('students.student')}</Th>
                <Th collapse>{t('students.subscription')}</Th>
                <Th collapse sortOn="last_online">
                  {t('students.lastActive')}
                </Th>
                <Th />
              </Tr>
            </Table.THead>
          )}
          <Table.TBody>
            {mentees.length > 0 ? (
              mentees.map((mentee) => (
                <CollapsibleRow
                  key={mentee.id}
                  data={mentee}
                  rowComponent={MenteesTableRow}
                  expandedRowComponent={ExpandedMenteeRow}
                  isSelected={selection.includes(mentee.id)}
                  onToggle={handleOnToggle}
                  onToggleSelect={handleToggleSelect}
                  isExpanded={expandedIds.includes(mentee.id)}
                />
              ))
            ) : (
              <Tr className="no-hover">
                <Td colSpan={6} center>
                  <div className={cx(styles.empty)}>
                    <NoMenteesIcon />
                    {showingArchived && !searchQuery ? (
                      <p className={cx(styles.title)}>
                        {t('views.coach.mentees.archived.title')}
                      </p>
                    ) : searchQuery ? (
                      <p className={cx(styles.title)}>
                        {t('views.coach.mentees.no_results.title', {
                          searchQuery,
                        })}
                      </p>
                    ) : (
                      <>
                        <p className={cx(styles.title)}>
                          {t('views.coach.mentees.nudge.title')}
                        </p>
                        <p>{t('views.coach.mentees.nudge.subtitle')}</p>
                      </>
                    )}
                  </div>
                </Td>
              </Tr>
            )}
          </Table.TBody>
        </Table>
      </Panel>

      <SelectionMenu open={selection.length > 0}>
        <button
          type="button"
          onClick={() =>
            openModal(ArchiveModal, {
              accountIds: selection,
              unarchive: showingArchived,
              handleToggleSelect,
              onComplete: () => handleToggleSelect([]),
              onArchive: update.mutateAsync,
              closeModal,
            })
          }
        >
          <span>
            {showingArchived ? t('students.dearchive') : t('students.archive')}
          </span>
          <Archive />
        </button>
      </SelectionMenu>
    </div>
  );
};
