import { UseMutationOptions, UseQueryOptions } from 'react-query';
import { useCreate, useIndexCR, useSingleCRUD } from '../Factories';
import { useApi } from '../Providers';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import {
  Exposition,
  ExpositionCreateInput,
  ExpositionFilters,
  ExpositionIncludes,
  ExpositionSorts,
  ExpositionUpdateInput,
  HookParams,
  Shareable,
  ShareableCreateInput,
  UUID,
} from '../Types';

interface IExpositionProps
  extends HookParams<
    typeof ExpositionIncludes,
    ExpositionFilters,
    typeof ExpositionSorts
  > {
  exposition?: UUID;
}

export function useExposition(
  { exposition, ...queryParameters }: IExpositionProps = {},
  settings?: UseQueryOptions<DataDocument<Exposition>, ErrorDocument>
) {
  const context = useApi();
  const {
    parameters: { Exposition: defaultExposition },
  } = context;
  const request = new ApiRequest(
    {
      enabled: !!(exposition || defaultExposition),
      baseName: 'v1.expositions',
      baseUri: '/v1/expositions',
      invalidate: ['exposition', 'expositions'],
      uri: `/${exposition}`,
      queryParameters,
      shareable: true,
    },
    context
  );
  return useSingleCRUD<
    Exposition,
    ExpositionCreateInput,
    ExpositionUpdateInput
  >(request, settings);
}

export function useExpositions(
  { ...queryParameters }: Omit<IExpositionProps, 'exposition' | 'shared'> = {},
  settings?: UseQueryOptions<DataDocument<Exposition[]>, ErrorDocument>
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.expositions',
      invalidate: ['exposition', 'expositions'],
      baseUri: '/v1/expositions',
      queryParameters,
    },
    context
  );
  return useIndexCR<Exposition, ExpositionCreateInput>(request, settings);
}

interface IShareExpositionProps {
  exposition: UUID;
}

/**
 * useExpositionActionsShare.
 *
 * Create a shareable for an Exposition.
 */
export function useExpositionActionsShare(
  { exposition }: IShareExpositionProps,
  settings?: UseMutationOptions<
    DataDocument<Shareable>,
    ErrorDocument,
    ShareableCreateInput
  >
) {
  const context = useApi();
  const request = new ApiRequest(
    {
      baseName: 'v1.expositions.share',
      baseUri: `/v1/expositions/${exposition}/actions/share`,
      invalidate: [
        'v1.expositions',
        'expositions',
        'exposition',
        'v1.profile',
        'account.expositions',
      ],
    },
    context
  );
  return useCreate<Shareable, ShareableCreateInput>(request, settings);
}
