import { QueryObserverResult, UseMutationResult } from 'react-query';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import { IResource } from '../Types';
import { UpdateMethods } from '../Types/Utils/UpdateMethods';
import useCreate from './useCreate';
import useDestroy from './useDestroy';
import useIndex from './useIndex';
import useShow from './useShow';
import useUpdate from './useUpdate';

type TOutputFormat<Resource extends IResource, CreateInput, UpdateInput> = [
  {
    index: Resource[];
    data: Resource | undefined;
    create: UseMutationResult<
      DataDocument<Resource>,
      ErrorDocument,
      CreateInput
    >;
    update: UseMutationResult<
      DataDocument<Resource>,
      ErrorDocument,
      UpdateInput & UpdateMethods
    >;
    createOrUpdate:
      | UseMutationResult<DataDocument<Resource>, ErrorDocument, CreateInput>
      | UseMutationResult<
          DataDocument<Resource>,
          ErrorDocument,
          UpdateInput & UpdateMethods
        >;
    destroy: UseMutationResult<
      DataDocument<Resource>,
      ErrorDocument,
      void,
      any
    >;
  },
  QueryObserverResult<DataDocument<Resource | Resource[]>, ErrorDocument>,
];

export default function useCRUD<
  Resource extends IResource,
  CreateInput,
  UpdateInput,
>(
  request: ApiRequest,
  id?: string
): TOutputFormat<Resource, CreateInput, UpdateInput> {
  const [index, responseIndex] = useIndex<Resource>(!id ? request : null);

  const [data, responseShow] = useShow<Resource>(id ? request : null);

  const create = useCreate<Resource, CreateInput>(request);

  const update = useUpdate<Resource, UpdateInput & UpdateMethods>(request);

  const destroy = useDestroy<Resource>(request);

  return [
    {
      index,
      data,
      create,
      update,
      createOrUpdate: !id ? create : update,
      destroy,
    },
    !id ? responseIndex : responseShow,
  ];
}
