import React, { useCallback } from 'react';
import { useTwoFactor } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { TwoFactorVerificationForm } from './Form/TwoFactorVerificationForm';

export const TwoFactorAuthRemoveView = () => {
  const [t] = useTranslator();
  const { disableTwoFactor } = useTwoFactor();

  const handleSubmit = useCallback(
    (verification_code: string) => {
      disableTwoFactor(verification_code);
    },
    [disableTwoFactor]
  );

  return (
    <>
      <h3>{t('security.two_factor.remove.title')}</h3>
      <p>{t('security.two_factor.remove.info')}</p>
      <p>{t('security.two_factor.remove.info2')}</p>
      <TwoFactorVerificationForm onSubmit={handleSubmit} />
    </>
  );
};
