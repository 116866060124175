import React from 'react';
import { Close } from '@mui/icons-material';
import classNames from 'classnames/bind';
import { QuickFilterProps } from './QuickFilterProps';
import styles from './QuickFilter.module.css';

const cx = classNames.bind(styles);

/**
 * QuickFilter.
 *
 * Quick filter button, with controlled active state.
 */
export const QuickFilter = ({
  children,
  isActive,
  onClick,
  className,
  style,
}: QuickFilterProps) => (
  <button
    className={cx(styles.base, { active: isActive, className })}
    style={style}
    type="button"
    onClick={onClick}
  >
    {children}
    {isActive && (
      <div className={cx(styles.disable)}>
        <Close />
      </div>
    )}
  </button>
);
