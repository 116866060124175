import React, { useCallback } from 'react';
import { useScorm } from '@brainstud/academy-api';
import { ScormContentState } from '@brainstud/academy-api/Types/Resources/Scorm';
import { Button } from '@brainstud/ui';
import { PlayArrow } from '@mui/icons-material';
import classNames from 'classnames';
import { IScormBlock } from 'Modules/course-blocks';
import { useRouter } from 'next/router';
import { useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import styles from './ScormBlock.module.css';

const cx = classNames.bind(styles);

export const ScormBlock = ({ scormId }: IScormBlock) => {
  const [t] = useTranslator();
  const [setToast] = useToaster();

  const router = useRouter();
  const { courseId } = router.query as { courseId: string };

  const [{ data: scorm }] = useScorm({ course: courseId, scorm: scormId });

  const handleClick = useCallback(() => {
    if (scorm?.state !== ScormContentState.AVAILABLE) {
      setToast(t('views.scorm.cannot_play'), 'warning');
      return;
    }
    router.push(`/scorm/player/${scorm.id}`);
  }, [router, scorm?.id, scorm?.state, setToast, t]);

  return (
    <Button
      quiet
      outline
      block
      type="button"
      disabled={scorm === undefined}
      className={cx(styles.button)}
      onClick={handleClick}
    >
      <div className={cx(styles.base)}>
        <div className={cx(styles.text)}>
          <span className={cx(styles.title)}>{scorm?.title}</span>
          <span className={cx(styles.message)}>
            {t('course-blocks.scorm_package.external_content')}
          </span>
        </div>
        <PlayArrow className={cx(styles.play)} />
      </div>
    </Button>
  );
};
