import React, { FC } from 'react';
import { Exam } from '@brainstud/academy-api';
import { ContentModal, Status } from '@brainstud/ui';
import { Grid } from '@mui/material';
import { useModals } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { DateFormatter } from 'Utils/DateFormatHelper';

type Props = {
  exam: Exam;
};

export const ExamAssessmentModal: FC<Props> = ({ exam }) => {
  const [t] = useTranslator();
  const { closeModal } = useModals();

  const assessment = exam?.assessment?.();

  return (
    <ContentModal onClose={closeModal}>
      <h3>{t('views.exam.assessment_modal.title')}</h3>
      <Grid container>
        <Grid item md={5}>
          <p>
            <strong>{t('views.exam.assessment_modal.completed_at')}</strong>
          </p>
          <p>
            {exam?.signalDate
              ? DateFormatter.toDate(new Date(exam?.signalDate))
              : ''}
          </p>
        </Grid>
        <Grid item md={7}>
          <p>
            <strong>{t('views.exam.assessment_modal.assessed_at')}</strong>
          </p>
          <p>
            {assessment?.date
              ? DateFormatter.toDate(new Date(assessment.date))
              : ''}
          </p>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={5}>
          <p>
            <strong>{t('views.exam.assessment_modal.assessment')}</strong>
          </p>
          <Status
            scheme={assessment?.passed ? 'green' : 'red'}
            style={{ marginLeft: 0 }}
          >
            {assessment?.gradeType === 'NUMBER'
              ? `${t('views.exam.assessment_modal.grade')}: ${assessment.gradeResult}`
              : `${t(`views.exam.assessment_modal.grades.${assessment?.gradeResult}`)}`}
          </Status>
        </Grid>
        <Grid item sm={7}>
          <p>
            <strong>{t('views.exam.assessment_modal.feedback')}</strong>
          </p>
          <p>{assessment?.feedback}</p>
        </Grid>
      </Grid>
    </ContentModal>
  );
};
