import { useContext } from 'react';
import {
  ContentBlocksContext,
  IContentBlocksContext,
} from './ContentBlocksContext';

export function useContentBlocksProvider(): IContentBlocksContext {
  const context = useContext(ContentBlocksContext);

  if (!context) {
    throw Error('The ContentBlocksContext is required for this hook.');
  }

  return context;
}
