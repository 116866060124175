import { useMemo } from 'react';
import {
  QueryObserverResult,
  UseMutationResult,
  UseQueryOptions,
} from 'react-query';
import { ApiRequest, DataDocument, ErrorDocument } from '../Support';
import { IResource } from '../Types';
import useCreate from './useCreate';
import useIndex from './useIndex';

type TOutputFormat<Resource extends IResource, CreateInput> = [
  {
    data: Resource[];
    create: UseMutationResult<
      DataDocument<Resource>,
      ErrorDocument,
      CreateInput
    >;
  },
  QueryObserverResult<DataDocument<Resource | Resource[]>, ErrorDocument>,
];

export default function useIndexCR<Resource extends IResource, CreateInput>(
  request: ApiRequest,
  settings?: UseQueryOptions<DataDocument<Resource[]>, ErrorDocument>
): TOutputFormat<Resource, CreateInput> {
  const [data, responseIndex] = useIndex<Resource>(request, settings);

  const create = useCreate<Resource, CreateInput>(request);

  return useMemo(
    () => [
      {
        data,
        create,
      },
      responseIndex,
    ],
    [data, create, responseIndex]
  );
}
