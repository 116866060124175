import React, { useCallback } from 'react';
import { useLearningRouteDuplicate, UUID } from '@brainstud/academy-api';
import { Button, Callout, ContentModal } from '@brainstud/ui';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import styles from './DuplicateLearningRouteModal.module.css';

export interface Props {
  closeModal: () => void;
  learningRouteId: UUID;
  learningRouteTitle?: string;
}
const cx = classNames.bind(styles);

/**
 * DuplicateLearningRouteModal.
 *
 * With this modal you can duplicate a learning route, within a collection.
 * In this duplicated learning route you can change the order and add conditions to chapters and assignments.
 *
 */
export const DuplicateLearningRouteModal = ({
  closeModal,
  learningRouteId,
  learningRouteTitle,
}: Props) => {
  const [t] = useTranslator();
  const router = useRouter();
  const [setFlash] = useToaster();
  const { courseId, collectionId } = router.query as {
    courseId: string;
    collectionId: string;
  };
  const duplicateLearningRoute = useLearningRouteDuplicate({
    learningRoute: learningRouteId,
  });

  const handleDuplicateLearningRoute = useCallback(() => {
    duplicateLearningRoute.mutate(
      {
        title: `copy of - ${learningRouteTitle}`,
      },
      {
        onSuccess: (response) => {
          closeModal();
          router.push(
            `/coach/courses/${courseId}/collections/${collectionId}/routes/${response.data.id}/edit`,
            undefined,
            { shallow: true }
          );
        },
        onError: () => {
          setFlash(
            t('views.courses.edit_route_modal.duplicate_error'),
            'error'
          );
        },
      }
    );
  }, [
    closeModal,
    collectionId,
    courseId,
    duplicateLearningRoute,
    learningRouteTitle,
    router,
    setFlash,
    t,
  ]);

  return (
    <ContentModal onClickOutside onClose={closeModal} className={styles.base}>
      <h2>{t('views.courses.assignments.edit_route')}</h2>
      <span className={cx(styles.content)}>
        {t('views.courses.edit_route_modal.content')}
      </span>

      <Callout
        warning
        dangerouslySetInnerHTML={{
          __html: sanitizer(t('views.courses.edit_route_modal.callout')),
        }}
      />
      <div className={cx(styles['button-wrapper'])}>
        <Button onClick={closeModal} quiet>
          {t('views.courses.edit_route_modal.close')}
        </Button>
        <Button
          onClick={handleDuplicateLearningRoute}
          loading={duplicateLearningRoute.isLoading}
          disabled={duplicateLearningRoute.isLoading}
        >
          {t('views.courses.assignments.edit_route')}
        </Button>
      </div>
    </ContentModal>
  );
};
